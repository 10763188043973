import { CreateGajiList, CreateGajiCreate, CreateGajiDetail } from "pages/Payroll";

export const CreateGaji = {
  component: <CreateGajiList />,
  link: "create-gaji",
  name: "Create Gaji",
  access: "PEKERJA_PAYROLL_BULANAN",
  child: [
    {
      name: "Tambah",
      link: "/tambah",
      component: <CreateGajiCreate />
    },
    {
      name: "Detail",
      link: "/detail",
      component: <CreateGajiDetail />
    }
  ]
};
