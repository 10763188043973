import { ButtonBack, ButtonCreate, dateConvert, numberConvert, Select } from "components";
import { Formik, useFormikContext } from "formik";
import { useAccessRights } from "hooks";
import { ListLayout } from "layouts";
import { debounce } from "lodash";
import { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import RealisasiPekerjaPensiunApi from "./__RealisasiPekerjaPensiunApi__";

const ModalFilterBody = () => {
  const { values, setValues } = useFormikContext();

  const getPeriode = useQuery(["periode-penggajian-for-list", "dropdown"], () =>
    RealisasiPekerjaPensiunApi.getPeriodeGajian()
  );

  return (
    <>
      <Select
        label="Periode Penggajian"
        placeholder="Pilih periode penggajian"
        defaultValue={getPeriode?.data?.find((item) => item.value === values?.periodeGajianId)}
        options={getPeriode?.data ?? []}
        onChange={(val) => setValues({ ...values, periodeGajianId: val.value, periodePerencanaan: val.label })}
        loading={getPeriode.isFetching}
        errorFetch={getPeriode.isError}
      />
    </>
  );
};

export const DataPekerjaPensiunList = () => {
  const number = numberConvert();
  const date = dateConvert();
  const navigate = useNavigate();
  const access = useAccessRights("REALISASI_PEKERJA_PENSIUN");

  const [searchKey, setSearchKey] = useState(undefined);
  const [pagination, setPagination] = useState({
    pageCurrent: 1,
    dataLength: 10
  });

  const [filter, setFilter] = useState({
    show: false,
    active: false,
    data: {
      periodeGajianId: undefined
    }
  });

  const getPerhitunganUangPhkList = useQuery(
    ["data-phk", "list", searchKey, pagination.pageCurrent, pagination.dataLength, filter.data?.periodeGajianId],
    () =>
      RealisasiPekerjaPensiunApi.getForm({
        search: searchKey,
        page: pagination.pageCurrent,
        limit: pagination.dataLength,
        periode_tahun: filter.data?.periodeGajianId
      }),
    {
      enabled: Boolean(filter?.data?.periodeGajianId)
    }
  );

  const onCreateButtonClickHandler = (data) =>
    navigate("/operasional-sdm/transaksi/realisasi-pekerja-pensiun/tambah/" + data?.pekerja_pengajuan_pensiun_id);

  const onPaginationChange = (data) => setPagination({ ...pagination, pageCurrent: data });

  const onPaginationDataLengthChange = (data) => setPagination({ ...pagination, dataLength: data });

  const searchHandler = (e) => {
    setSearchKey(e?.target?.value ? e.target.value : undefined);
  };

  const TABLE_HEADER = [
    {
      text: "NIK"
    },
    {
      text: "Pekerja"
    },
    {
      text: "Jabatan"
    },
    {
      text: "Unit Organisasi"
    },
    {
      text: "Tanggal Lahir"
    },
    {
      text: "Umur Hari Ini"
    },
    {
      text: "Tanggal Pengajuan"
    },
    {
      text: "Tanggal Pengangkatan"
    },
    {
      text: "Uang Pesangon"
    },
    {
      text: "Uang Penghargaan"
    },
    {
      text: "Uang Pengganti Hak"
    },
    {
      text: "Uang Pisah"
    },
    {
      text: "Total Uang PHK"
    },

    {
      text: "Aksi"
    }
  ];

  const TABLE_BODY = [
    { field: "nip" },
    { field: "pekerja_nama" },
    { field: "master_jabatan_nama" },
    { field: "master_unit_organisasi_nama" },
    {
      customField: (val) => (val?.tgl_lahir ? date.getSlashDMY(new Date(val?.tgl_lahir)) : "-")
    },

    {
      customField: (val) => (val?.umur_pensiun ? val.umur_pensiun + " Tahun" : "-")
    },
    {
      customField: (val) => (val?.tgl_batas_pensiun ? date.getSlashDMY(new Date(val?.tgl_batas_pensiun)) : "-")
    },
    {
      customField: (val) => (val?.tgl_pengangkatan ? date.getSlashDMY(new Date(val?.tgl_pengangkatan)) : "-")
    },
    {
      props: { textRight: true },
      customField: (val) =>
        val?.peraturan_uang_pesangon
          ? "Rp" + number.getWithComa(val?.peraturan_uang_pesangon, { minimumFractionDigits: 2 })
          : "-"
    },
    {
      props: { textRight: true },
      customField: (val) =>
        val?.peraturan_uang_upmk
          ? "Rp" + number.getWithComa(val?.peraturan_uang_upmk, { minimumFractionDigits: 2 })
          : "-"
    },
    {
      props: { textRight: true },
      customField: (val) =>
        val?.peraturan_uang_uph ? "Rp" + number.getWithComa(val?.peraturan_uang_uph, { minimumFractionDigits: 2 }) : "-"
    },
    {
      props: { textRight: true },
      customField: (val) =>
        val?.peraturan_uang_up ? "Rp" + number.getWithComa(val?.peraturan_uang_up, { minimumFractionDigits: 2 }) : "-"
    },
    {
      props: { textRight: true },
      customField: (val) =>
        val?.peraturan_total_uang_phk
          ? "Rp" + number.getWithComa(val?.peraturan_total_uang_phk, { minimumFractionDigits: 2 })
          : "-"
    },

    {
      props: {
        textCenter: true
      },
      customField: (data) => (
        <>
          {access.canCreate() && (
            <ButtonCreate tooltip={false} text="PENSIUN" onClick={() => onCreateButtonClickHandler(data)} />
          )}
        </>
      )
    }
  ];

  return (
    <>
      <ListLayout
        customLeftTopSection={() => (
          <>
            <div className="d-flex flex-column flex-sm-row justify-content-center justify-content-md-start align-items-center gap-2 mb-4">
              <Formik
                initialValues={{
                  periodeGajianId: filter?.data?.periodeGajianId
                }}
                onSubmit={(values) => {
                  setFilter({
                    show: false,
                    active: Boolean(Object.values(values).find((val) => val)),
                    data: values
                  });
                }}
              >
                {({ handleSubmit, values }) => (
                  <>
                    <ModalFilterBody />
                    <ButtonCreate tooltip={false} className="mt-3" text="CARI" onClick={() => handleSubmit(values)} />
                  </>
                )}
              </Formik>
            </div>
          </>
        )}
        customRightTopSection={() => (
          <div className="text-end mb-3">
            <ButtonBack
              tooltip={false}
              onClick={() => navigate("/operasional-sdm/transaksi/realisasi-pekerja-pensiun")}
            />
          </div>
        )}
        loading={getPerhitunganUangPhkList?.isFetching}
        error={getPerhitunganUangPhkList?.isError}
        dataTotal={getPerhitunganUangPhkList?.data?.total}
        dataLength={pagination.dataLength}
        pageCurrent={pagination.pageCurrent}
        pageTotal={getPerhitunganUangPhkList?.data?.last_page}
        onSearchChange={debounce(searchHandler, 1500)}
        onDataLengthChange={onPaginationDataLengthChange}
        onPaginationChange={onPaginationChange}
        data={getPerhitunganUangPhkList?.data}
        tableHeader={TABLE_HEADER}
        tableBody={TABLE_BODY}
      />
    </>
  );
};
