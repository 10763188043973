import { Services } from "services";
import { fetchDropdownMapper } from "utilities";

const fetchDropdown = fetchDropdownMapper(Services);

class RekapIzinApi {
  async getList(params) {
    const fetch = await Services.get("/laporan/rekap-ijin", { params });
    return fetch?.data?.data;
  }
  async getPeriodeIjin() {
    const getPeriodeDropdown = await fetchDropdown("/master/periode-perencanaan/dropdown", {
      value: "master_periode_perencanaan_id",
      label: "periode_perencanaan"
    });
    return [...getPeriodeDropdown];
  }
  async getUnitOrganisasi() {
    const getUnit = await fetchDropdown("/master/unit-organisasi/dropdown", {
      value: "master_unit_organisasi_id",
      label: "master_unit_organisasi_nama"
    });

    return [{ value: undefined, label: "Semua" }, ...getUnit];
  }
  async getExport(params) {
    const fetch = await Services.get("/laporan/rekap-ijin/export", { params });
    return fetch?.data?.data;
  }
}
export default new RekapIzinApi();
