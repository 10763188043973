import React, { Fragment, useState, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import JWT from "jwt-decode";
// import { BaseLayout } from "layouts";
import { useAuth } from "hooks";
import { ContentLayout, DataStatus, PageLoader } from "components";
import { Login } from "pages";
import CONFIG from "config";
import { CustomLayout } from "../components/CustomLayout/CustomLayout";
import { Services } from "services";

const BaseRoutes = () => {
  const auth = useAuth();
  const { APP_NAME, MODUL, UNIT, ICON, LOGO, PAGES } = CONFIG;
  const [loading, setLoading] = useState(true);
  const [dataSidebar, setDataSidebar] = useState([]);
  const [dataRoutes, setDataRoutes] = useState([]);
  const [parseAccessRights, setParseAccessRights] = useState([]);

  const getReadAccess = (menu = "") => {
    const parseMenu = menu.toUpperCase();
    const getAcccess = parseAccessRights?.find((val) => val?.access === parseMenu);

    return getAcccess?.canRead;
  };

  /**
   * mapPages:
   * Digunakan untuk mapping dan mencocokan data routes dan sidebar dari backend
   */
  const mapPages = (data) => {
    const mapObj = (element) => {
      return {
        name: element?.name ?? "",
        alias: element?.alias ?? undefined,
        icon: element?.icon ?? undefined,
        child: element?.child
          ? element.child.map((val) => ({
            ...val,
            read: true
          }))
          : undefined,
        component: element.alwaysShow || getReadAccess(element?.access) ? element?.component ?? undefined : undefined,
        read: element.subMenu || element.alwaysShow ? true : getReadAccess(element?.access)
      };
    };

    let routes = [];
    let sidebar = data?.map((parent) => {
      let parentObj = new Object(mapObj(parent));
      parentObj["link"] = `/${parent?.link ?? "404"}`;
      routes.push(parentObj);

      if (Boolean(parent?.subMenu)) {
        const checkChild = parent.subMenu?.map((child) => {
          let childObj = new Object(mapObj(child));
          childObj["link"] = `/${parent?.link ?? "404"}/${child?.link ?? "404"}`;
          routes.push(childObj);

          if (Boolean(child?.subMenu)) {
            const checkGrandChild = child.subMenu?.map((grandChild) => {
              let grandChildObj = new Object(mapObj(grandChild));
              grandChildObj["link"] = `/${parent?.link ?? "404"}/${child?.link ?? "404"}/${grandChild?.link ?? "404"}`;
              routes.push(grandChildObj);

              return grandChildObj;
            });

            childObj["subMenu"] = checkGrandChild;
          }

          return childObj;
        });

        parentObj["subMenu"] = checkChild;
      }

      return parentObj;
    });
    return {
      routes: () => routes.filter((val) => val.component),
      sidebar: () => sidebar
    };
  };

  /**
   * getAuthInfo:
   * Fetch data user yang login
   */
  const getAuthInfo = async () =>
    await new Promise(async (res, rej) => {
      const getToken = localStorage.getItem("access_token");
      const getHakAkses = await Services.get("/auth/hak-akses");

      if (getToken) {
        const token = JWT(getToken);
        const dataToken = {
          token: token,
          hak: getHakAkses?.data?.data
        };

        res(dataToken);
      }

      rej({ message: "Tidak ada token" });
    })
      .then((data) => {
        const newAccess =
          Object?.values(data.hak)?.map((val) => {
            const menu = val?.split("_");
            const name = menu?.[0]?.replaceAll("-", "_");
            const access = menu?.[1]?.split("");

            return {
              access: name?.toUpperCase(),
              canRead: access?.some((val) => val === "R"),
              canCreate: access?.some((val) => val === "C"),
              canUpdate: access?.some((val) => val === "U"),
              canDelete: access?.some((val) => val === "D"),
              canVerify: access?.some((val) => val === "V"),
              canApprove: access?.some((val) => val === "A")
            };
          }) ?? [];

        auth.setInfo({
          id: data.token.sub,
          name: data.token.nama,
          username: data.token.akun,
          isAdmin: data.token.is_superadmin,
          accessRigths: newAccess
        });

        setParseAccessRights(newAccess);
      })
      .catch(() => {
        auth.logout();
        localStorage.removeItem("auth_token");
        localStorage.removeItem("refresh_token");
      })
      .finally(() => setLoading(false));

  useEffect(() => {
    const check = mapPages(PAGES);

    setDataSidebar(check.sidebar());
    setDataRoutes(check.routes());
  }, [parseAccessRights]);

  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      getAuthInfo();
    } else {
      setLoading(false);
    }

    return () => {
      setLoading(true);
    };
  }, [auth.isAuthenticated()]);

  const RequireAuthRoute = ({ children }) => {
    let auth = useAuth();
    let location = useLocation();

    if (!auth.isAuthenticated()) return <Navigate to="/login" state={{ from: location }} replace />;

    return children;
  };

  if (loading) {
    return <PageLoader />;
  }

  return (
    <Routes>
      {dataRoutes.map((value, index) => {
        const { link, component: Component, child } = value;

        return (
          <Fragment key={index}>
            <Route
              key={index}
              path={link}
              element={
                <RequireAuthRoute>
                  <CustomLayout
                    withLandingPage={false}
                    APP_NAME={APP_NAME}
                    MODUL={MODUL}
                    UNIT={UNIT}
                    ICON={ICON}
                    LOGO={LOGO}
                    SIDEBAR={dataSidebar}
                    USER_HAK={auth.getAccessRights()}
                    children={Component}
                  />
                </RequireAuthRoute>
              }
            />
            {child &&
              child.map((child, childIndex) => {
                const { link: childLink, component: ChildComponent } = child;

                return (
                  <Route
                    key={childIndex}
                    path={link + childLink}
                    element={
                      <RequireAuthRoute>
                        <CustomLayout
                          withLandingPage={false}
                          APP_NAME={APP_NAME}
                          MODUL={MODUL}
                          UNIT={UNIT}
                          ICON={ICON}
                          LOGO={LOGO}
                          SIDEBAR={dataSidebar}
                          USER_HAK={auth.getAccessRights()}
                          children={ChildComponent}
                        />
                      </RequireAuthRoute>
                    }
                  />
                );
              })}
          </Fragment>
        );
      })}
      <Route path="/login" element={<Login />} />
      <Route
        path="*"
        element={
          <RequireAuthRoute>
            <CustomLayout
              withLandingPage={false}
              APP_NAME={APP_NAME}
              MODUL={MODUL}
              UNIT={UNIT}
              ICON={ICON}
              LOGO={LOGO}
              SIDEBAR={dataSidebar}
              USER_HAK={auth.getAccessRights()}
              children={
                <ContentLayout title="Halaman Tidak Tersedia">
                  <DataStatus text="404 - Halaman Tidak Tersedia" />
                </ContentLayout>
              }
            />
          </RequireAuthRoute>
        }
      />
    </Routes>
  );
};

export default BaseRoutes;
