import { Services } from "services";

class PenugasanKhususApi {
  async getList(params) {
    const fetch = await Services.get("/master/penugasan-khusus", { params });
    return fetch?.data?.data;
  }

  async getDetail(id) {
    const fetch = await Services.get("/master/penugasan-khusus/" + id);
    return fetch?.data?.data;
  }

  async getExport(params) {
    const fetch = await Services.get("/master/penugasan-khusus/export?to=", { params });
    return fetch?.data?.data;
  }

  create(data) {
    return Services.post("/master/penugasan-khusus", data);
  }

  update(data, id) {
    return Services.put("/master/penugasan-khusus/" + id, data);
  }

  delete(id) {
    return Services.delete("/master/penugasan-khusus/" + id);
  }
}

export default new PenugasanKhususApi();
