import { useFormikContext } from "formik";
import { InfoItemVertical, TextArea } from "components";

const DetailTabFormula = () => {
  const { values } = useFormikContext();

  return (
    <>
      <InfoItemVertical label="Formula" text={[values.formula]} />
    </>
  );
};

const FormTabFormula = () => {
  const { values, errors, touched, handleChange } = useFormikContext();
  return (
    <TextArea
      label="Formula"
      name="formula"
      placeholder="Masukkan formula"
      value={values.formula}
      onChange={handleChange}
      error={Boolean(errors.formula && touched.formula)}
      errorText={Boolean(errors.formula && touched.formula) && errors.formula}
    />
  );
};

export const TabFormula = ({ action }) => {
  return <>{action === "READ" ? <DetailTabFormula /> : <FormTabFormula />}</>;
};
