import React from "react";
import { Formik } from "formik";
import { ButtonCreate, InfoItemHorizontal, TextArea } from "components";
import { formSubmitMapper, formValidationSchema } from "../../__UraianJabatanUtils__";
import { useModalConfirm } from "hooks";
import { errorSubmitMapper } from "utilities";
import UraianJabatanApi from "../../__UraianJabatanApi__";
import { useParams } from "react-router-dom";
import { useMutation } from "react-query";
import { useFormikContext } from "formik";

const DetailTujuanJabatan = () => {
  const { values } = useFormikContext();

  return (
    <>
      <InfoItemHorizontal label="Tujuan Jabatan" text={values?.tujuanJabatan} />
    </>
  );
};

const FormTujuanJabatan = () => {
  const { values, handleChange, errors, touched } = useFormikContext();

  return (
    <TextArea
      label="Tujuan Jabatan*"
      rows="4"
      name="tujuanJabatan"
      value={values?.tujuanJabatan}
      onChange={handleChange}
      error={Boolean(errors.tujuanJabatan && touched.tujuanJabatan)}
      errorText={Boolean(errors.tujuanJabatan && touched.tujuanJabatan) && errors.tujuanJabatan}
    />
  );
};

export const TabTujuanJabatan = ({ action, data }) => {
  const modalConfirm = useModalConfirm();
  const parentFormik = useFormikContext();
  const { id } = useParams();

  const updateTujuanJabatan = useMutation((data) => UraianJabatanApi.update(data, id), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "update", customTextInfoTitle: "Data Berhasil Disimpan" });
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "update", ...errorSubmitMapper(err) })
  });

  const submitValidationHandler = (errors) =>
    new Promise((resolve, reject) => {
      const getError = Object.values(errors);

      if (getError.length > 0) {
        reject(getError);
      } else {
        resolve();
      }
    });

  const preSubmitHandler = (values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting) => {
    setSubmitting(true);
    validateForm().then(async (err) => {
      setErrors(err);
      setTouched(err);

      await submitValidationHandler(err, values)
        .then(() => handleSubmit())
        .catch((err) =>
          modalConfirm.trigger({
            type: "error",
            title: "Data Tidak Lengkap",
            data: err,
            onHide: () => modalConfirm.close()
          })
        )
        .finally(() => {
          setSubmitting(false);
        });
    });
  };

  const formSubmitHandler = (values, action) =>
    modalConfirm.trigger({
      type: "custom",
      onHide: modalConfirm.close,
      title: "Konfirmasi",
      customButtonShow: true,
      customButtonText: "Simpan",
      customButtonVariant: "primary",
      customTextHeader: "Apakah anda yakin menyimpan data ini?",
      onSubmit: () => {
        modalConfirm.loading();

        updateTujuanJabatan.mutate(formSubmitMapper().tujuanJabatan(values));
      },
      component: (
        <Formik initialValues={values}>
          <DetailTujuanJabatan />
        </Formik>
      )
    });

  return (
    <Formik
      enableReinitialize
      initialValues={parentFormik?.values?.tujuanJabatan}
      validationSchema={formValidationSchema?.tujuanJabatan}
      onSubmit={(values) => formSubmitHandler(values, action)}
    >
      {({ values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting }) => {
        return (
          <form>
            {action === "READ" ? <DetailTujuanJabatan /> : <FormTujuanJabatan />}

            {!Boolean(action === "READ") && (
              <div className="d-flex justify-content-end mb-2 mt-3">
                <ButtonCreate
                  text="Simpan"
                  onClick={() =>
                    preSubmitHandler(values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting)
                  }
                />
              </div>
            )}
          </form>
        );
      }}
    </Formik>
  );
};
