import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { Formik } from "formik";
import { ContentLayout } from "layouts";
import { ButtonBack, DataStatus } from "components";
import { errorFetchingPageMapper } from "utilities";
import { Content } from "./__EvaluasiJabatanComps__";
import { formInitialValues } from "./__EvaluasiJabatanUtils__";
import EvaluasiJabatanApi from "./__EvaluasiJabatanApi__";

export const EvaluasiJabatanDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const getEvaluasiJabatan = useQuery(["evaluasi-jabatan", id], () => EvaluasiJabatanApi.getSingle(id));

  return (
    <div>
      <ContentLayout showBreadcrumb>
        <div className="text-end mb-3">
          <ButtonBack tooltip={false} onClick={() => navigate(-1)} />
        </div>
        {getEvaluasiJabatan.isFetching || getEvaluasiJabatan.isError ? (
          <DataStatus
            loading={getEvaluasiJabatan.isFetching}
            text={errorFetchingPageMapper(getEvaluasiJabatan.error)}
          />
        ) : (
          <Formik initialValues={formInitialValues(getEvaluasiJabatan.data)}>
            {() => (
              <>
                <Content action="READ" />
              </>
            )}
          </Formik>
        )}
      </ContentLayout>
    </div>
  );
};
