import { Services } from "services";
import { fetchDropdownMapper } from "utilities";

const fetchDropdown = fetchDropdownMapper(Services);

class PayrollOverheadApi {
  async getList(params) {
    const fetch = await Services.get("/laporan/payroll-overhead", { params });
    return fetch?.data?.data;
  }

  //Halaman Depan
  async getPeriodePenggajianHalden() {
    const getPeriodePenggajian = await fetchDropdown("/utility/periode-tahun?feat=pekerja-payroll-bulanan", {
      value: "periode_tahun",
      label: "periode_tahun"
    });

    return getPeriodePenggajian;
  }
  async getTanggalAwalDanAkhir(params) {
    const fetch = await Services.get("/transaksi/pekerja-payroll-bulanan/dropdown-tanggal", { params });
    return fetch?.data?.data?.map((val) => ({
      value: val?.tgl_bulan_awal + " s/d " + val?.tgl_bulan_akhir,
      label: val?.tgl_bulan_awal + " s/d " + val?.tgl_bulan_akhir,
      tglAwal: val?.tgl_bulan_awal,
      tglAkhir: val?.tgl_bulan_akhir
    }));
  }

  async getExport(params) {
    const fetch = await Services.get("/laporan/payroll-overhead/export", { params });
    return fetch?.data?.data;
  }
}
export default new PayrollOverheadApi();
