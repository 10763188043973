import {
  ButtonDetail, ButtonFilter, InfoItemVertical, InputSearch, ModalFilter, rupiahConvert, Select, toastTrigger
} from "components";
import { Formik, useFormikContext } from "formik";
import { useAccessRights, useModalConfirm } from "hooks";
import { ListLayout } from "layouts";
import { debounce } from "lodash";
import { useState } from "react";
import { ButtonGroup } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { errorFetchingSelectMapper, errorFetchingTableMapper, exportHandler } from "utilities";
import KoreksiKerjaApi from "./__KoreksiKerjaApi__";
import { formValidationSchema } from "./__KoreksiKerjaUtils__";

const ModalFilterBody = () => {
  const { values, setValues, errors, touched } = useFormikContext();

  const getPeriodePenggajian = useQuery(["periode-rekap-order", "dropdown"], () =>
    KoreksiKerjaApi.getPeriodePenggajianHalden()
  );

  const getUnitOrganisasi = useQuery(
    ["unit-organisasi", "dropdown"],
    () => KoreksiKerjaApi.getUnitOrganisasiHalden(values?.idPeriode),
    {
      enabled: values?.idPeriode !== undefined
    }
  );

  const getTanggal = useQuery(
    ["tanggal-penggajian", "dropdown", values?.idPeriode],
    () =>
      KoreksiKerjaApi.getTanggalAwalDanAkhir({
        periode_tahun: values?.idPeriode
      }),
    {
      enabled: Boolean(values?.idPeriode)
    }
  );

  return (
    <>
      <Select
        label="Periode Penggajian"
        placeholder="Pilih periode penggajian"
        defaultValue={getPeriodePenggajian?.data?.find((val) => val.value === values.idPeriode)}
        loading={getPeriodePenggajian.isFetching}
        options={getPeriodePenggajian?.data ?? []}
        onChange={(val) => {
          setValues({
            ...values,
            idPeriode: val.value,
            namaPeriode: val.label
          });
        }}
        errorFetch={getPeriodePenggajian.isError}
        errorFetchText={errorFetchingSelectMapper(getPeriodePenggajian.error)}
        error={Boolean(errors.idPeriode && touched.idPeriode)}
        errorText={Boolean(errors.idPeriode && touched.idPeriode) && errors.idPeriode}
      />
      <Select
        disable={Boolean(!values?.idPeriode)}
        label="Tanggal Penggajian"
        placeholder="Pilih tanggal penggajian"
        defaultValue={getTanggal?.data?.find((item) => item.value === values?.tglId)}
        options={getTanggal?.data ?? []}
        onChange={(val) =>
          setValues({
            ...values,
            tglId: val.value,
            tglNama: val.label,
            tglAwal: val.tglAwal,
            tglAkhir: val.tglAkhir
          })
        }
        loading={getTanggal.isFetching}
        errorFetch={getTanggal.isError}
      />

      <Select
        disable={values.idPeriode === undefined}
        label="Unit Organisasi"
        placeholder="Pilih unit organisasi"
        loading={getUnitOrganisasi.isFetching}
        defaultValue={getUnitOrganisasi?.data?.find((val) => val.value === values.idUnitOrganisasi)}
        options={getUnitOrganisasi?.data ?? []}
        onChange={(val) => {
          setValues({
            ...values,
            idUnitOrganisasi: val.value
          });
        }}
        errorFetch={getUnitOrganisasi.isError}
        errorFetchText={errorFetchingSelectMapper(getUnitOrganisasi.error)}
        error={Boolean(errors.idUnitOrganisasi && touched.idUnitOrganisasi)}
        errorText={Boolean(errors.idUnitOrganisasi && touched.idUnitOrganisasi) && errors.idUnitOrganisasi}
      />
    </>
  );
};

export const KoreksiKerjaList = () => {
  const access = useAccessRights("KOREKSI_PEKERJA_BULANAN");
  const navigate = useNavigate();
  const [searchKey, setSearchKey] = useState(undefined);
  const rupiah = rupiahConvert();
  // const date = dateConvert();
  const modalConfirm = useModalConfirm();

  const searchHandler = (e) => {
    setSearchKey(e?.target?.value ? e.target.value : undefined);
  };

  const [pagination, setPagination] = useState({
    pageCurrent: 1,
    dataLength: 10
  });

  const [filter, setFilter] = useState({
    show: false,
    active: false,
    data: {
      namaKomponenPayroll: undefined,
      idPeriode: undefined,
      namaPeriode: undefined,
      idBulan: undefined,
      namaBulan: undefined,
      tanggalPeriode: undefined,
      tglAwal: undefined,
      tglAkhir: undefined
    }
  });

  // const [modal, setModal] = useState({
  //   show: false,
  //   action: "",
  //   data: {
  //     namaKomponenPayroll: undefined,
  //     namaPeriode: undefined,
  //     namaBulan: undefined,
  //     tanggalPeriode: undefined
  //   }
  // });

  const getList = useQuery(
    [
      "koreksi-kerja",
      "list",
      searchKey,
      pagination.pageCurrent,
      pagination.dataLength,
      filter?.data?.idPeriode,
      filter?.data?.idUnitOrganisasi,
      filter?.data?.tanggalPeriode
    ],
    () =>
      KoreksiKerjaApi.getList({
        search: searchKey,
        periode_tahun: filter?.data?.idPeriode,
        master_unit_organisasi_id: filter?.data?.idUnitOrganisasi,
        tgl_bulan_awal: filter?.data?.tglAwal,
        tgl_bulan_akhir: filter?.data?.tglAkhir,
        page: pagination.pageCurrent,
        limit: pagination.dataLength
      }),
    {
      enabled: filter?.data?.idPeriode !== undefined
    }
  );

  const getExportKoreksiKerja = useMutation(
    (data) =>
      KoreksiKerjaApi.getExport({
        periode_tahun: data?.idPeriode,
        master_unit_organisasi_id: data?.idUnitOrganisasi,
        tgl_bulan_awal: data?.tglAwal,
        tgl_bulan_akhir: data?.tglAkhir
      }),
    {
      onSuccess: (link) => {
        toastTrigger({ variant: "success", message: "Export berhasil" });
        exportHandler(link, "Data Koreksi Pekerja");
      },
      onError: () => {
        toastTrigger({ variant: "danger", message: "Export gagal" });
      }
    }
  );

  const onFilterResetHandler = (resetForm) => {
    setFilter({
      show: false,
      action: "",
      data: {
        namaKomponenPayroll: undefined,
        idPeriode: undefined,
        namaPeriode: undefined,
        idBulan: undefined,
        namaBulan: undefined,
        tanggalPeriode: undefined
      }
    });
    resetForm();
  };

  const onFilterHideHandler = () =>
    setFilter({
      ...filter,
      show: false,
      action: ""
    });

  const onFilterHandler = (values) => {
    setFilter({
      show: false,
      active: Boolean(Object.values(values).find((val) => val)),
      data: values
    });
  };

  const submitValidationHandler = (errors, values) =>
    new Promise((resolve, reject) => {
      const getError = Object.values(errors);

      if (getError.length > 0) {
        reject(getError);
      } else {
        resolve();
      }
    });

  const preSubmitHandler = (values, validateForm, setTouched, setErrors, setSubmitting) => {
    setSubmitting(true);
    validateForm().then(async (err) => {
      setErrors(err);
      setTouched(err);
      await submitValidationHandler(err, values)
        .then(() => onFilterHandler(values))
        .catch((err) =>
          modalConfirm.trigger({
            type: "error",
            title: "Data Tidak Lengkap",
            data: err,
            onHide: () => modalConfirm.close()
          })
        )
        .finally(() => {
          setSubmitting(false);
        });
    });
  };

  const onDetailButtonClickHandler = (data) => {
    navigate("detail/" + data.pekerja_id, {
      state: {
        periode_gajian_id: filter?.data?.idPeriode,
        master_unit_organisasi_id: filter?.data?.idUnitOrganisasi,
        tglAwal: filter?.data?.tglAwal,
        tglAkhir: filter?.data?.tglAkhir
      }
    });
  };

  const onPaginationChange = (data) => setPagination({ ...pagination, pageCurrent: data });

  const onPaginationDataLengthChange = (data) => setPagination({ ...pagination, dataLength: data });

  const onCreateButtonClickHandler = () => navigate("tambah");

  const TABLE_HEADER = [
    {
      text: "NIK"
    },
    {
      text: "Pekerja"
    },
    {
      text: "Jabatan"
    },
    {
      text: "Unit Organisasi"
    },
    {
      text: "Total Koreksi"
    },
    {
      text: "Aksi",
      props: {
        width: 50,
        fixed: true
      }
    }
  ];

  const TABLE_BODY = [
    {
      field: "nip"
    },
    {
      field: "pekerja_nama"
    },
    {
      field: "master_jabatan_nama"
    },
    {
      field: "master_unit_organisasi_nama"
    },
    {
      props: { textRight: true },
      customField: (data) => (data.total_koreksi ? rupiah.getWithComa(Number(data?.total_koreksi)) + "" : "-")
    },
    {
      props: { fixed: true },
      customField: (data) => (
        <ButtonGroup size="sm">
          {/* {access.canRead() && <ButtonDetail icon noText onClick={() => onDetailButtonClickHandler(data)} />} */}
          <ButtonDetail icon noText onClick={() => onDetailButtonClickHandler(data)} />
        </ButtonGroup>
      )
    }
  ];

  return (
    <>
      <ListLayout
        customLeftTopSection={() => (
          <div className="align-items-end">
            <div className="d-flex">
              <div className="me-2">
                <InputSearch onChange={debounce(searchHandler, 1500)} />
              </div>
              <div>
                <ButtonFilter
                  active={filter?.active}
                  className="text-nowrap"
                  onClick={() => setFilter({ ...filter, show: true })}
                />
              </div>
            </div>
            <div className="d-flex mt-3">
              <div className="me-2">
                <InfoItemVertical className="me-2" label="Komponen Payroll " text={"Koreksi Pekerja"} />
              </div>
              <div className="me-2">
                <InfoItemVertical
                  className="me-2"
                  label="Periode"
                  text={filter?.data?.namaPeriode ?? "-"}
                />
              </div>
            </div>
          </div>
        )}
        showExportExcelButton={Boolean(filter?.data?.idPeriode && filter?.data?.idUnitOrganisasi)}
        onExportExcelButtonClick={() => getExportKoreksiKerja.mutate(filter?.data)}
        loadingExportButton={getExportKoreksiKerja.isLoading}
        showrighttopbutton={access.canCreate() ? true : false}
        loading={getList.isFetching}
        error={getList.isError}
        errorText={errorFetchingTableMapper(getList.error)}
        dataTotal={getList?.data?.total}
        dataLength={pagination.dataLength}
        pageCurrent={pagination.pageCurrent}
        pageTotal={getList?.data?.last_page}
        onCreateButtonClick={onCreateButtonClickHandler}
        onDataLengthChange={onPaginationDataLengthChange}
        onPaginationChange={onPaginationChange}
        data={getList?.data?.data}
        tableHeader={TABLE_HEADER}
        tableBody={TABLE_BODY}
      />

      {/* ModalFilter */}
      <Formik initialValues={filter?.data} validationSchema={formValidationSchema.FormSearch}>
        {({ resetForm, values, validateForm, setTouched, setErrors, setSubmitting }) => (
          <ModalFilter
            show={filter.show}
            onReset={() => onFilterResetHandler(resetForm)}
            onHide={() => onFilterHideHandler()}
            onSubmit={() => {
              preSubmitHandler(values, validateForm, setTouched, setErrors, setSubmitting);
            }}
          >
            <ModalFilterBody />
          </ModalFilter>
        )}
      </Formik>
    </>
  );
};
