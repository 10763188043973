import { dateConvert } from "components";

const date = dateConvert();

export const formInitialValues = (data = {}, filter) => ({
    dataFormSetup: {
        periodeGajianId: data?.data?.info?.periode_tahun ?? filter?.periodeGajianId,
        periodePerencanaan: data?.data?.info?.periode_tahun ?? new Date().getFullYear(),
        unitOrganisasiId: filter?.unitOrganisasiId ?? "",
        unitOrganisasiNama: filter?.unitOrganisasiNama ?? "",
        nominal: false,
        detail:
            data?.data?.map((val) => ({
                checked: false,
                periodeGajianId: val?.periode_tahun ?? "",
                tglSetup: filter?.tglSetup ?? date.getDashYMD(new Date()),
                tglAwal: filter?.tglAwal,
                tglAkhir: filter?.tglAkhir,
                nik: val?.nip ?? "",
                pekerjaId: val?.pekerja_id ?? "",
                pekerjaNama: val?.pekerja_nama ?? "",
                unitOrganisasiId: val?.master_unit_organisasi_id ?? "",
                unitOrganisasiNama: val?.master_unit_organisasi_nama ?? "",
                jabatanId: val?.master_jabatan_id ?? "",
                jabatanNama: val?.master_jabatan_nama ?? "",
                statusPekerja: val?.status_pekerja ?? "",
                status: val?.status ?? "",
                nominal: val?.upah_bpjs ?? ""
            })) ?? []
    },

    detailData: {
        id: data?.pekerja_upah_bpjs_id ?? "",
        komponenPayroll: data?.komponen_payroll ?? "",
        periodeGajianId: data?.periode_tahun ?? "",
        periodePerencanaan: data?.periode_tahun ?? "",
        tglSetup: data?.tgl_setup ?? "",
        nik: data?.nip ?? "",
        pekerjaId: data?.pekerja_id ?? "",
        pekerjaNama: data?.pekerja_nama ?? "",
        unitOrganisasiId: data?.master_unit_organisasi_id ?? "",
        unitOrganisasiNama: data?.master_unit_organisasi_nama ?? "",
        jabatanId: data?.master_jabatan_id ?? "",
        jabatanNama: data?.master_jabatan_nama ?? "",
        statusPekerja: data?.status_pekerja ?? "",
        status: data?.status ?? "",
        nominal: data?.upah_bpjs ?? "",
        kesehatanNominal: data?.upah_bpjs_kesehatan ?? "",
        tenagaKerjaNominal: data?.upah_bpjs_tenaga_kerja ?? "",
        bpuNominal: data?.upah_bpjs_bpu ?? ""
    }
});
