import React from "react";
import { useQuery } from "react-query";
import { useFormikContext } from "formik";
import {
    TableList,
    InfoItemVertical,
    numberConvert,
    Select,
    DatePicker,
    ButtonCreate,
    InputCurrency,
    Input,
    dateConvert
} from "components";
import { useAccessRights } from "hooks";
import { Row, Col, Card, ButtonGroup } from "react-bootstrap";
import TunjanganUpahHarianApi from "../__TunjanganUpahHarianApi__";
import { useNavigate } from "react-router-dom";

const SectionDataSetup = ({ onSearchButtonClick, onResetClickButton }) => {
    const { values, setValues, errors, touched, handleChange } = useFormikContext();

    const defaultSearch = {
        periodeGajianId: values?.periodeGajianId,
        periodePerencanaan: values?.periodePerencanaan,
        bulan: values?.bulan,
        unitOrganisasiId: values?.unitOrganisasiId,
        unitOrganisasiNama: values?.unitOrganisasiNama,
        pekerjaId: values?.pekerjaId,
        pekerjaNama: values?.pekerjaNama,
        tglAwal: values?.tglAwal,
        tglAkhir: values?.tglAkhir,
        tglSetup: values?.tglSetup,
        statusPekerjaId: 1
    };

    // const getUnitOrganisasi = useQuery(["unit-organisasi", "dropdown"], () =>
    //     TunjanganUpahHarianApi.getUnitOrganisasi()
    // );
    const getStatusPekerja = useQuery(["status-pekerja", "dropdown"], () =>
        TunjanganUpahHarianApi.getStatusPekerja()
    );
    const getBulan = useQuery(["bulan", "dropdown"], () => TunjanganUpahHarianApi.getBulan());

    return (
        <>
            <Input name="periodeGajianId" label="Periode Penggajian" value={values?.periodeGajianId} onChange={handleChange} />
            <Select
                label="Bulan Penggajian"
                disable={Boolean(!values?.periodeGajianId)}
                options={getBulan?.data ?? []}
                placeholder="Pilih salah satu..."
                defaultValue={values?.bulan ? getBulan?.data?.find((item) => item.value === values?.bulan) : null}
                onChange={(val) => {
                    const tglNewStart = val.value <= 1 ?
                        `${values?.periodePerencanaan - 1}-12-26`
                        : `${values?.periodePerencanaan}-${val?.value < 10 ? `0${val?.value - 1}` : val?.value - 1}-26`;

                    const tglNewEnd = `${values?.periodePerencanaan}-${val?.value < 10 ? `0${val?.value}` : val?.value}-25`;

                    setValues({
                        ...values,
                        bulan: val.value,
                        bulanNama: val.label,
                        tglAwal: tglNewStart,
                        tglAkhir: tglNewEnd
                    });
                }}
                loading={getBulan?.isFetching}
                errorFetch={getBulan.isError}
                error={Boolean((errors.bulan && touched.bulan) || errors.validateDate)}
                errorText={Boolean(errors.bulan && touched.bulan) && errors.bulan ? errors.bulan : errors.validateDate}
            />

            <div>
                <div style={{ fontSize: 14 }}>Tanggal Penggajian</div>
                <div className="d-flex align-items-center justify-content-between">
                    <div style={{ width: "44%" }}>
                        <DatePicker
                            disabled
                            key={`${values?.periodeGajianId} ${values?.bulan}`}
                            placeholderText="dd/mm/yyyy"
                            selected={values?.periodeGajianId && values?.bulan ? values?.tglAwal : undefined}
                            disable={Boolean(!values?.bulan)}
                            onChange={(val) =>
                                setValues({
                                    ...values,
                                    tglAwal: val,
                                    bulan: new Date(val).getMonth() + 1,
                                    tglAkhir: new Date(new Date(val).setDate(new Date(val).getDate() + 30)),
                                })
                            }
                            error={Boolean(errors.tglAwal && touched.tglAwal) || errors.validateDate}
                            errorText={
                                Boolean(errors.tglAwal && touched.tglAwal) && errors.tglAwal ? errors.tglAwal : errors.validateDate
                            }
                        />
                    </div>
                    <small>s/d</small>
                    <div style={{ width: "44%" }}>
                        <DatePicker
                            disabled
                            key={`${values?.periodeGajianId} ${values?.bulan}`}
                            placeholderText="dd/mm/yyyy"
                            selected={values?.periodeGajianId && values?.bulan ? values?.tglAkhir : undefined}
                            disable={Boolean(!values?.bulan)}
                            onChange={(val) =>
                                setValues({
                                    ...values,
                                    tglAkhir: val
                                })
                            }
                            error={Boolean(errors.tglAkhir && touched.tglAkhir)}
                            errorText={Boolean(errors.tglAkhir && touched.tglAkhir) && errors.tglAkhir}
                        />
                    </div>
                </div>
            </div>
            <Select
                disable
                label="Status Pekerja"
                options={getStatusPekerja?.data ?? []}
                placeholder="Pilih salah satu..."
                defaultValue={values?.statusPekerjaId ? getStatusPekerja?.data?.find((item) => item.value === values?.statusPekerjaId) : null}
                onChange={(val) => {
                    setValues({
                        ...values,
                        statusPekerjaId: val.value,
                        statusPekerja: val.label,
                    });
                }}
                loading={getStatusPekerja?.isFetching}
                errorFetch={getStatusPekerja.isError}
                error={Boolean(errors.statusPekerja && touched.statusPekerja)}
                errorText={Boolean(errors.statusPekerja && touched.statusPekerja) && errors.statusPekerja}
            />
            <DatePicker
                disabled
                label="Tanggal Setup"
                placeholderText="dd-mm-yyyy"
                selected={values?.tglSetup}
                onChange={(val) =>
                    setValues({
                        ...values,
                        tglSetup: val
                    })
                }
                error={Boolean(errors.tglSetup && touched.tglSetup)}
                errorText={Boolean(errors.tglSetup && touched.tglSetup) && errors.tglSetup}
            />

            <div className="d-flex justify-content-end">
                <div>
                    {/* <ButtonCancel text="RESET" className="mb-3 me-2" tooltip={false} onClick={() => onResetClickButton()} /> */}
                    <ButtonCreate
                        disabled={Boolean(
                            !values?.periodeGajianId || !values?.bulan
                        )}
                        text="CARI"
                        className="mb-3"
                        tooltip={false}
                        onClick={() =>
                            onSearchButtonClick({
                                active: true,
                                data: defaultSearch
                            })
                        }
                    />
                </div>
            </div>
        </>
    );
};

const TabelDataPekerja = ({ action }) => {
    const { values, errors, touched } = useFormikContext();
    const number = numberConvert();
    const date = dateConvert();

    // const hourToMinute = () => 4 * 60;

    // const setNewValues = ({ index, propname, val }) => {
    //     const detailTemp = [...values?.detail];
    //     const updatedDetail = detailTemp[index];
    //     updatedDetail[propname] = val;

    //     setValues({ ...values, detail: detailTemp });
    // };

    const getError = (index, propname) => {
        return Boolean(errors?.detail?.[index]?.[propname] && touched?.detail?.[index]?.[propname]);
    };

    const getErrorText = (index, propname) => {
        return (
            Boolean(errors?.detail?.[index]?.[propname] && touched?.detail?.[index]?.[propname]) &&
            errors?.detail?.[index]?.[propname]
        );
    };

    // const arrayTotalUangLembur = values?.detail?.reduce((prev, curr) => {
    //     let hasil = 0;
    //     hasil = hasil + curr?.nominal;
    //     return [...prev, hasil];
    // }, []);

    // const calculationFunction = (arr) => {
    //     let sum = 0;
    //     arr.map((e) => (sum += e));
    //     return sum;
    // };

    return (
        <>
            <Row className="mb-4">
                <Col md={6}>
                    <InfoItemVertical label="Periode Penggajian" text={values.periodePerencanaan} />
                    <InfoItemVertical
                        label="Bulan Penggajian"
                        text={
                            (values?.tglAwal ? date.getSlashDMY(new Date(values?.tglAwal)) : "-") +
                            " s/d " +
                            (values?.tglAkhir ? date.getSlashDMY(new Date(values?.tglAkhir)) : "-")
                        }
                    />
                    <InfoItemVertical label="NIK" text={values.nik} />
                </Col>
                <Col md={6}>
                    <InfoItemVertical label="Pekerja" text={values.pekerjaNama} />
                    <InfoItemVertical label="Unit Organisasi" text={values.unitOrganisasiNama} />
                    <InfoItemVertical label="Jabatan" text={values.jabatanNama} />
                </Col>
                {/* <Col md={6}>
                    <InputCurrency
                        id="upahHarian"
                        placeholder="otomatis terisi"
                        disabled
                        // disabled={Boolean(values?.detail.length === 0 || !values?.pekerjaId || action === "READ")}
                        label="Upah Harian"
                        value={number.getWithComa(values?.upahHarian, { minimumFractionDigits: 2 })}
                        onChange={(val) => {
                            if (values?.detail.length !== 0) {
                                const arr = values?.detail;
                                const newArr = arr.reduce((accumulator, obj) => {
                                    const valNominal = obj.jamKerjaMenit >= hourToMinute() ? val : 0;
                                    return [
                                        ...accumulator,
                                        {
                                            upahHarian: valNominal,
                                            tglPresensi: obj.tglPresensi,
                                            jamIn: obj.jamIn,
                                            jamOut: obj.jamOut,
                                            jamInFormat: obj.jamInFormat,
                                            jamOutFormat: obj.jamOutFormat,
                                            totalWaktuKerjaFormat: obj.totalWaktuKerjaFormat
                                        }
                                    ];
                                }, []);

                                setValues({
                                    ...values,
                                    upahHarian: val,
                                    detail: newArr
                                });
                            }
                        }}
                        error={Boolean(errors.upahHarian && touched.upahHarian)}
                        errorText={Boolean(errors.upahHarian && touched.upahHarian) && errors.upahHarian}
                    />
                </Col> */}
            </Row>

            <TableList
                maxHeight={550}
                tableHeader={[
                    { text: "Tanggal Presensi" },
                    { text: "Jam In" },
                    { text: "Jam Out" },
                    { text: "Total Waktu Kerja" },
                    { text: "Upah Harian" }
                ]}
                tableBody={[
                    {
                        customField: (val) => (val?.tglPresensi ? date.getSlashDMY(new Date(val?.tglPresensi)) : "-")
                    },
                    {
                        field: "jamInFormat"
                    },
                    {
                        field: "jamOutFormat"
                    },
                    {
                        field: "totalWaktuKerjaFormat"
                    },
                    {
                        props: { textRight: true },
                        customField: (val, index) =>
                            action === "READ" ? (
                                "Rp" + (val?.upahHarian ? number.getWithComa(val?.upahHarian, { minimumFractionDigits: 2 }) : "Rp0,00")
                            ) : (
                                <InputCurrency
                                    disabled
                                    name="upahHarian"
                                    className="text-end"
                                    value={"Rp" + number.getWithComa(val?.upahHarian, { minimumFractionDigits: 2 })}
                                    error={getError(index, "upahHarian")}
                                    errorText={getErrorText(index, "upahHarian")}
                                />
                            )
                    }
                ]}
                data={values?.detail}
                tableFooter={[
                    {
                        text: "Total Upah Harian",
                        props: { colSpan: 5 }
                    },
                    {
                        props: { textRight: true },
                        text: values?.totalUpahHarian ? "Rp" + number.getWithComa(values?.totalUpahHarian, { minimumFractionDigits: 2 })
                            : "Rp0,00"
                    }
                ]}
            />
        </>
    );
};

const TabelPostList = ({ action, setCreateViewState, onPostButtonClickHandler, filterData }) => {
    const { values } = useFormikContext();
    const access = useAccessRights("PEKERJA_LEMBUR_PERJAM_BULANAN");
    const navigate = useNavigate();

    return (
        <>
            <TableList
                maxHeight={550}
                tableHeader={[
                    { text: "NIK" },
                    { text: "Pekerja" },
                    { text: "Unit Organisasi" },
                    { text: "Jabatan" },
                    { text: "Aksi" }
                ]}
                tableBody={[
                    {
                        field: "nik"
                    },
                    {
                        field: "pekerjaNama"
                    },
                    {
                        field: "unitOrganisasiNama"
                    },
                    {
                        field: "jabatanNama"
                    },
                    {
                        props: {
                            width: 50,
                            fixed: true,
                            textCenter: true
                        },
                        customField: (val) => (
                            <ButtonGroup size="sm">
                                {access.canCreate() && (
                                    <ButtonCreate tooltip={false} disabled={val?.hasPosted === true ? true : false} text="POST" onClick={() => {
                                        navigate("/payroll/transaksi/tunjangan-upah-harian/tambah",
                                            {
                                                state: {
                                                    periodeGajianId: val?.periodeGajianId,
                                                    unitOrganisasiId: val?.unitOrganisasiId,
                                                    unitOrganisasiNama: val?.unitOrganisasiNama,
                                                    pekerjaId: val?.pekerjaId,
                                                    pekerjaNama: val?.pekerjaNama,
                                                    jabatanNama: val?.jabatanNama,
                                                    nik: val?.nik,
                                                    tglAwal: val?.tglAwal,
                                                    tglAkhir: val?.tglAkhir
                                                }
                                            })
                                        // setCreateViewState("CREATE");
                                        // onPostButtonClickHandler({
                                        //   active: true,
                                        //   data: {
                                        //     ...filterData,
                                        //     unitOrganisasiId: val?.unitOrganisasiId,
                                        //     unitOrganisasiNama: val?.unitOrganisasiNama,
                                        //     pekerjaId: val?.pekerjaId,
                                        //     pekerjaNama: val?.pekerjaNama,
                                        //     jabatanNama: val?.jabatanNama,
                                        //     nik: val?.nik
                                        //   }
                                        // })
                                    }} />
                                )}
                            </ButtonGroup>
                        )
                    }
                ]}
                data={values?.detail}
            />
        </>
    )
}


export const Content = ({ action, onSearchButtonClick, onResetClickButton, setCreateViewState, filterData }) => {
    const { values, errors, touched, setValues } = useFormikContext();
    const date = dateConvert();
    const number = numberConvert();

    if (action === "UPDATE") {
        return (<>
            <Input disabled label="Komponen Payroll" value={values?.komponenPayroll ? values?.komponenPayroll : "-"} />
            <Input disabled label="Periode Gajian" value={values?.periodeGajianId} />
            <Input disabled label="Bulan" value={
                (values?.tglAwal ? date.getSlashDMY(new Date(values?.tglAwal)) : "-")
                + " s/d " +
                (values?.tglAkhir ? date.getSlashDMY(new Date(values?.tglAkhir)) : "-")
            } />
            <Input disabled label="NIK" value={values?.nik} />
            <Input disabled label="Pekerja" value={values?.pekerjaNama} />
            <Input disabled label="Unit Organisasi" value={values?.unitOrganisasiNama} />
            <Input disabled label="Jabatan" value={values?.jabatanNama ? values?.jabatanNama : "-"} />
            <InputCurrency
                label="Total Upah Harian"
                value={values?.totalUpahHarian ?
                    number.getWithComa(values?.totalUpahHarian, { minimumFractionDigits: 2 }) : "Rp-"}
                onChange={(val) => setValues({
                    ...values,
                    totalUpahHarian: val
                })}
                error={Boolean(errors.totalUpahHarian && touched.totalUpahHarian)}
                errorText={Boolean(errors.totalUpahHarian && touched.totalUpahHarian) && errors.totalUpahHarian}
            />

        </>)
    }

    return (
        <>
            <Row>
                {action === "POST" ? (
                    <>
                        <Col md={4} className="mb-4">
                            <Card style={{ height: "100%" }}>
                                <Card.Header>
                                    <b>Setup Data</b>
                                </Card.Header>
                                <Card.Body>
                                    <SectionDataSetup onSearchButtonClick={onSearchButtonClick} onResetClickButton={onResetClickButton} />
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Header>
                                    <b>List Pekerja</b>
                                </Card.Header>
                                <Card.Body>
                                    <TabelPostList action={action} setCreateViewState={setCreateViewState} onPostButtonClickHandler={onSearchButtonClick} filterData={filterData} />
                                </Card.Body>
                            </Card>
                        </Col>
                    </>
                ) : (
                    <Col md={12}>
                        <Card style={{ height: "100%" }}>
                            <Card.Header>
                                <b>Data Pekerja</b>
                            </Card.Header>
                            <Card.Body>
                                <TabelDataPekerja action={action} />
                            </Card.Body>
                        </Card>
                    </Col>
                )}
            </Row>
        </>
    );
};
