import { useState } from "react";
import { ButtonGroup } from "react-bootstrap";
import { useQuery, useMutation } from "react-query";
import { Formik } from "formik";
import { debounce } from "lodash";
import { ListLayout } from "layouts";
import {
  ButtonDetail,
  ButtonUpdate,
  ButtonDelete,
  toastTrigger,
  Select,
  InputSearch,
  InfoItemVertical
} from "components";
import { useModalConfirm, useAccessRights } from "hooks";
import { errorFetchingTableMapper, errorSubmitMapper, exportHandler, numberConvert } from "utilities";
import UraianJabatanApi from "./__UraianJabatanApi__";
import { useNavigate } from "react-router-dom";

export const UraianJabatanList = () => {
  const access = useAccessRights("URAIAN_JABATAN");
  const navigate = useNavigate();
  const number = numberConvert();
  const modalConfirm = useModalConfirm();
  const [searchKey, setSearchKey] = useState(undefined);
  const [pagination, setPagination] = useState({
    pageCurrent: 1,
    dataLength: 10
  });
  const [filter, setFilter] = useState({
    jenis_jabatan: undefined,
    jenis_jabatan_nama: ""
  });

  const getUraianJabatan = useQuery(
    ["uraianJabatan", "list", filter.jenis_jabatan, searchKey, pagination.pageCurrent, pagination.dataLength],
    () =>
      UraianJabatanApi.getList({
        jenis_jabatan: filter.jenis_jabatan,
        search: searchKey,
        page: pagination.pageCurrent,
        limit: pagination.dataLength
      })
  );

  const getJeniJabatan = useQuery(["jenisJabatan", "dropdown"], () => UraianJabatanApi.getJenisJabatan());

  const deleteUraianJabatan = useMutation((id) => UraianJabatanApi.delete(id), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "delete" });
      getUraianJabatan.refetch();
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "delete", ...errorSubmitMapper(err) })
  });

  const getExportPekerja = useMutation((type) => UraianJabatanApi.getExport({ to: type }), {
    onSuccess: (link) => {
      toastTrigger({ variant: "success", message: "Export berhasil" });
      exportHandler(link, "Data Uraian Jabatan");
    },
    onError: () => {
      toastTrigger({ variant: "danger", message: "Export gagal" });
    }
  });

  const searchHandler = (e) => {
    setSearchKey(e?.target?.value ? e.target.value : undefined);
  };

  const onCreateButtonClickHandler = () => navigate("tambah");

  const onDetailButtonClickHandler = (data) => navigate("detail/" + data.uraian_jabatan_id);

  const onUpdateButtonClickHandler = (data) => navigate("ubah/" + data.uraian_jabatan_id);

  const onPaginationChange = (data) => setPagination({ ...pagination, pageCurrent: data });

  const onPaginationDataLengthChange = (data) => setPagination({ ...pagination, dataLength: data });

  const formSubmitHandler = (values, action) =>
    modalConfirm.trigger({
      type: action.toLowerCase(),
      onHide: modalConfirm.close,
      onSubmit: () => {
        modalConfirm.loading();
        if (action === "DELETE") {
          deleteUraianJabatan.mutate(values.uraian_jabatan_id);
        }
      },
      component: (
        <Formik initialValues={values}>
          <InfoItemVertical label="Nama Jabatan" text={values.master_jabatan_nama} />
        </Formik>
      )
    });

  return (
    <>
      <ListLayout
        showExportExcelButton
        customLeftTopSection={() => (
          <div className="d-flex align-items-end mb-3 ">
            <div style={{ width: "100%" }} className="me-2">
              <Select
                noMargin
                label="Jenis Jabatan"
                placeholder="Pilih jenis jabatan"
                options={getJeniJabatan?.data ?? []}
                onChange={(val) => {
                  setFilter({
                    jenis_jabatan: val.value,
                    jenis_jabatan_nama: val.label
                  });
                }}
              />
            </div>
            <div style={{ width: "100%" }} className="me-2">
              <InputSearch onChange={debounce(searchHandler, 1500)} />
            </div>
          </div>
        )}
        onExportExcelButtonClick={() => getExportPekerja.mutate("excel")}
        loadingExportButton={getExportPekerja.isLoading}
        showRightTopButton={access.canCreate() ? true : false}
        loading={getUraianJabatan.isFetching}
        error={getUraianJabatan.isError}
        errorText={errorFetchingTableMapper(getUraianJabatan.error)}
        dataTotal={getUraianJabatan?.data?.total}
        dataLength={pagination.dataLength}
        pageCurrent={pagination.pageCurrent}
        pageTotal={getUraianJabatan?.data?.last_page}
        onSearchChange={debounce(searchHandler, 1500)}
        onCreateButtonClick={onCreateButtonClickHandler}
        onDataLengthChange={onPaginationDataLengthChange}
        onPaginationChange={onPaginationChange}
        data={getUraianJabatan?.data?.data}
        tableHeader={[
          {
            text: "Jenis Jabatan",
            props: {
              minWidth: 200
            }
          },
          {
            text: "Kode Jabatan",
            props: {
              minWidth: 200
            }
          },
          {
            text: "Nama Jabatan",
            props: {
              minWidth: 200
            }
          },
          {
            text: "Kelompok Jabatan",
            props: {
              minWidth: 200
            }
          },
          {
            text: "Grade",
            props: {
              minWidth: 200
            }
          },
          {
            text: "Pangkat",
            props: {
              minWidth: 200
            }
          },
          {
            text: "Aksi",
            props: {
              width: 50,
              fixed: true
            }
          }
        ]}
        tableBody={[
          {
            field: "jenis_jabatan_nama"
          },
          {
            field: "master_jabatan_kode"
          },
          {
            field: "master_jabatan_nama"
          },
          {
            field: "master_kelompok_jabatan_nama"
          },
          {
            customField: (data) => number.getWithComa(parseInt(data?.grade ?? 0), { minimumFractionDigits: 0 }),
            props: { textRight: true }
          },
          {
            field: "nama_pangkat"
          },
          {
            props: { fixed: true },
            customField: (data) => (
              <ButtonGroup size="sm">
                {access.canRead() && <ButtonDetail icon noText onClick={() => onDetailButtonClickHandler(data)} />}
                {access.canUpdate() && <ButtonUpdate icon noText onClick={() => onUpdateButtonClickHandler(data)} />}
                {access.canDelete() && <ButtonDelete icon noText onClick={() => formSubmitHandler(data, "DELETE")} />}
              </ButtonGroup>
            )
          }
        ]}
      />
    </>
  );
};
