import { Services } from "services";

class RekapOrderCateringApi {
  async getList(params) {
    const fetch = await Services.get("/laporan/rekap-order-catering", { params });
    return fetch?.data?.data;
  }

  async getExport(params) {
    const fetch = await Services.get("/laporan/rekap-order-catering/export", { params });
    return fetch?.data?.data;
  }

  async getPeriodePerencanaan() {
    const fetch = await Services.get("/master/periode-perencanaan/dropdown");
    return fetch?.data?.data.map((val) => ({
      value: val?.master_periode_perencanaan_id,
      label: val?.periode_perencanaan
    }));
  }

  async getTglPenjadwalan(tglAwal, tglAkhir) {
    const fetch = await Services.get(
      "/laporan/rekap-order-catering/bulan-penjadwalan/dropdown?tgl_bulan_awal=" +
        tglAwal +
        "&tgl_bulan_akhir=" +
        tglAkhir
    );

    return fetch?.data?.data?.map((val) => ({
      value: val,
      label: val
    }));
  }
}

export default new RekapOrderCateringApi();
