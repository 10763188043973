import { Services } from "services";

class LaporanThrApi {
    async getList(params) {
        const fetch = await Services.get("/laporan/thr", { params });
        return fetch?.data?.data;
    }

    async getUnitOrganisasi() {
        const fetch = await Services.get("/master/unit-organisasi/dropdown");
        return fetch?.data?.data.map((val) => ({
            value: val?.master_unit_organisasi_id,
            label: val?.master_unit_organisasi_nama
        }));
    }

    async getPeriode() {
        const fetch = await Services.get("/utility/periode-tahun?feat=thr-bulanan");
        return fetch?.data?.data.map((val) => ({
            value: val?.periode_tahun,
            label: val?.periode_tahun
        }));
    }

    async getAgama() {
        const fetch = await Services.get("/master/agama/dropdown");
        return fetch?.data?.data.map((val) => ({
            value: val?.master_agama_id,
            label: val?.agama
        }));
    }

    async getExport(params) {
        const fetch = await Services.get("/laporan/thr/export", { params });
        return fetch?.data?.data;
    }

}

export default new LaporanThrApi();
