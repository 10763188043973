import {
    ButtonDelete,
    ButtonFilter, ButtonUpdate, dateConvert, InputSearch, ModalDetail, ModalFilter, ModalUpdate, numberConvert,
    Select, useLayout
} from "components";
import { Form, Formik, useFormikContext } from "formik";
import { useAccessRights, useModalConfirm } from "hooks";
import { ListLayout } from "layouts";
import { debounce } from "lodash";
import { useState } from "react";
import { ButtonGroup } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { errorSubmitMapper } from "utilities";
import UploadPengirimanBetonApi from "./__UploadPengirimanBetonApi__";
import { Content } from "./__UploadPengirimanBetonComps__";
import { formInitialValues, formSubmitValueMapper, formValidationSchema } from "./__UploadPengirimanBetonUtilities__";

const ModalFilterBody = () => {
    const { values, setValues } = useFormikContext();

    const getPeriode = useQuery(["periode-penggajian", "dropdown"], () => UploadPengirimanBetonApi.getPeriode());

    const getBulan = useQuery(
        ["bulan-for-list", "dropdown", values?.periodeGajianId],
        () =>
            UploadPengirimanBetonApi.getBulanForList({
                periode_tahun: values?.periodeGajianId
            }),
        { enabled: Boolean(values?.periodeGajianId) }
    );

    return (
        <>
            <Select
                label="Periode Penggajian"
                placeholder="Pilih periode penggajian"
                defaultValue={getPeriode?.data?.find((item) => item.value === values?.periodeGajianId)}
                options={getPeriode?.data ?? []}
                onChange={(val) => setValues({ ...values, periodeGajianId: val.value, periodePerencanaan: val.label })}
                loading={getPeriode.isFetching}
                errorFetch={getPeriode.isError}
            />
            <Select
                disable={Boolean(!values?.periodeGajianId)}
                label="Bulan"
                placeholder="Pilih bulan"
                defaultValue={getBulan?.data?.find((item) => item.value === values.bulan)}
                options={getBulan?.data ?? []}
                onChange={(val) => setValues({ ...values, bulan: val.value })}
                loading={getBulan.isFetching}
                errorFetch={getBulan.isError}
            />
        </>
    );
};

export const UploadPengirimanBetonList = () => {
    const access = useAccessRights("PENGIRIMAN_BETON");
    const navigate = useNavigate();
    const number = numberConvert();
    const layout = useLayout();
    const modalConfirm = useModalConfirm();
    const date = dateConvert();

    const [searchKey, setSearchKey] = useState(undefined);
    const [pagination, setPagination] = useState({
        pageCurrent: 1,
        dataLength: 10
    });

    const [filter, setFilter] = useState({
        show: false,
        active: false,
        data: {
            periodeGajianId: undefined,
            bulan: undefined
        }
    });

    const [modal, setModal] = useState({
        show: false,
        action: "",
        data: {}
    });

    const getUploadPengirimanBetonList = useQuery(
        [
            "upload-pengiriman-beton",
            "list",
            searchKey,
            pagination.pageCurrent,
            pagination.dataLength,
            filter.data?.periodeGajianId,
            filter.data?.bulan
        ],
        () =>
            UploadPengirimanBetonApi.getList({
                search: searchKey,
                page: pagination.pageCurrent,
                limit: pagination.dataLength,
                periode_tahun: filter.data?.periodeGajianId,
                periode_bulan: filter.data?.bulan,
            }),
        {
            enabled: Boolean(
                filter?.data?.periodeGajianId &&
                filter?.data?.bulan
            )
        }
    );

    const updateUploadPengirimanBeton = useMutation(({ data, id }) => UploadPengirimanBetonApi.update(data, id), {
        onSuccess: () => {
            modalConfirm.infoSuccess({ typeInfo: "update" });
            getUploadPengirimanBetonList.refetch();
        },
        onError: (err) => modalConfirm.infoError({ typeInfo: "update", ...errorSubmitMapper(err) })
    });

    const deleteUploadPengirimanBeton = useMutation((id) => UploadPengirimanBetonApi.delete(id), {
        onSuccess: () => {
            modalConfirm.infoSuccess({ typeInfo: "delete" });
            getUploadPengirimanBetonList.refetch();
        },
        onError: (err) => modalConfirm.infoError({ typeInfo: "delete", ...errorSubmitMapper(err) })
    });

    // const getUploadPengirimanBetonExport = useMutation(
    //     (data) =>
    //         UploadPengirimanBetonApi.getExport({
    //             bulan: data?.bulan,
    //             periode_tahun: data?.periodeGajianId
    //         }),
    //     {
    //         onSuccess: (link) => {
    //             toastTrigger({ variant: "success", message: "Export berhasil" });
    //             exportHandler(link, "Data Pengiriman Beton");
    //         },
    //         onError: () => {
    //             toastTrigger({ variant: "danger", message: "Export gagal" });
    //         }
    //     }
    // );

    const onHideModalHandler = () => setModal({ show: false, action: "", data: {} });
    const onCreateButtonClickHandler = () => navigate("tambah");
    const onUpdateButtonClickHandler = (data) => setModal({ show: true, action: "UPDATE", data: data });
    // const onDetailButtonClickHandler = (data) => setModal({ show: true, action: "READ", data: data });

    const onPaginationChange = (data) => setPagination({ ...pagination, pageCurrent: data });

    const onPaginationDataLengthChange = (data) => setPagination({ ...pagination, dataLength: data });

    const searchHandler = (e) => {
        setSearchKey(e?.target?.value ? e.target.value : undefined);
    };

    const submitValidationHandler = (errors) =>
        new Promise((resolve, reject) => {
            const getError = Object.values(errors);

            if (getError.length > 0) {
                reject(getError);
            } else {
                resolve();
            }
        });

    const preSubmitHandler = (formik) => {
        const { values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting } = formik;

        setSubmitting(true);
        validateForm().then(async (err) => {
            setErrors(err);
            setTouched(err);

            await submitValidationHandler(err, values)
                .then(() => handleSubmit())
                .catch((err) =>
                    modalConfirm.trigger({
                        type: "error",
                        title: "Data Tidak Lengkap",
                        data: err,
                        onHide: () => modalConfirm.close()
                    })
                )
                .finally(() => {
                    setSubmitting(false);
                });
        });
    };

    const formSubmitHandler = (values, action) =>
        modalConfirm.trigger({
            type: action.toLowerCase(),
            onHide: modalConfirm.close,
            onSubmit: () => {
                modalConfirm.loading();

                if (action === "UPDATE") {
                    updateUploadPengirimanBeton
                        .mutateAsync({ data: formSubmitValueMapper(values).object, id: values.id })
                        .then(() => onHideModalHandler());
                } else if (action === "DELETE") {
                    deleteUploadPengirimanBeton.mutate(values.id);
                }
            },
            component: (
                <Formik initialValues={values}>
                    {/* <Content action="READ" /> */}
                </Formik>
            )
        });

    const TABLE_HEADER = [
        {
            text: "Tanggal"
        },
        {
            text: "No. Doket"
        },
        {
            text: "Customer"
        },
        {
            text: "Proyek"
        },
        {
            text: "Supir"
        },
        {
            text: "No. TM"
        },
        {
            text: "Mutu"
        },
        {
            text: "Volume"
        },
        {
            text: "Jenis Ritase"
        },
        {
            text: "Jarak"
        },
        {
            text: "Status Ritase"
        },
        {
            text: "Status Kubikasi"
        },
        // {
        //     text: "NIK"
        // },
        {
            text: "Nama Pekerja"
        },
        {
            text: "Aksi",
            props: {
                fixed: true
            }
        }
    ];

    const TABLE_BODY = [
        { customField: (val) => date.getSlashDMY(new Date(val?.tgl_pengiriman)) },
        {
            props: { textRight: true },
            field: "no_doket"
        },
        { field: "nama_kustomer" },
        { field: "proyek" },
        { field: "supir" },
        { field: "no_tm" },
        { field: "mutu" },
        {
            props: { textRight: true },
            customField: (val) => val?.volume ? number.getWithComa(val?.volume, { minimumFractionDigits: 0 }) : "-"
        },
        { field: "jenis_ritase_nama" },
        {
            props: { textRight: true },
            customField: (val) => val?.jarak ? number.getWithComa(val?.jarak, { minimumFractionDigits: 0 }) + "km" : "-"
        },
        { customField: (val) => val?.status_ritase === 1 || val?.status_ritase === undefined ? "Belum diposting" : "Sudah diposting" },
        { customField: (val) => val?.status_kubikasi === 1 || val?.status_kubikasi === undefined ? "Belum diposting" : "Sudah diposting" },
        // { field: "nip" },
        { field: "pekerja_nama" },
        {
            props: {
                width: 50,
                fixed: true,
                textCenter: true
            },
            customField: (data) => (
                <ButtonGroup size="sm">
                    {/* {access.canRead() && (
                        <ButtonDetail icon tooltip={false} noText onClick={() => onDetailButtonClickHandler(data)} />
                    )} */}
                    {access.canUpdate() && (
                        <ButtonUpdate tooltip={false} icon noText onClick={() => onUpdateButtonClickHandler(data)} />
                    )}
                    {access.canDelete() && (
                        <ButtonDelete
                            tooltip={false}
                            icon
                            noText
                            onClick={() => formSubmitHandler(formInitialValues(data).detailData, "DELETE")}
                        />
                    )}
                </ButtonGroup>
            )
        }
    ];

    return (
        <>
            <ListLayout
                customLeftTopSection={() => (
                    <>
                        <div className="d-flex align-items-end mb-3">
                            <div style={{ width: 300 }} className="me-2">
                                <InputSearch onChange={debounce(searchHandler, 1500)} />
                            </div>
                            <div>
                                <ButtonFilter
                                    active={filter?.active}
                                    className="text-nowrap"
                                    onClick={() => setFilter({ ...filter, show: true })}
                                />
                            </div>
                        </div>
                    </>
                )}
                showRightTopButton={access.canCreate() ? true : false}
                // showExportExcelButton={Boolean(filter?.data?.periodeGajianId && filter?.data?.bulan)}
                // onExportExcelButtonClick={() => getUploadPengirimanBetonExport.mutate(filter?.data)}
                // loadingExportButton={getUploadPengirimanBetonExport.isLoading}
                loading={getUploadPengirimanBetonList?.isFetching}
                error={getUploadPengirimanBetonList?.isError}
                dataTotal={getUploadPengirimanBetonList?.data?.total}
                dataLength={pagination.dataLength}
                pageCurrent={pagination.pageCurrent}
                pageTotal={getUploadPengirimanBetonList?.data?.last_page}
                onSearchChange={debounce(searchHandler, 1500)}
                onCreateButtonClick={onCreateButtonClickHandler}
                onDataLengthChange={onPaginationDataLengthChange}
                onPaginationChange={onPaginationChange}
                data={getUploadPengirimanBetonList?.data?.data}
                tableHeader={TABLE_HEADER}
                tableBody={TABLE_BODY}
            />

            {/**
       * Modal Detail:
       * Digunakan untuk melihat detail data
       */}
            <Formik enableReinitialize initialValues={formInitialValues(modal?.data).detailData}>
                <ModalDetail
                    title={layout.getActivePageName()}
                    show={Boolean(modal.show && modal.action === "READ")}
                    onHide={onHideModalHandler}
                >
                    <Content action="READ" />
                </ModalDetail>
            </Formik>

            {/**
       * Modal Update:
       * Modal untuk mengubah data
       */}
            <Formik
                enableReinitialize
                initialValues={formInitialValues(modal?.data).detailData}
                validationSchema={formValidationSchema}
                onSubmit={(values) => formSubmitHandler(values, "UPDATE")}
            >
                {(formik) => {
                    return (
                        <Form>
                            <ModalUpdate
                                title={layout.getActivePageName()}
                                show={Boolean(modal.show && modal.action === "UPDATE")}
                                onHide={() => {
                                    onHideModalHandler();
                                    formik.resetForm();
                                }}
                                onSubmit={() => preSubmitHandler(formik)}
                            >
                                <Content action="UPDATE" />
                            </ModalUpdate>
                        </Form>
                    );
                }}
            </Formik>

            {/* ModalFilter */}
            <Formik
                initialValues={{
                    bulan: filter?.data?.bulan,
                    periodeGajianId: filter?.data?.periodeGajianId
                }}
            >
                {({ resetForm, values }) => (
                    <ModalFilter
                        show={filter.show}
                        onReset={resetForm}
                        onHide={() =>
                            setFilter({
                                ...filter,
                                show: false
                            })
                        }
                        onSubmit={() => {
                            setFilter({
                                show: false,
                                active: Boolean(Object.values(values).find((val) => val)),
                                data: values
                            });
                        }}
                    >
                        <ModalFilterBody />
                    </ModalFilter>
                )}
            </Formik>
        </>
    );
};
