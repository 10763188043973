import { Services } from "services";
import { fetchDropdownMapper } from "utilities";

const fetchDropdown = fetchDropdownMapper(Services);

class PresensiApi {
  async getDataScan(params) {
    const fetch = await Services.get("/transaksi/presensi/data-scan", { params });
    return fetch?.data?.data;
  }
  async getRekapPeriode(params) {
    const fetch = await Services.get("/transaksi/presensi/rekap-periode", { params });
    return fetch?.data?.data;
  }
  async getRincianHarian(params) {
    const fetch = await Services.get("/transaksi/presensi/absen-harian", { params });
    return fetch?.data?.data;
  }

  async getPekerja() {
    const getPekerja = await fetchDropdown("/transaksi/registrasi-pekerja/dropdown", {
      value: "pekerja_id",
      label: "pekerja_nama"
    });
    return [{ value: undefined, label: "Semua" }, ...getPekerja];
  }

  async getShift() {
    const getShift = await fetchDropdown("/master/shift/dropdown", {
      value: "master_shift_id",
      label: "master_shift_nama"
    });
    return [{ value: undefined, label: "Semua" }, ...getShift];
  }

  async getUnitOrganisasiDropdown() {
    const fetch = await Services.get("/master/unit-organisasi/dropdown");
    const dataUnit = fetch?.data?.data.map((val) => ({
      value: val?.master_unit_organisasi_id,
      label: val?.master_unit_organisasi_nama
    }));
    return [{ value: undefined, label: "Semua" }, ...dataUnit];
  }

  async getExportDataScan(params) {
    const fetch = await Services.get("/transaksi/presensi/data-scan/export", { params });
    return fetch?.data?.data;
  }
  async getExportRekapPeriode(params) {
    const fetch = await Services.get("/transaksi/presensi/rekap-periode/export", { params });
    return fetch?.data?.data;
  }
  async getExportRincianHarian(params) {
    const fetch = await Services.get("/transaksi/presensi/absen-harian/export", { params });
    return fetch?.data?.data;
  }
  update(data, id) {
    return Services.put("/transaksi/presensi/absen-harian/" + id, data);
  }
  delete(id) {
    return Services.delete("/transaksi/presensi/absen-harian/" + id);
  }
}

export default new PresensiApi();
