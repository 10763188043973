import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useQuery, useMutation } from "react-query";
import { Formik } from "formik";
import { ContentLayout } from "layouts";
import { ButtonBack, ButtonCreate } from "components";
import { useModalConfirm } from "hooks";
import { errorSubmitMapper } from "utilities";
import { Content } from "./__SetupJadwalComps__";
import {
  formInitialValues,
  formSubmitValueMapper,
  formValidationSchema,
} from "./__SetupJadwalUtilities__";
import SetupJadwalApi from "./__SetupJadwalApi__";

export const SetupJadwalCreate = () => {
  const navigate = useNavigate();
  const modalConfirm = useModalConfirm();

  const defaultData = {
    periodeGajianId: new Date().getFullYear(),
    periodePerencanaan: new Date().getFullYear(),
    unitOrganisasiId: undefined,
    jabatanId: undefined,
    pekerjaId: undefined,
    bulan: undefined,
    tglAwal: undefined,
    tglAkhir: undefined,
    regularShift: undefined,
    weekNumber: undefined
  };

  const [filter, setFilter] = useState({
    search: false,
    data: defaultData
  });

  const resetFilter = () => {
    setFilter({
      active: false,
      data: defaultData
    });
  };

  const getSetupForm = useQuery(
    [
      "setup-jadwal-form",
      filter?.search,
      filter?.data?.periodeGajianId,
      filter?.data?.jabatanId,
      filter?.data?.tglAwal,
      filter?.data?.tglAkhir,
      filter?.data?.regularShift,
      filter?.data?.weekNumber
    ],
    () =>
      SetupJadwalApi.getForm({
        periode_tahun: filter?.data?.periodeGajianId,
        master_jabatan_id: filter?.data?.jabatanId,
        tgl_bulan_awal: filter?.data?.tglAwal,
        tgl_bulan_akhir: filter?.data?.tglAkhir,
        pekerja_id: filter?.data?.pekerjaId,
        reguler_shift: filter?.data?.regularShift,
        week_number: filter?.data?.weekNumber
      }),
    {
      enabled: Boolean(
        filter?.data?.periodeGajianId &&
        filter?.data?.jabatanId &&
        filter?.data?.tglAwal &&
        filter?.data?.tglAkhir
      )
    }
  );

  const createData = useMutation((data) => SetupJadwalApi.create(data), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "create" });
      getSetupForm?.refetch();
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "create", ...errorSubmitMapper(err) })
  });

  const submitValidationHandler = (errors) =>
    new Promise((resolve, reject) => {
      const getError = Object.values(errors);

      if (getError.length > 0) {
        reject(getError ?? []);
      } else {
        resolve();
      }
    });

  const preSubmitHandler = (values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting) => {
    setSubmitting(true);
    validateForm().then(async (err) => {
      setErrors(err);
      setTouched(err);

      await submitValidationHandler(err, values)
        .then(() => formSubmitHandler(values))
        .catch((err) =>
          modalConfirm.trigger({
            type: "error",
            title: "Data Tidak Lengkap",
            data: err,
            onHide: () => modalConfirm.close()
          })
        )
        .finally(() => {
          setSubmitting(false);
        });
    });
  };

  const formSubmitHandler = async (values) => {
    return await modalConfirm.trigger({
      type: "create",
      onHide: modalConfirm.close,
      onSubmit: () => {
        modalConfirm.loading();
        createData.mutateAsync(formSubmitValueMapper(values));
      }
    });
  };

  return (
    <>
      <ContentLayout showBreadcrumb>
        {/* {getSetupForm?.isFetching ? (
          <DataStatus loading />
        ) : ( */}
        <>
          <div className="text-end mb-3">
            <ButtonBack tooltip={false} onClick={() => navigate("/time-attendance/transaksi/setup-jadwal")} />
          </div>
          <Formik
            enableReinitialize
            initialValues={formInitialValues.dataFormSetup(getSetupForm?.data, filter?.data)}
            validationSchema={formValidationSchema}
          // onSubmit={(val, { resetForm }) => {
          //     filteredSearchHandler(val);
          //     resetForm();
          // }}
          >
            {({ values, handleSubmit, validateForm, setTouched, setErrors, setSubmitting }) => (
              <>
                <Content
                  action="CREATE"
                  setFilter={setFilter}
                  isLoading={getSetupForm.isFetching}
                  onSearchButtonClick={setFilter}
                  onResetClickButton={resetFilter}
                />
                <div className="text-end mb-3 mt-3">
                  <ButtonCreate
                    text="SIMPAN"
                    disabled={Boolean(values?.detail?.length === 0)}
                    tooltip={false}
                    onClick={() =>
                      preSubmitHandler(values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting)
                    }
                  />
                </div>
              </>
            )}
          </Formik>
        </>
        {/* )} */}
      </ContentLayout>
    </>
  );
};
