export const formInitialValues = (data = {}, filter) => ({
    dataFormSetup: {
        periodeGajianId: data?.periode_tahun ?? filter?.periodeGajianId,
        periodePerencanaan: data?.periode_tahun ?? new Date().getFullYear(),
        pekerjaId: data?.pekerja_id ?? filter?.pekerjaId,
        pekerjaNama: data?.pekerja_nama ?? filter?.pekerjaNama,
        nik: data?.nip,
        bulan: filter?.bulan,
        tglAwal: data?.tgl_bulan_awal ?? filter?.tglAwal,
        tglAkhir: data?.tgl_bulan_akhir ?? filter?.tglAkhir,
        tglSetup: data?.tgl_setup ?? filter?.tglSetup ?? new Date(),
        unitOrganisasiId: data?.master_unit_organisasi_id ?? filter?.unitOrganisasiId ?? "",
        unitOrganisasiNama: data?.master_unit_organisasi_nama ?? filter?.unitOrganisasiNama ?? "",

        uangDendaPermenit: data?.denda_permenit,
        totalUangDenda: data?.denda_total,
        nominal: false,
        detail:
            data?.detail?.map((val) => ({
                tglPresensi: val?.tgl_presensi,
                jam: val?.jam,
                jamIn: val?.jam_in,
                jumlahTerlambat: val?.menit_terlambat,
                noSurat: val?.no_surat_ijin,
                jenisIjinId: val?.master_jenis_ijin_id,
                jenisIjinNama: val?.jenis_ijin,
                nominal: parseFloat(val?.uang_denda) ?? 0
            })) ?? []
    },

    detailData: {
        id: data?.pekerja_denda_bulanan_id ?? "",
        komponenPayroll: data?.komponen_payroll ?? "",
        periodeGajianId: data?.periode_tahun ?? "",
        periodePerencanaan: data?.periode_tahun ?? "",
        tglSetup: data?.tgl_setup ?? "",
        tglAwal: data?.tgl_bulan_awal,
        tglAkhir: data?.tgl_bulan_akhir,
        nik: data?.nip ?? "",
        pekerjaId: data?.pekerja_id ?? "",
        pekerjaNama: data?.pekerja_nama ?? "",
        unitOrganisasiId: data?.master_unit_organisasi_id ?? "",
        unitOrganisasiNama: data?.master_unit_organisasi_nama ?? "",
        jabatanId: data?.master_jabatan_id ?? "",
        jabatanNama: data?.master_jabatan_nama ?? "",
        dendaPermenit: data?.denda_permenit ?? "",
        uangDendaPermenit: false,
        nominal: data?.denda_total ?? ""
    }
});
