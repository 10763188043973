export const formInitialValues = (data = {}) => ({
  id: data?.upah_ritase_id,
  urutanRitase: data?.urutan_riase,
  jenisRitaseId: data?.jenis_ritase_id,
  jenisRitaseKode: data?.jenis_ritase_kode,
  jenisRitaseNama: data?.jenis_ritase_nama,
  jarakBawah: data?.jarak_bawah,
  jarakAtas: data?.jarak_atas,
  upah: data?.upah
});
