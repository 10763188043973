import React from "react";
import { useQuery } from "react-query";
import { useFormikContext } from "formik";
import {
    TableList,
    InfoItemVertical,
    numberConvert,
    Select,
    DatePicker,
    ButtonCreate,
    InputCurrency,
    Input,
    dateConvert,
    InfoItemHorizontal
} from "components";
import { useAccessRights } from "hooks";
import { Row, Col, Card, ButtonGroup } from "react-bootstrap";
import Pph21BulananApi from "../__Pph21BulananApi__";
import { useNavigate } from "react-router-dom";

const SectionDataSetup = ({ onSearchButtonClick, onResetClickButton }) => {
    const { values, setValues, errors, touched, handleChange } = useFormikContext();
    // const date = dateConvert();

    const defaultSearch = {
        periodeGajianId: values?.periodeGajianId,
        periodePerencanaan: values?.periodePerencanaan,
        bulan: values?.bulan,
        unitOrganisasiId: values?.unitOrganisasiId,
        unitOrganisasiNama: values?.unitOrganisasiNama,
        pekerjaId: values?.pekerjaId,
        pekerjaNama: values?.pekerjaNama,
        tglAwal: values?.tglAwal,
        tglAkhir: values?.tglAkhir,
        tglSetup: values?.tglSetup,
    };

    const getUnitOrganisasi = useQuery(["unit-organisasi", "dropdown"], () =>
        Pph21BulananApi.getUnitOrganisasi()
    );
    // const getStatusPekerja = useQuery(["status-pekerja", "dropdown"], () =>
    //     Pph21BulananApi.getStatusPekerja()
    // );
    const getBulan = useQuery(["bulan", "dropdown"], () => Pph21BulananApi.getBulan());

    return (
        <>
            <Input name="periodeGajianId" label="Periode Penggajian" value={values?.periodeGajianId} onChange={handleChange} />
            <Select
                label="Unit Organisasi"
                options={getUnitOrganisasi?.data ?? []}
                placeholder="Pilih salah satu..."
                defaultValue={
                    values?.unitOrganisasiId
                        ? getUnitOrganisasi?.data?.find((item) => item.value === values?.unitOrganisasiId)
                        : null
                }
                onChange={(val) => {
                    setValues({
                        ...values,
                        unitOrganisasiId: val.value,
                        unitOrganisasiNama: val.label
                    });
                }}
                loading={getUnitOrganisasi?.isFetching}
                errorFetch={getUnitOrganisasi.isError}
                error={Boolean(errors.unitOrganisasiId && touched.unitOrganisasiId)}
                errorText={Boolean(errors.unitOrganisasiId && touched.unitOrganisasiId) && errors.unitOrganisasiId}
            />
            <Select
                label="Bulan Penggajian"
                disable={Boolean(!values?.periodeGajianId)}
                options={getBulan?.data ?? []}
                placeholder="Pilih salah satu..."
                defaultValue={values?.bulan ? getBulan?.data?.find((item) => item.value === values?.bulan) : null}
                onChange={(val) => {
                    const tglNewStart = val.value <= 1 ?
                        `${values?.periodePerencanaan - 1}-12-26`
                        : `${values?.periodePerencanaan}-${val?.value < 10 ? `0${val?.value - 1}` : val?.value - 1}-26`;

                    const tglNewEnd = `${values?.periodePerencanaan}-${val?.value < 10 ? `0${val?.value}` : val?.value}-25`;

                    setValues({
                        ...values,
                        bulan: val.value,
                        bulanNama: val.label,
                        tglAwal: tglNewStart,
                        tglAkhir: tglNewEnd
                    });
                }}
                loading={getBulan?.isFetching}
                errorFetch={getBulan.isError}
                error={Boolean((errors.bulan && touched.bulan) || errors.validateDate)}
                errorText={Boolean(errors.bulan && touched.bulan) && errors.bulan ? errors.bulan : errors.validateDate}
            />
            <small className="text-danger">Fitur ini hanya disetting dari Januari s/d November</small>

            <div>
                <div style={{ fontSize: 14 }}>Tanggal Penggajian</div>
                <div className="d-flex align-items-center justify-content-between">
                    <div style={{ width: "44%" }}>
                        <DatePicker
                            disabled
                            key={`${values?.periodeGajianId} ${values?.bulan}`}
                            placeholderText="dd/mm/yyyy"
                            selected={values?.periodeGajianId && values?.bulan ? values?.tglAwal : undefined}
                            disable={Boolean(!values?.bulan)}
                            onChange={(val) =>
                                setValues({
                                    ...values,
                                    tglAwal: val,
                                    bulan: new Date(val).getMonth() + 1,
                                    tglAkhir: new Date(new Date(val).setDate(new Date(val).getDate() + 30)),
                                })
                            }
                            error={Boolean(errors.tglAwal && touched.tglAwal) || errors.validateDate}
                            errorText={
                                Boolean(errors.tglAwal && touched.tglAwal) && errors.tglAwal ? errors.tglAwal : errors.validateDate
                            }
                        />
                    </div>
                    <small>s/d</small>
                    <div style={{ width: "44%" }}>
                        <DatePicker
                            disabled
                            key={`${values?.periodeGajianId} ${values?.bulan}`}
                            placeholderText="dd/mm/yyyy"
                            selected={values?.periodeGajianId && values?.bulan ? values?.tglAkhir : undefined}
                            disable={Boolean(!values?.bulan)}
                            onChange={(val) =>
                                setValues({
                                    ...values,
                                    tglAkhir: val
                                })
                            }
                            error={Boolean(errors.tglAkhir && touched.tglAkhir)}
                            errorText={Boolean(errors.tglAkhir && touched.tglAkhir) && errors.tglAkhir}
                        />
                    </div>
                </div>
            </div>
            <DatePicker
                disabled
                label="Tanggal Setup"
                placeholderText="dd-mm-yyyy"
                selected={values?.tglSetup}
                onChange={(val) =>
                    setValues({
                        ...values,
                        tglSetup: val
                    })
                }
                error={Boolean(errors.tglSetup && touched.tglSetup)}
                errorText={Boolean(errors.tglSetup && touched.tglSetup) && errors.tglSetup}
            />

            <div className="d-flex justify-content-end">
                <div>
                    {/* <ButtonCancel text="RESET" className="mb-3 me-2" tooltip={false} onClick={() => onResetClickButton()} /> */}
                    <ButtonCreate
                        disabled={Boolean(
                            !values?.periodeGajianId || !values?.bulan
                        )}
                        text="CARI"
                        className="mb-3"
                        tooltip={false}
                        onClick={() =>
                            onSearchButtonClick({
                                active: true,
                                data: defaultSearch
                            })
                        }
                    />
                </div>
            </div>
        </>
    );
};

const TabelDataPekerja = ({ action }) => {
    const { values } = useFormikContext();
    const number = numberConvert();
    const date = dateConvert();

    // const setNewValues = ({ index, propname, val }) => {
    //     const detailTemp = [...values?.detail];
    //     const updatedDetail = detailTemp[index];
    //     updatedDetail[propname] = val;

    //     setValues({ ...values, detail: detailTemp });
    // };

    // const getError = (index, propname) => {
    //     return Boolean(errors?.detail?.[index]?.[propname] && touched?.detail?.[index]?.[propname]);
    // };

    // const getErrorText = (index, propname) => {
    //     return (
    //         Boolean(errors?.detail?.[index]?.[propname] && touched?.detail?.[index]?.[propname]) &&
    //         errors?.detail?.[index]?.[propname]
    //     );
    // };

    // const arrayTotalUangLembur = values?.detail?.reduce((prev, curr) => {
    //     let hasil = 0;
    //     hasil = hasil + curr?.nominal;
    //     return [...prev, hasil];
    // }, []);

    // const calculationFunction = (arr) => {
    //     let sum = 0;
    //     arr.map((e) => (sum += e));
    //     return sum;
    // };

    return (
        <>
            <Row className="mb-4">
                <Col md={6}>
                    <InfoItemVertical label="Periode Penggajian" text={values.periodePerencanaan} />
                    <InfoItemVertical
                        label="Bulan Penggajian"
                        text={
                            (values?.tglAwal ? date.getSlashDMY(new Date(values?.tglAwal)) : "-") +
                            " s/d " +
                            (values?.tglAkhir ? date.getSlashDMY(new Date(values?.tglAkhir)) : "-")
                        }
                    />
                    <InfoItemVertical label="NIK" text={values.nik} />
                    <InfoItemVertical label="Pekerja" text={values.pekerjaNama} />
                </Col>
                <Col md={6}>
                    <InfoItemVertical label="Unit Organisasi" text={values.unitOrganisasiNama} />
                    <InfoItemVertical label="Jabatan" text={values.jabatanNama} />
                    <InfoItemVertical label="Status PPH21" text={values.statusPajak} />
                    <InfoItemVertical label="NPWP" text={values.noNpwp} />
                </Col>
            </Row>

            <TableList
                maxHeight={550}
                tableHeader={[
                    { text: "Komponen Gaji" },
                    { text: "Nominal" },
                ]}
                tableBody={[
                    {
                        field: "komponenNama"
                    },
                    {
                        props: { textRight: true },
                        customField: (val) => val?.nominal ? "Rp" + number.getWithComa(val?.nominal, { minimumFractionDigits: 2 }) : "Rp0"
                    },
                ]}
                data={values?.detail}
                tableFooter={[
                    {
                        text: "Penghasilan Bruto (bulan ini)",
                        props: { colSpan: 2 }
                    },
                    {
                        props: { textRight: true },
                        text: values?.penghasilanBrutoBulanan ? "Rp" + number.getWithComa(values?.penghasilanBrutoBulanan, { minimumFractionDigits: 2 })
                            : "Rp" + 0
                    }
                ]}
            />
            <InfoItemHorizontal className="mt-2" label={<b>PPh 21 (Bulan Ini)</b>} text={values?.pph21Bulanan ? "Rp" + number.getWithComa(values?.pph21Bulanan, { minimumFractionDigits: 2 }) : "Rp0"} />
        </>
    );
};

const TabelPostList = ({ action }) => {
    const { values } = useFormikContext();
    const access = useAccessRights("PEKERJA_PAJAK_BULANAN");
    const navigate = useNavigate();

    return (
        <>
            <TableList
                maxHeight={550}
                rowProps={(val) => ({ style: { background: (val.hasStatusPajak === false) ? "#fc7c7c" : "white" } })}
                tableHeader={[
                    { text: "NIK" },
                    { text: "Pekerja" },
                    { text: "Unit Organisasi" },
                    { text: "Jabatan" },
                    { text: "Status Pajak" },
                    { text: "NPWP" },
                    { text: "Aksi" }
                ]}
                tableBody={[
                    {
                        field: "nik"
                    },
                    {
                        field: "pekerjaNama"
                    },
                    {
                        field: "unitOrganisasiNama"
                    },
                    {
                        field: "jabatanNama"
                    },
                    {
                        field: "statusPajak"
                    },
                    {
                        field: "noNpwp"
                    },
                    {
                        props: {
                            width: 50,
                            fixed: true,
                            textCenter: true,
                            style: { backgroundColor: "white" }
                        },
                        customField: (val) => (
                            <ButtonGroup size="sm">
                                {access.canCreate() && (
                                    <ButtonCreate tooltip={false} disabled={(val?.hasPosted === true || val?.hasStatusPajak === false)} text="POST" onClick={() => {
                                        navigate("/payroll/transaksi/pph-21-bulanan/tambah",
                                            {
                                                state: {
                                                    periodeGajianId: val?.periodeGajianId,
                                                    unitOrganisasiId: val?.unitOrganisasiId,
                                                    unitOrganisasiNama: val?.unitOrganisasiNama,
                                                    pekerjaId: val?.pekerjaId,
                                                    pekerjaNama: val?.pekerjaNama,
                                                    jabatanNama: val?.jabatanNama,
                                                    nik: val?.nik,
                                                    tglAwal: val?.tglAwal,
                                                    tglAkhir: val?.tglAkhir,
                                                    tglSetup: val?.tglSetup
                                                }
                                            })
                                    }} />
                                )}
                            </ButtonGroup>
                        )
                    }
                ]}
                data={values?.detail}
            />
        </>
    )
}


export const Content = ({ action, onSearchButtonClick, onResetClickButton, setCreateViewState, filterData }) => {
    const { values, errors, touched, setValues } = useFormikContext();
    const date = dateConvert();
    const number = numberConvert();

    if (action === "UPDATE") {
        return (<>
            <Input disabled label="Komponen Payroll" value={values?.komponenPayroll ? values?.komponenPayroll : "-"} />
            <Input disabled label="Periode Gajian" value={values?.periodeGajianId} />
            <Input disabled label="Bulan" value={
                (values?.tglAwal ? date.getSlashDMY(new Date(values?.tglAwal)) : "-")
                + " s/d " +
                (values?.tglAkhir ? date.getSlashDMY(new Date(values?.tglAkhir)) : "-")
            } />
            <Input disabled label="NIK" value={values?.nik} />
            <Input disabled label="Pekerja" value={values?.pekerjaNama} />
            <Input disabled label="Unit Organisasi" value={values?.unitOrganisasiNama} />
            <Input disabled label="Jabatan" value={values?.jabatanNama ? values?.jabatanNama : "-"} />
            <InputCurrency
                label="Total Upah Ritase Bulanan"
                value={values?.totalUpahRitase ?
                    number.getWithComa(values?.totalUpahRitase, { minimumFractionDigits: 2 }) : "Rp-"}
                onChange={(val) => setValues({
                    ...values,
                    totalUpahRitase: val
                })}
                error={Boolean(errors.totalUpahRitase && touched.totalUpahRitase)}
                errorText={Boolean(errors.totalUpahRitase && touched.totalUpahRitase) && errors.totalUpahRitase}
            />

        </>)
    }

    return (
        <>
            <Row>
                {action === "POST" ? (
                    <>
                        <Col md={4} className="mb-4">
                            <Card style={{ height: "100%" }}>
                                <Card.Header>
                                    <b>Setup Data</b>
                                </Card.Header>
                                <Card.Body>
                                    <SectionDataSetup onSearchButtonClick={onSearchButtonClick} onResetClickButton={onResetClickButton} />
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Header>
                                    <b>List Pekerja</b>
                                </Card.Header>
                                <Card.Body>
                                    <TabelPostList action={action} setCreateViewState={setCreateViewState} onPostButtonClickHandler={onSearchButtonClick} filterData={filterData} />
                                </Card.Body>
                            </Card>
                        </Col>
                    </>
                ) : (
                    <Col md={12}>
                        <Card style={{ height: "100%" }}>
                            <Card.Header>
                                <b>Data Pekerja</b>
                            </Card.Header>
                            <Card.Body>
                                <TabelDataPekerja action={action} />
                            </Card.Body>
                        </Card>
                    </Col>
                )}
            </Row>
        </>
    );
};
