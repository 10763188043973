import {
  PerhitunganUangPhkList,
  PerhitunganUangPhkCreate,
  PerhitunganUangPhkUpdate,
  PerhitunganUangPhkDetail,
  DataPhkList
} from "pages/OprasionalSdm";

export const PerhitunganUangPhk = {
  component: <PerhitunganUangPhkList />,
  link: "perhitungan-uang-phk",
  name: "Perhitungan Uang PHK",
  access: "PERHITUNGAN_PHK",
  child: [
    {
      name: "Data PHK",
      link: "/list-data-phk",
      component: <DataPhkList />
    },
    {
      name: "Detail",
      link: "/detail/:id",
      component: <PerhitunganUangPhkDetail />
    },
    {
      name: "Ubah",
      link: "/ubah/:id",
      component: <PerhitunganUangPhkUpdate />
    },
    {
      name: "Tambah",
      link: "/tambah/:id",
      component: <PerhitunganUangPhkCreate />
    }
  ]
};
