import { InfoItemVertical, Select, TextArea } from "components";
import { useFormikContext } from "formik";
import { useQuery } from "react-query";
import MappingOverheadApi from "../__MappingOverheadApi__";

export const Content = ({ action }) => {
    const { values, errors, touched, handleChange, setValues } = useFormikContext();
    const getJenisOverhead = useQuery(["jenis-overhead", "dropdown"], () => MappingOverheadApi.getJenisOverhead());
    const getUnitOrganisasi = useQuery(["unit-organisasi", "dropdown"], () => MappingOverheadApi.getUnitOrganisasi());

    if (action === "READ") {
        return (
            <>
                <InfoItemVertical label="Jenis Overhead" text={values.jenisOverheadNama} />
                <InfoItemVertical label="Unit Organisasi" text={values.unitOrganisasiNama} />
                <InfoItemVertical label="Keterangan" text={values.keterangan} />
            </>
        );
    }

    return (
        <>
            <Select
                label="Jenis Overhead"
                placeholder="Pilih salah satu..."
                defaultValue={
                    values.jenisOverheadId ? getJenisOverhead?.data?.find((item) => item.value === values.jenisOverheadId) : null
                }
                options={getJenisOverhead?.data ?? []}
                onChange={(val) => setValues({ ...values, jenisOverheadId: val.value, jenisOverheadNama: val.label })}
                loading={getJenisOverhead.isFetching}
                errorFetch={getJenisOverhead.isError}
                error={Boolean(errors.jenisOverheadId && touched.jenisOverheadId)}
                errorText={Boolean(errors.jenisOverheadId && touched.jenisOverheadId) && errors.jenisOverheadId}
            />
            <Select
                label="Unit Organisasi"
                placeholder="Pilih salah satu..."
                defaultValue={
                    values.unitOrganisasiId ? getUnitOrganisasi?.data?.find((item) => item.value === values.unitOrganisasiId) : null
                }
                options={getUnitOrganisasi?.data ?? []}
                onChange={(val) => setValues({ ...values, unitOrganisasiId: val.value, unitOrganisasiNama: val.label })}
                loading={getUnitOrganisasi.isFetching}
                errorFetch={getUnitOrganisasi.isError}
                error={Boolean(errors.unitOrganisasiId && touched.unitOrganisasiId)}
                errorText={Boolean(errors.unitOrganisasiId && touched.unitOrganisasiId) && errors.unitOrganisasiId}
            />
            <TextArea
                label="Keterangan"
                name="keterangan"
                placeholder="Masukkan keterangan"
                value={values.keterangan}
                onChange={handleChange}
                error={Boolean(errors.keterangan && touched.keterangan)}
                errorText={Boolean(errors.keterangan && touched.keterangan) && errors.keterangan}
            />
        </>
    );
};
