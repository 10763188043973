export const formInitialValues = {
    detailData: (data, filter) => ({
        id: data?.pekerja_upah_ritase_cp_bulanan_id,
        periodeGajianId: data?.periode_tahun ?? filter?.periodeGajianId,
        periodePerencanaan: data?.periode_tahun ?? new Date().getFullYear(),
        pekerjaId: data?.pekerja_id ?? filter?.pekerjaId,
        pekerjaNama: data?.pekerja_nama ?? filter?.pekerjaNama,
        nik: data?.nip ?? filter?.nik,
        bulan: filter?.bulan,
        tglAwal: data?.tgl_bulan_awal ?? filter?.tglAwal,
        tglAkhir: data?.tgl_bulan_akhir ?? filter?.tglAkhir,
        tglSetup: data?.tgl_setup ?? filter?.tglSetup ?? new Date(),
        unitOrganisasiId: data?.master_unit_organisasi_id ?? filter?.unitOrganisasiId ?? "",
        unitOrganisasiNama: data?.master_unit_organisasi_nama ?? filter?.unitOrganisasiNama ?? "",
        jabatanNama: data?.master_jabatan_nama ?? filter?.jabatanNama ?? "",
        upahRitase: data?.upah_ritase_cp,
        totalUpahRitase: data?.total_upah_ritase_cp,

        detail:
            data?.detail?.map((val) => ({
                detailId: val?.pekerja_upah_ritase_cp_bulanan_detail_id,
                tglNota: val?.tgl_nota,
                noNota: val?.nomor_nota,
                proyekId: val?.proyek_id,
                proyekNama: val?.proyek_nama,
                upahRitase: val?.upah_ritase_cp
            })) ?? [],

    }),

    modalData: (data) => ({
        id: data?.pekerja_upah_ritase_cp_bulanan_id,
        komponenPayroll: data?.komponen_payroll ?? "",
        periodeGajianId: data?.periode_tahun,
        periodePerencanaan: data?.periode_tahun,
        pekerjaId: data?.pekerja_id,
        pekerjaNama: data?.pekerja_nama,
        nik: data?.nip,
        tglAwal: data?.tgl_bulan_awal,
        tglAkhir: data?.tgl_bulan_akhir,
        tglSetup: data?.tgl_setup,
        unitOrganisasiId: data?.master_unit_organisasi_id,
        unitOrganisasiNama: data?.master_unit_organisasi_nama,
        jabatanNama: data?.master_jabatan_nama,
        totalUpahRitase: data?.total_upah_ritase_cp
    }),

    formData: (data, filter) => ({
        periodeGajianId: filter?.periodeGajianId,
        periodePerencanaan: new Date().getFullYear(),
        bulan: filter?.bulan,
        tglAwal: filter?.tglAwal,
        tglAkhir: filter?.tglAkhir,
        tglSetup: filter?.tglSetup ?? new Date(),
        statusPekerjaId: filter?.statusPekerjaId,

        detail:
            data?.map((val) => ({
                nik: val?.nip,
                pekerjaId: val?.pekerja_id,
                pekerjaNama: val?.pekerja_nama,
                unitOrganisasiId: val?.master_unit_organisasi_id,
                unitOrganisasiNama: val?.master_unit_organisasi_nama,
                jabatanNama: val?.master_jabatan_nama,
                periodeGajianId: val?.periode_tahun,
                tglAwal: val?.tgl_bulan_awal,
                tglAkhir: val?.tgl_bulan_akhir,
                hasPosted: val?.has_posted
            })) ?? [],

    }),

};
