import {
  ButtonCreate, ButtonDelete, ButtonUpdate, DatePicker, InputSearch, ModalUpdate,
  Select, TableList, toastTrigger
} from "components";
import { Form, Formik } from "formik";
import { useLayout, useModalConfirm } from "hooks";
import { debounce } from "lodash";
import { useState } from "react";
import { ButtonGroup, Dropdown, Spinner } from "react-bootstrap";
import ReactDOM from "react-dom";
import { FiFileText } from "react-icons/fi";
import { useMutation, useQuery } from "react-query";
import { dateConvert, errorSubmitMapper, exportHandler } from "utilities";
import PresensiApi from "../__PresensiApi__";
import { formInitialValues, formSubmitMapper, formValidationSchema } from "../__PresensiUtils__";
import { ContentSingle } from "./ContentSingle";

export const TabRincianHarian = () => {
  // const navigate = useNavigate();
  const modalConfirm = useModalConfirm();
  const layout = useLayout();
  // const number = numberConvert();
  const date = dateConvert();

  // Config
  const [searchKey, setSearchKey] = useState(undefined);
  const [searchingData, setSearchingData] = useState({
    unitOrganisasiId: undefined,
    tglAwal: undefined,
    tglAkhir: undefined,
    pekerjaId: undefined,
    shiftId: undefined
  });
  const [pagination, setPagination] = useState({
    pageCurrent: 1,
    dataLength: 10
  });
  const [dataFilter, setDataFilter] = useState({
    unitOrganisasiId: undefined,
    tglAwal: undefined,
    tglAkhir: undefined,
    pekerjaId: undefined,
    shiftId: undefined
  });
  const [modal, setModal] = useState({
    show: false,
    action: "",
    data: {}
  });

  const elementTable = ReactDOM.findDOMNode(document.querySelector("table td[colspan='8']"));
  if (elementTable !== null) {
    elementTable.setAttribute("colspan", "11")
  }


  // Fetch Dropdown
  const getUnitOrganisasi = useQuery(["unit-organisasi", "dropdown"], () => PresensiApi.getUnitOrganisasiDropdown());
  const getPekerja = useQuery(["pekerja", "dropdown"], () => PresensiApi.getPekerja());
  const getShift = useQuery(["shift", "dropdown"], () => PresensiApi.getShift());

  // Fetch Data
  const getList = useQuery(
    [
      "rinci-harian",
      "list",
      searchKey,

      pagination.pageCurrent,
      pagination.dataLength,
      dataFilter?.pekerjaId,
      dataFilter?.unitOrganisasiId,
      dataFilter?.shiftId,
      dataFilter?.tglAwal,
      dataFilter?.tglAkhir
    ],
    () =>
      PresensiApi.getRincianHarian({
        search: searchKey,
        page: pagination.pageCurrent,
        limit: pagination.dataLength,
        tgl_awal: dataFilter?.tglAwal,
        tgl_akhir: dataFilter?.tglAkhir,
        master_unit_organisasi_id: dataFilter?.unitOrganisasiId,
        master_shift_id: dataFilter?.shiftId,
        pekerja_id: dataFilter?.pekerjaId
      }),
    {
      enabled: Boolean(dataFilter?.tglAwal && dataFilter?.tglAkhir)
    }
  );
  const getExport = useMutation(
    (data) =>
      PresensiApi.getExportRincianHarian({
        to: "excel",
        tgl_awal: data?.tglAwal,
        tgl_akhir: data?.tglAkhir,
        master_unit_organisasi_id: data?.unitOrganisasiId,
        pekerja_id: data?.pekerjaId
      }),
    {
      onSuccess: (link) => {
        toastTrigger({ variant: "success", message: "Export berhasil" });
        exportHandler(link, "Presensi Rincian Harian");
      },
      onError: () => {
        toastTrigger({ variant: "danger", message: "Export gagal" });
      }
    }
  );

  const deleteRincianHarian = useMutation((id) => PresensiApi.delete(id), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "delete" });
      getList.refetch();
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "delete", ...errorSubmitMapper(err) })
  });

  const updateRincianHarian = useMutation(({ data, id }) => PresensiApi.update(data, id), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "update" });
      getList.refetch();
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "update", ...errorSubmitMapper(err) })
  });

  const searchHandler = (e) => {
    setSearchKey(e?.target?.value ? e.target.value : undefined);
  };

  const onHideModalHandler = () => setModal({ show: false, action: "", data: {} });

  const onPaginationChange = (data) => setPagination({ ...pagination, pageCurrent: data });

  const onPaginationDataLengthChange = (data) => setPagination({ ...pagination, dataLength: data });

  const onUpdateButtonClickHandler = (data) => setModal({ show: true, action: "UPDATE", data: data });

  const submitValidationHandler = (errors) =>
    new Promise((resolve, reject) => {
      const getError = Object.values(errors);

      if (getError.length > 0) {
        reject(getError);
      } else {
        resolve();
      }
    });
  const preSubmitHandler = (formik) => {
    const { values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting } = formik;

    setSubmitting(true);
    validateForm().then(async (err) => {
      setErrors(err);
      setTouched(err);

      await submitValidationHandler(err, values)
        .then(() => handleSubmit())
        .catch((err) =>
          modalConfirm.trigger({
            type: "error",
            title: "Data Tidak Lengkap",
            data: err,
            onHide: () => modalConfirm.close()
          })
        )
        .finally(() => {
          setSubmitting(false);
        });
    });
  };

  const formSubmitHandler = (values, action) =>
    modalConfirm.trigger({
      type: action.toLowerCase(),
      alert: action === "DELETE" ? "Data akan dihapus secara permanen dan tidak dapat dikembalikan" : "",
      onHide: modalConfirm.close,
      onSubmit: () => {
        console.log("tete", values);
        modalConfirm.loading();

        if (action === "UPDATE") {
          updateRincianHarian
            .mutateAsync({ data: formSubmitMapper(values), id: values.id })
            .then(() => onHideModalHandler());
        } else if (action === "DELETE") {
          deleteRincianHarian.mutate(values.id);
        }
      },
      component: (
        <Formik initialValues={values}>
          <ContentSingle action="CONFIRM" data={values} />
        </Formik>
      )
    });

  return (
    <>
      <div className="d-flex justify-content-between">
        {/* Searching Field */}
        <div className="d-flex align-items-end mb-3 gap-2">
          <div style={{ maxWidth: 180 }}>
            <InputSearch onChange={debounce(searchHandler, 1500)} />
          </div>
          <div style={{ maxWidth: 180 }}>
            <DatePicker
              noMargin
              className="me-4"
              placeholderText="dd/mm/yyyy"
              label="Tanggal Awal"
              name="bulanPenggajianMulai"
              selected={searchingData?.tglAwal ?? ""}
              onChange={(val) => {
                setSearchingData({
                  ...searchingData,
                  tglAwal: val
                });
              }}
            />
          </div>
          <div>
            <DatePicker
              noMargin
              className="me-4"
              placeholderText="dd/mm/yyyy"
              label="Tanggal Akhir"
              name="tglAkhir"
              selected={searchingData?.tglAkhir ?? ""}
              onChange={(val) => {
                setSearchingData({
                  ...searchingData,
                  tglAkhir: val
                });
              }}
            />
          </div>

          <div style={{ width: 180 }}>
            <Select
              noMargin
              label="Shift"
              placeholder="Pilih salah satu.."
              options={getShift?.data ?? []}
              defaultValue={getShift?.data?.find((item) => item.value === searchingData?.shiftId)}
              loading={getShift?.isFetching}
              onChange={(val) =>
                setSearchingData({
                  ...searchingData,
                  shiftId: val.value
                })
              }
            />
          </div>
          <div style={{ width: 180 }}>
            <Select
              noMargin
              label="Unit Organisasi"
              placeholder="Pilih salah satu.."
              options={getUnitOrganisasi?.data ?? []}
              defaultValue={getUnitOrganisasi?.data?.find((item) => item.value === searchingData?.unitOrganisasiId)}
              loading={getUnitOrganisasi?.isFetching}
              onChange={(val) =>
                setSearchingData({
                  ...searchingData,
                  unitOrganisasiId: val.value,
                  unitOrganisasiNama: val.label
                })
              }
            />
          </div>
          <div style={{ width: 180 }}>
            <Select
              noMargin
              className="w-full"
              label="Pekerja"
              placeholder="Pilih salah satu.."
              options={getPekerja?.data ?? []}
              defaultValue={getPekerja?.data?.find((item) => item.value === searchingData?.pekerjaId)}
              loading={getPekerja?.isFetching}
              onChange={(val) =>
                setSearchingData({
                  ...searchingData,
                  pekerjaId: val.value,
                  pekerjaNama: val.label
                })
              }
            />
          </div>
          <div>
            <ButtonCreate
              text="Cari"
              tooltip={false}
              disabled={
                searchingData.tglAkhir === undefined ||
                searchingData.tglAwal === undefined
              }
              onClick={() => {
                setDataFilter({
                  ...dataFilter,
                  unitOrganisasiId: searchingData.unitOrganisasiId,
                  pekerjaId: searchingData.pekerjaId,
                  tglAwal: searchingData.tglAwal,
                  tglAkhir: searchingData.tglAkhir,
                  shiftId: searchingData.shiftId,
                  statusEnable: true
                });
                getList?.refetch();
                setSearchingData({ ...searchingData, statusEnable: false });
              }}
            />
          </div>
        </div>

        {/* Export and Create */}
        <div className="d-flex align-items-end mb-3">
          <Dropdown align="end">
            <Dropdown.Toggle
              size="sm"
              variant="outline-success"
              className="mx-2"
              disabled={getExport.isLoading}
            // disabled={true}
            >
              {getExport.isLoading ? (
                <Spinner size="sm" className="me-1" animation="border" />
              ) : (
                <FiFileText className="mb-1 mr-3" />
              )}
              <span className="mx-1">{getExport.isLoading ? "Memuat..." : "EXPORT"}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="mt-2">
              <Dropdown.Item onClick={() => getExport.mutate(dataFilter)}>Export Excel</Dropdown.Item>
              {/* <Dropdown.Item onClick={() => getExport.mutate("pdf")}>
              Export PDF
            </Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <TableList
        responsive
        data={getList?.data?.data}
        pagination={true}
        loading={getList.isFetching}
        dataTotal={getList?.data?.total}
        dataLength={pagination.dataLength}
        pageCurrent={pagination.pageCurrent}
        pageTotal={getList?.data?.last_page}
        onDataLengthChange={onPaginationDataLengthChange}
        onPaginationChange={onPaginationChange}
        rowProps={(val) => ({ style: { background: (parseInt(val.terlambat) !== 0) ? "#fc7c7c" : "white" } })}
        multipleTableHeader={[
          [
            {
              props: { rowSpan: 2 },
              text: "No"
            },

            {
              props: { rowSpan: 2 },
              text: "NIK"
            },
            {
              props: { rowSpan: 2 },
              text: "Nama"
            },
            {
              props: { rowSpan: 2 },
              text: "Tanggal"
            },

            {
              props: { colSpan: 2 },
              text: "Masuk"
            },
            {
              props: { colSpan: 2 },
              text: "Keluar"
            },
            {
              props: { colSpan: 2 },
              text: "Rincian"
            },
            {
              props: { rowSpan: 2 },
              text: "Aksi"
            }
          ],
          [
            {
              text: "Jam"
            },
            {
              text: "Terlambat"
            },
            {
              text: "Jam"
            },
            {
              text: "Pulang Cepat"
            },
            {
              text: "Durasi"
            },
            {
              text: "Lembur"
            },
          ]
        ]}
        tableBody={[
          { field: "nip" },
          { field: "pekerja_nama" },
          { customField: (data) => date.getSlashDMY(new Date(data.tgl_presensi)) },
          { field: "jam_in" },
          { customField: (data) => parseInt(data.terlambat) + " menit" },
          { field: "jam_out" },
          { customField: (data) => parseInt(data.pulang_cepat) + " menit" },
          { customField: (data) => parseInt(data.durasi) + " menit" },
          { customField: (data) => parseInt(data.lembur) + " menit" },

          {
            props: {
              width: 50,
              fixed: true
            },
            customField: (data, index) => (
              <div className="d-flex align-items-center">
                <ButtonGroup size="sm">
                  <ButtonUpdate icon={true} noText onClick={() => onUpdateButtonClickHandler(data)} />
                  <ButtonDelete
                    icon={true}
                    noText
                    onClick={() => formSubmitHandler(formInitialValues(data), "DELETE")}
                  />
                </ButtonGroup>
              </div>
            )
          }
        ]}
      />
      {/**
       * Modal Update:
       * Modal untuk mengubah data
       */}
      <Formik
        enableReinitialize
        initialValues={formInitialValues(modal.data)}
        validationSchema={formValidationSchema}
        onSubmit={(values) => formSubmitHandler(values, "UPDATE")}
      >
        {(formik) => {
          return (
            <Form>
              <ModalUpdate
                title={layout.getActivePageName()}
                show={Boolean(modal.show && modal.action === "UPDATE")}
                onHide={() => {
                  onHideModalHandler();
                  formik.resetForm();
                }}
                onSubmit={() => preSubmitHandler(formik)}
              >
                <ContentSingle action="UPDATE" />
              </ModalUpdate>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
