import {
  PengajuanPekerjaPensiunList,
  PengajuanPekerjaPensiunDetail,
  PengajuanPekerjaPensiunCreate
} from "pages/OprasionalSdm";

export const PengajuanPekerjaPensiun = {
  link: "pengajuan-pekerja-pensiun",
  name: "Pengajuan Pekerja Pensiun",
  access: "PEKERJA_PENSIUN",
  component: <PengajuanPekerjaPensiunList />,
  child: [
    {
      name: "Detail",
      link: "/detail/:id",
      component: <PengajuanPekerjaPensiunDetail />
    },
    {
      name: "Tambah",
      link: "/tambah",
      component: <PengajuanPekerjaPensiunCreate />
    }
  ]
};
