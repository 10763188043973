export const formInitialValues = (data = {}) => ({
  dataFormSetup: {
    dataPengirimanBeton: data?.file_pengiriman_beton ?? ""
  },

  detailData: {
    id: data?.pengiriman_beton_id ?? "",
    tglPengiriman: data?.tgl_pengiriman ?? "",
    noDoket: data?.no_doket ?? "",
    kustomer: data?.nama_kustomer ?? "",
    proyek: data?.proyek ?? "",
    supir: data?.supir ?? "",
    noTm: data?.no_tm ?? "",
    mutu: data?.mutu ?? "",
    volume: data?.volume ?? "",
    jenisRitaseId: data?.jenis_ritase_id ?? "",
    jenisRitaseKode: data?.jenis_ritase_kode ?? "",
    jenisRitaseNama: data?.jenis_ritase_nama ?? "",
    jarak: data?.jarak ?? "",
    pekerjaId: data?.pekerja_id ?? "",
    pekerjaNama: data?.pekerja_nama ?? "",
    statusRitase: data?.status_ritase ?? "",
    statusKubikasi: data?.status_kubikasi ?? ""
  }
});
