import * as Yup from "yup";

export const formValidationSchema = Yup.object().shape({
  idPeriode: Yup.string().required("Periode wajib dipilih"),
  tglSetup: Yup.string().required("Tanggal setup wajib dipilih"),
  idTahapPemberian: Yup.string().required("Tahap pemberian wajib dipilih"),
  idStatusPekerja: Yup.string().required("Status pekerja wajib dipilih"),
  proporsiThr: Yup.string().required("Proporsi thr wajib diisi"),
  idStatusThr: Yup.string().required("Status thr wajib dipilih"),
  keterangan: Yup.string().required("Keterangan wajib diisi")
});
