import { Services } from "services";
import { fetchDropdownMapper } from "utilities";

const fetchDropdown = fetchDropdownMapper(Services);

class SetupJaminanBpjsApi {
  async getList(params) {
    const fetch = await Services.get("/transaksi/setup-jaminan-bpjs", { params });
    return fetch?.data?.data;
  }

  async getExport(params) {
    const fetch = await Services.get("/transaksi/setup-jaminan-bpjs/export", { params });
    return fetch?.data?.data;
  }

  async getPeriodePenggajian() {
    const getPeriodePenggajian = await fetchDropdown("/utility/periode-tahun?feat=setup-jaminan-bpjs", {
      value: "periode_tahun",
      label: "periode_tahun"
    });

    return getPeriodePenggajian;
  }

  async getJenisUpahMinimum() {
    const getJenisUpahMinimum = await fetchDropdown("/master/jenis-upah-minimum/dropdown", {
      value: "jenis_upah_id",
      label: "jenis_upah_nama"
    });
    return getJenisUpahMinimum;
  }
  async getUpahBatasMinimum(params) {
    const fetch = await Services.get("/master/nominal-upah-bpjs/dropdown", { params });
    return fetch?.data?.data?.length === 1
      ? fetch?.data?.data[0]
      : { nominal_upah_minimum_id: undefined, batasBawahUpah: undefined };
  }
  async getKomponenBpjs() {
    const getKomponenBpjs = await fetchDropdown("/master/komponen-bpjs", {
      value: "komponen_bpjs_id",
      label: "komponen_bpjs_nama"
    });

    return getKomponenBpjs;
  }

  create(data) {
    return Services.post("/transaksi/setup-jaminan-bpjs", data);
  }

  update(data, id) {
    return Services.put("/transaksi/setup-jaminan-bpjs/" + id, data);
  }

  delete(id) {
    return Services.delete("/transaksi/setup-jaminan-bpjs/" + id);
  }
}
export default new SetupJaminanBpjsApi();
