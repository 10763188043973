import * as Yup from "yup";

export const formValidationSchema = Yup.object().shape({
    nama: Yup.string().required("Nama proyek wajib diisi"),
    pemilikId: Yup.string().required("Pemilik wajib diisi"),
    contactPerson: Yup.string().required("Contact person wajib diisi"),
    hp: Yup.string().required("Nomor hp wajib diisi"),
    provinsiKode: Yup.string().required("Provinsi wajib diisi"),
    kabupatenKode: Yup.string().required("Kabupaten/Kota wajib diisi"),
    kecamatanKode: Yup.string().required("Kecamatan wajib diisi"),
    desaKode: Yup.string().required("Desa/Kelurahan wajib diisi"),
    alamat: Yup.string().required("Alamat wajib diisi"),
});
