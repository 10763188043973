import { SetupKomponenJaminanHariTuaBulananList, SetupKomponenJaminanHariTuaBulananCreate } from "pages/Payroll";

export const SetupKomponenJaminanHariTuaBulanan = {
  component: <SetupKomponenJaminanHariTuaBulananList />,
  link: "setup-komponen-jaminan-hari-tua-bulanan",
  name: "Setup Komponen Jaminan Hari Tua Bulanan",
  access: "SETUP_KOMPONEN_JAMINAN_HARI_TUA_BULANAN",
  child: [
    {
      name: "Tambah",
      link: "/tambah",
      component: <SetupKomponenJaminanHariTuaBulananCreate />
    }
  ]
};
