import React from "react";
import { useFormikContext } from "formik";
import { InfoItemVertical, Select, numberConvert, InputNumber, InputCurrency } from "components";
import { useQuery } from "react-query";
import UpahRitaseApi from "../__UpahRitaseApi__";

export const Content = ({ action }) => {
  const { values, errors, touched, setValues } = useFormikContext();
  const number = numberConvert();

  const getJenisRitase = useQuery(["jenis-ritase", "dropdown"], () => UpahRitaseApi.getJenisRitase());

  if (action === "READ") {
    return (
      <>
        <InfoItemVertical label="Jenis Ritase" text={values.jenisRitaseNama} />
        <InfoItemVertical label="Urutan Ritase" text={values.urutanRitase} />
        <InfoItemVertical label="Jarak Bawah" text={values.jarakBawah + " km"} />
        <InfoItemVertical label="Jarak Atas" text={values.jarakAtas + " km"} />
        <InfoItemVertical label="Upah" text={"Rp" + number.getWithComa(values.upah, { minimumFractionDigits: 2 })} />
      </>
    );
  }

  return (
    <>
      <Select
        label="Jenis Ritase"
        placeholder="Pilih jenis ritase"
        defaultValue={
          values.jenisRitaseKode ? getJenisRitase?.data?.find((item) => item.kode === values.jenisRitaseKode) : null
        }
        options={getJenisRitase?.data ?? []}
        onChange={(val) =>
          setValues({
            ...values,
            jenisRitaseId: val.value,
            jenisRitaseNama: val.label,
            jenisRitaseKode: val.kode
          })
        }
        loading={getJenisRitase.isFetching}
        errorFetch={getJenisRitase.isError}
        error={Boolean(errors.jenisRitaseKode && touched.jenisRitaseKode)}
        errorText={Boolean(errors.jenisRitaseKode && touched.jenisRitaseKode) && errors.jenisRitaseKode}
      />
      <InputNumber
        label="Urutan Ritase"
        name="urutanRitase"
        placeholder="Masukkan urutan ritase"
        value={values.urutanRitase}
        onChange={(val) =>
          setValues({
            ...values,
            urutanRitase: val
          })
        }
        error={Boolean(errors.urutanRitase && touched.urutanRitase)}
        errorText={Boolean(errors.urutanRitase && touched.urutanRitase) && errors.urutanRitase}
      />
      <InputNumber
        label="Jarak Bawah"
        name="jarakBawah"
        placeholder="Masukkan jarak bawah"
        suffix=" km"
        value={values.jarakBawah}
        onChange={(val) =>
          setValues({
            ...values,
            jarakBawah: val
          })
        }
        error={Boolean(errors.jarakBawah && touched.jarakBawah)}
        errorText={Boolean(errors.jarakBawah && touched.jarakBawah) && errors.jarakBawah}
      />
      <InputNumber
        label="Jarak Atas"
        name="jarakAtas"
        placeholder="Masukkan jarak atas"
        suffix=" km"
        value={values.jarakAtas}
        onChange={(val) =>
          setValues({
            ...values,
            jarakAtas: val
          })
        }
        error={Boolean(errors.jarakAtas && touched.jarakAtas)}
        errorText={Boolean(errors.jarakAtas && touched.jarakAtas) && errors.jarakAtas}
      />
      <InputCurrency
        label="Upah"
        name="upah"
        placeholder="Masukkan jarak bawah"
        value={number.getWithComa(values.upah, { minimumFractionDigits: 2 })}
        onChange={(val) =>
          setValues({
            ...values,
            upah: val
          })
        }
        error={Boolean(errors.upah && touched.upah)}
        errorText={Boolean(errors.upah && touched.upah) && errors.upah}
      />
    </>
  );
};
