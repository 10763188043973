import { Services } from "services";
import { dateConvert } from "@bhawanadevteam/react-core";

const date = dateConvert();

class RekamanAktivitasApi {
  async getList(params) {
    const fetch = await Services.get("/transaksi/rekaman-aktivitas", { params });
    return fetch?.data?.data;
  }

  async getDetail(id) {
    const fetch = await Services.get("/transaksi/rekaman-aktivitas/" + id);
    return fetch?.data?.data;
  }

  async getPeriode() {
    const fetch = await Services.get("/master/periode-perencanaan");
    return fetch?.data?.data.map((val) => ({
      value: val?.master_periode_perencanaan_id,
      label: val?.periode_perencanaan
    }));
  }
  async getUnitOrganisasi() {
    const fetch = await Services.get("/master/unit-organisasi");
    return fetch?.data?.data.map((val) => ({
      value: val?.master_unit_organisasi_id,
      label: val?.master_unit_organisasi_nama
    }));
  }

  async getPekerja(params) {
    const fetch = await Services.get("/transaksi/registrasi-pekerja/dropdown", { params });
    return fetch?.data?.data.map((val) => ({
      value: val?.pekerja_id,
      label: val?.pekerja_nama
    }));
  }

  async getTanggal(params) {
    const fetch = await Services.get("/transaksi/rekaman-aktivitas/dropdown-tanggal", { params });
    return fetch?.data?.data.map((val) => ({
      value: val,
      label: date.getSlashDMY(new Date(val))
    }));
  }

  async getBulan() {
    return Array.from({ length: 12 }, (item, i) => {
      return {
        value: i + 1,
        label: new Date(0, i).toLocaleString("id-ID", { month: "long" })
      };
    });
  }

  async getStatusKegiatan() {
    return [
      {
        value: 1,
        label: "Belum Selesai"
      },
      {
        value: 2,
        label: "Sudah Selesai"
      },
      {
        value: 3,
        label: "Ditunda"
      }
    ];
  }

  async getExport(params) {
    const fetch = await Services.get("/transaksi/rekaman-aktivitas/export", { params });
    return fetch?.data?.data;
  }

  create(data) {
    return Services.post("/transaksi/rekaman-aktivitas", data);
  }

  update(data, id) {
    return Services.put("/transaksi/rekaman-aktivitas/" + id, data);
  }

  delete(id) {
    return Services.delete("/transaksi/rekaman-aktivitas/" + id);
  }
}

export default new RekamanAktivitasApi();
