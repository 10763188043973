import { Services } from "services";

class TerStatusPtkpApi {
    async getList(params) {
        const fetch = await Services.get("/master/tarif-efektif-status-ptkp", { params });
        return fetch?.data?.data;
    }

    async getDetail(id) {
        const fetch = await Services.get("/master/tarif-efektif-status-ptkp/" + id);
        return fetch?.data?.data;
    }

    async getStatusPtkp() {
        const fetch = await Services.get("/master/tarif-ptkp/dropdown");
        return fetch?.data?.data.map((val) => ({
            value: val?.tarif_ptpk_id,
            label: val?.status_pajak
        }));
    }

    async getExport(params) {
        const fetch = await Services.get("/master/tarif-efektif-status-ptkp/export", { params });
        return fetch?.data?.data;
    }

    create(data) {
        return Services.post("/master/tarif-efektif-status-ptkp", data);
    }

    update(data, id) {
        return Services.put("/master/tarif-efektif-status-ptkp/" + id, data);
    }

    delete(id) {
        return Services.delete("/master/tarif-efektif-status-ptkp/" + id);
    }
}

export default new TerStatusPtkpApi();
