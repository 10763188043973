import React from "react";
import { useQuery } from "react-query";
import { Card } from "react-bootstrap";
import { useFormikContext } from "formik";
import { Input, Select, InfoItemVertical } from "components";
import { errorFetchingSelectMapper } from "utilities";
import EvaluasiJabatanApi from "../__EvaluasiJabatanApi__";

const DetailJabatan = () => {
  const { values } = useFormikContext();

  return (
    <>
      {/* Jenis Jabatan */}
      <InfoItemVertical label="Jenis Jabatan " text={values?.jenis_jabatan_nama} />
      {/* Jabatan */}
      <InfoItemVertical label="Jabatan " text={values?.jabatan_nama} />
      {/* Unit Organisasi */}
      <InfoItemVertical label="Unit Organisasi" text={values?.unit_organisasi_nama} />
    </>
  );
};

const FormJabatan = () => {
  const { values, touched, errors, setValues } = useFormikContext();
  const getJenisJabatan = useQuery(["jenis-jabatan", "dropdown"], () => EvaluasiJabatanApi.getJenisJabatan());
  const getJabatan = useQuery(
    ["jabatan", "dropdown", values?.jenis_jabatan_id],
    () => EvaluasiJabatanApi.getJabatan(values?.jenis_jabatan_id),
    {
      enabled: Boolean(values?.jenis_jabatan_id)
    }
  );
  // console.log(values);
  return (
    <>
      <Select
        label="Jenis Jabatan"
        placeholder="Pilih jenis jabatan"
        options={getJenisJabatan.data}
        loading={getJenisJabatan.isLoading}
        errorFetch={getJenisJabatan.isError}
        errorFetchText={errorFetchingSelectMapper(getJenisJabatan.error)}
        defaultValue={
          values.jenis_jabatan_id ? getJenisJabatan?.data?.find((val) => val.value === values.jenis_jabatan_id) : []
        }
        onChange={(val) => {
          setValues({
            ...values,
            jenis_jabatan_id: val.value,
            jenis_jabatan_nama: val.label,
            jabatan_id: "",
            jabatan_nama: "",
            unit_organisasi_id: "",
            unit_organisasi_nama: ""
          });
        }}
        error={Boolean(errors.jenis_jabatan_id && touched.jenis_jabatan_id)}
        errorText={Boolean(errors.jenis_jabatan_id && touched.jenis_jabatan_id) && errors.jenis_jabatan_id}
      />
      <Select
        disable={!values?.jenis_jabatan_id}
        label="Jabatan"
        placeholder="Pilih jenis jabatan untuk menentukan jenis jabatan"
        options={getJabatan.data}
        loading={getJabatan.isLoading}
        errorFetch={getJabatan.isError}
        errorFetchText={errorFetchingSelectMapper(getJabatan.error)}
        defaultValue={values.jabatan_id ? getJabatan?.data?.find((val) => val.value === values.jabatan_id) : []}
        onChange={(val) => {
          setValues({
            ...values,
            jabatan_id: val.value,
            jabatan_nama: val.label,
            unit_organisasi_id: val.unit_organisasi_id,
            unit_organisasi_nama: val.unit_organisasi_nama
          });
        }}
        error={Boolean(errors.jabatan_id && touched.jabatan_id)}
        errorText={Boolean(errors.jabatan_id && touched.jabatan_id) && errors.jabatan_id}
      />
      {values?.jenis_jabatan_id === 1 ? (
        <Input
          key={values.unit_organisasi_nama}
          disabled
          label="Unit Organisasi"
          placeholder="Pilih jabatan untuk menentukan unit organisasi"
          value={values?.unit_organisasi_nama}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export const SectionJabatan = ({ action }) => {
  return (
    <Card>
      <Card.Header>
        <b>Jabatan</b>
      </Card.Header>
      <Card.Body>{action === "READ" || action === "CONFIRM" ? <DetailJabatan /> : <FormJabatan />}</Card.Body>
    </Card>
  );
};
