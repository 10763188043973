import { ButtonBack, DataStatus } from "components";
import { Formik } from "formik";
import { ContentLayout } from "layouts";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import SuratPerintahLemburApi from "./__SuratPerintahLemburApi__";
import { Content } from "./__SuratPerintahLemburComps__";
import { formInitialValues } from "./__SuratPerintahLemburUtilities__";

export const SuratPerintahLemburDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  // const modalConfirm = useModalConfirm();

  const getDetail = useQuery(["surat-perintah-lembur", "detail", id], () => SuratPerintahLemburApi.getDetail(id));

  return (
    <>
      <ContentLayout showBreadcrumb>
        {getDetail?.isFetching ? (
          <DataStatus loading />
        ) : (
          <>
            <div className="text-end mb-3">
              <ButtonBack
                tooltip={false}
                onClick={() => navigate("/operasional-sdm/transaksi/surat-perintah-lembur")}
              />
            </div>
            <Formik enableReinitialize initialValues={formInitialValues(getDetail?.data)}>
              <Content action="READ" />
            </Formik>
          </>
        )}
      </ContentLayout>
    </>
  );
};
