import {
  ButtonCreate, ButtonDelete, ButtonUpdate, Checkbox, CheckboxWrapper, dateConvert, DatePicker, InfoItemVertical, Input, Select, TableList, TextArea
} from "components";
import { Formik, useFormikContext } from "formik";
import { useModalConfirm } from "hooks";
import { useState } from "react";
import { ButtonGroup, Card, Col, Row } from "react-bootstrap";
import { useQuery } from "react-query";
import * as Yup from "yup";
import SuratPerintahLemburApi from "../__SuratPerintahLemburApi__";

const ContentLembur = ({ action, onDelete, onButtonUpdateClick }) => {
  const modalConfirm = useModalConfirm();
  // const number = numberConvert();
  const { values } = useFormikContext();

  return (
    <TableList
      tableHeader={[
        {
          text: "Pekerja"
        },
        {
          text: "Tugas Lembur"
        },
        {
          text: "Jam Mulai"
        },
        {
          text: "Jam Selesai"
        },
        {
          text: "Aksi",
          props: {
            width: 50,
            fixed: true,
            style: { display: action === "READ" ? "none" : "" }
          }
        }
      ]}
      tableBody={[
        {
          field: "pekerjaNama"
        },
        {
          field: "tugasLembur"
        },
        {
          props: { textCenter: true },
          field: "jamIn"
        },
        {
          props: { textCenter: true },
          field: "jamOut"
        },
        {
          props: {
            fixed: true,
            style: { display: action === "READ" && "none" }
          },
          customField: (val, index) => (
            <ButtonGroup size="sm" className="mx-1">
              {action !== "READ" && (
                <>
                  <ButtonDelete
                    icon
                    noText
                    tooltip={false}
                    onClick={() =>
                      modalConfirm.trigger({
                        type: "delete",
                        alert: true,
                        customAlertText: "Data yang terhapus tidak dapat dikembalikan",
                        onHide: modalConfirm.close,
                        onSubmit: () => {
                          modalConfirm.loading();
                          onDelete(index);
                          modalConfirm.close();
                        },
                        component: (
                          <>
                            <InfoItemVertical label="Pekerja" text={val.pekerjaNama} />
                            <InfoItemVertical label="Tugas Lembur" text={val.tugasLembur} />
                            <InfoItemVertical label="Jam Mulai" text={val.jamIn} />
                            <InfoItemVertical label="Jam Selesai" text={val.jamOut} />
                          </>
                        )
                      })
                    }
                  />
                  <ButtonUpdate
                    icon
                    noText
                    tooltip={false}
                    onClick={() => {
                      onButtonUpdateClick({ index: index, data: val, action: "UPDATE" });
                      onDelete(index);
                    }}
                  />
                </>
              )}
            </ButtonGroup>
          )
        }
      ]}
      data={values?.detail}
    />
  );
};

export const Content = ({ action }) => {
  const { values, setValues, errors, touched, setFieldValue, handleChange } = useFormikContext();
  const date = dateConvert();

  const getUnitOrganisasi = useQuery(["unit-organisasi", "dropdown"], () => SuratPerintahLemburApi.getUnitOrganisasi());

  const getPekerja = useQuery(["pekerja", "dropdown", values?.unitOrganisasiId], () =>
    SuratPerintahLemburApi.getPekerja({
      master_unit_organisasi_id: values?.unitOrganisasiId
    })
  );

  const [temp, setTemp] = useState({
    action: "CREATE",
    index: "",
    data: {}
  });

  const resetFormHandler = () => {
    setTemp({ action: "CREATE", data: {}, index: "" });
  };

  const createHandler = (obj) => {
    let newValue = [...values?.detail];

    setFieldValue("detail", [...newValue, { ...obj }]);
  };

  const updateHandler = (val) => {
    let newValue = [...values.detail];
    newValue[temp.index] = val;

    setFieldValue("detail", newValue);
    resetFormHandler();
  };

  const deleteHandler = (index) => {
    let newValue = [...values?.detail].filter((_v, i) => index !== i);

    setFieldValue("detail", newValue);
  };

  const onSubmit = (val) => {
    if (temp?.action === "CREATE") {
      createHandler(val);
    }
    if (temp?.action === "UPDATE") {
      updateHandler(val);
    }
  };

  // fungsi cek weekend
  let isWeekend = (lemburDate) => [0, 6].includes(new Date(lemburDate).getDay());

  return (
    <>
      <Row>
        <Col md={4}>
          <Card style={{ height: "100%" }}>
            <Card.Header>
              <b>Setup Data</b>
            </Card.Header>
            <Card.Body>
              {action === "READ" ? (
                <>
                  <InfoItemVertical label="Tanggal Lembur" text={date.getSlashDMY(new Date(values?.tglLembur))} />
                  <InfoItemVertical label="Sub Unit" text={values?.unitOrganisasiNama} />
                  <InfoItemVertical label="Nomor Surat Perintah Lembur" text={values?.nomor} />
                  <InfoItemVertical
                    label="Uang Kompensasi Lembur diluar hari kerja"
                    text={
                      values?.kompensasiLembur === true ? "Diberikan" : "Tidak diberikan"
                    }
                  />
                </>
              ) : (
                <>
                  <DatePicker
                    disabled={Boolean(action !== "CREATE")}
                    label="Tanggal Lembur"
                    placeholderText="Pilih tanggal lembur"
                    selected={values?.tglLembur}
                    onChange={(val) =>
                      setValues({
                        ...values,
                        tglLembur: val,
                        kompensasiLembur: isWeekend(val)
                      })
                    }
                    error={Boolean(errors.tglLembur && touched.tglLembur)}
                    errorText={Boolean(errors.tglLembur && touched.tglLembur) && errors.tglLembur}
                  />

                  <Select
                    disable={Boolean(action !== "CREATE")}
                    label="Sub Unit"
                    placeholder="Pilih sub unit organisasi"
                    defaultValue={getUnitOrganisasi?.data?.find((item) => item.value === values.unitOrganisasiId)}
                    options={getUnitOrganisasi?.data ?? []}
                    onChange={(val) =>
                      setValues({
                        ...values,
                        unitOrganisasiId: val.value,
                        unitOrganisasiNama: val.label
                      })
                    }
                    loading={getUnitOrganisasi.isFetching}
                    errorFetch={getUnitOrganisasi.isError}
                    error={Boolean(errors.unitOrganisasiId && touched.unitOrganisasiId)}
                    errorText={Boolean(errors.unitOrganisasiId && touched.unitOrganisasiId) && errors.unitOrganisasiId}
                  />
                  <Input
                    name="nomor"
                    disabled={Boolean(action !== "CREATE")}
                    label="Nomor Surat Perintah Lembur"
                    placeholder="Masukkan nomor"
                    value={values?.nomor}
                    onChange={handleChange}
                    error={Boolean(errors.nomor && touched.nomor)}
                    errorText={Boolean(errors.nomor && touched.nomor) && errors.nomor}
                  />
                  <CheckboxWrapper label="Uang Kompensasi">
                    <Checkbox
                      name={values?.kompensasiLembur}
                      checked={values?.kompensasiLembur === true}
                      onChange={() =>
                        values?.kompensasiLembur === true
                          ? setValues({
                            ...values,
                            kompensasiLembur: false
                          })
                          : setValues({
                            ...values,
                            kompensasiLembur: true
                          })
                      }
                    />
                  </CheckboxWrapper>
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col md={8}>
          <Card style={{ height: "100%" }}>
            <Card.Header>
              <b>Data Pekerja</b>
            </Card.Header>
            <Card.Body>
              {action !== "READ" ? (
                <Formik
                  enableReinitialize
                  initialValues={{
                    pekerjaId: temp?.action === "CREATE" ? "" : temp?.data?.pekerjaId,
                    pekerjaNama: temp?.action === "CREATE" ? "" : temp?.data?.pekerjaNama,
                    tugasLembur: temp?.action === "CREATE" ? "" : temp?.data?.tugasLembur,
                    jamIn: temp?.action === "CREATE" ? "" : temp?.data?.jamIn,
                    jamOut: temp?.action === "CREATE" ? "" : temp?.data?.jamOut
                  }}
                  validationSchema={Yup.object().shape({
                    pekerjaId: Yup.string().required("Pekerja wajib diisi"),
                    tugasLembur: Yup.string().required("Tugas lembur wajib diisi"),
                    jamIn: Yup.string().required("Jam mulai wajib diisi"),
                    jamOut: Yup.string().required("Jam selesai wajib diisi")
                  })}
                  onSubmit={(val, { resetForm }) => {
                    onSubmit(val);
                    resetForm();
                  }}
                >
                  {({ values, errors, touched, handleChange, setValues, handleSubmit }) => (
                    <>
                      <Row>
                        <Col>
                          <Select
                            label="Pekerja"
                            placeholder="Pilih pekerja"
                            defaultValue={getPekerja?.data?.find((item) => item.value === values.pekerjaId)}
                            options={getPekerja?.data ?? []}
                            onChange={(val) =>
                              setValues({
                                ...values,
                                pekerjaId: val.value,
                                pekerjaNama: val.label
                              })
                            }
                            loading={getPekerja.isFetching}
                            errorFetch={getPekerja.isError}
                            error={Boolean(errors.pekerjaId && touched.pekerjaId)}
                            errorText={Boolean(errors.pekerjaId && touched.pekerjaId) && errors.pekerjaId}
                          />
                        </Col>
                        <Col>
                          <Row>
                            <Col>
                              <Input
                                label="Jam Mulai"
                                type="time"
                                value={values?.jamIn}
                                onChange={(val) =>
                                  setValues({
                                    ...values,
                                    jamIn: val.target.value
                                  })
                                }
                                error={Boolean(errors.jamIn && touched.jamIn)}
                                errorText={Boolean(errors.jamIn && touched.jamIn) && errors.jamIn}
                              />
                            </Col>
                            <Col>
                              <Input
                                label="Jam Selesai"
                                type="time"
                                value={values?.jamOut}
                                onChange={(val) =>
                                  setValues({
                                    ...values,
                                    jamOut: val.target.value
                                  })
                                }
                                error={Boolean(errors.jamOut && touched.jamOut)}
                                errorText={Boolean(errors.jamOut && touched.jamOut) && errors.jamOut}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <TextArea
                        label="Tugas Lembur"
                        name="tugasLembur"
                        placeholder="Masukkan tugas lembur"
                        value={values.tugasLembur}
                        onChange={(val) =>
                          setValues({
                            ...values,
                            tugasLembur: val.target.value
                          })
                        }
                        error={Boolean(errors.tugasLembur && touched.tugasLembur)}
                        errorText={Boolean(errors.tugasLembur && touched.tugasLembur) && errors.tugasLembur}
                      />
                      <div className="text-end">
                        <ButtonCreate icon={true} tooltip={false} className="mb-3" onClick={handleSubmit} />
                      </div>
                    </>
                  )}
                </Formik>
              ) : (
                <></>
              )}
              <ContentLembur
                action={action}
                onDelete={deleteHandler}
                onButtonUpdateClick={(val) => setTemp({ data: val.data, index: val.index, action: "UPDATE" })}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
