import * as Yup from "yup";

export const formValidationSchema = Yup.object().shape({
  unitOrganisasiNama: Yup.string().required("Unit Organisasi wajib diisi"),

  perspektifKpiNama: Yup.string().required("Perspektif wajib diisi"),

  sasaranKpiNama: Yup.string().required("Sasaran  wajib diisi"),

  kpiNama: Yup.string().required("KPI wajib diisi"),

  definisi: Yup.string().required("Definisi wajib diisi"),

  periode: Yup.number().required("Periode wajib diisi"),

  periodeSatuanKpiId: Yup.string().required("Satuan periode wajib diisi"),

  formula: Yup.string().required("Formula wajib diisi"),

  polaritas: Yup.string().required("Polaritas wajib diisi"),

  frekuensi: Yup.number().required("Frekuensi wajib diisi"),
  frekuensiSatuanKpiId: Yup.string().required("Satuan Frekuensi wajib diisi")
});
