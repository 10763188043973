import { Services } from "services";
import { fetchDropdownMapper } from "utilities";

const fetchDropdown = fetchDropdownMapper(Services);

class PengajuanPekerjaPensiunApi {
  async getList(params) {
    const fetch = await Services.get("/transaksi/pekerja-pensiun", { params });
    return fetch?.data?.data;
  }
  async getListForm(params) {
    const fetch = await Services.get("/transaksi/pekerja-pensiun/form", { params });
    return fetch?.data?.data;
  }
  async getSingle(id) {
    const fetch = await Services.get("/transaksi/pekerja-pensiun/" + id);
    return fetch?.data;
  }
  async getGradenominal() {
    const getPeriodeDropdown = await fetchDropdown("/master/grade-nominal/dropdown", {
      value: "periode_gajian_id",
      label: "periode_perencanaan"
    });
    return getPeriodeDropdown;
  }
  async getUnitOrganisasiForList(params) {
    const fetch = await Services.get("/transaksi/pekerja-pensiun/dropdown-unit", { params });
    return fetch?.data?.data.map((val) => ({
      value: val?.master_unit_organisasi_id,
      label: val?.master_unit_organisasi_nama
    }));
  }

  async getPeriode() {
    const fetch = await Services.get("/utility/periode-tahun?feat=pekerja-pensiun");
    return fetch?.data?.data.map((val) => ({
      value: val?.periode_tahun,
      label: val?.periode_tahun
    }));
  }
  async getUnitOrganisasi() {
    const getUnit = await fetchDropdown("/master/unit-organisasi/dropdown", {
      value: "master_unit_organisasi_id",
      label: "master_unit_organisasi_nama"
    });
    return getUnit;
  }
  async getExport(params) {
    const fetch = await Services.get("/transaksi/pekerja-pensiun/export?", { params });
    return fetch?.data?.data;
  }
  create(data) {
    return Services.post("/transaksi/pekerja-pensiun", data);
  }
}
export default new PengajuanPekerjaPensiunApi();
