import { Services } from "services";

class SetupKomponenJaminanKehilanganPekerjaanBulananApi {
  async getList(params) {
    const fetch = await Services.get("/transaksi/setup-komponen-jaminan-kehilangan-pekerjaan-bulanan/", { params });
    return fetch?.data?.data;
  }

  async getDetail(id) {
    const fetch = await Services.get("/transaksi/setup-komponen-jaminan-kehilangan-pekerjaan-bulanan/" + id);
    return fetch?.data?.data;
  }

  async getSetupForm(params) {
    const fetch = await Services.get("/transaksi/setup-komponen-jaminan-kehilangan-pekerjaan-bulanan/form", { params });
    return fetch?.data;
  }

  async getUnitOrganisasi() {
    const fetch = await Services.get("master/unit-organisasi/dropdown");
    return fetch?.data?.data.map((val) => ({
      value: val?.master_unit_organisasi_id,
      label: val?.master_unit_organisasi_nama
    }));
  }

  async getUnitOrganisasiForList() {
    const fetch = await Services.get("/transaksi/setup-komponen-jaminan-kehilangan-pekerjaan-bulanan/dropdown-unit");
    return fetch?.data?.data.map((val) => ({
      value: val?.master_unit_organisasi_id,
      label: val?.master_unit_organisasi_nama
    }));
  }

  async getPeriode() {
    const fetch = await Services.get("/utility/periode-tahun?feat=setup-komponen-jaminan-kehilangan-pekerjaan-bulanan");
    return fetch?.data?.data.map((val) => ({
      value: val?.periode_tahun,
      label: val?.periode_tahun
    }));
  }

  async getBulan() {
    return Array.from({ length: 12 }, (item, i) => {
      return {
        value: i + 1,
        label: new Date(0, i).toLocaleString("id-ID", { month: "long" })
      };
    });
  }

  async getTanggal(params) {
    const fetch = await Services.get(
      "/transaksi/setup-komponen-jaminan-kehilangan-pekerjaan-bulanan/dropdown-tanggal",
      { params }
    );
    return fetch?.data?.data.map((val) => ({
      value: val?.tgl_bulan_awal + "/&/" + val?.tgl_bulan_akhir,
      label: val?.tgl_bulan_awal + " s/d " + val?.tgl_bulan_akhir,
      tglAwal: val?.tgl_bulan_awal,
      tglAkhir: val?.tgl_bulan_akhir
    }));
  }

  async getExport(params) {
    const fetch = await Services.get("/transaksi/setup-komponen-jaminan-kehilangan-pekerjaan-bulanan/export", {
      params
    });
    return fetch?.data?.data;
  }

  create(data) {
    return Services.post("/transaksi/setup-komponen-jaminan-kehilangan-pekerjaan-bulanan", data);
  }

  update(data, id) {
    return Services.put("/transaksi/setup-komponen-jaminan-kehilangan-pekerjaan-bulanan/" + id, data);
  }

  delete(id) {
    return Services.delete("/transaksi/setup-komponen-jaminan-kehilangan-pekerjaan-bulanan/" + id);
  }
}
export default new SetupKomponenJaminanKehilanganPekerjaanBulananApi();
