import * as Yup from "yup";

export const formValidationSchema = Yup.object().shape({
  tglInput: Yup.string().required("Tanggal input surat dinas wajib diisi"),
  nomor: Yup.string().required("Nomor wajib diisi"),
  unitOrganisasiId: Yup.string().required("Unit organisasi wajib diisi"),
  pekerjaId: Yup.string().required("Pekerja wajib diisi"),
  tglMulai: Yup.string().required("Tanggal mulai dinas wajib diisi"),
  tglAkhir: Yup.string().required("Tanggal akhir dinas wajib diisi"),
  lamaPenugasan: Yup.string().required("Lama dinas wajib diisi")
});
