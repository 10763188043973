import { SetupKomponenJaminanKesehatanBulananList, SetupKomponenJaminanKesehatanBulananCreate } from "pages/Payroll";

export const SetupKomponenJaminanKesehatanBulanan = {
  component: <SetupKomponenJaminanKesehatanBulananList />,
  link: "setup-komponen-jaminan-kesehatan-bulanan",
  name: "Setup Komponen Jaminan Kesehatan Bulanan",
  access: "SETUP_KOMPONEN_JAMINAN_KESEHATAN_BULANAN",
  child: [
    {
      name: "Tambah",
      link: "/tambah",
      component: <SetupKomponenJaminanKesehatanBulananCreate />
    }
  ]
};
