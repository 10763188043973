import { Services } from "services";
import { fetchDropdownMapper } from "utilities";

const fetchDropdown = fetchDropdownMapper(Services);

class UnitOrganisasiApi {
  async getList(params) {
    const fetch = await Services.get("/master/unit-organisasi", { params });
    return fetch?.data?.data;
  }

  async getExport(params) {
    const fetch = await Services.get("/master/unit-organisasi/export", { params });
    return fetch?.data?.data;
  }

  async getUnitOrganisasiParent(levelHirarki) {
    return fetchDropdown(`/master/unit-organisasi/dropdown-by-hirarki/${levelHirarki}`, {
      value: "master_unit_organisasi_id",
      label: "master_unit_organisasi_nama",
      level: "master_unit_organisasi_level"
    });
  }

  async getHirarkiUnitOrganisasi(level) {
    return fetchDropdown(`/master/hirarki-unit-organisasi?master_hirarki_level=${level}`, {
      value: "master_hirarki_id",
      label: "master_hirarki_nama",
      level: "master_hirarki_level"
    });
  }

  getLevel() {
    return Array.from({ length: 10 }).map((_, index) => ({
      label: index + 1,
      value: index + 1
    }));
  }

  getRelasiParent() {
    return [
      { label: "Vertical", value: 0 },
      { label: "Horizontal", value: 1 }
    ];
  }

  create(data) {
    return Services.post("/master/unit-organisasi", data);
  }

  update(data, id) {
    return Services.put("/master/unit-organisasi/" + id, data);
  }

  delete(id) {
    return Services.delete("/master/unit-organisasi/" + id);
  }
}

export default new UnitOrganisasiApi();
