import { Services } from "services";

class PekerjaShiftNonRegularApi {
    async getList(params) {
        const fetch = await Services.get("/master/pekerja-non-reguler", { params });
        return fetch?.data?.data;
    }

    async getPekerja(params) {
        const fetch = await Services.get("/master/pekerja-non-reguler/pekerja", { params });
        return fetch?.data?.data.map((val) => ({
            value: val?.pekerja_id,
            label: val?.pekerja_nama
        }));
    }

    create(data) {
        return Services.post("/master/pekerja-non-reguler", data);
    }

    delete(id) {
        return Services.delete("/master/pekerja-non-reguler/" + id);
    }
}
export default new PekerjaShiftNonRegularApi();
