import { useQuery } from "react-query";
import { useFormikContext } from "formik";
import { errorFetchingSelectMapper } from "utilities";
import { InfoItemVertical, Select, TextArea } from "components";
import UraianKPIApi from "../__UraianKPIApi__";

const DetailTabIdentitasKPI = () => {
  const { values } = useFormikContext();
  // const getUnitOrganisasi = useQuery(["unit-organisasi", "dropdown"], () => UraianKPIApi.getUnitOrganisasi());

  return (
    <>
      <InfoItemVertical label="Unit Organisasi" text={values.unitOrganisasiNama} />
      <InfoItemVertical label="Kode" text={values.unitOrganisasiKode} />
      <InfoItemVertical label="Perspektif" text={values.perspektifKpiNama} />
      <InfoItemVertical label="Sasaran" text={values.sasaranKpiNama} />
      <InfoItemVertical label="KPI" text={values.kpiNama} />
      <InfoItemVertical label="Definisi" text={values.definisi} />
    </>
  );
};

const FormTabIdentitasKPI = () => {
  const { values, errors, touched, handleChange, setValues } = useFormikContext();
  const getUnitOrganisasi = useQuery(["unit-organisasi", "dropdown"], () => UraianKPIApi.getUnitOrganisasi());
  const getPerspektif = useQuery(["unit-perspektif", "dropdown"], () => UraianKPIApi.getPerspektifKPI());

  const getSasaran = useQuery(
    ["sasaran dropdown", values.unitOrganisasiId],
    () => UraianKPIApi.getSasaran(values.unitOrganisasiId),
    {
      enabled: Boolean(values.unitOrganisasiId)
    }
  );
  const getKPI = useQuery(
    ["KPI dropdown", values.unitOrganisasiId],
    () => UraianKPIApi.getKPI(values.unitOrganisasiId),
    {
      enabled: Boolean(values.unitOrganisasiId)
    }
  );

  return (
    <>
      {/* Unit Organisasi */}
      <Select
        label="Unit Organisasi"
        placeholder="Pilih jenis Organisasi"
        defaultValue={getUnitOrganisasi?.data
          ?.filter((val) => val.value)
          ?.find((item) => item.value === values.unitOrganisasiId)}
        options={getUnitOrganisasi?.data?.filter((val) => val.value)}
        onChange={(val) =>
          setValues({
            ...values,
            unitOrganisasiId: val.value,
            unitOrganisasiNama: val.label,
            unitOrganisasiKode: val.unit_organisasi_kode
          })
        }
        loading={getUnitOrganisasi.isFetching}
        error={Boolean(errors.unitOrganisasiNama && touched.unitOrganisasiNama)}
        errorText={Boolean(errors.unitOrganisasiNama && touched.unitOrganisasiNama) && errors.unitOrganisasiNama}
        errorFetch={getUnitOrganisasi.isError}
        errorFetchText={errorFetchingSelectMapper(getUnitOrganisasi.error)}
      />

      {/* Perspektif */}
      <Select
        label="Perspektif"
        placeholder="Pilih jenis perspektif"
        defaultValue={getPerspektif?.data?.find((item) => item.value === values.perspektifKpiId)}
        options={getPerspektif?.data ?? []}
        onChange={(val) =>
          setValues({
            ...values,
            perspektifKpiId: val.value,
            perspektifKpiNama: val.label
          })
        }
        loading={getPerspektif.isFetching}
        error={Boolean(errors.perspektifKpiNama && touched.perspektifKpiNama)}
        errorText={Boolean(errors.perspektifKpiNama && touched.perspektifKpiNama) && errors.perspektifKpiNama}
        errorFetch={getPerspektif.isError}
        errorFetchText={errorFetchingSelectMapper(getPerspektif.error)}
      />

      {/* Sasaran */}
      <Select
        disable={!values?.unitOrganisasiId}
        label="Sasaran"
        placeholder="Pilih jenis sasaran"
        defaultValue={values.sasaranKpiId ? getSasaran?.data?.find((item) => item.value === values.sasaranKpiId) : null}
        options={getSasaran?.data ?? []}
        onChange={(val) =>
          setValues({
            ...values,
            sasaranKpiId: val.value,
            sasaranKpiNama: val.label
          })
        }
        loading={getSasaran.isFetching}
        error={Boolean(errors.sasaranKpiNama && touched.sasaranKpiNama)}
        errorText={Boolean(errors.sasaranKpiNama && touched.sasaranKpiNama) && errors.sasaranKpiNama}
        errorFetch={getSasaran.isError}
        errorFetchText={errorFetchingSelectMapper(getSasaran.error)}
      />

      {/* KPI */}
      <Select
        disable={!values?.unitOrganisasiId}
        label="KPI"
        placeholder="Pilih jenis kpi"
        defaultValue={values.kpiId ? getKPI?.data?.find((item) => item.value === values.kpiId) : null}
        options={getKPI?.data ?? []}
        onChange={(val) =>
          setValues({
            ...values,
            kpiId: val.value,
            kpiNama: val.label
          })
        }
        loading={getKPI.isFetching}
        error={Boolean(errors.kpiNama && touched.kpiNama)}
        errorText={Boolean(errors.kpiNama && touched.kpiNama) && errors.kpiNama}
        errorFetch={getKPI.isError}
        errorFetchText={errorFetchingSelectMapper(getKPI.error)}
      />

      {/* Definisi */}
      <TextArea
        label="Definisi"
        name="definisi"
        placeholder="Masukkan definisi"
        value={values.definisi}
        onChange={handleChange}
        error={Boolean(errors.definisi && touched.definisi)}
        errorText={Boolean(errors.definisi && touched.definisi) && errors.definisi}
      />
    </>
  );
};

export const TabIdentitasKPI = ({ action }) => {
  return <>{action === "READ" ? <DetailTabIdentitasKPI /> : <FormTabIdentitasKPI />}</>;
};
