/**
 * Mapping agar sesuai dengan data yang akan ditampilkan di Frontend
 * @param {object} data - Data yang berasal dari Backend
 */
import { dateConvert } from "components";

const date = dateConvert();

export const formInitialValues = (data = {}) => ({
  periodeGajianId: data?.periode_gajian_id,
  keterangan: data?.keterangan,
  tglSetup: data?.tgl_setup ?? date.getDashYMD(new Date()),
  periodePerencanaan: data?.periode_tahun ?? date.getYear(new Date()),

  periodeGajianGrade: data?.periode_gajian_grade
    ? data?.periode_gajian_grade?.map((val) => ({
      periodeGajianGradeId: val?.periode_gajian_grade_id,
      gradeId: val?.master_grade_id,
      grade: val?.grade,
      step: val?.step,
      nominal: val?.nominal
    }))
    : []
});
