export const formInitialValues = (data = {}) => ({
  id: data?.presensi_id ?? "",
  nip: data?.nip ?? "",
  pekerjaNama: data?.pekerja_nama ?? "",
  tglPresensi: data?.tgl_presensi ?? "",
  jamIn: data?.jam_in ?? "",
  terlambat: data?.terlambat ?? "",
  jamOut: data?.jam_out ?? "",
  pulangCepat: data?.pulang_cepat ?? "",
  durasi: data?.durasi ?? "",
  lembur: data?.lembur ?? ""
});
