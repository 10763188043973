export const formSubmitValueMapper = (data) => ({
    array: data?.detail
        ?.filter((obj) => {
            return obj.checked;
        })
        .map((val) => ({
            periode_tahun: val?.periodeGajianId,
            pekerja_id: val?.pekerjaId,
            master_unit_organisasi_id: val?.unitOrganisasiId,
            uang_perkubik: val?.nominal,
        })),
    object: {
        periode_tahun: data?.periodeGajianId,
        pekerja_id: data?.pekerjaId,
        master_unit_organisasi_id: data?.unitOrganisasiId,
        uang_perkubik: data?.nominal,
    }
});
