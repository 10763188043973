import { UraianKPICreate, UraianKPIDetail, UraianKPIList, UraianKPIUpdate } from "pages/Kinerja";

export const UraianKPI = {
  link: "uraian-kpi",
  name: "Uraian KPI",
  access: "URAIAN_KPI",
  component: <UraianKPIList />,
  child: [
    {
      name: "Detail",
      link: "/detail/:id",
      component: <UraianKPIDetail />
    },
    {
      name: "Tambah",
      link: "/tambah",
      component: <UraianKPICreate />
    },
    {
      name: "Ubah",
      link: "/ubah/:id",
      component: <UraianKPIUpdate />
    }
  ]
};
