import { Services } from "services";
import { fetchDropdownMapper } from "utilities";

const fetchDropdown = fetchDropdownMapper(Services);

class SubKegiatanApi {
  async getList(params) {
    const fetch = await Services.get("/master/sub-kegiatan", { params });
    return fetch?.data?.data;
  }

  async getDetail(id) {
    const fetch = await Services.get("/master/sub-kegiatan/" + id);
    return fetch?.data?.data;
  }

  async getExport(params) {
    const fetch = await Services.get("/master/sub-kegiatan/export", { params });
    return fetch?.data?.data;
  }

  getKegiatan() {
    return fetchDropdown("/master/kegiatan/dropdown", {
      value: "master_kegiatan_id",
      label: "master_kegiatan_nama"
    });
  }

  getFee() {
    return fetchDropdown("/master/fee/dropdown", {
      value: "master_fee_id",
      label: "master_fee_kategori"
    });
  }

  getBillable() {
    return [
      { label: "Ya", value: 1 },
      { label: "Tidak", value: 2 }
    ];
  }

  create(data) {
    return Services.post("/master/sub-kegiatan", data);
  }

  update(data, id) {
    return Services.put("/master/sub-kegiatan/" + id, data);
  }

  delete(id) {
    return Services.delete("/master/sub-kegiatan/" + id);
  }
}

export default new SubKegiatanApi();
