import React from "react";
import { useFormikContext } from "formik";
import { Input, InfoItemVertical } from "components";

export const Content = ({ action }) => {
  const { values, errors, touched, handleChange } = useFormikContext();

  if (action === "READ") {
    return (
      <>
        <InfoItemVertical label="Nama Agama" text={values.agama_nama} />
      </>
    );
  }

  return (
    <>
      <Input
        label="Nama Agama*"
        name="agama_nama"
        placeholder="Masukkan nama agama"
        value={values.agama_nama}
        onChange={handleChange}
        error={Boolean(errors.agama_nama && touched.agama_nama)}
        errorText={Boolean(errors.agama_nama && touched.agama_nama) && errors.agama_nama}
      />
    </>
  );
};
