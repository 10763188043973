import { Services } from "services";
import { fetchDropdownMapper } from "utilities";
const fetchDropdown = fetchDropdownMapper(Services);

class SetupGradeStepNominalApi {
  async getList(params) {
    const fetch = await Services.get("/master/grade-nominal", { params });
    return fetch?.data?.data;
  }

  async getSingle(id) {
    const fetch = await Services.get("/master/grade-nominal/" + id);
    return fetch?.data?.data;
  }

  async getPeriodePerencanaan() {
    const getPeriodeDropdown = await fetchDropdown("/utility/periode-tahun?feat=setup-grade-nominal", {
      value: "periode_tahun",
      label: "periode_tahun"
    });

    return [{ value: undefined, label: "Semua" }, ...getPeriodeDropdown];
  }

  getPeriodePenggajian() {
    return fetchDropdown("/master/grade-nominal/periode-perencanaan", {
      value: "master_periode_perencanaan_id",
      label: "periode_perencanaan"
    });
  }

  getGrade() {
    return fetchDropdown("/master/grade/dropdown", {
      value: "master_grade_id",
      label: "grade"
    });
  }

  getStep() {
    const getStep = [
      { value: "0", label: "0" },
      { value: "1", label: "1" },
      { value: "2", label: "2" },
      { value: "3", label: "3" },
      { value: "4", label: "4" },
      { value: "5", label: "5" },
      { value: "6", label: "6" },
      { value: "7", label: "7" },
      { value: "8", label: "8" },
      { value: "9", label: "9" },
      { value: "10", label: "10" }
    ];

    return getStep;
  }

  async getExport(params) {
    const fetch = await Services.get("/master/grade-nominal/export?", { params });
    return fetch?.data?.data;
  }

  create(data) {
    return Services.post("/master/grade-nominal", data);
  }

  update(data, id) {
    return Services.put("/master/grade-nominal/" + id, data);
  }

  delete(id) {
    return Services.delete("/master/grade-nominal/" + id);
  }
}

export default new SetupGradeStepNominalApi();
