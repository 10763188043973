import { dateConvert } from "components";

const convertDate = dateConvert();

export const getFunction = {
  getTanggalAwal: (tahun) => {
    const tglAwal = tahun + "-01-01";
    return tglAwal;
  },
  getTime: (tahun) => {
    const tglAwal = tahun + "-01-01";
    const tglAkhr = tahun + "-12-31";
    const timeTglAwal = new Date(tglAwal).getTime();
    const timeTglAkhir = new Date(tglAkhr).getTime();
    return {
      maxTime: timeTglAkhir,
      minTime: timeTglAwal
    };
  },
  getIncludeTime: (tahun) => {
    const jan = new Date(tahun + "-01-01");
    const feb = new Date(tahun + "-02-01");
    const mar = new Date(tahun + "-03-01");
    const apr = new Date(tahun + "-04-01");
    const mei = new Date(tahun + "-05-01");
    const jun = new Date(tahun + "-06-01");
    const jul = new Date(tahun + "-07-01");
    const aug = new Date(tahun + "-08-01");
    const sep = new Date(tahun + "-09-01");
    const okt = new Date(tahun + "-10-01");
    const nov = new Date(tahun + "-11-01");
    const des = new Date(tahun + "-12-01");
    const include = [
      jan.getTime(),
      feb.getTime(),
      mar.getTime(),
      apr.getTime(),
      mei.getTime(),
      jun.getTime(),
      jul.getTime(),
      aug.getTime(),
      sep.getTime(),
      okt.getTime(),
      nov.getTime(),
      des.getTime()
    ];
    return [...include];
  },
  getTglAkhir: (tahun, tglAwal) => {
    const numTahun = Number(tahun);
    const moduloTahun = numTahun % 4;
    const tglAwalSplit = tglAwal.split("-");
    const mm = Number(tglAwalSplit[1]);
    let dd;
    if (mm === 2) {
      if (moduloTahun === 0) {
        dd = 29;
      } else {
        dd = 28;
      }
    } else if ((mm < 8 && mm % 2 === 1) || (mm >= 8 && mm % 2 === 0)) {
      dd = 31;
    } else {
      dd = 30;
    }
    const tglAkhir = tahun + "-" + mm + "-" + dd;

    return tglAkhir;
  },
  getStatusKegiatan: (id) => {
    if (id < 0 || id > 3 || id === undefined) {
      return undefined;
    } else if (id === 1) {
      return "Belum Selesai";
    } else if (id === 2) {
      return "Sudah Selesai";
    } else {
      return "Ditunda";
    }
  },
  getTimeShift: (data, id) => {
    if (data === undefined) {
      const dateMin = new Date("2023-02-12T00:00");
      const dateMax = new Date("2023-02-12T23:59");
      if (id === 1) {
        return dateMin;
      } else {
        return dateMax;
      }
    } else {
      const splitData1 = data.split("(");
      const splitData2 = splitData1[1].split(")");
      const splitTime = splitData2[0].split("-");
      const dateMin = new Date("2023-02-12T" + splitTime[0]).getTime();
      const dateMax = new Date("2023-02-12T" + splitTime[1]).getTime();
      if (id === 1) {
        return dateMin;
      } else {
        return dateMax;
      }
    }
  },
  getMiliSecond: (data) => {
    const times = new Date("2023-02-12T" + data).getTime();
    return times;
  },
  hoursMinutes: ({ date }) => {
    const fullDate = new Date(date);
    const isoStandard = fullDate.toString().split(" ");
    const fullTime = isoStandard[4].split(":");
    const hoursminutes = fullTime[0] + ":" + fullTime[1];
    return hoursminutes;
  },
  getTanggal: (date) => {
    const datex = date.split("/");
    const newDate = datex[2] + "-" + datex[1] + "-" + datex[0];
    return newDate;
  }
};

export const formInitialValues = {
  Form: (values, action) => ({
    detailData: {
      idSetupJadwal: values?.jadwal?.setup_jadwal_detail_id,
      periode: action === "CREATE" ? values?.info?.periode_perencanaan : values?.periode_perencanaan,
      bulanPenjadwalan:
        action === "CREATE"
          ? convertDate.getSlashDMY(new Date(values?.info?.tgl_bulan_awal)) +
            " s/d " +
            convertDate.getSlashDMY(new Date(values?.info?.tgl_bulan_akhir))
          : convertDate.getSlashDMY(new Date(values?.tgl_bulan_awal)) +
            " s/d " +
            convertDate.getSlashDMY(new Date(values?.tgl_bulan_akhir)),
      tglAktivitas:
        action === "CREATE"
          ? convertDate.getSlashDMY(new Date(values?.info?.tgl_aktivitas))
          : convertDate.getSlashDMY(new Date(values?.tgl_jaga)),
      unitOrganisasi: action === "CREATE" ? values?.info?.unit_organisasi_nama : values?.master_unit_organisasi_nama,
      nik: action === "CREATE" ? values?.info?.nip : values?.nip,
      namaPekerja: action === "CREATE" ? values?.info?.pekerja_nama : values?.pekerja_nama,
      idPekerja: action === "CREATE" ? values?.info?.pekerja_id : values?.pekerja_id,
      jabatan: action === "CREATE" ? values?.info?.master_jabatan_nama : values?.master_jabatan_nama,
      hari: values?.jadwal?.hari + "," + values?.jadwal?.tgl_jaga,
      jadwalShift: values?.jadwal?.master_shift_nama,
      lokasiKerja: values?.jadwal?.lokasi_kerja,
      peranPekerja:
        action === "CREATE" ? values?.jadwal?.master_peran_pekerja : values?.jadwal?.master_peran_pekerja_nama
    },
    formKegiatan: {
      idKegiatan: undefined,
      namaKegiatan: undefined,
      idSubKegiatan: undefined,
      namaSubKegiatan: undefined,
      wktAwal: new Date(getFunction.getTimeShift(undefined, 1)),
      wktAkhir: new Date(getFunction.getTimeShift(undefined, 2)),
      wktMinimum: getFunction.getTimeShift(undefined, 1),
      wktMaksimum: getFunction.getTimeShift(undefined, 2),
      idStatusKegiatan: undefined,
      namaStatusKegiatan: undefined,
      keterangan: undefined
    },
    dataKegiatan: values?.aktivitas
      ? values?.aktivitas?.map((val) => ({
          idSetupKegiatanAktivitas: val?.setup_aktivitas_kegiatan_id ?? undefined,
          idKegiatan: val?.master_kegiatan_id ?? undefined,
          namaKegiatan: val?.master_kegiatan_nama ?? undefined,
          idSubKegiatan: val?.master_sub_kegiatan_id ?? undefined,
          namaSubKegiatan: val?.sub_kegiatan ?? undefined,
          wktAwal: val?.waktu_awal_pelaksanaan ?? undefined,
          wktAkhir: val?.wakti_akhir_pelaksanaan ?? undefined,
          idStatusKegiatan: val?.status_kegiatan ?? undefined,
          namaStatusKegiatan: getFunction.getStatusKegiatan(val?.status_kegiatan),
          keterangan: val?.keterangan ?? undefined
        }))
      : []
  })
};
