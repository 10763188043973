import { Services } from "services";

class SetupInsentifStrukturalApi {
  async getList(params) {
    const fetch = await Services.get("/master/insentif-struktural", { params });
    return fetch?.data?.data;
  }

  async getDetail(id) {
    const fetch = await Services.get("/master/insentif-struktural/" + id);
    return fetch?.data?.data;
  }

  async getExport(params) {
    const fetch = await Services.get("/master/insentif-struktural/export?to=", { params });
    return fetch?.data?.data;
  }

  create(data) {
    return Services.post("/master/insentif-struktural", data);
  }

  update(data, id) {
    return Services.put("/master/insentif-struktural/" + id, data);
  }

  delete(id) {
    return Services.delete("/master/insentif-struktural/" + id);
  }
}

export default new SetupInsentifStrukturalApi();
