export const formInitialValues = (data = {}) => ({
  id: data?.surat_ijin_id ?? "",
  tglInput: data?.tgl_input ?? new Date(),
  unitOrganisasiId: data?.master_unit_organisasi_id ?? "",
  unitOrganisasiNama: data?.master_unit_organisasi_nama ?? "",
  pekerjaId: data?.pekerja_id ?? "",
  pekerjaNama: data?.pekerja_nama ?? "",
  jabatanId: data?.master_jabatan_id ?? "",
  jabatanNama: data?.master_jabatan_nama ?? "",
  jenisIzinId: data?.master_jenis_ijin_id ?? "",
  jenisIzinNama: data?.master_jenis_ijin_nama ?? "",
  nomor: data?.nomor ?? "",
  tujuan: data?.tujuan ?? "",
  selama: data?.selama ?? "",
  catatan: data?.catatan ?? "",
  detail:
    data?.detail?.map((val) => ({
      tglIzin: val?.tgl_ijin ?? ""
    })) ?? []
});
