import { Services } from "services";
import { fetchDropdownMapper } from "utilities";

const fetchDropdown = fetchDropdownMapper(Services);

class SetupInsentifRadiasiApi {
  async getList(params) {
    const fetch = await Services.get("/master/insentif-radiasi", { params });
    return fetch?.data?.data;
  }
  async getJabatan() {
    const getJabatanDropdown = await fetchDropdown("/master/jabatan/dropdown", {
      value: "master_jabatan_id",
      label: "master_jabatan_nama"
    });
    return [...getJabatanDropdown];
  }
  async getUnitOrganisasi() {
    const getUnitOrganisasi = await fetchDropdown("/master/unit-organisasi/dropdown", {
      value: "master_unit_organisasi_id",
      label: "master_unit_organisasi_nama"
    });
    return [...getUnitOrganisasi];
  }
  async getLokasiKerja() {
    const getLokasiKerjaDropdown = await fetchDropdown("/master/lokasi-kerja/dropdown", {
      value: "master_lokasi_kerja_id",
      label: "lokasi_kerja"
    });
    return [...getLokasiKerjaDropdown];
  }
  async getDetail(id) {
    const fetch = await Services.get("/master/insentif-radiasi/" + id);
    return fetch?.data?.data;
  }
  async getExport(params) {
    const fetch = await Services.get("master/insentif-radiasi/export?to=", { params });
    return fetch?.data?.data;
  }
  create(data) {
    return Services.post("/master/insentif-radiasi", data);
  }
  update(data, id) {
    return Services.put("/master/insentif-radiasi/" + id, data);
  }
  delete(id) {
    return Services.delete("master/insentif-radiasi/" + id);
  }
}

export default new SetupInsentifRadiasiApi();
