import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { Formik } from "formik";
import { ButtonBack, ContentLayout, DataStatus } from "components";
import { errorFetchingPageMapper } from "utilities";
import { Content } from "./__PekerjaComps__";
import PekerjaApi from "./__PekerjaApi__";
import { useAuth } from "hooks";

export const PekerjaUpdate = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("akun");
  const auth = useAuth();

  const isGeneralUser = Boolean(auth.getId() !== 0 || auth.getUsername().toLowerCase() !== "superadmin")

  const getPekerja = useQuery(["pekerja", id], () => PekerjaApi.getSingle(id), { enabled: Boolean(isGeneralUser === false) });

  const getSelf = useQuery(["pekerja", "self"], () => PekerjaApi.getSelf(), { enabled: Boolean(isGeneralUser === true) })


  return (
    <>
      <ContentLayout showBreadcrumb>
        <div className="text-end mb-3">
          <ButtonBack tooltip={false} onClick={() => navigate(-1)} />
        </div>
        {!isGeneralUser ? (
          getPekerja.isFetching || getPekerja.isError ? (
            <DataStatus loading={getPekerja.isFetching} text={errorFetchingPageMapper(getPekerja.error)} />
          ) : (
            <Formik initialValues={getPekerja.data}>
              <Content
                action="UPDATE"
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                refreshData={getPekerja.refetch}
              />
            </Formik>
          )
        ) : (
          getSelf.isFetching || getSelf.isError ? (
            <DataStatus loading={getSelf.isFetching} text={errorFetchingPageMapper(getSelf.error)} />
          ) : (
            <Formik initialValues={getSelf.data}>
              <Content
                action="UPDATE"
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                refreshData={getSelf.refetch}
              />
            </Formik>
          )
        )}
      </ContentLayout>
    </>
  );
};
