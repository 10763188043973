import { Pph21BulananList, Pph21BulananCreate, Pph21BulananDetail, Pph21BulananPostList } from "pages/Payroll";

export const Pph21Bulanan = {
    component: <Pph21BulananList />,
    link: "pph-21-bulanan",
    name: "Pajak Penghasilan Bulanan",
    access: "PEKERJA_PAJAK_BULANAN",
    child: [
        {
            name: "List Pekerja",
            link: "/post-list",
            component: <Pph21BulananPostList />
        },
        {
            name: "Tambah",
            link: "/tambah",
            component: <Pph21BulananCreate />
        },
        {
            name: "Detail",
            link: "/detail/:id",
            component: <Pph21BulananDetail />
        }
    ]
};
