import {
  PermohonanIzinList,
  PermohonanIzinCreate,
  PermohonanIzinUpdate,
  PermohonanIzinDetail
} from "pages/OprasionalSdm";

export const PermohonanIzin = {
  component: <PermohonanIzinList />,
  link: "permohonan-izin",
  name: "Permohonan Izin",
  access: "SURAT_IJIN",
  child: [
    {
      name: "Detail",
      link: "/detail/:id",
      component: <PermohonanIzinDetail />
    },
    {
      name: "Ubah",
      link: "/ubah/:id",
      component: <PermohonanIzinUpdate />
    },
    {
      name: "Tambah",
      link: "/tambah",
      component: <PermohonanIzinCreate />
    }
  ]
};
