import React from "react";
import { useFormikContext } from "formik";
import { Input, InfoItemVertical, TextArea } from "components";

export const Content = ({ action }) => {
  const { values, errors, touched, handleChange } = useFormikContext();

  if (action === "READ") {
    return (
      <>
        <InfoItemVertical label="Nomor Serial" text={values.nomorSerial} />
        <InfoItemVertical label="Nama Mesin" text={values.namaMesin} />
        <InfoItemVertical label="Port" text={values.port} />
        <InfoItemVertical label="Lokasi" text={values.lokasi} />
      </>
    );
  }
  return (
    <>
      <Input
        label="Nomor Serial"
        name="nomorSerial"
        placeholder="Masukkan nomor serial"
        value={values.nomorSerial}
        onChange={handleChange}
        error={Boolean(errors.nomorSerial && touched.nomorSerial)}
        errorText={Boolean(errors.nomorSerial && touched.nomorSerial) && errors.nomorSerial}
      />
      <Input
        label="Nama Mesin"
        name="namaMesin"
        placeholder="Masukkan nama mesin"
        value={values.namaMesin}
        onChange={handleChange}
        error={Boolean(errors.namaMesin && touched.namaMesin)}
        errorText={Boolean(errors.namaMesin && touched.namaMesin) && errors.namaMesin}
      />
      <Input
        label="Port"
        name="port"
        placeholder="Masukkan port"
        value={values.port}
        onChange={handleChange}
        error={Boolean(errors.port && touched.port)}
        errorText={Boolean(errors.port && touched.port) && errors.port}
      />
      <TextArea
        label="Lokasi"
        name="lokasi"
        placeholder="Masukkan lokasi"
        value={values.lokasi}
        onChange={handleChange}
        error={Boolean(errors.lokasi && touched.lokasi)}
        errorText={Boolean(errors.lokasi && touched.lokasi) && errors.lokasi}
      />
    </>
  );
};
