import {
  ButtonCreate, DatePicker, InputSearch, Select, TableList, toastTrigger
} from "components";
import { debounce } from "lodash";
import { useState } from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import { FiFileText } from "react-icons/fi";
import { useMutation, useQuery } from "react-query";
import { dateConvert, exportHandler } from "utilities";
import PresensiApi from "../__PresensiApi__";

export const TabDataScan = () => {
  const date = dateConvert();

  // Config
  const [searchKey, setSearchKey] = useState(undefined);
  const [searchingData, setSearchingData] = useState({
    unitOrganisasiId: undefined,
    tglAwal: undefined,
    tglAkhir: undefined,
    pekerjaId: undefined
  });
  const [pagination, setPagination] = useState({
    pageCurrent: 1,
    dataLength: 10
  });
  const [dataFilter, setDataFilter] = useState({
    unitOrganisasiId: undefined,
    tglAwal: undefined,
    tglAkhir: undefined,
    pekerjaId: undefined
  });
  // const [modal, setModal] = useState({
  //   show: false,
  //   action: "",
  //   data: {}
  // });

  // Fetch Dropdown
  const getUnitOrganisasi = useQuery(["unit-organisasi", "dropdown"], () => PresensiApi.getUnitOrganisasiDropdown());

  const getPekerja = useQuery(["pekerja", "dropdown"], () => PresensiApi.getPekerja());

  // Fetch Data
  const getList = useQuery(
    [
      "data-scan",
      "list",
      searchKey,

      pagination.pageCurrent,
      pagination.dataLength,
      dataFilter?.pekerjaId,
      dataFilter?.unitOrganisasiId,
      dataFilter?.tglAwal,
      dataFilter?.tglAkhir
    ],
    () =>
      PresensiApi.getDataScan({
        search: searchKey,
        page: pagination.pageCurrent,
        limit: pagination.dataLength,
        tgl_awal: dataFilter?.tglAwal,
        tgl_akhir: dataFilter?.tglAkhir,
        master_unit_organisasi_id: dataFilter?.unitOrganisasiId,
        pekerja_id: dataFilter?.pekerjaId
      }),
    {
      enabled: Boolean(dataFilter?.tglAwal && dataFilter?.tglAkhir)
    }
  );
  const getExport = useMutation(
    (data) =>
      PresensiApi.getExportDataScan({
        to: "excel",
        tgl_awal: data?.tglAwal,
        tgl_akhir: data?.tglAkhir,
        master_unit_organisasi_id: data?.unitOrganisasiId,
        pekerja_id: data?.pekerjaId
      }),
    {
      onSuccess: (link) => {
        toastTrigger({ variant: "success", message: "Export berhasil" });
        exportHandler(link, "Presensi Data Scan");
      },
      onError: () => {
        toastTrigger({ variant: "danger", message: "Export gagal" });
      }
    }
  );

  const searchHandler = (e) => {
    setSearchKey(e?.target?.value ? e.target.value : undefined);
  };

  const onPaginationChange = (data) => setPagination({ ...pagination, pageCurrent: data });

  const onPaginationDataLengthChange = (data) => setPagination({ ...pagination, dataLength: data });

  return (
    <>
      <div className="d-flex justify-content-between">
        {/* Searching Field */}
        <div className="d-flex align-items-end mb-3 gap-2 ">
          <div style={{ maxWidth: 180 }}>
            <InputSearch onChange={debounce(searchHandler, 1500)} />
          </div>
          <div style={{ maxWidth: 180 }}>
            <DatePicker
              noMargin
              className="me-4"
              placeholderText="dd/mm/yyyy"
              label="Tanggal Awal"
              name="bulanPenggajianMulai"
              selected={searchingData?.tglAwal ?? ""}
              onChange={(val) => {
                setSearchingData({
                  ...searchingData,
                  tglAwal: val
                });
              }}
            />
          </div>
          <div>
            <DatePicker
              noMargin
              className="me-4"
              placeholderText="dd/mm/yyyy"
              label="Tanggal Akhir"
              name="tglAkhir"
              selected={searchingData?.tglAkhir ?? ""}
              onChange={(val) => {
                setSearchingData({
                  ...searchingData,
                  tglAkhir: val
                });
              }}
            />
          </div>

          <div style={{ width: 180 }}>
            <Select
              noMargin
              label="Unit Organisasi"
              placeholder="Pilih salah satu.."
              options={getUnitOrganisasi?.data ?? []}
              defaultValue={getUnitOrganisasi?.data?.find((item) => item.value === searchingData?.unitOrganisasiId)}
              loading={getUnitOrganisasi?.isFetching}
              onChange={(val) =>
                setSearchingData({
                  ...searchingData,
                  unitOrganisasiId: val.value,
                  unitOrganisasiNama: val.label
                })
              }
            />
          </div>
          <div style={{ width: 180 }}>
            <Select
              noMargin
              className="w-full"
              label="Pekerja"
              placeholder="Pilih salah satu.."
              options={getPekerja?.data ?? []}
              defaultValue={getPekerja?.data?.find((item) => item.value === searchingData?.pekerjaId)}
              loading={getPekerja?.isFetching}
              onChange={(val) =>
                setSearchingData({
                  ...searchingData,
                  pekerjaId: val.value,
                  pekerjaNama: val.label
                })
              }
            />
          </div>
          <div>
            <ButtonCreate
              text="Cari"
              tooltip={false}
              disabled={searchingData.tglAkhir === undefined || searchingData.tglAwal === undefined}
              onClick={() => {
                setDataFilter({
                  ...dataFilter,
                  unitOrganisasiId: searchingData.unitOrganisasiId,
                  pekerjaId: searchingData.pekerjaId,
                  tglAwal: searchingData.tglAwal,
                  tglAkhir: searchingData.tglAkhir,
                  statusEnable: true
                });
                getList?.refetch();
                setSearchingData({ ...searchingData, statusEnable: false });
              }}
            />
          </div>
        </div>

        {/* Export and Create */}
        <div className="d-flex align-items-end mb-3">
          <Dropdown align="end">
            <Dropdown.Toggle
              size="sm"
              variant="outline-success"
              className="mx-2"
              disabled={getExport.isLoading}
            // disabled={true}
            >
              {getExport.isLoading ? (
                <Spinner size="sm" className="me-1" animation="border" />
              ) : (
                <FiFileText className="mb-1 mr-3" />
              )}
              <span className="mx-1">{getExport.isLoading ? "Memuat..." : "EXPORT"}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="mt-2">
              <Dropdown.Item onClick={() => getExport.mutate(dataFilter)}>Export Excel</Dropdown.Item>
              {/* <Dropdown.Item onClick={() => getExport.mutate("pdf")}>
                Export PDF
              </Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <TableList
        responsive
        data={getList?.data?.data}
        pagination={true}
        loading={getList.isFetching}
        dataTotal={getList?.data?.total}
        dataLength={pagination.dataLength}
        pageCurrent={pagination.pageCurrent}
        pageTotal={getList?.data?.last_page}
        onDataLengthChange={onPaginationDataLengthChange}
        onPaginationChange={onPaginationChange}
        tableHeader={[
          { text: "NIK" },
          { text: "Nama" },
          { text: "Tanggal" },
          { text: "Jam" },
          { text: "Cabang" },
          { text: "Departemen" },
          { text: "Jabatan" },
        ]}
        tableBody={[
          { field: "nip" },
          { field: "pekerja_nama" },
          { customField: (data) => date.getSlashDMY(new Date(data.tgl)) },
          { field: "jam" },
          { field: "cabang" },
          { field: "master_unit_organisasi_nama" },
          { field: "master_jabatan_nama" }
        ]}
      />
    </>
  );
};
