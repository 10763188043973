import * as Yup from "yup";

export const formValidationSchema = Yup.object().shape({
    nominal: Yup.string().required("Gaji 13 wajib diisi"),
    detail: Yup.array().of(
        Yup.object().shape({
            nominal: Yup.string().required("Gaji 13 wajib diisi")
        })
    )
});
