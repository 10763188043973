import { Formik } from "formik";
import { useModalConfirm } from "hooks";
import { ContentLayout } from "layouts";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

import { ButtonBack, ButtonUpdate, DataStatus } from "components";
import { errorFetchingPageMapper, errorSubmitMapper } from "utilities";
import PhkApi from "./__PhkApi__";
import { Content } from "./__PhkComps__";
import { formInitialValues, formSubmitMapper, formValidationSchema } from "./__PhkUtils__";

export const PhkUpdate = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const getPhk = useQuery(["pekerja-phk", id], () => PhkApi.getSingle(id));
  const modalConfirm = useModalConfirm();

  const updatePhk = useMutation((data) => PhkApi.update(data.data, id), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "update", size: "md" });
      navigate(-1);
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "update", size: "md", ...errorSubmitMapper(err) })
  });

  const formSubmitHandler = (values) =>
    modalConfirm.trigger({
      type: "update",
      size: "lg",
      onHide: modalConfirm.close,
      onSubmit: () => {
        modalConfirm.loading();
        updatePhk.mutate({ data: formSubmitMapper(values) });
      },
      component: (
        <Formik initialValues={values}>
          <Content action="READ" />
        </Formik>
      )
    });

  const submitValidationHandler = (errors) =>
    new Promise((resolve, reject) => {
      const getError = Object.values(errors);

      if (getError.length > 0) {
        reject(getError);
      } else {
        resolve();
      }
    });

  const preSubmitHandler = (formik) => {
    const { values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting } = formik;

    setSubmitting(true);
    validateForm().then(async (err) => {
      setErrors(err);
      setTouched(err);
      await submitValidationHandler(err, values)
        .then(() => handleSubmit())
        .catch((err) =>
          modalConfirm.trigger({
            type: "error",
            title: "Data Tidak Lengkap",
            data: err,
            onHide: () => modalConfirm.close()
          })
        )
        .finally(() => {
          setSubmitting(false);
        });
    });
  };
  return (
    <>
      <ContentLayout showBreadcrumb>
        {getPhk.isFetching || getPhk.isError ? (
          <DataStatus loading={getPhk.isFetching} text={errorFetchingPageMapper(getPhk.error)} />
        ) : (
          <Formik
            enableReinitialize
            initialValues={formInitialValues(getPhk.data)}
            validationSchema={formValidationSchema}
            onSubmit={formSubmitHandler}
          >
            {(formik) => (
              <>
                <div className="text-end mb-3">
                  <ButtonBack text="Kembali" tooltip={false} noIcon onClick={() => navigate(-1)} />
                </div>

                <Content action="UPDATE" />
                <div className="mt-3 text-end">
                  <ButtonUpdate tooltip={false} onClick={() => preSubmitHandler(formik)} />
                </div>
              </>
            )}
          </Formik>
        )}
      </ContentLayout>
    </>
  );
};
