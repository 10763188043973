export const formInitialValues = ({ data = {} }) => ({
  id: data?.uraian_kpi_id,

  unitOrganisasiId: data?.identitas_kpi?.master_unit_organisasi_id,
  unitOrganisasiNama: data?.identitas_kpi?.master_unit_organisasi_nama,
  unitOrganisasiKode: data?.identitas_kpi?.master_unit_organisasi_kode,

  perspektifKpiId: data?.identitas_kpi?.master_perspektif_kpi_id,
  perspektifKpiNama: data?.identitas_kpi?.perspektif_kpi_nama,

  sasaranKpiId: data?.identitas_kpi?.master_sasaran_kpi_id,
  sasaranKpiNama: data?.identitas_kpi?.sasaran_nama,

  kpiId: data?.identitas_kpi?.master_kpi_id,
  kpiNama: data?.identitas_kpi?.kpi_nama,

  definisi: data?.identitas_kpi?.definisi,

  periode: data?.periode_target?.periode_target,
  periodeSatuanKpiId: data?.periode_target?.master_satuan_kpi_id,
  periodeSatuanKpiNama: data?.periode_target?.satuan_kpi_nama,

  formula: data?.formula?.formula,

  target:
    data?.target?.map((val) => ({ targetKpiId: val.master_target_kpi_id, targetKpiNama: val.target_kpi_nama })) ?? [],

  polaritas: data?.polaritas?.polaritas,
  polaritasNama: data?.polaritas?.polaritas_nama,

  frekuensi: data?.frekuensi_monitoring?.frekuensi_monitoring,
  frekuensiSatuanKpiId: data?.frekuensi_monitoring?.master_satuan_kpi_id,
  frekuensiSatuanKpiNama: data?.frekuensi_monitoring?.satuan_kpi_nama,

  nama: data?.sumber_data ?? []
});
