export const formInitialValues = (data = {}) => ({
  id: data?.master_masa_kerja_id,
  periodePenggajianId: data?.periode_tahun ?? new Date().getFullYear(),
  periodePenggajianNama: data?.periode_tahun ?? new Date().getFullYear(),
  masaKerja: data?.masa_kerja,
  faktorManfaatUangPesangon: data?.fm_pesangon,
  faktorManfaatUangPenghargaanMasaKerja: data?.fm_upmk,
  faktorManfaatUangPenggantiHak: data?.fm_uph,
  faktorManfaatUangPisah: data?.fm_up
});
