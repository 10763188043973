import * as Yup from "yup";

export const formValidationSchema = Yup.object().shape({
  periodeGajianId: Yup.string().required("Periode wajib diisi"),
  tglInput: Yup.string().required("Tanggal input permohonan cuti wajib diisi"),
  nomor: Yup.string().required("Nomor wajib diisi"),
  unitOrganisasiId: Yup.string().required("Unit organisasi wajib diisi"),
  pekerjaId: Yup.string().required("Pekerja wajib diisi"),
  jenisCutiId: Yup.string().required("Jenis Cuti wajib diisi"),
  tglMulai: Yup.string().required("Tanggal mulai cuti wajib diisi"),
  tglAkhir: Yup.string().required("Tanggal akhir cuti wajib diisi"),
  lamaCuti: Yup.string().required("Lama cuti wajib diisi"),
  alasan: Yup.string().required("Alasan cuti wajib diisi")
});
