import * as Yup from "yup";

export const formValidationSchema = Yup.object().shape({
  periodeGajianId: Yup.string().required("Periode gajian wajib diisi"),
  tglSetup: Yup.string().required("Tanggal setup wajib diisi"),
  kode: Yup.string().required("Kode insentif wajib diisi"),
  status: Yup.string().required("Status wajib diisi"),
  nominal: Yup.string().required("Nominal wajib diisi"),
  keterangan: Yup.string().required("Keterangan wajib diisi")
});
