import { Services } from "services";
import { fetchDropdownMapper } from "utilities";

const fetchDropdown = fetchDropdownMapper(Services);

class UraianKPIApi {
  async getList(params) {
    const fetch = await Services.get("/transaksi/uraian-kpi", { params });
    return fetch?.data?.data;
  }

  async getReadSingle(id) {
    const fetch = await Services.get("/transaksi/uraian-kpi/" + id);
    return fetch?.data?.data;
  }

  async getUnitOrganisasi() {
    const getUnitOrganisasi = await fetchDropdown("/master/unit-organisasi/dropdown", {
      value: "master_unit_organisasi_id",
      label: "master_unit_organisasi_nama",
      unit_organisasi_kode: "master_unit_organisasi_kode",
      unit_organisasi_parent_nama: "master_hirarki_nama"
    });

    return [
      { value: undefined, label: "Semua", unit_organisasi_kode: undefined, unit_organisasi_parent_nama: undefined },
      ...getUnitOrganisasi
    ];
  }

  async getSasaran(unit_organisasi_id) {
    const getSasaran = await fetchDropdown(
      "/master/sasaran-kpi/dropdown?master_unit_organisasi_id=" + unit_organisasi_id,
      {
        value: "master_sasaran_kpi_id",
        label: "sasaran_nama",
        unit_organisasi_id: "master_unit_organisasi_id"
      }
    );

    return getSasaran;
  }

  getPolaritas() {
    const getPolaritas = [
      { value: "1", label: "Positif" },
      { value: "2", label: "Negatif" }
    ];

    return getPolaritas;
  }

  async getKPI(unit_organisasi_id) {
    const getKPI = await fetchDropdown("/master/kpi/dropdown?master_unit_organisasi_id=" + unit_organisasi_id, {
      value: "master_kpi_id",
      label: "kpi_nama",
      unit_organisasi_id: "master_unit_organisasi_id"
    });

    return getKPI;
  }

  async getPerspektifKPI() {
    const getPerspektifKPI = await fetchDropdown("/master/perspektif-kpi/dropdown", {
      value: "master_perspektif_kpi_id",
      label: "perspektif_kpi_nama"
    });

    return getPerspektifKPI;
  }

  async getSatuanKPI() {
    const getSatuanKPI = await fetchDropdown("/master/satuan-kpi/dropdown", {
      value: "master_satuan_kpi_id",
      label: "satuan_kpi_nama"
    });

    return getSatuanKPI;
  }

  async getTargetKPI() {
    const getTargetKPI = await fetchDropdown("/master/target-kpi/dropdown", {
      value: "master_target_kpi_id",
      label: "target_kpi_nama"
    });

    return getTargetKPI;
  }

  async getExport(params) {
    const fetch = await Services.get("/transaksi/uraian-kpi/export", { params });
    return fetch?.data?.data;
  }

  create(data) {
    return Services.post("/transaksi/uraian-kpi", data);
  }

  update(data, id) {
    return Services.put("/transaksi/uraian-kpi/" + id, data);
  }

  delete(id) {
    return Services.delete("/transaksi/uraian-kpi/" + id);
  }
}
export default new UraianKPIApi();
