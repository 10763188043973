import { Services } from "services";

class SetupUpahPokokBulananApi {
  async getList(params) {
    const fetch = await Services.get("/transaksi/upah-pokok-bulanan", { params });
    return fetch?.data?.data;
  }

  async getDetail(id) {
    const fetch = await Services.get("/transaksi/upah-pokok-bulanan/" + id);
    return fetch?.data?.data;
  }

  async getSetupForm(params) {
    const fetch = await Services.get("/transaksi/upah-pokok-bulanan/form", { params });
    return fetch?.data;
  }

  getStatus() {
    return [
      {
        value: 1,
        label: "Aktif"
      },
      {
        value: 2,
        label: "Tidakk Aktif"
      }
    ];
  }

  async getUnitOrganisasi() {
    const fetch = await Services.get("/master/unit-organisasi/dropdown");
    return fetch?.data?.data.map((val) => ({
      value: val?.master_unit_organisasi_id,
      label: val?.master_unit_organisasi_nama
    }));
  }
  async getUnitOrganisasiForList(params) {
    const fetch = await Services.get("/transaksi/upah-pokok-bulanan/dropdown-unit", { params });
    return fetch?.data?.data.map((val) => ({
      value: val?.master_unit_organisasi_id,
      label: val?.master_unit_organisasi_nama
    }));
  }

  async getPeriode() {
    const fetch = await Services.get("/utility/periode-tahun?feat=upah-pokok-bulanan");
    return fetch?.data?.data.map((val) => ({
      value: val?.periode_tahun,
      label: val?.periode_tahun
    }));
  }

  async getBulan() {
    return Array.from({ length: 12 }, (item, i) => {
      return {
        value: i + 1,
        label: new Date(0, i).toLocaleString("id-ID", { month: "long" })
      };
    });
  }

  async getTanggal(params) {
    const fetch = await Services.get("/transaksi/upah-pokok-bulanan/dropdown-tanggal", { params });
    return fetch?.data?.data.map((val) => ({
      value: val?.tgl_bulan_awal + "/&/" + val?.tgl_bulan_akhir,
      label: val?.tgl_bulan_awal + " s/d " + val?.tgl_bulan_akhir,
      tglAwal: val?.tgl_bulan_awal,
      tglAkhir: val?.tgl_bulan_akhir
    }));
  }

  async getExport(params) {
    const fetch = await Services.get("/transaksi/upah-pokok-bulanan/export", { params });
    return fetch?.data?.data;
  }

  create(data) {
    return Services.post("/transaksi/upah-pokok-bulanan", data);
  }

  update(data, id) {
    return Services.put("/transaksi/upah-pokok-bulanan/" + id, data);
  }

  delete(id) {
    return Services.delete("/transaksi/upah-pokok-bulanan/" + id);
  }
}

export default new SetupUpahPokokBulananApi();
