export const formSubmitMapper = {
  akun: (values = {}) => ({
    akun: {
      pekerja_nama: values.pekerjaNama,
      akun: values.akun,
      passwd: values.password
    }
  }),
  profile: (values = {}) => ({
    profile: {
      tempat_lahir: values.tempatLahir,
      tgl_lahir: values.tglLahir,
      master_kelamin_id: values.jenisKelaminId,
      master_agama_id: values.agamaId,
      master_status_perkawinan_id: values.statusPerkawinanId,
      foto_profil: values?.fotoProfile,
      identitas: values.identitas.map((val) => ({
        master_identitas_id: val.jenisIdentitasId,
        no_identitas: val.noIdentitas,
        file_identitas: val.fileIdentitas
      }))
    }
  }),
  jabatan: (data = []) => ({
    jabatan: data.map((val) => ({
      master_jabatan_id: val.jabatanId ?? "",
      master_grade_id: val.gradeId ?? "",
      master_step_id: val.stepId ?? ""
    }))
  }),
  kepegawaian: (values = {}) => ({
    kepegawaian: {
      nip: values.nip,
      tanggal_bergabung: values.tanggalBergabung,
      tanggal_pengangkatan: values.tanggalPengangkatan,
      no_pengangkatan: values.noPengangkatan,
      jenis_pekerja: values.jenisPekerjaId.toString(),
      master_status_pekerja_id: values.statusPekerjaId,
      master_detail_status_pekerja_id: values.detailStatusPekerjaId,
      master_unit_organisasi_id: values.unitOrganisasiId,
      master_lokasi_kerja_id: values.lokasiKerjaId,
      no_npwp: values.noNpwp,
      no_bpjs_ketenagakerjaan: values.noBpjsKetenagakerjaan,
      no_bpjs_kesehatan: values.noBpjsKesehatan,
      no_yadapen: values.noYadapen,
      tahun_pensiun: parseInt(values.tahunPensiun.slice(0, 4)),
      bulan_pensiun: parseInt(values.bulanPensiun.slice(5, 7)),
      tanggal_keluar: values.tanggalKeluar,

      nama_wali: values.namaWali,
      master_jenis_kontak_id: values.jenisKontakId,
      kontak_wali: values.kontakWali,
      master_hubungan_keluarga_id: values.hubunganWaliId,

      nama_wali_1: values.namaWali1,
      master_jenis_kontak_id_1: values.jenisKontakId1,
      kontak_wali_1: values.kontakWali1,
      master_hubungan_keluarga_id_1: values.hubunganWaliId1,

      nama_wali_2: values.namaWali2,
      master_jenis_kontak_id_2: values.jenisKontakId2,
      kontak_wali_2: values.kontakWali2,
      master_hubungan_keluarga_id_2: values.hubunganWaliId2,

      upload_hasil_psikotes: values.hasilPsikotes,
      file_sk: values.skPengangkatan
    }
  }),
  alamat: (data = {}) => ({
    alamat: {
      provinsi_kode: data.provinsiKode.toString(),
      kabupaten_kode: data.kabupatenKode.toString(),
      kecamatan_kode: data.kecamatanKode.toString(),
      desa_kode: data.desaKode.toString(),
      rt_rw: (data.rt ?? "-") + "/" + (data.rw ?? "-"),
      alamat: data.alamat,
      provinsi_kode_1: data.provinsiKode1.toString(),
      kabupaten_kode_1: data.kabupatenKode1.toString(),
      kecamatan_kode_1: data.kecamatanKode1.toString(),
      desa_kode_1: data.desaKode1.toString(),
      rt_rw_1: (data.rt1 ?? "-") + "/" + (data.rw1 ?? "-"),
      alamat_1: data.alamat1
    }
  }),
  pendidikan: (data = []) => ({
    pendidikan: data.map((val) => ({
      master_jenjang_pendidikan_id: val.jenjangPendidikanId,
      master_jenjang_pendidikan_nama: val.jenjangPendidikanNama,
      nama_sekolah: val.namaSekolah,
      nomor_ijazah: val.nomorIjazah,
      file_ijazah: val.fileIjazah,
      transkrip_nilai: val.fileTrnaskrip
    }))
  }),
  kontak: (data = []) => ({
    kontak: data.map((val) => ({
      master_jenis_kontak_id: val.jenisKontakId,
      jenis_kontak: val.jenisKontakNama,
      data_kontak: val.dataKontak,
      keterangan: val.keterangan
    }))
  }),
  bank: (data = {}) => ({
    bank: [
      {
        master_bank_id: data.bankId,
        no_rekening: data.noRekening,
        atas_nama: data.atasNama,
        cabang_bank: data.cabangBank
      }
    ]
  }),
  rekamMedis: (data = {}) => ({
    rekam_medis: {
      no_rekam_medis: data.noRekamMedis
    }
  }),
  keluarga: (data = {}) => ({
    keluarga: data.map((val) => ({
      nama_keluarga: val.keluargaNama,
      master_hubungan_keluarga_id: val.hubunganKeluargaId,
      master_status_perkawinan_id: val.statusPerkawinanId,
      tgl_cerai: val.tglCerai,
      status: val.status,
      tgl_meninggal: val.tglMeninggal,
      provinsi_kode: String(val.provinsiKode),
      kabupaten_kode: String(val.kabupatenKode),
      kecamatan_kode: String(val.kecamatanKode),
      desa_kode: String(val.desaKode),
      rt_rw: val.rt + "/" + val.rw,
      alamat: val.alamat,
      tempat_lahir: val.tempatLahir,
      tgl_lahir: val.tglLahir,
      master_agama_id: val.agamaId,
      master_kelamin_id: val.jenisKelaminId,
      bpjs_kesehatan: val.noBpjsKesehatan,
      rekam_medis: val.noRekamMedis,
      pekerjaan: val.pekerjaan,
      instansi_sekolah: val.instansi,
      alamat_instansi: val.alamatInstansi,
      pendidikan_terakhir: val.pendidikanTerakhirId,
      tgl_lulus: val.tglLulus,
      nama_tempat_pendidikan: val.tempatPendidikan,
      telpon: val.telp,
      hp: val.hp,
      wa: val.wa,
      email: val.email,
      keterangan: val.keteranganKontak
    }))
  })
};
