import { Services } from "services";

class JadwalRegularApi {
    async getList(params) {
        const fetch = await Services.get("/master/jadwal-reguler", { params });
        return fetch?.data?.data;
    }

    async getDetail(id) {
        const fetch = await Services.get("/master/jadwal-reguler/" + id);
        return fetch?.data?.data;
    }

    async getShift() {
        const fetch = await Services.get("/master/shift/dropdown");
        return fetch?.data?.data.map((val) => ({
            value: val?.master_shift_id,
            label: val?.master_shift_nama,
            jamIn: val?.jam_masuk,
            jamOut: val?.jam_keluar
        }));
    }

    update(data) {
        return Services.put("/master/jadwal-reguler/", data);
    }

}

export default new JadwalRegularApi();
