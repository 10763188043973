import { Services } from "services";
import { fetchDropdownMapper } from "utilities";

const fetchDropdown = fetchDropdownMapper(Services);

class RealisasiPekerjaPensiunApi {
  async getList(params) {
    const fetch = await Services.get("/transaksi/realisasi-pekerja-pensiun", { params });
    return fetch?.data?.data;
  }
  async getSingle(id) {
    const fetch = await Services.get("/transaksi/realisasi-pekerja-pensiun/" + id);
    return fetch?.data?.data;
  }
  async getDataDetail(id) {
    const fetch = await Services.get("/transaksi/realisasi-pekerja-pensiun/pensiun/" + id);
    return fetch?.data?.data;
  }
  async getForm(params) {
    const fetch = await Services.get("/transaksi/realisasi-pekerja-pensiun/form", { params });
    return fetch?.data?.data;
  }

  getPeriodeGajian() {
    return fetchDropdown("/utility/periode-tahun?feat=realisasi-pekerja-pensiun", {
      value: "periode_tahun",
      label: "periode_tahun"
    });
  }
  async getUnitOrganisasi(action) {
    const getUnit = await fetchDropdown("/master/unit-organisasi/dropdown", {
      value: "master_unit_organisasi_id",
      label: "master_unit_organisasi_nama"
    });

    if (action === "UNFILTER") {
      return getUnit;
    } else {
      return [{ value: undefined, label: "Semua" }, ...getUnit];
    }
  }
  async getPeriodePerencanaan() {
    const fetch = await Services.get("/transaksi/perhitungan-phk/dropdown-periode");
    return fetch?.data?.data.map((val) => ({
      value: val?.periode_gajian_id,
      label: val?.periode_gajian_perencanaan
    }));
  }

  // async getInfoPekerja(id) {
  //   const fetch = await Services.get("/transaksi/realisasi-pekerja-pensiun/dropdown-pekerja?master_unit_organisasi_id="+id);
  //   return fetch?.data?.data;
  // }

  getInfoPekerja(id) {
    return fetchDropdown("/transaksi/realisasi-pekerja-pensiun/dropdown-pekerja?master_unit_organisasi_id=" + id, {
      value: "pekerja_id",
      label: "pekerja_nama"
    });
  }
  getAlasanPhk() {
    return fetchDropdown("/master/alasan-phk/dropdown", {
      value: "master_alasan_phk_id",
      label: "kode",
      alasan: "nama"
    });
  }

  async getExport(params) {
    const fetch = await Services.get("/transaksi/realisasi-pekerja-pensiun/export", { params });
    return fetch?.data?.data;
  }
  create(data) {
    return Services.post("/transaksi/realisasi-pekerja-pensiun", data);
  }
  update(data, id) {
    return Services.put("/transaksi/realisasi-pekerja-pensiun/" + id, data);
  }

  linkUploadSuratKeputusan = "/upload/suart-keputusan-phk";
  linkUploadSuratKeterangan = "/upload/surat-keterangan-phk";
}

export default new RealisasiPekerjaPensiunApi();
