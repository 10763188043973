import { ButtonBack, DataStatus } from "components";
import { Formik } from "formik";
import { ContentLayout } from "layouts";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import PermohonanCutiApi from "./__PermohonanCutiApi__";
import { Content } from "./__PermohonanCutiComps__";
import { formInitialValues } from "./__PermohonanCutiUtilities__";

export const PermohonanCutiDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const getDetail = useQuery(["surat-permohonan-cuti", "detail", id], () => PermohonanCutiApi.getDetail(id));

  return (
    <>
      <ContentLayout showBreadcrumb>
        {getDetail?.isFetching ? (
          <DataStatus loading />
        ) : (
          <>
            <div className="text-end mb-3">
              <ButtonBack tooltip={false} onClick={() => navigate("/operasional-sdm/transaksi/permohonan-cuti")} />
            </div>
            <Formik enableReinitialize initialValues={formInitialValues(getDetail?.data)}>
              <Content action="READ" />
            </Formik>
          </>
        )}
      </ContentLayout>
    </>
  );
};
