import { jenisJabatanMapper } from "../__EvaluasiJabatanUtils__";

export const formInitialValues = (data = {}) => ({
  jenis_jabatan_id: data?.jenis_jabatan,
  jenis_jabatan_nama: jenisJabatanMapper(data?.jenis_jabatan),
  jabatan_id: data?.master_jabatan_id,
  jabatan_nama: data?.master_jabatan_nama,
  unit_organisasi_id: data?.master_unit_organisasi_id,
  unit_organisasi_nama: data?.master_unit_organisasi_nama,
  f1_id: data?.master_f1_id,
  f1_nama: data?.master_f1_nama,
  f1_kode: data?.master_f1_kode,
  f2_id: data?.master_f2_id,
  f2_nama: data?.master_f2_nama,
  f2_kode: data?.master_f2_kode,
  f3_id: data?.master_f3_id,
  f3_nama: data?.master_f3_nama,
  f3_kode: data?.master_f3_kode,
  a1_id: data?.master_judgement1_id,
  a1_nama: data?.master_judgement1_nama,
  a1_kode: data?.master_judgement1_kode,
  f4_id: data?.master_f4_id,
  f4_nama: data?.master_f4_nama,
  f4_kode: data?.master_f4_kode,
  f5_id: data?.master_f5_id,
  f5_nama: data?.master_f5_nama,
  f5_kode: data?.master_f5_kode,
  a2_id: data?.master_judgement2_id,
  a2_nama: data?.master_judgement2_nama,
  a2_kode: data?.master_judgement2_kode,
  f6_id: data?.master_f6_id,
  f6_nama: data?.master_f6_nama,
  f6_kode: data?.master_f6_kode,
  f7_id: data?.master_f7_id,
  f7_nama: data?.master_f7_nama,
  f7_kode: data?.master_f7_kode,
  f8_id: data?.master_f8_id,
  f8_nama: data?.master_f8_nama,
  f8_kode: data?.master_f8_kode,
  a3_id: data?.master_judgement3_id,
  a3_nama: data?.master_judgement3_nama,
  a3_kode: data?.master_judgement3_kode,
  grade_nama: data?.grade,
  grade_id: data?.master_grade_id,
  skor_1: data?.input?.score,
  skor_2: data?.proses?.persentase,
  skor_3: data?.output?.score,
  skor_1_color: data?.input?.master_warna_kode,
  skor_2_color: data?.proses?.master_warna_kode,
  skor_3_color: data?.output?.master_warna_kode,
  pangkat_id: data?.master_pangkat_id,
  pangkat_nama: data?.nama_pangkat
});
