import { Services } from "services";

class KelompokJabatanApi {
  async getList(params) {
    const fetch = await Services.get("/master/kelompok-jabatan", { params });
    return fetch?.data?.data;
  }

  async getExport(params) {
    const fetch = await Services.get("/master/kelompok-jabatan/export", { params });
    return fetch?.data?.data;
  }

  getJenisJabatan() {
    return [
      { label: "Struktural", value: 1 },
      { label: "Fungsional", value: 2 }
    ];
  }

  create(data) {
    return Services.post("/master/kelompok-jabatan", data);
  }

  update(data, id) {
    return Services.put("/master/kelompok-jabatan/" + id, data);
  }

  delete(id) {
    return Services.delete("/master/kelompok-jabatan/" + id);
  }
}

export default new KelompokJabatanApi();
