import { Services } from "services";
import { dateConvert } from "utilities";
const date = dateConvert();

class RekapPenghasilanApi {
  async getList(params) {
    const fetch = await Services.get("/laporan/rekap-penghasilan", { params });
    return fetch?.data?.data;
  }
  async getTanggal(params) {
    const fetch = await Services.get("/transaksi/pekerja-pajak-bulanan/dropdown-tanggal", { params });
    return fetch?.data?.data.map((val) => ({
      label: `${date.getSlashDMY(new Date(val?.tgl_bulan_awal))} - ${date.getSlashDMY(new Date(val?.tgl_bulan_akhir))}`,
      valueAwal: val?.tgl_bulan_awal,
      valueAkhir: val?.tgl_bulan_akhir
    }));
  }
  async getUnitOrganisasi() {
    const fetch = await Services.get("/master/unit-organisasi/dropdown");
    return fetch?.data?.data.map((val) => ({
      value: val?.master_unit_organisasi_id,
      label: val?.master_unit_organisasi_nama
    }));
  }
  async getPeriodePerencanaan() {
    const fetch = await Services.get("/laporan/rekap-penghasilan/dropdown-periode");
    return fetch?.data?.data.map((val) => ({
      value: val?.periode_gajian_id,
      label: val?.periode_gajian_perencanaan
    }));
  }
  async getKomponenPayroll() {
    const fetch = await Services.get("/master/komponen-payroll");
    return fetch?.data?.data;
  }
  async getExport(params) {
    const fetch = await Services.get("/laporan/rekap-penghasilan/export", { params });
    return fetch?.data?.data;
  }
}

export default new RekapPenghasilanApi();
