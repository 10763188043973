import { Services } from "services";

class JenisLemburMakanApi {
    async getList(params) {
        const fetch = await Services.get("/master/jenis-lembur-makan", { params });
        return fetch?.data?.data;
    }

    async getDetail(id) {
        const fetch = await Services.get("/master/jenis-lembur-makan/" + id);
        return fetch?.data?.data;
    }

    async getJenisRitase() {
        const fetch = await Services.get("/master/jenis-ritase/dropdown");
        return fetch?.data?.data.map((val) => ({
            value: val?.jenis_ritase_id,
            label: val?.jenis_ritase_nama
        }));
    }

    async getExport(params) {
        const fetch = await Services.get("/master/jenis-lembur-makan/export", { params });
        return fetch?.data?.data;
    }

    create(data) {
        return Services.post("/master/jenis-lembur-makan", data);
    }

    update(data, id) {
        return Services.put("/master/jenis-lembur-makan/" + id, data);
    }

    delete(id) {
        return Services.delete("/master/jenis-lembur-makan/" + id);
    }
}

export default new JenisLemburMakanApi();
