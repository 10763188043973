import { Formik, useFormikContext } from "formik";
import { ButtonGroup, Card, Col, Row } from "react-bootstrap";
import { TableList, ButtonDelete, Input, ButtonCreate, Select, InfoItemHorizontal } from "components";
import { useMutation, useQuery } from "react-query";
import { useModalConfirm } from "hooks";
import UraianJabatanApi from "../../__UraianJabatanApi__";
import { formSubmitMapper, formValidationSchema } from "../../__UraianJabatanUtils__";
import { errorSubmitMapper } from "utilities";
import { useParams } from "react-router-dom";

const HeadSectionForm = ({ action }) => {
  const { values, setValues, handleChange } = useFormikContext();
  const getJenjangPendidikan = useQuery(
    ["jenjangPendidikanSpesifikasi", "dropdown"],
    () => UraianJabatanApi.getJenjangPendidikan(),
    { enabled: Boolean(action !== "READ") }
  );

  return (
    <Col lg="6">
      {action === "READ" ? (
        <>
          <InfoItemHorizontal text={values?.jenjanganPendidikanNama} label="Pendidikan (minimum)" />
          <InfoItemHorizontal text={values?.pengalamanKerja} label="Pengalaman Kerja (minimum)" />
        </>
      ) : (
        <>
          <Select
            label="Pendidikan (minimum)*"
            placeholder="Pilih salah satu"
            name="jenis_jabatan"
            loading={getJenjangPendidikan?.isFetching}
            options={getJenjangPendidikan?.data ?? []}
            defaultValue={getJenjangPendidikan?.data?.find((item) => item.value === values?.jenjanganPendidikanId)}
            onChange={(val) => {
              setValues({ ...values, jenjanganPendidikanId: val.value, jenjanganPendidikanNama: val.label });
            }}
          />
          <Input
            label="Pengalaman Kerja (minimum)*"
            name="pengalamanKerja"
            placeholder="Masukan pengalaman kerja"
            value={values?.pengalamanKerja}
            onChange={handleChange}
          />
        </>
      )}
    </Col>
  );
};

const TableJenisKompetensi = ({ action, jenis, jenisKompetensiId }) => {
  const modalConfirm = useModalConfirm();
  const { setFieldValue, values, setValues } = useFormikContext();

  const getJenisKompetensi = useQuery(
    [jenis, "dropdown"],
    () => UraianJabatanApi.getJenisKompetensi({ master_jenis_kompetensi_id: jenisKompetensiId }),
    { enabled: !!jenisKompetensiId && action !== "READ" }
  );

  const getSkala = useQuery(["skala", "dropdown"], () => UraianJabatanApi.getSkala(), {
    enabled: Boolean(action !== "READ")
  });

  const formSubmitHandler = (data) => {
    if (jenis === "core") {
      setFieldValue("jeniskompetensiCore", [{ ...data }, ...values.jeniskompetensiCore]);
    }
    if (jenis === "role") {
      setFieldValue("jeniskompetensiRole", [{ ...data }, ...values.jeniskompetensiRole]);
    }
    if (jenis === "function") {
      setFieldValue("jeniskompetensiFunction", [{ ...data }, ...values.jeniskompetensiFunction]);
    }
  };

  return (
    <>
      {action !== "READ" && (
        <Formik
          enableReinitialize
          initialValues={{
            kompetensiJabatanNama: "",
            kompetensiJabatanId: "",
            skalaKemahiranId: "",
            skalaKemahiranNama: ""
          }}
          validationSchema={formValidationSchema.spesifikasi}
          onSubmit={(values) => {
            formSubmitHandler(values);
          }}
        >
          {({ values, setValues, errors, touched, handleSubmit }) => {
            return (
              <form>
                <Row>
                  <Col lg="3">
                    <Select
                      label={
                        jenis === "core"
                          ? "Jenis Kompetensi : Core"
                          : jenis === "role"
                          ? "Jenis Kompetensi : Role"
                          : "Jenis Kompetensi : Function"
                      }
                      placeholder="Pilih salah satu"
                      name="kompetensiJabatanId"
                      loading={getJenisKompetensi?.isFetching}
                      options={getJenisKompetensi?.data ?? []}
                      defaultValue={getJenisKompetensi?.data?.find(
                        (item) => item.value === values?.kompetensiJabatanId
                      )}
                      onChange={(val) => {
                        setValues({ ...values, kompetensiJabatanNama: val.label, kompetensiJabatanId: val.value });
                      }}
                      error={Boolean(errors.kompetensiJabatanId && touched.kompetensiJabatanId)}
                      errorText={
                        Boolean(errors.kompetensiJabatanId && touched.kompetensiJabatanId) && errors.kompetensiJabatanId
                      }
                    />
                  </Col>
                  <Col lg="3">
                    <Select
                      label="Skala"
                      placeholder="Pilih salah satu"
                      name="skalaKemahiranId"
                      loading={getSkala?.isFetching}
                      options={getSkala?.data ?? []}
                      defaultValue={getSkala?.data?.find((item) => item.value === values?.skalaKemahiranId)}
                      onChange={(val) => {
                        setValues({ ...values, skalaKemahiranId: val.value, skalaKemahiranNama: val.label });
                      }}
                      error={Boolean(errors.skalaKemahiranId && touched.skalaKemahiranId)}
                      errorText={
                        Boolean(errors.skalaKemahiranId && touched.skalaKemahiranId) && errors.skalaKemahiranId
                      }
                    />
                  </Col>
                  <Col lg="6">
                    <ButtonCreate
                      icon
                      noText
                      className="mt-4"
                      onClick={() => {
                        handleSubmit();
                      }}
                    />
                  </Col>
                </Row>
              </form>
            );
          }}
        </Formik>
      )}

      <TableList
        tableHeader={[
          {
            text: jenis === "core" ? "Kompetensi Core" : jenis === "role" ? "Kompetensi Role" : "Kompetensi Function"
          },
          { text: "Skala" },
          {
            text: "Aksi",
            props: { fixed: true, width: 50, className: action === "READ" ? "d-none" : "" }
          }
        ]}
        data={
          jenis === "core"
            ? values?.jeniskompetensiCore
            : jenis === "role"
            ? values?.jeniskompetensiRole
            : values?.jeniskompetensiFunction
        }
        className="mt-3"
        tableBody={[
          {
            field: "kompetensiJabatanNama"
          },
          { field: "skalaKemahiranNama" },
          {
            props: { fixed: true, className: action === "READ" ? "d-none" : "" },
            customField: (data, index) => (
              <ButtonGroup size="sm">
                {!Boolean(action === "READ") && (
                  <ButtonDelete
                    icon
                    noText
                    onClick={() => {
                      modalConfirm.trigger({
                        type: "delete",
                        data: [
                          { label: "Kompetensi Core", text: data?.kompetensiJabatanNama },
                          { label: "Skala", text: data?.skalaKemahiranNama }
                        ],
                        onSubmit: () => {
                          if (jenis === "core") {
                            const deletedData = values?.jeniskompetensiCore?.filter((val, ind) => ind !== index);
                            setValues({ ...values, jeniskompetensiCore: deletedData });
                          }

                          if (jenis === "role") {
                            const deletedData = values?.jeniskompetensiRole?.filter((val, ind) => ind !== index);
                            setValues({ ...values, jeniskompetensiRole: deletedData });
                          }

                          if (jenis === "function") {
                            const deletedData = values?.jeniskompetensiFunction?.filter((val, ind) => ind !== index);
                            setValues({ ...values, jeniskompetensiFunction: deletedData });
                          }

                          modalConfirm.close();
                        },

                        onHide: modalConfirm.close
                      });
                    }}
                  />
                )}
              </ButtonGroup>
            )
          }
        ]}
      />
    </>
  );
};

const TableSertifikat = ({ action, jenis, dropdown }) => {
  const modalConfirm = useModalConfirm();
  const { setFieldValue, values, setValues } = useFormikContext();

  const formValidationSertifikat =
    jenis === "pelatihan" ? formValidationSchema.sertifikatPelatihan : formValidationSchema.sertifikatProfesi;

  const intialValueSertifikat =
    jenis === "pelatihan"
      ? { sertifikatPelatihanId: "", sertifikatPelatihanNama: "" }
      : { sertifikatProfesiId: "", sertifikatProfesiNama: "" };

  const validate = (errors, touched) => {
    return jenis === "pelatihan"
      ? {
          error: Boolean(errors.sertifikatPelatihanId && touched.sertifikatPelatihanId),
          errorText:
            Boolean(errors.sertifikatPelatihanId && touched.sertifikatPelatihanId) && errors.sertifikatPelatihanId
        }
      : {
          error: Boolean(errors.sertifikatProfesiId && touched.sertifikatProfesiId),
          errorText: Boolean(errors.sertifikatProfesiId && touched.sertifikatProfesiId) && errors.sertifikatProfesiId
        };
  };

  const formSubmitHandler = (data) => {
    if (jenis === "pelatihan") {
      setFieldValue("sertifikatPelatihan", [{ ...data }, ...values.sertifikatPelatihan]);
    }
    if (jenis === "profesi") {
      setFieldValue("sertifikatProfesi", [{ ...data }, ...values.sertifikatProfesi]);
    }
  };

  return (
    <>
      {action !== "READ" && (
        <Formik
          enableReinitialize
          initialValues={intialValueSertifikat}
          validationSchema={formValidationSertifikat}
          onSubmit={(values) => {
            formSubmitHandler(values);
          }}
        >
          {({ values, setValues, errors, touched, handleSubmit }) => {
            return (
              <form>
                <Row>
                  <Col lg="3">
                    <Select
                      label={`Sertifikat ${jenis === "pelatihan" ? "Pelatihan" : "Profesi"}`}
                      placeholder="Pilih salah satu"
                      loading={dropdown?.isFetching}
                      options={dropdown?.data ?? []}
                      onChange={(val) => {
                        if (jenis === "pelatihan") {
                          setValues({
                            ...values,
                            sertifikatPelatihanNama: val.label,
                            sertifikatPelatihanId: val.value
                          });
                        }

                        if (jenis === "profesi") {
                          setValues({ ...values, sertifikatProfesiNama: val.label, sertifikatProfesiId: val.value });
                        }
                      }}
                      error={validate(errors, touched).error}
                      errorText={validate(errors, touched).errorText}
                    />
                  </Col>
                  <Col lg="6">
                    <ButtonCreate
                      icon
                      noText
                      className="mt-4"
                      onClick={() => {
                        handleSubmit();
                      }}
                    />
                  </Col>
                </Row>
              </form>
            );
          }}
        </Formik>
      )}

      <TableList
        tableHeader={[
          {
            text: `Sertifikat ${jenis === "pelatihan" ? "Pelatihan" : "Profesi"}`
          },
          {
            text: "Aksi",
            props: { fixed: true, width: 50, className: action === "READ" ? "d-none" : "" }
          }
        ]}
        data={jenis === "pelatihan" ? values?.sertifikatPelatihan : values?.sertifikatProfesi}
        className="mt-3"
        tableBody={[
          {
            field: jenis === "pelatihan" ? "sertifikatPelatihanNama" : "sertifikatProfesiNama"
          },
          {
            props: { fixed: true, className: action === "READ" ? "d-none" : "" },
            customField: (data, index) => (
              <ButtonGroup size="sm">
                {!Boolean(action === "READ") && (
                  <ButtonDelete
                    icon
                    noText
                    onClick={() => {
                      modalConfirm.trigger({
                        type: "delete",
                        data: [
                          {
                            label: "Sertifikat",
                            text: jenis === "pelatihan" ? data?.sertifikatPelatihanNama : data?.sertifikatProfesiNama
                          }
                        ],
                        onSubmit: () => {
                          if (jenis === "pelatihan") {
                            const deletedData = values?.sertifikatPelatihan?.filter((val, ind) => ind !== index);
                            setValues({ ...values, sertifikatPelatihan: deletedData });
                          }

                          if (jenis === "profesi") {
                            const deletedData = values?.sertifikatProfesi?.filter((val, ind) => ind !== index);
                            setValues({ ...values, sertifikatProfesi: deletedData });
                          }

                          modalConfirm.close();
                        },

                        onHide: modalConfirm.close
                      });
                    }}
                  />
                )}
              </ButtonGroup>
            )
          }
        ]}
      />
    </>
  );
};

export const TabSpesifikasi = ({ action }) => {
  const modalConfirm = useModalConfirm();
  const { id } = useParams();
  const parentFormik = useFormikContext();

  const getSertifikatPelatihan = useQuery(
    ["sertifikatPelatihan", "dropdown"],
    () => UraianJabatanApi.getSertifikatPelatihan(),
    {
      enabled: Boolean(action !== "READ")
    }
  );

  const getSertifikatProfesi = useQuery(
    ["sertifikatProfesi", "dropdown"],
    () => UraianJabatanApi.getSertifikatProfesi(),
    {
      enabled: Boolean(action !== "READ")
    }
  );

  const updateSpesifikasi = useMutation((data) => UraianJabatanApi.update(data, id), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "update", customTextInfoTitle: "Data Berhasil Disimpan" });
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "update", ...errorSubmitMapper(err) })
  });

  const submitValidationHandler = (errors) =>
    new Promise((resolve, reject) => {
      const getError = Object.values(errors);

      if (getError.length > 0) {
        reject(getError);
      } else {
        resolve();
      }
    });

  const preSubmitHandler = (values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting) => {
    setSubmitting(true);
    validateForm().then(async (err) => {
      setErrors(err);
      setTouched(err);

      await submitValidationHandler(err, values)
        .then(() => {
          handleSubmit();
        })
        .catch((err) =>
          modalConfirm.trigger({
            type: "error",
            title: "Data Tidak Lengkap",
            data: err,
            onHide: () => modalConfirm.close()
          })
        )
        .finally(() => {
          setSubmitting(false);
        });
    });
  };

  const formSubmitHandler = (values) =>
    modalConfirm.trigger({
      type: "custom",
      onHide: modalConfirm.close,
      title: "Konfirmasi",
      customButtonShow: true,
      customButtonText: "Simpan",
      customButtonVariant: "primary",
      customTextHeader: "Apakah anda yakin menyimpan data ini?",
      onSubmit: () => {
        modalConfirm.loading();

        updateSpesifikasi.mutate(formSubmitMapper().spesifikasi(values));
      },
      component: (
        <>
          <Formik initialValues={values}>
            <>
              <HeadSectionForm action="READ" />
              <Card className="mt-4">
                <Card.Header>
                  <div>Kompetensi</div>
                </Card.Header>
                <Card.Body>
                  <TableJenisKompetensi action="READ" jenis="core" />
                  <div className="mt-4" />
                  <TableJenisKompetensi action="READ" jenis="role" />
                  <div className="mt-4" />
                  <TableJenisKompetensi action="READ" jenis="function" />
                  <div className="mt-4" />
                  <TableSertifikat action="READ" jenis="pelatihan" dropdown={getSertifikatPelatihan} />
                  <div className="mt-4" />
                  <TableSertifikat action="READ" jenis="profesi" dropdown={getSertifikatProfesi} />
                </Card.Body>
              </Card>
            </>
          </Formik>
        </>
      )
    });

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={parentFormik?.values?.spesifikasi}
        onSubmit={(values) => {
          formSubmitHandler(values, action);
        }}
      >
        {({ values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting }) => {
          return (
            <>
              {action === "READ" ? (
                <>
                  <HeadSectionForm action="READ" />
                  <Card className="mt-4">
                    <Card.Header>
                      <div>Kompetensi</div>
                    </Card.Header>
                    <Card.Body>
                      <TableJenisKompetensi action="READ" jenis="core" jenisKompetensiId="1" />
                      <div className="mt-4" />
                      <TableJenisKompetensi action="READ" jenis="role" jenisKompetensiId="2" />
                      <div className="mt-4" />
                      <TableJenisKompetensi action="READ" jenis="function" jenisKompetensiId="3" />
                      <div className="mt-4" />
                      <TableSertifikat action="READ" jenis="pelatihan" dropdown={getSertifikatPelatihan} />
                      <div className="mt-4" />
                      <TableSertifikat action="READ" jenis="profesi" dropdown={getSertifikatProfesi} />
                    </Card.Body>
                  </Card>
                </>
              ) : (
                <>
                  <HeadSectionForm />
                  <Card className="mt-4">
                    <Card.Header>
                      <div>Kompetensi</div>
                    </Card.Header>
                    <Card.Body>
                      <TableJenisKompetensi jenis="core" jenisKompetensiId="1" />
                      <div className="mt-4" />
                      <TableJenisKompetensi jenis="role" jenisKompetensiId="2" />
                      <div className="mt-4" />
                      <TableJenisKompetensi jenis="function" jenisKompetensiId="3" />
                      <div className="mt-4" />
                      <TableSertifikat jenis="pelatihan" dropdown={getSertifikatPelatihan} />
                      <div className="mt-4" />
                      <TableSertifikat jenis="profesi" dropdown={getSertifikatProfesi} />
                    </Card.Body>
                  </Card>

                  <div className="d-flex justify-content-end mb-2 mt-3">
                    <ButtonCreate
                      text="Simpan"
                      onClick={() => {
                        preSubmitHandler(values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting);
                      }}
                      disabled={values?.detail?.length < 1}
                    />
                  </div>
                </>
              )}
            </>
          );
        }}
      </Formik>
    </>
  );
};
