export const formSubmitMapper = (data) => ({
  array: data?.detail?.map((val) => ({
    pekerja_upah_pokok_id: val?.pekerjaUpahPokokId,
    master_unit_organisasi_id: val?.unitOrganisasiId,
    periode_tahun: val?.periodeGajianId,
    tgl_setup: val?.tglSetup,
    pekerja_id: val?.pekerjaId,
    status: 1,
    nominal: val?.nominal
  })),

  object: {
    periode_tahun: data?.periodeGajianId,
    tgl_setup: data?.tglSetup,
    tgl_bulan_awal: data?.tglAwal,
    tgl_bulan_akhir: data?.tlAkhir,
    pekerja_id: data?.pekerjaId,
    master_unit_organisasi_id: data?.unitOrganisasiId,

    nominal: data?.nominal,
    status: data?.status
  }
});
