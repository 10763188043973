import { useQuery } from "react-query";
import { Col, Row, Card } from "react-bootstrap";
import { useFormikContext } from "formik";
import { InfoItemVertical, Select, Input, DatePicker, InputNumber, TextArea, dateConvert } from "components";
import PermohonanCutiApi from "../__PermohonanCutiApi__";

export const Content = ({ action }) => {
  const { values, setValues, errors, touched, handleChange } = useFormikContext();
  const date = dateConvert();

  const getUnitOrganisasi = useQuery(["unit-organisasi", "dropdown"], () => PermohonanCutiApi.getUnitOrganisasi());
  const getJenisCuti = useQuery(["jenis-cuti", "dropdown"], () => PermohonanCutiApi.getJenisCuti());

  const getPekerja = useQuery(["pekerja", "dropdown", values?.unitOrganisasiId], () =>
    PermohonanCutiApi.getPekerja({
      master_unit_organisasi_id: values?.unitOrganisasiId
    })
  );

  const getPerhitungan = useQuery(
    ["perhitungan-jenis-cuti", values?.pekerjaId, values?.periodeGajianId, values.jenisCutiId],
    () =>
      PermohonanCutiApi.getPerhitunganJenisCuti({
        periode_tahun: values?.periodeGajianId,
        pekerja_id: values?.pekerjaId,
        master_jenis_cuti_id: values?.jenisCutiId
      }),
    {
      enabled: Boolean(values?.pekerjaId && values?.periodeGajianId && values.jenisCutiId),
      onSuccess: (data) =>
        setValues({
          ...values,
          maxCuti: data?.maksimal_cuti,
          cutiSudah: data?.total_cuti_tahun_ini,
          sisaCuti: data?.total_sisa_cuti
        })
    }
  );

  if (action === "READ") {
    return (
      <>
        <Row>
          <Col>
            <InfoItemVertical label="Periode" text={values?.periodeGajianId} />
          </Col>
        </Row>
        <Row>
          <Col>
            <InfoItemVertical label="Tanggal" text={date.getSlashDMY(new Date(values?.tglInput))} />
          </Col>
          <Col>
            <InfoItemVertical label="Nomor" text={values?.nomor} />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <InfoItemVertical label="Unit Organisasi" text={values?.unitOrganisasiNama} />
            <InfoItemVertical label="Pekerja" text={values?.pekerjaNama} />
            <InfoItemVertical label="Jabatan" text={values?.jabatanNama} />
            <div className="mt-2">
              <p>Bermaksud mengajukan izin untuk keperluan</p>
            </div>
            <InfoItemVertical label="Jenis Cuti" text={values?.jenisCutiNama} />
            <InfoItemVertical label="Maksimal Cuti" text={values?.maxCuti ? values?.maxCuti + " Hari" : "-"} />
            <InfoItemVertical
              label="Cuti Yang Sudah Diambil"
              text={values?.cutiSudah ? values?.cutiSudah + " Hari" : "-"}
            />
            <InfoItemVertical label="Sisa Cuti Tahun Ini" text={values?.sisaCuti ? values?.sisaCuti + " Hari" : "-"} />
            <InfoItemVertical
              label="Tanggal Mulai Cuti"
              text={values?.tglMulai ? date.getSlashDMY(new Date(values?.tglMulai)) : "-"}
            />
            <InfoItemVertical
              label="Tanggal Akhir Cuti"
              text={values?.tglAkhir ? date.getSlashDMY(new Date(values?.tglAkhir)) : "-"}
            />
            <InfoItemVertical label="Alasan" text={values?.alasan} />
          </Col>
        </Row>
      </>
    );
  }

  return (
    <>
      <Row>
        <Col>
          <Card style={{ height: "100%" }}>
            <Card.Header>
              <b>Surat Permohonan Cuti</b>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col md={6}>
                  <Input
                    name="periodeGajianId"
                    label="Periode"
                    value={values?.periodePerencanaan}
                    onChange={(val) =>
                      setValues({
                        ...values,
                        periodeGajianId: val?.target.value,
                        periodePerencanaan: val?.target.value
                      })
                    }
                    error={Boolean(errors.periodeGajianId && touched.periodeGajianId)}
                    errorText={Boolean(errors.periodeGajianId && touched.periodeGajianId) && errors.periodeGajianId}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <DatePicker
                    disabled={Boolean(action !== "CREATE")}
                    label="Tanggal"
                    placeholderText="Pilih tanggal input permohonan izin"
                    selected={values?.tglInput}
                    onChange={(val) =>
                      setValues({
                        ...values,
                        tglInput: val
                      })
                    }
                    error={Boolean(errors.tglInput && touched.tglInput)}
                    errorText={Boolean(errors.tglInput && touched.tglInput) && errors.tglInput}
                  />
                </Col>
                <Col>
                  <Input
                    disabled={Boolean(action !== "CREATE")}
                    name="nomor"
                    label="Nomor"
                    placeholder="Masukkan nomor surat"
                    value={values?.nomor}
                    onChange={handleChange}
                    error={Boolean(errors.nomor && touched.nomor)}
                    errorText={Boolean(errors.nomor && touched.nomor) && errors.nomor}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Select
                    disable={Boolean(action !== "CREATE")}
                    label="Unit Organisasi"
                    placeholder="Pilih unit organisasi"
                    defaultValue={getUnitOrganisasi?.data?.find((item) => item.value === values.unitOrganisasiId)}
                    options={getUnitOrganisasi?.data ?? []}
                    onChange={(val) =>
                      setValues({
                        ...values,
                        unitOrganisasiId: val.value,
                        unitOrganisasiNama: val.label
                      })
                    }
                    loading={getUnitOrganisasi.isFetching}
                    errorFetch={getUnitOrganisasi.isError}
                    error={Boolean(errors.unitOrganisasiId && touched.unitOrganisasiId)}
                    errorText={Boolean(errors.unitOrganisasiId && touched.unitOrganisasiId) && errors.unitOrganisasiId}
                  />
                  <Select
                    label="Pekerja"
                    placeholder="Pilih pekerja"
                    defaultValue={getPekerja?.data?.find((item) => item.value === values.pekerjaId)}
                    options={getPekerja?.data ?? []}
                    onChange={(val) =>
                      setValues({
                        ...values,
                        pekerjaId: val.value,
                        pekerjaNama: val.label,
                        jabatanId: val.jabatanId,
                        jabatanNama: val.jabatanNama
                      })
                    }
                    loading={getPekerja.isFetching}
                    errorFetch={getPekerja.isError}
                    error={Boolean(errors.pekerjaId && touched.pekerjaId)}
                    errorText={Boolean(errors.pekerjaId && touched.pekerjaId) && errors.pekerjaId}
                  />
                  <Input
                    name="jabatan"
                    disabled
                    label="Jabatan"
                    value={values?.jabatanNama}
                    onChange={handleChange}
                    error={Boolean(errors.jabatanId && touched.jabatanId)}
                    errorText={Boolean(errors.jabatanId && touched.jabatanId) && errors.jabatanId}
                  />
                  <div className="mt-2">
                    <p>Bermaksud mengajukan cuti untuk keperluan</p>
                  </div>
                  <Select
                    label="Jenis Cuti"
                    placeholder="Pilih jenis cuti"
                    defaultValue={getJenisCuti?.data?.find((item) => item.value === values.jenisCutiId)}
                    options={getJenisCuti?.data ?? []}
                    onChange={(val) =>
                      setValues({
                        ...values,
                        jenisCutiId: val.value,
                        jenisCutiNama: val.label
                      })
                    }
                    loading={getJenisCuti.isFetching}
                    errorFetch={getJenisCuti.isError}
                    error={Boolean(errors.jenisCutiId && touched.jenisCutiId)}
                    errorText={Boolean(errors.jenisCutiId && touched.jenisCutiId) && errors.jenisCutiId}
                  />
                  <Input
                    disabled
                    loading={getPerhitungan?.isLoading}
                    name="maxCuti"
                    label="Maksimal Cuti"
                    placeholder="Maksimal cuti"
                    value={values?.maxCuti}
                    onChange={handleChange}
                    error={Boolean(errors.maxCuti && touched.maxCuti)}
                    errorText={Boolean(errors.maxCuti && touched.maxCuti) && errors.maxCuti}
                  />
                  <Input
                    disabled
                    name="cutiSudah"
                    label="Cuti Yang Sudah Diambil"
                    placeholder="Maksimal yang sudah diambil"
                    value={values?.cutiSudah}
                    onChange={handleChange}
                    error={Boolean(errors.cutiSudah && touched.cutiSudah)}
                    errorText={Boolean(errors.cutiSudah && touched.cutiSudah) && errors.cutiSudah}
                  />
                  <Input
                    disabled
                    name="sisaCuti"
                    label="Sisa Cuti Tahun Ini"
                    placeholder="Sisa cuti tahun ini"
                    value={values?.sisaCuti}
                    onChange={handleChange}
                    error={Boolean(errors.sisaCuti && touched.sisaCuti)}
                    errorText={Boolean(errors.sisaCuti && touched.sisaCuti) && errors.sisaCuti}
                  />
                  <DatePicker
                    label="Tanggal Mulai"
                    placeholderText="Pilih tanggal mulai cuti"
                    selected={values?.tglMulai}
                    onChange={(val) => {
                      const dateDiff = new Date(val?.tglAkhir).getTime() - new Date(val).getTime();
                      const dayDiff = Math.round(dateDiff / (1000 * 3600 * 24)) + 1;

                      setValues({
                        ...values,
                        tglMulai: val,
                        lamaCuti: dayDiff
                      });
                    }}
                    error={Boolean(errors.tglMulai && touched.tglMulai)}
                    errorText={Boolean(errors.tglMulai && touched.tglMulai) && errors.tglMulai}
                  />
                  <DatePicker
                    label="Tanggal Akhir"
                    placeholderText="Pilih tanggal akhir cuti"
                    selected={values?.tglAkhir}
                    onChange={(val) => {
                      const dateDiff = new Date(val).getTime() - new Date(values?.tglMulai).getTime();
                      const dayDiff = Math.round(dateDiff / (1000 * 3600 * 24)) + 1;

                      setValues({
                        ...values,
                        tglAkhir: val,
                        lamaCuti: dayDiff
                      });
                    }}
                    error={Boolean(errors.tglAkhir && touched.tglAkhir)}
                    errorText={Boolean(errors.tglAkhir && touched.tglAkhir) && errors.tglAkhir}
                  />
                  <Input
                    disabled
                    name="lamaCuti"
                    label="Lama Cuti"
                    value={values?.lamaCuti ? values?.lamaCuti + " Hari" : ""}
                    error={Boolean(errors.lamaCuti && touched.lamaCuti)}
                    errorText={Boolean(errors.lamaCuti && touched.lamaCuti) && errors.lamaCuti}
                  />
                  <TextArea
                    label="Alasan"
                    name="alasan"
                    placeholder="Masukkan alasan"
                    value={values.alasan}
                    onChange={(val) =>
                      setValues({
                        ...values,
                        alasan: val.target.value
                      })
                    }
                    error={Boolean(errors.alasan && touched.alasan)}
                    errorText={Boolean(errors.alasan && touched.alasan) && errors.alasan}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
