import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import { numberConvert, dateConvert } from "utilities";
import { TableList, InfoItemVertical } from "components";

export const DataPekerja = ({ getListForm, dataFilter }) => {
  const number = numberConvert();
  const date = dateConvert();

  const dataInfo = getListForm?.data?.komponen_bpjs;

  const onValuesUndefined =
    dataFilter.periodePenggajianId === undefined ||
    dataFilter.unitOrganisasiId === undefined ||
    dataFilter.bulanId === undefined ||
    dataFilter.tanggalSetup === undefined;

  return (
    <Card>
      <Card.Header>
        <b>Data Pekerja</b>
      </Card.Header>
      <Card.Body>
        <>
          <Row>
            <Col md={6}>
              <InfoItemVertical label="Periode Penggajian" text={dataInfo?.periode_gajian ?? "-"} />
              <InfoItemVertical
                label="Bulan Penggajian"
                text={
                  (dataInfo?.tgl_bulan_awal ? date.getSlashDMY(new Date(dataInfo?.tgl_bulan_awal)) : "-") +
                  " s/d " +
                  (dataInfo?.tgl_bulan_akhir ? date.getSlashDMY(new Date(dataInfo?.tgl_bulan_akhir)) : "-")
                }
              />
              <InfoItemVertical
                label="Tanggal Setup"
                text={dataInfo?.tgl_setup ? date.getSlashDMY(new Date(dataInfo?.tgl_setup)) : "-"}
              />
            </Col>
            <Col md={6}>
              <InfoItemVertical label="Unit Organisasi" text={dataInfo?.master_unit_organisasi_nama ?? "-"} />
              <InfoItemVertical label="Jenis Upah Minimum" text={dataInfo?.jenis_upah ?? "-"} />
              <InfoItemVertical label="Komponen BPJS" text={dataInfo?.komponen_bpjs_nama ?? "-"} />
              {/* <InfoItemVertical
                label="Proporsi Perusahaan"
                text={dataInfo?.proporsi_perusahaan ? `${number.getWithComa(dataInfo?.proporsi_perusahaan, { minimumFractionDigits: 2, maximumFractionDigits: 4 })}%` : "-"}
              />
              <InfoItemVertical
                label="Proporsi Karyawan"
                text={dataInfo?.proporsi_pekerja ? `${number.getWithComa(dataInfo?.proporsi_pekerja, { minimumFractionDigits: 2, maximumFractionDigits: 4 })}%` : "-"}
              />
              <InfoItemVertical
                label="Batas Bawah Upah"
                text={
                  dataInfo?.batas_bawah
                    ? `Rp${number.getWithComa(parseInt(dataInfo?.batas_bawah), { minimumFractionDigits: 2 })}`
                    : "-"
                }
              />
              <InfoItemVertical
                label="Batas Atas Upah"
                text={
                  dataInfo?.batas_atas
                    ? `Rp${number.getWithComa(parseInt(dataInfo?.batas_atas), { minimumFractionDigits: 2 })}`
                    : "-"
                }
              />
              <InfoItemVertical label="Keterangan" text={dataInfo?.keterangan ?? "-"} /> */}
            </Col>
          </Row>

          <TableList
            className="mb-4"
            data={onValuesUndefined ? [] : getListForm?.data?.pekerja}
            loading={onValuesUndefined ? false : getListForm?.isFetching}
            tableHeader={[
              {
                text: "NIK"
              },
              {
                text: "Pekerja"
              },
              {
                text: "Unit Organisasi"
              },
              {
                text: "Jabatan"
              },
              {
                text: "Upah Tetap BPJS"
              },
              {
                text: "Besaran Upah"
              },
              {
                text: "Iuran Perusahaan"
              },
              {
                text: "Iuran Karyawan"
              },
              {
                text: "Jenis BPJS"
              }
            ]}
            tableBody={[
              { field: "nip" },
              { field: "pekerja_nama" },
              { field: "master_unit_organisasi_nama" },
              { field: "master_jabatan_nama" },
              {
                props: { textRight: true },
                customField: (data) =>
                  `Rp${number.getWithComa(parseInt(data?.upah_bpjs), { minimumFractionDigits: 2 })}`
              },
              {
                props: { textRight: true },
                customField: (data) =>
                  `Rp${number.getWithComa(parseInt(data?.besaran_upah), { minimumFractionDigits: 2 })}`
              },
              {
                props: { textRight: true },
                customField: (data) =>
                  `Rp${number.getWithComa(parseInt(data?.iuran_perusahaan), { minimumFractionDigits: 2 })}`
              },
              {
                props: { textRight: true },
                customField: (data) =>
                  `Rp${number.getWithComa(parseInt(data?.iuran_karyawan), { minimumFractionDigits: 2 })}`
              },
              {
                field: "jenis"
              }
            ]}
          />
        </>
      </Card.Body>
    </Card>
  );
};
