import React from "react";
import { useFormikContext } from "formik";
import { Input, InfoItemVertical, TextArea } from "components";

export const Content = ({ action }) => {
  const { values, errors, touched, handleChange } = useFormikContext();

  if (action === "READ") {
    return (
      <>
        <InfoItemVertical label="Jenis Upah Minimum" text={values?.jenisUpahMinimmum ?? "-"} />
        <InfoItemVertical label="Keterangan" text={values?.keterangan ?? "-"} />
      </>
    );
  }

  return (
    <>
      <Input
        label="Jenis Upah Minimum"
        name="jenisUpahMinimmum"
        placeholder="Masukkan jenis upah minimum"
        value={values.jenisUpahMinimmum}
        onChange={handleChange}
        error={Boolean(errors.jenisUpahMinimmum && touched.jenisUpahMinimmum)}
        errorText={Boolean(errors.jenisUpahMinimmum && touched.jenisUpahMinimmum) && errors.jenisUpahMinimmum}
      />
      <TextArea
        label="Keterangan"
        name="keterangan"
        placeholder="Masukkan keterangan"
        value={values.keterangan}
        onChange={handleChange}
        error={Boolean(errors.keterangan && touched.keterangan)}
        errorText={Boolean(errors.keterangan && touched.keterangan) && errors.keterangan}
      />
    </>
  );
};
