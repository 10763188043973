import { Services } from "services";

class KomponenPayrollApi {
  async getList(params) {
    const fetch = await Services.get("/master/komponen-payroll", { params });
    return fetch?.data?.data;
  }

  async getDetail(id) {
    const fetch = await Services.get("/master/komponen-payroll/" + id);
    return fetch?.data?.data;
  }

  getStatusKomponenPayroll() {
    return [
      {
        value: 1,
        label: "Penghasilan"
      },
      {
        value: 2,
        label: "Pemotongan"
      }
    ];
  }

  async getExport(params) {
    const fetch = await Services.get("/master/komponen-payroll/export?to=", { params });
    return fetch?.data?.data;
  }

  create(data) {
    return Services.post("/master/komponen-payroll", data);
  }

  update(data, id) {
    return Services.put("/master/komponen-payroll/" + id, data);
  }

  delete(id) {
    return Services.delete("/master/komponen-payroll/" + id);
  }
}

export default new KomponenPayrollApi();
