import pagesConfig from "./pagesConfig";
import logo from "../assets/Logo/brayaPNG.png";
import icon from "../assets/Logo/brayaPNG.png";

const config = {
  /**
   * APP_NAME:
   * Digunakan untuk menentukan nama yang project yang tampil pada topbar
   */
  APP_NAME: "Braya",

  /**
   * APP_CODE:
   * Digunakan untuk menentukan kode yang akan dicocokan pada data dari backend untuk
   */
  APP_CODE: "HRD",

  /**
   * MODUL:
   * Digunakan untuk menentukan nama modul yang tampil pada sidebar
   */
  MODUL: "HRIS",

  /**
   * UNIT:
   * Digunakan untuk menentukan nama unit produksi dll, yang akan tampil pada sidebar
   * Unit akan berada dibawah tulisan modul pada sidebar
   * Unit dapat dikosongkan (not required)
   */
  UNIT: "",

  /**
   * ICON:
   * Digunakan untuk menentukan icon yang ada pada header window
   */
  ICON: icon,

  /**
   * LOGO:
   * Digunakan untuk menentukan logo yang ada pada sidebar
   */
  LOGO: logo,

  /**
   * PAGES CONFIG:
   * Digunakan untuk menentukan menu-menu yang tampil pada sidebar dan routes pada aplikasi
   * Tambahkan pages di __PagesConfig__
   */
  PAGES: pagesConfig
};

export default config;
