export const errorFetchingPageMapper = (err) => {
  return err?.response?.data?.data ? err?.response?.data?.data : "Data gagal dimuat";
};

export const errorFetchingTableMapper = (err) => {
  return err?.response?.data?.data ? err?.response?.data?.data : "Data gagal dimuat";
};

export const errorFetchingSelectMapper = (err) => {
  return err?.response?.data?.data ? err?.response?.data?.data : "Data gagal dimuat";
};

export const errorSubmitMapper = (err) => {
  let errMessage = [];

  try {
    const getErrorList = err?.response?.data?.data;
    Object.values(getErrorList).forEach((val) => val.forEach((val) => errMessage.push(val)));
  } catch (error) {
    const getErrorMessage = err?.response?.data?.meta?.message;
    errMessage.push(getErrorMessage);
  }

  return {
    customTextInfoDetail: (
      <div className="d-flex flex-column mt-2">
        <ul>
          {errMessage?.map((val) => (
            <li className="text-danger">{val}</li>
          ))}
        </ul>
      </div>
    )
  };
};
