import { dateConvert } from "@bhawanadevteam/react-core";

const date = dateConvert();

export const formInitialValues = () => ({
  tanggalSetup: date.getDashYMD(new Date()),
  periodePenggajianId: new Date().getFullYear(),
  periodePenggajianNama: new Date().getFullYear(),
  bulanId: "",
  unitOrganisasiId: ""
});
