import { Services } from "services";
import { fetchDropdownMapper } from "utilities";
const fetchDropdown = fetchDropdownMapper(Services);

class KompetensiApi {
  async getList(params) {
    const fetch = await Services.get("/master/kompetensi-jabatan", { params });
    return fetch?.data?.data;
  }

  async getExport(params) {
    const fetch = await Services.get("/master/kompetensi-jabatan/export", { params });
    return fetch?.data?.data;
  }

  async getJenisKompetensi() {
    const getJenisDropdown = await fetchDropdown("/master/jenis-kompetensi/dropdown", {
      value: "master_jenis_kompetensi_id",
      label: "master_jenis_kompetensi_nama"
    });

    return [{ value: undefined, label: "Semua" }, ...getJenisDropdown];
  }

  create(data) {
    return Services.post("/master/kompetensi-jabatan", data);
  }

  update(data, id) {
    return Services.put("/master/kompetensi-jabatan/" + id, data);
  }

  delete(id) {
    return Services.delete("/master/kompetensi-jabatan/" + id);
  }
}

export default new KompetensiApi();
