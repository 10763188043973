import { Services } from "services";
import { fetchDropdownMapper } from "utilities";

const fetchDropdown = fetchDropdownMapper(Services);

class SetupThrApi {
  async getList(params) {
    const fetch = await Services.get("/master/tarif-ptkp", { params });
    return fetch?.data?.data;
  }

  async getPeriodePenggajian() {
    const getPeriodePenggajian = await fetchDropdown("/master/grade-nominal/dropdown", {
      value: "periode_gajian_id",
      label: "periode_perencanaan"
    });

    return getPeriodePenggajian;
  }
  async getStatusPajak() {
    const getStatusPajak = await fetchDropdown("/master/tarif-ptkp", {
      value: "status_pajak",
      label: "status_pajak"
    });

    return getStatusPajak;
  }

  async getExport(params) {
    const fetch = await Services.get("/master/tarif-ptkp/export", { params });
    return fetch?.data?.data;
  }

  create(data) {
    return Services.post("/master/tarif-ptkp", data);
  }

  update(data, id) {
    return Services.put("/master/tarif-ptkp/" + id, data);
  }

  delete(id) {
    return Services.delete("/master/tarif-ptkp/" + id);
  }
}
export default new SetupThrApi();
