import { InfoItemVertical, Select, TextArea } from "components";
import { useFormikContext } from "formik";
import { useQuery } from "react-query";
import AtasanUnitOrganisasiApi from "../__AtasanUnitOrganisasiApi__";

export const Content = ({ action }) => {
  const { values, errors, touched, handleChange, setValues } = useFormikContext();

  const getUnitOrganisasi = useQuery(["unit-organisasi-dropdown"], () => AtasanUnitOrganisasiApi.getUnitOrganisasi());
  const getJabatan = useQuery(["jabatan-dropdown"], () => AtasanUnitOrganisasiApi.getJabatan());

  if (action === "READ") {
    return (
      <>
        <InfoItemVertical label="Unit Organisasi" text={values.unitOrganisasiNama} />
        <InfoItemVertical label="Jabatan Struktual" text={values.jabatanNama} />
        <InfoItemVertical label="Keterangan" text={values.keterangan} />
      </>
    );
  }

  return (
    <>
      <Select
        label="Unit Organisasi"
        placeholder="Pilih salah satu"
        loading={getUnitOrganisasi.isFetching}
        options={getUnitOrganisasi?.data ?? []}
        defaultValue={getUnitOrganisasi?.data?.find((item) => item.value === values.unitOrganisasiId)}
        onChange={(val) =>
          setValues({
            ...values,
            unitOrganisasiId: val.value,
            unitOrganisasiNama: val.label
          })
        }
        error={Boolean(errors.unitOrganisasiId && touched.unitOrganisasiId)}
        errorText={Boolean(errors.unitOrganisasiId && touched.unitOrganisasiId) && errors.unitOrganisasiId}
        errorFetch={getUnitOrganisasi.isError}
      />
      <Select
        label="Jabatan Struktual"
        placeholder="Pilih salah satu"
        loading={getJabatan.isFetching}
        options={getJabatan?.data ?? []}
        defaultValue={getJabatan?.data?.find((item) => item.value === values.jabatanId)}
        onChange={(val) =>
          setValues({
            ...values,
            jabatanId: val.value,
            jabatanNama: val.label
          })
        }
        error={Boolean(errors.jabatanId && touched.jabatanId)}
        errorText={Boolean(errors.jabatanId && touched.jabatanId) && errors.jabatanId}
        errorFetch={getJabatan.isError}
      />
      <TextArea
        label="Keterangan"
        name="keterangan"
        placeholder="Masukkan keterangan"
        value={values.keterangan}
        onChange={handleChange}
        error={Boolean(errors.keterangan && touched.keterangan)}
        errorText={Boolean(errors.keterangan && touched.keterangan) && errors.keterangan}
      />
    </>
  );
};
