import React from "react";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { ButtonBack, ContentLayout } from "components";
import { Content } from "./__PekerjaComps__";

export const PekerjaCreate = () => {
  const navigate = useNavigate();

  return (
    <>
      <ContentLayout showBreadcrumb>
        <div className="text-end mb-3">
          <ButtonBack tooltip={false} onClick={() => navigate(-1)} />
        </div>
        <Formik initialValues={{}}>
          <Content action="CREATE" />
        </Formik>
      </ContentLayout>
    </>
  );
};
