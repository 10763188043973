export const formInitialValues = (data = {}, filter) => ({
  dataFormSetup: {
    periodeGajianId: data?.periode_tahun ?? filter?.periodeGajianId,
    periodePerencanaan: data?.periode_tahun ?? new Date().getFullYear(),
    pekerjaId: data?.pekerja_id ?? filter?.pekerjaId,
    pekerjaNama: data?.pekerja_nama ?? filter?.pekerjaNama,
    nik: data?.nip,
    bulan: filter?.bulan,
    tglAwal: data?.tgl_bulan_awal ?? filter?.tglAwal,
    tglAkhir: data?.tgl_bulan_akhir ?? filter?.tglAkhir,
    tglSetup: data?.tgl_setup ?? filter?.tglSetup ?? new Date(),
    unitOrganisasiId: data?.master_unit_organisasi_id ?? filter?.unitOrganisasiId ?? "",
    unitOrganisasiNama: data?.master_unit_organisasi_nama ?? filter?.unitOrganisasiNama ?? "",
    uangMakan: data?.uang_makan,
    nominal: false,
    detail:
      data?.detail?.map((val) => ({
        tglPresensi: val?.tgl_presensi,
        jamKerjaJam: val?.jam_kerja_jam,
        jamKerjaMenit: val?.jam_kerja_menit,
        nominal: parseFloat(val?.uang_makan) ?? 0
      })) ?? []
  },

  detailData: {
    id: data?.pekerja_makan_bulanan_id ?? "",
    komponenPayroll: data?.komponen_payroll ?? "",
    periodeGajianId: data?.periode_tahun ?? "",
    periodePerencanaan: data?.periode_tahun ?? "",
    tglSetup: data?.tgl_setup ?? "",
    tglAwal: data?.tgl_bulan_awal,
    tglAkhir: data?.tgl_bulan_akhir,
    nik: data?.nip ?? "",
    pekerjaId: data?.pekerja_id ?? "",
    pekerjaNama: data?.pekerja_nama ?? "",
    unitOrganisasiId: data?.master_unit_organisasi_id ?? "",
    unitOrganisasiNama: data?.master_unit_organisasi_nama ?? "",
    jabatanId: data?.master_jabatan_id ?? "",
    jabatanNama: data?.master_jabatan_nama ?? "",
    nominal: data?.total_uang_makan ?? ""
  }
});
