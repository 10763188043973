import { dateConvert } from "@bhawanadevteam/react-core";

const date = dateConvert();

export const formSubmitValueMapper = (data) => ({
    periode_tahun: data?.periodeTahun,
    tgl_nota: data?.tglNota,
    nomor_nota: data?.noNota,
    customer_id: data?.customerId,
    customer_nama: data?.customerNama,
    proyek_id: data?.proyekId,
    proyek_nama: data?.proyekNama,
    alamat: data?.proyekAlamat,

    masuk_proyek_jam: data?.jamMasukFormat,
    mulai_proyek_jam: data?.jamMulaiFormat,
    selesai_jam: data?.jamSelesaiFormat,
    keluar_proyek_jam: data?.jamKeluarFormat,
    total_jam_keluar: data?.totalJam,

    masuk_proyek_jam_format: data?.jamMasukFormat,
    mulai_proyek_jam_format: data?.jamMulaiFormat,
    selesai_jam_format: data?.jamSelesaiFormat,
    keluar_proyek_jam_format: data?.jamKeluarFormat,
    total_jam_keluar_format: data?.totalJamFormat,

    sambungan_pipa: data?.sambunganPipa,
    volume: data?.volume,
    km_awal: data?.kmAwal,
    km_akhir: data?.kmAkhir,
    total_km: data?.totalKm,

    crew: data?.detail?.filter((obj) => {
        return obj.checked;
    }).map((val) => ({
        pekerja_id: val?.pekerjaId,
    }))
})
