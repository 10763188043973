import { Services } from "services";
import { fetchDropdownMapper } from "utilities";

const fetchDropdown = fetchDropdownMapper(Services);

class TapenBpdApi {
  async getList(params) {
    const fetch = await Services.get("/master/tappen-bpd", { params });
    return fetch?.data?.data;
  }

  async getPeriodePenggajian() {
    const getPeriodePenggajian = await fetchDropdown("/master/grade-nominal/dropdown", {
      value: "periode_gajian_id",
      label: "periode_perencanaan"
    });

    return getPeriodePenggajian;
  }
  async getStatusPekerja() {
    const getStatusPekerja = await fetchDropdown("/master/status-pekerja/dropdown", {
      value: "master_status_pekerja_id",
      label: "status_pekerja"
    });

    return getStatusPekerja;
  }

  getTahapPemberian() {
    const getTahapPemberian = [
      { value: 1, label: "I" },
      { value: 2, label: "II" }
    ];

    return getTahapPemberian;
  }

  getStatus() {
    const getStatusThr = [
      { value: 1, label: "Aktif" },
      { value: 2, label: "Tidak Aktif" }
    ];

    return getStatusThr;
  }

  async getExport(params) {
    const fetch = await Services.get("/master/tappen-bpd/export", { params });
    return fetch?.data?.data;
  }

  create(data) {
    return Services.post("/master/tappen-bpd", data);
  }

  update(data, id) {
    return Services.put("/master/tappen-bpd/" + id, data);
  }

  delete(id) {
    return Services.delete("/master/tappen-bpd/" + id);
  }
}
export default new TapenBpdApi();
