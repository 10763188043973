import React from "react";
import { useFormikContext } from "formik";
import { Input, InfoItemVertical, TextArea, Select } from "components";
import { useQuery } from "react-query";
import TerStatusPtkpApi from "../__TerStatusPtkpApi__";

export const Content = ({ action }) => {
    const { values, errors, touched, handleChange, setValues } = useFormikContext();

    const getStatusPtkp = useQuery(["status-ptkp", "dropdown"], () => TerStatusPtkpApi.getStatusPtkp());

    if (action === "READ") {
        return (
            <>
                <InfoItemVertical label="Status PTKP" text={values.statusPtkpNama} />
                <InfoItemVertical label="TER" text={values.ter} />
                <InfoItemVertical label="Keterangan" text={values.keterangan} />
            </>
        );
    }

    return (
        <>
            <Select
                label="Status PTKP"
                options={getStatusPtkp?.data ?? []}
                placeholder="Pilih salah satu..."
                defaultValue={
                    values?.statusPtkpId
                        ? getStatusPtkp?.data?.find((item) => item.value === values?.statusPtkpId)
                        : null
                }
                onChange={(val) => {
                    setValues({
                        ...values,
                        statusPtkpId: val.value,
                        statusPtkpNama: val.label
                    });
                }}
                loading={getStatusPtkp?.isFetching}
                errorFetch={getStatusPtkp.isError}
                error={Boolean(errors.statusPtkpId && touched.statusPtkpId)}
                errorText={Boolean(errors.statusPtkpId && touched.statusPtkpId) && errors.statusPtkpId}
            />
            <Input
                label="TER"
                name="ter"
                placeholder="Masukkan tarif efektif rata-rata"
                value={values.ter}
                onChange={handleChange}
                error={Boolean(errors.ter && touched.ter)}
                errorText={Boolean(errors.ter && touched.ter) && errors.ter}
            />
            <TextArea
                label="Keterangan"
                name="keterangan"
                placeholder="Masukkan keketeranganangan"
                value={values.keterangan}
                onChange={handleChange}
                error={Boolean(errors.keterangan && touched.keterangan)}
                errorText={Boolean(errors.keterangan && touched.keterangan) && errors.keterangan}
            />

        </>
    );
};
