import {
  ButtonCreate, DatePicker, InfoItemVertical, Input, InputCurrency, numberConvert,
  Select, TableList
} from "components";
import { useFormikContext } from "formik";
import { Card, Col, Row } from "react-bootstrap";
import { useQuery } from "react-query";
import SetupUpahPokokTahunanApi from "../__SetupUpahPokokTahunanApi__";

const SectionDataSetup = ({ onSearchButtonClick, onResetClickButton }) => {
  const { values, setValues, errors, touched } = useFormikContext();
  // const date = dateConvert();

  const getUnitOrganisasi = useQuery(["unit-organisasi", "dropdown"], () =>
    SetupUpahPokokTahunanApi.getUnitOrganisasi()
  );

  return (
    <>
      <Input disabled label="Periode Penggajian" value={values?.periodePerencanaan} />
      <Select
        label="Unit Organisasi"
        options={getUnitOrganisasi?.data ?? []}
        placeholder="Pilih salah satu..."
        defaultValue={
          values?.unitOrganisasiId
            ? getUnitOrganisasi?.data?.find((item) => item.value === values?.unitOrganisasiId)
            : null
        }
        onChange={(val) => {
          setValues({
            ...values,
            unitOrganisasiId: val.value,
            unitOrganisasiNama: val.label
          });
        }}
        loading={getUnitOrganisasi?.isFetching}
        errorFetch={getUnitOrganisasi.isError}
        error={Boolean(errors.unitOrganisasiId && touched.unitOrganisasiId)}
        errorText={Boolean(errors.unitOrganisasiId && touched.unitOrganisasiId) && errors.unitOrganisasiId}
      />

      <DatePicker
        disabled
        label="Tanggal Setup"
        placeholderText="dd-mm-yyyy"
        selected={values?.tglSetup}
        onChange={(val) =>
          setValues({
            ...values,
            tglSetup: val
          })
        }
        error={Boolean(errors.tglSetup && touched.tglSetup)}
        errorText={Boolean(errors.tglSetup && touched.tglSetup) && errors.tglSetup}
      />

      <div className="d-flex justify-content-end">
        <div>
          {/* <ButtonCancel text="RESET" className="mb-3 me-2" tooltip={false} onClick={() => onResetClickButton()} /> */}
          <ButtonCreate
            disabled={Boolean(!values?.periodeGajianId || !values?.unitOrganisasiId)}
            text="CARI"
            className="mb-3"
            tooltip={false}
            onClick={() => onSearchButtonClick(values)}
          />
        </div>
      </div>
    </>
  );
};

const TabelDataPekerja = () => {
  const { values, setValues, errors, touched } = useFormikContext();
  const number = numberConvert();

  const setNewValues = ({ index, propname, val }) => {
    const detailTemp = [...values?.detail];
    const updatedDetail = detailTemp[index];
    updatedDetail[propname] = val;

    setValues({ ...values, detail: detailTemp });
  };

  const getError = (index, propname) => {
    return Boolean(errors?.detail?.[index]?.[propname] && touched?.detail?.[index]?.[propname]);
  };

  const getErrorText = (index, propname) => {
    return (
      Boolean(errors?.detail?.[index]?.[propname] && touched?.detail?.[index]?.[propname]) &&
      errors?.detail?.[index]?.[propname]
    );
  };

  return (
    <>
      <TableList
        maxHeight={550}
        tableHeader={[
          { text: "NIK" },
          { text: "Pekerja" },
          { text: "Unit Organisasi" },
          { text: "Jabatan" },

          { text: "Nominal" }
        ]}
        tableBody={[
          {
            field: "nik"
          },
          {
            field: "pekerjaNama"
          },
          {
            field: "unitOrganisasiNama"
          },
          {
            field: "jabatanNama"
          },

          {
            customField: (val, index) => (
              <InputCurrency
                name="nominal"
                onChange={(x) => {
                  setNewValues({ index: index, propname: "status", val: 1 });
                  setNewValues({ index: index, propname: "nominal", val: x });
                }}
                value={"Rp" + number.getWithComa(val?.nominal, { minimumFractionDigits: 2 })}
                error={getError(index, "nominal")}
                errorText={getErrorText(index, "nominal")}
              />
            )
          }
        ]}
        data={values?.detail}
      />
    </>
  );
};

export const Content = ({ action, onSearchButtonClick, onResetClickButton }) => {
  const { values, setValues, errors, touched } = useFormikContext();
  const number = numberConvert();

  const getStatus = useQuery(["status", "dropdown"], () => SetupUpahPokokTahunanApi.getStatus());

  if (action === "READ") {
    return (
      <>
        <InfoItemVertical label="Pekerja" text={values.pekerjaNama} />
        <InfoItemVertical label="Unit Organisasi" text={values.unitOrganisasiNama} />
        <InfoItemVertical label="Jabatan" text={values.jabatanNama} />

        <InfoItemVertical
          label="Nominal"
          text={values.nominal ? "Rp" + number.getWithComa(values?.nominal, { minimumFractionDigits: 2 }) : "-"}
        />
        <InfoItemVertical label="Status" text={values?.status === 1 ? "Aktif" : "Tidak Aktif"} />
      </>
    );
  }

  if (action === "UPDATE") {
    return (
      <>
        <Input disabled label="Pekerja" value={values?.pekerjaNama} />
        <Input disabled label="Unit Organisasi" value={values?.unitOrganisasiNama} />
        <Input disabled label="Jabatan" value={values?.jabatanNama} />

        <InputCurrency
          name="nominal"
          label="Nominal"
          value={values.nominal ? "Rp" + number.getWithComa(values?.nominal, { minimumFractionDigits: 2 }) : "-"}
          onChange={(val) =>
            setValues({
              ...values,
              nominal: val
            })
          }
          error={Boolean(errors.nominal && touched.nominal)}
          errorText={Boolean(errors.nominal && touched.nominal) && errors.nominal}
        />
        <Select
          label="Status"
          placeholder="Pilih status"
          defaultValue={getStatus?.data?.find((item) => item.value === values.status)}
          options={getStatus?.data ?? []}
          onChange={(val) => setValues({ ...values, status: val.value })}
          loading={getStatus.isFetching}
          errorFetch={getStatus.isError}
        />
      </>
    );
  }

  return (
    <>
      <Row>
        <Col md={4} className="mb-4">
          <Card>
            <Card.Header>
              <b>Setup Data</b>
            </Card.Header>
            <Card.Body>
              <SectionDataSetup onSearchButtonClick={onSearchButtonClick} onResetClickButton={onResetClickButton} />
            </Card.Body>
          </Card>
        </Col>

        <Col md={8}>
          <Card>
            <Card.Header>
              <b>Data Pekerja</b>
            </Card.Header>
            <Card.Body>
              <TabelDataPekerja />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
