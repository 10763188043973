import {
  ButtonDetail,
  ButtonUpdate, InputSearch, ModalDetail, Select, toastTrigger
} from "components";
import { Formik } from "formik";
import { useAccessRights, useLayout } from "hooks";
import { ListLayout } from "layouts";
import { debounce } from "lodash";
import { useState } from "react";
import { ButtonGroup } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { dateConvert, errorFetchingTableMapper, exportHandler } from "utilities";
import RealisasiPekerjaPensiunApi from "./__RealisasiPekerjaPensiunApi__";
import { Content } from "./__RealisasiPekerjaPensiunComps__";
import { formInitialValues } from "./__RealisasiPekerjaPensiunUtils__";

export const RealisasiPekerjaPensiunList = () => {
  const layout = useLayout();
  const access = useAccessRights("REALISASI_PEKERJA_PENSIUN");
  const navigate = useNavigate();
  // const modalConfirm = useModalConfirm();

  // const number = numberConvert();
  const date = dateConvert();

  const [searchKey, setSearchKey] = useState(undefined);
  const [pagination, setPagination] = useState({
    pageCurrent: 1,
    dataLength: 10
  });
  const [modal, setModal] = useState({
    show: false,
    action: "",
    data: {}
  });

  const [filter, setFilter] = useState({
    periode_gajian_id: undefined,
    master_unit_organisasi_id: undefined
  });

  const getPeriodePerencanaan = useQuery(["periode-gajian", "dropdown"], RealisasiPekerjaPensiunApi.getPeriodeGajian);
  const getUnitOrganisasi = useQuery(["unit-organisasi", "dropdown"], RealisasiPekerjaPensiunApi.getUnitOrganisasi);

  const getList = useQuery(
    ["realisasi-pekerja-pensiun", "list", searchKey, filter, pagination.pageCurrent, pagination.dataLength],
    () =>
      RealisasiPekerjaPensiunApi.getList({
        periode_tahun: filter.periode_gajian_id,
        master_unit_organisasi_id: filter.master_unit_organisasi_id,
        search: searchKey,
        page: pagination.pageCurrent,
        limit: pagination.dataLength
      }), { enabled: Boolean(filter?.periode_gajian_id) }
  );
  const getDetailRealisasiPekerjaPensiun = useQuery(["detail-realisasi-pekerja-pensiun", "detail", modal], () =>
    RealisasiPekerjaPensiunApi.getSingle(modal?.data?.pekerja_phk_id), { enabled: Boolean(modal?.data?.pekerja_phk_id) }
  );

  // const deleteRealisasiPekerjaPensiun = useMutation((id) => RealisasiPekerjaPensiunApi.delete(id), {
  //   onSuccess: () => {
  //     modalConfirm.infoSuccess({ typeInfo: "delete" });
  //     getList.refetch();
  //   },
  //   onError: (err) => modalConfirm.infoError({ typeInfo: "delete", ...errorSubmitMapper(err) })
  // });

  const exportRealisasiPekerjaPensiun = useMutation(
    (type) => RealisasiPekerjaPensiunApi.getExport({ to: type, periode_tahun: filter.periode_gajian_id }),
    {
      onSuccess: (link) => {
        toastTrigger({ variant: "success", message: "Export berhasil" });
        exportHandler(link, "Data Setup Insentif Produktivitas");
      },
      onError: () => {
        toastTrigger({ variant: "danger", message: "Export gagal" });
      }
    }
  );

  const searchHandler = (e) => {
    setSearchKey(e?.target?.value ? e.target.value : undefined);
  };
  const onHideModalHandler = () => setModal({ show: false, action: "", data: {} });

  const onCreateButtonClickHandler = () => navigate("list-pekerja-pensiun");

  const onDetailButtonClickHandler = (data) => setModal({ show: true, action: "READ", data: data });

  const onUpdateButtonClickHandler = (data) => navigate("ubah/" + data?.pekerja_phk_id);

  const onPaginationChange = (data) => setPagination({ ...pagination, pageCurrent: data });

  const onPaginationDataLengthChange = (data) => setPagination({ ...pagination, dataLength: data });

  // const onDeleteButtonClickHandler = (values, action) => {
  //   setModal({ data: values });
  //   modalConfirm.trigger({
  //     type: "delete",
  //     size: "lg",
  //     alert: "Data akan dihapus secara permanen dan tidak dapat dikembalikan",
  //     onHide: modalConfirm.close,
  //     onSubmit: () => {
  //       modalConfirm.loading();
  //       if (action === "READ") {
  //         deleteRealisasiPekerjaPensiun.mutate(values?.pekerja_phk_id);
  //       }
  //     },
  //     component: (
  //       <Formik initialValues={formInitialValues(getDetailRealisasiPekerjaPensiun?.data)}>
  //         <Content action={action} loading={getDetailRealisasiPekerjaPensiun?.isFetching} />
  //       </Formik>
  //     )
  //   });
  // };

  return (
    <>
      <ListLayout
        customLeftTopSection={() => (
          <>
            <div className="d-flex align-items-end mb-3 gap-2 flex-md-row flex-column">
              <div style={{ width: "100%" }}>
                <InputSearch onChange={debounce(searchHandler, 1500)} />
              </div>
              <div style={{ width: "100%" }}>
                <Select
                  noMargin
                  label="Periode PHK"
                  placeholder="Pilih salah satu.."
                  options={getPeriodePerencanaan?.data}
                  onChange={(val) => {
                    setFilter({
                      ...filter,
                      periode_gajian_id: val.value
                    });
                  }}
                />
              </div>
              <div style={{ width: "100%" }}>
                <Select
                  noMargin
                  label="Unit Organisasi"
                  placeholder="Pilih salah satu.."
                  options={getUnitOrganisasi?.data}
                  onChange={(val) => {
                    setFilter({
                      ...filter,
                      master_unit_organisasi_id: val.value
                    });
                  }}
                />
              </div>
            </div>
            <div className="d-flex gap-3"></div>
          </>
        )}
        showRightTopButton={access.canCreate() ? true : false}
        showExportExcelButton
        // showExportPdfButton
        onExportExcelButtonClick={() => exportRealisasiPekerjaPensiun.mutate("excel")}
        // onExportPdfButtonClick={() => exportRealisasiPekerjaPensiun.mutate("pdf")}
        loadingExportButton={exportRealisasiPekerjaPensiun.isLoading}
        loading={getList.isFetching}
        error={getList.isError}
        errorText={errorFetchingTableMapper(getList.error)}
        dataTotal={getList?.data?.total}
        dataLength={pagination.dataLength}
        pageCurrent={pagination.pageCurrent}
        pageTotal={getList?.data?.last_page}
        onSearchChange={debounce(searchHandler, 1500)}
        onCreateButtonClick={onCreateButtonClickHandler}
        onDataLengthChange={onPaginationDataLengthChange}
        onPaginationChange={onPaginationChange}
        data={getList?.data?.data}
        tableHeader={[
          {
            text: "NIK"
          },
          {
            text: "Pekerja"
          },
          {
            text: "Jabatan"
          },
          {
            text: "Unit Organisasi"
          },
          {
            text: "Tgl Pemberhentian"
          },
          {
            text: "Masa Kerja"
          },
          {
            text: "Alasan PHK"
          },
          {
            text: "Aksi",
            props: { width: 50, fixed: true }
          }
        ]}
        tableBody={[
          {
            field: "nip"
          },
          {
            field: "pekerja_nama"
          },
          {
            field: "master_jabatan_nama"
          },
          {
            field: "master_unit_organisasi_nama"
          },
          { customField: (val) => (val?.tgl_pemberhentian ? date.getSlashDMY(new Date(val?.tgl_pemberhentian)) : "-") },

          {
            props: { textCenter: true, minWidth: 200 },
            customField: (val) => {
              const jumlahHari = val?.masa_kerja;

              const tahun = Math.floor(jumlahHari / 365.25);
              const sisaTahun = jumlahHari % 365.25;
              const bulan = Math.floor(sisaTahun / 30.44);
              const sisaBulan = sisaTahun % 30.44;
              const hari = Math.round(sisaBulan);

              return tahun + " Tahun " + bulan + " Bulan " + hari + " Hari";
            }
          },

          {
            field: "alasan_phk"
          },
          {
            props: { fixed: true },
            customField: (data) => (
              <ButtonGroup size="sm">
                {access.canRead() && <ButtonDetail icon noText onClick={() => onDetailButtonClickHandler(data)} />}
                {access.canUpdate() && <ButtonUpdate icon noText onClick={() => onUpdateButtonClickHandler(data)} />}
              </ButtonGroup>
            )
          }
        ]}
      />
      {/**
       * Modal Detail:
       * Digunakan untuk melihat detail data
       */}
      <Formik enableReinitialize initialValues={formInitialValues(getDetailRealisasiPekerjaPensiun?.data)}>
        <ModalDetail
          size="lg"
          title={layout.getActivePageName()}
          show={Boolean(modal.show && modal.action === "READ")}
          onHide={onHideModalHandler}
        >
          <Content action="READ" loading={getDetailRealisasiPekerjaPensiun?.isFetching} />
        </ModalDetail>
      </Formik>
    </>
  );
};
