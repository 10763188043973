import {
    ButtonDetail, ButtonFilter, ButtonUpdate, dateConvert,
    DatePicker, InputSearch, ModalFilter, Select, toastTrigger
} from "components";
import { Formik, useFormikContext } from "formik";
import { useAccessRights } from "hooks";
import { ListLayout } from "layouts";
import { debounce } from "lodash";
import { useState } from "react";
import { ButtonGroup } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { exportHandler } from "utilities";
import InputTimeSheetConcretePumpApi from "./__InputTimeSheetConcretePumpApi__";

const ModalFilterBody = () => {
    const { values, setValues } = useFormikContext();

    const getPeriode = useQuery(["periode-penggajian", "dropdown"], () => InputTimeSheetConcretePumpApi.getPeriode());

    return (
        <>
            <Select
                label="Periode Penggajian"
                placeholder="Pilih periode penggajian"
                defaultValue={getPeriode?.data?.find((item) => item.value === values?.periodeGajianId)}
                options={getPeriode?.data ?? []}
                onChange={(val) => setValues({ ...values, periodeGajianId: val.value, periodePerencanaan: val.label })}
                loading={getPeriode.isFetching}
                errorFetch={getPeriode.isError}
            />
            <div>
                <div className="d-flex align-items-center justify-content-between">
                    <div style={{ width: "44%" }}>
                        <DatePicker
                            label="Tanggal Awal"
                            placeholderText="Masukkan tgl. awal"
                            selected={values?.tglAwal}
                            onChange={(date) => setValues({
                                ...values,
                                tglAwal: new Date(date)
                            })}
                            selectsStart
                            startDate={values?.tglAwal}
                            endDate={values?.tglAkhir}
                        />
                    </div>
                    <small className="mt-3">s/d</small>
                    <div style={{ width: "44%" }}>
                        <DatePicker
                            label="Tanggal Akhir"
                            placeholderText="Masukkan tgl. akhir"
                            selected={values?.tglAkhir}
                            onChange={(date) => setValues({
                                ...values,
                                tglAkhir: new Date(date)
                            })}
                            selectsEnd
                            startDate={values?.tglAwal}
                            endDate={values?.tglAkhir}
                            minDate={values?.tglAkhir}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export const InputTimeSheetConcretePumpList = () => {
    const access = useAccessRights("TIME_SHEET_CONCRETE");
    const navigate = useNavigate();
    // const number = numberConvert();
    // const modalConfirm = useModalConfirm();
    const date = dateConvert();
    // const layout = useLayout();

    const [searchKey, setSearchKey] = useState(undefined);
    const [pagination, setPagination] = useState({
        pageCurrent: 1,
        dataLength: 10
    });

    const [filter, setFilter] = useState({
        show: false,
        active: false,
        data: {
            periodeGajianId: undefined,
            tglAwal: undefined,
            tglAkhir: undefined
        }
    });

    const getInputTimeSheetConcretePumpList = useQuery(
        [
            "time-sheet-concrete-pump",
            "list",
            searchKey,
            pagination.pageCurrent,
            pagination.dataLength,
            filter.data?.periodeGajianId,
            filter.data?.tglAwal,
            filter.data?.tglAkhir
        ],
        () =>
            InputTimeSheetConcretePumpApi.getList({
                search: searchKey,
                page: pagination.pageCurrent,
                limit: pagination.dataLength,
                periode_tahun: filter.data?.periodeGajianId,
                tgl_awal: date.getDashYMD(new Date(filter.data?.tglAwal)),
                tgl_akhir: date.getDashYMD(new Date(filter.data?.tglAkhir))
            }),
        {
            enabled: Boolean(
                filter?.data?.periodeGajianId &&
                filter?.data?.tglAwal &&
                filter?.data?.tglAkhir
            )
        }
    );

    // const deleteInputTimeSheetConcretePump = useMutation((id) => InputTimeSheetConcretePumpApi.delete(id), {
    //     onSuccess: () => {
    //         modalConfirm.infoSuccess({ typeInfo: "delete" });
    //         getInputTimeSheetConcretePumpList.refetch();
    //     },
    //     onError: (err) => modalConfirm.infoError({ typeInfo: "delete", ...errorSubmitMapper(err) })
    // });

    const getInputTimeSheetConcretePumpExport = useMutation(
        (data) =>
            InputTimeSheetConcretePumpApi.getExport({
                periode_tahun: data?.periodeGajianId,
                tgl_awal: data?.tglAwal,
                tgl_akhir: data?.tglAkhir
            }),
        {
            onSuccess: (link) => {
                toastTrigger({ variant: "success", message: "Export berhasil" });
                exportHandler(link, "Data Time Sheet Concrete Pump");
            },
            onError: () => {
                toastTrigger({ variant: "danger", message: "Export gagal" });
            }
        }
    );

    const onCreateButtonClickHandler = () => navigate("tambah");
    const onUpdateButtonClickHandler = (data) => navigate("ubah/" + data.time_sheet_cp_id);
    const onDetailButtonClickHandler = (data) => navigate("detail/" + data.time_sheet_cp_id);

    const onPaginationChange = (data) => setPagination({ ...pagination, pageCurrent: data });

    const onPaginationDataLengthChange = (data) => setPagination({ ...pagination, dataLength: data });

    const searchHandler = (e) => {
        setSearchKey(e?.target?.value ? e.target.value : undefined);
    };

    const TABLE_HEADER = [
        {
            text: "Tanggal"
        },
        {
            text: "No. Nota"
        },
        {
            text: "Pemakai"
        },
        {
            text: "Proyek"
        },
        {
            text: "Alamat"
        },
        {
            text: "Aksi",
            props: {
                fixed: true
            }
        }
    ];

    const TABLE_BODY = [
        { customField: (val) => date.getSlashDMY(new Date(val?.tgl_nota)) },
        { field: "nomor_nota" },
        { field: "customer_nama" },
        { field: "proyek_nama" },
        { field: "alamat" },
        {
            props: {
                width: 50,
                fixed: true,
                textCenter: true
            },
            customField: (data) => (
                <ButtonGroup size="sm">
                    {access.canRead() && (
                        <ButtonDetail icon tooltip={false} noText onClick={() => onDetailButtonClickHandler(data)} />
                    )}
                    {access.canUpdate() && (
                        <ButtonUpdate tooltip={false} icon noText onClick={() => onUpdateButtonClickHandler(data)} />
                    )}
                    {/* {access.canDelete() && (
                        <ButtonDelete
                            tooltip={false}
                            icon
                            noText
                            onClick={() => formSubmitHandler(formInitialValues(data).detailData, "DELETE")}
                        />
                    )} */}
                </ButtonGroup>
            )
        }
    ];

    return (
        <>
            <ListLayout
                customLeftTopSection={() => (
                    <>
                        <div className="d-flex align-items-end mb-3">
                            <div style={{ width: 300 }} className="me-2">
                                <InputSearch onChange={debounce(searchHandler, 1500)} />
                            </div>
                            <div>
                                <ButtonFilter
                                    active={filter?.active}
                                    className="text-nowrap"
                                    onClick={() => setFilter({ ...filter, show: true })}
                                />
                            </div>
                        </div>
                    </>
                )}
                showRightTopButton={access.canCreate() ? true : false}
                showExportExcelButton={Boolean(filter?.data?.periodeGajianId && filter?.data?.tglAwal && filter?.data?.tglAkhir)}
                onExportExcelButtonClick={() => getInputTimeSheetConcretePumpExport.mutate(filter?.data)}
                loadingExportButton={getInputTimeSheetConcretePumpExport.isLoading}
                loading={getInputTimeSheetConcretePumpList?.isFetching}
                error={getInputTimeSheetConcretePumpList?.isError}
                dataTotal={getInputTimeSheetConcretePumpList?.data?.total}
                dataLength={pagination.dataLength}
                pageCurrent={pagination.pageCurrent}
                pageTotal={getInputTimeSheetConcretePumpList?.data?.last_page}
                onSearchChange={debounce(searchHandler, 1500)}
                onCreateButtonClick={onCreateButtonClickHandler}
                onDataLengthChange={onPaginationDataLengthChange}
                onPaginationChange={onPaginationChange}
                data={getInputTimeSheetConcretePumpList?.data?.data}
                tableHeader={TABLE_HEADER}
                tableBody={TABLE_BODY}
            />

            {/* ModalFilter */}
            <Formik
                initialValues={{
                    periodeGajianId: filter?.data?.periodeGajianId,
                    tglAwal: filter?.data?.tglAwal,
                    tglAkhir: filter?.data?.tglAkhir
                }}
            >
                {({ resetForm, values }) => (
                    <ModalFilter
                        show={filter.show}
                        onReset={resetForm}
                        onHide={() =>
                            setFilter({
                                ...filter,
                                show: false
                            })
                        }
                        onSubmit={() => {
                            setFilter({
                                show: false,
                                active: Boolean(Object.values(values).find((val) => val)),
                                data: values
                            });
                        }}
                    >
                        <ModalFilterBody />
                    </ModalFilter>
                )}
            </Formik>
        </>
    );
};
