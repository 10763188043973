import { PekerjaDetail, PekerjaUpdate } from "pages/Pekerja";
import { IoPersonOutline } from "react-icons/io5";

export const AccountPage = {
    link: "akun",
    name: "Akun",
    alwaysShow: true,
    icon: <IoPersonOutline />,
    component: <PekerjaDetail />,
    child: [
        {
            name: "Ubah",
            link: "/ubah",
            component: <PekerjaUpdate />
        }
    ]
};
