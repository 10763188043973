import React from "react";
import { useFormikContext } from "formik";
import { Input, InfoItemVertical, numberConvert, InputNumber } from "components";

export const Content = ({ action }) => {
  const { values, errors, touched, handleChange, setValues } = useFormikContext();
  const number = numberConvert();

  if (action === "READ") {
    return (
      <>
        <InfoItemVertical label="Periode Penggajian" text={values.periodePenggajianNama} />
        <InfoItemVertical label="Kode" text={values.kode} />
        <InfoItemVertical label="Alasan PHK" text={values?.alasanPHK} />
        <InfoItemVertical
          label="Pesangon"
          text={values.pesangon ? number.getWithComa(values.pesangon, { minimumFractionDigits: 2 }) + "%" : "0%"}
        />
        <InfoItemVertical
          label="Upah Penghargaan Masa Kerja"
          text={
            values.upahPenghargaanMasaKerja
              ? number.getWithComa(values.upahPenghargaanMasaKerja, { minimumFractionDigits: 2 }) + "%"
              : "0%"
          }
        />
        <InfoItemVertical
          label="Upah Pengganti HAK"
          text={
            values.upahPenggantiHAK ? number.getWithComa(values.upahPenggantiHAK, { minimumFractionDigits: 2 }) + "%" : "0%"
          }
        />
        <InfoItemVertical
          label="Upah Pisah"
          text={values.upahPisah ? number.getWithComa(values.upahPisah, { minimumFractionDigits: 2 }) + "%" : "0%"}
        />
      </>
    );
  }

  return (
    <>
      <Input
        label="Periode Penggajian"
        name="periodePenggajianId"
        placeholder="Masukkan periode penggajian"
        value={values.periodePenggajianId ? String(values?.periodePenggajianId) : "-"}
        onChange={(val) =>
          setValues({
            ...values,
            periodePenggajianId: val.target.value,
            periodePenggajianNama: val.target.value
          })
        }
        error={Boolean(errors.periodePenggajianId && touched.periodePenggajianId)}
        errorText={Boolean(errors.periodePenggajianId && touched.periodePenggajianId) && errors.periodePenggajianId}
      />
      <Input
        label="Kode"
        name="kode"
        placeholder="Masukkan kode jenis ijin"
        value={values.kode}
        onChange={handleChange}
        error={Boolean(errors.kode && touched.kode)}
        errorText={Boolean(errors.kode && touched.kode) && errors.kode}
      />
      <Input
        label="Alasan PHK"
        name="alasanPHK"
        placeholder="Masukkan alasan phk"
        value={values.alasanPHK}
        onChange={handleChange}
        error={Boolean(errors.alasanPHK && touched.alasanPHK)}
        errorText={Boolean(errors.alasanPHK && touched.alasanPHK) && errors.alasanPHK}
      />
      <InputNumber
        name="pesangon"
        label="Pesangon"
        placeholder="Masukan pesangon"
        suffix="%"
        value={number.getWithComa(values.pesangon, { minimumFractionDigits: 2 })}
        onChange={(val) => setValues({ ...values, pesangon: val })}
        error={Boolean(errors.pesangon && touched.pesangon)}
        errorText={Boolean(errors.pesangon && touched.pesangon) && errors.pesangon}
      />
      <InputNumber
        name="upahPenghargaanMasaKerja"
        label="Upah Penghargaan Masa Kerja"
        placeholder="Masukan upah penghargaan masa kerja"
        suffix="%"
        value={number.getWithComa(values.upahPenghargaanMasaKerja, { minimumFractionDigits: 2 })}
        onChange={(val) => setValues({ ...values, upahPenghargaanMasaKerja: val })}
        upahPenghargaanMasaKerja
        error={Boolean(errors.upahPenghargaanMasaKerja && touched.upahPenghargaanMasaKerja)}
        errorText={
          Boolean(errors.upahPenghargaanMasaKerja && touched.upahPenghargaanMasaKerja) &&
          errors.upahPenghargaanMasaKerja
        }
      />
      <InputNumber
        name="upahPenggantiHAK"
        label="Upah Pengganti HAK"
        placeholder="Masukan upah pengganti hak"
        suffix="%"
        value={number.getWithComa(values.upahPenggantiHAK, { minimumFractionDigits: 2 })}
        onChange={(val) => setValues({ ...values, upahPenggantiHAK: val })}
        error={Boolean(errors.upahPenggantiHAK && touched.upahPenggantiHAK)}
        errorText={Boolean(errors.upahPenggantiHAK && touched.upahPenggantiHAK) && errors.upahPenggantiHAK}
      />
      <InputNumber
        name="upahPisah"
        label="Upah Pisah"
        placeholder="Masukan upah pisah"
        suffix="%"
        value={number.getWithComa(values.upahPisah, { minimumFractionDigits: 2 })}
        onChange={(val) => setValues({ ...values, upahPisah: val })}
        error={Boolean(errors.upahPisah && touched.upahPisah)}
        errorText={Boolean(errors.upahPisah && touched.upahPisah) && errors.upahPisah}
      />
    </>
  );
};
