import { InfoItemVertical } from "components";
import { useFormikContext } from "formik";
import { Card, Col, Row } from "react-bootstrap";
import { dateConvert } from "utilities";
import { SectionPhk } from "../__RealisasiPekerjaPensiunComps__";

export const Content = ({ action, getListForm, dataFilter, setDataFilter }) => {
  const { values } = useFormikContext();
  // const navigate = useNavigate();
  const date = dateConvert();

  return (
    <>
      <Row>
        <Col md={4}>
          <Card>
            <Card.Header>
              <b>Data Pekerja</b>
            </Card.Header>
            <Card.Body>
              <InfoItemVertical label="Periode Penggajian" text={values?.periodePenggajian} />
              <InfoItemVertical
                label="Tanggal Setup"
                text={values?.tglSetup ? date.getSlashDMY(new Date(values?.tglSetup)) : "-"}
              />
              <InfoItemVertical label="Unit Organisasi" text={values?.unitOrganisasiNama} />
              <InfoItemVertical label="NIK" text={values?.nip} />
              <InfoItemVertical label="Pekerja" text={values?.pekerjaNama} />
              <InfoItemVertical label="Jabatan" text={values?.jabatanNama} />
              <InfoItemVertical
                label="Tanggal Pengangkatan"
                text={values?.tglPengangkatan ? date.getSlashDMY(new Date(values?.tglPengangkatan)) : "-"}
              />
            </Card.Body>
          </Card>
        </Col>
        <Col md={8}>
          <SectionPhk action={action} />
        </Col>
      </Row>
    </>
  );
};
