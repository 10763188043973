export const formInitialValues = (data = {}) => ({
    id: data?.customer_id,
    nama: data?.customer_nama,
    contactPerson: data?.contact_person,
    hp: data?.hp,
    provinsiKode: data?.provinsi_kode,
    kabupatenKode: data?.kabupaten_kode,
    kecamatanKode: data?.kecamatan_kode,
    desaKode: data?.desa_kode,
    provinsiNama: data?.provinsi_nama,
    kabupatenNama: data?.kabupaten_nama,
    kecamatanNama: data?.kecamatan_nama,
    desaNama: data?.desa_nama,
    alamat: data?.alamat
});
