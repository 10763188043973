import { ContentLayout, ButtonBack, DataStatus } from "components";
import { Content } from "./_UraianJabatanComps__";
import { useQuery } from "react-query";
import { Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { formInitialValues } from "./__UraianJabatanUtils__";
import UraianJabatanApi from "./__UraianJabatanApi__";

export const UraianJabatanUpdate = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const getDetailUraianJabatan = useQuery(["uraian-jabatan", "detail"], () => UraianJabatanApi.getDetail(id), {
    enabled: !!id
  });

  return (
    <>
      <ContentLayout>
        {getDetailUraianJabatan?.isFetching ? (
          <DataStatus loading />
        ) : (
          <>
            <div className="d-flex justify-content-end mt-2 mb-3">
              <ButtonBack tooltip={false} onClick={() => navigate("/organisasi/master/uraian-jabatan")} />
            </div>
            <Formik enableReinitialize initialValues={formInitialValues(getDetailUraianJabatan?.data ?? {})}>
              <Content
                action="UPDATE"
                getDetailIsFetching={getDetailUraianJabatan?.isFetching}
                getDetailIsError={getDetailUraianJabatan?.isError}
              />
            </Formik>
          </>
        )}
      </ContentLayout>
    </>
  );
};
