import { useState } from "react";
import { ButtonGroup } from "react-bootstrap";
import { useQuery, useMutation } from "react-query";
import { Form, Formik } from "formik";
import { debounce } from "lodash";
import { ListLayout } from "layouts";
import {
    ButtonDetail,
    ButtonUpdate,
    ButtonDelete,
    ModalDetail,
    ModalCreate,
    ModalUpdate,
    toastTrigger
} from "components";
import { useLayout, useModalConfirm, useAccessRights } from "hooks";
import { errorFetchingTableMapper, errorSubmitMapper, exportHandler } from "utilities";
import {
    formInitialValues,
    formValidationSchema,
    formSubmitValueMapper
} from "./__JenisOverheadUtilities__";
import { Content } from "./__JenisOverheadComps__";
import JenisOverheadApi from "./__JenisOverheadApi__";


export const JenisOverheadList = () => {
    const layout = useLayout();
    const access = useAccessRights("JENIS_OVERHEAD");
    const modalConfirm = useModalConfirm();
    const [searchKey, setSearchKey] = useState(undefined);
    const [pagination, setPagination] = useState({
        pageCurrent: 1,
        dataLength: 10
    });
    const [modal, setModal] = useState({
        show: false,
        action: "",
        data: {}
    });

    const getJenisOverhead = useQuery(
        ["jenis-overhead", "list", searchKey, pagination.pageCurrent, pagination.dataLength],
        () =>
            JenisOverheadApi.getList({
                search: searchKey,
                page: pagination.pageCurrent,
                limit: pagination.dataLength
            })
    );

    const createJenisOverhead = useMutation((data) => JenisOverheadApi.create(data), {
        onSuccess: () => {
            modalConfirm.infoSuccess({ typeInfo: "create" });
            getJenisOverhead.refetch();
        },
        onError: (err) => modalConfirm.infoError({ typeInfo: "create", ...errorSubmitMapper(err) })
    });

    const updateJenisOverhead = useMutation(({ data, id }) => JenisOverheadApi.update(data, id), {
        onSuccess: () => {
            modalConfirm.infoSuccess({ typeInfo: "update" });
            getJenisOverhead.refetch();
        },
        onError: (err) => modalConfirm.infoError({ typeInfo: "update", ...errorSubmitMapper(err) })
    });

    const deleteJenisOverhead = useMutation((id) => JenisOverheadApi.delete(id), {
        onSuccess: () => {
            modalConfirm.infoSuccess({ typeInfo: "delete" });
            getJenisOverhead.refetch();
        },
        onError: (err) => modalConfirm.infoError({ typeInfo: "delete", ...errorSubmitMapper(err) })
    });

    const getExportJenisOverhead = useMutation((type) => JenisOverheadApi.getExport({ to: type }), {
        onSuccess: (link) => {
            toastTrigger({ variant: "success", message: "Export berhasil" });
            exportHandler(link, "Data Jenis Overhead");
        },
        onError: () => {
            toastTrigger({ variant: "danger", message: "Export gagal" });
        }
    });

    const searchHandler = (e) => {
        setSearchKey(e?.target?.value ? e.target.value : undefined);
    };

    const onHideModalHandler = () => setModal({ show: false, action: "", data: {} });

    const onCreateButtonClickHandler = () => setModal({ show: true, action: "CREATE", data: {} });

    const onDetailButtonClickHandler = (data) => setModal({ show: true, action: "READ", data: data });

    const onUpdateButtonClickHandler = (data) => setModal({ show: true, action: "UPDATE", data: data });

    const onPaginationChange = (data) => setPagination({ ...pagination, pageCurrent: data });

    const onPaginationDataLengthChange = (data) => setPagination({ ...pagination, dataLength: data });

    const submitValidationHandler = (errors) =>
        new Promise((resolve, reject) => {
            const getError = Object.values(errors);

            if (getError.length > 0) {
                reject(getError);
            } else {
                resolve();
            }
        });

    const preSubmitHandler = (formik) => {
        const { values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting } = formik;

        setSubmitting(true);
        validateForm().then(async (err) => {
            setErrors(err);
            setTouched(err);

            await submitValidationHandler(err, values)
                .then(() => handleSubmit())
                .catch((err) =>
                    modalConfirm.trigger({
                        type: "error",
                        title: "Data Tidak Lengkap",
                        data: err,
                        onHide: () => modalConfirm.close()
                    })
                )
                .finally(() => {
                    setSubmitting(false);
                });
        });
    };

    const formSubmitHandler = (values, action) =>
        modalConfirm.trigger({
            type: action.toLowerCase(),
            onHide: modalConfirm.close,
            onSubmit: () => {
                modalConfirm.loading();

                if (action === "UPDATE") {
                    updateJenisOverhead
                        .mutateAsync({ data: formSubmitValueMapper(values), id: values.id })
                        .then(() => onHideModalHandler());
                } else if (action === "DELETE") {
                    deleteJenisOverhead.mutate(values.id);
                } else {
                    createJenisOverhead.mutateAsync(formSubmitValueMapper(values)).then(() => onHideModalHandler());
                }
            },
            component: (
                <Formik initialValues={values}>
                    <Content action="READ" />
                </Formik>
            )
        });

    return (
        <>
            <ListLayout
                showRightTopButton={access.canCreate() ? true : false}
                showExportExcelButton
                showExportPdfButton
                onExportExcelButtonClick={() => getExportJenisOverhead.mutate("excel")}
                onExportPdfButtonClick={() => getExportJenisOverhead.mutate("pdf")}
                loadingExportButton={getExportJenisOverhead.isLoading}
                loading={getJenisOverhead.isFetching}
                error={getJenisOverhead.isError}
                errorText={errorFetchingTableMapper(getJenisOverhead.error)}
                dataTotal={getJenisOverhead?.data?.total}
                dataLength={pagination.dataLength}
                pageCurrent={pagination.pageCurrent}
                pageTotal={getJenisOverhead?.data?.last_page}
                onSearchChange={debounce(searchHandler, 1500)}
                onCreateButtonClick={onCreateButtonClickHandler}
                onDataLengthChange={onPaginationDataLengthChange}
                onPaginationChange={onPaginationChange}
                data={getJenisOverhead?.data?.data}
                tableHeader={[
                    {
                        text: "Jenis Overhead",
                        props: {
                            minWidth: 200
                        }
                    },
                    {
                        text: "Keterangan",
                        props: {
                            minWidth: 200
                        }
                    },
                    {
                        text: "Aksi",
                        props: {
                            width: 50,
                            fixed: true
                        }
                    }
                ]}
                tableBody={[
                    {
                        field: "jenis_overhead_nama"
                    },
                    {
                        field: "keterangan"
                    },
                    {
                        props: { fixed: true },
                        customField: (data) => (
                            <ButtonGroup size="sm">
                                {access.canRead() && <ButtonDetail icon noText onClick={() => onDetailButtonClickHandler(data)} />}
                                {access.canUpdate() && <ButtonUpdate icon noText onClick={() => onUpdateButtonClickHandler(data)} />}
                                {access.canDelete() && (
                                    <ButtonDelete icon noText onClick={() => formSubmitHandler(formInitialValues(data), "DELETE")} />
                                )}
                            </ButtonGroup>
                        )
                    }
                ]}
            />

            {/**
       * Modal Detail:
       * Digunakan untuk melihat detail data
       */}
            <Formik enableReinitialize initialValues={formInitialValues(modal.data)}>
                <ModalDetail
                    title={layout.getActivePageName()}
                    show={Boolean(modal.show && modal.action === "READ")}
                    onHide={onHideModalHandler}
                >
                    <Content action="READ" />
                </ModalDetail>
            </Formik>

            {/**
       * Modal Create:
       * Digunakan untuk menambah data
       */}
            <Formik
                enableReinitialize
                initialValues={formInitialValues({})}
                validationSchema={formValidationSchema}
                onSubmit={(values) => formSubmitHandler(values, "CREATE")}
            >
                {(formik) => {
                    return (
                        <Form>
                            <ModalCreate
                                title={layout.getActivePageName()}
                                show={Boolean(modal.show && modal.action === "CREATE")}
                                onHide={() => {
                                    onHideModalHandler();
                                    formik.resetForm();
                                }}
                                onSubmit={() => preSubmitHandler(formik)}
                            >
                                <Content action="CREATE" />
                            </ModalCreate>
                        </Form>
                    );
                }}
            </Formik>

            {/**
       * Modal Update:
       * Modal untuk mengubah data
       */}
            <Formik
                enableReinitialize
                initialValues={formInitialValues(modal.data)}
                validationSchema={formValidationSchema}
                onSubmit={(values) => formSubmitHandler(values, "UPDATE")}
            >
                {(formik) => {
                    return (
                        <Form>
                            <ModalUpdate
                                title={layout.getActivePageName()}
                                show={Boolean(modal.show && modal.action === "UPDATE")}
                                onHide={() => {
                                    onHideModalHandler();
                                    formik.resetForm();
                                }}
                                onSubmit={() => preSubmitHandler(formik)}
                            >
                                <Content action="UPDATE" />
                            </ModalUpdate>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
};
