import { InfoItemVertical, Select } from "components";
import { useFormikContext } from "formik";
import { useQuery } from "react-query";
import { errorFetchingSelectMapper } from "utilities";
import StepApi from "../__StepApi__";

export const Content = ({ action }) => {
  const { values, errors, touched, setValues, setFieldValue } = useFormikContext();
  const getGrade = useQuery(["grade", "dropdown"], () => StepApi.getGrade());
  const getStep = useQuery(["step", "dropdown"], () => StepApi.getStepDropdown());

  if (action === "READ") {
    return (
      <>
        <InfoItemVertical label="Grade" text={values.grade_nama} />
        <InfoItemVertical label="Step" text={values.step?.toString()} />
      </>
    );
  }

  return (
    <>
      <Select
        label="Grade"
        placeholder="Pilih grade"
        defaultValue={getGrade?.data?.find((item) => item.value === values.grade_id)}
        options={getGrade?.data ?? []}
        onChange={(val) => {
          setValues({
            ...values,
            grade_nama: val.value,
            grade_id: val.label
          });
        }}
        loading={getGrade.isFetching}
        error={Boolean(errors.grade_nama && touched.grade_nama)}
        errorText={Boolean(errors.grade_nama && touched.grade_nama) && errors.grade_nama}
        errorFetch={getGrade.isError}
        errorFetchText={errorFetchingSelectMapper(getGrade.error)}
      />

      <Select
        label="Step"
        placeholder="Pilih step"
        defaultValue={getStep?.data?.find((item) => item.value === values.step)}
        options={getStep?.data ?? []}
        onChange={(val) => setFieldValue("step", val.value)}
        loading={getStep.isFetching}
        error={Boolean(errors.step && touched.step)}
        errorText={Boolean(errors.step && touched.step) && errors.step}
        errorFetch={getStep.isError}
        errorFetchText={errorFetchingSelectMapper(getStep.error)}
      />
    </>
  );
};
