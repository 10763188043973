import {
  ButtonDetail, ButtonFilter, InfoItemVertical, InputSearch, ListLayout, ModalDetail, ModalFilter, Select, toastTrigger
} from "components";
import { Formik, useFormikContext } from "formik";
import { useAccessRights, useLayout } from "hooks";
import { debounce } from "lodash";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { dateConvert, exportHandler, numberConvert } from "utilities";
import SetupKomponenJaminanPensiunBulananApi from "./__SetupKomponenJaminanPensiunBulananApi__";
import { Content } from "./__SetupKomponenJaminanPensiunBulananComps__";

const ModalFilterBody = () => {
  const { values, setValues } = useFormikContext();

  const getPeriode = useQuery(["periode-penggajian", "dropdown"], () =>
    SetupKomponenJaminanPensiunBulananApi.getPeriode()
  );

  const getTanggal = useQuery(
    ["tanggal-penggajian", "dropdown", values?.periodeGajianId],
    () =>
      SetupKomponenJaminanPensiunBulananApi.getTanggal({
        periode_tahun: values?.periodeGajianId
      }),
    {
      enabled: Boolean(values?.periodeGajianId)
    }
  );

  const getUnitOrganisasi = useQuery(
    ["unit-organisasi-for-list", "dropdown", values?.periodeGajianId],
    () =>
      SetupKomponenJaminanPensiunBulananApi.getUnitOrganisasiForList({
        periode_tahun: values?.periodeGajianId
      }),
    { enabled: Boolean(values?.periodeGajianId) }
  );

  return (
    <>
      <Select
        label="Periode Penggajian"
        placeholder="Pilih periode penggajian"
        defaultValue={getPeriode?.data?.find((item) => item.value === values?.periodeGajianId)}
        options={getPeriode?.data ?? []}
        onChange={(val) => setValues({ ...values, periodeGajianId: val.value, periodePerencanaan: val.label })}
        loading={getPeriode.isFetching}
        errorFetch={getPeriode.isError}
      />
      <Select
        disable={Boolean(!values?.periodeGajianId)}
        label="Tanggal Penggajian"
        placeholder="Pilih tanggal penggajian"
        defaultValue={getTanggal?.data?.find((item) => item.value === values?.tglId)}
        options={getTanggal?.data ?? []}
        onChange={(val) =>
          setValues({
            ...values,
            tglId: val.value,
            tglNama: val.label,
            tglAwal: val.tglAwal,
            tglAkhir: val.tglAkhir
          })
        }
        loading={getTanggal.isFetching}
        errorFetch={getTanggal.isError}
      />
      <Select
        disable={Boolean(!values?.periodeGajianId)}
        label="Unit Organisasi"
        placeholder="Pilih unit organisasi"
        defaultValue={getUnitOrganisasi?.data?.find((item) => item.value === values.unitOrganisasiId)}
        options={getUnitOrganisasi?.data ?? []}
        onChange={(val) => setValues({ ...values, unitOrganisasiId: val.value })}
        loading={getUnitOrganisasi.isFetching}
        errorFetch={getUnitOrganisasi.isError}
      />
    </>
  );
};

export const SetupKomponenJaminanPensiunBulananList = () => {
  const access = useAccessRights("SETUP_KOMPONEN_JAMINAN_PENSIUN_BULANAN");
  const layout = useLayout();
  const number = numberConvert();
  const navigate = useNavigate();
  const date = dateConvert();

  const [searchKey, setSearchKey] = useState(undefined);
  const [pagination, setPagination] = useState({
    pageCurrent: 1,
    dataLength: 10
  });

  const [modal, setModal] = useState({
    show: false,
    action: "",
    data: {}
  });

  const [filter, setFilter] = useState({
    show: false,
    active: false,
    data: {
      periodeGajianId: undefined,
      unitOrganisasiId: undefined,
      tglAwal: undefined,
      tglAkhir: undefined
    }
  });

  const getList = useQuery(
    [
      "setup-komponen-jaminan-pensiun",
      "list",
      searchKey,
      filter?.data?.periodeGajianId,
      filter?.data?.unitOrganisasiId,
      filter?.data?.tglAwal,
      filter?.data?.tglAkhir,
      pagination.pageCurrent,
      pagination.dataLength
    ],
    () =>
      SetupKomponenJaminanPensiunBulananApi.getList({
        periode_tahun: filter?.data?.periodeGajianId,
        tgl_bulan_awal: filter?.data?.tglAwal,
        tgl_bulan_akhir: filter?.data?.tglAkhir,
        master_unit_organisasi_id: filter?.data?.unitOrganisasiId,
        search: searchKey,
        page: pagination.pageCurrent,
        limit: pagination.dataLength
      }),
    {
      enabled: Boolean(
        filter?.data?.periodeGajianId &&
        filter?.data?.tglAwal &&
        filter?.data?.tglAkhir
      )
    }
  );

  const getExportJaminanPensiun = useMutation(
    (data) =>
      SetupKomponenJaminanPensiunBulananApi.getExport({
        master_unit_organisasi_id: data?.unitOrganisasiId,
        periode_tahun: data?.periodeGajianId,
        tgl_bulan_awal: data?.tglAwal,
        tgl_bulan_akhir: data?.tglAkhir
      }),
    {
      onSuccess: (link) => {
        toastTrigger({ variant: "success", message: "Export berhasil" });
        exportHandler(link, "Data Setup Komponen Jaminan Pensiun");
      },
      onError: () => {
        toastTrigger({ variant: "danger", message: "Export gagal" });
      }
    }
  );

  const dataInfo = getList?.data?.info;

  const onHideModalHandler = () => setModal({ show: false, action: "", data: {} });
  const searchHandler = (e) => setSearchKey(e?.target?.value ? e.target.value : undefined);
  const onCreateButtonClickHandler = () => navigate("tambah");
  const onDetailButtonClickHandler = (data) => setModal({ show: true, action: "READ", data: data });
  const onPaginationChange = (data) => setPagination({ ...pagination, pageCurrent: data });
  const onPaginationDataLengthChange = (data) => setPagination({ ...pagination, dataLength: data });

  const TABLE_HEADER = [
    {
      text: "NIK"
    },
    {
      text: "Pekerja",
      props: { minWidth: 250 }
    },
    {
      text: "Unit Organisasi"
    },
    {
      text: "Jabatan"
    },
    {
      text: "Upah Tetap BPJS"
    },
    {
      text: "Besaran Upah"
    },
    {
      text: "Iuran Perusahaan"
    },
    {
      text: "Iuran Karyawan"
    },
    {
      text: "Total Iuran BPJS TK - Jaminan Hari Tua"
    },
    {
      text: "Jenis BPJS"
    },
    {
      text: "Aksi",
      props: { width: 50, fixed: true }
    }
  ];
  const TABLE_BODY = [
    { field: "nip" },
    { field: "pekerja_nama" },
    { field: "master_unit_organisasi_nama" },
    { field: "master_jabatan_nama" },
    {
      props: { textRight: true },
      customField: (data) => `Rp${number.getWithComa(data?.upah_bpjs, { minimumFractionDigits: 2 })}`
    },
    {
      props: { textRight: true },
      customField: (data) => `Rp${number.getWithComa(data?.besaran_upah, { minimumFractionDigits: 2 })}`
    },
    {
      props: { textRight: true },
      customField: (data) => `Rp${number.getWithComa(data?.iuran_perusahaan, { minimumFractionDigits: 2 })}`
    },
    {
      props: { textRight: true },
      customField: (data) => `Rp${number.getWithComa(data?.iuran_karyawan, { minimumFractionDigits: 2 })}`
    },
    {
      props: { textRight: true },
      customField: (data) =>
        data?.iuran_perusahaan && data?.iuran_karyawan
          ? `Rp${number.getWithComa(parseInt(Number(data?.iuran_perusahaan) + Number(data?.iuran_karyawan)), {
            minimumFractionDigits: 2
          })}`
          : "-"
    },
    {
      field: "jenis"
    },
    {
      props: { fixed: true, textCenter: true },
      customField: (data) => (
        <>{access.canRead() && <ButtonDetail icon noText onClick={() => onDetailButtonClickHandler(data, "READ")} />}</>
      )
    }
  ];

  return (
    <>
      <ListLayout
        customLeftTopSection={() => (
          <>
            <div className="d-flex align-items-end mb-3">
              <div style={{ width: 300 }} className="me-2">
                <InputSearch onChange={debounce(searchHandler, 1500)} />
              </div>
              <div>
                <ButtonFilter
                  active={filter?.active}
                  className="text-nowrap"
                  onClick={() => setFilter({ ...filter, show: true })}
                />
              </div>
            </div>
            {/* Info Searching */}
            <Row className="mb-4">
              <Col>
                <InfoItemVertical label="Komponen Payroll" text={dataInfo?.komponen_payroll ?? "-"} />
                <InfoItemVertical
                  label="Bulan Penggajian"
                  text={
                    (dataInfo?.bulan_awal_penggajian
                      ? date.getSlashDMY(new Date(dataInfo?.bulan_awal_penggajian))
                      : "-") +
                    " s/d" +
                    (dataInfo?.bulan_awal_penggajian
                      ? date.getSlashDMY(new Date(dataInfo?.bulan_awal_penggajian))
                      : "-")
                  }
                />
                {/* <InfoItemVertical
                  label="Proporsi Perusahaan"
                  text={
                    dataInfo?.proporsi_perusahaan
                      ? number.getWithComa(dataInfo?.proporsi_perusahaan, { minimumFractionDigits: 2, maximumFractionDigits: 4 }) + "%"
                      : "-"
                  }
                />
                <InfoItemVertical
                  label="Batas Bawah Upah"
                  text={
                    dataInfo?.batas_bawah
                      ? `Rp${number.getWithComa(dataInfo?.batas_bawah, { minimumFractionDigits: 0 })}`
                      : "-"
                  }
                /> */}
              </Col>
              <Col>
                <InfoItemVertical label="Periode Penggajian" text={dataInfo?.periode_perencanaan_penggajian ?? "-"} />
                <InfoItemVertical label="Jenis Upah Minimum" text={dataInfo?.jenis_upah_nama ?? "-"} />
                {/* <InfoItemVertical
                  label="Proporsi Pekerja"
                  text={
                    dataInfo?.proporsi_pekerja
                      ? number.getWithComa(dataInfo?.proporsi_pekerja, { minimumFractionDigits: 2, maximumFractionDigits: 4 }) + "%"
                      : "-"
                  }
                />
                <InfoItemVertical
                  label="Batas Atas Upah"
                  text={
                    dataInfo?.batas_atas
                      ? `Rp${number.getWithComa(dataInfo?.batas_atas, { minimumFractionDigits: 0 })}`
                      : "-"
                  }
                /> */}
              </Col>
            </Row>
          </>
        )}
        showRightTopButton={access.canCreate() ? true : false}
        showExportExcelButton={Boolean(filter?.data?.periodeGajianId && filter?.data?.unitOrganisasiId)}
        onExportExcelButtonClick={() => getExportJaminanPensiun.mutate(filter?.data)}
        loadingExportButton={getExportJaminanPensiun.isLoading}
        loading={getList?.isFetching}
        error={getList?.isError}
        dataTotal={getList?.data?.total}
        dataLength={pagination.dataLength}
        pageCurrent={pagination.pageCurrent}
        pageTotal={getList?.data?.last_page}
        onSearchChange={debounce(searchHandler, 1500)}
        onCreateButtonClick={onCreateButtonClickHandler}
        onDataLengthChange={onPaginationDataLengthChange}
        onPaginationChange={onPaginationChange}
        data={getList?.data?.data}
        tableHeader={TABLE_HEADER}
        tableBody={TABLE_BODY}
      />

      <ModalDetail
        title={layout.getActivePageName()}
        show={Boolean(modal.show && modal.action === "READ")}
        onHide={onHideModalHandler}
      >
        <Content action="READ" data={modal?.data} dataInfo={dataInfo} />
      </ModalDetail>

      {/* ModalFilter */}
      <Formik
        initialValues={{
          unitOrganisasiId: filter?.data?.unitOrganisasiId,
          periodeGajianId: filter?.data?.periodeGajianId,
          tglAwal: filter?.data?.tglAwal,
          tglAkhir: filter?.data?.tglAkhir
        }}
      >
        {({ resetForm, values }) => (
          <ModalFilter
            show={filter.show}
            onReset={resetForm}
            onHide={() =>
              setFilter({
                ...filter,
                show: false
              })
            }
            onSubmit={() => {
              setFilter({
                show: false,
                active: Boolean(Object.values(values).find((val) => val)),
                data: values
              });
            }}
          >
            <ModalFilterBody />
          </ModalFilter>
        )}
      </Formik>
    </>
  );
};
