import React, { useState } from "react";
import { useMutation } from "react-query";
import { ButtonGroup } from "react-bootstrap";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { Formik, useFormikContext } from "formik";
import {
  Button,
  TableList,
  ButtonCreate,
  ButtonUpdate,
  ButtonDelete,
  ModalCreate,
  ModalUpdate,
  Select,
  Input,
  TextArea
} from "components";
import { errorSubmitMapper, errorFetchingSelectMapper } from "utilities";
import { useModalConfirm } from "hooks";
import { formInitialValues, formSubmitMapper, formValidationSchema } from "../__PekerjaUtils__";
import PekerjaApi from "../__PekerjaApi__";

const ModalKontakContent = () => {
  const { values, handleChange, errors, touched, setValues } = useFormikContext();
  const getJenisKontak = useQuery(["jenisKontak", "dropdown"], () => PekerjaApi.getJenisKontak());

  return (
    <>
      <Select
        label="Jenis Kontak"
        loading={getJenisKontak.isFetching}
        options={getJenisKontak?.data ?? []}
        defaultValue={getJenisKontak?.data?.find((item) => item.value === values.jenisKontakId)}
        onChange={(val) => {
          setValues({
            ...values,
            jenisKontakId: val?.value,
            jenisKontakNama: val?.label
          });
        }}
        error={Boolean(errors.jenisKontakId && touched.jenisKontakId)}
        errorText={Boolean(errors.jenisKontakId && touched.jenisKontakId) && errors.jenisKontakId}
        errorFetch={getJenisKontak.isError}
        errorFetchText={errorFetchingSelectMapper(getJenisKontak.error)}
      />
      <Input
        label="Data Kontak"
        type="text"
        name="dataKontak"
        value={values?.dataKontak}
        onChange={handleChange}
        error={Boolean(errors.dataKontak && touched.dataKontak)}
        errorText={Boolean(errors.dataKontak && touched.dataKontak) && errors.dataKontak}
      />
      <TextArea
        label="Keterangan"
        type="text"
        name="keterangan"
        value={values?.keterangan}
        onChange={handleChange}
        error={Boolean(errors.keterangan && touched.keterangan)}
        errorText={Boolean(errors.keterangan && touched.keterangan) && errors.keterangan}
      />
    </>
  );
};

const TableKontak = ({ action }) => {
  const parentFormik = useFormikContext();
  const modalConfirm = useModalConfirm();
  const [modal, setModal] = useState({
    show: false,
    action: "",
    index: "",
    data: {}
  });

  const formInitialValues = {
    jenisKontakId: modal?.data?.jenisKontakId,
    jenisKontakNama: modal?.data?.jenisKontakNama,
    dataKontak: modal?.data?.dataKontak,
    keterangan: modal?.data?.keterangan
  };

  const closeModalHandler = () => setModal({ show: false, action: "", data: {}, index: "" });

  const createDataHandler = (values, { resetForm }) => {
    let newData = [{ ...values }, ...parentFormik.values.detail];

    parentFormik.setFieldValue("detail", newData);
    resetForm();
    closeModalHandler();
  };

  const updateDataHandler = (values, { resetForm }) => {
    let newData = [...parentFormik.values.detail];

    newData.splice(modal.index, 1, values);
    parentFormik.setFieldValue("detail", newData);
    resetForm();
    closeModalHandler();
  };

  const deleteDataHandler = (index) => {
    let newData = [...parentFormik.values.detail];

    newData.splice(index, 1);
    parentFormik.setFieldValue("detail", newData);
  };

  const onButtonCreateClick = () => setModal({ show: true, data: {}, action: "CREATE", index: "" });

  const onButtonUpdateClick = (val, index) => setModal({ show: true, data: val, action: "UPDATE", index });

  const onButtonDeleteClick = (val, index) =>
    modalConfirm.trigger({
      type: "delete",
      data: [
        { label: "Jenis Kontak", text: val.jenisKontakNama },
        { label: "Data Kontak", text: val.dataKontak },
        { label: "Keterangan", text: val.keterangan }
      ],
      onSubmit: () => {
        deleteDataHandler(index);
        modalConfirm.close();
      },
      onHide: modalConfirm.close
    });

  return (
    <>
      {!Boolean(action === "READ") && (
        <div className="text-end mb-4">
          <ButtonCreate tooltip={false} onClick={onButtonCreateClick} />
        </div>
      )}

      <TableList
        responsive
        data={parentFormik.values?.detail}
        tableHeader={[
          {
            text: "Jenis Kontak"
          },
          {
            text: "Data Kontak"
          },
          {
            text: "Keterangan"
          },
          {
            text: "Aksi",
            props: { fixed: true, width: 50, className: action === "READ" ? "d-none" : "" }
          }
        ]}
        tableBody={[
          {
            field: "jenisKontakNama"
          },
          {
            field: "dataKontak"
          },
          {
            field: "keterangan"
          },
          {
            props: { fixed: true, className: action === "READ" ? "d-none" : "" },
            customField: (data, index) => (
              <ButtonGroup size="sm">
                <ButtonUpdate icon noText onClick={() => onButtonUpdateClick(data, index)} />
                <ButtonDelete icon noText onClick={() => onButtonDeleteClick(data, index)} />
              </ButtonGroup>
            )
          }
        ]}
      />

      {/* Create Modal */}
      <Formik
        initialValues={formInitialValues}
        validationSchema={formValidationSchema.kontak}
        onSubmit={createDataHandler}
      >
        {({ handleSubmit }) => (
          <ModalCreate
            show={modal.show && modal.action === "CREATE"}
            onHide={closeModalHandler}
            onSubmit={handleSubmit}
          >
            <ModalKontakContent />
          </ModalCreate>
        )}
      </Formik>

      {/* Update Modal */}
      <Formik
        enableReinitialize
        initialValues={formInitialValues}
        validationSchema={formValidationSchema.kontak}
        onHide={closeModalHandler}
        onSubmit={updateDataHandler}
      >
        {({ handleSubmit }) => (
          <ModalUpdate
            show={modal.show && modal.action === "UPDATE"}
            onHide={closeModalHandler}
            onSubmit={handleSubmit}
          >
            <ModalKontakContent />
          </ModalUpdate>
        )}
      </Formik>
    </>
  );
};

export const TabKontak = ({ action, refreshData, isGeneralUser }) => {
  const { id } = useParams();
  const modalConfirm = useModalConfirm();
  const parentFormik = useFormikContext();

  const updateKontak = useMutation((data) => PekerjaApi.update(data, id), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "update", size: "md", customTextInfoTitle: "Data Berhasil Disimpan" });
      refreshData();
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "update", size: "md", ...errorSubmitMapper(err) })
  });
  const updateKontakSelf = useMutation((data) => PekerjaApi.updateSelf(data), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "update", size: "md", customTextInfoTitle: "Data Berhasil Disimpan" });
      refreshData();
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "update", size: "md", ...errorSubmitMapper(err) })
  });

  const submitValidationHandler = (errors) =>
    new Promise((resolve, reject) => {
      const getError = Object.values(errors);

      if (getError.length > 0) {
        reject(getError);
      } else {
        resolve();
      }
    });

  const preSubmitHandler = (values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting) => {
    setSubmitting(true);
    validateForm().then(async (err) => {
      setErrors(err);
      setTouched(err);

      await submitValidationHandler(err, values)
        .then(() => handleSubmit())
        .catch((err) =>
          modalConfirm.trigger({
            type: "error",
            title: "Data Tidak Lengkap",
            data: err,
            onHide: () => modalConfirm.close()
          })
        )
        .finally(() => {
          setSubmitting(false);
        });
    });
  };

  const formSubmitHandler = (values) =>
    modalConfirm.trigger({
      size: "lg",
      type: "custom",
      title: "Konfirmasi",
      customButtonShow: true,
      customButtonText: "Simpan",
      customButtonVariant: "primary",
      customTextHeader: "Apakah anda yakin menyimpan data ini?",
      onHide: modalConfirm.close,
      onSubmit: () => {
        modalConfirm.loading();
        if (isGeneralUser === true) {
          updateKontakSelf.mutate(formSubmitMapper.kontak(values.detail));
        } else {
          updateKontak.mutate(formSubmitMapper.kontak(values.detail));
        }
      },
      component: (
        <Formik initialValues={values}>
          <TableKontak action="READ" />
        </Formik>
      )
    });

  return (
    <Formik
      enableReinitialize
      initialValues={{ detail: formInitialValues.kontak(parentFormik.values) }}
      onSubmit={formSubmitHandler}
    >
      {({ values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting }) => {
        return (
          <>
            <TableKontak action={action} />
            {!Boolean(action === "READ") && (
              <div className="text-end mt-3">
                <Button
                  text="Simpan"
                  className="px-4"
                  onClick={() =>
                    preSubmitHandler(values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting)
                  }
                />
              </div>
            )}
          </>
        );
      }}
    </Formik>
  );
};
