import { InfoItemVertical, Input } from "@bhawanadevteam/react-core";
import { useFormikContext } from "formik";
import { Col, Row } from "react-bootstrap";
import { dateConvert } from "utilities";

export const ContentSingle = ({ action, data }) => {
  const { values, errors, touched, handleChange } = useFormikContext();
  const date = dateConvert();

  if (action === "READ") {
    return (
      <>
        <InfoItemVertical label="NIK" text={data?.nip ?? "-"} />
        <InfoItemVertical label="Nama" text={data?.pekerjaNama ?? "-"} />
        <InfoItemVertical label="Tanggal" text={date.getSlashDMY(new Date(data.tglPresensi)) ?? "-"} />
        <Row>
          <Col>
            <InfoItemVertical label="Jam Masuk" text={data?.jamIn ?? "-"} />
          </Col>
          <Col>
            <InfoItemVertical label="Terlambat" text={data?.terlambat + " menit" ?? "0 menit"} />
          </Col>
        </Row>
        <Row>
          <Col>
            <InfoItemVertical label="Jam Keluar" text={data?.jamOut ?? "-"} />
          </Col>
          <Col>
            <InfoItemVertical label="Pulang Cepat" text={data?.pulangCepat + " menit" ?? "0 menit"} />
          </Col>
        </Row>
        <Row>
          <Col>
            <InfoItemVertical label="Rincian Durasi" text={data?.durasi + " menit" ?? "0 menit"} />
          </Col>
          <Col>
            <InfoItemVertical label="Rincian Lembur" text={data?.lembur + " menit" ?? "0 menit"} />
          </Col>
        </Row>
      </>
    );
  }
  if (action === "CONFIRM") {
    return (
      <>
        <InfoItemVertical label="NIK" text={data?.nip ?? "-"} />
        <InfoItemVertical label="Nama" text={data?.pekerjaNama ?? "-"} />
        <InfoItemVertical label="Tanggal" text={date.getSlashDMY(new Date(data.tglPresensi)) ?? "-"} />
        <Row>
          <Col>
            <InfoItemVertical label="Jam Masuk" text={data?.jamIn ?? "-"} />
          </Col>
          <Col>{/* <InfoItemVertical label="Terlambat" text={data?.terlambat +" menit" ?? "0 menit"} /> */}</Col>
        </Row>
        <Row>
          <Col>
            <InfoItemVertical label="Jam Keluar" text={data?.jamOut ?? "-"} />
          </Col>
          <Col>{/* <InfoItemVertical label="Pulang Cepat" text={data?.pulangCepat+" menit" ?? "0 menit"} /> */}</Col>
        </Row>
        {/* <Row>
          <Col>
            <InfoItemVertical label="Rincian Durasi" text={data?.durasi +" menit" ?? "0 menit"} />
          </Col>
          <Col>
            <InfoItemVertical label="Rincian Lembur" text={data?.lembur +" menit" ?? "0 menit"} />
          </Col> 
        </Row> */}
      </>
    );
  }
  return (
    <>
      <InfoItemVertical label="NIK" text={values?.nip ?? "-"} />
      <InfoItemVertical label="Nama" text={values?.pekerjaNama ?? "-"} />
      <InfoItemVertical label="Tanggal" text={date.getSlashDMY(new Date(values.tglPresensi)) ?? "-"} />
      <Input
        label="Jam Masuk"
        name="jamIn"
        type="time"
        placeholder=""
        value={values.jamIn}
        onChange={handleChange}
        error={Boolean(errors.jamIn && touched.jamIn)}
        errorText={Boolean(errors.jamIn && touched.jamIn) && errors.jamIn}
      />
      <Input
        label="Jam Keluar"
        name="jamOut"
        type="time"
        placeholder=""
        value={values.jamOut}
        onChange={handleChange}
        error={Boolean(errors.jamOut && touched.jamOut)}
        errorText={Boolean(errors.jamOut && touched.jamOut) && errors.jamOut}
      />
    </>
  );
};
