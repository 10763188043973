import { Services } from "services";
import { fetchDropdownMapper } from "utilities";

const fetchDropdown = fetchDropdownMapper(Services);

class SetupKodeColaApi {
  async getList(params) {
    const fetch = await Services.get("/master/insentif-cola", { params });
    return fetch?.data?.data;
  }
  async getPeriode() {
    const getPeriodeDropdown = await fetchDropdown("/master/grade-nominal/dropdown", {
      value: "periode_gajian_id",
      label: "periode_perencanaan"
    });
    return [...getPeriodeDropdown];
  }
  async getDetail(id) {
    const fetch = await Services.get("/master/insentif-cola/" + id);
    return fetch?.data?.data;
  }
  async getExport(params) {
    const fetch = await Services.get("/master/insentif-cola/export?to=", { params });
    return fetch?.data?.data;
  }
  create(data) {
    return Services.post("/master/insentif-cola", data);
  }
  update(data, id) {
    return Services.put("/master/insentif-cola/" + id, data);
  }
  delete(id) {
    return Services.delete("/master/insentif-cola/" + id);
  }
}

export default new SetupKodeColaApi();
