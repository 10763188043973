import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useModalConfirm } from "hooks";
import { Formik, useFormikContext } from "formik";
import { useMutation, useQuery } from "react-query";
import {
  ButtonBack,
  DatePicker,
  Select,
  InfoItemVertical,
  ButtonCreate,
  dateConvert,
  rupiahConvert,
  TableList,
  ContentLayout,
  Input,
  numberConvert
} from "components";
import { errorSubmitMapper, errorFetchingSelectMapper } from "utilities";
import SetupKomponenJaminanKesehatanBulananApi from "./__SetupKomponenJaminanKesehatanBulananApi__";
import { formInitialValues } from "./__SetupKomponenJaminanKesehatanBulananUtils__";
import { Card, Col, Row } from "react-bootstrap";

const FormSearch = ({ setSearch, search }) => {
  const date = dateConvert();

  const getUnitOrganisasi = useQuery(["unit-organisasi", "dropdown"], () =>
    SetupKomponenJaminanKesehatanBulananApi.getUnitOrganisasi()
  );

  const getBulan = useQuery(["bulan", "dropdown"], () => SetupKomponenJaminanKesehatanBulananApi.getBulan());

  const [filter, setFilter] = useState({
    idPeriode: new Date().getFullYear(),
    namaPeriode: new Date().getFullYear(),
    tglSetup: date.getDashYMD(new Date()),
    idBulan: undefined,
    namaBulan: undefined,
    idUnitOrganisasi: undefined,
    namaUnitOrganisasi: undefined,
    tglAwal: undefined,
    tglAkhir: undefined
  });

  return (
    <>
      <Card>
        <Card.Header>Setup Data</Card.Header>
        <Card.Body>
          <Input
            disabled
            label="Periode"
            name="idPeriode"
            placeholder="Masukkan periode"
            value={filter.idPeriode ? String(filter?.idPeriode) : ""}
            onChange={(val) =>
              setFilter({
                ...filter,
                idPeriode: val.target.value,
                namaPeriode: val.target.value
              })
            }
          />
          <Select
            label="Unit Organisasi"
            placeholder="Pilih unit organisasi"
            defaultValue={getUnitOrganisasi?.data?.find((item) => item?.value === filter?.idUnitOrganisasi)}
            options={getUnitOrganisasi?.data ?? []}
            onChange={(val) => {
              setFilter({
                ...filter,
                idUnitOrganisasi: val.value,
                namaUnitOrganisasi: val.label
              });
            }}
            loading={getUnitOrganisasi.isFetching}
            errorFetch={getUnitOrganisasi.isError}
            errorFetchText={errorFetchingSelectMapper(getUnitOrganisasi.error)}
          />
          <Select
            label="Bulan Penggajian"
            options={getBulan?.data ?? []}
            placeholder="Pilih salah satu..."
            defaultValue={filter?.idBulan ? getBulan?.data?.find((item) => item.value === filter?.idBulan) : null}
            onChange={(val) => {
              const tglNewStart = val.value <= 1 ?
                `${filter?.namaPeriode - 1}-12-26`
                : `${filter?.namaPeriode}-${val?.value < 10 ? `0${val?.value - 1}` : val?.value - 1}-26`;

              const tglNewEnd = `${filter?.namaPeriode}-${val?.value < 10 ? `0${val?.value}` : val?.value}-25`;

              setFilter({
                ...filter,
                idBulan: val.value,
                namaBulan: val.label,
                tglAwal: tglNewStart,
                tglAkhir: tglNewEnd
              });
            }}
            loading={getBulan?.isFetching}
            errorFetch={getBulan.isError}
          />
          <div>
            <div style={{ fontSize: 14 }}>Tanggal Penggajian</div>
            <div className="d-flex align-items-center justify-content-between">
              <div style={{ width: "44%" }}>
                <DatePicker
                  disabled
                  key={`${filter?.namaPeriode} ${filter?.idBulan}`}
                  placeholderText="dd/mm/yyyy"
                  selected={filter?.namaPeriode && filter?.idBulan ? filter?.tglAwal : undefined}
                />
              </div>
              <small>s/d</small>
              <div style={{ width: "44%" }}>
                <DatePicker
                  disabled
                  key={`${filter?.namaPeriode} ${filter?.idBulan}`}
                  placeholderText="dd/mm/yyyy"
                  selected={filter?.namaPeriode && filter?.idBulan ? filter?.tglAkhir : undefined}
                />
              </div>
            </div>
          </div>
          <DatePicker
            disabled
            label="Tanggal Setup"
            placeholderText="dd/mm/yy"
            selected={filter?.tglSetup ? new Date(filter?.tglSetup) : undefined}
            onChange={(val) => {
              setFilter({
                ...filter,
                tglSetup: val
              });
            }}
          />
          <div className="text-end mt-2">
            <ButtonCreate
              tooltip={false}
              disabled={
                filter?.idPeriode === undefined ||
                filter?.idBulan === undefined ||
                filter?.tglSetup === undefined ||
                filter?.tglAwal === undefined ||
                filter?.tglAkhir === undefined ||
                filter?.idUnitOrganisasi === undefined
              }
              text="CARI"
              onClick={() => {
                setSearch({
                  ...search,
                  ...filter
                });
                setFilter({
                  ...filter,
                  idBulan: undefined,
                  namaBulan: undefined,
                  idUnitOrganisasi: undefined,
                  namaUnitOrganisasi: undefined,
                  tglAwal: undefined,
                  tglAkhir: undefined
                });
              }}
            />
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

const TabelDataPekerja = ({ idPeriode }) => {
  const { values } = useFormikContext();

  const TABLE_HEADER = [
    {
      text: "NIK"
    },
    {
      text: "Pekerja"
    },
    {
      text: "Jabatan"
    },
    {
      text: "Upah Tetap BPJS"
    },

    {
      text: "Besaran Upah"
    },
    {
      text: "Iuran Perusahaan"
    },
    {
      text: "Iuran Pekerja"
    }
  ];
  const TABLE_BODY = [
    {
      field: "nip"
    },
    {
      field: "namaPekerja"
    },
    {
      field: "namaJabatan"
    },
    {
      props: { textRight: true },
      field: "upahBpjs"
    },
    {
      props: { textRight: true },
      field: "besaranUpah"
    },
    {
      props: { textRight: true },
      field: "iuranPerusahaan"
    },
    {
      props: { textRight: true },
      field: "iuranPekerja"
    }
  ];

  return (
    <>
      <TableList maxHeight={"auto"} tableHeader={TABLE_HEADER} tableBody={TABLE_BODY} data={values?.tabelDataPekerja} />
    </>
  );
};

export const SetupKomponenJaminanKesehatanBulananCreate = () => {
  const navigate = useNavigate();
  const rupiah = rupiahConvert();
  const date = dateConvert();
  const modalConfirm = useModalConfirm();
  const number = numberConvert();

  const [search, setSearch] = useState({
    idPeriode: new Date().getFullYear(),
    namaPeriode: new Date().getFullYear(),
    tglSetup: undefined,
    idBulan: undefined,
    namaBulan: undefined,
    idUnitOrganisasi: undefined,
    namaUnitOrganisasi: undefined,
    tglAwal: undefined,
    tglAkhir: undefined
  });

  const getList = useQuery(
    [
      "setup-komponen-jaminan-kesehatan-bulanan",
      "list",
      search.idPeriode,
      search.idBulan,
      search.tglSetup,
      search.idUnitOrganisasi,
      search.tglAwal,
      search.tglAkhir
    ],
    () =>
      SetupKomponenJaminanKesehatanBulananApi.getListCreate({
        periode_tahun: search.idPeriode,
        tgl_bulan_awal: search.tglAwal,
        tgl_bulan_akhir: search.tglAkhir,
        tgl_setup: search.tglSetup,
        master_unit_organisasi_id: search.idUnitOrganisasi
      }),
    {
      enabled: Boolean(search.idPeriode && search.idUnitOrganisasi && search.tglAwal && search.tglAkhir)
    }
  );

  const tampilanAtasTabel = getList?.data?.komponen_bpjs;

  const createFormSetupKomponenJaminanKesehatan = useMutation(
    (data) => SetupKomponenJaminanKesehatanBulananApi.create(data),
    {
      onSuccess: () => {
        modalConfirm.infoSuccess({ typeInfo: "success" });
        navigate("/payroll/transaksi/setup-komponen-jaminan-kesehatan-bulanan");
      },
      onError: (err) => modalConfirm.infoError({ typeInfo: "error", ...errorSubmitMapper(err) })
    }
  );

  const formSubmitHandler = (values) =>
    modalConfirm.trigger({
      size: "md",
      show: true,
      type: "create",
      typeInfoDetail: "create",
      onHide: modalConfirm.close,
      component: <></>,
      onSubmit: () => {
        createFormSetupKomponenJaminanKesehatan.mutate([...getList?.data?.pekerja]);
        modalConfirm.close();
      }
    });

  const submitValidationHandler = (errors, values) =>
    new Promise((resolve, reject) => {
      const getError = Object.values(errors);

      if (getError.length > 0) {
        reject(getError);
      } else {
        resolve();
      }
    });

  const preSubmitHandler = (values, setValues, validateForm, setTouched, setErrors, handleSubmit, setSubmitting) => {
    setSubmitting(true);
    validateForm().then(async (err) => {
      setErrors(err);
      setTouched(err);
      await submitValidationHandler(err, values)
        .then(() => handleSubmit())
        .catch((err) =>
          modalConfirm.trigger({
            type: "error",
            title: "Data Tidak Lengkap",
            data: err,
            onHide: () => modalConfirm.close()
          })
        )
        .finally(() => {
          setSubmitting(false);
        });
    });
  };
  return (
    <>
      <ContentLayout showBreadcrumb>
        <div className="text-end mb-3">
          <ButtonBack tooltip={false} onClick={() => navigate(-1)} />
        </div>
        <div className={search?.idPeriode === undefined ? "text-danger" : "d-none"}>
          <small>Harap Mengisi Setup Data Terlebih Dahulu</small>
        </div>
        <div className="d-flex">
          <div style={{ width: "30%" }} className="me-3">
            <FormSearch setSearch={setSearch} search={search} />
          </div>
          <div style={{ width: "100%" }} className="d-flex flex-column justify-content-between">
            {getList.isFetching ? (
              <>
                <div className="d-flex justify-content-center">
                  <medium>
                    <b>Memuat data ...</b>
                  </medium>
                </div>
              </>
            ) : (
              <>
                <Formik
                  initialValues={formInitialValues.FormTabel(getList?.data?.pekerja)}
                  onSubmit={(values) => formSubmitHandler(values)}
                >
                  {({ values, setValues, validateForm, setTouched, setErrors, handleSubmit, setSubmitting }) => {
                    return (
                      <>
                        <div style={{ height: "100%" }}>
                          <Card>
                            <Card.Header><b>Data Pekerja</b></Card.Header>
                            <Card.Body>
                              <Row>
                                <Col>
                                  <InfoItemVertical
                                    label="Periode Penggajian"
                                    text={tampilanAtasTabel?.periode_gajian}
                                  />
                                  <InfoItemVertical
                                    label="Bulan Penggajian"
                                    text={
                                      (tampilanAtasTabel?.tgl_bulan_awal
                                        ? date.getSlashDMY(new Date(tampilanAtasTabel?.tgl_bulan_awal))
                                        : "-") +
                                      " s/d " +
                                      (tampilanAtasTabel?.tgl_bulan_akhir
                                        ? date.getSlashDMY(new Date(tampilanAtasTabel?.tgl_bulan_akhir))
                                        : "-")
                                    }
                                  />
                                  <InfoItemVertical
                                    label="Tanggal Setup"
                                    text={
                                      tampilanAtasTabel?.tgl_setup
                                        ? date.getSlashDMY(new Date(tampilanAtasTabel?.tgl_setup))
                                        : "-"
                                    }
                                  />
                                  <InfoItemVertical
                                    label="Unit Organisasi"
                                    text={tampilanAtasTabel?.master_unit_organisasi_nama}
                                  />
                                  <InfoItemVertical label="Jenis Upah Minimum" text={tampilanAtasTabel?.jenis_upah} />
                                  <InfoItemVertical
                                    label="Komponen BPJS "
                                    text={tampilanAtasTabel?.komponen_bpjs_nama}
                                  />
                                </Col>
                                <Col>
                                  <InfoItemVertical
                                    label="Proporsi Perusahaan"
                                    text={
                                      tampilanAtasTabel?.proporsi_perusahaan
                                        ? number.getWithComa(tampilanAtasTabel?.proporsi_perusahaan, { minimumFractionDigits: 2, maximumFractionDigits: 4 }) + "%"
                                        : "-"
                                    }
                                  />
                                  <InfoItemVertical
                                    label="Proporsi Karyawan"
                                    text={
                                      tampilanAtasTabel?.proporsi_pekerja
                                        ? number.getWithComa(tampilanAtasTabel?.proporsi_pekerja, { minimumFractionDigits: 2, maximumFractionDigits: 4 }) + "%"
                                        : "-"
                                    }
                                  />
                                  <InfoItemVertical
                                    label="Batas Bawah Upah"
                                    text={
                                      tampilanAtasTabel?.batas_bawah
                                        ? rupiah.getWithComa(tampilanAtasTabel?.batas_bawah)
                                        : "-"
                                    }
                                  />
                                  <InfoItemVertical
                                    label="Batas Atas Upah"
                                    text={
                                      tampilanAtasTabel?.batas_atas
                                        ? rupiah.getWithComa(tampilanAtasTabel?.batas_atas)
                                        : "-"
                                    }
                                  />
                                  <InfoItemVertical label="Keterangan" text={tampilanAtasTabel?.keterangan} />
                                  <Row></Row>
                                </Col>
                              </Row>
                              <TabelDataPekerja idPeriode={search?.idPeriode} />
                            </Card.Body>
                          </Card>
                        </div>
                        <div className="mt-2 text-end">
                          <ButtonCreate
                            disabled={values?.tabelDataPekerja?.length === 0 || values?.tabelDataPekerja === undefined}
                            tooltip={false}
                            text={"SIMPAN"}
                            onClick={() =>
                              preSubmitHandler(
                                values,
                                setValues,
                                validateForm,
                                setTouched,
                                setErrors,
                                handleSubmit,
                                setSubmitting
                              )
                            }
                          />
                        </div>
                      </>
                    );
                  }}
                </Formik>
              </>
            )}
          </div>
        </div>
      </ContentLayout>
    </>
  );
};
