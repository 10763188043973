import { useNavigate, useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import { Formik } from "formik";
import { ContentLayout } from "layouts";
import { ButtonBack } from "components";
import { Content } from "./__CreateGajiComps__";
import CreateGajiApi from "./__CreateGajiApi__";

export const CreateGajiDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const id = location?.state?.detailData?.pekerja_payroll_bulanan_id;
  const CreateGajiDetail = useQuery(["create-gaji", "detail", id], () => CreateGajiApi.getDetail(id), {
    enabled: Boolean(id)
  });

  return (
    <ContentLayout showBreadcrumb>
      <div className="text-end mb-3">
        <ButtonBack tooltip={false} onClick={() => navigate("/payroll/transaksi/create-gaji")} />
      </div>
      <Formik>
        <Content action="READ" dataDetail={CreateGajiDetail?.data} />
      </Formik>
    </ContentLayout>
  );
};
