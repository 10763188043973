import * as Yup from "yup";

export const formValidationSchema = {
  FormDetailBarang: Yup.object().shape({
    namaItem: Yup.string().required("Nama barang wajib diisi"),
    idSatuan: Yup.string().required("Satuan barang wajib diisi"),
    jumlahBarang: Yup.string().required("Qty wajib diisi"),
    hargaBarang: Yup.string().required("Harga satuan wajib diisi"),
    diskon: Yup.string().required("Diskon wajib diisi")
  })
};
