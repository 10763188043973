import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { Formik } from "formik";
import { ContentLayout, ButtonBack, DataStatus } from "components";
import { errorFetchingPageMapper } from "utilities";
import { Content } from "./__UraianKPIComps__";
import { formInitialValues, formValidationSchema } from "./__UraianKPIUtils__";

import UraianKPIApi from "./__UraianKPIApi__";

export const UraianKPIDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const getUraianKPI = useQuery(["uraian-kpi", id], () => UraianKPIApi.getReadSingle(id));

  const submitHandler = () => {
    navigate(-1);
  };

  return (
    <ContentLayout showBreadcrumb>
      {/* Form */}
      {getUraianKPI.isFetching || getUraianKPI.isError ? (
        <DataStatus loading={getUraianKPI.isFetching} text={errorFetchingPageMapper(getUraianKPI.error)} />
      ) : (
        <Formik
          enableReinitialize
          initialValues={formInitialValues({ data: getUraianKPI?.data })}
          validationSchema={formValidationSchema}
          onSubmit={(values) => submitHandler(values)}
        >
          {() => (
            <>
              <div className="d-flex justify-content-end">
                <ButtonBack
                  tooltip={false}
                  icon={false}
                  className="mb-3 mt-4"
                  text="Tutup"
                  onClick={() => navigate(-1)}
                />
              </div>
              <Content action="READ" />
            </>
          )}
        </Formik>
      )}
    </ContentLayout>
  );
};
