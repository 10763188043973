import React, { useState } from "react";
import { useMutation } from "react-query";
import { ButtonGroup, ProgressBar } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { Formik, useFormikContext } from "formik";
import {
  Button,
  TableList,
  ButtonCreate,
  ButtonUpdate,
  ButtonDelete,
  ModalCreate,
  ModalUpdate,
  Select,
  Input,
  InputFileSingle
} from "components";
import { errorSubmitMapper, errorFetchingSelectMapper, getFileLink } from "utilities";
import { useModalConfirm, useUploads } from "hooks";
import { formInitialValues, formSubmitMapper, formValidationSchema } from "../__PekerjaUtils__";
import PekerjaApi from "../__PekerjaApi__";

const ModalPendidikanContent = () => {
  const modalConfirm = useModalConfirm();
  const { values, handleChange, errors, touched, setValues } = useFormikContext();

  const getJenjangPendidikan = useQuery(["jenjangPendidikan", "dropdown"], () => PekerjaApi.getJenjangPendidikan());

  const uploadIjazah = useUploads(PekerjaApi.linkUploadPendidikan, {
    onSuccess: (val) => {
      const link = val?.data?.data;
      setValues({ ...values, fileIjazah: link, fileIjazahFull: getFileLink({ link, temporary: true }) });
    },
    onError: (err) =>
      modalConfirm.infoError({
        customTextInfoTitle: "Upload ijazah gagal",
        ...errorSubmitMapper(err),
        onHide: modalConfirm.close
      })
  });

  const uploadTranskrip = useUploads(PekerjaApi.linkUploadPendidikan, {
    onSuccess: (val) => {
      const link = val?.data?.data;
      setValues({ ...values, fileTrnaskrip: link, fileTrnaskripFull: getFileLink({ link, temporary: true }) });
    },
    onError: (err) =>
      modalConfirm.infoError({
        customTextInfoTitle: "Upload transkrip nilai gagal",
        ...errorSubmitMapper(err),
        onHide: modalConfirm.close
      })
  });

  return (
    <>
      <Select
        label="Jenjang Pendidikan"
        loading={getJenjangPendidikan.isLoading}
        options={getJenjangPendidikan?.data ?? []}
        defaultValue={getJenjangPendidikan?.data?.find((item) => item.value === values.jenjangPendidikanId)}
        onChange={(val) => {
          setValues({ ...values, jenjangPendidikanId: val.value, jenjangPendidikanNama: val.label });
        }}
        error={Boolean(errors.jenjangPendidikanId && touched.jenjangPendidikanId)}
        errorText={Boolean(errors.jenjangPendidikanId && touched.jenjangPendidikanId) && errors.jenjangPendidikanId}
        errorFetch={getJenjangPendidikan.isError}
        errorFetchText={errorFetchingSelectMapper(getJenjangPendidikan.error)}
      />

      <Input
        label="Nama Sekolah / Institusi"
        name="namaSekolah"
        type="text"
        placeholder="Masukan nama sekolah"
        value={values?.namaSekolah}
        onChange={handleChange}
        error={Boolean(errors.namaSekolah && touched.namaSekolah)}
        errorText={Boolean(errors.namaSekolah && touched.namaSekolah) && errors.namaSekolah}
      />

      <Input
        label="No. Ijazah"
        name="nomorIjazah"
        type="text"
        placeholder="Masukan no. ijazah"
        value={values?.nomorIjazah}
        onChange={handleChange}
        error={Boolean(errors.nomorIjazah && touched.nomorIjazah)}
        errorText={Boolean(errors.nomorIjazah && touched.nomorIjazah) && errors.nomorIjazah}
      />

      <InputFileSingle
        label="Upload Ijazah"
        onClick={(e) => (uploadIjazah.isLoading ? e.preventDefault() : {})}
        disabled={uploadIjazah.isLoading}
        link={values.fileIjazahFull}
        prefiew={Boolean(values.fileIjazahFull)}
        onChange={(val) => {
          let formData = new FormData();
          formData.append("file", val.data);
          uploadIjazah.mutateAsync(formData);
        }}
        error={Boolean(errors.fileIjazah && touched.fileIjazah)}
        errorText={Boolean(errors.fileIjazah && touched.fileIjazah) && errors.fileIjazah}
      />
      {uploadIjazah.isLoading && (
        <ProgressBar now={uploadIjazah.progress} label={`Mengunggah file ${uploadIjazah.progress}%`} />
      )}
      <InputFileSingle
        label="Upload Transkrip Nilai"
        onClick={(e) => (uploadTranskrip.isLoading ? e.preventDefault() : {})}
        disabled={uploadTranskrip.isLoading}
        link={values.fileTrnaskripFull}
        prefiew={Boolean(values.fileTrnaskripFull)}
        onChange={(val) => {
          let formData = new FormData();
          formData.append("file", val.data);
          uploadTranskrip.mutateAsync(formData);
        }}
        error={Boolean(errors.fileTrnaskrip && touched.fileTrnaskrip)}
        errorText={Boolean(errors.fileTrnaskrip && touched.fileTrnaskrip) && errors.fileTrnaskrip}
      />
      {uploadTranskrip.isLoading && (
        <ProgressBar now={uploadTranskrip.progress} label={`Mengunggah file ${uploadTranskrip.progress}%`} />
      )}
    </>
  );
};

const TablePendidikan = ({ action }) => {
  const parentFormik = useFormikContext();
  const modalConfirm = useModalConfirm();
  const [modal, setModal] = useState({
    show: false,
    action: "",
    index: "",
    data: {}
  });

  const formInitialValues = {
    jenjangPendidikanId: modal?.data?.jenjangPendidikanId,
    jenjangPendidikanNama: modal?.data?.jenjangPendidikanNama,
    namaSekolah: modal?.data?.namaSekolah,
    nomorIjazah: modal?.data?.nomorIjazah,
    fileIjazah: modal?.data?.fileIjazah,
    fileIjazahFull: modal?.data?.fileIjazahFull,
    fileTrnaskrip: modal?.data?.fileTrnaskrip,
    fileTrnaskripFull: modal?.data?.fileTrnaskripFull
  };

  const closeModalHandler = () => setModal({ show: false, action: "", data: {}, index: "" });

  const createDataHandler = (values, { resetForm }) => {
    let newData = [{ ...values }, ...parentFormik.values.detail];

    parentFormik.setFieldValue("detail", newData);
    resetForm();
    closeModalHandler();
  };

  const updateDataHandler = (values, { resetForm }) => {
    let newData = [...parentFormik.values.detail];

    newData.splice(modal.index, 1, values);
    parentFormik.setFieldValue("detail", newData);
    resetForm();
    closeModalHandler();
  };

  const deleteDataHandler = (index) => {
    let newData = [...parentFormik.values.detail];

    newData.splice(index, 1);
    parentFormik.setFieldValue("detail", newData);
  };

  const onButtonCreateClick = () => setModal({ show: true, data: {}, action: "CREATE", index: "" });

  const onButtonUpdateClick = (val, index) => setModal({ show: true, data: val, action: "UPDATE", index });

  const onButtonDeleteClick = (val, index) =>
    modalConfirm.trigger({
      type: "delete",
      data: [
        { label: "Jenjang Pendididkan", text: val.jenjangPendidikanNama },
        { label: "Nama Sekolah / Institusi", text: val.namaSekolah },
        { label: "Nomor Ijazah", text: val.nomorIjazah }
      ],
      onSubmit: () => {
        deleteDataHandler(index);
        modalConfirm.close();
      },
      onHide: modalConfirm.close
    });

  return (
    <>
      {!Boolean(action === "READ") && (
        <div className="text-end mb-4">
          <ButtonCreate tooltip={false} onClick={onButtonCreateClick} />
        </div>
      )}

      <TableList
        responsive
        data={parentFormik.values?.detail}
        tableHeader={[
          {
            text: "Jenjang Pendidikan"
          },
          {
            text: "Nama Sekolah / Institusi"
          },
          {
            text: "No. Ijazah"
          },
          {
            text: "Ijazah"
          },
          {
            text: "Transkrip Nilai"
          },
          {
            text: "Aksi",
            props: { fixed: true, width: 50, className: action === "READ" ? "d-none" : "" }
          }
        ]}
        tableBody={[
          {
            field: "jenjangPendidikanNama"
          },
          {
            field: "namaSekolah"
          },
          {
            field: "nomorIjazah"
          },
          {
            customField: (val) => (
              <a href={val.fileIjazahFull} target="_blank" rel="noreferrer">
                <b>Lihat File</b>
              </a>
            ),
            props: { textCenter: true }
          },
          {
            customField: (val) => (
              <a href={val.fileTrnaskrip} target="_blank" rel="noreferrer">
                <b>Lihat File</b>
              </a>
            ),
            props: { textCenter: true }
          },
          {
            props: { fixed: true, className: action === "READ" ? "d-none" : "" },
            customField: (data, index) => (
              <ButtonGroup size="sm">
                <ButtonUpdate icon noText onClick={() => onButtonUpdateClick(data, index)} />
                <ButtonDelete icon noText onClick={() => onButtonDeleteClick(data, index)} />
              </ButtonGroup>
            )
          }
        ]}
      />

      {/* Create Modal */}
      <Formik
        initialValues={formInitialValues}
        validationSchema={formValidationSchema.pendidikan}
        onSubmit={createDataHandler}
      >
        {({ handleSubmit }) => (
          <ModalCreate
            show={modal.show && modal.action === "CREATE"}
            onHide={closeModalHandler}
            onSubmit={handleSubmit}
          >
            <ModalPendidikanContent />
          </ModalCreate>
        )}
      </Formik>

      {/* Update Modal */}
      <Formik
        enableReinitialize
        initialValues={formInitialValues}
        validationSchema={formValidationSchema.pendidikan}
        onHide={closeModalHandler}
        onSubmit={updateDataHandler}
      >
        {({ handleSubmit }) => (
          <ModalUpdate
            show={modal.show && modal.action === "UPDATE"}
            onHide={closeModalHandler}
            onSubmit={handleSubmit}
          >
            <ModalPendidikanContent />
          </ModalUpdate>
        )}
      </Formik>
    </>
  );
};

export const TabPendidikan = ({ action, refreshData, isGeneralUser }) => {
  const { id } = useParams();
  const modalConfirm = useModalConfirm();
  const parentFormik = useFormikContext();

  const updatePendidikan = useMutation((data) => PekerjaApi.update(data, id), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "update", size: "md", customTextInfoTitle: "Data Berhasil Disimpan" });
      refreshData();
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "update", size: "md", ...errorSubmitMapper(err) })
  });
  const updatePendidikanSelf = useMutation((data) => PekerjaApi.updateSelf(data), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "update", size: "md", customTextInfoTitle: "Data Berhasil Disimpan" });
      refreshData();
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "update", size: "md", ...errorSubmitMapper(err) })
  });

  const submitValidationHandler = (errors) =>
    new Promise((resolve, reject) => {
      const getError = Object.values(errors);

      if (getError.length > 0) {
        reject(getError);
      } else {
        resolve();
      }
    });

  const preSubmitHandler = (values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting) => {
    setSubmitting(true);
    validateForm().then(async (err) => {
      setErrors(err);
      setTouched(err);

      await submitValidationHandler(err, values)
        .then(() => handleSubmit())
        .catch((err) =>
          modalConfirm.trigger({
            type: "error",
            title: "Data Tidak Lengkap",
            data: err,
            onHide: () => modalConfirm.close()
          })
        )
        .finally(() => {
          setSubmitting(false);
        });
    });
  };

  const formSubmitHandler = (values) =>
    modalConfirm.trigger({
      size: "lg",
      type: "custom",
      onHide: modalConfirm.close,
      title: "Konfirmasi",
      customButtonShow: true,
      customButtonText: "Simpan",
      customButtonVariant: "primary",
      customTextHeader: "Apakah anda yakin menyimpan data ini?",
      onSubmit: () => {
        modalConfirm.loading();
        if (isGeneralUser === true) {
          updatePendidikanSelf.mutate(formSubmitMapper.pendidikan(values.detail));
        } else {
          updatePendidikan.mutate(formSubmitMapper.pendidikan(values.detail));
        }
      },
      component: (
        <Formik initialValues={values}>
          <TablePendidikan action="READ" />
        </Formik>
      )
    });

  return (
    <Formik
      enableReinitialize
      initialValues={{ detail: formInitialValues.pendidikan(parentFormik.values) }}
      onSubmit={formSubmitHandler}
    >
      {({ values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting }) => {
        return (
          <>
            <TablePendidikan action={action} />
            {!Boolean(action === "READ") && (
              <div className="text-end mt-3">
                <Button
                  text="Simpan"
                  className="px-4"
                  onClick={() =>
                    preSubmitHandler(values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting)
                  }
                />
              </div>
            )}
          </>
        );
      }}
    </Formik>
  );
};
