export const formSubmitValueMapper = (values = {}) => ({
  setup_jadwal_id: values?.id,
  periode_tahun: values?.periodeGajianId,
  tgl_setup: values?.tglSetup,
  tgl_bulan_awal: values?.tglAwal,
  tgl_bulan_akhir: values?.tglAkhir,
  week_order: parseInt(values?.weekNumber),
  maks_week: values?.maxWeek,
  list: values?.detail?.map((val) => ({
    pekerja_id: val?.pekerjaId,
    pekerja_nama: val?.pekerjaNama,
    master_jabatan_id: val?.jabatanId,
    jadwal: val?.jadwal[0]?.dates?.map((x) => ({
      tgl: x.tgl,
      disabled: x.isDisabled,
      master_shift_id: x.shiftId,
      week_order: x.weekOrder
    }))
  }))
});
