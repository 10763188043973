import {
  SetupKomponenJaminanKehilanganPekerjaanBulananList,
  SetupKomponenJaminanKehilanganPekerjaanBulananCreate
} from "pages/Payroll";

export const SetupKomponenJaminanKehilanganPekerjaanBulanan = {
  component: <SetupKomponenJaminanKehilanganPekerjaanBulananList />,
  link: "setup-komponen-jaminan-kehilangan-pekerjaan-bulanan",
  name: "Setup Komponen Jaminan Kehilangan Pekerjaan Bulanan",
  access: "SETUP_KOMPONEN_JAMINAN_KEHILANGAN_PEKERJAAN_BULANAN",
  child: [
    {
      name: "Tambah",
      link: "/tambah",
      component: <SetupKomponenJaminanKehilanganPekerjaanBulananCreate />
    }
  ]
};
