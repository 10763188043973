import { dateConvert } from "components";
const date = dateConvert();

export const getFunction = {
  getJenisKoreksi: (data) => {
    if (data < 1 || data > 2 || data === undefined) {
      return undefined;
    } else if (data === 1) {
      return "Koreksi Minus";
    } else {
      return "Koreksi Plus";
    }
  }
};

export const formInitialValues = {
  Form: (values) => ({
    tabelListKoreksi: values?.koreksi?.map((data) => ({
      id: data?.pekerja_koreksi_bulanan_id,
      namaKomponenUpah: data?.master_komponen_payroll_nama,
      idKomponenUpah: data?.master_komponen_payroll_id,
      namaJenisKoreksi: getFunction.getJenisKoreksi(data?.jenis_koreksi),
      idJenisKoreksi: data?.jenis_koreksi,
      nominal: data?.nominal_koreksi ? Number(data?.nominal_koreksi) + "" : undefined,
      keterangan: data?.keterangan
    })),
    dataPekerja: {
      tanggalSetup: values?.koreksi[0]?.tgl_setup,
      namaPeriode: values?.periode_gajian,
      bulanPenggajian: values?.tgl_bulan_awal
        ? date?.getSlashDMY(new Date(values?.tgl_bulan_awal)) +
        " s/d " +
        (values?.tgl_bulan_akhir ? date?.getSlashDMY(new Date(values?.tgl_bulan_akhir)) : undefined)
        : undefined +
        " s/d " +
        (values?.tgl_bulan_akhir ? date?.getSlashDMY(new Date(values?.tgl_bulan_akhir)) : undefined),
      namaUnitOrganisasi: values?.master_unit_organisasi_nama,
      namaPekerja: values?.pekerja_nama,
      namaJabatan: values?.master_jabatan_nama
    }
  })
};
