import {
    ButtonFilter, InfoItemVertical, InputSearch, ModalFilter, numberConvert,
    Select, toastTrigger
} from "components";
import { Dropdown, Spinner } from "react-bootstrap";
import { FiFileText } from "react-icons/fi";
import { Formik, useFormikContext } from "formik";
import { ListLayout } from "layouts";
import { debounce } from "lodash";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { exportHandler } from "utilities";
import LaporanThrApi from "./__LaporanThrApi__";

const ModalFilterBody = () => {
    const { values, setValues } = useFormikContext();

    const getPeriode = useQuery(["periode-penggajian", "dropdown"], () => LaporanThrApi.getPeriode());
    const getAgama = useQuery(["agama", "dropdown"], () => LaporanThrApi.getAgama());

    return (
        <>
            <Select
                label="Periode THR"
                placeholder="Pilih periode thr"
                defaultValue={getPeriode?.data?.find((item) => item.value === values?.periodeGajianId)}
                options={getPeriode?.data ?? []}
                onChange={(val) => setValues({ ...values, periodeGajianId: val.value, periodePerencanaan: val.label })}
                loading={getPeriode.isFetching}
                errorFetch={getPeriode.isError}
            />
            <Select
                label="Agama"
                placeholder="Pilih agama"
                defaultValue={getAgama?.data?.find((item) => item.value === values.agamaId)}
                options={getAgama?.data ?? []}
                onChange={(val) => setValues({ ...values, agamaId: val.value, agamaNama: val.label })}
                loading={getAgama.isFetching}
                errorFetch={getAgama.isError}
            />
        </>
    );
};

export const LaporanThrList = () => {
    const navigate = useNavigate();
    const number = numberConvert();

    const [searchKey, setSearchKey] = useState(undefined);
    const [pagination, setPagination] = useState({
        pageCurrent: 1,
        dataLength: 10
    });

    const [filter, setFilter] = useState({
        show: false,
        active: false,
        data: {
            periodeGajianId: undefined,
            unitOrganisasiId: undefined,
            tglAwal: undefined,
            tglAkhir: undefined
        }
    });

    const [modal, setModal] = useState({
        show: false,
        action: "",
        data: {}
    });

    const getLaporanThrList = useQuery(
        [
            "tunjangan-hari-raya",
            "list",
            searchKey,
            pagination.pageCurrent,
            pagination.dataLength,
            filter.data?.periodeGajianId,
            filter.data?.unitOrganisasiId,
            filter.data?.tglAwal,
            filter.data?.tglAkhir,
            filter.data?.agamaId
        ],
        () =>
            LaporanThrApi.getList({
                search: searchKey,
                page: pagination.pageCurrent,
                limit: pagination.dataLength,
                tahun: filter.data?.periodeGajianId,
                master_agama_id: filter.data?.agamaId,
                master_unit_organisasi_id: filter.data?.unitOrganisasiId,
                tgl_bulan_awal: filter.data?.tglAwal,
                tgl_bulan_akhir: filter.data?.tglAkhir
            }),
        {
            enabled: Boolean(
                filter?.data?.periodeGajianId
                // filter?.data?.unitOrganisasiId &&
                // filter?.data?.tglAwal &&
                // filter?.data?.tglAkhir
            )
        }
    );

    const getLaporanThrExport = useMutation(
        (data) =>
            LaporanThrApi.getExport({
                tahun: data?.periodeGajianId,
                master_agama_id: data?.agamaId,
            }),
        {
            onSuccess: (link) => {
                toastTrigger({ variant: "success", message: "Export berhasil" });
                exportHandler(link, "Data Tunjangan Hari Raya");
            },
            onError: () => {
                toastTrigger({ variant: "danger", message: "Export gagal" });
            }
        }
    );

    const onHideModalHandler = () => setModal({ show: false, action: "", data: {} });
    const onCreateButtonClickHandler = () => navigate("tambah");

    const onPaginationChange = (data) => setPagination({ ...pagination, pageCurrent: data });

    const onPaginationDataLengthChange = (data) => setPagination({ ...pagination, dataLength: data });

    const searchHandler = (e) => {
        setSearchKey(e?.target?.value ? e.target.value : undefined);
    };

    const submitValidationHandler = (errors) =>
        new Promise((resolve, reject) => {
            const getError = Object.values(errors);

            if (getError.length > 0) {
                reject(getError);
            } else {
                resolve();
            }
        });

    const dataInfo = getLaporanThrList?.data?.info;

    const TABLE_HEADER = [
        {
            text: "NIK"
        },
        {
            text: "Pekerja"
        },
        {
            text: "Unit Organisasi"
        },
        {
            text: "Jabatan"
        },
        {
            text: "Agama"
        },
        {
            text: "Persentase belum diberikan",
            props: {
                width: 100
            }
        },
        {
            text: "Nominal Belum dibayar",
            props: {
                width: 200
            }
        },
        {
            text: "Persentase sudah diberikan",
            props: {
                width: 100
            }
        },
        {
            text: "Nominal Sudah dibayar",
            props: {
                width: 200
            }
        },
    ];

    const TABLE_BODY = [
        { field: "nip" },
        { field: "pekerja_nama" },
        { field: "master_unit_organisasi_nama" },
        { field: "master_jabatan_nama" },
        { field: "agama" },
        {
            props: { textRight: true },
            customField: (val) => number.getWithComa((100 - val?.persentase), { minimumFractionDigits: 2 }) + "%"
        },
        {
            props: { textRight: true },
            customField: (val) => "Rp" + number.getWithComa(
                (parseFloat(val.tunjangan_tetap) + parseFloat(val.upah_pokok)) * ((100 - val.persentase) / 100),
                { minimumFractionDigits: 2 })
        },
        {
            props: { textRight: true },
            customField: (val) => number.getWithComa(val?.persentase, { minimumFractionDigits: 2 }) + "%"
        },
        {
            props: { textRight: true },
            customField: (val) => "Rp" + number.getWithComa(val?.nominal, { minimumFractionDigits: 2 })
        },
    ];

    return (
        <>
            <ListLayout
                customRightTopSection={() => (
                    <div className="d-flex flex-column flex-sm-row justify-content-center justify-content-md-end align-items-center">
                        <Dropdown align="end">
                            <Dropdown.Toggle
                                size="sm"
                                variant="outline-success"
                                className="mx-2"
                                disabled={getLaporanThrExport?.isLoading}
                            >
                                {getLaporanThrExport?.isLoading ? (
                                    <Spinner size="sm" className="me-1" animation="border" />
                                ) : (
                                    <FiFileText className="mb-1 mr-3" />
                                )}
                                <span className="mx-1">{getLaporanThrExport?.isLoading ? "Memproses..." : "EXPORT"}</span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="mt-2">
                                <Dropdown.Item onClick={() => getLaporanThrExport.mutate(filter?.data)}>Export Excel</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                )}
                customLeftTopSection={() => (
                    <>
                        <div className="d-flex align-items-end mb-3">
                            <div style={{ width: 300 }} className="me-2">
                                <InputSearch onChange={debounce(searchHandler, 1500)} />
                            </div>
                            <div>
                                <ButtonFilter
                                    active={filter?.active}
                                    className="text-nowrap"
                                    onClick={() => setFilter({ ...filter, show: true })}
                                />
                            </div>
                        </div>
                        <div className="d-flex align-items-end mb-3 gap-2">
                            <InfoItemVertical label="Komponen Payroll" text={dataInfo?.komponen_payroll} />
                            <InfoItemVertical
                                label="Periode Thr"
                                text={dataInfo?.periode_perencanaan_penggajian}
                            />
                        </div>
                    </>
                )}
                // showRightTopButton={access.canCreate() ? true : false}
                // showExportExcelButton={Boolean(filter?.data?.periodeGajianId)}
                // onExportExcelButtonClick={() => getLaporanThrExport.mutate(filter?.data)}
                // loadingExportButton={getLaporanThrExport.isLoading}
                loading={getLaporanThrList?.isFetching}
                error={getLaporanThrList?.isError}
                dataTotal={getLaporanThrList?.data?.total}
                dataLength={pagination.dataLength}
                pageCurrent={pagination.pageCurrent}
                pageTotal={getLaporanThrList?.data?.last_page}
                onSearchChange={debounce(searchHandler, 1500)}
                onCreateButtonClick={onCreateButtonClickHandler}
                onDataLengthChange={onPaginationDataLengthChange}
                onPaginationChange={onPaginationChange}
                data={getLaporanThrList?.data?.data}
                tableHeader={TABLE_HEADER}
                tableBody={TABLE_BODY}
            />

            {/* ModalFilter */}
            <Formik
                initialValues={{
                    periodeGajianId: filter?.data?.periodeGajianId,
                    agamaId: filter?.data?.agamaId,
                    unitOrganisasiId: filter?.data?.unitOrganisasiId,
                    tglAwal: filter?.data?.tglAwal,
                    tglAkhir: filter?.data?.tglAkhir
                }}
            >
                {({ resetForm, values }) => (
                    <ModalFilter
                        show={filter.show}
                        onReset={resetForm}
                        onHide={() =>
                            setFilter({
                                ...filter,
                                show: false
                            })
                        }
                        onSubmit={() => {
                            setFilter({
                                show: false,
                                active: Boolean(Object.values(values).find((val) => val)),
                                data: values
                            });
                        }}
                    >
                        <ModalFilterBody />
                    </ModalFilter>
                )}
            </Formik>
        </>
    );
};
