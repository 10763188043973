import { Services } from "services";
import { fetchDropdownMapper } from "utilities";

const fetchDropdown = fetchDropdownMapper(Services);

class TingkatCompensableFactor {
  async getList(params) {
    const fetch = await Services.get("/master/tingkat-compensable-factors", { params });
    return fetch?.data?.data;
  }

  async getMasterCompensable() {
    const getMasterCompensable = await fetchDropdown("/master/compensable-factors/dropdown", {
      value: "master_compensable_id",
      label: "master_compensable_nama"
    });

    return getMasterCompensable;
  }

  async getExport(params) {
    const fetch = await Services.get("/master/tingkat-compensable-factors/export", { params });
    return fetch?.data?.data;
  }

  create(data) {
    return Services.post("/master/tingkat-compensable-factors", data);
  }

  update(data, id) {
    return Services.put("/master/tingkat-compensable-factors/" + id, data);
  }

  delete(id) {
    return Services.delete("/master/tingkat-compensable-factors/" + id);
  }
}
export default new TingkatCompensableFactor();
