import * as Yup from "yup";

export const formValidationSchema = Yup.object().shape({
  idPeriodePenggajian: Yup.string().required("Periode penggajian wajib dipilih"),
  tglSetup: Yup.string().required("Tanggal setup wajib dipilih"),
  proporsiUpahLembur: Yup.string().required("Proporsi wajib diisi"),
  idJenisUpahLembur: Yup.string().required("Jenis upah lembur wajib dipilih"),
  idSesiPerhitungan: Yup.string().required("Sesi perhitungan wajib dipilih"),
  idStatus: Yup.string().required("Status wajib dipilih")
});
