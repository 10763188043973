import { useState } from "react";
import { ButtonGroup } from "react-bootstrap";
import { useQuery, useMutation } from "react-query";
import { Form, Formik } from "formik";
import { debounce } from "lodash";
import { ListLayout } from "layouts";
import {
  ButtonDetail,
  ButtonUpdate,
  ButtonDelete,
  ModalDetail,
  ModalCreate,
  ModalUpdate,
  toastTrigger,
  InputSearch,
  Select
} from "components";
import { useLayout, useModalConfirm, useAccessRights } from "hooks";
import { errorFetchingTableMapper, errorSubmitMapper, exportHandler } from "utilities";
import {
  formInitialValues,
  formValidationSchema,
  formSubmitMapper,
  jenisJabatanMapper
} from "./__KelompokJabatanUtils__";
import { Content } from "./__KelompokJabatanComps__";
import KelompokJabatanApi from "./__KelompokJabatanApi__";

export const KelompokJabatanList = () => {
  const layout = useLayout();
  const access = useAccessRights("MASTER_KELOMPOK_JABATAN");
  const modalConfirm = useModalConfirm();
  const [searchKey, setSearchKey] = useState(undefined);
  const [pagination, setPagination] = useState({
    pageCurrent: 1,
    dataLength: 10
  });
  const [modal, setModal] = useState({
    show: false,
    action: "",
    data: {}
  });
  const [filter, setFilter] = useState({
    id_jenis_jabatan: undefined
  });

  const getJenisJabatan = useQuery(["jenis-jabatan1", "dropdown"], () => KelompokJabatanApi.getJenisJabatan());

  const getKelompokJabatan = useQuery(
    ["kelompok-jabatan", "list", searchKey, filter, pagination.pageCurrent, pagination.dataLength],
    () =>
      KelompokJabatanApi.getList({
        jenis_jabatan: filter.id_jenis_jabatan,
        search: searchKey,
        page: pagination.pageCurrent,
        limit: pagination.dataLength
      })
  );

  const createKelompokJabatan = useMutation((data) => KelompokJabatanApi.create(data), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "create" });
      getKelompokJabatan.refetch();
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "create", ...errorSubmitMapper(err) })
  });

  const updateKelompokJabatan = useMutation(({ data, id }) => KelompokJabatanApi.update(data, id), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "update" });
      getKelompokJabatan.refetch();
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "update", ...errorSubmitMapper(err) })
  });

  const deleteKelompokJabatan = useMutation((id) => KelompokJabatanApi.delete(id), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "delete" });
      getKelompokJabatan.refetch();
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "delete", ...errorSubmitMapper(err) })
  });

  const getExportKelompokJabatan = useMutation((type) => KelompokJabatanApi.getExport({ to: type }), {
    onSuccess: (link) => {
      toastTrigger({ variant: "success", message: "Export berhasil" });
      exportHandler(link, "Data Kompetensi");
    },
    onError: () => {
      toastTrigger({ variant: "danger", message: "Export gagal" });
    }
  });

  const searchHandler = (e) => {
    setSearchKey(e?.target?.value ? e.target.value : undefined);
  };

  const onHideModalHandler = () => setModal({ show: false, action: "", data: {} });

  const onCreateButtonClickHandler = () => setModal({ show: true, action: "CREATE", data: {} });

  const onDetailButtonClickHandler = (data) => setModal({ show: true, action: "READ", data: data });

  const onUpdateButtonClickHandler = (data) => setModal({ show: true, action: "UPDATE", data: data });

  const onPaginationChange = (data) => setPagination({ ...pagination, pageCurrent: data });

  const onPaginationDataLengthChange = (data) => setPagination({ ...pagination, dataLength: data });

  const submitValidationHandler = (errors) =>
    new Promise((resolve, reject) => {
      const getError = Object.values(errors);

      if (getError.length > 0) {
        reject(getError);
      } else {
        resolve();
      }
    });

  const preSubmitHandler = (formik) => {
    const { values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting } = formik;

    setSubmitting(true);
    validateForm().then(async (err) => {
      setErrors(err);
      setTouched(err);

      await submitValidationHandler(err, values)
        .then(() => handleSubmit())
        .catch((err) =>
          modalConfirm.trigger({
            type: "error",
            title: "Data Tidak Lengkap",
            data: err,
            onHide: () => modalConfirm.close()
          })
        )
        .finally(() => {
          setSubmitting(false);
        });
    });
  };

  const TABLE_HEADER = [
    {
      text: "Jenis Jabatan",
      props: {
        minWidth: 150
      }
    },
    {
      text: "Kelompok Jabatan",
      props: {
        minWidth: 150
      }
    },
    {
      text: "Keterangan"
    },
    {
      text: "Aksi",
      props: {
        width: 50,
        fixed: true
      }
    }
  ];

  const TABLE_BODY = [
    {
      customField: (data) => jenisJabatanMapper(data.jenis_jabatan)
    },
    {
      field: "master_kelompok_jabatan_nama"
    },
    {
      field: "keterangan"
    },
    {
      props: { fixed: true },
      customField: (data) => (
        <ButtonGroup size="sm">
          {access.canRead() && <ButtonDetail icon noText onClick={() => onDetailButtonClickHandler(data)} />}
          {access.canUpdate() && <ButtonUpdate icon noText onClick={() => onUpdateButtonClickHandler(data)} />}
          {access.canDelete() && (
            <ButtonDelete icon noText onClick={() => formSubmitHandler(formInitialValues(data), "DELETE")} />
          )}
        </ButtonGroup>
      )
    }
  ];

  const formSubmitHandler = (values, action) =>
    modalConfirm.trigger({
      type: action.toLowerCase(),
      onHide: modalConfirm.close,
      onSubmit: () => {
        modalConfirm.loading();

        if (action === "UPDATE") {
          updateKelompokJabatan
            .mutateAsync({ data: formSubmitMapper(values), id: values.id })
            .then(() => onHideModalHandler());
        } else if (action === "DELETE") {
          deleteKelompokJabatan.mutate(values.id);
        } else {
          createKelompokJabatan.mutateAsync(formSubmitMapper(values)).then(() => onHideModalHandler());
        }
      },
      component: (
        <Formik initialValues={values}>
          <Content action="READ" />
        </Formik>
      )
    });

  return (
    <>
      <ListLayout
        customLeftTopSection={() => (
          <div className="d-flex align-items-end mb-3 ">
            <div style={{ width: "100%" }} className="me-2">
              <Select
                noMargin
                label="Jenis Jabatan"
                placeholder="Pilih jenis kompetensi"
                options={getJenisJabatan?.data ?? []}
                onChange={(val) => {
                  setFilter({
                    id_jenis_jabatan: val.value
                  });
                }}
              />
            </div>
            <div style={{ width: "100%" }} className="me-2">
              <InputSearch onChange={debounce(searchHandler, 1500)} />
            </div>
          </div>
        )}
        showExportExcelButton
        showExportPdfButton
        onExportExcelButtonClick={() => getExportKelompokJabatan.mutate("excel")}
        onExportPdfButtonClick={() => getExportKelompokJabatan.mutate("pdf")}
        loadingExportButton={getExportKelompokJabatan.isLoading}
        showRightTopButton={access.canCreate() ? true : false}
        loading={getKelompokJabatan.isFetching}
        error={getKelompokJabatan.isError}
        errorText={errorFetchingTableMapper(getKelompokJabatan.error)}
        dataTotal={getKelompokJabatan?.data?.total}
        dataLength={pagination.dataLength}
        pageCurrent={pagination.pageCurrent}
        pageTotal={getKelompokJabatan?.data?.last_page}
        onCreateButtonClick={onCreateButtonClickHandler}
        onDataLengthChange={onPaginationDataLengthChange}
        onPaginationChange={onPaginationChange}
        data={getKelompokJabatan?.data?.data}
        tableHeader={TABLE_HEADER}
        tableBody={TABLE_BODY}
      />

      {/**
       * Modal Detail:
       * Digunakan untuk melihat detail data
       */}
      <Formik enableReinitialize initialValues={formInitialValues(modal.data)}>
        <ModalDetail
          title={layout.getActivePageName()}
          show={Boolean(modal.show && modal.action === "READ")}
          onHide={onHideModalHandler}
        >
          <Content action="READ" />
        </ModalDetail>
      </Formik>

      {/**
       * Modal Create:
       * Digunakan untuk menambah data
       */}
      <Formik
        enableReinitialize
        initialValues={formInitialValues({})}
        validationSchema={formValidationSchema}
        onSubmit={(values) => formSubmitHandler(values, "CREATE")}
      >
        {(formik) => {
          return (
            <Form>
              <ModalCreate
                title={layout.getActivePageName()}
                show={Boolean(modal.show && modal.action === "CREATE")}
                onHide={() => {
                  onHideModalHandler();
                  formik.resetForm();
                }}
                onSubmit={() => preSubmitHandler(formik)}
              >
                <Content action="CREATE" />
              </ModalCreate>
            </Form>
          );
        }}
      </Formik>

      {/**
       * Modal Update:
       * Modal untuk mengubah data
       */}
      <Formik
        enableReinitialize
        initialValues={formInitialValues(modal.data)}
        validationSchema={formValidationSchema}
        onSubmit={(values) => formSubmitHandler(values, "UPDATE")}
      >
        {(formik) => {
          return (
            <Form>
              <ModalUpdate
                title={layout.getActivePageName()}
                show={Boolean(modal.show && modal.action === "UPDATE")}
                onHide={() => {
                  onHideModalHandler();
                  formik.resetForm();
                }}
                onSubmit={() => preSubmitHandler(formik)}
              >
                <Content action="UPDATE" />
              </ModalUpdate>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
