import React from "react";
import { useFormikContext } from "formik";
import { useQuery } from "react-query";
import { Select, InfoItemVertical } from "components";
import PekerjaShiftNonRegularApi from "../__PekerjaShiftNonRegularApi__";
import { errorFetchingSelectMapper } from "utilities";

export const Content = ({ action }) => {
    const { values, errors, touched, setValues } = useFormikContext();

    const getPekerja = useQuery(["pekerja-dropdown"], () => PekerjaShiftNonRegularApi.getPekerja());

    if (action === "READ") {
        return (
            <>
                <InfoItemVertical label="Pekerja" text={values.pekerjaNama} />
            </>
        );
    }
    return (
        <>
            <Select
                label="Pekerja"
                placeholder="Pilih salah satu"
                options={getPekerja.data}
                loading={getPekerja.isFetching}
                errorFetch={getPekerja.isError}
                errorFetchText={errorFetchingSelectMapper(getPekerja.error)}
                defaultValue={values.pekerjaId ? getPekerja?.data?.find((val) => val.value === values.pekerjaId) : []}
                onChange={(val) => {
                    setValues({
                        ...values,
                        pekerjaId: val.value,
                        pekerjaNama: val.label
                    });
                }}
                error={Boolean(errors.pekerjaId && touched.pekerjaId)}
                errorText={Boolean(errors.pekerjaId && touched.pekerjaId) && errors.pekerjaId}
            />
        </>
    );
};
