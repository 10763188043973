import { Services } from "services";

class RequestJadwalApi {
  async getList(params) {
    const fetch = await Services.get("/transaksi/request-jadwal", { params });
    return fetch?.data?.data;
  }

  async getDetail(id) {
    const fetch = await Services.get("/transaksi/request-jadwal/" + id);
    return fetch?.data?.data;
  }

  async getForm(params) {
    const fetch = await Services.get("/transaksi/request-jadwal/form", { params });
    return fetch?.data?.data;
  }

  async getPeriode() {
    const fetch = await Services.get("/master/periode-perencanaan");
    return fetch?.data?.data.map((val) => ({
      value: val?.master_periode_perencanaan_id,
      label: val?.periode_perencanaan
    }));
  }
  async getUnitOrganisasi() {
    const fetch = await Services.get("/master/unit-organisasi");
    return fetch?.data?.data.map((val) => ({
      value: val?.master_unit_organisasi_id,
      label: val?.master_unit_organisasi_nama
    }));
  }

  async getJabatan(params) {
    const fetch = await Services.get("/master/jabatan/dropdown", { params });
    return fetch?.data?.data.map((val) => ({
      value: val?.master_jabatan_id,
      label: val?.master_jabatan_nama
    }));
  }

  async getPekerja(params) {
    const fetch = await Services.get("/transaksi/pengajuan-cuti/dropdown/pekerja", { params });
    return fetch?.data?.data.map((val) => ({
      value: val?.pekerja_id,
      label: val?.pekerja_nama
    }));
  }

  async getBulan() {
    return Array.from({ length: 12 }, (item, i) => {
      return {
        value: i + 1,
        label: new Date(0, i).toLocaleString("id-ID", { month: "long" })
      };
    });
  }

  async getShift() {
    const fetch = await Services.get("/master/shift/dropdown");
    return fetch?.data?.data.map((val) => ({
      value: val?.master_shift_id,
      label: val?.master_shift_nama
    }));
  }

  async getLokasiKerja() {
    const fetch = await Services.get("/master/lokasi-kerja/dropdown");
    return fetch?.data?.data.map((val) => ({
      value: val?.master_lokasi_kerja_id,
      label: val?.lokasi_kerja
    }));
  }

  async getPeranPekerja() {
    const fetch = await Services.get("/master/peran-pekerja/dropdown");
    return fetch?.data?.data.map((val) => ({
      value: val?.master_peran_pekerja_id,
      label: val?.master_peran_pekerja_nama
    }));
  }

  async getExport(params) {
    const fetch = await Services.get("/transaksi/request-jadwal/export", { params });
    return fetch?.data?.data;
  }

  create(data) {
    return Services.post("/transaksi/request-jadwal", data);
  }

  update(data, id) {
    return Services.put("/transaksi/request-jadwal/" + id, data);
  }

  delete(id) {
    return Services.delete("/transaksi/request-jadwal/" + id);
  }
}

export default new RequestJadwalApi();
