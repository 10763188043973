import { Services } from "services";
import { fetchDropdownMapper } from "utilities";

const fetchDropdown = fetchDropdownMapper(Services);

class SetupGradeStepNominalTunjanganApi {
  async getList(params) {
    const fetch = await Services.get("/master/setup-grade-step-nominal", { params });
    return fetch?.data?.data;
  }
  async getReadSingle(id) {
    const fetch = await Services.get("/master/setup-grade-step-nominal/" + id);
    return fetch?.data?.data;
  }

  async getGrade() {
    const getGrade = await fetchDropdown("/master/grade/dropdown", {
      value: "master_grade_id",
      label: "grade"
    });

    return getGrade;
  }

  async getStep() {
    //Api-nya diubah
    const getStep = await fetchDropdown("/master/lokasi-kerja/dropdown", {
      value: "master_lokasi_kerja_id",
      label: "lokasi_kerja"
    });

    return getStep;
  }

  async getPeriodePenggajian() {
    const getPeriodePenggajian = await fetchDropdown("/master/grade-nominal/dropdown", {
      value: "periode_gajian_id",
      label: "periode_perencanaan"
    });

    return getPeriodePenggajian;
  }

  async getPeriodePenggajianContent() {
    const getPeriodePenggajianAlready = await fetchDropdown("/master/setup-grade-step-nominal", {
      value: "periode_gajian_id",
      label: "periode_perencanaan"
    });
    const getPeriodePenggajianNotYet = await fetchDropdown("/master/grade-nominal/dropdown/", {
      value: "periode_gajian_id",
      label: "periode_perencanaan"
    });

    function getDifference(arr1, arr2) {
      return arr1.filter((object1) => {
        return !arr2.some((object2) => {
          return object1.value === object2.value;
        });
      });
    }

    return getDifference(getPeriodePenggajianNotYet, getPeriodePenggajianAlready);
  }

  async getExport(params) {
    const fetch = await Services.get("/transaksi/lowongan-sdm/export", { params });
    return fetch?.data?.data;
  }

  create(data, id) {
    return Services.post("/master/setup-grade-step-nominal/batch/" + id, data);
  }

  update(data, id) {
    return Services.post("/master/setup-grade-step-nominal/batch/" + id, data);
  }
}
export default new SetupGradeStepNominalTunjanganApi();
