import { ButtonCreate, DatePicker, InfoItemVertical, Input, Select } from "components";
import { useFormikContext } from "formik";
import { Card, Col, Row } from "react-bootstrap";
import { useQuery } from "react-query";
import { dateConvert } from "utilities";
import PhkApi from "../__PhkApi__";
import { SectionPhk } from "../__PhkComps__";

export const Content = ({ action, getListForm, dataFilter, setDataFilter }) => {
  const { values, setValues } = useFormikContext();
  // const navigate = useNavigate();
  const date = dateConvert();

  // const getPeriode = useQuery(["periode-gajian", "dropdown"], PhkApi.getPeriodeGajian);
  const getUnitOrganisasi = useQuery(["unit-organisasi", "dropdown"], () => PhkApi.getUnitOrganisasi("UNFILTER"));
  const getInfoPekerja = useQuery(
    ["info-pekerja", "detail", values?.unitOrganisasiId],
    () =>
      PhkApi.getInfoPekerja({
        master_unit_organisasi_id: values?.unitOrganisasiId
      }),
    {
      enabled: Boolean(values?.unitOrganisasiId)
    }
  );

  if (action !== "READ") {
    return (
      <>
        <Row>
          {action === "CREATE" ? (
            <Col md={4} className="mb-4">
              <Card style={{ height: "100%" }}>
                <Card.Header>
                  <b>Setup Data</b>
                </Card.Header>
                <Card.Body>
                  <Input
                    label="Periode Penggajian"
                    placeholder="Masukkan periode"
                    value={values?.periodePenggajianId}
                    onChange={(val) =>
                      setValues({
                        ...values,
                        periodePenggajianId: val.target.value
                      })
                    }
                  />
                  <DatePicker
                    noMargin
                    label="Tanggal Setup"
                    placeholderText="Pilih tanggal"
                    selected={values?.tglSetup ? new Date(values.tglSetup) : ""}
                    onChange={(val) => setValues({ ...values, tglSetup: val })}
                  />
                  <Select
                    noMargin
                    label="Unit Organisasi"
                    placeholder="Pilih salah satu.."
                    loading={getUnitOrganisasi?.isFetching}
                    options={getUnitOrganisasi?.data ?? []}
                    onChange={(val) => setValues({ ...values, unitOrganisasiId: val?.value })}
                  />
                  <Select
                    noMargin
                    label="Pekerja"
                    placeholder="Pilih salah satu.."
                    loading={getInfoPekerja?.isFetching}
                    options={getInfoPekerja?.data ?? []}
                    onChange={(val) => setValues({ ...values, pekerjaId: val?.value })}
                  />
                  <div className="text-end mt-2">
                    <ButtonCreate
                      text="Cari"
                      tooltip={false}
                      disabled={
                        values?.periodePenggajianId === undefined ||
                        values?.tglSetup === undefined ||
                        values?.unitOrganisasiId === undefined ||
                        values?.pekerjaId === undefined
                      }
                      onClick={() => {
                        setDataFilter({
                          ...dataFilter,
                          periodePenggajianId: values?.periodePenggajianId,
                          tglSetup: values?.tglSetup,
                          unitOrganisasiId: values?.unitOrganisasiId,
                          pekerjaId: values?.pekerjaId
                        });
                      }}
                    />
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ) : (
            <></>
          )}
          <Col md={action === "CREATE" ? 8 : 12}>
            <SectionPhk action={action} />
          </Col>
        </Row>
      </>
    );
  }
  return (
    <>
      <Row>
        <Col md={4}>
          <Card style={{ height: "100%" }}>
            <Card.Header>
              <b>Data Pekerja</b>
            </Card.Header>
            <Card.Body>
              <InfoItemVertical label="Periode Penggajian" text={values?.periodePenggajian} />
              <InfoItemVertical
                label="Tanggal Setup"
                text={values?.tglSetup ? date.getSlashDMY(new Date(values?.tglSetup)) : "-"}
              />
              <InfoItemVertical label="Unit Organisasi" text={values?.unitOrganisasiNama} />
              <InfoItemVertical label="NIK" text={values?.nip} />
              <InfoItemVertical label="Pekerja" text={values?.pekerjaNama} />
              <InfoItemVertical label="Jabatan" text={values?.jabatanNama} />
              <InfoItemVertical
                label="Tanggal Pengangkatan"
                text={values?.tglPengangkatan ? date.getSlashDMY(new Date(values?.tglPengangkatan)) : "-"}
              />
            </Card.Body>
          </Card>
        </Col>
        <Col md={8}>
          <SectionPhk action={action} />
        </Col>
      </Row>
    </>
  );
};
