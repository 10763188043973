import { InfoItemVertical, Input, InputCurrency, numberConvert, TextArea } from "components";
import { useFormikContext } from "formik";
import { useQuery } from "react-query";
import SetupThrApi from "../__SetupPtkpApi__";

export const Content = ({ action }) => {
  const { values, errors, touched, handleChange, setValues } = useFormikContext();
  // const date = dateConvert();
  const number = numberConvert();
  const getStatusPajak = useQuery(["status-pajak", "dropdown"], () => SetupThrApi.getStatusPajak());

  if (action === "READ") {
    return (
      <>
        <InfoItemVertical label="Periode Penggajian" text={values.namaPeriode} />
        <InfoItemVertical label="Status Pajak" text={values.statusPajak} />
        <InfoItemVertical
          label="Nominal PTKP"
          text={values.nominalPtkp ? "Rp" + number.getWithComa(values?.nominalPtkp, { minimumFractionDigits: 2 }) : "-"}
        />
        <InfoItemVertical label="Keterangan" text={values.keterangan} />
      </>
    );
  }

  return (
    <>
      <Input
        label="Periode Penggajian"
        name="periodePenggajianId"
        placeholder="Masukkan periode penggajian"
        value={values.idPeriode ? String(values?.idPeriode) : "-"}
        onChange={(val) =>
          setValues({
            ...values,
            idPeriode: val.target.value,
            namaPeriode: val.target.value
          })
        }
        error={Boolean(errors.idPeriode && touched.idPeriode)}
        errorText={Boolean(errors.idPeriode && touched.idPeriode) && errors.idPeriode}
      />
      <Input
        label="Status Pajak"
        placeholder="Masukkan status"
        name="statusPajak"
        value={values?.statusPajak}
        loading={getStatusPajak.isLoading}
        onChange={(val) => {
          if (Boolean(getStatusPajak?.data?.find((item) => item?.value === val.target.value))) {
            setValues({
              ...values,
              statusPajakErr: false,
              statusPajak: val.target.value
            });
          } else {
            setValues({
              ...values,
              statusPajakErr: true,
              statusPajak: val.target.value
            });
          }
        }}
        error={Boolean(errors.statusPajak && touched.statusPajak) || errors.statusPajakErr}
        errorText={
          Boolean(errors.statusPajak && touched.statusPajak) && errors.statusPajak
            ? errors.statusPajak
            : errors.statusPajakErr
        }
      />
      <InputCurrency
        label="Nominal PTKP"
        placeholder="Masukkan nominal"
        name="nominalPtkp"
        value={values.nominalPtkp ? number.getWithComa(values?.nominalPtkp, { minimumFractionDigits: 2 }) : "-"}
        onChange={(val) =>
          setValues({
            ...values,
            nominalPtkp: val
          })
        }
        error={Boolean(errors.nominalPtkp && touched.nominalPtkp)}
        errorText={Boolean(errors.nominalPtkp && touched.nominalPtkp) && errors.nominalPtkp}
      />
      <TextArea
        label="Keterangan"
        name="keterangan"
        placeholder="Masukkan keterangan"
        value={values.keterangan}
        onChange={handleChange}
        error={Boolean(errors.keterangan && touched.keterangan)}
        errorText={Boolean(errors.keterangan && touched.keterangan) && errors.keterangan}
      />
    </>
  );
};
