import { KoreksiKerjaList, KoreksiKerjaCreate, KoreksiKerjaDetail } from "pages/Payroll";

export const KoreksiPekerja = {
  component: <KoreksiKerjaList />,
  link: "koreksi-pekerja",
  name: "Koreksi Pekerja",
  access: "PEKERJA_KOREKSI_BULANAN",
  child: [
    {
      name: "Tambah",
      link: "/tambah",
      component: <KoreksiKerjaCreate />
    },
    {
      name: "Detail",
      link: "/detail/:id",
      component: <KoreksiKerjaDetail />
    }
  ]
};
