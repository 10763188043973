import { Services } from "services";

class UraianJabatanApi {
  async getList(params) {
    const fetch = await Services.get("/transaksi/uraian-jabatan", { params });
    return fetch?.data?.data;
  }

  async getDetail(id, params) {
    const fetch = await Services.get("/transaksi/uraian-jabatan/" + id, { params });
    return fetch?.data?.data;
  }

  async getExport(params) {
    const fetch = await Services.get("/transaksi/uraian-jabatan/export", { params });
    return fetch?.data?.data;
  }

  getJenisJabatan() {
    return [
      { label: "Struktural", value: 1 },
      { label: "Fungsional", value: 2 }
    ];
  }

  async getJabatan(params) {
    const fetch = await Services.get("/master/jabatan", { params });
    return fetch?.data?.data.map((val) => ({
      value: val?.master_jabatan_id,
      label: val?.master_jabatan_nama,
      master_jabatan_kode: val.master_jabatan_kode,
      master_kelompok_jabatan_nama: val.master_kelompok_jabatan_nama,
      master_unit_organisasi_nama: val.master_unit_organisasi_nama,
      grade: val.grade,
      pangkat: val.pangkat,
      atasan_langsung_nama: val?.atasan_langsung_nama,
      bawahan_langsung: val?.bawahan_langsung,
      pemegang_jabatan: val?.pemegang_jabatan
    }));
  }

  async getJenisKompetensi(params) {
    const fetch = await Services.get("/master/kompetensi-jabatan/dropdown", { params });
    return fetch?.data?.data.map((val) => ({
      value: val?.master_kompetensi_jabatan_id,
      label: val?.kompetensi_jabatan_nama
    }));
  }

  async getSkala() {
    const fetch = await Services.get("/master/skala-kemahiran/dropdown");
    return fetch?.data?.data.map((val) => ({ value: val?.master_skala_kemahiran_id, label: val?.kemahiran_nama }));
  }

  async getJenjangPendidikan() {
    const fetch = await Services.get("/master/jenjang-pendidikan/dropdown");
    return fetch?.data?.data.map((val) => ({
      value: val?.master_jenjang_pendidikan_id,
      label: val?.master_jenjang_pendidikan_nama
    }));
  }

  async getSertifikatPelatihan() {
    const fetch = await Services.get("/master/sertifikat-pelatihan/dropdown");
    return fetch?.data?.data.map((val) => ({
      value: val?.master_sertifikat_pelatihan_id,
      label: val?.master_sertifikat_pelatihan_nama
    }));
  }
  async getSertifikatProfesi() {
    const fetch = await Services.get("/master/sertifikat-profesi/dropdown");
    return fetch?.data?.data.map((val) => ({
      value: val?.master_sertifikat_profesi_id,
      label: val?.master_sertifikat_profesi_nama
    }));
  }

  async getKondisiKerja() {
    const fetch = await Services.get("/master/kondisi-kerja/dropdown");
    return fetch?.data?.data.map((val) => ({
      master_kondisi_kerja_id: val?.master_kondisi_kerja_id,
      master_kondisi_kerja_nama: val?.master_kondisi_kerja_nama
    }));
  }

  show(id) {
    return Services.put(`/master/agama/${id}/active`);
  }

  hide(id) {
    return Services.put(`/master/agama/${id}/inactive`);
  }

  create(data) {
    return Services.post("/transaksi/uraian-jabatan", data);
  }

  update(data, id) {
    return Services.put("/transaksi/uraian-jabatan/" + id, data);
  }

  delete(id) {
    return Services.delete("/transaksi/uraian-jabatan/" + id);
  }
}

export default new UraianJabatanApi();
