import { Services } from "services";
class PerspektifKPIApi {
  async getList(params) {
    const fetch = await Services.get("/master/perspektif-kpi", { params });
    return fetch?.data?.data;
  }

  async getExport(params) {
    const fetch = await Services.get("/master/perspektif-kpi/export", { params });
    return fetch?.data?.data;
  }

  create(data) {
    return Services.post("/master/perspektif-kpi", data);
  }

  update(data, id) {
    return Services.put("/master/perspektif-kpi/" + id, data);
  }

  delete(id) {
    return Services.delete("/master/perspektif-kpi/" + id);
  }
}

export default new PerspektifKPIApi();
