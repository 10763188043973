import { UploadPengirimanBetonList, UploadPengirimanBetonCreate } from "pages/Payroll";

export const UploadPengirimanBeton = {
    component: <UploadPengirimanBetonList />,
    link: "upload-pengiriman-beton",
    name: "Upload Data Pengiriman Beton",
    access: "PENGIRIMAN_BETON",
    child: [
        {
            name: "Tambah",
            link: "/tambah",
            component: <UploadPengirimanBetonCreate />
        }
    ]
};
