import React from "react";
import { useFormikContext } from "formik";
import { Input, InfoItemVertical, Select, InputCurrency } from "components";
import { useQuery } from "react-query";
import SetupNominalUpahMinimumBPJSApi from "../__SetupNominalUpahMinimumBPJSApi__";
import { numberConvert } from "utilities";

export const Content = ({ action }) => {
  const { values, errors, touched, handleChange, setValues } = useFormikContext();
  const number = numberConvert();
  const getJenisUpahMinimum = useQuery(["jenis-upah", "dropdown"], SetupNominalUpahMinimumBPJSApi.getJenisUpahMinimum);

  if (action === "READ") {
    return (
      <>
        <InfoItemVertical
          label="Periode Penggajian"
          text={values?.periodePenggajianNama ? values?.periodePenggajianNama : values?.periodePenggajianId ?? "-"}
        />
        <InfoItemVertical
          label="Jenis Upah Minimum"
          text={values?.jenisUpahMinimumNama ? values?.jenisUpahMinimumNama : values?.jenisUpahMinimumId ?? "-"}
        />
        <InfoItemVertical
          label="Nominal"
          text={
            values?.nominal
              ? "Rp" + number.getWithComa(values?.nominal, { minimumFractionDigits: 2 })
              : "-"
          }
        />
      </>
    );
  }

  return (
    <>
      <Input
        disabled
        label="Periode Penggajian"
        value={values?.periodePenggajianNama}
        onChange={handleChange}
      />
      <Select
        label="Jenis Upah Minimum"
        placeholder="Pilih jenis upah minimum"
        defaultValue={getJenisUpahMinimum?.data?.find((item) => item.value === values.jenisUpahMinimumId)}
        options={getJenisUpahMinimum?.data ?? []}
        onChange={(val) => setValues({ ...values, jenisUpahMinimumId: val.value, jenisUpahMinimumNama: val.label })}
        loading={getJenisUpahMinimum.isFetching}
        errorFetch={getJenisUpahMinimum.isError}
        error={Boolean(errors.jenisUpahMinimumId && touched.jenisUpahMinimumId)}
        errorText={Boolean(errors.jenisUpahMinimumId && touched.jenisUpahMinimumId) && errors.jenisUpahMinimumId}
      />
      <InputCurrency
        label="Nominal"
        name="nominal"
        placeholder="Masukkan nominal"
        value={values?.nominal}
        onChange={(val) => setValues({ ...values, nominal: val })}
        error={Boolean(errors.nominal && touched.nominal)}
        errorText={Boolean(errors.nominal && touched.nominal) && errors.nominal}
      />
    </>
  );
};
