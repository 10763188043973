export const formSubmitValueMapper = (data) =>
  data?.detail.map((val) => ({
    jaminan_bpjs_id: val?.jaminanBPJSId,
    periode_tahun: val?.periodeGajianId,
    tgl_setup: val?.tglSetup,
    tgl_bulan_awal: val?.tglAwal,
    tgl_bulan_akhir: val?.tglAkhir,
    master_unit_organisasi_id: val?.unitOrganisasiId,
    pekerja_id: val?.pekerjaId,
    pekerja_nama: val?.pekerjaNama,
    master_jabatan_id: val?.jabatanId,
    upah_bpjs: val?.upahBpjs,
    besaran_upah: val?.besaranUpah,
    iuran_perusahaan: val?.iuranPerusahaan,
    iuran_karyawan: val?.iuranKaryawan,
    jenis: val?.jenis
  }));
