import { useQuery, useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { Formik } from "formik";
import { ContentLayout, ButtonBack, ButtonUpdate, DataStatus } from "components";
import { useModalConfirm } from "hooks";
import { Content } from "./__UraianKPIComps__";
import { formInitialValues, formValidationSchema, formSubmitMapper } from "./__UraianKPIUtils__";

import UraianKPIApi from "./__UraianKPIApi__";

export const UraianKPIUpdate = () => {
  const navigate = useNavigate();
  const modalConfirm = useModalConfirm();
  const { id } = useParams();

  const getUraianKPI = useQuery(["uraian-kpi", id], () => UraianKPIApi.getReadSingle(id));

  const updateUraianKPI = useMutation((data) => UraianKPIApi.update(data, id));

  const submitHandler = async (values, { setSubmitting, resetForm }) => {
    const finalValue = formSubmitMapper({ data: values });

    modalConfirm.trigger({
      size: "lg",
      type: "update",
      component: (
        <Formik enableReinitialize initialValues={values}>
          {() => (
            <>
              <Content action="READ" />
            </>
          )}
        </Formik>
      ),
      onSubmit: async () => {
        modalConfirm.loading();

        await updateUraianKPI
          .mutateAsync(finalValue, id)
          .then(() => {
            modalConfirm.infoSuccess({
              typeInfo: "success",
              onHide: modalConfirm.close
            });
          })
          .catch(() => {
            modalConfirm.infoError({ typeInfo: "error" });
          })
          .finally(() => setSubmitting(false));
      },
      onHide: modalConfirm.close
    });
  };

  const submitValidationHandler = async (errors, values) =>
    new Promise(async (resolve, reject) => {
      const getError = Object.values(errors);

      if (values?.target?.length < 1) {
        getError.push("Data target tidak boleh kosong");
      }

      if (values?.nama?.length < 1) {
        getError.push("Data nama tidak boleh kosong");
      }

      if (getError.length > 0) {
        reject(getError);
      } else {
        resolve();
      }
    });

  return (
    <ContentLayout showBreadcrumb>
      {/* Top Button */}
      <div className="d-flex justify-content-end">
        <ButtonBack tooltip={false} className="mb-3" onClick={() => navigate(-1)} />
      </div>

      {/* Form */}
      {getUraianKPI.isFetching || getUraianKPI.isError ? (
        <DataStatus loading={getUraianKPI.isFetching} text="Data gagal dimuat" />
      ) : (
        <Formik
          enableReinitialize
          initialValues={formInitialValues({ data: getUraianKPI?.data })}
          validationSchema={formValidationSchema}
          onSubmit={(values, setSubmitting, resetForm) => {
            submitHandler(values, { setSubmitting, resetForm });
          }}
        >
          {({ values, isSubmitting, setSubmitting, handleSubmit, validateForm, setTouched, setErrors }) => (
            <>
              <Content action="UPDATE" />
              <div className="d-flex justify-content-end">
                <ButtonUpdate
                  tooltip={false}
                  className="mb-3 mt-4"
                  text="Ubah"
                  onClick={async () => {
                    setSubmitting(true);

                    validateForm().then(async (err) => {
                      setErrors(err);
                      setTouched(err);

                      await submitValidationHandler(err, values)
                        .then(() => handleSubmit())
                        .catch((err) =>
                          modalConfirm.trigger({
                            type: "error",
                            data: err,
                            onHide: () => modalConfirm.close()
                          })
                        )
                        .finally(() => {
                          setSubmitting(false);
                        });
                    });
                  }}
                  loading={isSubmitting}
                />
              </div>
            </>
          )}
        </Formik>
      )}
    </ContentLayout>
  );
};
