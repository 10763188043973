import {
  ButtonFilter, ButtonUpdate, dateConvert,
  DatePicker, InputSearch, ModalFilter, numberConvert, Select, toastTrigger
} from "components";
import { Formik, useFormikContext } from "formik";
import { useAccessRights } from "hooks";
import { ListLayout } from "layouts";
import { debounce } from "lodash";
import { useState } from "react";
import { ButtonGroup } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { exportHandler } from "utilities";
import SuratPerjalananDinasKeluarApi from "./__SuratPerjalananDinasKeluarApi__";

const ModalFilterBody = () => {
  const { values, setValues } = useFormikContext();

  const getUnitOrganisasi = useQuery(["unit-organisasi-for-list", "dropdown"], () =>
    SuratPerjalananDinasKeluarApi.getUnitOrganisasiForList()
  );

  return (
    <>
      <div>
        <div className="d-flex align-items-center justify-content-between">
          <div style={{ width: "44%" }}>
            <DatePicker
              label="Tanggal Awal"
              placeholderText="Masukkan tgl. awal"
              selected={values?.tglAwal}
              onChange={(date) => setValues({
                ...values,
                tglAwal: new Date(date)
              })}
              selectsStart
              startDate={values?.tglAwal}
              endDate={values?.tglAkhir}
            />
          </div>
          <small className="mt-3">s/d</small>
          <div style={{ width: "44%" }}>
            <DatePicker
              label="Tanggal Akhir"
              placeholderText="Masukkan tgl. akhir"
              selected={values?.tglAkhir}
              onChange={(date) => setValues({
                ...values,
                tglAkhir: new Date(date)
              })}
              selectsEnd
              startDate={values?.tglAwal}
              endDate={values?.tglAkhir}
              minDate={values?.tglAkhir}
            />
          </div>
        </div>
      </div>
      <Select
        label="Unit Organisasi"
        placeholder="Pilih unit organisasi"
        defaultValue={getUnitOrganisasi?.data?.find((item) => item.value === values.unitOrganisasiId)}
        options={getUnitOrganisasi?.data ?? []}
        onChange={(val) => setValues({ ...values, unitOrganisasiId: val.value })}
        loading={getUnitOrganisasi.isFetching}
        errorFetch={getUnitOrganisasi.isError}
      />
    </>
  );
};

export const SuratPerjalananDinasKeluarList = () => {
  const access = useAccessRights("SURAT_PERJALANAN_DINAS");
  const navigate = useNavigate();
  // const modalConfirm = useModalConfirm();
  const date = dateConvert();
  const number = numberConvert();

  const [searchKey, setSearchKey] = useState(undefined);
  const [pagination, setPagination] = useState({
    pageCurrent: 1,
    dataLength: 10
  });

  const [filter, setFilter] = useState({
    show: false,
    active: false,
    data: {
      periodeGajianId: undefined,
      unitOrganisasiId: undefined,
      tglAwal: undefined,
      tglAkhir: undefined
    }
  });

  const getSuratPerjalananDinasKeluarList = useQuery(
    [
      "surat-perjalanan-dinas-keluar",
      "list",
      searchKey,
      pagination.pageCurrent,
      pagination.dataLength,
      filter.data?.tglAwal,
      filter.data?.tglAkhir,
      filter.data?.unitOrganisasiId
    ],
    () =>
      SuratPerjalananDinasKeluarApi.getList({
        search: searchKey,
        page: pagination.pageCurrent,
        limit: pagination.dataLength,
        tgl_awal_filter: filter.data?.tglAwal,
        tgl_akhir_filter: filter.data?.tglAkhir,
        master_unit_organisasi_id: filter.data?.unitOrganisasiId
      }),
    {
      enabled: Boolean(filter?.data?.tglAwal && filter?.data?.tglAkhir)
    }
  );

  // const deleteSuratPerjalananDinasKeluar = useMutation((id) => SuratPerjalananDinasKeluarApi.delete(id), {
  //   onSuccess: () => {
  //     modalConfirm.infoSuccess({ typeInfo: "delete" });
  //     getSuratPerjalananDinasKeluarList.refetch();
  //   },
  //   onError: (err) => modalConfirm.infoError({ typeInfo: "delete", ...errorSubmitMapper(err) })
  // });

  const getSuratPerjalananDinasKeluarExport = useMutation(
    (data) =>
      SuratPerjalananDinasKeluarApi.getExport({
        master_unit_organisasi_id: data?.unitOrganisasiId,
        tgl_awal_filter: data?.tglAwal,
        tgl_akhir_filter: data?.tglAkhir
      }),
    {
      onSuccess: (link) => {
        toastTrigger({ variant: "success", message: "Export berhasil" });
        exportHandler(link, "Data Surat Perjalanan Dinas Keluar");
      },
      onError: () => {
        toastTrigger({ variant: "danger", message: "Export gagal" });
      }
    }
  );

  const onCreateButtonClickHandler = () => navigate("tambah");
  const onUpdateButtonClickHandler = (data) => navigate("ubah/" + data?.surat_perjalanan_dinas_id);
  // const onDetailButtonClickHandler = (data) => navigate("detail/" + data?.surat_perjalanan_dinas_id);
  const onPaginationChange = (data) => setPagination({ ...pagination, pageCurrent: data });

  const onPaginationDataLengthChange = (data) => setPagination({ ...pagination, dataLength: data });

  const searchHandler = (e) => {
    setSearchKey(e?.target?.value ? e.target.value : undefined);
  };

  // const formSubmitHandler = (values, action) =>
  //   modalConfirm.trigger({
  //     type: action.toLowerCase(),
  //     onHide: modalConfirm.close,
  //     onSubmit: () => {
  //       modalConfirm.loading();
  //       deleteSuratPerjalananDinasKeluar.mutate(values.id);
  //     },
  //     component: <Formik initialValues={values}>{/* <Content action="READ" /> */}</Formik>
  //   });

  const TABLE_HEADER = [
    {
      text: "Tanggal Input"
    },
    {
      text: "NIK"
    },
    {
      text: "Pekerja"
    },
    {
      text: "Unit Organisasi"
    },
    {
      text: "Jabatan"
    },
    {
      text: "No. Surat"
    },
    {
      text: "Tujuan"
    },
    {
      text: "Tanggal Mulai"
    },
    {
      text: "Tanggal Akhir"
    },
    {
      text: "Lama Dinas"
    },
    {
      text: "Aksi",
      props: {
        fixed: true
      }
    }
  ];

  const TABLE_BODY = [
    { customField: (val) => date.getSlashDMY(new Date(val.tgl_input)) },
    { field: "nip" },
    { field: "pekerja_nama" },
    { field: "master_unit_organisasi_nama" },
    { field: "master_jabatan_nama" },
    { field: "nomor" },
    { field: "tujuan" },
    { customField: (val) => date.getSlashDMY(new Date(val.tgl_mulai)) },
    { customField: (val) => date.getSlashDMY(new Date(val.tgl_akhir)) },
    {
      customField: (val) => {
        const dateDiff = new Date(val?.tgl_akhir).getTime() - new Date(val?.tgl_mulai).getTime();
        const dayDiff = Math.round(dateDiff / (1000 * 3600 * 24)) + 1;

        return number.getWithComa(dayDiff, { minimumFractionDigits: 0 }) + " Hari";
      }
    },
    {
      props: {
        width: 50,
        fixed: true,
        textCenter: true
      },
      customField: (data) => (
        <ButtonGroup size="sm">
          {/* {access.canRead() && (
                        <ButtonDetail icon tooltip={false} noText onClick={() => onDetailButtonClickHandler(data)} />
                    )} */}
          {access.canUpdate() && (
            <ButtonUpdate tooltip={false} icon noText onClick={() => onUpdateButtonClickHandler(data)} />
          )}
          {/* {access.canDelete() && (
                        <ButtonDelete
                            tooltip={false}
                            icon
                            noText
                            onClick={() => formSubmitHandler(formInitialValues(data), "DELETE")}
                        />
                    )} */}
        </ButtonGroup>
      )
    }
  ];

  return (
    <>
      <ListLayout
        customLeftTopSection={() => (
          <>
            <div className="d-flex align-items-end mb-3">
              <div style={{ width: 300 }} className="me-2">
                <InputSearch onChange={debounce(searchHandler, 1500)} />
              </div>
              <div>
                <ButtonFilter
                  active={filter?.active}
                  className="text-nowrap"
                  onClick={() => setFilter({ ...filter, show: true })}
                />
              </div>
            </div>
          </>
        )}
        showRightTopButton={access.canCreate() ? true : false}
        showExportExcelButton={Boolean(filter?.data?.tglAwal && filter?.data?.tglAkhir)}
        onExportExcelButtonClick={() => getSuratPerjalananDinasKeluarExport.mutate(filter?.data)}
        loadingExportButton={getSuratPerjalananDinasKeluarExport.isLoading}
        loading={getSuratPerjalananDinasKeluarList?.isFetching}
        error={getSuratPerjalananDinasKeluarList?.isError}
        dataTotal={getSuratPerjalananDinasKeluarList?.data?.total}
        dataLength={pagination.dataLength}
        pageCurrent={pagination.pageCurrent}
        pageTotal={getSuratPerjalananDinasKeluarList?.data?.last_page}
        onSearchChange={debounce(searchHandler, 1500)}
        onCreateButtonClick={onCreateButtonClickHandler}
        onDataLengthChange={onPaginationDataLengthChange}
        onPaginationChange={onPaginationChange}
        data={getSuratPerjalananDinasKeluarList?.data?.data}
        tableHeader={TABLE_HEADER}
        tableBody={TABLE_BODY}
      />

      {/* ModalFilter */}
      <Formik
        initialValues={{
          unitOrganisasiId: filter?.data?.unitOrganisasiId,
          tglAwal: filter?.data?.tglAwal,
          tglAkhir: filter?.data?.tglAkhir
        }}
      >
        {({ resetForm, values }) => (
          <ModalFilter
            show={filter.show}
            onReset={resetForm}
            onHide={() =>
              setFilter({
                ...filter,
                show: false
              })
            }
            onSubmit={() => {
              setFilter({
                show: false,
                active: Boolean(Object.values(values).find((val) => val)),
                data: values
              });
            }}
          >
            <ModalFilterBody />
          </ModalFilter>
        )}
      </Formik>
    </>
  );
};
