import { PhkList, PhkCreate, PhkUpdate, PhkDetail } from "pages/OprasionalSdm";

export const Phk = {
  name: "PHK",
  link: "phk",
  access: "PEKERJA_PHK",
  component: <PhkList />,
  child: [
    {
      name: "Tambah",
      link: "/tambah",
      component: <PhkCreate />
    },
    {
      name: "Ubah",
      link: "/ubah/:id",
      component: <PhkUpdate />
    },
    {
      name: "Detail",
      link: "/detail/:id",
      component: <PhkDetail />
    }
  ]
};
