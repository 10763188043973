import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { ContentLayout, ButtonBack, ButtonCreate } from "components";
import { useModalConfirm } from "hooks";
import { Content } from "./__UraianKPIComps__";
import { formInitialValues, formValidationSchema, formSubmitMapper } from "./__UraianKPIUtils__";

import UraianKPIApi from "./__UraianKPIApi__";

export const UraianKPICreate = () => {
  const navigate = useNavigate();
  const modalConfirm = useModalConfirm();

  const createUraianKPI = useMutation((data) => UraianKPIApi.create(data));

  const submitHandler = async (values, { resetForm, setSubmitting }) => {
    const finalValue = formSubmitMapper({ data: values });

    modalConfirm.trigger({
      size: "lg",
      type: "create",
      component: (
        <Formik enableReinitialize initialValues={values}>
          {() => (
            <>
              <Content action="READ" />
            </>
          )}
        </Formik>
      ),
      onSubmit: async () => {
        modalConfirm.loading();

        await createUraianKPI
          .mutateAsync(finalValue)
          .then(() => {
            modalConfirm.infoSuccess({
              typeInfo: "success",
              onHide: () => {
                modalConfirm.close();
              }
            });
            resetForm();
          })
          .catch(() => {
            modalConfirm.infoError({ typeInfo: "error" });
          })
          .finally(() => setSubmitting(false));
      },
      onHide: () => {
        modalConfirm.close();
      }
    });
  };

  const submitValidationHandler = async (errors, values) =>
    new Promise(async (resolve, reject) => {
      const getError = Object.values(errors);

      if (values?.target?.length < 1) {
        getError.push("Data target tidak boleh kosong");
      }

      if (values?.nama?.length < 1) {
        getError.push("Data nama tidak boleh kosong");
      }

      if (getError.length > 0) {
        reject(getError);
      } else {
        resolve();
      }
    });

  return (
    <ContentLayout showBreadcrumb>
      {/* Top Button */}
      <div className="d-flex justify-content-end">
        <ButtonBack tooltip={false} className="mb-3" onClick={() => navigate(-1)} />
      </div>

      {/* Form */}
      <Formik
        enableReinitialize
        initialValues={formInitialValues({})}
        validationSchema={formValidationSchema}
        onSubmit={submitHandler}
      >
        {({ values, isSubmitting, setSubmitting, handleSubmit, validateForm, setTouched, setErrors }) => (
          <>
            <Content action="CREATE" />
            <div className="d-flex justify-content-end">
              <ButtonCreate
                tooltip={false}
                className="mb-3 mt-4"
                text="Simpan"
                onClick={async () => {
                  setSubmitting(true);

                  validateForm().then(async (err) => {
                    setErrors(err);
                    setTouched(err);

                    await submitValidationHandler(err, values)
                      .then(() => handleSubmit())
                      .catch((err) =>
                        modalConfirm.trigger({
                          type: "error",
                          data: err,
                          onHide: () => modalConfirm.close()
                        })
                      )
                      .finally(() => {
                        setSubmitting(false);
                      });
                  });
                }}
                loading={isSubmitting}
              />
            </div>
          </>
        )}
      </Formik>
    </ContentLayout>
  );
};
