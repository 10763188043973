import { InfoItemVertical, Input, Select, TextArea } from "components";
import { useFormikContext } from "formik";
import { useQuery } from "react-query";
import { errorFetchingSelectMapper } from "utilities";
import CustomerApi from "../__CustomerApi__";

export const Content = ({ action }) => {
    const { values, errors, touched, setValues, handleChange, setFieldValue } = useFormikContext();
    // const number = numberConvert();

    // const getJenisRitase = useQuery(["jenis-ritase", "dropdown"], () => UpahRitaseApi.getJenisRitase());
    const getProvinsi = useQuery(["wilayah", "dropdown"], () => CustomerApi.getWilayah("provinsi"));
    // const getProvinsi1 = useQuery(["wilayah", "dropdown"], () => CustomerApi.getWilayah("provinsi"));
    const getKabupaten = useQuery(
        ["kabupaten", "dropdown", values.provinsiKode],
        () => CustomerApi.getWilayah("kabupaten", { provinsi_kode: values.provinsiKode }),
        {
            enabled: !!values.provinsiKode
        }
    );

    const getKecamatan = useQuery(
        ["kecamatan", "dropdown", values.kabupatenKode],
        () => CustomerApi.getWilayah("kecamatan", { kabupaten_kode: values.kabupatenKode }),
        {
            enabled: !!values.kabupatenKode
        }
    );

    const getDesa = useQuery(
        ["desa", "dropdown", values.kecamatanKode],
        () => CustomerApi.getWilayah("desa", { kecamatan_kode: values.kecamatanKode }),
        {
            enabled: !!values.kecamatanKode
        }
    );


    if (action === "READ") {
        return (
            <>
                <InfoItemVertical label="Nama Customer" text={values.nama} />
                <InfoItemVertical label="Contact Person" text={values.contactPerson} />
                <InfoItemVertical label="Hp" text={values.hp} />
                <InfoItemVertical label="Provinsi" text={values.provinsiNama} />
                <InfoItemVertical label="Kabupaten/Kota" text={values?.kabupatenNama} />
                <InfoItemVertical label="Kecamatan" text={values?.kecamatanNama} />
                <InfoItemVertical label="Desa" text={values?.desaNama} />
                <InfoItemVertical label="Alamat" text={values?.alamat} />
            </>
        );
    }

    return (
        <>
            <Input
                name="nama"
                placeholder="Masukkan nama customer"
                label="Nama Customer"
                value={values?.nama}
                onChange={handleChange}
                error={Boolean(errors.nama && touched.nama)}
                errorText={Boolean(errors.nama && touched.nama) && errors.nama}
            />
            <Input
                name="contactPerson"
                placeholder="Masukkan contact person"
                label="Contact Person"
                value={values?.contactPerson}
                onChange={handleChange}
                error={Boolean(errors.contactPerson && touched.contactPerson)}
                errorText={Boolean(errors.contactPerson && touched.contactPerson) && errors.contactPerson}
            />
            <Input
                name="hp"
                placeholder="Masukkan nomor hp"
                label="HP"
                value={values?.hp}
                onChange={handleChange}
                error={Boolean(errors.hp && touched.hp)}
                errorText={Boolean(errors.hp && touched.hp) && errors.hp}
            />
            <Select
                label="Provinsi"
                placeholder="Pilih Provinsi"
                loading={getProvinsi.isLoading}
                options={getProvinsi.data ?? []}
                defaultValue={getProvinsi.data?.find(
                    (item) => item?.value?.toString() === values?.provinsiKode?.toString()
                )}
                onChange={(val) => {
                    setValues({
                        ...values,
                        provinsiKode: val?.value,
                        provinsiNama: val?.label,
                        kabupatenKode: "",
                        kabupatenNama: "",
                        kecamatanKode: "",
                        kecamatanNama: "",
                        desaKode: "",
                        desaNama: ""
                    });
                }}
                error={Boolean(errors.provinsiKode && touched.provinsiKode)}
                errorText={Boolean(errors.provinsiKode && touched.provinsiKode) && errors.provinsiKode}
                errorFetch={getProvinsi.isError}
                errorFetchText={errorFetchingSelectMapper(getProvinsi.error)}
            />

            <Select
                disable={!values.provinsiKode}
                label="Kabupaten"
                placeholder="Pilih kabupaten"
                loading={getKabupaten.isLoading}
                options={getKabupaten?.data ?? []}
                defaultValue={getKabupaten?.data?.find(
                    (item) => item?.value?.toString() === values?.kabupatenKode?.toString()
                )}
                onChange={(val) => {
                    setFieldValue("kabupatenKode", val?.value);
                    setValues({
                        ...values,
                        kabupatenKode: val?.value,
                        kabupatenNama: val?.label,
                        kecamatanKode: "",
                        kecamatanNama: "",
                        desaKode: "",
                        desaNama: ""
                    });
                }}
                error={Boolean(errors.kabupatenKode && touched.kabupatenKode)}
                errorText={Boolean(errors.kabupatenKode && touched.kabupatenKode) && errors.kabupatenKode}
                errorFetch={getKabupaten.isError}
                errorFetchText={errorFetchingSelectMapper(getKabupaten.error)}
            />

            <Select
                disable={!values.kabupatenKode}
                label="Kecamatan"
                placeholder="Pilih kecamatan"
                loading={getKecamatan.isLoading}
                options={getKecamatan?.data ?? []}
                defaultValue={getKecamatan?.data?.find(
                    (item) => item?.value?.toString() === values?.kecamatanKode?.toString()
                )}
                onChange={(val) => {
                    setValues({
                        ...values,
                        kecamatanKode: val?.value,
                        kecamatanNama: val?.label,
                        desaKode: "",
                        desaNama: ""
                    });
                }}
                error={Boolean(errors.kecamatanKode && touched.kecamatanKode)}
                errorText={Boolean(errors.kecamatanKode && touched.kecamatanKode) && errors.kecamatanKode}
                errorFetch={getKecamatan.isError}
                errorFetchText={errorFetchingSelectMapper(getKecamatan.error)}
            />

            <Select
                disable={!values.kecamatanKode}
                label="Desa"
                placeholder="Pilih desa"
                loading={getDesa.isLoading}
                options={getDesa?.data ?? []}
                defaultValue={getDesa?.data?.find((item) => item?.value?.toString() === values?.desaKode?.toString())}
                onChange={(val) => {
                    setValues({
                        ...values,
                        desaKode: val?.value,
                        desaNama: val?.label
                    });
                }}
                error={Boolean(errors.desaKode && touched.desaKode)}
                errorText={Boolean(errors.desaKode && touched.desaKode) && errors.desaKode}
                errorFetch={getDesa.isError}
                errorFetchText={errorFetchingSelectMapper(getDesa.error)}
            />
            <TextArea
                label="Alamat"
                name="alamat"
                placeholder="Masukan alamat"
                value={values?.alamat}
                onChange={(val) => setValues({
                    ...values,
                    alamat: val.target.value
                })}
                error={Boolean(errors.alamat && touched.alamat)}
                errorText={Boolean(errors.alamat && touched.alamat) && errors.alamat}
            />
        </>
    );
};
