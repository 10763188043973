import { Services } from "services";
import { fetchDropdownMapper } from "utilities";
const fetchDropdown = fetchDropdownMapper(Services);

class SasaranKPIApi {
  async getList(params) {
    const fetch = await Services.get("/master/sasaran-kpi", { params });
    return fetch?.data?.data;
  }

  async getExport(params) {
    const fetch = await Services.get("/master/sasaran-kpi/export", { params });
    return fetch?.data?.data;
  }

  async getUnitOrganisasiDropdown() {
    const getDropdown = await fetchDropdown("/master/unit-organisasi/dropdown", {
      value: "master_unit_organisasi_id",
      label: "master_unit_organisasi_nama"
    });
    return [{ value: undefined, label: "Semua" }, ...getDropdown];
  }

  getUnitOrganisasi() {
    return fetchDropdown("/master/unit-organisasi", {
      value: "master_unit_organisasi_id",
      label: "master_unit_organisasi_nama"
    });
  }

  create(data) {
    return Services.post("/master/sasaran-kpi", data);
  }

  update(data, id) {
    return Services.put("/master/sasaran-kpi/" + id, data);
  }

  delete(id) {
    return Services.delete("/master/sasaran-kpi/" + id);
  }
}

export default new SasaranKPIApi();
