import * as Yup from "yup";

export const formValidationSchema = Yup.object().shape({
  nama: Yup.string().required("Nama catering wajib diisi"),
  provinsiId: Yup.string().required("Provinsi wajib diisi"),
  kabupatenKotaId: Yup.string().required("Kabupaten/Kote wajib diisi"),
  kecamatanId: Yup.string().required("Kecamatan wajib diisi"),
  desaKelurahanId: Yup.string().required("Desa/Kelurahan wajib diisi"),
  rt: Yup.string().required("RT wajib diisi"),
  rw: Yup.string().required("RW wajib diisi"),
  alamat: Yup.string().required("Alamat wajib diisi"),
  keterangan: Yup.string().required("Keterangan wajib diisi")
});
