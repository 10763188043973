import { Services } from "services";
import { fetchDropdownMapper } from "utilities";
const fetchDropdown = fetchDropdownMapper(Services);

class StepApi {
  async getList(params) {
    const fetch = await Services.get("/master/step", { params });
    return fetch?.data?.data;
  }

  async getExport(params) {
    const fetch = await Services.get("/master/step/export", { params });
    return fetch?.data?.data;
  }

  getGrade() {
    return fetchDropdown("/master/grade", {
      value: "master_grade_id",
      label: "grade"
    });
  }

  getStepDropdown() {
    return Array.from({ length: 11 }).map((_, index) => ({
      label: index,
      value: index
    }));
  }

  create(data) {
    return Services.post("/master/step", data);
  }

  update(data, id) {
    return Services.put("/master/step/" + id, data);
  }

  delete(id) {
    return Services.delete("/master/step/" + id);
  }
}

export default new StepApi();
