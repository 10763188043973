import * as Yup from "yup";

export const formValidationSchema = {
  formFilter: Yup.object().shape({
    periodePerencanaanId: Yup.string().required("Periode perencanaan wajib dipilih"),
    bulan: Yup.string().required("Bulan penjadwalan wajib di pilih"),
    idUnitOrganisasi: Yup.string().required("Unit organisasi wajib di pilih"),
    idPekerja: Yup.string().required("Pekerja wajib di pilih")
  }),
  formKegiatan: Yup.object().shape({
    idKegiatan: Yup.string().required("Kegiatan wajib dipilih"),
    idSubKegiatan: Yup.string().required("Sub kegiatan wajib di pilih"),
    idStatusKegiatan: Yup.string().required("Status kegiatan wajib di pilih"),
    keterangan: Yup.string().required("Keteranganja wajib di isi")
  })
};
