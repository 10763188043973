export const formSubmitValueMapper = (data) => ({
  array: data?.detail
    ?.filter((obj) => {
      return obj.checked;
    })
    .map((val) => ({
      periode_tahun: val?.periodeGajianId,
      pekerja_id: val?.pekerjaId,
      master_unit_organisasi_id: val?.unitOrganisasiId,
      nominal: val?.nominal,
      tgl_setup: val?.tglSetup
    })),
  object: {
    periode_tahun: data?.periodeGajianId,
    tgl_setup: data?.tglSetup,
    pekerja_id: data?.pekerjaId,
    master_unit_organisasi_id: data?.unitOrganisasiId,
    nominal: data?.nominal,
    status: data?.status
  }
});
