import { useState } from "react";
import { ButtonGroup } from "react-bootstrap";
import { useQuery, useMutation } from "react-query";
import { Form, Formik } from "formik";
import { debounce } from "lodash";
import { ListLayout } from "layouts";
import {
  ButtonDetail,
  ButtonUpdate,
  ButtonDelete,
  ModalDetail,
  ModalCreate,
  ModalUpdate,
  toastTrigger
} from "components";
import { useLayout, useModalConfirm, useAccessRights } from "hooks";
import { errorFetchingTableMapper, errorSubmitMapper, exportHandler } from "utilities";
import { formInitialValues, formValidationSchema, formSubmitMapper } from "./__AgamaUtils__";
import AgamaApi from "./__AgamaApi__";
import { Content } from "./_AgamaComps__";

export const AgamaList = () => {
  const layout = useLayout();
  const access = useAccessRights("MASTER_AGAMA");
  const modalConfirm = useModalConfirm();
  const [searchKey, setSearchKey] = useState(undefined);
  const [pagination, setPagination] = useState({
    pageCurrent: 1,
    dataLength: 10
  });
  const [modal, setModal] = useState({
    show: false,
    action: "",
    data: {}
  });

  const getAgama = useQuery(["agama", "list", searchKey, pagination.pageCurrent, pagination.dataLength], () =>
    AgamaApi.getList({
      search: searchKey,
      page: pagination.pageCurrent,
      limit: pagination.dataLength
    })
  );

  const createAgama = useMutation((data) => AgamaApi.create(data), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "create" });
      getAgama.refetch();
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "create", ...errorSubmitMapper(err) })
  });

  const updateAgama = useMutation(({ data, id }) => AgamaApi.update(data, id), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "update" });
      getAgama.refetch();
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "update", ...errorSubmitMapper(err) })
  });

  const deleteAgama = useMutation((id) => AgamaApi.delete(id), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "delete" });
      getAgama.refetch();
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "delete", ...errorSubmitMapper(err) })
  });

  const getExportAgama = useMutation((type) => AgamaApi.getExport({ to: type }), {
    onSuccess: (link) => {
      toastTrigger({ variant: "success", message: "Export berhasil" });
      exportHandler(link, "Data Agama");
    },
    onError: () => {
      toastTrigger({ variant: "danger", message: "Export gagal" });
    }
  });

  const searchHandler = (e) => {
    setSearchKey(e?.target?.value ? e.target.value : undefined);
  };

  const onHideModalHandler = () => setModal({ show: false, action: "", data: {} });

  const onCreateButtonClickHandler = () => setModal({ show: true, action: "CREATE", data: {} });

  const onDetailButtonClickHandler = (data) => setModal({ show: true, action: "READ", data: data });

  const onUpdateButtonClickHandler = (data) => setModal({ show: true, action: "UPDATE", data: data });

  const onPaginationChange = (data) => setPagination({ ...pagination, pageCurrent: data });

  const onPaginationDataLengthChange = (data) => setPagination({ ...pagination, dataLength: data });

  const submitValidationHandler = (errors) =>
    new Promise((resolve, reject) => {
      const getError = Object.values(errors);

      if (getError.length > 0) {
        reject(getError);
      } else {
        resolve();
      }
    });

  const preSubmitHandler = (formik) => {
    const { values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting } = formik;

    setSubmitting(true);
    validateForm().then(async (err) => {
      setErrors(err);
      setTouched(err);

      await submitValidationHandler(err, values)
        .then(() => handleSubmit())
        .catch((err) =>
          modalConfirm.trigger({
            type: "error",
            title: "Data Tidak Lengkap",
            data: err,
            onHide: () => modalConfirm.close()
          })
        )
        .finally(() => {
          setSubmitting(false);
        });
    });
  };

  const formSubmitHandler = (values, action) =>
    modalConfirm.trigger({
      type: action.toLowerCase(),
      onHide: modalConfirm.close,
      onSubmit: () => {
        modalConfirm.loading();

        if (action === "UPDATE") {
          updateAgama.mutateAsync({ data: formSubmitMapper(values), id: values.id }).then(() => onHideModalHandler());
        } else if (action === "DELETE") {
          deleteAgama.mutate(values.id);
        } else {
          createAgama.mutateAsync(formSubmitMapper(values)).then(() => onHideModalHandler());
        }
      },
      component: (
        <Formik initialValues={values}>
          <Content action="READ" />
        </Formik>
      )
    });

  return (
    <>
      <ListLayout
        showExportExcelButton
        showExportPdfButton
        onExportExcelButtonClick={() => getExportAgama.mutate("excel")}
        onExportPdfButtonClick={() => getExportAgama.mutate("pdf")}
        loadingExportButton={getExportAgama.isLoading}
        showRightTopButton={access.canCreate() ? true : false}
        loading={getAgama.isFetching}
        error={getAgama.isError}
        errorText={errorFetchingTableMapper(getAgama.error)}
        dataTotal={getAgama?.data?.total}
        dataLength={pagination.dataLength}
        pageCurrent={pagination.pageCurrent}
        pageTotal={getAgama?.data?.last_page}
        onSearchChange={debounce(searchHandler, 1500)}
        onCreateButtonClick={onCreateButtonClickHandler}
        onDataLengthChange={onPaginationDataLengthChange}
        onPaginationChange={onPaginationChange}
        data={getAgama?.data?.data}
        tableHeader={[
          {
            text: "Agama",
            props: {
              minWidth: 200
            }
          },
          {
            text: "Aksi",
            props: {
              width: 50,
              fixed: true
            }
          }
        ]}
        tableBody={[
          {
            field: "agama"
          },
          {
            props: { fixed: true },
            customField: (data) => (
              <ButtonGroup size="sm">
                {access.canRead() && <ButtonDetail icon noText onClick={() => onDetailButtonClickHandler(data)} />}
                {access.canUpdate() && <ButtonUpdate icon noText onClick={() => onUpdateButtonClickHandler(data)} />}
                {access.canDelete() && (
                  <ButtonDelete icon noText onClick={() => formSubmitHandler(formInitialValues(data), "DELETE")} />
                )}
              </ButtonGroup>
            )
          }
        ]}
      />

      <Formik enableReinitialize initialValues={formInitialValues(modal.data)}>
        <ModalDetail
          title={layout.getActivePageName()}
          show={Boolean(modal.show && modal.action === "READ")}
          onHide={onHideModalHandler}
        >
          <Content action="READ" />
        </ModalDetail>
      </Formik>

      <Formik
        enableReinitialize
        initialValues={formInitialValues({})}
        validationSchema={formValidationSchema}
        onSubmit={(values) => formSubmitHandler(values, "CREATE")}
      >
        {(formik) => {
          return (
            <Form>
              <ModalCreate
                title={layout.getActivePageName()}
                show={Boolean(modal.show && modal.action === "CREATE")}
                onHide={() => {
                  onHideModalHandler();
                  formik.resetForm();
                }}
                onSubmit={() => preSubmitHandler(formik)}
              >
                <Content action="CREATE" />
              </ModalCreate>
            </Form>
          );
        }}
      </Formik>

      <Formik
        enableReinitialize
        initialValues={formInitialValues(modal.data)}
        validationSchema={formValidationSchema}
        onSubmit={(values) => formSubmitHandler(values, "UPDATE")}
      >
        {(formik) => {
          return (
            <Form>
              <ModalUpdate
                title={layout.getActivePageName()}
                show={Boolean(modal.show && modal.action === "UPDATE")}
                onHide={() => {
                  onHideModalHandler();
                  formik.resetForm();
                }}
                onSubmit={() => preSubmitHandler(formik)}
              >
                <Content action="UPDATE" />
              </ModalUpdate>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
