import * as Yup from "yup";

export const formValidationSchema = Yup.object().shape({
    periodeTahun: Yup.string().required("Periode gajian wajib diisi"),
    tglNota: Yup.string().required("Tgl. nota wajib diisi"),
    noNota: Yup.string().required("No. nota wajib diisi"),
    customerId: Yup.string().required("Pemakai wajib diisi"),
    proyekId: Yup.string().required("Proyek wajib diisi"),
    // proyekAlamat: Yup.string().required("Alamat wajib diisi"),

    jamMasuk: Yup.string().required("Masuk jam proyek wajib diisi"),
    jamMulai: Yup.string().required("Mulai dipakai jam wajib diisi"),
    jamSelesai: Yup.string().required("Selesai jam wajib diisi"),
    jamKeluar: Yup.string().required("Keluar jam proyek wajib diisi"),
    totalJam: Yup.string().required("Total jam pemakaian wajib diisi"),

    sambunganPipa: Yup.string().required("Panjang sambugan pipa wajib diisi"),
    volume: Yup.string().required("Volume wajib diisi"),
    kmAwal: Yup.string().required("Km awal wajib diisi"),
    kmAkhir: Yup.string().required("Km akhir wajib diisi"),
    // totalKm: Yup.string().required("Total km wajib diisi"),
});
