import { ButtonBack, ButtonCreate } from "components";
import { Formik } from "formik";
import { useModalConfirm } from "hooks";
import { ContentLayout } from "layouts";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { errorSubmitMapper } from "utilities";
import PhkApi from "./__PhkApi__";
import { Content } from "./__PhkComps__";
import { formInitialValues, formSubmitMapper } from "./__PhkUtils__";

export const PhkCreate = () => {
  const navigate = useNavigate();
  const modalConfirm = useModalConfirm();

  // const [searchingData, setSearchingData] = useState({
  //   unitOrganisasiId: undefined,
  //   periodePenggajianId: undefined,
  //   pekerjaId: undefined,
  //   tglSetup: undefined,
  //   statusEnable: false
  // });
  const [dataFilter, setDataFilter] = useState({
    unitOrganisasiId: undefined,
    periodePenggajianId: undefined,
    pekerjaId: undefined,
    tglSetup: undefined,
    statusEnable: false
  });

  // const getUnitOrganisasi = useQuery(["unit-organisasi", "dropdown"], () => PhkApi.getUnitOrganisasi("UNFILTER"));

  // const getPeriode = useQuery(["periode-gajian", "dropdown"], PhkApi.getPeriodeGajian);

  const getInfoPekerja = useQuery(
    [
      "info-pekerja",
      "detail",
      dataFilter?.unitOrganisasiId,
      dataFilter?.periodePenggajianId,
      dataFilter?.tglSetup,
      dataFilter?.pekerjaId
    ],
    () =>
      PhkApi.getForm({
        master_unit_organisasi_id: dataFilter?.unitOrganisasiId,
        periode_tahun: dataFilter?.periodePenggajianId,
        tgl_setup: dataFilter?.tglSetup,
        pekerja_id: dataFilter?.pekerjaId
      }),
    {
      enabled: Boolean(
        dataFilter?.unitOrganisasiId && dataFilter?.periodePenggajianId && dataFilter?.tglSetup && dataFilter?.pekerjaId
      )
    }
  );
  const createData = useMutation((data) => PhkApi.create(data), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "create" });
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "create", ...errorSubmitMapper(err) })
  });

  const submitValidationHandler = (errors) =>
    new Promise((resolve, reject) => {
      const t = Object.values(errors)
        .flat()
        .filter((v) => v !== undefined)
        .map((v) => Object.values(v))
        .flat();
      const getError = Array.from(new Set(t));

      if (getError.length > 0) {
        reject(getError);
      } else {
        resolve();
      }
    });

  const preSubmitHandler = (values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting) => {
    setSubmitting(true);
    validateForm().then(async (err) => {
      setErrors(err);
      setTouched(err);

      await submitValidationHandler(err, values)
        .then(() => handleSubmit())
        .catch((err) =>
          modalConfirm.trigger({
            type: "error",
            title: "Data Tidak Lengkap",
            data: err,
            onHide: () => modalConfirm.close()
          })
        )
        .finally(() => {
          setSubmitting(false);
        });
    });
  };

  const formSubmitHandler = async (values) => {
    return await modalConfirm.trigger({
      type: "create",
      size: "md",
      customButtonText: "Simpan",
      onHide: modalConfirm.close,
      onSubmit: () => {
        modalConfirm.loading();
        createData.mutateAsync(formSubmitMapper(values)).then(() => navigate("/operasional-sdm/transaksi/phk"));
      },
      component: (
        <>
          <Formik initialValues={formInitialValues(values, getInfoPekerja?.data)}>
            {/* <Content action="CREATE_CONFIRM" /> */}
          </Formik>
        </>
      )
    });
  };

  return (
    <>
      <ContentLayout showBreadcrumb>
        <>
          <div className="text-end mb-3">
            <ButtonBack tooltip={false} onClick={() => navigate("/operasional-sdm/transaksi/phk")} />
          </div>
          <Formik
            enableReinitialize
            initialValues={formInitialValues(getInfoPekerja?.data)}
            onSubmit={(values) => formSubmitHandler(values)}
          >
            {({ values, handleSubmit, validateForm, setTouched, setErrors, setSubmitting }) => (
              <>
                <Content action="CREATE" dataFilter={dataFilter} setDataFilter={setDataFilter} />
                <div className="text-end mt-3 mb-2">
                  <ButtonCreate
                    text="Simpan"
                    tooltip={false}
                    onClick={() =>
                      preSubmitHandler(values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting)
                    }
                    disabled={
                      values?.periodePenggajianId === undefined ||
                      values?.tglSetup === undefined ||
                      values?.pekerjaId === undefined
                    }
                    className="px-4"
                  />
                </div>
              </>
            )}
          </Formik>
        </>
      </ContentLayout>
    </>
  );
};
