export const formSubmitValueMapper = {
    detailData: (data) => ({
        periode_tahun: data?.periodeGajianId,
        tgl_setup: data?.tglSetup,
        tgl_bulan_awal: data?.tglAwal,
        tgl_bulan_akhir: data?.tglAkhir,
        pekerja_id: data?.pekerjaId,
        master_unit_organisasi_id: data?.unitOrganisasiId,
        detail: data?.detail?.map((val) => ({
            tgl_presensi: val?.tglPresensi,
            scan_in: val?.scanIn,
            scan_out: val?.scanOut,
            jam_in: val?.jamIn,
            jam_out: val?.jamOut,
            total_waktu_kerja: val?.totalWaktuKerja,
            setting_upah_harian: val?.settingUpahHarian,
            nilai_upah_harian: val?.upahHarian
        }))
    }),

    modalData: (data) => ({
        pekerja_lembur_perjam_bulanan_id: data?.id,
        komponen_payroll: data?.komponenPayroll,
        periode_tahun: data?.periodeGajianId,
        tgl_bulan_awal: data?.tglAwal,
        tgl_bulan_akhir: data?.tglAkhir,
        tgl_setup: data?.tglSetup,
        pekerja_id: data?.pekerjaId,
        master_unit_organisasi_id: data?.unitOrganisasiId,
        total_upah_harian: data?.totalUpahHarian,
    }),

    quickPostData: (data) => ({
        periode_tahun: data?.periodeGajianId,
        tgl_bulan_awal: data?.tglAwal,
        tgl_bulan_akhir: data?.tglAkhir,
    })
};
