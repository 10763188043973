import React from "react";
import { useFormikContext } from "formik";
import { Input, InfoItemVertical, rupiahConvert, InputCurrency } from "components";

export const Content = ({ action }) => {
    const { values, errors, touched, handleChange, setValues } = useFormikContext();
    const rupiah = rupiahConvert();

    if (action === "READ") {
        return (
            <>
                <InfoItemVertical label="Periode Gajian" text={values.periodeTahun} />
                <InfoItemVertical label="Upah Ritase CP" text={values.nominal ? rupiah.getWithComa(values?.nominal) : "-"} />
            </>
        );
    }

    return (
        <>
            <Input
                label="Periode Tahun"
                name="periodeTahun"
                placeholder="Masukkan periode tahun"
                value={values.periodeTahun}
                onChange={handleChange}
                error={Boolean(errors.periodeTahun && touched.periodeTahun)}
                errorText={Boolean(errors.periodeTahun && touched.periodeTahun) && errors.periodeTahun}
            />
            <InputCurrency
                label="Upah Ritase CP"
                name="nominal"
                placeholder="Masukkan upah ritase CP"
                value={rupiah.getWithComa(values.nominal)}
                onChange={(val) => setValues({
                    ...values,
                    nominal: val
                })}
                error={Boolean(errors.nominal && touched.nominal)}
                errorText={Boolean(errors.nominal && touched.nominal) && errors.nominal}
            />
        </>
    );
};
