import {
  ButtonDelete, ButtonDetail, ButtonFilter, ButtonUpdate, InputSearch, ModalDetail, ModalFilter, Select, toastTrigger
} from "components";
import { Formik, useFormikContext } from "formik";
import { useAccessRights, useLayout, useModalConfirm } from "hooks";
import { ListLayout } from "layouts";
import { debounce } from "lodash";
import { useState } from "react";
import { ButtonGroup } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { dateConvert, errorFetchingTableMapper, errorSubmitMapper, exportHandler } from "utilities";
import PhkApi from "./__PhkApi__";
import { Content } from "./__PhkComps__";
import { formInitialValues } from "./__PhkUtils__";
const ModalFilterBody = () => {
  const { values, setValues } = useFormikContext();

  const getPeriode = useQuery(["periode-penggajian", "dropdown"], () => PhkApi.getPeriodeGajian());

  const getUnitOrganisasi = useQuery(
    ["unit-organisasi-for-list", "dropdown", values?.periodeGajianId],
    () =>
      PhkApi.getUnitOrganisasiForList({
        periode_tahun: values?.periodeGajianId
      }),
    { enabled: Boolean(values?.periodeGajianId) }
  );

  return (
    <>
      <Select
        label="Periode Penggajian"
        placeholder="Pilih periode penggajian"
        defaultValue={getPeriode?.data?.find((item) => item.value === values?.periodeGajianId)}
        options={getPeriode?.data ?? []}
        onChange={(val) => setValues({ ...values, periodeGajianId: val.value, periodePerencanaan: val.label })}
        loading={getPeriode.isFetching}
        errorFetch={getPeriode.isError}
      />
      <Select
        disable={Boolean(!values?.periodeGajianId)}
        label="Unit Organisasi"
        placeholder="Pilih unit organisasi"
        defaultValue={getUnitOrganisasi?.data?.find((item) => item.value === values.unitOrganisasiId)}
        options={getUnitOrganisasi?.data ?? []}
        onChange={(val) => setValues({ ...values, unitOrganisasiId: val.value })}
        loading={getUnitOrganisasi.isFetching}
        errorFetch={getUnitOrganisasi.isError}
      />
    </>
  );
};

export const PhkList = () => {
  const layout = useLayout();
  const access = useAccessRights("PEKERJA_PHK");
  const navigate = useNavigate();
  const modalConfirm = useModalConfirm();

  // const number = numberConvert();
  const date = dateConvert();

  const [searchKey, setSearchKey] = useState(undefined);
  const [pagination, setPagination] = useState({
    pageCurrent: 1,
    dataLength: 10
  });
  const [modal, setModal] = useState({
    show: false,
    action: "",
    data: {}
  });

  const [filter, setFilter] = useState({
    show: false,
    active: false,
    data: {
      periodeGajianId: undefined,
      unitOrganisasiId: undefined,
      tglAwal: undefined,
      tglAkhir: undefined
    }
  });

  const getList = useQuery(
    [
      "setup-insentif-produktivitas",
      "list",
      searchKey,
      filter.data?.periodeGajianId,
      filter.data?.unitOrganisasiId,
      pagination.pageCurrent,
      pagination.dataLength
    ],
    () =>
      PhkApi.getList({
        periode_tahun: filter.data?.periodeGajianId,
        master_unit_organisasi_id: filter.data?.unitOrganisasiId,
        search: searchKey,
        page: pagination.pageCurrent,
        limit: pagination.dataLength
      })
  );

  const getDetailPhk = useQuery(
    ["detail-pekerja-phk", "detail", modal],
    () => PhkApi.getSingle(modal?.data?.pekerja_phk_id),
    {
      enabled: Boolean(modal?.data?.pekerja_phk_id)
    }
  );

  const deletePhk = useMutation((id) => PhkApi.delete(id), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "delete" });
      getList.refetch();
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "delete", ...errorSubmitMapper(err) })
  });

  const exportPhk = useMutation(
    (data) =>
      PhkApi.getExport({
        periode_tahun: data?.periodeGajianId,
        master_unit_organisasi_id: data?.unitOrganisasiId
      }),
    {
      onSuccess: (link) => {
        toastTrigger({ variant: "success", message: "Export berhasil" });
        exportHandler(link, "Data Input PHK");
      },
      onError: () => {
        toastTrigger({ variant: "danger", message: "Export gagal" });
      }
    }
  );

  const searchHandler = (e) => {
    setSearchKey(e?.target?.value ? e.target.value : undefined);
  };
  const onHideModalHandler = () => setModal({ show: false, action: "", data: {} });

  const onCreateButtonClickHandler = () => navigate("tambah");

  const onDetailButtonClickHandler = (data) => setModal({ show: true, action: "READ", data: data });

  const onUpdateButtonClickHandler = (data) => navigate("ubah/" + data?.pekerja_phk_id);

  const onPaginationChange = (data) => setPagination({ ...pagination, pageCurrent: data });

  const onPaginationDataLengthChange = (data) => setPagination({ ...pagination, dataLength: data });

  const onDeleteButtonClickHandler = (values, action) => {
    setModal({ data: values });
    modalConfirm.trigger({
      type: "delete",
      size: "lg",
      alert: "Data akan dihapus secara permanen dan tidak dapat dikembalikan",
      onHide: modalConfirm.close,
      onSubmit: () => {
        modalConfirm.loading();
        if (action === "READ") {
          deletePhk.mutate(values?.pekerja_phk_id);
        }
      },
      component: (
        <Formik initialValues={formInitialValues(getDetailPhk?.data)}>
          <Content action={action} loading={getDetailPhk?.isFetching} />
        </Formik>
      )
    });
  };

  return (
    <>
      <ListLayout
        customLeftTopSection={() => (
          <>
            <div className="d-flex align-items-end mb-3">
              <div style={{ width: 300 }} className="me-2">
                <InputSearch onChange={debounce(searchHandler, 1500)} />
              </div>
              <div>
                <ButtonFilter
                  active={filter?.active}
                  className="text-nowrap"
                  onClick={() => setFilter({ ...filter, show: true })}
                />
              </div>
            </div>
          </>
        )}
        showRightTopButton={access.canCreate() ? true : false}
        showExportExcelButton={Boolean(filter?.data?.periodeGajianId)}
        // showExportPdfButton
        onExportExcelButtonClick={() => exportPhk.mutate(filter?.data)}
        // onExportPdfButtonClick={() => exportPhk.mutate("pdf")}
        loadingExportButton={exportPhk.isLoading}
        loading={getList.isFetching}
        error={getList.isError}
        errorText={errorFetchingTableMapper(getList.error)}
        dataTotal={getList?.data?.total}
        dataLength={pagination.dataLength}
        pageCurrent={pagination.pageCurrent}
        pageTotal={getList?.data?.last_page}
        onSearchChange={debounce(searchHandler, 1500)}
        onCreateButtonClick={onCreateButtonClickHandler}
        onDataLengthChange={onPaginationDataLengthChange}
        onPaginationChange={onPaginationChange}
        data={getList?.data?.data}
        tableHeader={[
          {
            text: "NIK"
          },
          {
            text: "Pekerja"
          },
          {
            text: "Jabatan"
          },
          {
            text: "Unit Organisasi"
          },
          {
            text: "Tgl Pemberhentian"
          },
          {
            text: "Masa Kerja"
          },
          {
            text: "Alasan PHK"
          },
          {
            text: "Aksi",
            props: { width: 50, fixed: true }
          }
        ]}
        tableBody={[
          {
            field: "nip"
          },
          {
            field: "pekerja_nama"
          },
          {
            field: "master_jabatan_nama"
          },
          {
            field: "master_unit_organisasi_nama"
          },
          { customField: (val) => (val?.tgl_pemberhentian ? date.getSlashDMY(new Date(val?.tgl_pemberhentian)) : "-") },

          {
            props: { textCenter: true, minWidth: 200 },
            customField: (val) => {
              const jumlahHari = val?.masa_kerja;

              const tahun = Math.floor(jumlahHari / 365.25);
              const sisaTahun = jumlahHari % 365.25;
              const bulan = Math.floor(sisaTahun / 30.44);
              const sisaBulan = sisaTahun % 30.44;
              const hari = Math.round(sisaBulan);

              return tahun + " Tahun " + bulan + " Bulan " + hari + " Hari";
            }
          },

          {
            field: "alasan_phk"
          },
          {
            props: { fixed: true },
            customField: (data) => (
              <ButtonGroup size="sm">
                {access.canRead() && <ButtonDetail icon noText onClick={() => onDetailButtonClickHandler(data)} />}
                {access.canUpdate() && <ButtonUpdate icon noText onClick={() => onUpdateButtonClickHandler(data)} />}
                {access.canDelete() && (
                  <ButtonDelete icon tooltip={false} noText onClick={() => onDeleteButtonClickHandler(data, "READ")} />
                )}
              </ButtonGroup>
            )
          }
        ]}
      />
      {/**
       * Modal Detail:
       * Digunakan untuk melihat detail data
       */}
      <Formik enableReinitialize initialValues={formInitialValues(getDetailPhk?.data)}>
        <ModalDetail
          size="lg"
          title={layout.getActivePageName()}
          show={Boolean(modal.show && modal.action === "READ")}
          onHide={onHideModalHandler}
        >
          <Content action="READ" loading={getDetailPhk?.isFetching} />
        </ModalDetail>
      </Formik>

      {/* ModalFilter */}
      <Formik
        initialValues={{
          unitOrganisasiId: filter?.data?.unitOrganisasiId,
          periodeGajianId: filter?.data?.periodeGajianId
        }}
      >
        {({ resetForm, values }) => (
          <ModalFilter
            show={filter.show}
            onReset={resetForm}
            onHide={() =>
              setFilter({
                ...filter,
                show: false
              })
            }
            onSubmit={() => {
              setFilter({
                show: false,
                active: Boolean(Object.values(values).find((val) => val)),
                data: values
              });
            }}
          >
            <ModalFilterBody />
          </ModalFilter>
        )}
      </Formik>
    </>
  );
};
