import React from "react";
import { useFormikContext } from "formik";
import { useQuery } from "react-query";
import {
  Input,
  InfoItemVertical,
  TextArea,
  Select,
  InputNumber,
  dateConvert,
  rupiahConvert,
  DatePicker,
  numberConvert
} from "components";
import { errorFetchingSelectMapper } from "utilities";
import SetupJaminanBpjsApi from "../__SetupJaminanBpjsApi__";

export const Content = ({ action }) => {
  const { values, errors, touched, handleChange, setValues } = useFormikContext();
  const date = dateConvert();
  const rupiah = rupiahConvert();
  const number = numberConvert();

  const getJenisUpahMinimum = useQuery(["jenis-upah-minimum", "dropdown"], () =>
    SetupJaminanBpjsApi.getJenisUpahMinimum()
  );

  useQuery(
    ["batas-upah-minimum", "dropdown", values.idJenisUpahMinimum, values.idPeriode],
    () =>
      SetupJaminanBpjsApi.getUpahBatasMinimum({
        jenis_upah_id: values.idJenisUpahMinimum,
        periode_gajian_id: values.idPeriode
      }),
    {
      enabled: values.idJenisUpahMinimum !== undefined && values.idPeriode !== undefined,
      onSuccess: (data) => {
        setValues({
          ...values,
          batasBawahUpah: data?.nominal ? Number(data?.nominal) : undefined,
          idNominal: data?.nominal_upah_minimum_id ?? undefined
        });
      }
    }
  );

  const getKomponenBpjs = useQuery(["komponen-bpjs", "dropdown"], () => SetupJaminanBpjsApi.getKomponenBpjs());

  if (action === "READ") {
    return (
      <>
        <InfoItemVertical label="Periode Penggajian" text={values?.namaPeriode} />
        <InfoItemVertical label="Tanggal Setup" text={date.getSlashDMY(new Date(values?.tglSetup))} />
        <InfoItemVertical label="Jenis Upah" text={values?.namaJenisUpahMinimum} />
        <InfoItemVertical label="Komponen BPJS" text={values?.namaKomponenBpjs} />
        <InfoItemVertical
          label="Proporsi Perusahaan"
          text={
            values?.proporsiPerusahaan
              ? number.getWithComa(values?.proporsiPerusahaan, { minimumFractionDigits: 2, maximumFractionDigits: 4 }) + "%"
              : "-"
          }
        />
        <InfoItemVertical
          label="Proporsi Pekerja"
          text={
            values?.proporsiPekerja
              ? number.getWithComa(values?.proporsiPekerja, { minimumFractionDigits: 2, maximumFractionDigits: 4 }) + "%"
              : "-"
          }
        />
        <InfoItemVertical
          label="Batas Bawah Upah"
          text={values?.batasBawahUpah ? rupiah.getWithComa(values?.batasBawahUpah, { minimumFractionDigits: 2 }) : "-"}
        />
        <InfoItemVertical
          label="Batas Atas Upah"
          text={values?.batasAtasUpah ? rupiah.getWithComa(values?.batasAtasUpah, { minimumFractionDigits: 2 }) : "-"}
        />
        <InfoItemVertical label="Keterangan" text={values?.keterangan} />
      </>
    );
  }

  return (
    <>
      <div>
        <Input
          label="Periode Penggajian"
          name="idPeriode"
          placeholder="Masukkan periode penggajian"
          value={values.idPeriode ? String(values?.idPeriode) : "-"}
          onChange={(val) =>
            setValues({
              ...values,
              idPeriode: val.target.value,
              namaPeriode: val.target.value
            })
          }
          error={Boolean(errors.idPeriode && touched.idPeriode)}
          errorText={Boolean(errors.idPeriode && touched.idPeriode) && errors.idPeriode}
        />
        <DatePicker
          label="Tanggal Setup"
          placeholderText="dd/mm/yy"
          selected={values.tglSetup ? new Date(values.tglSetup) : undefined}
          onChange={(val) => setValues({ ...values, tglSetup: val })}
          error={Boolean(errors.tglSetup && touched.tglSetup)}
          errorText={Boolean(errors.tglSetup && touched.tglSetup) && errors.tglSetup}
        />
        <Select
          label="Jenis Upah Minimum"
          placeholder="Pilih upah minimum"
          defaultValue={getJenisUpahMinimum?.data?.find((item) => item.value === values?.idJenisUpahMinimum)}
          options={getJenisUpahMinimum?.data ?? []}
          onChange={(val) =>
            setValues({
              ...values,
              idJenisUpahMinimum: val.value,
              namaJenisUpahMinimum: val.label
            })
          }
          loading={getJenisUpahMinimum.isLoading}
          error={Boolean(errors.idJenisUpahMinimum && touched.idJenisUpahMinimum)}
          errorText={Boolean(errors.idJenisUpahMinimum && touched.idJenisUpahMinimum) && errors.idJenisUpahMinimum}
          errorFetch={getJenisUpahMinimum.isError}
          errorFetchText={errorFetchingSelectMapper(getJenisUpahMinimum.error)}
        />
        <Select
          label="Komponen BPJS"
          placeholder="Pilih komponen bpjs"
          defaultValue={getKomponenBpjs?.data?.find((item) => item.value === values.idKomponenBpjs)}
          options={getKomponenBpjs?.data ?? []}
          onChange={(val) =>
            setValues({
              ...values,
              idKomponenBpjs: val.value,
              namaKomponenBpjs: val.label
            })
          }
          loading={getKomponenBpjs.isLoading}
          error={Boolean(errors.idKomponenBpjs && touched.idKomponenBpjs)}
          errorText={Boolean(errors.idKomponenBpjs && touched.idKomponenBpjs) && errors.idKomponenBpjs}
          errorFetch={getKomponenBpjs.isError}
          errorFetchText={errorFetchingSelectMapper(getKomponenBpjs.error)}
        />
        <InputNumber
          label="Proporsi Perusahaan"
          suffix="%"
          type="text"
          decimalScale={4}
          placeholder="Masukkan proporsi"
          name="proporsiPerusahaan"
          value={values?.proporsiPerusahaan}
          onChange={(val) => setValues({ ...values, proporsiPerusahaan: val })}
          error={Boolean(errors.proporsiPerusahaan && touched.proporsiPerusahaan)}
          errorText={Boolean(errors.proporsiPerusahaan && touched.proporsiPerusahaan) && errors.proporsiPerusahaan}
        />
        <InputNumber
          label="Proporsi Pekerja"
          suffix="%"
          type="text"
          decimalScale={4}
          placeholder="Masukkan proporsi"
          name="proporsiPekerja"
          value={values?.proporsiPekerja}
          onChange={(val) => setValues({ ...values, proporsiPekerja: val })}
          error={Boolean(errors.proporsiPekerja && touched.proporsiPekerja)}
          errorText={Boolean(errors.proporsiPekerja && touched.proporsiPekerja) && errors.proporsiPekerja}
        />
        <Input
          disabled
          label="Batas bawah upah"
          type="text"
          placeholder="Masukkan batas bawah upah"
          name="batasBawahUpah"
          value={values?.batasBawahUpah !== undefined ? rupiah.getWithComa(values?.batasBawahUpah ?? 0) : "-"}
          error={Boolean(errors.idNominal && touched.idNominal)}
          errorText={Boolean(errors.idNominal && touched.idNominal) && errors.idNominal}
        />
        <InputNumber
          label="Batas atas upah"
          prefix="Rp"
          type="text"
          placeholder="Masukkan batas atas upah"
          name="batasAtasUpah"
          value={values?.batasAtasUpah ? number.getWithComa(values?.batasAtasUpah, { minimumFractionDigits: 2 }) : ""}
          onChange={(val) => setValues({ ...values, batasAtasUpah: val })}
          error={Boolean(errors.batasAtasUpah && touched.batasAtasUpah)}
          errorText={Boolean(errors.batasAtasUpah && touched.batasAtasUpah) && errors.batasAtasUpah}
        />
        <TextArea
          label="Keterangan"
          name="keterangan"
          placeholder="Masukkan keterangan"
          value={values.keterangan}
          onChange={handleChange}
          error={Boolean(errors.keterangan && touched.keterangan)}
          errorText={Boolean(errors.keterangan && touched.keterangan) && errors.keterangan}
        />
      </div>
    </>
  );
};
