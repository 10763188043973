import { Services } from "services";
import { fetchDropdownMapper } from "utilities";

const fetchDropdown = fetchDropdownMapper(Services);

class AtasanUnitOrganisasiApi {
  async getList(params) {
    const fetch = await Services.get("/master/atasan-unit-organisasi", { params });
    return fetch?.data?.data;
  }

  async getExport(params) {
    const fetch = await Services.get("/master/atasan-unit-organisasi/export", { params });
    return fetch?.data?.data;
  }

  async getUnitOrganisasi() {
    const fetch = await Services.get("/master/unit-organisasi");
    return fetch?.data?.data.map((val) => ({
      value: val?.master_unit_organisasi_id,
      label: val?.master_unit_organisasi_nama
    }));
  }
  getJabatan() {
    return fetchDropdown("/master/jabatan/dropdown?jenis_jabatan=1", {
      value: "master_jabatan_id",
      label: "master_jabatan_nama"
    });
  }

  create(data) {
    return Services.post("/master/atasan-unit-organisasi", data);
  }

  update(data, id) {
    return Services.put("/master/atasan-unit-organisasi/" + id, data);
  }

  delete(id) {
    return Services.delete("/master/atasan-unit-organisasi/" + id);
  }
}

export default new AtasanUnitOrganisasiApi();
