import {
  TunjanganRitaseList,
  TunjanganRitaseCreate,
  TunjanganRitaseDetail,
  TunjanganRitaseUpdate
} from "pages/Payroll";

export const TunjanganRitase = {
  component: <TunjanganRitaseList />,
  link: "tunjangan-ritase",
  name: "Tunjangan Ritase",
  access: "PEKERJA_KUBIKASI_BULANAN",
  child: [
    {
      name: "Tambah",
      link: "/tambah",
      component: <TunjanganRitaseCreate />
    },
    {
      name: "Detail",
      link: "/detail/:id",
      component: <TunjanganRitaseDetail />
    },
    {
      name: "Ubah",
      link: "/ubah/:id",
      component: <TunjanganRitaseUpdate />
    }
  ]
};
