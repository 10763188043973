import React, { useState } from "react";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import { Formik } from "formik";
import { useUploads } from "hooks";
import { ContentLayout } from "layouts";
import { errorSubmitMapper } from "utilities";
import { ButtonBack, DataStatus, useModalConfirm, ButtonCreate } from "components";
import { Content } from "./__SetupUpahTetapBpjsComps__";
import { formInitialValues, formSubmitValueMapper, formValidationSchema } from "./__SetupUpahTetapBpjsUtilities__";
import SetupUpahTetapBpjsApi from "./__SetupUpahTetapBpjsApi__";

export const SetupUpahTetapBpjsCreate = () => {
    const navigate = useNavigate();
    const modalConfirm = useModalConfirm();

    const defaultData = {
        periodeGajianId: new Date().getFullYear(),
        periodePerencanaan: new Date().getFullYear(),
        unitOrganisasiId: undefined,
        unitOrganisasiNama: undefined,
    };

    const [filter, setFilter] = useState({
        active: false,
        data: defaultData
    });

    const resetFilter = () => {
        setFilter({
            active: false,
            data: defaultData
        });
    };

    const filteredSearchHandler = (val) => {
        setFilter({
            active: true,
            data: {
                periodeGajianId: val?.periodeGajianId,
                periodePerencanaan: val?.periodePerencanaan,
                unitOrganisasiId: val?.unitOrganisasiId,
                unitOrganisasiNama: val?.unitOrganisasiNama,
            }
        });
    };

    const getSetupForm = useQuery(
        [
            "setup-form",
            "list",
            filter.data.periodeGajianId,
            filter.data?.unitOrganisasiId
        ],
        () =>
            SetupUpahTetapBpjsApi.getSetupForm({
                periode_tahun: filter.data.periodeGajianId,
                master_unit_organisasi_id: filter?.data?.unitOrganisasiId
            }),
        {
            enabled: Boolean(
                filter.data.periodeGajianId &&
                filter.data.unitOrganisasiId
            )
        }
    );

    const createData = useMutation((data) => SetupUpahTetapBpjsApi.create(data), {
        onSuccess: () => {
            modalConfirm.infoSuccess({ typeInfo: "create" });
            navigate("/payroll/transaksi/upah-tetap-bpjs");
        },
        onError: (err) => modalConfirm.infoError({ typeInfo: "create", ...errorSubmitMapper(err) })
    });

    const uploadData = useUploads(SetupUpahTetapBpjsApi.linkUploadData, {
        onSuccess: () => {
            modalConfirm.infoSuccess({
                customTextInfoTitle: "Upload data setup upah tetap bpjs berhasil",
                onHide: modalConfirm.close
            })
            navigate("/payroll/transaksi/upah-tetap-bpjs");
        },
        onError: (err) =>
            modalConfirm.infoError({
                customTextInfoTitle: "Upload data setup upah tetap bpjs gagal",
                ...errorSubmitMapper(err),
                onHide: modalConfirm.close
            })
    });

    const submitValidationHandler = (errors) =>
        new Promise((resolve, reject) => {
            const getError = Object.values(errors);

            if (getError.length > 0) {
                reject(getError);
            } else {
                resolve();
            }
        });

    const preSubmitHandler = (values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting) => {
        setSubmitting(true);
        validateForm().then(async (err) => {
            setErrors(err);
            setTouched(err);

            await submitValidationHandler(err, values)
                .then(() => formSubmitHandler(values))
                .catch((err) =>
                    modalConfirm.trigger({
                        type: "error",
                        title: "Data Tidak Lengkap",
                        data: err,
                        onHide: () => modalConfirm.close()
                    })
                )
                .finally(() => {
                    setSubmitting(false);
                });
        });
    };

    const formSubmitHandler = async (values) => {
        return await modalConfirm.trigger({
            type: "create",
            onHide: modalConfirm.close,
            onSubmit: () => {
                // modalConfirm.loading();
                // createData.mutateAsync(formSubmitValueMapper(values).array);
                //for upload
                uploadData.mutateAsync(formSubmitValueMapper(values).create);
            }
        });
    };

    return (
        <>
            <ContentLayout showBreadcrumb>
                {getSetupForm?.isFetching ? (
                    <DataStatus loading />
                ) : (
                    <>
                        <div className="text-end mb-3">
                            <ButtonBack tooltip={false} onClick={() => navigate("/payroll/transaksi/upah-tetap-bpjs")} />
                        </div>
                        <Formik
                            // enableReinitialize
                            initialValues={formInitialValues(getSetupForm?.data, filter?.data).dataFormSetup}
                            validationSchema={Yup.object().shape({
                                periodeGajianId: Yup.string().required("Periode wajib diisi"),
                                dataImport: Yup.string().required("File upah tetap bpjs wajib diisi"),
                            })}
                            onSubmit={(val, { resetForm }) => {
                                filteredSearchHandler(val);
                                // resetForm();
                            }}
                        >
                            {({ values, handleSubmit, validateForm, setTouched, setErrors, setSubmitting }) => (
                                <>
                                    <Content
                                        action="CREATE"
                                        setFilter={setFilter}
                                        filterData={filter.data}
                                        loading={getSetupForm.isFetching}
                                        onSearchButtonClick={handleSubmit}
                                        onResetClickButton={resetFilter}
                                        uploadData={uploadData}
                                    />
                                    <div className="text-end mb-3 mt-3">
                                        <ButtonCreate
                                            text="SIMPAN"
                                            // disabled={Boolean(values?.detail?.length === 0)}
                                            tooltip={false}
                                            onClick={() =>
                                                preSubmitHandler(values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting)
                                            }
                                        />
                                    </div>
                                </>
                            )}
                        </Formik>
                    </>
                )}
            </ContentLayout>
        </>
    );
};
