import { Services } from "services";

class SetupDanaPendidikanMasterApi {
  async getList(params) {
    const fetch = await Services.get("/master/setup-dana-pendidikan", { params });
    return fetch?.data?.data;
  }

  async getDetail(id) {
    const fetch = await Services.get("/master/setup-dana-pendidikan/" + id);
    return fetch?.data?.data;
  }

  async getPeriode() {
    const fetch = await Services.get("/master/grade-nominal/dropdown");
    return fetch?.data?.data.map((val) => ({
      value: val?.periode_gajian_id,
      label: val?.periode_perencanaan
    }));
  }

  async getExport(params) {
    const fetch = await Services.get("/master/setup-dana-pendidikan/export", { params });
    return fetch?.data?.data;
  }

  create(data) {
    return Services.post("/master/setup-dana-pendidikan", data);
  }

  update(data, id) {
    return Services.put("/master/setup-dana-pendidikan/" + id, data);
  }

  delete(id) {
    return Services.delete("/master/setup-dana-pendidikan/" + id);
  }
}

export default new SetupDanaPendidikanMasterApi();
