import * as Yup from "yup";

export const formValidationSchema = Yup.object().shape({
  periodePenggajianId: Yup.string().required("Periode penggajian wajib diisi !"),
  kode: Yup.string().required("Kode wajib diisi !").max(3, 'Kode tidak boleh lebih dari 3 digit!'),
  alasanPHK: Yup.string().required("Alasan phk wajib diisi !"),
  pesangon: Yup.string().required("Pesangon wajib diisi !"),
  upahPenghargaanMasaKerja: Yup.string().required("Upah penghargaan masa kerja wajib diisi !"),
  upahPenggantiHAK: Yup.string().required("Upah pengganti hak wajib diisi !"),
  upahPisah: Yup.string().required("Upah pisah wajib diisi !")
});
