export const formSubmitMapper = ({ data = {} }) => ({
  identitas_kpi: {
    master_unit_organisasi_id: data.unitOrganisasiId,
    master_perspektif_kpi_id: data.perspektifKpiId,
    master_sasaran_kpi_id: data.sasaranKpiId,
    master_kpi_id: data.kpiId,
    definisi: data.definisi
  },
  periode_target: {
    master_satuan_kpi_id: data.periodeSatuanKpiId,
    periode_target: data.periode
  },
  formula: {
    formula: data.formula
  },
  target: data?.target?.map((val) => ({ master_target_kpi_id: val.targetKpiId })),
  polaritas: {
    polaritas: data.polaritas
  },
  frekuensi_monitoring: {
    frekuensi_monitoring: data.frekuensi,
    master_satuan_kpi_id: data.frekuensiSatuanKpiId
  },
  sumber_data: data?.nama?.map((val) => val)
});
