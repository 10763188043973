export const formSubmitValueMapper = (data) => ({
    array: data?.detail
        ?.filter((obj) => {
            return obj.checked;
        })
        .map((val) => ({
            periode_tahun: val?.periodeGajianId,
            tgl_setup: val?.tglSetup,
            tgl_bulan_awal: val?.tglAwal,
            tgl_bulan_akhir: val?.tglAkhir,
            pekerja_id: val?.pekerjaId,
            master_unit_organisasi_id: val?.unitOrganisasiId,
            upah_pokok: val?.upahPokok,
            tunjangan_tetap: val?.tunjanganTetap,
            persentase: val?.persentase,
            nominal: val?.nominal,
            master_agama_id: val?.agamaId,
            tahun_thr: val?.tahun,
            tgl_pembayaran: val?.tglPembayaran
        })),
    object: {
        // periode_tahun: data?.periodeGajianId,
        // tgl_setup: data?.tglSetup,
        // tgl_bulan_awal: data?.tglAwal,
        // tgl_bulan_akhir: data?.tlAkhir,
        // pekerja_id: data?.pekerjaId,
        // master_unit_organisasi_id: data?.unitOrganisasiId,
        upah_pokok: data?.upahPokok,
        tunjangan_tetap: data?.tunjanganTetap,
        persentase: data?.persentase,
        nominal: data?.nominal,
        master_agama_id: data?.agamaId
    }
});
