import { Row, Col, Card } from "react-bootstrap";
import { ChartDoughnut, numberConvert } from "components";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import DashboardApi from "./__DashboardApi__";

export const Dashboard = () => {
  const navigate = useNavigate();
  const number = numberConvert();

  const getSummaryTotal = useQuery(["summary-total"], () => DashboardApi.getSummaryTotal());

  const getPekerja = useQuery(["summary-pekerja"], () => DashboardApi.getPekerja());

  const getStatusPekerja = useQuery(["summary-status-pekerja"], () => DashboardApi.getStatusPekerja());

  //data Info
  const infoTotal = getSummaryTotal?.data;

  //chart doughnut Total Pekerja
  const infoPekerja = getPekerja?.data;
  const doughnutLabel = getPekerja?.data
    ? [
      `Laki-laki ${infoPekerja?.total_perecentage_laki_laki}%`,
      `Perempuan ${infoPekerja?.total_perecentage_perempuan}%`
    ]
    : ["Tidak ada Pekerja"];
  const doughnutDataset = getSummaryTotal?.data
    ? [
      {
        label: "Data Pekerja",
        data: [infoPekerja?.total_laki_laki, infoPekerja?.total_perempuan],
        backgroundColor: ["rgb(54, 162, 235)", "rgb(255, 99, 132)"],
        hoverOffset: 4
      }
    ]
    : [
      {
        label: "Data Pekerja",
        data: [0.0001],
        backgroundColor: ["#F2F2F2"],
        hoverOffset: 4
      }
    ];

  const dataDoughnutCartTotalPekerja = {
    labels: doughnutLabel,
    datasets: doughnutDataset
  };

  //chart doughnut Status Pekerja
  const infoStatusPekerja = getStatusPekerja?.data;
  const doughnutLabelStatusPekerja = getPekerja?.data
    ? [
      `PKWTT ${number.getWithComa(infoStatusPekerja?.percentage_pkwtt, { minimumFractionDigits: 2 })}%`,
      `PKWT ${number.getWithComa(infoStatusPekerja?.percentage_pkwt, { minimumFractionDigits: 2 })}%`,
      `PHK ${number.getWithComa(infoStatusPekerja?.percentage_phk, { minimumFractionDigits: 2 })}%`,
      `Lainnya ${number.getWithComa(infoStatusPekerja?.percentage_lainnya, { minimumFractionDigits: 2 })}%`
    ]
    : ["Tidak ada Pekerja"];
  const doughnutDatasetStatusPekerja = getSummaryTotal?.data
    ? [
      {
        label: "Data Status Pekerja",
        data: [
          infoStatusPekerja?.total_pkwtt,
          infoStatusPekerja?.total_pkwt,
          infoStatusPekerja?.total_phk,
          infoStatusPekerja?.total_lainnya
        ],
        backgroundColor: ["rgb(255, 205, 86)", "rgb(0, 255, 128)", "rgb(255, 0, 0)", "rgb(192, 192, 192)"],
        hoverOffset: 4
      }
    ]
    : [
      {
        label: "Data Pekerja",
        data: [0.0001],
        backgroundColor: ["#F2F2F2"],
        hoverOffset: 4
      }
    ];

  const dataDoughnutCartStatusPekerja = {
    labels: doughnutLabelStatusPekerja,
    datasets: doughnutDatasetStatusPekerja
  };

  return (
    <>
      <Card className="mb-2">
        <div className="mt-2 mb-2 ms-2 me-2">
          <b>Dashboard</b>
        </div>
      </Card>
      {/* Card info */}
      <Row className="mb-2">
        <Col className="mb-2" md={4} sm={12}>
          <Card className="border-none mb-4" style={{ borderRadius: "12px", padding: "20px" }}>
            <div
              className="d-flex gap-4  align-items-center rounded-pill"
              onClick={() => navigate("/operasional-sdm/transaksi/permohonan-cuti")}
            >
              <div
                className="d-flex justify-content-center align-items-center rounded-circle"
                style={{ width: "64px", background: "#FFEFD4", aspectRatio: "1/1" }}
              >
                <svg
                  fill="#F59E0B"
                  viewBox="0 0 16 16"
                  height="2em"
                  width="2em"
                >
                  <path d="M.05 3.555A2 2 0 012 2h12a2 2 0 011.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.026A2 2 0 002 14h6.256A4.493 4.493 0 018 12.5a4.49 4.49 0 011.606-3.446l-.367-.225L8 9.586l-1.239-.757zM16 4.697v4.974A4.491 4.491 0 0012.5 8a4.49 4.49 0 00-1.965.45l-.338-.207L16 4.697z" />
                  <path d="M16 12.5a3.5 3.5 0 11-7 0 3.5 3.5 0 017 0zm-1.993-1.679a.5.5 0 00-.686.172l-1.17 1.95-.547-.547a.5.5 0 00-.708.708l.774.773a.75.75 0 001.174-.144l1.335-2.226a.5.5 0 00-.172-.686z" />
                </svg>
              </div>
              <div>
                <h2 className="mb-0">
                  <b>{infoTotal?.total_permohonan_cuti ?? 0}</b>
                </h2>
                <small>Permohonana Cuti</small>
              </div>
            </div>
          </Card>
          <Card className="border-none" style={{ borderRadius: "12px", padding: "20px" }}>
            <div
              className="d-flex gap-4 align-items-center rounded-pill"
              onClick={() => navigate("/operasional-sdm/transaksi/permohonan-izin")}
            >
              <div
                className="d-flex justify-content-center align-items-center rounded-circle"
                style={{ width: "64px", background: "#ECEEF6", aspectRatio: "1/1" }}
              >
                <svg
                  viewBox="0 0 512 512"
                  fill="#00ABDA"
                  height="2em"
                  width="2em"
                >
                  <path d="M215.4 96H96v177.4L.2 202.5c1.6-18.1 10.9-34.9 25.7-45.8L48 140.3V96c0-26.5 21.5-48 48-48h76.6l49.9-36.9C232.2 3.9 243.9 0 256 0s23.8 3.9 33.5 11l49.9 37H416c26.5 0 48 21.5 48 48v44.3l22.1 16.4c14.8 10.9 24.1 27.7 25.7 45.8L416 273.4V96H215.4zM0 448V242.1l217.6 161.2c11.1 8.2 24.6 12.7 38.4 12.7s27.3-4.4 38.4-12.7L512 242.1V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64zm176-288h160c8.8 0 16 7.2 16 16s-7.2 16-16 16H176c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64h160c8.8 0 16 7.2 16 16s-7.2 16-16 16H176c-8.8 0-16-7.2-16-16s7.2-16 16-16z" />
                </svg>
              </div>
              <div>
                <h2 className="mb-0">
                  <b>{infoTotal?.total_permohonan_ijin ?? 0}</b>
                </h2>
                <small>Permohonan Izin</small>
              </div>
            </div>
          </Card>
        </Col>
        <Col md={4} sm={12}>
          <Card className="border-none mb-2" style={{ minHeight: "100%", borderRadius: "12px", padding: "20px" }}>
            <div className="d-flex justify-content-between mb-4">
              <b>Total Pekerja</b>
              <b>{infoPekerja?.total_pekerja}</b>
            </div>
            <div className="m-2">
              <ChartDoughnut data={dataDoughnutCartTotalPekerja} />
            </div>
          </Card>
        </Col>
        <Col md={4} sm={12}>
          <Card className="border-none mb-2" style={{ minHeight: "100%", borderRadius: "12px", padding: "20px" }}>
            <div className="d-flex justify-content-between mb-4">
              <b>Status Pekerja</b>
              <b>{infoStatusPekerja?.total_pekerja}</b>
            </div>
            <div className="m-2">
              <ChartDoughnut data={dataDoughnutCartStatusPekerja} />
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};
