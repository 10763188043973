import { TunjanganHariRayaList, TunjanganHariRayaCreate } from "pages/Payroll";

export const TunjanganHariRaya = {
    component: <TunjanganHariRayaList />,
    link: "tunjangan-hari-raya",
    name: "Tunjangan Hari Raya",
    access: "THR_BULANAN",
    child: [
        {
            name: "Tambah",
            link: "/tambah",
            component: <TunjanganHariRayaCreate />
        }
    ]
};
