import {
  ButtonFilter, dateConvert, InfoItemVertical, InputSearch, ModalFilter, rupiahConvert, Select, Tbody,
  Td, toastTrigger
} from "components";
import { Formik, useFormikContext } from "formik";
import { ListLayout } from "layouts";
import { debounce } from "lodash";
import { useState } from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import { FiFileText } from "react-icons/fi";
import { useMutation, useQuery } from "react-query";
import { errorFetchingSelectMapper, errorFetchingTableMapper, exportHandler } from "utilities";
import PayrollOverheadApi from "./__PayrollOverheadApi__";

const ModalFilterBody = () => {
  const { values, setValues, errors, touched } = useFormikContext();

  const getPeriodePenggajian = useQuery(["periode-rekap-order", "dropdown"], () =>
    PayrollOverheadApi.getPeriodePenggajianHalden()
  );

  const getTanggal = useQuery(
    ["tanggal-awal-akhir", "dropdown", values?.idPeriode, values?.idBulan],
    () =>
      PayrollOverheadApi.getTanggalAwalDanAkhir({
        periode_tahun: values?.idPeriode
      }),
    {
      enabled: values?.idPeriode !== undefined
    }
  );

  return (
    <>
      <Select
        label="Periode Penggajian"
        placeholder="Pilih periode penggajian"
        defaultValue={getPeriodePenggajian?.data?.find((val) => val.value === values.idPeriode)}
        loading={getPeriodePenggajian.isFetching}
        options={getPeriodePenggajian?.data ?? []}
        onChange={(val) => {
          setValues({
            ...values,
            idPeriode: val.value,
            namaPeriode: val.label,
            idBulan: undefined,
            namaBulan: undefined,
            tanggalPeriode: undefined,
            tglAwal: undefined,
            tglAkhir: undefined,
            idUnitOrganisasi: undefined
          });
        }}
        errorFetch={getPeriodePenggajian.isError}
        errorFetchText={errorFetchingSelectMapper(getPeriodePenggajian.error)}
        error={Boolean(errors.idPeriode && touched.idPeriode)}
        errorText={Boolean(errors.idPeriode && touched.idPeriode) && errors.idPeriode}
      />
      <Select
        disable={Boolean(!values?.idPeriode)}
        label="Tanggal Penggajian"
        placeholder="Pilih tanggal penggajian"
        defaultValue={getTanggal?.data?.find((item) => item.value === values?.tglId)}
        options={getTanggal?.data ?? []}
        onChange={(val) =>
          setValues({
            ...values,
            tglId: val.value,
            tglNama: val.label,
            tglAwal: val.tglAwal,
            tglAkhir: val.tglAkhir
          })
        }
        loading={getTanggal.isFetching}
        errorFetch={getTanggal.isError}
      />
    </>
  );
};

export const PayrollOverheadList = () => {
  // const access = useAccessRights("PAYROLL_OVERHEAD");
  // const navigate = useNavigate();
  const [searchKey, setSearchKey] = useState(undefined);
  const rupiah = rupiahConvert();
  const date = dateConvert();
  // const modalConfirm = useModalConfirm();

  const searchHandler = (e) => {
    setSearchKey(e?.target?.value ? e.target.value : undefined);
  };

  const [pagination, setPagination] = useState({
    pageCurrent: 1,
    dataLength: 10
  });

  const [filter, setFilter] = useState({
    show: false,
    active: false,
    data: {
      namaKomponenPayroll: undefined,
      idPeriode: undefined,
      namaPeriode: undefined,

      tglAwal: undefined,
      tglAkhir: undefined
    }
  });

  const getList = useQuery(
    [
      "payroll-overhead",
      "list",
      searchKey,
      pagination.pageCurrent,
      pagination.dataLength,
      filter?.data?.idPeriode,

      filter?.data?.tanggalPeriode
    ],
    () =>
      PayrollOverheadApi.getList({
        search: searchKey,
        periode_tahun: filter?.data?.idPeriode,

        tgl_bulan_awal: filter?.data?.tglAwal,
        tgl_bulan_akhir: filter?.data?.tglAkhir,
        page: pagination.pageCurrent,
        limit: pagination.dataLength
      }),
    {
      enabled: filter?.data?.idPeriode !== undefined
    }
  );

  const getExportPayrollOverhead = useMutation(
    (type) =>
      PayrollOverheadApi.getExport({
        to: type,
        periode_tahun: filter?.data?.idPeriode,
        master_unit_organisasi_id: filter?.data?.idUnitOrganisasi,
        tgl_bulan_awal: filter?.data?.tglAwal,
        tgl_bulan_akhir: filter?.data?.tglAkhir
      }),
    {
      onSuccess: (link) => {
        toastTrigger({ variant: "success", message: "Export berhasil" });
        exportHandler(link, "Data Laporan Payroll Overhead");
      },
      onError: () => {
        toastTrigger({ variant: "danger", message: "Export gagal" });
      }
    }
  );

  const onPaginationChange = (data) => setPagination({ ...pagination, pageCurrent: data });

  const onPaginationDataLengthChange = (data) => setPagination({ ...pagination, dataLength: data });

  const TABLE_HEADER = [
    {
      text: "Hirarki"
    },
    {
      text: "Unit Organisasi"
    },
    {
      text: "Nominal"
    }
  ];

  const TABLE_BODY = [
    {
      field: "jenis_overhead_nama"
    },
    {
      customField: (val) => (
        <>
          <table className="bg-transparent table">
            <Tbody>
              {val?.unit_organisasi?.map((data) => (
                <tr>
                  <Td>{data?.master_unit_organisasi_nama}</Td>
                </tr>
              ))}
              <tr>
                <Td className="text-end">
                  <b>TOTAL</b>
                </Td>
              </tr>
            </Tbody>
          </table>
        </>
      )
    },
    {
      customField: (val) => (
        <>
          <table className="bg-transparent table" style={{ boxShadow: "none" }}>
            <Tbody>
              {val?.unit_organisasi?.map((data) => (
                <tr>
                  <Td>{data.total_gaji ? rupiah.getWithComa(Number(data?.total_gaji)) + "" : "-"}</Td>
                </tr>
              ))}
              <tr>
                <Td className="text-end">
                  <b>{val.sub_total ? rupiah.getWithComa(Number(val?.sub_total)) + "" : "-"}</b>
                </Td>
              </tr>
            </Tbody>
          </table>
        </>
      )
    }
  ];

  return (
    <>
      <ListLayout
        customLeftTopSection={() => (
          <div className="align-items-end">
            <div className="d-flex">
              <div className="me-2">
                <InputSearch onChange={debounce(searchHandler, 1500)} />
              </div>
              <div>
                <ButtonFilter
                  active={filter?.active}
                  className="text-nowrap"
                  onClick={() => setFilter({ ...filter, show: true })}
                />
              </div>
            </div>
            <div className="d-flex mt-3">
              <div className="me-2">
                <InfoItemVertical
                  className="me-2"
                  label="Periode Perencanaan Penggajian"
                  text={filter?.data?.namaPeriode ?? "-"}
                />
              </div>
              <div className="me-2">
                <InfoItemVertical
                  className="me-2"
                  label="Bulan Penggajian"
                  text={
                    (getList.data?.info?.tgl_bulan_awal
                      ? date.getSlashDMY(new Date(getList.data?.info?.tgl_bulan_awal))
                      : "-") +
                    " s/d " +
                    (getList.data?.info?.tgl_bulan_akhir
                      ? date.getSlashDMY(new Date(getList.data?.info?.tgl_bulan_akhir))
                      : "-")
                  }
                />
              </div>
            </div>
          </div>
        )}
        customRightTopSection={() => (
          <div className="d-flex flex-column flex-sm-row justify-content-center justify-content-md-end align-items-center">
            <Dropdown align="end">
              <Dropdown.Toggle
                size="sm"
                variant="outline-success"
                className="mx-2"
                disabled={getExportPayrollOverhead?.isLoading}
              >
                {getExportPayrollOverhead?.isLoading ? (
                  <Spinner size="sm" className="me-1" animation="border" />
                ) : (
                  <FiFileText className="mb-1 mr-3" />
                )}
                <span className="mx-1">{getExportPayrollOverhead?.isLoading ? "Memproses..." : "EXPORT"}</span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="mt-2">
                <Dropdown.Item onClick={() => getExportPayrollOverhead.mutate("excel")}>Export Excel</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}
        loading={getList.isFetching}
        error={getList.isError}
        errorText={errorFetchingTableMapper(getList.error)}
        dataTotal={getList?.data?.data.total}
        dataLength={pagination.dataLength}
        pageCurrent={pagination.pageCurrent}
        pageTotal={getList?.data?.data?.last_page}
        onDataLengthChange={onPaginationDataLengthChange}
        onPaginationChange={onPaginationChange}
        data={getList?.data?.data}
        tableHeader={TABLE_HEADER}
        tableBody={TABLE_BODY}
      />

      {/* ModalFilter */}
      <Formik
        initialValues={{
          periodeGajianId: filter?.data?.periodeGajianId,
          unitOrganisasiId: filter?.data?.unitOrganisasiId,
          tglAwal: filter?.data?.tglAwal,
          tglAkhir: filter?.data?.tglAkhir
        }}
      >
        {({ resetForm, values }) => (
          <ModalFilter
            show={filter.show}
            onReset={resetForm}
            onHide={() =>
              setFilter({
                ...filter,
                show: false
              })
            }
            onSubmit={() => {
              setFilter({
                show: false,
                active: Boolean(Object.values(values).find((val) => val)),
                data: values
              });
            }}
          >
            <ModalFilterBody />
          </ModalFilter>
        )}
      </Formik>
    </>
  );
};
