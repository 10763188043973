import React, { useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { useQuery, useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { ContentLayout } from "layouts";
import { ButtonBack, ButtonUpdate, DataStatus } from "components";
import { errorSubmitMapper } from "utilities";
import { useModalConfirm } from "hooks";
import GradeApi from "./__GradeApi__";
import { TableGrade } from "./__GradeComps__";
import { formSubmitMapper } from "./__GradeUtils__";

const dataDefaultGrade = Array.from({ length: 23 }).map((_v, i) => ({
  grade_nama: i + 1,
  jnd: 0,
  skor_jobeva_low: 0,
  skor_jobeva_mid: 0,
  skor_jobeva_hi: 0
}));

export const GradeUpdate = () => {
  const navigate = useNavigate();
  const modalConfirm = useModalConfirm();
  const [previousDataGrade, setPreviousDataGrade] = useState([]);
  const [newDataGrade, setNewDataGrade] = useState([]);

  const getGrade = useQuery(["grade", "list"], () => GradeApi.get(), {
    onSuccess: (data) => {
      const mapDataGrade = dataDefaultGrade.map((val) => {
        const getGradeItem = data?.data?.find((find) => find?.grade?.toString() === val?.grade_nama?.toString());

        return {
          grade_nama: String(getGradeItem?.grade ?? val.grade),
          jnd: parseInt(getGradeItem?.jnd ?? val.jnd),
          skor_jobeva_low: parseInt(getGradeItem?.nilai_terendah ?? val.nilai_terendah),
          skor_jobeva_mid: parseInt(getGradeItem?.nilai_tengah ?? val.nilai_tengah),
          skor_jobeva_hi: parseInt(getGradeItem?.nilai_tertinggi ?? val.nilai_tertinggi)
        };
      });

      setPreviousDataGrade(mapDataGrade);
      setNewDataGrade(mapDataGrade);
    }
  });

  const updateGrade = useMutation(() => GradeApi.update(formSubmitMapper(newDataGrade)), {
    onSuccess: () => {
      modalConfirm.infoSuccess({
        typeInfo: "update",
        onHide: () => {
          modalConfirm.close();
          navigate(-1);
        }
      });
    },
    onError: (err) => {
      modalConfirm.infoError({ typeInfo: "update", ...errorSubmitMapper(err) });
    }
  });

  return (
    <ContentLayout showBreadcrumb>
      {/* Top Button */}
      <div className="d-flex justify-content-end">
        <ButtonBack tooltip={false} className="mb-3" onClick={() => navigate(-1)} />
      </div>

      {getGrade.isFetching || getGrade.isError ? (
        <DataStatus
          loading={getGrade.isFetching}
          text={getGrade.isFetching ? "Memuat data . . ." : "Data gagal dimuat!"}
        />
      ) : (
        <>
          <Row>
            <Col lg={6}>
              <Card className="mb-3">
                <Card.Header>
                  <b>Rentang Skor Grade Sebelumnya</b>
                </Card.Header>
                <Card.Body>
                  <TableGrade action="READ" data={previousDataGrade} />
                </Card.Body>
              </Card>
            </Col>
            <Col lg={6}>
              <Card className="mb-3">
                <Card.Header>
                  <b>Setup Rentang Skor Grade Baru</b>
                </Card.Header>
                <Card.Body>
                  <TableGrade data={newDataGrade} setData={setNewDataGrade} />
                </Card.Body>
              </Card>
            </Col>
          </Row>

          {/* Bottom Button */}
          <div className="d-flex justify-content-end">
            <ButtonUpdate
              tooltip={false}
              className="mb-3 mt-3"
              onClick={() =>
                modalConfirm.trigger({
                  size: "lg",
                  type: "update",
                  component: <TableGrade action="READ" data={newDataGrade} />,
                  onSubmit: () => {
                    modalConfirm.loading();
                    updateGrade.mutate();
                  },
                  onHide: () => {
                    modalConfirm.close();
                  }
                })
              }
            />
          </div>
        </>
      )}
    </ContentLayout>
  );
};
