import React from "react";
import { useQuery } from "react-query";
import { useFormikContext } from "formik";
import { Input, Select, InfoItemVertical } from "components";
import { errorFetchingSelectMapper } from "utilities";
import KPIApi from "../__KPIApi__";

export const Content = ({ action }) => {
  const { values, errors, touched, handleChange, setValues } = useFormikContext();
  const getUnitOrganisasi = useQuery(["unitorganisasi", "dropdown"], KPIApi.getUnitOrganisasi);

  if (action === "READ") {
    return (
      <>
        <InfoItemVertical label="Unit Organisasi" text={values.unit_organisasi_nama} />
        <InfoItemVertical label="Kode KPI" text={values.kode} />
        <InfoItemVertical label="KPI" text={values.nama} />
      </>
    );
  }

  return (
    <>
      <Select
        label="Unit Organisasi"
        placeholder="Pilih unit organisasi"
        defaultValue={getUnitOrganisasi?.data
          ?.filter((val) => val.value)
          ?.find((item) => item.value === values.unit_organisasi_id)}
        options={getUnitOrganisasi?.data?.filter((val) => val.value)}
        onChange={(val) => {
          setValues({
            ...values,
            unit_organisasi_id: val.value,
            unit_organisasi_nama: val.label
          });
        }}
        loading={getUnitOrganisasi.isFetching}
        error={Boolean(errors.unit_organisasi_nama && touched.unit_organisasi_nama)}
        errorText={Boolean(errors.unit_organisasi_nama && touched.unit_organisasi_nama) && errors.unit_organisasi_nama}
        errorFetch={getUnitOrganisasi.isError}
        errorFetchText={errorFetchingSelectMapper(getUnitOrganisasi.error)}
      />

      <Input
        label="Kode KPI"
        name="kode"
        placeholder="Masukkan kode KPI"
        value={values.kode}
        onChange={handleChange}
        error={Boolean(errors.kode && touched.kode)}
        errorText={Boolean(errors.kode && touched.kode) && errors.kode}
      />

      <Input
        label="KPI"
        name="nama"
        placeholder="Masukkan nama KPI"
        value={values.nama}
        onChange={handleChange}
        error={Boolean(errors.nama && touched.nama)}
        errorText={Boolean(errors.nama && touched.nama) && errors.nama}
      />
    </>
  );
};
