import React from "react";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { Toast } from "components";
import { ModalConfirmProvider, LayoutProvider, AuthProvider } from "hooks";
import BaseRoutes from "routes";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import "styles/css/global.css";

const App = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false
      }
    }
  });

  return (
    <BrowserRouter basename="/">
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <LayoutProvider>
            <ModalConfirmProvider>
              <BaseRoutes />
            </ModalConfirmProvider>
            <Toast />
          </LayoutProvider>
        </AuthProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
};

export default App;
