import React from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { Formik } from "formik";
import { ContentLayout } from "layouts";
import { ButtonBack, ButtonCreate } from "components";
import { errorSubmitMapper } from "utilities";
import { useModalConfirm } from "hooks";
import { Content } from "./__EvaluasiJabatanComps__";
import { formInitialValues, formValidationSchema, formSubmitValueMapper } from "./__EvaluasiJabatanUtils__";
import EvaluasiJabatanApi from "./__EvaluasiJabatanApi__";

export const EvaluasiJabatanCreate = () => {
  const navigate = useNavigate();
  const modalConfirm = useModalConfirm();

  const createEvaluasiJabatan = useMutation((data) => EvaluasiJabatanApi.create(data), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "create", size: "md" });
      navigate(-1);
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "create", size: "md", ...errorSubmitMapper(err) })
  });

  const submitValidationHandler = (errors) =>
    new Promise((resolve, reject) => {
      const getError = Object.values(errors);

      if (getError.length > 0) {
        reject(getError);
      } else {
        resolve();
      }
    });

  const preSubmitHandler = (formik) => {
    const { values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting } = formik;

    setSubmitting(true);
    validateForm().then(async (err) => {
      setErrors(err);
      setTouched(err);
      await submitValidationHandler(err, values)
        .then(() => handleSubmit())
        .catch((err) =>
          modalConfirm.trigger({
            type: "error",
            title: "Data Tidak Lengkap",
            data: err,
            onHide: () => modalConfirm.close()
          })
        )
        .finally(() => {
          setSubmitting(false);
        });
    });
  };

  const formSubmitHandler = (values) =>
    modalConfirm.trigger({
      type: "create",
      size: "lg",
      onHide: modalConfirm.close,
      onSubmit: () => {
        modalConfirm.loading();

        createEvaluasiJabatan.mutate(formSubmitValueMapper(values));
      },
      component: (
        <Formik initialValues={values}>
          <Content action="READ" />
        </Formik>
      )
    });

  return (
    <div>
      <ContentLayout showBreadcrumb>
        <div className="text-end mb-3">
          <ButtonBack tooltip={false} onClick={() => navigate(-1)} />
        </div>
        <Formik
          initialValues={formInitialValues()}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {(formik) => (
            <>
              <Content action="CREATE" />
              <div className="text-end my-4">
                <ButtonCreate tooltip={false} text="Simpan" className="px-4" onClick={() => preSubmitHandler(formik)} />
              </div>
            </>
          )}
        </Formik>
      </ContentLayout>
    </div>
  );
};
