import React from "react";
import { useQuery } from "react-query";
import { useFormikContext } from "formik";
import { Input, Select, InfoItemVertical, TextArea } from "components";
import { errorFetchingSelectMapper } from "utilities";
import PangkatApi from "../__PangkatApi__";
import { jenisJabatanMapper } from "../__PangkatUtils__";

export const Content = ({ action }) => {
  const { values, errors, touched, handleChange, setValues } = useFormikContext();
  const getGrade = useQuery(["grade", "dropdown"], () => PangkatApi.getGrade());
  const getJenisJabatan = useQuery(["jenis-jabatan", "dropdown"], () => PangkatApi.getJenisJabatan());

  if (action === "READ") {
    return (
      <>
        <InfoItemVertical label="Nama Pangkat" text={values.nama} />
        <InfoItemVertical label="Grade Pangkat" text={values.grade_nama} />
        <InfoItemVertical label="Jenis Jabatan" text={jenisJabatanMapper(values.jenis_jabatan_id)} />
        <InfoItemVertical label="Keterangan" text={values.keterangan} />
      </>
    );
  }

  return (
    <>
      <Input
        label="Nama Pangkat"
        name="nama"
        placeholder="Masukkan nama pangkat"
        value={values.nama}
        onChange={handleChange}
        error={Boolean(errors.nama && touched.nama)}
        errorText={Boolean(errors.nama && touched.nama) && errors.nama}
      />
      <Select
        label="Grade Pangkat"
        placeholder="Pilih grade pangkat"
        defaultValue={getGrade?.data?.find((item) => item.value === values.grade_id)}
        options={getGrade?.data ?? []}
        onChange={(val) => {
          setValues({
            ...values,
            grade_nama: val.value,
            grade_id: val.label
          });
        }}
        loading={getGrade.isFetching}
        error={Boolean(errors.grade_nama && touched.grade_nama)}
        errorText={Boolean(errors.grade_nama && touched.grade_nama) && errors.grade_nama}
        errorFetch={getGrade.isError}
        errorFetchText={errorFetchingSelectMapper(getGrade.error)}
      />

      <Select
        label="Jenis Jabatan"
        placeholder="Pilih jenis jabatan"
        defaultValue={getJenisJabatan?.data?.find((item) => item.value === values.jenis_jabatan_nama)}
        options={getJenisJabatan?.data ?? []}
        onChange={(val) => {
          setValues({
            ...values,
            jenis_jabatan_nama: val.value
          });
        }}
        loading={getJenisJabatan.isFetching}
        error={Boolean(errors.jenis_jabatan_nama && touched.jenis_jabatan_nama)}
        errorText={Boolean(errors.jenis_jabatan_nama && touched.jenis_jabatan_nama) && errors.jenis_jabatan_nama}
        errorFetch={getJenisJabatan.isError}
        errorFetchText={errorFetchingSelectMapper(getJenisJabatan.error)}
      />

      <TextArea
        label="Keterangan"
        name="keterangan"
        placeholder="Masukkan keterangan"
        value={values.keterangan}
        onChange={handleChange}
        error={Boolean(errors.keterangan && touched.keterangan)}
        errorText={Boolean(errors.keterangan && touched.keterangan) && errors.keterangan}
      />
    </>
  );
};
