import React from "react";
import { useQuery } from "react-query";
import { useFormikContext } from "formik";
import { Input, Select, InfoItemVertical, TextArea, InputNumber } from "components";
import { errorFetchingSelectMapper } from "utilities";
import KelompokJabatanApi from "../__KelompokJabatanApi__";
import { jenisJabatanMapper } from "../__KelompokJabatanUtils__";

export const Content = ({ action }) => {
  const { values, errors, touched, handleChange, setValues, setFieldValue } = useFormikContext();
  const getJenisJabatan = useQuery(["jenis-jabatan", "dropdown"], () => KelompokJabatanApi.getJenisJabatan());

  if (action === "READ") {
    return (
      <>
        <InfoItemVertical label="Jenis Jabatan" text={jenisJabatanMapper(values.jenisJabatanId)} />
        <InfoItemVertical label="Kelompok Jabtan" text={values.nama} />
        <InfoItemVertical label="Ururtan" text={values.urutan} />
        <InfoItemVertical label="Keterangan" text={values.keterangan} />
      </>
    );
  }

  return (
    <>
      <Select
        label="Jenis Jabatan"
        placeholder="Pilih jenis jabatan"
        defaultValue={getJenisJabatan?.data?.find((item) => item.value === values.jenisJabatanId)}
        options={getJenisJabatan?.data ?? []}
        onChange={(val) => {
          setValues({
            ...values,
            jenisJabatanId: val.value
          });
        }}
        loading={getJenisJabatan.isFetching}
        error={Boolean(errors.jenisJabatanId && touched.jenisJabatanId)}
        errorText={Boolean(errors.jenisJabatanId && touched.jenisJabatanId) && errors.jenisJabatanId}
        errorFetch={getJenisJabatan.isError}
        errorFetchText={errorFetchingSelectMapper(getJenisJabatan.error)}
      />

      <Input
        label="Kelompok Jabatan"
        name="nama"
        placeholder="Masukkan nama kelompok jabatan"
        value={values.nama}
        onChange={handleChange}
        error={Boolean(errors.nama && touched.nama)}
        errorText={Boolean(errors.nama && touched.nama) && errors.nama}
      />
      <InputNumber
        label="Urutan"
        name="urutan"
        placeholder="Masukkan urutan"
        value={values.urutan}
        onChange={(val) => setFieldValue("urutan", val)}
        error={Boolean(errors.urutan && touched.urutan)}
        errorText={Boolean(errors.urutan && touched.urutan) && errors.urutan}
      />

      <TextArea
        label="Keterangan"
        name="keterangan"
        placeholder="Masukkan keterangan"
        value={values.keterangan}
        onChange={handleChange}
        error={Boolean(errors.keterangan && touched.keterangan)}
        errorText={Boolean(errors.keterangan && touched.keterangan) && errors.keterangan}
      />
    </>
  );
};
