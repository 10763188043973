export const formInitialValues = (data = {}) => {
  const obj = {
    master_peran_id: data?.master_peran_id ?? "",
    master_peran_nama: data?.master_peran_nama ?? "",
    master_profesi_id: data?.master_profesi_id ?? "",
    master_profesi_nama: data?.master_profesi_nama ?? "",
    nilai_skp: data?.nilai_skp ?? "",

    periodePengembanganId: data?.periode_pengembangan_id ?? "",
    periodePengembanganNama: data?.info?.periode_pengmbangan ?? "",
    sifatPengembanganId: data?.info?.sifat_pengembangan ?? "",
    namaPengembanganId: data?.nama_pengembangan_id ?? "",
    namaPengembanganNama: data?.info?.nama_pengembangan ?? "",

    tglPermintaan: data?.info?.tgl_permintaan ?? "",
    noPermintaan: data?.info?.no_permintaan ?? "",

    periodeId: data?.periode ?? "",
    detail:
      data?.list?.map((val) => ({
        pekerja_id: val?.pekerja_id ?? "",
        pekerja_pengembangan_id: val?.pekerja_pengembangan_id ?? "",
        nilaiSKP: val?.nilai_skp ?? "",
        nip: val?.nip ?? "",
        pesertaNama: val?.pekerja_nama ?? "",
        unitOrganisasiNama: val?.master_unit_organisasi_nama ?? "",
        namaJabatan: val?.master_jabatan_nama ?? "",
        noSuratTugas: val?.no_surat_tugas ?? "",
        noPelaksanaSuratTugas: val?.no_pelaksanaan_surat_tugas ?? "",
        profesi: val?.master_profesi_nama ?? "",
        peran: val?.master_peran_nama ?? "",
        profesiId: val?.master_profesi_id ?? "",
        peranId: val?.master_peran_id ?? ""
      })) ?? []
  };

  return obj;
};
