import React from "react";
import { useState } from "react";
import { ButtonGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import { debounce } from "lodash";
import { ListLayout } from "layouts";
import { ButtonDetail, Select, InputSearch, toastTrigger, ButtonCreate } from "components";
import { useAccessRights } from "hooks";
import { errorFetchingTableMapper, exportHandler, dateConvert, numberConvert } from "utilities";
import PengajuanPekerjaPensiunApi from "./__PengajuanPekerjaPensiunApi__";

export const PengajuanPekerjaPensiunList = () => {
  const access = useAccessRights("PEKERJA_PENSIUN");
  const number = numberConvert();
  const navigate = useNavigate();
  const date = dateConvert();
  const [searchKey, setSearchKey] = useState(undefined);
  const [pagination, setPagination] = useState({
    pageCurrent: 1,
    dataLength: 10
  });

  const [filter, setFilter] = useState({
    periodeGajianId: undefined,
    unitOrganisasiId: undefined
  });
  const [onFilter, setOnFilter] = useState({
    periodeGajianId: undefined,
    unitOrganisasiId: undefined
  });

  const getPeriode = useQuery(["periode-penggajian", "dropdown"], () => PengajuanPekerjaPensiunApi.getPeriode());

  const getUnitOrganisasi = useQuery(
    ["unit-organisasi-for-list", "dropdown", filter?.periodeGajianId],
    () =>
      PengajuanPekerjaPensiunApi.getUnitOrganisasiForList({
        periode_tahun: filter?.periodeGajianId
      }),
    { enabled: Boolean(filter?.periodeGajianId) }
  );

  const getPengajuanPekerjaPensiun = useQuery(
    ["pengajuan-pekerja-pensiun", "list", searchKey, onFilter, pagination.pageCurrent, pagination.dataLength],
    () =>
      PengajuanPekerjaPensiunApi.getList({
        periode_tahun: onFilter.periodeGajianId,
        master_unit_organisasi_id: onFilter.unitOrganisasiId,
        search: searchKey,
        page: pagination.pageCurrent,
        limit: pagination.dataLength
      }),
    { enabled: Boolean(onFilter?.periodeGajianId) }
  );
  const exportPengajuanPekerjaPensiun = useMutation((data) => PengajuanPekerjaPensiunApi.getExport({
    periode_tahun: data?.periodeGajianId,
    master_unit_organisasi_id: data?.unitOrganisasiId
  }), {
    onSuccess: (link) => {
      toastTrigger({ variant: "success", message: "Export berhasil" });
      exportHandler(link, "Data Pengajuan Pekerja Pensiun");
    },
    onError: () => {
      toastTrigger({ variant: "danger", message: "Export gagal" });
    }
  });

  const searchHandler = (e) => setSearchKey(e?.target?.value ? e.target.value : undefined);
  const onCreateButtonClickHandler = () => navigate("tambah");
  const onDetailButtonClickHandler = (data) => navigate("detail/" + data?.pekerja_pengajuan_pensiun_id);
  const onPaginationChange = (data) => setPagination({ ...pagination, pageCurrent: data });
  const onPaginationDataLengthChange = (data) => setPagination({ ...pagination, dataLength: data });

  //Converter hari ke format "Tahun BUlan Hari"
  // const MasaKerjaConverter = (data) => {
  //   let years = Math.floor(data / 365);
  //   let months = Math.floor((data % 365) / 30);
  //   let days = Math.floor((data % 365) % 30);
  //   return `${years} Tahun ${months} Bulan ${days} Hari`;
  // }

  return (
    <>
      <ListLayout
        showExportExcelButton
        customLeftTopSection={() => (
          <div className="d-flex align-items-end mb-3 ">
            <div style={{ width: 300 }} className="me-2">
              <InputSearch onChange={debounce(searchHandler, 1500)} />
            </div>
            <div className="me-2" style={{ width: 350 }}>
              <Select
                noMargin
                label="Periode Penggajian"
                placeholder="Pilih periode penggajian"
                defaultValue={getPeriode?.data?.find((item) => item.value === filter?.periodeGajianId)}
                options={getPeriode?.data ?? []}
                onChange={(val) => setFilter({ ...filter, periodeGajianId: val.value })}
                loading={getPeriode.isFetching}
                errorFetch={getPeriode.isError}
              />
            </div>
            <div style={{ width: 350 }}>
              <Select
                noMargin
                disable={Boolean(!filter?.periodeGajianId)}
                label="Unit Organisasi"
                placeholder="Pilih unit organisasi"
                defaultValue={getUnitOrganisasi?.data?.find((item) => item.value === filter?.unitOrganisasiId)}
                options={getUnitOrganisasi?.data ?? []}
                onChange={(val) => setFilter({ ...filter, unitOrganisasiId: val.value })}
                loading={getUnitOrganisasi.isFetching}
                errorFetch={getUnitOrganisasi.isError}
              />
            </div>
            <div className="ms-2">
              <ButtonCreate
                text="CARI"
                tooltip={false}
                onClick={() =>
                  setOnFilter({
                    ...onFilter,
                    periodeGajianId: filter?.periodeGajianId,
                    unitOrganisasiId: filter?.unitOrganisasiId
                  })
                }
              />
            </div>
          </div>
        )}
        onExportExcelButtonClick={() => exportPengajuanPekerjaPensiun.mutate(onFilter)}
        // onExportPdfButtonClick={() => exportPengajuanPekerjaPensiun.mutate("pdf")}
        loadingExportButton={exportPengajuanPekerjaPensiun.isLoading}
        showRightTopButton={access.canCreate() ? true : false}
        loading={getPengajuanPekerjaPensiun.isFetching}
        error={getPengajuanPekerjaPensiun.isError}
        errorText={errorFetchingTableMapper(getPengajuanPekerjaPensiun.error)}
        dataTotal={getPengajuanPekerjaPensiun?.data?.total}
        dataLength={pagination.dataLength}
        pageCurrent={pagination.pageCurrent}
        pageTotal={getPengajuanPekerjaPensiun?.data?.last_page}
        onCreateButtonClick={onCreateButtonClickHandler}
        onDataLengthChange={onPaginationDataLengthChange}
        onPaginationChange={onPaginationChange}
        data={getPengajuanPekerjaPensiun?.data?.data}
        tableHeader={[
          {
            text: "NIK"
          },
          {
            text: "Pekerja"
          },
          {
            text: "Jabatan"
          },
          {
            text: "Unit Organisasi"
          },
          {
            text: "Tanggal Setup"
          },
          {
            text: "Tanggal Lahir"
          },
          {
            text: "Tanggal Pengangkatan"
          },
          {
            text: "Tanggal Batas Pensiun"
          },
          {
            text: "Umur"
          },
          {
            text: "Masa Kerja"
          },
          {
            text: "Total Uang PHK"
          },
          {
            text: "Aksi",
            props: {
              width: 50,
              fixed: true
            }
          }
        ]}
        tableBody={[
          {
            field: "nip"
          },
          {
            field: "pekerja_nama"
          },
          {
            field: "master_jabatan_nama"
          },
          {
            field: "master_unit_organisasi_nama"
          },
          {
            customField: (val) => (val?.tgl_setup ? date.getSlashDMY(new Date(val.tgl_setup)) : "-")
          },
          {
            customField: (val) => (val?.tgl_lahir ? date.getSlashDMY(new Date(val.tgl_lahir)) : "-")
          },
          {
            customField: (val) => (val?.tgl_pengangkatan ? date.getSlashDMY(new Date(val.tgl_pengangkatan)) : "-")
          },
          {
            customField: (val) => (val?.tgl_batas_pensiun ? date.getSlashDMY(new Date(val.tgl_batas_pensiun)) : "-")
          },
          {
            field: "umur_pekerja"
          },
          {
            field: "masa_kerja"
          },
          // {
          //   customField: (val) => val?.masa_kerja ? MasaKerjaConverter(val?.masa_kerja) : "-"
          // },
          {
            customField: (data) =>
              data?.peraturan_total_uang_phk != null
                ? `${number.getWithComa(parseInt(data?.peraturan_total_uang_phk), { minimumFractionDigits: 0 })}`
                : "-",
            props: { textRight: true }
          },
          {
            props: { fixed: true },
            customField: (data) => (
              <ButtonGroup size="sm">
                {access.canRead() && (
                  <ButtonDetail icon noText onClick={() => onDetailButtonClickHandler(data, "READ")} />
                )}
              </ButtonGroup>
            )
          }
        ]}
      />
    </>
  );
};
