import {
  ButtonFilter, InfoItemVertical, InputSearch, ModalFilter, rupiahConvert, Select, toastTrigger
} from "components";
import { Formik, useFormikContext } from "formik";
import { useModalConfirm } from "hooks";
import { ListLayout } from "layouts";
import { debounce } from "lodash";
import { useState } from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import { FiFileText } from "react-icons/fi";
import { useMutation, useQuery } from "react-query";
import { errorFetchingSelectMapper, errorFetchingTableMapper, exportHandler } from "utilities";
import PerhitunganPajakBulananApi from "./__LaporanPajakTahunanApi__";
import { formValidationSchema } from "./__LaporanPajakTahunanUtils__";

const ModalFilterBody = () => {
  const { values, setValues, errors, touched } = useFormikContext();

  const getPeriodePenggajian = useQuery(["periode-rekap-order", "dropdown"], () =>
    PerhitunganPajakBulananApi.getPeriodePenggajianHalden()
  );

  const getUnitOrganisasi = useQuery(
    ["unit-organisasi", "dropdown"],
    () => PerhitunganPajakBulananApi.getUnitOrganisasiHalden(values?.idPeriode),
    {
      enabled: values?.idPeriode !== undefined
    }
  );

  return (
    <>
      <Select
        label="Periode Penggajian"
        placeholder="Pilih periode penggajian"
        defaultValue={getPeriodePenggajian?.data?.find((val) => val.value === values.idPeriode)}
        loading={getPeriodePenggajian.isFetching}
        options={getPeriodePenggajian?.data ?? []}
        onChange={(val) => {
          setValues({
            ...values,
            idPeriode: val.value,
            namaPeriode: val.label,
            idBulan: undefined,
            namaBulan: undefined,
            tanggalPeriode: undefined,
            tglAwal: undefined,
            tglAkhir: undefined,
            idUnitOrganisasi: undefined
          });
        }}
        errorFetch={getPeriodePenggajian.isError}
        errorFetchText={errorFetchingSelectMapper(getPeriodePenggajian.error)}
        error={Boolean(errors.idPeriode && touched.idPeriode)}
        errorText={Boolean(errors.idPeriode && touched.idPeriode) && errors.idPeriode}
      />
      <Select
        disable={values.idPeriode === undefined}
        label="Unit Organisasi"
        placeholder="Pilih unit organisasi"
        loading={getUnitOrganisasi.isFetching}
        defaultValue={getUnitOrganisasi?.data?.find((val) => val.value === values.idUnitOrganisasi)}
        options={getUnitOrganisasi?.data ?? []}
        onChange={(val) => {
          setValues({
            ...values,
            idUnitOrganisasi: val.value
          });
        }}
        errorFetch={getUnitOrganisasi.isError}
        errorFetchText={errorFetchingSelectMapper(getUnitOrganisasi.error)}
        error={Boolean(errors.idUnitOrganisasi && touched.idUnitOrganisasi)}
        errorText={Boolean(errors.idUnitOrganisasi && touched.idUnitOrganisasi) && errors.idUnitOrganisasi}
      />
    </>
  );
};

export const LaporanPajakTahunanList = () => {
  // const access = useAccessRights("PAJAK_TAHUNAN");
  // const navigate = useNavigate();
  const [searchKey, setSearchKey] = useState(undefined);
  const rupiah = rupiahConvert();
  // const date = dateConvert();
  const modalConfirm = useModalConfirm();

  const searchHandler = (e) => {
    setSearchKey(e?.target?.value ? e.target.value : undefined);
  };

  const [pagination, setPagination] = useState({
    pageCurrent: 1,
    dataLength: 10
  });

  const [filter, setFilter] = useState({
    show: false,
    active: false,
    data: {
      namaKomponenPayroll: undefined,
      idPeriode: undefined,
      namaPeriode: undefined,
      idBulan: undefined,
      namaBulan: undefined,
      tanggalPeriode: undefined,
      tglAwal: undefined,
      tglAkhir: undefined
    }
  });

  // const [modal, setModal] = useState({
  //   show: false,
  //   action: "",
  //   data: {
  //     namaKomponenPayroll: undefined,
  //     namaPeriode: undefined,
  //     namaBulan: undefined,
  //     tanggalPeriode: undefined
  //   }
  // });

  const getList = useQuery(
    [
      "laporan-pajak-tahunan",
      "list",
      searchKey,
      pagination.pageCurrent,
      pagination.dataLength,
      filter?.data?.idPeriode,
      filter?.data?.idUnitOrganisasi
    ],
    () =>
      PerhitunganPajakBulananApi.getList({
        search: searchKey,
        periode_tahun: filter?.data?.idPeriode,
        master_unit_organisasi_id: filter?.data?.idUnitOrganisasi,
        page: pagination.pageCurrent,
        limit: pagination.dataLength
      }),
    {
      enabled: filter?.data?.idPeriode !== undefined
    }
  );

  const getExportPerhitunganPajakBulanan = useMutation(
    (type) =>
      PerhitunganPajakBulananApi.getExport({
        to: type,
        periode_tahun: filter?.data?.idPeriode,
        master_unit_organisasi_id: filter?.data?.idUnitOrganisasi
      }),
    {
      onSuccess: (link) => {
        toastTrigger({ variant: "success", message: "Export berhasil" });
        exportHandler(link, "Data Laporan Pajak Tahunan");
      },
      onError: () => {
        toastTrigger({ variant: "danger", message: "Export gagal" });
      }
    }
  );

  const onFilterResetHandler = (resetForm) => {
    setFilter({
      show: false,
      action: "",
      data: {
        namaKomponenPayroll: undefined,
        idPeriode: undefined,
        namaPeriode: undefined
      }
    });
    resetForm();
  };

  const onFilterHideHandler = () =>
    setFilter({
      ...filter,
      show: false,
      action: ""
    });

  const onFilterHandler = (values) => {
    setFilter({
      show: false,
      active: Boolean(Object.values(values).find((val) => val)),
      data: values
    });
  };

  const submitValidationHandler = (errors, values) =>
    new Promise((resolve, reject) => {
      const getError = Object.values(errors);

      if (getError.length > 0) {
        reject(getError);
      } else {
        resolve();
      }
    });

  const preSubmitHandler = (values, validateForm, setTouched, setErrors, setSubmitting) => {
    setSubmitting(true);
    validateForm().then(async (err) => {
      setErrors(err);
      setTouched(err);
      await submitValidationHandler(err, values)
        .then(() => onFilterHandler(values))
        .catch((err) =>
          modalConfirm.trigger({
            type: "error",
            title: "Data Tidak Lengkap",
            data: err,
            onHide: () => modalConfirm.close()
          })
        )
        .finally(() => {
          setSubmitting(false);
        });
    });
  };

  const onPaginationChange = (data) => setPagination({ ...pagination, pageCurrent: data });

  const onPaginationDataLengthChange = (data) => setPagination({ ...pagination, dataLength: data });

  const TABLE_HEADER = [
    {
      text: "NIK"
    },
    {
      text: "Pekerja"
    },
    {
      text: "Jabatan"
    },
    {
      text: "Unit Organisasi"
    },
    {
      text: "Status Pajak"
    },
    {
      text: "NPWP"
    },
    {
      text: "Penghasilan Netto (Tahunan)"
    },
    {
      text: "Penghasilan Tidak Kena Pajak"
    },
    {
      text: "Penghasilan Kena Pajak"
    },
    {
      text: "Total PPH21(Tahunan)"
    },
    {
      text: "Total PPH21(Sudah Di Bayar)"
    },
    {
      text: "Koreksi PPH21"
    }
  ];

  const TABLE_BODY = [
    {
      field: "nip"
    },
    {
      field: "pekerja_nama"
    },
    {
      field: "master_jabatan_nama"
    },
    {
      field: "master_unit_organisasi_nama"
    },
    {
      field: "status_pajak"
    },
    {
      field: "no_npwp"
    },
    {
      props: { textRight: true },
      customField: (data) => rupiah.getWithComa(Number(data?.netto_tahunan ?? 0))
    },
    {
      props: { textRight: true },
      customField: (data) => rupiah.getWithComa(Number(data?.penghasilan_tidak_kena_pajak ?? 0))
    },
    {
      props: { textRight: true },
      customField: (data) => rupiah.getWithComa(Number(data?.penghasilan_kena_pajak ?? 0))
    },
    {
      props: { textRight: true },
      customField: (data) => rupiah.getWithComa(Number(data?.pph21_tahunan ?? 0))
    },
    {
      props: { textRight: true },
      customField: (data) => rupiah.getWithComa(Number(data?.pph21_sudah_bayar ?? 0))
    },
    {
      props: { textRight: true },
      customField: (data) => rupiah.getWithComa(Number(data?.koreksi_pph21 ?? 0))
    }
  ];

  return (
    <>
      <ListLayout
        customLeftTopSection={() => (
          <div className="align-items-end">
            <div className="d-flex">
              <div className="me-2">
                <InputSearch onChange={debounce(searchHandler, 1500)} />
              </div>
              <div>
                <ButtonFilter
                  active={filter?.active}
                  className="text-nowrap"
                  onClick={() => setFilter({ ...filter, show: true })}
                />
              </div>
            </div>
            <div className="d-flex mt-3">
              <div className="me-2">
                <InfoItemVertical className="me-2" label="Komponen Payroll " text={"PPH21 (Tahunan)"} />
              </div>
              <div className="me-2">
                <InfoItemVertical
                  className="me-2"
                  label="Periode Perencanaan Penggajian"
                  text={filter?.data?.namaPeriode ?? "-"}
                />
              </div>
            </div>
          </div>
        )}
        customRightTopSection={() => (
          <div className="d-flex flex-column flex-sm-row justify-content-center justify-content-md-end align-items-center">
            <Dropdown align="end">
              <Dropdown.Toggle
                size="sm"
                variant="outline-success"
                className="mx-2"
                disabled={getExportPerhitunganPajakBulanan?.isLoading}
              >
                {getExportPerhitunganPajakBulanan?.isLoading ? (
                  <Spinner size="sm" className="me-1" animation="border" />
                ) : (
                  <FiFileText className="mb-1 mr-3" />
                )}
                <span className="mx-1">{getExportPerhitunganPajakBulanan?.isLoading ? "Memproses..." : "EXPORT"}</span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="mt-2">
                <Dropdown.Item onClick={() => getExportPerhitunganPajakBulanan.mutate("excel")}>
                  Export Excel
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}
        loading={getList.isFetching}
        error={getList.isError}
        errorText={errorFetchingTableMapper(getList.error)}
        dataTotal={getList?.data?.total}
        dataLength={pagination.dataLength}
        pageCurrent={pagination.pageCurrent}
        pageTotal={getList?.data?.last_page}
        onDataLengthChange={onPaginationDataLengthChange}
        onPaginationChange={onPaginationChange}
        data={getList?.data?.data}
        tableHeader={TABLE_HEADER}
        tableBody={TABLE_BODY}
      />

      {/* ModalFilter */}
      <Formik initialValues={filter?.data} validationSchema={formValidationSchema.FormSearch}>
        {({ resetForm, values, validateForm, setTouched, setErrors, setSubmitting }) => (
          <ModalFilter
            show={filter.show}
            onReset={() => onFilterResetHandler(resetForm)}
            onHide={() => onFilterHideHandler()}
            onSubmit={() => {
              preSubmitHandler(values, validateForm, setTouched, setErrors, setSubmitting);
            }}
          >
            <ModalFilterBody />
          </ModalFilter>
        )}
      </Formik>
    </>
  );
};
