import { ButtonBack } from "components";
import { Formik } from "formik";
import { ContentLayout } from "layouts";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import PerhitunganUangPhkApi from "./__PerhitunganUangPhkApi__";
import { Content } from "./__PerhitunganUangPhkComps__";
import { formInitialValues } from "./__PerhitunganUangPhkUtilities__";

export const PerhitunganUangPhkDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  // const modalConfirm = useModalConfirm();

  const getDetailPerhitunganUangPhk = useQuery(["detail-perhitungan-uang-phk"], () =>
    PerhitunganUangPhkApi.getDetail(id)
  );

  return (
    <>
      <ContentLayout showBreadcrumb>
        {/* {getDetailPerhitunganUangPhk?.isFetching ? (
                    <DataStatus loading />
                ) : ( */}
        <>
          <div className="text-end mb-3">
            <ButtonBack tooltip={false} onClick={() => navigate("/operasional-sdm/transaksi/perhitungan-uang-phk")} />
          </div>
          <Formik enableReinitialize initialValues={formInitialValues(getDetailPerhitunganUangPhk?.data)}>
            <Content action="READ" />
          </Formik>
        </>
        {/* )} */}
      </ContentLayout>
    </>
  );
};
