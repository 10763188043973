import { Services } from "services";

class SanksiApi {
  async getList(params) {
    const fetch = await Services.get("/master/sanksi", { params });
    return fetch?.data?.data;
  }

  async getExport(params) {
    const fetch = await Services.get("/master/sanksi/export", { params });
    return fetch?.data?.data;
  }

  create(data) {
    return Services.post("/master/sanksi", data);
  }

  update(data, id) {
    return Services.put("/master/sanksi/" + id, data);
  }

  delete(id) {
    return Services.delete("/master/sanksi/" + id);
  }
}

export default new SanksiApi();
