import React from "react";
import { useFormikContext } from "formik";
import { InfoItemVertical, InputNumber, numberConvert, Input } from "components";

export const Content = ({ action }) => {
  const { values, errors, touched, setValues } = useFormikContext();
  const number = numberConvert();

  if (action === "READ") {
    return (
      <>
        <InfoItemVertical label="Periode Penggajian" text={values.periodePenggajianNama} />
        <InfoItemVertical
          label="Masa Kerja"
          text={values.masaKerja ? number.getWithComa(values.masaKerja, { minimumFractionDigits: 0 }) + " tahun" : "0"}
        />
        <InfoItemVertical
          label="Faktor Manfaat Uang Pesangon"
          text={
            values.faktorManfaatUangPesangon
              ? number.getWithComa(values.faktorManfaatUangPesangon, { minimumFractionDigits: 2 }) + "%"
              : "0%"
          }
        />
        <InfoItemVertical
          label="Faktor Manfaat Uang Penghargaan Masa Kerja"
          text={
            values.faktorManfaatUangPenghargaanMasaKerja
              ? number.getWithComa(values.faktorManfaatUangPenghargaanMasaKerja, { minimumFractionDigits: 2 }) + "%"
              : "0%"
          }
        />
        <InfoItemVertical
          label="Faktor Manfaat Uang Pengganti Hak"
          text={
            values.faktorManfaatUangPenggantiHak
              ? number.getWithComa(values.faktorManfaatUangPenggantiHak, { minimumFractionDigits: 2 }) + "%"
              : "0%"
          }
        />
        <InfoItemVertical
          label="Faktor Manfaat Uang Pisah"
          text={
            values.faktorManfaatUangPisah
              ? number.getWithComa(values.faktorManfaatUangPisah, { minimumFractionDigits: 2 }) + "%"
              : "0%"
          }
        />
      </>
    );
  }

  return (
    <>
      <Input
        name="periodePenggajianId"
        label="Periode Penggajian"
        placeholder="Masukan periode pengggajian"
        value={values?.periodePenggajianId ? String(values?.periodePenggajianId) : ""}
        onChange={(val) =>
          setValues({
            ...values,
            periodePenggajianId: val.target.value,
            periodePenggajianNama: val.target.value
          })
        }
        error={Boolean(errors.periodePenggajianId && touched.periodePenggajianId)}
        errorText={Boolean(errors.periodePenggajianId && touched.periodePenggajianId) && errors.periodePenggajianId}
      />
      <InputNumber
        name="masaKerja"
        label="Masa Kerja (tahun)"
        placeholder="Masukan masa kerja"
        value={values?.masaKerja ? number.getWithComa(values.masaKerja, { minimumFractionDigits: 0 }) : ""}
        onChange={(val) => setValues({ ...values, masaKerja: val })}
        error={Boolean(errors.masaKerja && touched.masaKerja)}
        errorText={Boolean(errors.masaKerja && touched.masaKerja) && errors.masaKerja}
      />
      <InputNumber
        name="faktorManfaatUangPesangon"
        label="Faktor Manfaat Uang Pesangon"
        placeholder="Masukan faktor manfaat uang pesangon"
        suffix="%"
        value={number.getWithComa(values.faktorManfaatUangPesangon, { minimumFractionDigits: 2 })}
        onChange={(val) => setValues({ ...values, faktorManfaatUangPesangon: val })}
        error={Boolean(errors.faktorManfaatUangPesangon && touched.faktorManfaatUangPesangon)}
        errorText={
          Boolean(errors.faktorManfaatUangPesangon && touched.faktorManfaatUangPesangon) &&
          errors.faktorManfaatUangPesangon
        }
      />
      <InputNumber
        name="faktorManfaatUangPenghargaanMasaKerja"
        label="Faktor Manfaat Uang Penghargaan Masa Kerja"
        placeholder="Masukan faktor manfaat uang penghargaan masa kerja"
        suffix="%"
        value={number.getWithComa(values.faktorManfaatUangPenghargaanMasaKerja, { minimumFractionDigits: 2 })}
        onChange={(val) => setValues({ ...values, faktorManfaatUangPenghargaanMasaKerja: val })}
        error={Boolean(errors.faktorManfaatUangPenghargaanMasaKerja && touched.faktorManfaatUangPenghargaanMasaKerja)}
        errorText={
          Boolean(errors.faktorManfaatUangPenghargaanMasaKerja && touched.faktorManfaatUangPenghargaanMasaKerja) &&
          errors.faktorManfaatUangPenghargaanMasaKerja
        }
      />
      <InputNumber
        name="faktorManfaatUangPenggantiHak"
        label="Faktor Manfaat Uang Pengganti Hak"
        placeholder="Masukan faktor manfaat uang pengganti hak"
        suffix="%"
        value={number.getWithComa(values.faktorManfaatUangPenggantiHak, { minimumFractionDigits: 2 })}
        onChange={(val) => setValues({ ...values, faktorManfaatUangPenggantiHak: val })}
        error={Boolean(errors.faktorManfaatUangPenggantiHak && touched.faktorManfaatUangPenggantiHak)}
        errorText={
          Boolean(errors.faktorManfaatUangPenggantiHak && touched.faktorManfaatUangPenggantiHak) &&
          errors.faktorManfaatUangPenggantiHak
        }
      />
      <InputNumber
        name="faktorManfaatUangPisah"
        label="Faktor Manfaat Uang Pisah"
        placeholder="Masukan faktor manfaat uang pisah"
        suffix="%"
        value={number.getWithComa(values.faktorManfaatUangPisah, { minimumFractionDigits: 2 })}
        onChange={(val) => setValues({ ...values, faktorManfaatUangPisah: val })}
        error={Boolean(errors.faktorManfaatUangPisah && touched.faktorManfaatUangPisah)}
        errorText={
          Boolean(errors.faktorManfaatUangPisah && touched.faktorManfaatUangPisah) && errors.faktorManfaatUangPisah
        }
      />
    </>
  );
};
