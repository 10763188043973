import { Services } from "services";

class UpahRitaseApi {
  async getList(params) {
    const fetch = await Services.get("/master/upah-ritase", { params });
    return fetch?.data?.data;
  }

  async getDetail(id) {
    const fetch = await Services.get("/master/upah-ritase/" + id);
    return fetch?.data?.data;
  }

  async getJenisRitase() {
    const fetch = await Services.get("/master/jenis-ritase/dropdown");
    return fetch?.data?.data.map((val) => ({
      value: val?.jenis_ritase_id,
      label: val?.jenis_ritase_nama,
      kode: val?.jenis_ritase_kode
    }));
  }

  async getExport(params) {
    const fetch = await Services.get("/master/upah-ritase/export", { params });
    return fetch?.data?.data;
  }

  create(data) {
    return Services.post("/master/upah-ritase", data);
  }

  update(data, id) {
    return Services.put("/master/upah-ritase/" + id, data);
  }

  delete(id) {
    return Services.delete("/master/upah-ritase/" + id);
  }
}

export default new UpahRitaseApi();
