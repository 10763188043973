import { Services } from "services";

class GradeApi {
  async get(params) {
    const fetch = await Services.get("/master/grade", { params });
    return fetch.data;
  }

  update(data) {
    return Services.post("/master/grade", data);
  }
}

export default new GradeApi();
