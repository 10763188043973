export const formSubmitValueMapper = (values) => ({
  master_jabatan_id: values.jabatan_id?.toString(),
  master_unit_organisasi_id: values.unit_organisasi_id?.toString(),
  master_grade_id: values.grade_id?.toString(),
  master_pangkat_id: values.pangkat_id?.toString(),
  master_f1_kode: values.f1_kode?.toString(),
  master_f2_kode: values.f2_kode?.toString(),
  master_f3_kode: values.f3_kode?.toString(),
  master_judgement1_kode: values.a1_kode?.toString(),
  master_f4_kode: values.f4_kode?.toString(),
  master_f5_kode: values.f5_kode?.toString(),
  master_judgement2_kode: values.a2_kode?.toString(),
  master_f6_kode: values.f6_kode?.toString(),
  master_f7_kode: values.f7_kode?.toString(),
  master_f8_kode: values.f8_kode?.toString(),
  master_judgement3_kode: values.a3_kode?.toString()
});
