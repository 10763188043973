import { getFunction } from "../__ShiftUtils__";

export const formSubmitMapper = (values = {}) => ({
  master_shift_nama: values.nama,
  kode_shift: values?.kode,
  jam_masuk: values.jamMasuk ? getFunction.hoursMinutes({ date: values.jamMasuk }) : "",
  jam_keluar: values.jamPulang ? getFunction.hoursMinutes({ date: values.jamPulang }) : "",
  scan: values?.scanId,
  durasi_opsi: values?.durasiId,
  hitungan_shift: values?.counterAs,
  shift_overtime: values?.shiftLemburId,
  shift_pendek: values?.shiftTerpendekId,
  durasi_maksimal: values?.durasiMaksiman,
  jam_lembur_mulai: values.jamMulaiLembur ? getFunction.hoursMinutes({ date: values.jamMulaiLembur }) : "",
  jam_mulai_lembur_makan: values.jamMulaiLemburMakan ? getFunction.hoursMinutes({ date: values.jamMulaiLemburMakan }) : "",
  jam_mulai_terlambat: values.jamMulaiTerlambat ? getFunction.hoursMinutes({ date: values.jamMulaiTerlambat }) : "",
});
