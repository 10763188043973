import { Services } from "services";
import { fetchDropdownMapper } from "utilities";

const fetchDropdown = fetchDropdownMapper(Services);

class SetupProporsiUpahLemburApi {
  async getList(params) {
    const fetch = await Services.get("/master/proporsi-upah-lembur", { params });
    return fetch?.data?.data;
  }

  async getExport(params) {
    const fetch = await Services.get("/master/proporsi-upah-lembur/export", { params });
    return fetch?.data?.data;
  }

  async getPeriodePenggajian() {
    const getPeriodePenggajian = await fetchDropdown("/master/grade-nominal/dropdown", {
      value: "periode_gajian_id",
      label: "periode_perencanaan"
    });
    return getPeriodePenggajian;
  }

  async getJenisUpahLembur() {
    const getJenisUpahLembur = await fetchDropdown("/master/jenis-lembur/dropdown", {
      value: "master_jenis_lembur_id",
      label: "master_jenis_lembur_nama"
    });
    return getJenisUpahLembur;
  }

  async getSesiPerhitungan() {
    const getSesiPerhitungan = await fetchDropdown("/master/sesi-perhitungan/dropdown", {
      value: "master_sesi_perhitungan_id",
      label: "master_sesi_perhitungan_nama"
    });
    return getSesiPerhitungan;
  }

  getStatus() {
    const getStatus = [
      { value: 1, label: "Aktif" },
      { value: 2, label: "Tidak Aktif" }
    ];
    return getStatus;
  }

  create(data) {
    return Services.post("/master/proporsi-upah-lembur", data);
  }

  update(data, id) {
    return Services.put("/master/proporsi-upah-lembur/" + id, data);
  }

  delete(id) {
    return Services.delete("/master/proporsi-upah-lembur/" + id);
  }
}

export default new SetupProporsiUpahLemburApi();
