import { InfoItemVertical, Input, Select, TextArea } from "components";
import { useFormikContext } from "formik";
import { useQuery } from "react-query";
import { errorFetchingSelectMapper } from "utilities";
import TingkatCompensableFactorApi from "../__TingkatCompensableFactorApi__";

export const Content = ({ action }) => {
  const { values, errors, touched, handleChange, setValues } = useFormikContext();
  const getMasterCompensable = useQuery(["master-compensable", "dropdown"], () =>
    TingkatCompensableFactorApi.getMasterCompensable()
  );

  if (action === "READ") {
    return (
      <>
        <InfoItemVertical label="Master Compensable" text={values.namaMasterCompensable} />
        <InfoItemVertical label="Kode" text={values.kode} />
        <InfoItemVertical label="Nama Tingkat" text={values.nama} />
        <InfoItemVertical label="Keterangan" text={values.keterangan} />
      </>
    );
  }

  return (
    <>
      <Select
        label="Master Compensable"
        placeholder="Pilih master compensable"
        defaultValue={getMasterCompensable?.data?.find((item) => item.value === values?.idMasterCompensable)}
        options={getMasterCompensable?.data ?? []}
        onChange={(val) =>
          setValues({
            ...values,
            idMasterCompensable: val.value,
            namaMasterCompensable: val.label
          })
        }
        loading={getMasterCompensable.isFetching}
        errorFetch={getMasterCompensable.isError}
        errorFetchText={errorFetchingSelectMapper(getMasterCompensable.error)}
      />
      <Input
        label="Kode"
        name="kode"
        placeholder="Masukkan kode"
        value={values.kode === undefined ? "" : values.kode}
        onChange={handleChange}
        error={Boolean(errors.kode && touched.kode)}
        errorText={Boolean(errors.kode && touched.kode) && errors.kode}
      />
      <Input
        label="Nama Tingkat"
        name="nama"
        placeholder="Masukkan nama tingkat"
        value={values.nama === undefined ? "" : values.nama}
        onChange={handleChange}
        error={Boolean(errors.nama && touched.nama)}
        errorText={Boolean(errors.nama && touched.nama) && errors.nama}
      />
      <TextArea
        label="Keterangan"
        name="keterangan"
        placeholder="Masukkan keterangan"
        value={values.keterangan === undefined ? "" : values.keterangan}
        onChange={handleChange}
        error={Boolean(errors.keterangan && touched.keterangan)}
        errorText={Boolean(errors.keterangan && touched.keterangan) && errors.keterangan}
      />
    </>
  );
};
