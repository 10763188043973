import React, { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { ListLayout } from "layouts";
import { ButtonGroup } from "react-bootstrap";
import {
  ButtonDetail,
  ButtonFilter,
  ModalFilter,
  InputSearch,
  numberConvert,
  Select,
  InfoItemVertical
} from "components";
import { useAccessRights } from "hooks";
import { Formik, useFormikContext } from "formik";
import { debounce } from "lodash";
import SlipGajiApi from "./__SlipGajiApi__";

const ModalFilterBody = () => {
  const { values, setValues } = useFormikContext();

  const getPeriode = useQuery(["periode-penggajian-for-list", "dropdown"], () => SlipGajiApi.getPeriodeForList());
  const getUnitOrganisasi = useQuery(
    ["unit-organisasi-for-list", "dropdown", values?.periodeGajianId],
    () =>
      SlipGajiApi.getUnitOrganisasi({
        periode_tahun: values?.periodeGajianId
      }),
    {
      enabled: Boolean(values?.periodeGajianId)
    }
  );
  const getTanggal = useQuery(
    ["tanggal-penggajian", "dropdown", values?.periodeGajianId],
    () =>
      SlipGajiApi.getTanggal({
        periode_tahun: values?.periodeGajianId
      }),
    {
      enabled: Boolean(values?.periodeGajianId)
    }
  );

  return (
    <>
      <Select
        label="Periode Penggajian"
        placeholder="Pilih periode penggajian"
        defaultValue={getPeriode?.data?.find((item) => item.value === values?.periodeGajianId)}
        options={getPeriode?.data ?? []}
        onChange={(val) => setValues({ ...values, periodeGajianId: val.value, periodePerencanaan: val.label })}
        loading={getPeriode.isFetching}
        errorFetch={getPeriode.isError}
      />
      <Select
        disable={Boolean(!values?.periodeGajianId)}
        label="Tanggal Penggajian"
        placeholder="Pilih tanggal penggajian"
        defaultValue={getTanggal?.data?.find((item) => item.value === values?.tglId)}
        options={getTanggal?.data ?? []}
        onChange={(val) =>
          setValues({
            ...values,
            tglId: val.value,
            tglNama: val.label,
            tglAwal: val.tglAwal,
            tglAkhir: val.tglAkhir
          })
        }
        loading={getTanggal.isFetching}
        errorFetch={getTanggal.isError}
      />
      <Select
        label="Unit Organisasi"
        placeholder="Pilih unit organisasi"
        defaultValue={getUnitOrganisasi?.data?.find((item) => item.value === values.unitOrganisasiId)}
        options={getUnitOrganisasi?.data ?? []}
        onChange={(val) => setValues({ ...values, unitOrganisasiId: val.value })}
        loading={getUnitOrganisasi.isFetching}
        errorFetch={getUnitOrganisasi.isError}
      />
    </>
  );
};

export const SlipGajiList = () => {
  const access = useAccessRights("SLIP_GAJI");
  const number = numberConvert();
  const navigate = useNavigate();

  const [searchKey, setSearchKey] = useState(undefined);
  const [pagination, setPagination] = useState({
    pageCurrent: 1,
    dataLength: 10
  });

  const [filter, setFilter] = useState({
    show: false,
    active: false,
    data: {
      periodeGajianId: undefined,
      unitOrganisasiId: undefined,
      tglId: undefined,
      tglAwal: undefined,
      tglAkhir: undefined
    }
  });

  const getSlipGajiList = useQuery(
    [
      "slip-gaji",
      "list",
      searchKey,
      pagination.pageCurrent,
      pagination.dataLength,
      filter.data?.periodeGajianId,
      filter.data?.unitOrganisasiId,
      filter.data?.tglAwal,
      filter.data?.tglAkhir
    ],
    () =>
      SlipGajiApi.getList({
        search: searchKey,
        page: pagination.pageCurrent,
        limit: pagination.dataLength,
        periode_tahun: filter.data?.periodeGajianId,
        master_unit_organisasi_id: filter.data?.unitOrganisasiId,
        tgl_bulan_awal: filter.data?.tglAwal,
        tgl_bulan_akhir: filter.data?.tglAkhir
      }),
    {
      enabled: Boolean(
        filter?.data?.periodeGajianId &&
          filter?.data?.unitOrganisasiId &&
          filter?.data?.tglAwal &&
          filter?.data?.tglAkhir
      )
    }
  );

  const onCreateButtonClickHandler = () => navigate("tambah");

  const onDetailButtonClickHandler = (data) => navigate("detail/" + data?.pekerja_payroll_bulanan_id);

  const onPaginationChange = (data) => setPagination({ ...pagination, pageCurrent: data });

  const onPaginationDataLengthChange = (data) => setPagination({ ...pagination, dataLength: data });

  const searchHandler = (e) => {
    setSearchKey(e?.target?.value ? e.target.value : undefined);
  };

  const dataInfo = getSlipGajiList?.data?.info;

  const TABLE_HEADER = [
    {
      text: "NIK"
    },
    {
      text: "Pekerja"
    },
    {
      text: "Jabatan"
    },
    {
      text: "Unit Organisasi"
    },
    {
      text: "Status Penggajian"
    },
    {
      text: "Total Gaji"
    },
    {
      text: "Aksi",
      props: {
        fixed: true
      }
    }
  ];

  const TABLE_BODY = [
    { field: "nip" },
    { field: "pekerja_nama" },
    { field: "master_jabatan_nama" },
    { field: "master_unit_organisasi_nama" },
    { customField: (val) => (val?.status_penggajian === 1 ? "Aktif" : "Tidak Aktif") },
    {
      props: { textRight: true },
      customField: (val) => "Rp" + number.getWithComa(val?.total_gaji, { minimumFractionDigits: 0 })
    },
    {
      props: {
        width: 50,
        fixed: true,
        textCenter: true
      },
      customField: (data) => (
        <ButtonGroup size="sm">
          {access.canRead() && (
            <ButtonDetail icon tooltip={false} noText onClick={() => onDetailButtonClickHandler(data)} />
          )}
        </ButtonGroup>
      )
    }
  ];

  return (
    <>
      <ListLayout
        customLeftTopSection={() => (
          <>
            <div className="d-flex align-items-end mb-3">
              <div style={{ width: 300 }} className="me-2">
                <InputSearch onChange={debounce(searchHandler, 1500)} />
              </div>
              <div>
                <ButtonFilter
                  active={filter?.active}
                  className="text-nowrap"
                  onClick={() => setFilter({ ...filter, show: true })}
                />
              </div>
            </div>
            <div className="d-flex align-items-end mb-3 gap-2">
              <InfoItemVertical label="Komponen Payroll" text={dataInfo?.komponen_payroll} />
              <InfoItemVertical
                label="Periode Perencanaan Penggajian"
                // text={dataInfo?.periode_perencanaan_penggajian}
                text={filter?.data?.periodeGajianId}
              />
            </div>
          </>
        )}
        customRightTopSection={() => <></>}
        loading={getSlipGajiList?.isFetching}
        error={getSlipGajiList?.isError}
        dataTotal={getSlipGajiList?.data?.total}
        dataLength={pagination.dataLength}
        pageCurrent={pagination.pageCurrent}
        pageTotal={getSlipGajiList?.data?.last_page}
        onSearchChange={debounce(searchHandler, 1500)}
        onCreateButtonClick={onCreateButtonClickHandler}
        onDataLengthChange={onPaginationDataLengthChange}
        onPaginationChange={onPaginationChange}
        data={getSlipGajiList?.data?.data}
        tableHeader={TABLE_HEADER}
        tableBody={TABLE_BODY}
      />

      {/* ModalFilter */}
      <Formik
        initialValues={{
          periodeGajianId: filter?.data?.periodeGajianId,
          unitOrganisasiId: filter?.data?.unitOrganisasiId,
          tglAwal: filter?.data?.tglAwal,
          tglAkhir: filter?.data?.tglAkhir
        }}
      >
        {({ resetForm, values }) => (
          <ModalFilter
            show={filter.show}
            onReset={resetForm}
            onHide={() =>
              setFilter({
                ...filter,
                show: false
              })
            }
            onSubmit={() => {
              setFilter({
                show: false,
                active: Boolean(Object.values(values).find((val) => val)),
                data: values
              });
            }}
          >
            <ModalFilterBody />
          </ModalFilter>
        )}
      </Formik>
    </>
  );
};
