import { ButtonBack, ContentLayout } from "components";
import { useNavigate } from "react-router-dom";
import { Content } from "./_UraianJabatanComps__";

export const UraianJabatanCreate = () => {
  const navigate = useNavigate();

  return (
    <>
      <ContentLayout>
        <div className="d-flex justify-content-end mb-3">
          <ButtonBack tooltip={false} onClick={() => navigate("/organisasi/master/uraian-jabatan")} />
        </div>
        <Content action="CREATE" />
      </ContentLayout>
    </>
  );
};
