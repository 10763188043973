import { TunjanganUpahRitaseBulananList, TunjanganUpahRitaseBulananCreate, TunjanganUpahRitaseBulananDetail, TunjanganUpahRitaseBulananPostList } from "pages/Payroll";

export const TunjanganUpahRitaseBulanan = {
    component: <TunjanganUpahRitaseBulananList />,
    link: "tunjangan-upah-ritase-bulanan",
    name: "Tunjangan Upah Ritase CP Bulanan",
    access: "TUNJANGAN_UPAH_RITASE_CP",
    child: [
        {
            name: "List Pekerja",
            link: "/post-list",
            component: <TunjanganUpahRitaseBulananPostList />
        },
        {
            name: "Tambah",
            link: "/tambah",
            component: <TunjanganUpahRitaseBulananCreate />
        },
        {
            name: "Detail",
            link: "/detail/:id",
            component: <TunjanganUpahRitaseBulananDetail />
        }
    ]
};
