import { Services } from "services";

class ProyekApi {
    async getList(params) {
        const fetch = await Services.get("/master/proyek", { params });
        return fetch?.data?.data;
    }

    async getDetail(id) {
        const fetch = await Services.get("/master/proyek/" + id);
        return fetch?.data?.data;
    }

    async getWilayah(path, params) {
        const fetch = await Services.get(`/master/wilayah/${path}/dropdown`, { params });

        if (path === "provinsi") {
            return fetch?.data?.data.map((val) => ({
                value: val?.provinsi_kode,
                label: val?.provinsi_nama
            }));
        }

        if (path === "kabupaten") {
            return fetch?.data?.data.map((val) => ({
                value: val?.kabupaten_kode,
                label: val?.kabupaten_nama
            }));
        }

        if (path === "kecamatan") {
            return fetch?.data?.data.map((val) => ({
                value: val?.kecamatan_kode,
                label: val?.kecamatan_nama
            }));
        }

        if (path === "desa") {
            return fetch?.data?.data.map((val) => ({
                value: val?.desa_kode,
                label: val?.desa_nama
            }));
        }
    }

    async getCustomer() {
        const fetch = await Services.get("/master/customer/dropdown");
        return fetch?.data?.data.map((val) => ({
            value: val?.customer_id,
            label: val?.customer_nama,
        }));
    }

    async getExport(params) {
        const fetch = await Services.get("/master/proyek/export", { params });
        return fetch?.data?.data;
    }

    create(data) {
        return Services.post("/master/proyek", data);
    }

    update(data, id) {
        return Services.put("/master/proyek/" + id, data);
    }

    delete(id) {
        return Services.delete("/master/proyek/" + id);
    }
}

export default new ProyekApi();
