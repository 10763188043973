import React, { useState } from "react";
import { useMutation } from "react-query";
import { ButtonGroup } from "react-bootstrap";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { Formik, useFormikContext } from "formik";
import {
  Button,
  TableList,
  ButtonCreate,
  ButtonUpdate,
  ButtonDelete,
  ModalCreate,
  ModalUpdate,
  Select,
  Input
} from "components";
import { errorSubmitMapper, errorFetchingSelectMapper } from "utilities";
import { useModalConfirm } from "hooks";
import { formInitialValues, formSubmitMapper, formValidationSchema } from "../__PekerjaUtils__";
import PekerjaApi from "../__PekerjaApi__";

const ModalJabatanContent = () => {
  const { values, handleChange, errors, touched, setValues } = useFormikContext();
  const getJenisJabatan = useQuery(["jenisJabatan", "dropdown"], () => PekerjaApi.getJenisJabatan());
  const getJabatan = useQuery(
    ["jabatan", "dropdown", values?.jenisJabatanId],
    () => PekerjaApi.getJabatan({ jenis_jabatan: values.jenisJabatanId }),
    {
      enabled: !!values?.jenisJabatanId
    }
  );
  const getGrade = useQuery(
    ["grade", "dropdown", values?.jabatanId],
    () => PekerjaApi.getGrade({ master_jabatan_id: values?.jabatanId }),
    {
      enabled: !!values?.jabatanId
    }
  );
  const getStep = useQuery(
    ["step", "dropdown", values?.grade],
    () => PekerjaApi.getStep({ master_grade_id: values?.grade }),
    {
      enabled: !!values?.grade
    }
  );

  return (
    <>
      <Select
        label="Jenis Jabatan"
        loading={getJenisJabatan.isFetching}
        options={getJenisJabatan?.data ?? []}
        defaultValue={getJenisJabatan?.data?.find((item) => item.value === values.jenisJabatanId)}
        onChange={(val) => {
          setValues({
            ...values,
            jenisJabatanId: val?.value,
            jenisJabatanNama: val?.label,
            kelompokJabatanNama: "",
            unitOrganisasiNama: ""
          });
        }}
        error={Boolean(errors.jenisJabatanId && touched.jenisJabatanId)}
        errorText={Boolean(errors.jenisJabatanId && touched.jenisJabatanId) && errors.jenisJabatanId}
        errorFetch={getJenisJabatan.isError}
        errorFetchText={errorFetchingSelectMapper(getJenisJabatan.error)}
      />
      <Select
        disable={!values.jenisJabatanId}
        label="Jabatan"
        loading={getJabatan.isFetching}
        options={getJabatan?.data ?? []}
        defaultValue={getJabatan?.data?.find((item) => item.value === values.jabatanId)}
        onChange={(val) => {
          setValues({
            ...values,
            jabatanId: val?.value,
            jabatanNama: val?.label,
            jabatanKode: val?.kode,
            kelompokJabatanNama: val?.kelompokJabatan,
            unitOrganisasiNama: val?.unitOrganisasiNama,
            gradeId: "",
            grade: "",
            stepId: "",
            step: ""
          });
        }}
        error={Boolean(errors.jabatanId && touched.jabatanId)}
        errorText={Boolean(errors.jabatanId && touched.jabatanId) && errors.jabatanId}
        errorFetch={getJabatan.isError}
        errorFetchText={errorFetchingSelectMapper(getJabatan.error)}
      />
      <Select
        label="Grade"
        loading={getGrade.isFetching}
        disable={getGrade.isIdle}
        options={getGrade?.data ?? []}
        defaultValue={getGrade?.data?.find((item) => item.value === values.gradeId)}
        onChange={(val) => {
          setValues({
            ...values,
            gradeId: val?.value,
            grade: val?.label
          });
        }}
        error={Boolean(errors.gradeId && touched.gradeId)}
        errorText={Boolean(errors.gradeId && touched.gradeId) && errors.gradeId}
      />
      <Select
        label="Step"
        loading={getStep.isFetching}
        disable={getStep.isIdle}
        options={getStep?.data ?? []}
        defaultValue={getStep?.data?.find((item) => item.value === values.stepId)}
        onChange={(val) => {
          setValues({
            ...values,
            stepId: val?.value,
            step: val?.label
          });
        }}
        error={Boolean(errors.stepId && touched.stepId)}
        errorText={Boolean(errors.stepId && touched.stepId) && errors.stepId}
      />
      <Input
        label="Kelompok Jabatan"
        type="text"
        disabled
        value={values?.kelompokJabatanNama}
        onChange={handleChange}
      />
      <Input label="Unit Organisasi" type="text" disabled value={values?.unitOrganisasiNama} onChange={handleChange} />
    </>
  );
};

const TableJabatan = ({ action }) => {
  const parentFormik = useFormikContext();
  const modalConfirm = useModalConfirm();
  const [modal, setModal] = useState({
    show: false,
    action: "",
    index: "",
    data: {}
  });

  const formInitialValues = {
    jenisJabatanId: modal?.data?.jenisJabatanId,
    jenisJabatanNama: modal?.data?.jenisJabatanNama,
    jabatanId: modal?.data?.jabatanId,
    jabatanKode: modal?.data?.jabatanKode,
    jabatanNama: modal?.data?.jabatanNama,
    unitOrganisasiNama: modal?.data?.unitOrganisasiNama,
    kelompokJabatanNama: modal?.data?.kelompokJabatanNama,
    grade: modal?.data?.grade,
    gradeId: modal?.data?.gradeId,
    step: modal?.data?.step,
    stepId: modal?.data?.stepId
  };

  const closeModalHandler = () => setModal({ show: false, action: "", data: {}, index: "" });

  const createDataHandler = (values, { resetForm }) => {
    let newData = [{ ...values }, ...parentFormik.values.detail];

    parentFormik.setFieldValue("detail", newData);
    resetForm();
    closeModalHandler();
  };

  const updateDataHandler = (values, { resetForm }) => {
    let newData = [...parentFormik.values.detail];

    newData.splice(modal.index, 1, values);
    parentFormik.setFieldValue("detail", newData);
    resetForm();
    closeModalHandler();
  };

  const deleteDataHandler = (index) => {
    let newData = [...parentFormik.values.detail];

    newData.splice(index, 1);
    parentFormik.setFieldValue("detail", newData);
  };

  const onButtonCreateClick = () => setModal({ show: true, data: {}, action: "CREATE", index: "" });

  const onButtonUpdateClick = (val, index) => setModal({ show: true, data: val, action: "UPDATE", index });

  const onButtonDeleteClick = (val, index) =>
    modalConfirm.trigger({
      type: "delete",
      data: [
        { label: "Jenis Jabatan", text: val.jenisJabatanNama },
        { label: "Kode Jabatan", text: val.jabatanKode },
        { label: "Nama Jabatan", text: val.jabatanNama },
        { label: "Unit Organisasi", text: val.unitOrganisasiNama },
        { label: "Kelompok Jabatan", text: val.kelompokJabatanNama },
        { label: "Grade", text: val.grade },
        { label: "Step", text: val.step }
      ],
      onSubmit: () => {
        deleteDataHandler(index);
        modalConfirm.close();
      },
      onHide: modalConfirm.close
    });

  return (
    <>
      {!Boolean(action === "READ") && (
        <div className="text-end mb-4">
          <ButtonCreate tooltip={false} onClick={onButtonCreateClick} />
        </div>
      )}

      <TableList
        responsive
        data={parentFormik.values?.detail}
        tableHeader={[
          {
            text: "Jenis Jabatan"
          },
          {
            text: "Kode Jabatan"
          },
          {
            text: "Nama Jabatan"
          },
          {
            text: "Unit Organisasi"
          },
          {
            text: "Kelompok Jabatan"
          },
          {
            text: "Grade"
          },
          {
            text: "Step"
          },
          {
            text: "Aksi",
            props: { fixed: true, width: 50, className: action === "READ" ? "d-none" : "" }
          }
        ]}
        tableBody={[
          {
            field: "jenisJabatanNama"
          },
          {
            field: "jabatanKode"
          },
          {
            field: "jabatanNama"
          },
          {
            field: "unitOrganisasiNama"
          },
          {
            field: "kelompokJabatanNama"
          },
          {
            customField: (val) => val?.grade?.toString()
          },
          {
            customField: (val) => val?.step?.toString()
          },
          {
            props: { fixed: true, className: action === "READ" ? "d-none" : "" },
            customField: (data, index) => (
              <ButtonGroup size="sm">
                <ButtonUpdate icon noText onClick={() => onButtonUpdateClick(data, index)} />
                <ButtonDelete icon noText onClick={() => onButtonDeleteClick(data, index)} />
              </ButtonGroup>
            )
          }
        ]}
      />

      {/* Create Modal */}
      <Formik
        initialValues={formInitialValues}
        validationSchema={formValidationSchema.jabatan}
        onSubmit={createDataHandler}
      >
        {({ handleSubmit }) => (
          <ModalCreate
            show={modal.show && modal.action === "CREATE"}
            onHide={closeModalHandler}
            onSubmit={handleSubmit}
          >
            <ModalJabatanContent />
          </ModalCreate>
        )}
      </Formik>

      {/* Update Modal */}
      <Formik
        enableReinitialize
        initialValues={formInitialValues}
        validationSchema={formValidationSchema.jabatan}
        onHide={closeModalHandler}
        onSubmit={updateDataHandler}
      >
        {({ handleSubmit }) => (
          <ModalUpdate
            show={modal.show && modal.action === "UPDATE"}
            onHide={closeModalHandler}
            onSubmit={handleSubmit}
          >
            <ModalJabatanContent />
          </ModalUpdate>
        )}
      </Formik>
    </>
  );
};

export const TabJabatan = ({ action, refreshData, isGeneralUser }) => {
  const { id } = useParams();
  const modalConfirm = useModalConfirm();
  const parentFormik = useFormikContext();

  const updateJabatan = useMutation((data) => PekerjaApi.update(data, id), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "update", size: "md", customTextInfoTitle: "Data Berhasil Disimpan" });
      refreshData();
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "update", size: "md", ...errorSubmitMapper(err) })
  });

  const updateJabatanSelf = useMutation((data) => PekerjaApi.updateSelf(data), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "update", size: "md", customTextInfoTitle: "Data Berhasil Disimpan" });
      refreshData();
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "update", size: "md", ...errorSubmitMapper(err) })
  });

  const submitValidationHandler = (errors) =>
    new Promise((resolve, reject) => {
      const getError = Object.values(errors);

      if (getError.length > 0) {
        reject(getError);
      } else {
        resolve();
      }
    });

  const preSubmitHandler = (values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting) => {
    setSubmitting(true);
    validateForm().then(async (err) => {
      setErrors(err);
      setTouched(err);

      await submitValidationHandler(err, values)
        .then(() => handleSubmit())
        .catch((err) =>
          modalConfirm.trigger({
            type: "error",
            title: "Data Tidak Lengkap",
            data: err,
            onHide: () => modalConfirm.close()
          })
        )
        .finally(() => {
          setSubmitting(false);
        });
    });
  };

  const formSubmitHandler = (values) =>
    modalConfirm.trigger({
      size: "lg",
      type: "custom",
      onHide: modalConfirm.close,
      title: "Konfirmasi",
      customButtonShow: true,
      customButtonText: "Simpan",
      customButtonVariant: "primary",
      customTextHeader: "Apakah anda yakin menyimpan data ini?",
      onSubmit: () => {
        modalConfirm.loading();
        if (isGeneralUser === true) {
          updateJabatanSelf.mutate(formSubmitMapper.jabatan(values.detail));
        } else {
          updateJabatan.mutate(formSubmitMapper.jabatan(values.detail));
        }
      },
      component: (
        <Formik initialValues={values}>
          <TableJabatan action="READ" />
        </Formik>
      )
    });

  return (
    <Formik
      enableReinitialize
      initialValues={{ detail: formInitialValues.jabatan(parentFormik.values) }}
      onSubmit={formSubmitHandler}
    >
      {({ values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting }) => {
        return (
          <>
            <TableJabatan action={action} />
            {!Boolean(action === "READ") && (
              <div className="text-end mt-3">
                <Button
                  text="Simpan"
                  className="px-4"
                  onClick={() =>
                    preSubmitHandler(values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting)
                  }
                />
              </div>
            )}
          </>
        );
      }}
    </Formik>
  );
};
