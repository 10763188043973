import { Services } from "services";
import { fetchDropdownMapper } from "utilities";

const fetchDropdown = fetchDropdownMapper(Services);

class CreateGajiApi {
  async getList(params) {
    const fetch = await Services.get("/transaksi/pekerja-payroll-bulanan", { params });
    return fetch?.data?.data;
  }
  async getListForm(params) {
    const fetch = await Services.get("/transaksi/pekerja-payroll-bulanan/form", { params });
    return fetch?.data?.data;
  }
  async getListPost(params) {
    const fetch = await Services.get("/transaksi/pekerja-payroll-bulanan/list-post", { params });
    return fetch?.data?.data;
  }
  async getTanggal(params) {
    const fetch = await Services.get("/transaksi/pekerja-payroll-bulanan/dropdown-tanggal", { params });
    return fetch?.data?.data.map((val) => ({
      value: val?.tgl_bulan_awal + "/&/" + val?.tgl_bulan_akhir,
      label: val?.tgl_bulan_awal + " s/d " + val?.tgl_bulan_akhir,
      tglAwal: val?.tgl_bulan_awal,
      tglAkhir: val?.tgl_bulan_akhir
    }));
  }
  async getPekerja(params) {
    const fetch = await Services.get("/transaksi/registrasi-pekerja/dropdown", { params });
    return fetch?.data?.data.map((val) => ({
      label: val?.pekerja_nama,
      value: val?.pekerja_id,
      valueJabatan: val?.master_jabatan_nama
    }));
  }
  async getUnitOrganisasiCreate() {
    const fetch = await Services.get("/master/unit-organisasi/dropdown");
    return fetch?.data?.data.map((val) => ({
      value: val?.master_unit_organisasi_id,
      label: val?.master_unit_organisasi_nama
    }));
  }
  async getUnitOrganisasi(params) {
    const fetch = await Services.get("/transaksi/pekerja-payroll-bulanan/dropdown-unit", { params });
    return fetch?.data?.data.map((val) => ({
      value: val?.master_unit_organisasi_id,
      label: val?.master_unit_organisasi_nama
    }));
  }
  async getPeriode() {
    const getPeriodeDropdown = await fetchDropdown("/utility/periode-tahun?feat=pekerja-payroll-bulanan", {
      value: "periode_tahun",
      label: "periode_tahun"
    });
    return [...getPeriodeDropdown];
  }
  async getDetail(id) {
    const fetch = await Services.get(`/transaksi/pekerja-payroll-bulanan/${id}`);
    return fetch?.data?.data;
  }
  async getExport(params) {
    const fetch = await Services.get("/transaksi/pekerja-payroll-bulanan/export", { params });
    return fetch?.data?.data;
  }
  createData(data) {
    return Services.post("/transaksi/pekerja-payroll-bulanan", data);
  }
  quickPost(data) {
    return Services.post("/transaksi/pekerja-payroll-bulanan/quick-post", data);
  }
}
export default new CreateGajiApi();
