import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { Formik } from "formik";
import { ContentLayout } from "layouts";
import { Content } from "./__PengajuanPekerjaPensiunComps__";
import { formInitialValues } from "./__PengajuanPekerjaPensiunUtils__";
import { DataStatus } from "components";
import PengajuanPekerjaPensiunApi from "./__PengajuanPekerjaPensiunApi__";
import { errorFetchingPageMapper } from "utilities";

export const PengajuanPekerjaPensiunDetail = () => {
  const { id } = useParams();
  const dataDetail = useQuery(["pengajuan-pekerja", id], () => PengajuanPekerjaPensiunApi.getSingle(id));

  return (
    <>
      <ContentLayout showBreadcrumb>
        {dataDetail.isFetching || dataDetail.isError ? (
          <DataStatus loading={dataDetail.isFetching} text={errorFetchingPageMapper(dataDetail.error)} />
        ) : (
          <Formik enableReinitialize initialValues={formInitialValues(dataDetail.data)}>
            {() => (
              <>
                <Content action="READ" getDetail={dataDetail?.data} />
              </>
            )}
          </Formik>
        )}
      </ContentLayout>
    </>
  );
};
