import { Services } from "services";

class SetupJadwalApi {
  async getList(params) {
    const fetch = await Services.get("/transaksi/setup-jadwal", { params });
    return fetch?.data?.data;
  }

  async getDetail(id, params) {
    const fetch = await Services.get("/transaksi/setup-jadwal/" + id, { params });
    return fetch?.data?.data;
  }

  async getForm(params) {
    const fetch = await Services.get("/transaksi/setup-jadwal/form", { params });
    return fetch?.data?.data;
  }

  async getPeriode() {
    const fetch = await Services.get("/utility/periode-tahun?feat=setup-jadwal");
    return fetch?.data?.data.map((val) => ({
      value: val?.periode_tahun,
      label: val?.periode_tahun
    }));
  }

  async getUnitOrganisasi() {
    const fetch = await Services.get("/master/unit-organisasi");
    return fetch?.data?.data.map((val) => ({
      value: val?.master_unit_organisasi_id,
      label: val?.master_unit_organisasi_nama
    }));
  }

  async getUnitOrganisasiForList() {
    const fetch = await Services.get("/transaksi/setup-jadwal/dropdown-unit");
    return fetch?.data?.data.map((val) => ({
      value: val?.master_unit_organisasi_id,
      label: val?.master_unit_organisasi_nama
    }));
  }

  async getJabatan(params) {
    const fetch = await Services.get("/master/jabatan/dropdown", { params });
    return fetch?.data?.data.map((val) => ({
      value: val?.master_jabatan_id,
      label: val?.master_jabatan_nama
    }));
  }

  async getJabatanForList(params) {
    const fetch = await Services.get("/transaksi/setup-jadwal/dropdown-jabatan", { params });
    return fetch?.data?.data.map((val) => ({
      value: val?.master_jabatan_id,
      label: val?.master_jabatan_nama
    }));
  }

  async getPekerja(params) {
    const fetch = await Services.get("/transaksi/registrasi-pekerja/dropdown", { params });
    return fetch?.data?.data.map((val) => ({
      value: val?.pekerja_id,
      label: val?.pekerja_nama
    }));
  }

  async getBulan() {
    return Array.from({ length: 12 }, (item, i) => {
      return {
        value: i + 1,
        label: new Date(0, i).toLocaleString("id-ID", { month: "long" })
      };
    });
  }

  async getShift() {
    const fetch = await Services.get("/master/shift/dropdown");
    return fetch?.data?.data.map((val) => ({
      value: val?.master_shift_id,
      label: val?.master_shift_nama
    }));
  }

  async getExport(params) {
    const fetch = await Services.get("/transaksi/setup-jadwal/export", { params });
    return fetch?.data?.data;
  }

  create(data) {
    return Services.post("/transaksi/setup-jadwal", data);
  }

  update(data, id) {
    return Services.put("/transaksi/setup-jadwal/" + id, data);
  }

  delete(id) {
    return Services.delete("/transaksi/setup-jadwal/" + id);
  }
}

export default new SetupJadwalApi();
