export const formInitialValues = (data) => ({
  identitasJabatan: {
    jabatanId: data?.master_jabatan_id ?? "",
    jabatanNama: data?.master_jabatan_nama ?? "",
    jenisJabatanId: data?.identitas?.jenis_jabatan ?? "",
    jenisJabatanNama: data?.identitas?.jenis_jabatan_nama ?? "",
    unitOrganisasiNama: data?.identitas?.master_unit_organisasi_nama ?? "",
    unitOrganisasiId: data?.identitas?.master_unit_organisasi_id ?? "",
    kelompokJabatanNama: data?.identitas?.master_kelompok_jabatan_nama ?? "",
    kelompokJabatanId: data?.identitas?.master_kelompok_jabatan_id ?? "",
    grade: data?.identitas?.grade ?? "",
    pangkat: data?.identitas?.nama_pangkat ?? "",
    atasanLangsung: data?.identitas?.atasan_langsung,
    bawahanLangsung: data?.identitas?.bawahan_langsung ?? "",
    pemegangJabatan: data?.identitas?.pemegang_jabatan ?? ""
  },
  tujuanJabatan: { tujuanJabatan: data?.tujuan?.tujuan_jabatan ?? "" },
  akuntabilitasJabatan: {
    detail: data?.akuntabilitas
      ? data?.akuntabilitas?.map((val) => ({
          akuntabilitasUtama: val?.akuntabilitas_utama ?? "",
          akuntabilitasJabatanId: val?.uraian_jabatan_akuntabilitas_id ?? ""
        }))
      : []
  },
  tugasRutin: {
    detail: data?.tugas
      ? data?.tugas?.map((val) => ({
          tugasRutinNama: val?.tugas_rutin ?? "",
          tugasRutinId: val?.uraian_jabatan_tugas_id ?? ""
        }))
      : []
  },
  wewenang: {
    detail: data?.wewenang
      ? data?.wewenang?.map((val) => ({
          wewenangNama: val?.wewenang ?? "",
          wewenangId: val?.uraian_jabatan_wewenang_id ?? ""
        }))
      : []
  },
  hubunganKerja: {
    detail: data?.hubungan
      ? data?.hubungan?.map((val) => ({
          hubunganKerjaNama: val?.hubungan_kerja ?? "",
          hubunganKerjaId: val?.uraian_jabatan_hubungan_id ?? ""
        }))
      : []
  },
  dimensi: {
    detail: data?.dimensi
      ? data?.dimensi?.map((val) => ({
          dimensiNama: val?.dimensi ?? "",
          dimensiId: val?.uraian_jabatan_dimensi_id ?? ""
        }))
      : []
  },
  spesifikasi: {
    jenjanganPendidikanId: data?.spesifikasi?.master_jenjang_pendidikan_id ?? "",
    jenjanganPendidikanNama: data?.spesifikasi?.master_jenjang_pendidikan_nama ?? "",
    pengalamanKerja: data?.spesifikasi?.pengalaman_kerja ?? "",
    jeniskompetensiCore: data?.spesifikasi?.kompetensi
      ? data?.spesifikasi?.kompetensi
          ?.filter((val) => val?.master_jenis_kompetensi_id === 1)
          ?.map((val) => ({
            kompetensiJabatanNama: val.kompetensi_jabatan_nama ?? "",
            kompetensiJabatanId: val?.master_kompetensi_jabatan_id ?? "",
            skalaKemahiranId: val?.master_skala_kemahiran_id ?? "",
            skalaKemahiranNama: val?.kemahiran_nama ?? ""
          }))
      : [],
    jeniskompetensiRole: data?.spesifikasi?.kompetensi
      ? data?.spesifikasi?.kompetensi
          ?.filter((val) => val?.master_jenis_kompetensi_id === 2)
          ?.map((val) => ({
            kompetensiJabatanNama: val.kompetensi_jabatan_nama ?? "",
            kompetensiJabatanId: val?.master_kompetensi_jabatan_id ?? "",
            skalaKemahiranId: val?.master_skala_kemahiran_id ?? "",
            skalaKemahiranNama: val?.kemahiran_nama ?? ""
          }))
      : [],
    jeniskompetensiFunction: data?.spesifikasi?.kompetensi
      ? data?.spesifikasi?.kompetensi
          .filter((val) => val?.master_jenis_kompetensi_id === 3)
          ?.map((val) => ({
            kompetensiJabatanNama: val.kompetensi_jabatan_nama ?? "",
            kompetensiJabatanId: val?.master_kompetensi_jabatan_id ?? "",
            skalaKemahiranId: val?.master_skala_kemahiran_id ?? "",
            skalaKemahiranNama: val?.kemahiran_nama ?? ""
          }))
      : [],
    sertifikatPelatihan: data?.spesifikasi?.sertifikat_pelatihan
      ? data?.spesifikasi?.sertifikat_pelatihan?.map((val) => ({
          sertifikatPelatihanId: val?.master_sertifikat_pelatihan_id ?? "",
          sertifikatPelatihanNama: val?.master_sertifikat_pelatihan_nama ?? ""
        }))
      : [],
    sertifikatProfesi: data?.spesifikasi?.sertifikat_profesi
      ? data?.spesifikasi?.sertifikat_profesi?.map((val) => ({
          sertifikatProfesiId: val?.master_sertifikat_profesi_id ?? "",
          sertifikatProfesiNama: val?.master_sertifikat_pelatihan_nama ?? ""
        }))
      : []
  },
  kondisiKerja:
    data?.kondisi_kerja?.length > 0
      ? data?.kondisi_kerja?.map((val) => ({
          masterKondisiKerjaId: val?.master_kondisi_kerja_id ?? "",
          masterKondisiKerjaNama: val?.master_kondisi_kerja_nama ?? "",
          jawabanKondisiKerja: val?.jawaban_kondisi_kerja ?? ""
        }))
      : data?.length > 0
      ? data?.map((val) => ({
          masterKondisiKerjaId: val?.master_kondisi_kerja_id ?? "",
          masterKondisiKerjaNama: val?.master_kondisi_kerja_nama ?? "",
          jawabanKondisiKerja: val?.jawaban_kondisi_kerja ?? ""
        }))
      : []
});
