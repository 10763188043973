import {
  SuratPerjalananDinasKeluarList,
  SuratPerjalananDinasKeluarCreate,
  SuratPerjalananDinasKeluarUpdate
} from "pages/OprasionalSdm";

export const SuratPerjalananDinasKeluar = {
  component: <SuratPerjalananDinasKeluarList />,
  link: "surat-perjalanan-dinas-keluar",
  name: "Surat Perjalanan Dinas Keluar",
  access: "SURAT_PERJALANAN_DINAS",
  child: [
    {
      name: "Ubah",
      link: "/ubah/:id",
      component: <SuratPerjalananDinasKeluarUpdate />
    },
    {
      name: "Tambah",
      link: "/tambah",
      component: <SuratPerjalananDinasKeluarCreate />
    }
  ]
};
