import { Services } from "services";
class PekerjaApi {
  async getList(params) {
    const fetch = await Services.get("/transaksi/registrasi-pekerja", { params });
    return fetch?.data?.data;
  }

  async getSingle(id) {
    const fetch = await Services.get(
      "/transaksi/registrasi-pekerja/" +
      id +
      "?with=akun,kepegawaian,profile,jabatan,kontak,alamat,keluarga,bank,pendidikan"
    );
    return fetch?.data?.data;
  }

  async getExport(params) {
    const fetch = await Services.get("/transaksi/registrasi-pekerja/export", { params });
    return fetch?.data?.data;
  }

  async getJenisIdentitas() {
    const fetch = await Services.get("/master/jenis-identitas");
    return fetch?.data?.data.map((val) => ({ value: val?.master_identitas_id, label: val?.jenis_identitas }));
  }

  async getJenisKelamin() {
    const fetch = await Services.get("/master/jenis-kelamin");
    return fetch?.data?.data.map((val) => ({ value: val?.master_kelamin_id, label: val?.jenis_kelamin }));
  }

  async getAgama() {
    const fetch = await Services.get("/master/agama");
    return fetch?.data?.data.map((val) => ({ value: val?.master_agama_id, label: val?.agama }));
  }

  async getStatusKawin() {
    const fetch = await Services.get("/master/status-perkawinan");
    return fetch?.data?.data.map((val) => ({ value: val?.master_status_perkawinan_id, label: val?.status_perkawinan }));
  }

  async getJenisPekerja() {
    const fetch = await Services.get("/master/jenis-pekerja/dropdown");
    return fetch?.data?.data.map((val) => ({ value: val?.master_jenis_pekerja_id, label: val?.jenis_pekerja }));
  }

  async getStatusPekerja() {
    const fetch = await Services.get("/master/status-pekerja/dropdown");
    return fetch?.data?.data.map((val) => ({ value: val?.master_status_pekerja_id, label: val?.status_pekerja }));
  }

  async getDetailStatusPekerja(id) {
    const fetch = await Services.get("/master/detail-status-pekerja/dropdown?master_status_pekerja_id=" + id);
    return fetch?.data?.data.map((val) => ({
      value: val?.master_detail_status_pekerja_id,
      label: val?.detail_status_pekerja
    }));
  }

  async getUnitOrganisasi() {
    const fetch = await Services.get("/master/unit-organisasi/dropdown");
    return fetch?.data?.data.map((val) => ({
      value: val?.master_unit_organisasi_id,
      label: val?.master_unit_organisasi_nama
    }));
  }

  async getLokasiKerja() {
    const fetch = await Services.get("/master/lokasi-kerja/dropdown");
    return fetch?.data?.data.map((val) => ({
      value: val?.master_lokasi_kerja_id,
      label: val?.lokasi_kerja
    }));
  }

  async getHubunganKeluarga() {
    const fetch = await Services.get("/master/hubungan-keluarga/dropdown");
    return fetch?.data?.data.map((val) => ({
      value: val?.master_hubungan_keluarga_id,
      label: val?.hubungan_keluarga
    }));
  }

  async getWilayah(path, params) {
    const fetch = await Services.get(`/master/wilayah/${path}/dropdown`, { params });

    if (path === "provinsi") {
      return fetch?.data?.data.map((val) => ({
        value: val?.provinsi_kode,
        label: val?.provinsi_nama
      }));
    }

    if (path === "kabupaten") {
      return fetch?.data?.data.map((val) => ({
        value: val?.kabupaten_kode,
        label: val?.kabupaten_nama
      }));
    }

    if (path === "kecamatan") {
      return fetch?.data?.data.map((val) => ({
        value: val?.kecamatan_kode,
        label: val?.kecamatan_nama
      }));
    }

    if (path === "desa") {
      return fetch?.data?.data.map((val) => ({
        value: val?.desa_kode,
        label: val?.desa_nama
      }));
    }
  }

  async getBank() {
    const fetch = await Services.get("/master/bank/dropdown");
    return fetch?.data?.data.map((val) => ({
      value: val?.master_bank_id,
      label: val?.bank_nama
    }));
  }

  getJenisJabatan() {
    return [
      { label: "Struktural", value: 1 },
      { label: "Fungsional", value: 2 }
    ];
  }

  async getJabatan(params) {
    const fetch = await Services.get("/master/jabatan/dropdown", { params });
    return fetch?.data?.data.map((val) => ({
      value: val?.master_jabatan_id,
      label: val?.master_jabatan_nama,
      kode: val.master_jabatan_kode,
      kelompokJabatan: val.master_kelompok_jabatan_nama,
      unitOrganisasiNama: val.master_unit_organisasi_nama
    }));
  }

  async getGrade(params) {
    const fetch = await Services.get("/master/grade", { params });
    return fetch?.data?.data.map((val) => ({
      value: val?.master_grade_id,
      label: val?.grade
    }));
  }

  async getStep(params) {
    const fetch = await Services.get("/master/step/dropdown", { params });
    return fetch?.data?.data.map((val) => ({
      value: val?.master_step_id,
      label: val?.step
    }));
  }

  async getJenjangPendidikan() {
    const fetch = await Services.get("/master/jenjang-pendidikan/dropdown");
    return fetch?.data?.data.map((val) => ({
      value: val?.master_jenjang_pendidikan_id,
      label: val?.master_jenjang_pendidikan_nama
    }));
  }

  async getJenisKontak() {
    const fetch = await Services.get("/master/jenis-kontak/dropdown");
    return fetch?.data?.data.map((val) => ({
      value: val?.master_jenis_kontak_id,
      label: val?.jenis_kontak
    }));
  }

  show(id) {
    return Services.put(`/master/agama/${id}/active`);
  }

  hide(id) {
    return Services.put(`/master/agama/${id}/inactive`);
  }

  create(data) {
    return Services.post("/transaksi/registrasi-pekerja", data);
  }

  update(data, id) {
    return Services.put("/transaksi/registrasi-pekerja/" + id, data);
  }

  delete(id) {
    return Services.delete("/transaksi/registrasi-pekerja/" + id);
  }

  switchStatusPekerja(id) {
    return Services.put("/transaksi/registrasi-pekerja/status/" + id);
  }

  //Khusus untuk pekerja yang sedang login (bukan admin/superadmin)
  async getSelf(params) {
    const fetch = await Services.get("/pekerja/self", { params });
    return fetch?.data?.data;
  }

  updateSelf(data) {
    return Services.post("/pekerja/self", data);
  }

  linkUploadProfil = "/upload/pekerja-profil";

  linkUploadIdentitas = "/upload/pekerja-identitas";

  linkUploadKepegawaian = "/upload/pekerja-kepegawaian";

  linkUploadPendidikan = "/upload/pekerja-pendidikan";
}

export default new PekerjaApi();
