import React from "react";
import { Formik } from "formik";
import { ButtonCreate, InfoItemHorizontal, Input } from "components";
import { formInitialValues, formSubmitMapper, formValidationSchema } from "../../__UraianJabatanUtils__";
import { useModalConfirm } from "hooks";
import { errorSubmitMapper } from "utilities";
import UraianJabatanApi from "../../__UraianJabatanApi__";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { useFormikContext } from "formik";

const DetailSection = () => {
  const { values } = useFormikContext();

  return values?.map((val, ind) => (
    <InfoItemHorizontal label={`${ind + 1}.  ${val?.masterKondisiKerjaNama}`} text={val.jawabanKondisiKerja} />
  ));
};

const FormSection = () => {
  const { values, setValues, errors, touched } = useFormikContext();

  return (
    values?.length > 0 &&
    values?.map((val, ind) => {
      return (
        <Input
          label={`${ind + 1}.  ${val?.masterKondisiKerjaNama}`}
          placeholder={`Masukan ${val?.masterKondisiKerjaNama?.toLowerCase()}`}
          value={val.jawabanKondisiKerja}
          onChange={(e) =>
            setValues([...values?.map((res, i) => (ind === i ? { ...res, jawabanKondisiKerja: e.target.value } : res))])
          }
          error={Boolean(errors[ind]?.jawabanKondisiKerja && touched[ind]?.jawabanKondisiKerja)}
          errorText={
            Boolean(errors[ind]?.jawabanKondisiKerja && touched[ind]?.jawabanKondisiKerja) &&
            errors[ind]?.jawabanKondisiKerja
          }
        />
      );
    })
  );
};

export const TabKondisiKerja = ({ action }) => {
  const modalConfirm = useModalConfirm();
  const { id } = useParams();
  const parentFormik = useFormikContext();

  const getKondisiKerja = useQuery(["kondisi-kerja", "dropdown"], () => UraianJabatanApi.getKondisiKerja());

  const updateKondisiKerja = useMutation((data) => UraianJabatanApi.update(data, id), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "update", customTextInfoTitle: "Data Berhasil Disimpan" });
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "update", ...errorSubmitMapper(err) })
  });

  const submitValidationHandler = (errors) =>
    new Promise((resolve, reject) => {
      const parseErrors = Object.values(errors);
      const getErrors = parseErrors.map((val) => val.jawabanKondisiKerja);

      if (getErrors.length > 0) {
        reject(["Semua kolom wajib diisi"]);
      } else {
        resolve();
      }
    });

  const preSubmitHandler = (values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting) => {
    setSubmitting(true);
    validateForm().then(async (err) => {
      setErrors(err);
      setTouched(err);

      await submitValidationHandler(err, values)
        .then(() => handleSubmit())
        .catch((err) =>
          modalConfirm.trigger({
            type: "error",
            title: "Data Tidak Lengkap",
            data: err,
            onHide: () => modalConfirm.close()
          })
        )
        .finally(() => {
          setSubmitting(false);
        });
    });
  };

  const formSubmitHandler = (values, action) =>
    modalConfirm.trigger({
      type: "custom",
      onHide: modalConfirm.close,
      title: "Konfirmasi",
      customButtonShow: true,
      customButtonText: "Simpan",
      customButtonVariant: "primary",
      customTextHeader: "Apakah anda yakin menyimpan data ini?",
      onSubmit: () => {
        modalConfirm.loading();

        updateKondisiKerja.mutate(formSubmitMapper().kondisiKerja(values));
      },
      component: (
        <Formik initialValues={values}>
          <DetailSection />
        </Formik>
      )
    });

  //fungsi merge array dari dropdown getKondisiKerja dengan array kondisiKerja dari api read single
  const mergeArrayKondisiKerja = (data) =>
    data?.length > 0 &&
    data?.map(
      (val) =>
        parentFormik?.values?.kondisiKerja.find((res) => res.masterKondisiKerjaId === val.masterKondisiKerjaId) ?? val
    );

  const dataKondisiKerja =
    action === "READ"
      ? parentFormik?.values?.kondisiKerja
      : mergeArrayKondisiKerja(formInitialValues(getKondisiKerja?.data).kondisiKerja);

  return (
    <Formik
      enableReinitialize
      initialValues={dataKondisiKerja}
      validationSchema={formValidationSchema?.kondisiKerja}
      onSubmit={(values) => formSubmitHandler(values, action)}
    >
      {({ values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting }) => {
        return (
          <form>
            {action === "READ" ? (
              <DetailSection />
            ) : (
              <>
                <FormSection />
                <div className="d-flex justify-content-end mb-2 mt-3">
                  <ButtonCreate
                    text="Simpan"
                    onClick={() =>
                      preSubmitHandler(values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting)
                    }
                  />
                </div>
              </>
            )}
          </form>
        );
      }}
    </Formik>
  );
};
