import {
  ButtonDetail, ButtonFilter, ButtonUpdate, InputSearch, ModalFilter, numberConvert, Select, toastTrigger
} from "components";
import { Formik, useFormikContext } from "formik";
import { useAccessRights } from "hooks";
import { ListLayout } from "layouts";
import { debounce } from "lodash";
import { useState } from "react";
import { ButtonGroup } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { dateConvert, exportHandler } from "utilities";
import PerhitunganUangPhkApi from "./__PerhitunganUangPhkApi__";

const ModalFilterBody = () => {
  const { values, setValues } = useFormikContext();

  const getPeriodePerencanaan = useQuery(["periode-perencanaan", "dropdown"], () =>
    PerhitunganUangPhkApi.getPeriodePerencanaan()
  );

  const getUnitOrganisasi = useQuery(["unit-organisasi", "dropdown", values?.periodeGajianId], () =>
    PerhitunganUangPhkApi.getUnitOrganisasi({
      periode_tahun: values?.periodeGajianId
    })
  );

  return (
    <>
      <Select
        label="Periode PHK"
        placeholder="Pilih periode phk"
        defaultValue={getPeriodePerencanaan?.data?.find((item) => item.value === values.periodeGajianId)}
        options={getPeriodePerencanaan?.data ?? []}
        onChange={(val) => setValues({ ...values, periodeGajianId: val.value })}
        loading={getPeriodePerencanaan.isFetching}
        errorFetch={getPeriodePerencanaan.isError}
      />
      <Select
        label="Unit Organisasi"
        placeholder="Pilih unit organisasi"
        defaultValue={getUnitOrganisasi?.data?.find((item) => item.value === values.unitOrganisasiId)}
        options={getUnitOrganisasi?.data ?? []}
        onChange={(val) => setValues({ ...values, unitOrganisasiId: val.value })}
        loading={getUnitOrganisasi.isFetching}
        errorFetch={getUnitOrganisasi.isError}
      />
    </>
  );
};

export const PerhitunganUangPhkList = () => {
  const access = useAccessRights("PERHITUNGAN_PHK");
  const date = dateConvert();
  // const modalConfirm = useModalConfirm();
  // const layout = useLayout();
  const number = numberConvert();
  const navigate = useNavigate();

  const [searchKey, setSearchKey] = useState(undefined);
  const [pagination, setPagination] = useState({
    pageCurrent: 1,
    dataLength: 10
  });

  const [filter, setFilter] = useState({
    show: false,
    active: false,
    data: {
      periodeGajianId: undefined,
      periodePerencanaan: undefined,
      unitOrganisasiId: undefined,
      unitOrganisasiNama: undefined
    }
  });

  // const [modal, setModal] = useState({
  //   show: false,
  //   action: "",
  //   data: {}
  // });

  const getPerhitunganUangPhkList = useQuery(
    [
      "perhitungan-uang-phk",
      "list",
      searchKey,
      pagination.pageCurrent,
      pagination.dataLength,
      filter.data?.periodeGajianId,
      filter.data?.unitOrganisasiId
    ],
    () =>
      PerhitunganUangPhkApi.getList({
        search: searchKey,
        page: pagination.pageCurrent,
        limit: pagination.dataLength,
        periode_tahun: filter.data?.periodeGajianId,
        master_unit_organisasi_id: filter.data?.unitOrganisasiId
      })
  );

  const getPerhitunganUangPhkExport = useMutation(
    (data) => PerhitunganUangPhkApi.getExport({ periode_tahun: data.periodeGajianId }),
    {
      onSuccess: (link) => {
        toastTrigger({ variant: "success", message: "Export berhasil" });
        exportHandler(link, "Data Perhitungan Uang PHK");
      },
      onError: () => {
        toastTrigger({ variant: "danger", message: "Export gagal" });
      }
    }
  );

  // const deletePerhitunganUangPhk = useMutation((id) => PerhitunganUangPhkApi.delete(id), {
  //   onSuccess: () => {
  //     modalConfirm.infoSuccess({ typeInfo: "delete" });
  //     getPerhitunganUangPhkList.refetch();
  //   },
  //   onError: (err) => modalConfirm.infoError({ typeInfo: "delete", ...errorSubmitMapper(err) })
  // });

  const onCreateButtonClickHandler = () => navigate("list-data-phk");

  const onDetailButtonClickHandler = (data) => navigate("detail/" + data?.pekerja_phk_uang_id);

  const onUpdateButtonClickHandler = (data) => navigate("ubah/" + data?.pekerja_phk_uang_id);

  const onPaginationChange = (data) => setPagination({ ...pagination, pageCurrent: data });

  const onPaginationDataLengthChange = (data) => setPagination({ ...pagination, dataLength: data });

  const searchHandler = (e) => {
    setSearchKey(e?.target?.value ? e.target.value : undefined);
  };

  // const formSubmitHandler = (values, action) => {
  //   modalConfirm.trigger({
  //     size: "lg",
  //     type: action.toLowerCase(),
  //     alert: "Data akan dihapus secara permanen dan tidak dapat dikembalikan",
  //     onHide: modalConfirm.close,
  //     onSubmit: () => {
  //       modalConfirm.loading();
  //       if (action === "DELETE") {
  //         deletePerhitunganUangPhk.mutate(values?.pekerja_phk_uang_id);
  //       }
  //     },
  //     component: (
  //       <Formik initialValues={formInitialValues(values)}>
  //         <Content action="READ" />
  //       </Formik>
  //     )
  //   });
  // };

  const TABLE_HEADER = [
    {
      text: "NIK"
    },
    {
      text: "Pekerja"
    },
    {
      text: "Jabatan"
    },
    {
      text: "Unit Organisasi"
    },
    {
      text: "Tgl. Pemberhentian"
    },
    {
      text: "Masa Kerja"
    },
    {
      text: "Alasan PHK"
    },
    {
      text: "Uang PHK"
    },
    {
      text: "Aksi",
      props: {
        fixed: true,
        rowSpan: 2
      }
    }
  ];

  const TABLE_BODY = [
    { field: "nip" },
    { field: "pekerja_nama" },
    { field: "master_jabatan_nama" },
    { field: "master_unit_organisasi_nama" },
    { customField: (val) => (val?.tgl_pemberhentian ? date.getSlashDMY(new Date(val?.tgl_pemberhentian)) : "-") },
    {
      customField: (val) =>
        val?.masa_kerja ? number.getWithComa(val?.masa_kerja, { minimumFractionDigits: 0 }) + " Tahun" : "-"
    },
    { field: "alasan_phk_nama" },
    {
      props: { textRight: true },
      customField: (val) =>
        val?.kebijakan_total_uang_phk
          ? "Rp" + number.getWithComa(val?.kebijakan_total_uang_phk, { minimumFractionDigits: 2 })
          : "-"
    },
    {
      props: {
        width: 50,
        fixed: true,
        textCenter: true
      },
      customField: (data) => (
        <ButtonGroup size="sm">
          {access.canRead() && (
            <ButtonDetail icon tooltip={false} noText onClick={() => onDetailButtonClickHandler(data)} />
          )}
          {access.canUpdate() && (
            <ButtonUpdate icon tooltip={false} noText onClick={() => onUpdateButtonClickHandler(data)} />
          )}
          {/* {access.canDelete() && (
            <ButtonDelete icon tooltip={false} noText onClick={() => formSubmitHandler(data, "DELETE")} />
          )} */}
        </ButtonGroup>
      )
    }
  ];

  return (
    <>
      <ListLayout
        customLeftTopSection={() => (
          <div className="d-flex align-items-end mb-3">
            <div style={{ width: 300 }} className="me-2">
              <InputSearch onChange={debounce(searchHandler, 1500)} />
            </div>
            <div>
              <ButtonFilter
                active={filter?.active}
                className="text-nowrap"
                onClick={() => setFilter({ ...filter, show: true })}
              />
            </div>
          </div>
        )}
        showRightTopButton={access.canCreate() ? true : false}
        showExportExcelButton={Boolean(filter?.data?.periodeGajianId)}
        // showExportPdfButton
        onExportExcelButtonClick={() => getPerhitunganUangPhkExport.mutate(filter?.data)}
        // onExportPdfButtonClick={() => getPerhitunganUangPhkExport.mutate("pdf")}
        loadingExportButton={getPerhitunganUangPhkExport.isLoading}
        loading={getPerhitunganUangPhkList?.isFetching}
        error={getPerhitunganUangPhkList?.isError}
        dataTotal={getPerhitunganUangPhkList?.data?.total}
        dataLength={pagination.dataLength}
        pageCurrent={pagination.pageCurrent}
        pageTotal={getPerhitunganUangPhkList?.data?.last_page}
        onSearchChange={debounce(searchHandler, 1500)}
        onCreateButtonClick={onCreateButtonClickHandler}
        onDataLengthChange={onPaginationDataLengthChange}
        onPaginationChange={onPaginationChange}
        data={getPerhitunganUangPhkList?.data?.data}
        tableHeader={TABLE_HEADER}
        tableBody={TABLE_BODY}
      />

      {/* ModalFilter */}
      <Formik
        initialValues={{
          periodeGajianId: filter?.data?.periodeGajianId,
          periodePerencanaan: filter?.data?.periodePerencanaan,
          unitOrganisasiId: filter?.data?.unitOrganisasiId,
          unitOrganisasiNama: filter?.data?.unitOrganisasiNama
        }}
      >
        {({ resetForm, values }) => (
          <ModalFilter
            show={filter.show}
            onReset={resetForm}
            onHide={() =>
              setFilter({
                ...filter,
                show: false
              })
            }
            onSubmit={() => {
              setFilter({
                show: false,
                active: Boolean(Object.values(values).find((val) => val)),
                data: values
              });
            }}
          >
            <ModalFilterBody />
          </ModalFilter>
        )}
      </Formik>
    </>
  );
};
