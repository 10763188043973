import { useFormikContext } from "formik";
import { errorFetchingSelectMapper } from "utilities";
import { InfoItemVertical, Select } from "components";
import UraianKPIApi from "../__UraianKPIApi__";

const DetailTabPolaritas = () => {
  const { values } = useFormikContext();

  return (
    <>
      <InfoItemVertical label="Polaritas" text={values.polaritasNama} />
    </>
  );
};

const FormTabPolaritas = () => {
  const { values, errors, touched, setValues } = useFormikContext();
  const getPolaritas = UraianKPIApi.getPolaritas();

  return (
    <>
      {/* Polaritas */}
      <Select
        label="Polaritas"
        placeholder="Pilih polaritas"
        defaultValue={getPolaritas.find((item) => item.value === values.polaritas) ?? []}
        options={getPolaritas}
        onChange={(val) =>
          setValues({
            ...values,
            polaritas: val.value,
            polaritasNama: val.label
          })
        }
        loading={false}
        error={Boolean(errors.polaritas && touched.polaritas)}
        errorText={Boolean(errors.polaritas && touched.polaritas) && errors.polaritas}
        errorFetch={false}
        errorFetchText={errorFetchingSelectMapper(false)}
      />
    </>
  );
};

export const TabPolaritas = ({ action }) => {
  return <>{action === "READ" ? <DetailTabPolaritas /> : <FormTabPolaritas />}</>;
};
