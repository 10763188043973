import { TunjanganTetapBulananList, TunjanganTetapBulananCreate } from "pages/Payroll";

export const TunjanganTetapBulanan = {
  component: <TunjanganTetapBulananList />,
  link: "tunjangan-tetap-bulanan",
  name: "Tunjangan Tetap Bulanan",
  access: "TUNJANGAN_TETAP_BULANAN",
  child: [
    {
      name: "Tambah",
      link: "/tambah",
      component: <TunjanganTetapBulananCreate />
    }
  ]
};
