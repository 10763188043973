import {
    ButtonCreate, dateConvert, DatePicker, InfoItemVertical, Input, InputCurrency, numberConvert,
    Select, TableList
} from "components";
import { useFormikContext } from "formik";
import { Card, Col, Row } from "react-bootstrap";
import { useQuery } from "react-query";
import DendaKeterlambatanApi from "../__DendaKeterlambatanApi__";

const SectionDataSetup = ({ onSearchButtonClick, onResetClickButton }) => {
    const { values, setValues, errors, touched } = useFormikContext();

    const defaultSearch = {
        periodeGajianId: values?.periodeGajianId,
        periodePerencanaan: values?.periodePerencanaan,
        bulan: values?.bulan,
        unitOrganisasiId: values?.unitOrganisasiId,
        unitOrganisasiNama: values?.unitOrganisasiNama,
        pekerjaId: values?.pekerjaId,
        pekerjaNama: values?.pekerjaNama,
        tglAwal: values?.tglAwal,
        tglAkhir: values?.tglAkhir,
        tglSetup: values?.tglSetup
    };

    const getUnitOrganisasi = useQuery(["unit-organisasi", "dropdown"], () =>
        DendaKeterlambatanApi.getUnitOrganisasi()
    );
    const getPekerja = useQuery(["pekerja", "dropdown", values?.unitOrganisasiId], () =>
        DendaKeterlambatanApi.getPekerja({
            master_unit_organisasi_id: values?.unitOrganisasiId
        })
    );
    const getBulan = useQuery(["bulan", "dropdown"], () => DendaKeterlambatanApi.getBulan());

    return (
        <>
            <Input disabled label="Periode Penggajian" value={values?.periodePerencanaan} />
            <Select
                label="Unit Organisasi"
                options={getUnitOrganisasi?.data ?? []}
                placeholder="Pilih salah satu..."
                defaultValue={
                    values?.unitOrganisasiId
                        ? getUnitOrganisasi?.data?.find((item) => item.value === values?.unitOrganisasiId)
                        : null
                }
                onChange={(val) => {
                    setValues({
                        ...values,
                        unitOrganisasiId: val.value,
                        unitOrganisasiNama: val.label
                    });
                }}
                loading={getUnitOrganisasi?.isFetching}
                errorFetch={getUnitOrganisasi.isError}
                error={Boolean(errors.unitOrganisasiId && touched.unitOrganisasiId)}
                errorText={Boolean(errors.unitOrganisasiId && touched.unitOrganisasiId) && errors.unitOrganisasiId}
            />
            <Select
                label="Pekerja"
                options={getPekerja?.data ?? []}
                placeholder="Pilih salah satu..."
                defaultValue={values?.pekerjaId ? getPekerja?.data?.find((item) => item.value === values?.pekerjaId) : null}
                onChange={(val) => {
                    setValues({
                        ...values,
                        pekerjaId: val.value,
                        pekerjaNama: val.label
                    });
                }}
                loading={getPekerja?.isFetching}
                errorFetch={getPekerja.isError}
                error={Boolean(errors.pekerjaId && touched.pekerjaId)}
                errorText={Boolean(errors.pekerjaId && touched.pekerjaId) && errors.pekerjaId}
            />
            <Select
                label="Bulan Penggajian"
                disable={Boolean(!values?.periodeGajianId)}
                options={getBulan?.data ?? []}
                placeholder="Pilih salah satu..."
                defaultValue={values?.bulan ? getBulan?.data?.find((item) => item.value === values?.bulan) : null}
                onChange={(val) => {
                    const tglNewStart = val.value <= 1 ?
                        `${values?.periodePerencanaan - 1}-12-26`
                        : `${values?.periodePerencanaan}-${val?.value < 10 ? `0${val?.value - 1}` : val?.value - 1}-26`;

                    const tglNewEnd = `${values?.periodePerencanaan}-${val?.value < 10 ? `0${val?.value}` : val?.value}-25`;

                    setValues({
                        ...values,
                        bulan: val.value,
                        bulanNama: val.label,
                        tglAwal: tglNewStart,
                        tglAkhir: tglNewEnd
                    });
                }}
                loading={getBulan?.isFetching}
                errorFetch={getBulan.isError}
                error={Boolean((errors.bulan && touched.bulan) || errors.validateDate)}
                errorText={Boolean(errors.bulan && touched.bulan) && errors.bulan ? errors.bulan : errors.validateDate}
            />
            <div>
                <div style={{ fontSize: 14 }}>Tanggal Penggajian</div>
                <div className="d-flex align-items-center justify-content-between">
                    <div style={{ width: "44%" }}>
                        <DatePicker
                            disabled
                            key={`${values?.periodeGajianId} ${values?.bulan}`}
                            placeholderText="dd/mm/yyyy"
                            selected={values?.periodeGajianId && values?.bulan ? values?.tglAwal : undefined}
                            disable={Boolean(!values?.bulan)}
                            onChange={(val) =>
                                setValues({
                                    ...values,
                                    tglAwal: val,
                                    bulan: new Date(val).getMonth() + 1,
                                    tglAkhir: new Date(new Date(val).setDate(new Date(val).getDate() + 30))
                                })
                            }
                            error={Boolean(errors.tglAwal && touched.tglAwal) || errors.validateDate}
                            errorText={
                                Boolean(errors.tglAwal && touched.tglAwal) && errors.tglAwal ? errors.tglAwal : errors.validateDate
                            }
                        />
                    </div>
                    <small>s/d</small>
                    <div style={{ width: "44%" }}>
                        <DatePicker
                            disabled
                            key={`${values?.periodeGajianId} ${values?.bulan}`}
                            placeholderText="dd/mm/yyyy"
                            selected={values?.periodeGajianId && values?.bulan ? values?.tglAkhir : undefined}
                            disable={Boolean(!values?.bulan)}
                            onChange={(val) =>
                                setValues({
                                    ...values,
                                    tglAkhir: val
                                })
                            }
                            error={Boolean(errors.tglAkhir && touched.tglAkhir)}
                            errorText={Boolean(errors.tglAkhir && touched.tglAkhir) && errors.tglAkhir}
                        />
                    </div>
                </div>
            </div>
            <DatePicker
                disabled
                label="Tanggal Setup"
                placeholderText="dd-mm-yyyy"
                selected={values?.tglSetup}
                onChange={(val) =>
                    setValues({
                        ...values,
                        tglSetup: val
                    })
                }
                error={Boolean(errors.tglSetup && touched.tglSetup)}
                errorText={Boolean(errors.tglSetup && touched.tglSetup) && errors.tglSetup}
            />
            <div className="d-flex justify-content-end">
                <div>
                    {/* <ButtonCancel text="RESET" className="mb-3 me-2" tooltip={false} onClick={() => onResetClickButton()} /> */}
                    <ButtonCreate
                        disabled={Boolean(
                            !values?.periodeGajianId || !values?.unitOrganisasiId || !values?.pekerjaId || !values?.bulan
                        )}
                        text="CARI"
                        className="mb-3"
                        tooltip={false}
                        onClick={() =>
                            onSearchButtonClick({
                                active: true,
                                data: defaultSearch
                            })
                        }
                    />
                </div>
            </div>
        </>
    );
};

const TabelDataPekerja = ({ action }) => {
    const { values, setValues, errors, touched } = useFormikContext();
    const number = numberConvert();
    const date = dateConvert();

    // const setNewValues = ({ index, propname, val }) => {
    //     const detailTemp = [...values?.detail];
    //     const updatedDetail = detailTemp[index];
    //     updatedDetail[propname] = val;

    //     setValues({ ...values, detail: detailTemp });
    // };

    const getError = (index, propname) => {
        return Boolean(errors?.detail?.[index]?.[propname] && touched?.detail?.[index]?.[propname]);
    };

    const getErrorText = (index, propname) => {
        return (
            Boolean(errors?.detail?.[index]?.[propname] && touched?.detail?.[index]?.[propname]) &&
            errors?.detail?.[index]?.[propname]
        );
    };

    // const arrayTotalUangDenda = values?.detail?.reduce((prev, curr) => {
    //     let hasil = 0;
    //     hasil = hasil + curr?.nominal;
    //     return [...prev, hasil];
    // }, []);

    // const calculationFunction = (arr) => {
    //     let sum = 0;
    //     arr.map((e) => (sum += e));
    //     return sum;
    // };

    return (
        <>
            <Row className="mb-4">
                <Col md={6}>
                    <InfoItemVertical label="Periode Penggajian" text={values.periodePerencanaan} />
                    <InfoItemVertical
                        label="Bulan Penggajian"
                        text={
                            (values?.tglAwal ? date.getSlashDMY(new Date(values?.tglAwal)) : "-") +
                            " s/d " +
                            (values?.tglAkhir ? date.getSlashDMY(new Date(values?.tglAkhir)) : "-")
                        }
                    />
                    <InfoItemVertical label="NIK" text={values.nik} />
                </Col>
                <Col md={6}>
                    <InfoItemVertical label="Pekerja" text={values.pekerjaNama} />
                    <InfoItemVertical label="Unit Organisasi" text={values.unitOrganisasiNama} />
                    {/* <InfoItemVertical label="Jabatan" text={values.jabatanNama} /> */}
                </Col>
                <Col md={6}>
                    <InputCurrency
                        id="uangDendaPermenit"
                        placeholder="Masukkan nominal uang denda permenit"
                        label="Uang Denda Permenit"
                        disabled={Boolean(values?.detail.length === 0 || !values?.pekerjaId || action === "READ")}
                        value={number.getWithComa(values?.uangDendaPermenit, { minimumFractionDigits: 2 })}
                        onChange={(val) => {
                            if (values?.detail.length !== 0) {
                                const arr = values?.detail;
                                const newArr = arr.reduce((accumulator, obj) => {
                                    const test = (obj.jumlahTerlambat > 0 && !obj.noSurat) ? val : 0;
                                    return [
                                        ...accumulator,
                                        {
                                            nominal: obj.jumlahTerlambat * test,
                                            tglPresensi: obj.tglPresensi,
                                            jamIn: obj.jamIn,
                                            jumlahTerlambat: obj.jumlahTerlambat,
                                            noSurat: obj.noSurat,
                                            jenisIjinId: obj.jenisIjinId,
                                            jenisIjinNama: obj.jenisIjinNama,
                                        }
                                    ];
                                }, []);

                                const arrayNominal = newArr.reduce((prev, curr) => {
                                    let hasil = 0;
                                    hasil = hasil + curr?.nominal;
                                    return [...prev, hasil];
                                }, []);

                                const calculationFunction = (arr) => {
                                    let sum = 0;
                                    arr.map((e) => (sum += e));
                                    return sum;
                                };

                                setValues({
                                    ...values,
                                    uangDendaPermenit: val,
                                    detail: newArr,
                                    totalUangDenda: calculationFunction(arrayNominal)
                                });
                            }
                        }}
                        error={Boolean(errors.uangDendaPermenit && touched.uangDendaPermenit)}
                        errorText={Boolean(errors.uangDendaPermenit && touched.uangDendaPermenit) && errors.uangDendaPermenit}
                    />
                </Col>
            </Row>

            <TableList
                maxHeight={550}
                multipleTableHeader={[
                    [
                        {
                            props: { rowSpan: 2 },
                            text: "No."
                        },
                        {
                            props: { rowSpan: 2 },
                            text: "Tanggal Presensi"
                        },
                        {
                            props: { rowSpan: 2 },
                            text: "Jam In"
                        },
                        {
                            props: { rowSpan: 2 },
                            text: "Jumlah Terlambat"
                        },
                        {
                            props: { colSpan: 2 },
                            text: "Surat Ijin"
                        },
                        {
                            props: { rowSpan: 2 },
                            text: "Denda"
                        },
                    ],
                    [
                        { text: "No. Surat Ijin" },
                        { text: "Jenis Ijin" },
                    ]
                ]}
                tableBody={[
                    {
                        customField: (val) => (val?.tglPresensi ? date.getSlashDMY(new Date(val?.tglPresensi)) : "-")
                    },
                    {
                        field: "jam"
                    },
                    {
                        customField: (val) =>
                            val?.jumlahTerlambat ? number.getWithComa(val?.jumlahTerlambat, { minimumFractionDigits: 0 }) + " Menit" : "Tidak Terlambat"
                    },
                    {
                        field: "noSurat"
                    },
                    {
                        field: "jenisIjinNama"
                    },
                    {
                        props: { textRight: true },
                        customField: (val, index) =>
                            action === "READ" ? (
                                "Rp" + (val?.nominal ? number.getWithComa(val?.nominal, { minimumFractionDigits: 2 }) : "-")
                            ) : (
                                <InputCurrency
                                    disabled
                                    name="nominal"
                                    className="text-end"
                                    value={"Rp" + number.getWithComa(val?.nominal, { minimumFractionDigits: 2 })}
                                    error={getError(index, "nominal")}
                                    errorText={getErrorText(index, "nominal")}
                                />
                            )
                    }
                ]}
                data={values?.detail}
                tableFooter={[
                    {
                        text: "Total Denda Keterlambatan",
                        props: { colSpan: 6 }
                    },
                    // {
                    //     props: { textRight: true },
                    //     text: calculationFunction(arrayTotalUangDenda)
                    //         ? "Rp" + number.getWithComa(calculationFunction(arrayTotalUangDenda), { minimumFractionDigits: 2 })
                    //         : "Rp" + 0
                    // },
                    {
                        props: { textRight: true },
                        text: values?.totalUangDenda
                            ? "Rp" + number.getWithComa(values?.totalUangDenda, { minimumFractionDigits: 2 })
                            : "Rp" + 0
                    }
                ]}
            />
        </>
    );
};

export const Content = ({ action, onSearchButtonClick, onResetClickButton }) => {
    const { values, setValues, errors, touched } = useFormikContext();
    const number = numberConvert();

    if (action === "READ") {
        return (
            <>
                <InfoItemVertical label="Pekerja" text={values.pekerjaNama} />
                <InfoItemVertical label="Jabatan" text={values.jabatanNama} />
                <InfoItemVertical label="Unit Organisasi" text={values.unitOrganisasiNama} />
                <InfoItemVertical
                    label="Total Denda Keterlambatan"
                    text={values.nominal ? "Rp" + number.getWithComa(values?.nominal, { minimumFractionDigits: 2 }) : "-"}
                />
            </>
        );
    }

    if (action === "UPDATE") {
        return (
            <>
                <Input disabled label="Pekerja" value={values?.pekerjaNama} />
                <Input disabled label="Unit Organisasi" value={values?.unitOrganisasiNama} />
                <Input disabled label="Jabatan" value={values?.jabatanNama} />
                <InputCurrency
                    name="nominal"
                    label="Total Denda Keterlambatan"
                    placeholder="Masukkan total denda keterlambatan"
                    value={values.nominal ? "Rp" + number.getWithComa(values?.nominal, { minimumFractionDigits: 2 }) : "-"}
                    onChange={(val) =>
                        setValues({
                            ...values,
                            nominal: val
                        })
                    }
                    error={Boolean(errors.nominal && touched.nominal)}
                    errorText={Boolean(errors.nominal && touched.nominal) && errors.nominal}
                />
            </>
        );
    }

    return (
        <>
            <Row>
                <Col md={4} className="mb-4">
                    <Card style={{ height: "100%" }}>
                        <Card.Header>
                            <b>Setup Data</b>
                        </Card.Header>
                        <Card.Body>
                            <SectionDataSetup onSearchButtonClick={onSearchButtonClick} onResetClickButton={onResetClickButton} />
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={8}>
                    <Card style={{ height: "100%" }}>
                        <Card.Header>
                            <b>Data Pekerja</b>
                        </Card.Header>
                        <Card.Body>
                            <TabelDataPekerja action={action} />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};
