export const formInitialValues = (data = {}) => ({
  id: data?.surat_cuti_id ?? "",
  periodeGajianId: data?.periode_tahun ?? new Date().getFullYear(),
  periodePerencanaan: data?.periode_tahun ?? new Date().getFullYear(),
  tglInput: data?.tgl_input ?? new Date(),
  unitOrganisasiId: data?.master_unit_organisasi_id ?? "",
  unitOrganisasiNama: data?.master_unit_organisasi_nama ?? "",
  pekerjaId: data?.pekerja_id ?? "",
  pekerjaNama: data?.pekerja_nama ?? "",
  jabatanId: data?.master_jabatan_id ?? "",
  jabatanNama: data?.master_jabatan_nama ?? "",
  jenisCutiId: data?.master_jenis_cuti_id ?? "",
  jenisCutiNama: data?.master_jenis_cuti_nama ?? "",
  nomor: data?.nomor ?? "",
  maxCuti: data?.maksimal_cuti ?? "",
  cutiSudah: data?.total_cuti_tahun_ini ?? "",
  sisaCuti: data?.total_sisa_cuti ?? "",
  tglMulai: data?.tgl_mulai ?? "",
  tglAkhir: data?.tgl_akhir ?? "",
  lamaCuti: data?.lama_cuti ?? "",
  alasan: data?.alasan ?? ""
});
