import * as Yup from "yup";

export const formValidationSchema = Yup.object().shape({
  periodePenggajianId: Yup.string().required("Periode penggajian wajib diisi !"),
  tanggalSetup: Yup.string().required("Tanggal setup wajib diisi !"),
  kodeTunjangan: Yup.string().required("Kode tunjangan wajib diisi !"),
  shiftId: Yup.string().required("Nama shift wajib diisi !"),
  nominal: Yup.string().required("Nominal wajib diisi !"),
  status: Yup.string().required("Status wajib diisi !")
});
