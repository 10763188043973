import { Services } from "services";

class JenisRitaseApi {
    async getList(params) {
        const fetch = await Services.get("/master/jenis-ritase", { params });
        return fetch?.data?.data;
    }

    async getDetail(id) {
        const fetch = await Services.get("/master/jenis-ritase/" + id);
        return fetch?.data?.data;
    }

    getStatusKomponenPayroll() {
        return [
            {
                value: 1,
                label: "Penghasilan"
            },
            {
                value: 2,
                label: "Pemotongan"
            }
        ];
    }

    async getExport(params) {
        const fetch = await Services.get("/master/jenis-ritase/export?to=", { params });
        return fetch?.data?.data;
    }

    create(data) {
        return Services.post("/master/jenis-ritase", data);
    }

    update(data, id) {
        return Services.put("/master/jenis-ritase/" + id, data);
    }

    delete(id) {
        return Services.delete("/master/jenis-ritase/" + id);
    }
}

export default new JenisRitaseApi();
