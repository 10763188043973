export const formSubmitMapper = {
  FormCreate: (values = {}) => ({
    periode_tahun: values?.idPeriode,
    tgl_setup: values?.tglSetup,
    tgl_bulan_awal: values?.tglAwal,
    tgl_bulan_akhir: values?.tglAkhir,
    master_unit_organisasi_id: values?.idUnitOrganisasi,
    pekerja_id: values?.idPekerja,
    koreksi: values?.tabelListKoreksi?.map((val) => ({
      master_komponen_payroll_id: val?.idKomponenUpah,
      jenis_koreksi: val?.idJenisKoreksi,
      nominal_koreksi: val?.nominal,
      keterangan: val?.keterangan,
      pekerja_koreksi_bulanan_id: val?.id
    }))
  })
};
