import { Services } from "services";
import { fetchDropdownMapper } from "utilities";
const fetchDropdown = fetchDropdownMapper(Services);

class JabatanApi {
  async getList(params) {
    const fetch = await Services.get("/master/jabatan", { params });
    return fetch?.data?.data;
  }

  async getExport(params) {
    const fetch = await Services.get("/master/jabatan/export", { params });
    return fetch?.data?.data;
  }

  async getJenisJabatan() {
    const getJenisJabatan = await fetchDropdown("/master/jenis-jabatan", {
      value: "jenis_jabatan_id",
      label: "jenis_jabatan_nama"
    });

    return getJenisJabatan;
  }

  async getJobStream() {
    const getJobStreamDropdown = await fetchDropdown("/master/job-stream/dropdown", {
      value: "master_job_stream_id",
      label: "master_job_stream_nama"
    });

    return getJobStreamDropdown;
  }

  async getJenisProfesi() {
    const getJenisProfesiDropdown = await fetchDropdown("/master/jenis-profesi/dropdown", {
      value: "master_jenis_profesi_id",
      label: "master_jenis_profesi_nama"
    });

    return getJenisProfesiDropdown;
  }

  async getKelompokJabatan() {
    const getJenisDropdown = await fetchDropdown("/master/kelompok-jabatan/dropdown", {
      value: "master_kelompok_jabatan_id",
      label: "master_kelompok_jabatan_nama"
    });

    return getJenisDropdown;
  }

  async getUnitOrganisasi() {
    const getJenisDropdown = await fetchDropdown("/master/unit-organisasi/dropdown", {
      value: "master_unit_organisasi_id",
      label: "master_unit_organisasi_nama"
    });

    return getJenisDropdown;
  }

  async getAtasanLangsung() {
    const getJenisDropdown = await fetchDropdown("/master/jabatan/dropdown", {
      value: "master_jabatan_id",
      label: "master_jabatan_nama"
    });

    return getJenisDropdown;
  }

  create(data) {
    return Services.post("/master/jabatan", data);
  }

  update(data, id) {
    return Services.put("/master/jabatan/" + id, data);
  }

  delete(id) {
    return Services.delete("/master/jabatan/" + id);
  }
}

export default new JabatanApi();
