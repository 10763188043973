import {
  RealisasiPekerjaPensiunList,
  RealisasiPekerjaPensiunCreate,
  RealisasiPekerjaPensiunUpdate,
  DataPekerjaPensiunList
} from "pages/OprasionalSdm";

export const RealisasiPekerjaPensiun = {
  name: "Realisasi Pekerja Pensiun",
  link: "realisasi-pekerja-pensiun",
  access: "REALISASI_PEKERJA_PENSIUN",
  component: <RealisasiPekerjaPensiunList />,
  child: [
    {
      name: "Tambah",
      link: "/tambah/:id",
      component: <RealisasiPekerjaPensiunCreate />
    },
    {
      name: "Data Pekerja Pensiun",
      link: "/list-pekerja-pensiun",
      component: <DataPekerjaPensiunList />
    },
    {
      name: "Ubah",
      link: "/ubah/:id",
      component: <RealisasiPekerjaPensiunUpdate />
    }
  ]
};
