export const formSubmitValueMapper = {
    detailData: (data) => ({
        periode_tahun: data?.periodeGajianId,
        tgl_setup: data?.tglSetup,
        tgl_bulan_awal: data?.tglAwal,
        tgl_bulan_akhir: data?.tglAkhir,
        pekerja_id: data?.pekerjaId,
        master_unit_organisasi_id: data?.unitOrganisasiId,
        master_jabatan_id: data?.jabatanId,
        total_upah_ritase_cp: data?.totalUpahRitase,

        detail: data?.detail?.map((val) => ({
            pekerja_upah_ritase_cp_bulanan_detail_id: val?.detailId,
            tgl_nota: val?.tglNota,
            nomor_nota: val?.noNota,
            proyek_id: val?.proyekId,
            proyek_nama: val?.proyekNama,
            upah_ritase_cp: val?.upahRitase
        }))
    }),

    modalData: (data) => ({
        pekerja_lembur_perjam_bulanan_id: data?.id,
        komponen_payroll: data?.komponenPayroll,
        periode_tahun: data?.periodeGajianId,
        tgl_bulan_awal: data?.tglAwal,
        tgl_bulan_akhir: data?.tglAkhir,
        tgl_setup: data?.tglSetup,
        pekerja_id: data?.pekerjaId,
        master_unit_organisasi_id: data?.unitOrganisasiId,
        total_upah_ritase_cp: data?.totalUpahRitase,
    }),

    quickPostData: (data) => ({
        periode_tahun: data?.periodeGajianId,
        tgl_bulan_awal: data?.tglAwal,
        tgl_bulan_akhir: data?.tglAkhir,
    })
};
