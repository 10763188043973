import { SetupKomponenJaminanKematianBulananList, SetupKomponenJaminanKematianBulananCreate } from "pages/Payroll";

export const SetupKomponenJaminanKematianBulanan = {
  component: <SetupKomponenJaminanKematianBulananList />,
  link: "setup-komponen-jaminan-kematian-bulanan",
  name: "Setup Komponen Jaminan Kematian Bulanan",
  access: "SETUP_JAMINAN_KEMATIAN_BULANAN",
  child: [
    {
      name: "Tambah",
      link: "/tambah",
      component: <SetupKomponenJaminanKematianBulananCreate />
    }
  ]
};
