import React from "react";
import { Col } from "react-bootstrap";
import {
  TabIdentitasJabatan,
  TabTujuanJabatan,
  TabAkuntabilitasJabatan,
  TabTugasRutin,
  TabHubunganKerja,
  TabDimensi,
  TabWewenang,
  TabSpesifikasi,
  TabKondisiKerja,
  TabStrukturOrganisasi
} from "./TabComp";
import { ContentLayout, Tabs } from "components";
import CONFIG from "../../../../../config";

export const Content = ({ action, getDetailIsFetching, getDetailIsError }) => {
  return (
    <>
      {action === "CREATE" ? (
        <div>
          <Tabs
            defaultActiveKey="identitas-jabatan"
            tabs={[
              {
                key: "identitas-jabatan",
                title: "Identitas Jabatan",
                component: (
                  <div className="d-flex justify-content-center">
                    <Col md={6}>
                      <TabIdentitasJabatan action={action} />
                    </Col>
                  </div>
                )
              }
            ]}
          />
        </div>
      ) : action === "READ" ? (
        <>
          <div className="d-flex justify-content-center mb-3">
            <img src={CONFIG.LOGO} alt="" style={{ maxWidth: 100 }} />
            <b style={{ fontSize: 25, marginTop: 55, marginLeft: 20 }}>URAIAN JABATAN</b>
          </div>
          <ContentLayout title="Identitas Jabatan" className="mb-3">
            <TabIdentitasJabatan action={action} />
          </ContentLayout>
          <ContentLayout title="Tujuan Jabatan" className="mb-3">
            <TabTujuanJabatan action={action} />
          </ContentLayout>
          <ContentLayout title="Akuntabilitas Utama" className="mb-3">
            <TabAkuntabilitasJabatan action={action} />
          </ContentLayout>
          <ContentLayout title="Tugas-tugas Rutin" className="mb-3">
            <TabTugasRutin action={action} />
          </ContentLayout>
          <ContentLayout title="Wewenang" className="mb-3">
            <TabWewenang action={action} />
          </ContentLayout>
          <ContentLayout title="Hubungan Kerja" className="mb-3">
            <TabHubunganKerja action={action} />
          </ContentLayout>
          <ContentLayout title="Dimensi" className="mb-3">
            <TabDimensi action={action} />
          </ContentLayout>
          <ContentLayout title="Spesifikasi" className="mb-3">
            <TabSpesifikasi action={action} />
          </ContentLayout>
          <ContentLayout title="Kondisi Kerja" className="mb-3">
            <TabKondisiKerja action={action} />
          </ContentLayout>
          <ContentLayout title="Kedudukan Struktur Organisasi" className="mb-3">
            <TabStrukturOrganisasi action={action} />
          </ContentLayout>
        </>
      ) : (
        <div>
          <Tabs
            id="pekerja"
            defaultActiveKey="identitas-jabatan"
            fill
            tabs={[
              {
                key: "identitas-jabatan",
                title: "Identitas Jabatan",
                component: (
                  <div className="d-flex justify-content-center">
                    <Col md={action === "READ" ? 12 : 6}>
                      <TabIdentitasJabatan action={action} />
                    </Col>
                  </div>
                )
              },
              {
                key: "tujuan-jabatan",
                title: "Tujuan Jabatan",
                component: (
                  <div className="d-flex justify-content-center">
                    <Col md={action === "READ" ? 12 : 6}>
                      <TabTujuanJabatan action={action} />
                    </Col>
                  </div>
                )
              },
              {
                key: "akuntabilitas-utama",
                title: "Akuntabilitas Utama",
                component: <TabAkuntabilitasJabatan action={action} />
              },
              {
                key: "tugas-rutin",
                title: "Tugas-tugas Rutin",
                component: <TabTugasRutin action={action} />
              },
              {
                key: "wewenang",
                title: "Wewenang",
                component: <TabWewenang action={action} />
              },
              {
                key: "hubungan-kerja",
                title: "Hubungan Kerja",
                component: <TabHubunganKerja action={action} />
              },
              {
                key: "dimensi",
                title: "Dimensi",
                component: <TabDimensi action={action} />
              },
              {
                key: "spesifikasi",
                title: "Spesifikasi",
                component: <TabSpesifikasi action={action} />
              },
              {
                key: "kondisi-kerja",
                title: "Kondisi Kerja",
                component: (
                  <div className="d-flex justify-content-center">
                    <Col md={action === "READ" ? 12 : 6}>
                      <TabKondisiKerja action={action} />
                    </Col>
                  </div>
                )
              },
              {
                key: "kedudukan-struktur-organisasi",
                title: "Kedudukan Struktur Organisasi",
                component: (
                  <TabStrukturOrganisasi
                    action={action}
                    getDetailIsFetching={getDetailIsFetching}
                    getDetailIsError={getDetailIsError}
                  />
                )
              }
            ]}
          />
        </div>
      )}
    </>
  );
};
