import Axios from "axios";

const { REACT_APP_API_BASE_URL } = process.env;

export const Services = Axios.create({
  baseURL: REACT_APP_API_BASE_URL
});

Services.defaults.timeout = 30000;

Services.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem("access_token");
  const token = "Bearer " + accessToken;

  config.headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: accessToken ? token : undefined
  };

  return config;
});

Services.interceptors.response.use(
  (res) => res,
  async (err) => {
    const refreshToken = localStorage.getItem("refresh_token");

    if (err.response.status === 401) {
      return await Services.post(REACT_APP_API_BASE_URL + "/auth/refresh", {
        refresh_token: refreshToken
      })
        .then((res) => {
          const newAccessToken = res?.data?.data?.access_token;
          const newRefreshToken = res?.data?.data?.refresh_token;

          localStorage.setItem("access_token", newAccessToken);
          localStorage.setItem("refresh_token", newRefreshToken);
        })
        .catch(() => {
          localStorage.removeItem("auth-token");
          localStorage.removeItem("access_token");
          localStorage.removeItem("refresh_token");
          window.location = "/";
        });
    } else {
      return Promise.reject(err);
    }
  }
);
