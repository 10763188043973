import { Services } from "services";
import { fetchDropdownMapper } from "utilities";

const fetchDropdown = fetchDropdownMapper(Services);

class SkupPekerjaanApi {
  async getList(params) {
    const fetch = await Services.get("/master/skup-pekerjaan/", { params });
    return fetch?.data?.data;
  }
  async getListKlien(params) {
    const fetch = await Services.get("/master/klien/", { params });
    return fetch?.data?.data?.map((val) => ({ value: val?.master_klien_id, label: val?.master_klien_nama }));
  }
  async getDetail(id) {
    const fetch = await Services.get("/master/skup-pekerjaan/" + id);
    return fetch?.data?.data;
  }
  async getExport(params) {
    const fetch = await Services.get("/master/skup-pekerjaan/export", { params });
    return fetch?.data?.data;
  }
  async getNamaKlien() {
    const getNamaKlien = await fetchDropdown("/master/klien/dropdown", {
      value: "master_klien_id",
      label: "master_klien_nama"
    });
    return [...getNamaKlien];
  }
  create(data) {
    return Services.post("/master/skup-pekerjaan/", data);
  }
  update(data, id) {
    return Services.put("/master/skup-pekerjaan/" + id, data);
  }
  delete(id) {
    return Services.delete("/master/skup-pekerjaan/" + id);
  }
}

export default new SkupPekerjaanApi();
