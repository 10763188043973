import { TunjanganTetapTahunanList, TunjanganTetapTahunanCreate } from "pages/Payroll";

export const TunjanganTetapTahunan = {
  component: <TunjanganTetapTahunanList />,
  link: "tunjangan-tetap-tahunan",
  name: "Tunjangan Tetap Tahunan",
  access: "SETUP_TUNJANGAN_TETAP",
  child: [
    {
      name: "Tambah",
      link: "/tambah",
      component: <TunjanganTetapTahunanCreate />
    }
  ]
};
