import React from "react";
import { useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, useFormikContext } from "formik";
import { Button, InfoItemVertical, Input } from "components";
import { errorSubmitMapper } from "utilities";
import { useModalConfirm } from "hooks";
import { formInitialValues, formSubmitMapper, formValidationSchema } from "../__PekerjaUtils__";
import PekerjaApi from "../__PekerjaApi__";

const DetailAkun = () => {
  const { values } = useFormikContext();
  return (
    <>
      <InfoItemVertical label="Nama" text={values.pekerjaNama} />
      <InfoItemVertical label="Username" text={values.akun} />
    </>
  );
};

const FormAkun = () => {
  const { values, errors, touched, handleChange } = useFormikContext();

  return (
    <>
      <Input
        label="Nama"
        name="pekerjaNama"
        placeholder="Masukan nama pekerja"
        value={values?.pekerjaNama}
        onChange={handleChange}
        error={Boolean(errors.pekerjaNama && touched.pekerjaNama)}
        errorText={Boolean(errors.pekerjaNama && touched.pekerjaNama) && errors.pekerjaNama}
      />

      <Input
        label="Username"
        name="akun"
        placeholder="Masukan username"
        value={values?.akun}
        onChange={handleChange}
        error={Boolean(errors.akun && touched.akun)}
        errorText={Boolean(errors.akun && touched.akun) && errors.akun}
      />

      <Input
        label="Password"
        name="password"
        type="password"
        placeholder="Masukan password"
        value={values?.password}
        onChange={handleChange}
        error={Boolean(errors.password && touched.password)}
        errorText={Boolean(errors.password && touched.password) && errors.password}
      />
      <Input
        label="Konfirmasi Password"
        type="password"
        name="passwordKonfirmasi"
        placeholder="Masukan konfirmasi password"
        value={values?.passwordKonfirmasi}
        onChange={handleChange}
        error={Boolean(errors.passwordKonfirmasi && touched.passwordKonfirmasi)}
        errorText={Boolean(errors.passwordKonfirmasi && touched.passwordKonfirmasi) && errors.passwordKonfirmasi}
      />
    </>
  );
};

export const TabAkun = ({ action, refreshData, isGeneralUser }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const modalConfirm = useModalConfirm();
  const parentFormik = useFormikContext();

  const createPekerjaAkun = useMutation((data) => PekerjaApi.create(data), {
    onSuccess: (data) => {
      modalConfirm.infoSuccess({ typeInfo: "create", customTextInfoTitle: "Data Berhasil Disimpan" });
      navigate("/pekerja/transaksi/pekerja/ubah/" + data?.data?.data);
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "create", ...errorSubmitMapper(err) })
  });

  const updatePekerjaAkun = useMutation((data) => PekerjaApi.update(data, id), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "update", customTextInfoTitle: "Data Berhasil Disimpan" });
      refreshData();
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "update", ...errorSubmitMapper(err) })
  });

  const updatePekerjaAkunSelf = useMutation((data) => PekerjaApi.updateSelf(data), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "update", customTextInfoTitle: "Data Berhasil Disimpan" });
      refreshData();
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "update", ...errorSubmitMapper(err) })
  });

  const submitValidationHandler = (errors) =>
    new Promise((resolve, reject) => {
      const getError = Object.values(errors);

      if (getError.length > 0) {
        reject(getError);
      } else {
        resolve();
      }
    });

  const preSubmitHandler = (values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting) => {
    setSubmitting(true);
    validateForm().then(async (err) => {
      setErrors(err);
      setTouched(err);

      await submitValidationHandler(err, values)
        .then(() => handleSubmit())
        .catch((err) =>
          modalConfirm.trigger({
            type: "error",
            title: "Data Tidak Lengkap",
            data: err,
            onHide: () => modalConfirm.close()
          })
        )
        .finally(() => {
          setSubmitting(false);
        });
    });
  };

  const formSubmitHandler = (values) =>
    modalConfirm.trigger({
      type: "custom",
      onHide: modalConfirm.close,
      title: "Konfirmasi",
      customButtonShow: true,
      customButtonText: "Simpan",
      customButtonVariant: "primary",
      customTextHeader: "Apakah anda yakin menyimpan data ini?",
      onSubmit: () => {
        modalConfirm.loading();
        if (isGeneralUser === true) {
          updatePekerjaAkunSelf.mutate(formSubmitMapper.akun(values));
        }
        else {
          if (action === "CREATE") {
            createPekerjaAkun.mutate(formSubmitMapper.akun(values));
          }
          if (action === "UPDATE") {
            updatePekerjaAkun.mutate(formSubmitMapper.akun(values));
          }
        }
      },
      component: (
        <Formik initialValues={values}>
          <DetailAkun />
        </Formik>
      )
    });

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues.akun(parentFormik.values)}
      validationSchema={formValidationSchema.akun(action)}
      onSubmit={formSubmitHandler}
    >
      {({ values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting }) => {
        return (
          <>
            {action === "READ" ? <DetailAkun /> : <FormAkun />}
            {!Boolean(action === "READ") && (
              <div className="text-end mt-3">
                <Button
                  text="Simpan"
                  className="px-4"
                  onClick={() =>
                    preSubmitHandler(values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting)
                  }
                />
              </div>
            )}
          </>
        );
      }}
    </Formik>
  );
};
