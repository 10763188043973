import { InputTimeSheetConcretePumpList, InputTimeSheetConcretePumpCreate, InputTimeSheetConcretePumpUpdate, InputTimeSheetConcretePumpDetail } from "pages/Payroll";

export const InputTimeSheetConcretePump = {
    component: <InputTimeSheetConcretePumpList />,
    link: "time-sheet-concrete-pump",
    name: "Time Sheet Concrete Pump",
    access: "TIME_SHEET_CONCRETE",
    child: [
        {
            name: "Tambah",
            link: "/tambah",
            component: <InputTimeSheetConcretePumpCreate />
        },
        {
            name: "Detail",
            link: "/detail/:id",
            component: <InputTimeSheetConcretePumpDetail />
        },
        {
            name: "Ubah",
            link: "/ubah/:id",
            component: <InputTimeSheetConcretePumpUpdate />
        }
    ]
};
