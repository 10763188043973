import { SetupJadwalList, SetupJadwalCreate, SetupJadwalUpdate, SetupJadwalDetail } from "pages/TimeAttendance";

export const SetupJadwal = {
  component: <SetupJadwalList />,
  link: "setup-jadwal",
  name: "Setup Jadwal",
  access: "SETUP_JADWAL",
  child: [
    {
      name: "Detail",
      link: "/detail/:id",
      component: <SetupJadwalDetail />
    },
    {
      name: "Tambah",
      link: "/tambah",
      component: <SetupJadwalCreate />
    },
    {
      name: "Ubah",
      link: "/ubah/:id",
      component: <SetupJadwalUpdate />
    }
  ]
};
