import React from "react";
import { useFormikContext } from "formik";
import { Input, InfoItemVertical, TextArea } from "components";

export const Content = ({ action }) => {
  const { values, errors, touched, handleChange } = useFormikContext();

  if (action === "READ") {
    return (
      <>
        <InfoItemVertical label="Kode" text={values?.kode ?? "-"} />
        <InfoItemVertical label="Komponen" text={values?.namaKomponen ?? "-"} />
        <InfoItemVertical label="Keterangan" text={values?.keterangan ?? "-"} />
      </>
    );
  }

  return (
    <>
      <Input
        label="Kode"
        name="kode"
        placeholder="Masukkan kode"
        value={values.kode}
        onChange={handleChange}
        error={Boolean(errors.kode && touched.kode)}
        errorText={Boolean(errors.kode && touched.kode) && errors.kode}
      />
      <Input
        label="Nama Komponen"
        name="namaKomponen"
        placeholder="Masukkan nama komponen"
        value={values.namaKomponen}
        onChange={handleChange}
        error={Boolean(errors.namaKomponen && touched.namaKomponen)}
        errorText={Boolean(errors.namaKomponen && touched.namaKomponen) && errors.namaKomponen}
      />
      <TextArea
        label="Keterangan"
        name="keterangan"
        placeholder="Masukkan keterangan"
        value={values.keterangan}
        onChange={handleChange}
        error={Boolean(errors.keterangan && touched.keterangan)}
        errorText={Boolean(errors.keterangan && touched.keterangan) && errors.keterangan}
      />
    </>
  );
};
