import { rupiahConvert } from "components";
const rupiah = rupiahConvert();

export const formInitialValues = {
  FormTabel: (datax) => ({
    tabelDataPekerja: datax?.map((data) => ({
      nip: data.nip,
      namaPekerja: data.pekerja_nama,
      idPekerja: data.pekerja_id,
      namaUnitOrganisasi: data.master_unit_organisasi_nama,
      idUnitOrganisasi: data.master_unit_organisasi_id,
      namaJabatan: data.master_jabatan_nama,
      idJabatan: data.master_jabatan_id,
      upahBpjs: data.upah_bpjs ? rupiah.getWithComa(Number(data.upah_bpjs)) : undefined,
      besaranUpah: data.besaran_upah ? rupiah.getWithComa(Number(data.besaran_upah)) : undefined,
      iuranPerusahaan: data.iuran_perusahaan ? rupiah.getWithComa(Number(data.iuran_perusahaan)) : undefined,
      iuranPekerja: data.iuran_karyawan ? rupiah.getWithComa(Number(data.iuran_karyawan)) : undefined
    }))
  })
};
