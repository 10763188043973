import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import { Formik } from "formik";
import { ContentLayout } from "layouts";
import { ButtonBack, ButtonUpdate, DataStatus } from "components";
import { errorSubmitMapper, errorFetchingPageMapper } from "utilities";
import { useModalConfirm } from "hooks";
import { Content } from "./__EvaluasiJabatanComps__";
import { formInitialValues, formValidationSchema, formSubmitValueMapper } from "./__EvaluasiJabatanUtils__";
import EvaluasiJabatanApi from "./__EvaluasiJabatanApi__";

export const EvaluasiJabatanUpdate = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const modalConfirm = useModalConfirm();
  const getEvaluasiJabatan = useQuery(["evaluasi-jabatan", id], () => EvaluasiJabatanApi.getSingle(id));

  const updateEvaluasiJabatan = useMutation((data) => EvaluasiJabatanApi.update(data, id), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "update", size: "md" });
      navigate(-1);
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "update", size: "md", ...errorSubmitMapper(err) })
  });

  const submitValidationHandler = (errors) =>
    new Promise((resolve, reject) => {
      const getError = Object.values(errors);

      if (getError.length > 0) {
        reject(getError);
      } else {
        resolve();
      }
    });

  const preSubmitHandler = (formik) => {
    const { values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting } = formik;

    setSubmitting(true);
    validateForm().then(async (err) => {
      setErrors(err);
      setTouched(err);
      await submitValidationHandler(err, values)
        .then(() => handleSubmit())
        .catch((err) =>
          modalConfirm.trigger({
            type: "error",
            title: "Data Tidak Lengkap",
            data: err,
            onHide: () => modalConfirm.close()
          })
        )
        .finally(() => {
          setSubmitting(false);
        });
    });
  };

  const formSubmitHandler = (values) =>
    modalConfirm.trigger({
      type: "update",
      size: "lg",
      onHide: modalConfirm.close,
      onSubmit: () => {
        modalConfirm.loading();

        updateEvaluasiJabatan.mutate(formSubmitValueMapper(values));
      },
      component: (
        <Formik initialValues={values}>
          <Content action="READ" />
        </Formik>
      )
    });

  return (
    <div>
      <ContentLayout showBreadcrumb>
        <div className="text-end mb-3">
          <ButtonBack tooltip={false} onClick={() => navigate(-1)} />
        </div>
        {getEvaluasiJabatan.isFetching || getEvaluasiJabatan.isError ? (
          <DataStatus
            loading={getEvaluasiJabatan.isFetching}
            text={errorFetchingPageMapper(getEvaluasiJabatan.error)}
          />
        ) : (
          <Formik
            initialValues={formInitialValues(getEvaluasiJabatan.data)}
            validationSchema={formValidationSchema}
            onSubmit={formSubmitHandler}
          >
            {(formik) => (
              <>
                <Content action="UPDATE" />
                <div className="text-end my-4">
                  <ButtonUpdate tooltip={false} className="px-4" onClick={() => preSubmitHandler(formik)} />
                </div>
              </>
            )}
          </Formik>
        )}
      </ContentLayout>
    </div>
  );
};
