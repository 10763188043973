import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { Formik } from "formik";
import { ContentLayout } from "layouts";
import { ButtonBack, DataStatus } from "components";
import { Content } from "./__TunjanganUangMakanComps__";
import { formInitialValues } from "./__TunjanganUangMakanUtilities__";
import TunjanganUangMakanApi from "./__TunjanganUangMakanApi__";

export const TunjanganUangMakanDetail = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const getDetail = useQuery(
        [
            "detail",
            "tunjangan-uang-makan",
            id
        ],
        () =>
            TunjanganUangMakanApi.getDetail(id)
    );


    return (
        <>
            <ContentLayout showBreadcrumb>
                {getDetail?.isFetching ? (
                    <DataStatus loading />
                ) : (
                    <>
                        <div className="text-end mb-3">
                            <ButtonBack tooltip={false} onClick={() => navigate("/payroll/transaksi/tunjangan-uang-makan")} />
                        </div>
                        <Formik
                            enableReinitialize
                            initialValues={formInitialValues(getDetail?.data)}
                        >
                            <Content
                                action="READ"
                                loading={getDetail.isFetching}
                            />
                        </Formik>
                    </>
                )}
            </ContentLayout>
        </>
    );
};
