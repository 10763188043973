import { Services } from "services";

class TerPersenTarifApi {
    async getList(params) {
        const fetch = await Services.get("/master/persentase-tarif-efektif-rata", { params });
        return fetch?.data?.data;
    }

    async getDetail(id) {
        const fetch = await Services.get("/master/persentase-tarif-efektif-rata/" + id);
        return fetch?.data?.data;
    }

    async getTer() {
        return [
            {
                value: "A",
                label: "A"
            },
            {
                value: "B",
                label: "B"
            },
            {
                value: "C",
                label: "C"
            }
        ];
    }

    async getExport(params) {
        const fetch = await Services.get("/master/persentase-tarif-efektif-rata/export", { params });
        return fetch?.data?.data;
    }

    create(data) {
        return Services.post("/master/persentase-tarif-efektif-rata", data);
    }

    update(data, id) {
        return Services.put("/master/persentase-tarif-efektif-rata/" + id, data);
    }

    delete(id) {
        return Services.delete("/master/persentase-tarif-efektif-rata/" + id);
    }
}

export default new TerPersenTarifApi();
