import { SuratPenugasanKhususList, SuratPenugasanKhususCreate, SuratPenugasanKhususUpdate } from "pages/OprasionalSdm";

export const SuratPenugasanKhusus = {
  component: <SuratPenugasanKhususList />,
  link: "surat-penugasan-khusus",
  name: "Surat Penugasan Khusus",
  access: "SURAT_PENUGASAN_KHUSUS",
  child: [
    {
      name: "Ubah",
      link: "/ubah/:id",
      component: <SuratPenugasanKhususUpdate />
    },
    {
      name: "Tambah",
      link: "/tambah",
      component: <SuratPenugasanKhususCreate />
    }
  ]
};
