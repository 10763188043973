import {
  ButtonDetail, ModalCreate, ModalDetail, ModalUpdate,
  toastTrigger
} from "components";
import { Form, Formik } from "formik";
import { useAccessRights, useLayout, useModalConfirm } from "hooks";
import { ListLayout } from "layouts";
import { debounce } from "lodash";
import { useState } from "react";
import { ButtonGroup } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import { errorFetchingTableMapper, errorSubmitMapper, exportHandler } from "utilities";
import KomponenBPJSApi from "./__KomponenBPJSApi__";
import { Content } from "./__KomponenBPJSComps__";
import { formInitialValues, formSubmitValueMapper, formValidationSchema } from "./__KomponenBPJSUtilities__";

export const KomponenBPJSList = () => {
  const layout = useLayout();
  const access = useAccessRights("KOMPONEN_BPJS");
  const modalConfirm = useModalConfirm();

  const [searchKey, setSearchKey] = useState(undefined);
  const [pagination, setPagination] = useState({
    pageCurrent: 1,
    dataLength: 10
  });
  const [modal, setModal] = useState({
    show: false,
    action: "",
    data: {}
  });

  const getListKomponenBPJS = useQuery(
    ["komponen-bpjs", "list", searchKey, pagination.pageCurrent, pagination.dataLength],
    () =>
      KomponenBPJSApi.getList({
        search: searchKey,
        page: pagination.pageCurrent,
        limit: pagination.dataLength
      })
  );
  const createKomponenBPJS = useMutation((data) => KomponenBPJSApi.create(data), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "create" });
      getListKomponenBPJS.refetch();
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "create", ...errorSubmitMapper(err) })
  });
  const updateKomponenBPJS = useMutation(({ data, id }) => KomponenBPJSApi.update(data, id), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "update" });
      getListKomponenBPJS.refetch();
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "update", ...errorSubmitMapper(err) })
  });
  const deleteKomponenBPJS = useMutation((id) => KomponenBPJSApi.delete(id), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "delete" });
      getListKomponenBPJS.refetch();
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "delete", ...errorSubmitMapper(err) })
  });
  const exportKomponenBPJS = useMutation((type) => KomponenBPJSApi.getExport({ to: type }), {
    onSuccess: (link) => {
      toastTrigger({ variant: "success", message: "Export berhasil" });
      exportHandler(link, "Data Komponen BPJS");
    },
    onError: () => {
      toastTrigger({ variant: "danger", message: "Export gagal" });
    }
  });

  const searchHandler = (e) => {
    setSearchKey(e?.target?.value ? e.target.value : undefined);
  };
  const onHideModalHandler = () => setModal({ show: false, action: "", data: {} });
  const onCreateButtonClickHandler = () => setModal({ show: true, action: "CREATE", data: {} });
  const onDetailButtonClickHandler = (data) => setModal({ show: true, action: "READ", data: data });
  // const onUpdateButtonClickHandler = (data) => setModal({ show: true, action: "UPDATE", data: data });
  const onPaginationChange = (data) => setPagination({ ...pagination, pageCurrent: data });
  const onPaginationDataLengthChange = (data) => setPagination({ ...pagination, dataLength: data });

  const submitValidationHandler = (errors) =>
    new Promise((resolve, reject) => {
      const getError = Object.values(errors);

      if (getError.length > 0) {
        reject(getError);
      } else {
        resolve();
      }
    });
  const preSubmitHandler = (formik) => {
    const { values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting } = formik;

    setSubmitting(true);
    validateForm().then(async (err) => {
      setErrors(err);
      setTouched(err);

      await submitValidationHandler(err, values)
        .then(() => handleSubmit())
        .catch((err) =>
          modalConfirm.trigger({
            type: "error",
            title: "Data Tidak Lengkap",
            data: err,
            onHide: () => modalConfirm.close()
          })
        )
        .finally(() => {
          setSubmitting(false);
        });
    });
  };
  const formSubmitHandler = (values, action) =>
    modalConfirm.trigger({
      alert: action === "DELETE" && true,
      type: action.toLowerCase(),
      onHide: modalConfirm.close,
      onSubmit: () => {
        modalConfirm.loading();

        if (action === "UPDATE") {
          updateKomponenBPJS
            .mutateAsync({ data: formSubmitValueMapper(values), id: values.id })
            .then(() => onHideModalHandler());
        } else if (action === "DELETE") {
          deleteKomponenBPJS.mutate(values.id);
        } else {
          createKomponenBPJS.mutateAsync(formSubmitValueMapper(values)).then(() => onHideModalHandler());
        }
      },
      component: (
        <Formik initialValues={values}>
          <Content action="READ" />
        </Formik>
      )
    });

  return (
    <>
      <ListLayout
        showRightTopButton={access.canCreate() ? true : false}
        showExportExcelButton
        showExportPdfButton
        onExportExcelButtonClick={() => exportKomponenBPJS.mutate("excel")}
        onExportPdfButtonClick={() => exportKomponenBPJS.mutate("pdf")}
        loadingExportButton={exportKomponenBPJS.isLoading}
        loading={getListKomponenBPJS.isFetching}
        error={getListKomponenBPJS.isError}
        errorText={errorFetchingTableMapper(getListKomponenBPJS.error)}
        dataTotal={getListKomponenBPJS?.data?.total}
        dataLength={pagination.dataLength}
        pageCurrent={pagination.pageCurrent}
        pageTotal={getListKomponenBPJS?.data?.last_page}
        onSearchChange={debounce(searchHandler, 1500)}
        onCreateButtonClick={onCreateButtonClickHandler}
        onDataLengthChange={onPaginationDataLengthChange}
        onPaginationChange={onPaginationChange}
        data={getListKomponenBPJS?.data?.data}
        tableHeader={[
          {
            text: "Kode Insentif",
            props: { minWidth: 80 }
          },
          {
            text: "Nama Komponen",
            props: { minWidth: 200 }
          },
          {
            text: "Keterangan",
            props: { minWidth: 200 }
          },
          {
            text: "Aksi",
            props: { width: 50, fixed: true }
          }
        ]}
        tableBody={[
          {
            props: { textCenter: true },
            field: "komponen_bpjs_kode"
          },
          { field: "komponen_bpjs_nama" },
          { field: "keterangan" },
          {
            props: { textCenter: true },
            customField: (data) => (
              <ButtonGroup size="sm">
                {access.canRead() && <ButtonDetail icon noText onClick={() => onDetailButtonClickHandler(data)} />}
                {/* {access.canUpdate() && <ButtonUpdate icon noText onClick={() => onUpdateButtonClickHandler(data)} />}
                {access.canDelete() && (
                  <ButtonDelete icon noText onClick={() => formSubmitHandler(formInitialValues(data), "DELETE")} />
                )} */}
              </ButtonGroup>
            )
          }
        ]}
      />

      {/**
       * Modal Detail:
       * Digunakan untuk melihat detail data
       */}
      <Formik enableReinitialize initialValues={formInitialValues(modal.data)}>
        <ModalDetail
          title={layout.getActivePageName()}
          show={Boolean(modal.show && modal.action === "READ")}
          onHide={onHideModalHandler}
        >
          <Content action="READ" />
        </ModalDetail>
      </Formik>

      {/**
       * Modal Create:
       * Digunakan untuk menambah data
       */}
      <Formik
        enableReinitialize
        initialValues={formInitialValues({})}
        validationSchema={formValidationSchema}
        onSubmit={(values) => formSubmitHandler(values, "CREATE")}
      >
        {(formik) => {
          return (
            <Form>
              <ModalCreate
                title={layout.getActivePageName()}
                show={Boolean(modal.show && modal.action === "CREATE")}
                onHide={() => {
                  onHideModalHandler();
                  formik.resetForm();
                }}
                onSubmit={() => preSubmitHandler(formik)}
              >
                <Content action="CREATE" />
              </ModalCreate>
            </Form>
          );
        }}
      </Formik>

      {/**
       * Modal Update:
       * Modal untuk mengubah data
       */}
      <Formik
        enableReinitialize
        initialValues={formInitialValues(modal.data)}
        validationSchema={formValidationSchema}
        onSubmit={(values) => formSubmitHandler(values, "UPDATE")}
      >
        {(formik) => {
          return (
            <Form>
              <ModalUpdate
                title={layout.getActivePageName()}
                show={Boolean(modal.show && modal.action === "UPDATE")}
                onHide={() => {
                  onHideModalHandler();
                  formik.resetForm();
                }}
                onSubmit={() => preSubmitHandler(formik)}
              >
                <Content action="UPDATE" />
              </ModalUpdate>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
