import * as Yup from "yup";

export const formValidationSchema = Yup.object().shape({
  tglPelanggaran: Yup.string().required("Tanggal pelanggaran wajib diisi").nullable(),
  periodePerencanaanId: Yup.string().required("Periode pelanggaran wajib dipilih").nullable(),
  unitOrganisasiId: Yup.string().required("Unit organisasi wajib dipilih").nullable(),
  pekerjaId: Yup.string().required("Pekerja wajib dipilih").nullable(),
  kasusId: Yup.string().required("Kasus wajib dipilih").nullable(),
  sanksiId: Yup.string().required("Sanksi wajib dipilih").nullable(),
  tglAwalSanksi: Yup.string().required("Tgl. awal sanksi wajib dipilih").nullable(),
  tglAkhirSanksi: Yup.string().required("Tgl. akhir sanksi wajib dipilih").nullable(),
  tindakLanjut: Yup.string().required("Tindak lanjut wajib diisi").nullable(),
  presenUpahPokok: Yup.string().required("Persentase penerimaan upah pokok wajib diisi").nullable(),
  statusPelanggaran: Yup.string().required("Status pelanggaran wajib dipilih").nullable(),
  keteranganPelanggaran: Yup.string().required("Keterangan pelanggaran wajib diisi").nullable()
});
