import React from "react";
import { useFormikContext } from "formik";
import { Input, InfoItemVertical, TextArea, InputNumber } from "components";

export const Content = ({ action }) => {
  const { values, errors, touched, handleChange, setFieldValue } = useFormikContext();

  if (action === "READ") {
    return (
      <>
        <InfoItemVertical label="Kode" text={values.kode} />
        <InfoItemVertical label="Jenis Cuti" text={values.namaJenisCuti} />
        <InfoItemVertical label="Maksimal Cuti (Hari)" text={values.jumlahCuti} />
        <InfoItemVertical label="Keterangan" text={values.keterangan} />
      </>
    );
  }

  return (
    <>
      <Input
        label="Kode"
        name="kode"
        placeholder="Masukkan kode"
        value={values.kode ?? ""}
        onChange={handleChange}
        error={Boolean(errors.kode && touched.kode)}
        errorText={Boolean(errors.kode && touched.kode) && errors.kode}
      />
      <Input
        label="Jenis Cuti"
        name="namaJenisCuti"
        placeholder="Masukkan nama jenis cuti"
        value={values.namaJenisCuti ?? ""}
        onChange={handleChange}
        error={Boolean(errors.namaJenisCuti && touched.namaJenisCuti)}
        errorText={Boolean(errors.namaJenisCuti && touched.namaJenisCuti) && errors.namaJenisCuti}
      />
      <InputNumber
        label="Maksimal Cuti (hari)"
        name="jumlahCuti"
        placeholder="Masukkan jumlah cuti"
        value={values?.jumlahCuti ?? ""}
        onChange={(val) => setFieldValue("jumlahCuti", val)}
        error={Boolean(errors.jumlahCuti && touched.jumlahCuti)}
        errorText={Boolean(errors.jumlahCuti && touched.jumlahCuti) && errors.jumlahCuti}
      />
      <TextArea
        label="Keterangan"
        name="keterangan"
        placeholder="Masukkan keterangan"
        value={values.keterangan ?? ""}
        onChange={handleChange}
        error={Boolean(errors.keterangan && touched.keterangan)}
        errorText={Boolean(errors.keterangan && touched.keterangan) && errors.keterangan}
      />
    </>
  );
};
