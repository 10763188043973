import React from "react";
import { useQuery } from "react-query";
import { useFormikContext } from "formik";
import { Input, Select, InfoItemVertical } from "components";
import { errorFetchingSelectMapper } from "utilities";
import HirarkiUnitOrganisasiApi from "../__HirarkiUnitOrganisasiApi__";

export const Content = ({ action }) => {
  const { values, errors, touched, handleChange, setFieldValue } = useFormikContext();
  const getLevel = useQuery(["level", "dropdown"], () => HirarkiUnitOrganisasiApi.getLevel());

  if (action === "READ") {
    return (
      <>
        <InfoItemVertical label="Nama Hirarki Unit Organisasi" text={values.nama} />
        <InfoItemVertical label="Level Hirarki Unit Organisasi" text={values.level} />
      </>
    );
  }

  return (
    <>
      <Input
        label="Nama Hirarki Unit Organisasi"
        name="nama"
        placeholder="Masukkan nama hirarki unit organisasi"
        value={values.nama}
        onChange={handleChange}
        error={Boolean(errors.nama && touched.nama)}
        errorText={Boolean(errors.nama && touched.nama) && errors.nama}
      />
      <Select
        label="Level Hirarki Unit Organisasi"
        placeholder="Pilih level hirarki unit organisasi"
        defaultValue={getLevel?.data?.find((item) => item.value === values.level)}
        options={getLevel?.data ?? []}
        onChange={(val) => setFieldValue("level", val.value)}
        loading={getLevel.isFetching}
        error={Boolean(errors.level && touched.level)}
        errorText={Boolean(errors.level && touched.level) && errors.level}
        errorFetch={getLevel.isError}
        errorFetchText={errorFetchingSelectMapper(getLevel.error)}
      />
    </>
  );
};
