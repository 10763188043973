import React, { useState } from "react";
import * as Yup from "yup";
import {
    DatePicker,
    Input,
    InputNumber,
    Select,
    TableList,
    CheckboxWrapper,
    Checkbox,
    ButtonCreate,
    TextArea,
    ModalCreate,
    numberConvert,
    InfoItemVertical,
    dateConvert
} from "components";
import { useLayout, useModalConfirm, } from "hooks";
import { useFormikContext, Formik, Form } from "formik";
import { useQuery, useMutation } from "react-query";
import { Col, Row, Card } from "react-bootstrap";
import { errorSubmitMapper } from "utilities";
import InputTimeSheetConcretePumpApi from "../__InputTimeSheetConcretePumpApi__";

const ContentModal = ({ action, status }) => {
    const { values, errors, touched, handleChange } = useFormikContext();
    if (status === "CUSTOMER") {
        return (
            <>
                <Input
                    name="nama"
                    placeholder="Masukkan nama customer"
                    label="Nama Customer"
                    value={values?.nama}
                    onChange={handleChange}
                    error={Boolean(errors.nama && touched.nama)}
                    errorText={Boolean(errors.nama && touched.nama) && errors.nama}
                />
            </>
        )
    }

    return (
        <>
            <Input
                name="nama"
                placeholder="Masukkan nama Proyek"
                label="Nama Proyek"
                value={values?.nama}
                onChange={handleChange}
                error={Boolean(errors.nama && touched.nama)}
                errorText={Boolean(errors.nama && touched.nama) && errors.nama}
            />
        </>
    )
}

const TabelDataPekerja = ({ action }) => {
    const { values, setValues } = useFormikContext();

    const setNewValues = ({ index, propname, val }) => {
        const detailTemp = [...values?.detail];
        const updatedDetail = detailTemp[index];
        updatedDetail[propname] = val;

        setValues({ ...values, detail: detailTemp });
    };

    return (
        <>
            <div>Data Crew</div>
            <TableList
                maxHeight={550}
                tableHeader={[
                    { text: "Pekerja" },
                    { text: "Jabatan" },
                    {
                        text: "Aksi",
                        props: {
                            fixed: true
                        }
                    }
                ]}
                tableBody={[

                    {
                        field: "pekerjaNama"
                    },
                    {
                        field: "jabatanNama"
                    },
                    {
                        props: { textCenter: true, width: 50 },
                        customField: (val, index) => (
                            <div className="d-flex justify-content-center">
                                <CheckboxWrapper>
                                    <Checkbox
                                        disabled={Boolean(action === "READ")}
                                        name={index + val?.checked}
                                        checked={val?.checked === true}
                                        onChange={() =>
                                            val?.checked === true
                                                ? setNewValues({ index: index, propname: "checked", val: false })
                                                : setNewValues({ index: index, propname: "checked", val: true })
                                        }
                                    />
                                </CheckboxWrapper>
                            </div>
                        )
                    },
                ]}
                data={values?.detail}
            />
        </>
    );
};

const SectionInput = ({ action, onCreateButtonClickHandler, getCustomer, getProyek }) => {
    const { values, errors, touched, setValues, handleChange } = useFormikContext();
    const number = numberConvert();
    const date = dateConvert();

    const [warning, setWarning] = useState({
        jamMulai: false,
        jamSelesai: false
    });

    // const getCustomer = useQuery(["customer", "dropdown"], () => InputTimeSheetConcretePumpApi.getCustomer());
    // const getProyek = useQuery(["proyek", "dropdown", values?.customerId], () => InputTimeSheetConcretePumpApi.getProyek({
    //     customer_id: values?.customerId
    // }));

    const diffMinutes = (a, b) => {
        let diff = (b.getTime() - a.getTime()) / 1000
        diff /= 60;
        let hasil = Math.abs(Math.round(diff))
        return hasil;
    }

    const toHoursAndMinutes = (totalMinutes) => {
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        const hasil = hours + " jam " + minutes + " menit"
        return hasil;
    }

    if (action === "READ") {
        return (
            <Row>
                <Col md={12} lg={6}>
                    <Card style={{ height: "100%" }}>
                        <Card.Body>
                            <InfoItemVertical label="Periode Penggajian" text={values?.periodeTahun} />
                            <Row>
                                <Col>
                                    <InfoItemVertical label="Tgl. Nota" text={date.getSlashDMY(new Date(values?.tglNota))} />
                                </Col>
                                <Col>
                                    <InfoItemVertical label="No. Nota" text={values?.noNota} />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <InfoItemVertical label="Pemakai" text={values?.customerNama} />
                                </Col>
                                <Col>
                                    <InfoItemVertical label="Proyek" text={values?.proyekNama} />
                                </Col>
                            </Row>
                            <InfoItemVertical label="Alamat Proyek" text={values?.proyekAlamat} />
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={12} lg={3}>
                    <Card>
                        <Card.Body>
                            <InfoItemVertical label="Masuk Jam Proyek" text={values?.jamMasukFormat} />
                            <InfoItemVertical label="Mulai dipakai Jam" text={values?.jamMulaiFormat} />
                            <InfoItemVertical label="Selesai Jam" text={values?.jamSelesaiFormat} />
                            <InfoItemVertical label="Keluar Proyek Jam" text={values?.jamKeluarFormat} />
                            <InfoItemVertical label="Total Jam Pemakaian" text={values?.totalJam ? toHoursAndMinutes(values?.totalJam) : ""} />
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={12} lg={3}>
                    <Card>
                        <Card.Body>
                            <InfoItemVertical label="Sambungan Pipa (m)" text={number.getWithComa(values?.sambunganPipa, { minimumFractionDigits: 2 })} />
                            <InfoItemVertical label="Volume (m3)" text={number.getWithComa(values?.volume, { minimumFractionDigits: 2 })} />
                            <InfoItemVertical label="Km Awal" text={number.getWithComa(values?.kmAwal, { minimumFractionDigits: 2 })} />
                            <InfoItemVertical label="Km Akhir" text={number.getWithComa(values?.kmAkhir, { minimumFractionDigits: 2 })} />
                            <InfoItemVertical label="Total Km" text={number.getWithComa(values?.totalKm, { minimumFractionDigits: 2 })} />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        )
    }

    return (
        <>
            <Row>
                <Col md={12} lg={6}>
                    <Input
                        name="periodeTahun"
                        label="Periode Penggajian"
                        placeholder="Masukkan periode penggajian"
                        value={values?.periodeTahun}
                        onChange={handleChange}
                        error={Boolean(errors.periodeTahun && touched.periodeTahun)}
                        errorText={Boolean(errors.periodeTahun && touched.periodeTahun) && errors.periodeTahun}
                    />
                    <Row>
                        <Col>
                            <DatePicker
                                name="tglNota"
                                placeholderText="Pilih tanggal nota"
                                label="Tanggal"
                                placeholder="Masukkan tanggal"
                                selected={values?.tglNota}
                                onChange={(val) => setValues({
                                    ...values,
                                    tglNota: val
                                })}
                                error={Boolean(errors.tglNota && touched.tglNota)}
                                errorText={Boolean(errors.tglNota && touched.tglNota) && errors.tglNota}
                            />
                        </Col>
                        <Col>
                            <Input
                                name="noNota"
                                label="No. Nota"
                                placeholder="Masukkan no nota"
                                value={values?.noNota}
                                onChange={handleChange}
                                error={Boolean(errors.noNota && touched.noNota)}
                                errorText={Boolean(errors.noNota && touched.noNota) && errors.noNota}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="d-flex gap-2">
                                <div style={{ width: "100%", minWidth: "100px" }}>
                                    <Select
                                        label="Pemakai"
                                        options={getCustomer?.data ?? []}
                                        placeholder="Pilih salah satu..."
                                        defaultValue={values?.customerId ? getCustomer?.data?.find((item) => item.value === values?.customerId) : null}
                                        onChange={(val) => {
                                            setValues({
                                                ...values,
                                                customerId: val.value,
                                                customerNama: val.label,
                                            });
                                        }}
                                        loading={getCustomer?.isFetching}
                                        errorFetch={getCustomer.isError}
                                        error={Boolean(errors.customerId && touched.customerId)}
                                        errorText={Boolean(errors.customerId && touched.customerId) && errors.customerId}
                                    />
                                </div>
                                <div className="mt-4">
                                    <ButtonCreate tooltip={false} icon noText onClick={() => onCreateButtonClickHandler("CUSTOMER")} />
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div className="d-flex gap-2">
                                <div style={{ width: "100%", minWidth: "100px" }}>
                                    <Select
                                        disable={Boolean(!values?.customerId)}
                                        label="Proyek"
                                        options={getProyek?.data ?? []}
                                        placeholder="Pilih salah satu..."
                                        defaultValue={values?.proyekId ? getProyek?.data?.find((item) => item.value === values?.proyekId) : null}
                                        onChange={(val) => {
                                            setValues({
                                                ...values,
                                                proyekId: val.value,
                                                proyekNama: val.label,
                                                proyekAlamat: val.alamat
                                            });
                                        }}
                                        loading={getProyek?.isFetching}
                                        errorFetch={getProyek.isError}
                                        error={Boolean(errors.proyekId && touched.proyekId)}
                                        errorText={Boolean(errors.proyekId && touched.proyekId) && errors.proyekId}
                                    />
                                </div>
                                <div className="mt-4">
                                    <ButtonCreate disabled={Boolean(!values?.customerId)} tooltip={false} icon noText onClick={() => onCreateButtonClickHandler("PROYEK")} />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <TextArea
                        disabled
                        name="proyekAlamat"
                        label="Alamat"
                        placeholder="Otomatis terisi"
                        value={values?.proyekAlamat}
                        onChange={handleChange}
                        error={Boolean(errors.proyekAlamat && touched.proyekAlamat)}
                        errorText={Boolean(errors.proyekAlamat && touched.proyekAlamat) && errors.proyekAlamat}
                    />
                </Col>
                <Col md={12} lg={3}>
                    <DatePicker
                        name="jamMasuk"
                        label="Masuk jam proyek"
                        placeholderText="Masukkan jam masuk proyek"
                        dateFormat="dd-MM-yyyy hh:mm"
                        showTimeInput
                        showTimeSelect
                        value={values?.jamMasukFormat}
                        selected={values?.jamMasukFormat}
                        maxDate={values?.jamKeluarFormat ?? ""}
                        onChangeDefault={(val) => {
                            setValues({
                                ...values,
                                jamMasuk: date.getDashYMDWithTime(new Date(val)),
                                jamMasukFormat: date.getDashYMDWithTime(new Date(val)),
                                jamMulai: date.getDashYMDWithTime(new Date(val)),
                                jamMulaiFormat: date.getDashYMDWithTime(new Date(val)),
                                totalJam: diffMinutes(
                                    new Date(values?.jamMulai),
                                    new Date(values?.jamSelesai)
                                )
                            })
                        }
                        }
                        error={Boolean(errors.jamMasuk && touched.jamMasuk)}
                        errorText={Boolean(errors.jamMasuk && touched.jamMasuk) && errors.jamMasuk}
                    />
                    <DatePicker
                        name="jamMulai"
                        label="Jam mulai"
                        placeholderText="Masukkan jam mulai"
                        dateFormat="dd-MM-yyyy hh:mm"
                        maxDate={values?.jamKeluarFormat ?? ""}
                        showTimeInput
                        showTimeSelect
                        value={values?.jamMulaiFormat}
                        selected={values?.jamMulaiFormat}
                        onChangeDefault={(val) => {
                            setValues({
                                ...values,
                                jamMulai: date.getDashYMDWithTime(new Date(val)),
                                jamMulaiFormat: date.getDashYMDWithTime(new Date(val)),
                                totalJam: diffMinutes(
                                    new Date(values?.val),
                                    new Date(values?.jamSelesai)
                                )
                            })
                        }
                        }
                        error={Boolean(errors.jamMulai && touched.jamMulai)}
                        errorText={Boolean(errors.jamMulai && touched.jamMulai) && errors.jamMulai}
                    />

                    <DatePicker
                        name="jamSelesai"
                        label="Jam Selesai"
                        placeholderText="Masukkan jam selesai"
                        dateFormat="dd-MM-yyyy hh:mm"
                        showTimeInput
                        showTimeSelect
                        minDate={values?.jamMasukFormat ?? ""}
                        value={values?.jamSelesaiFormat}
                        selected={values?.jamSelesaiFormat}
                        onChangeDefault={(val) => {
                            setValues({
                                ...values,
                                jamSelesai: date.getDashYMDWithTime(new Date(val)),
                                jamSelesaiFormat: date.getDashYMDWithTime(new Date(val)),
                                jamKeluar: date.getDashYMDWithTime(new Date(val)),
                                jamKeluarFormat: date.getDashYMDWithTime(new Date(val)),
                                totalJam: diffMinutes(
                                    new Date(values?.jamMulai),
                                    new Date(val)
                                )
                            })
                        }
                        }
                        error={Boolean(errors.jamSelesai && touched.jamSelesai)}
                        errorText={Boolean(errors.jamSelesai && touched.jamSelesai) && errors.jamSelesai}
                    />
                    <DatePicker
                        name="jamKeluar"
                        label="Jam Keluar"
                        placeholderText="Masukkan jam keluar"
                        dateFormat="dd-MM-yyyy hh:mm"
                        minDate={values?.jamMasukFormat ?? ""}
                        showTimeInput
                        showTimeSelect
                        value={values?.jamKeluarFormat}
                        selected={values?.jamKeluarFormat}
                        onChangeDefault={(val) => {
                            setValues({
                                ...values,
                                jamKeluar: date.getDashYMDWithTime(new Date(val)),
                                jamKeluarFormat: date.getDashYMDWithTime(new Date(val)),
                            })
                        }
                        }
                        error={Boolean(errors.jamKeluar && touched.jamKeluar)}
                        errorText={Boolean(errors.jamKeluar && touched.jamKeluar) && errors.jamKeluar}
                    />
                    <Input
                        disabled
                        name="totalJam"
                        placeholder="Otomatis terisi"
                        label="Total Jam Pemakaian"
                        value={values?.totalJam ? toHoursAndMinutes(values?.totalJam) : ""}
                        onChange={handleChange}
                        error={Boolean(errors.totalJam && touched.totalJam)}
                        errorText={Boolean(errors.totalJam && touched.totalJam) && errors.totalJam}
                    />
                    {/* <Input
                        name="jamMasuk"
                        type="time"
                        label="Masuk Jam Proyek"
                        value={values?.jamMasukFormat}
                        onChange={(val) => setValues({
                            ...values,
                            jamMasuk: val.target.value,
                            jamMasukFormat: val.target.value
                        })}
                        error={Boolean(errors.jamMasuk && touched.jamMasuk)}
                        errorText={Boolean(errors.jamMasuk && touched.jamMasuk) && errors.jamMasuk}
                    /> */}
                    {/* <Input
                        name="jamMulai"
                        type="time"
                        label="Mulai dipakai jam"
                        value={values?.jamMulaiFormat}
                        onChange={(val) => {
                            const x = val.target.value
                            if (values?.jamSelesai && x <= values?.jamSelesai) {
                                setValues({
                                    ...values,
                                    jamMulai: x,
                                    jamMulaiFormat: x,
                                    totalJam: diffMinutes(
                                        new Date(`${values?.tglNota} ${x}`),
                                        new Date(`${values?.tglNota} ${values?.jamSelesai}`)
                                    )

                                })
                                setWarning({
                                    ...warning,
                                    jamMulai: false
                                })
                            } else if (!values?.jamSelesai) {
                                setValues({
                                    ...values,
                                    jamMulai: x,
                                    jamMulaiFormat: x,
                                })
                                setWarning({
                                    ...warning,
                                    jamMulai: false
                                })
                            } else {
                                setWarning({
                                    ...warning,
                                    jamMulai: true
                                })

                            }
                        }}
                        error={Boolean(errors.jamMulai && touched.jamMulai)}
                        errorText={Boolean(errors.jamMulai && touched.jamMulai) && errors.jamMulai}
                    />
                    {warning?.jamMulai === true ? (
                        <small className="text-danger" style={{ padding: 0 }}>
                            Jam mulai tidak boleh melebihi jam selesai
                        </small>
                    ) : (<></>)}
                    <Input
                        name="jamSelesai"
                        type="time"
                        label="Selesai jam"
                        value={values?.jamSelesaiFormat}
                        onChange={(val) => {
                            const x = val?.target?.value
                            if (values?.jamMulai && x >= values?.jamMulai) {
                                setValues({
                                    ...values,
                                    jamSelesai: x,
                                    jamSelesaiFormat: x,
                                    totalJam: diffMinutes(
                                        new Date(`${values?.tglNota} ${values?.jamMulai}`),
                                        new Date(`${values?.tglNota} ${x}`)
                                    )

                                })
                                setWarning({
                                    ...warning,
                                    jamSelesai: false
                                })
                            } else (
                                setWarning({
                                    ...warning,
                                    jamSelesai: true
                                })
                            )
                        }}
                        error={Boolean(errors.jamSelesai && touched.jamSelesai)}
                        errorText={Boolean(errors.jamSelesai && touched.jamSelesai) && errors.jamSelesai}
                    />
                    {warning?.jamSelesai === true ? (
                        <small className="text-danger" style={{ margin: 0 }}>
                            Jam selesai tidak boleh kurang dari jam mulai
                        </small>
                    ) : (<></>)}
                    <Input
                        name="jamKeluar"
                        type="time"
                        label="Keluar proyek jam"
                        value={values?.jamKeluarFormat}
                        onChange={(val) => setValues({
                            ...values,
                            jamKeluar: val.target.value,
                            jamKeluarFormat: val.target.value
                        })}
                        error={Boolean(errors.jamKeluar && touched.jamKeluar)}
                        errorText={Boolean(errors.jamKeluar && touched.jamKeluar) && errors.jamKeluar}
                    /> */}
                </Col>
                <Col md={12} lg={3}>
                    <InputNumber
                        name="sambuganPipa"
                        placeholder="Masukkan panjang sambungan pipa"
                        label="Sambungan Pipa (m)"
                        value={number.getWithComa(values?.sambunganPipa, { minimumFractionDigits: 0 })}
                        onChange={(val) => setValues({
                            ...values,
                            sambunganPipa: val
                        })}
                        error={Boolean(errors.sambunganPipa && touched.sambunganPipa)}
                        errorText={Boolean(errors.sambunganPipa && touched.sambunganPipa) && errors.sambunganPipa}
                    />
                    <InputNumber
                        name="volume"
                        placeholder="Masukkan volume"
                        label="Volume (m3)"
                        value={number.getWithComa(values?.volume, { minimumFractionDigits: 0 })}
                        onChange={(val) => setValues({
                            ...values,
                            volume: val
                        })}
                        error={Boolean(errors.volume && touched.volume)}
                        errorText={Boolean(errors.volume && touched.volume) && errors.volume}
                    />
                    <InputNumber
                        name="kmAwal"
                        label="Km Awal"
                        placeholder="Masukkan Km awal"
                        value={number.getWithComa(values?.kmAwal, { minimumFractionDigits: 0 })}
                        onChange={(val) => setValues({
                            ...values,
                            kmAwal: val,
                            totalKm: values?.kmAkhir !== undefined || values?.kmAkhir !== 0 ? (values?.kmAkhir - val) : val,
                        })}
                        error={Boolean(errors.kmAwal && touched.kmAwal)}
                        errorText={Boolean(errors.kmAwal && touched.kmAwal) && errors.kmAwal}
                    />
                    <InputNumber
                        name="kmAkhir"
                        label="Km Akhir"
                        placeholder="Masukkan Km akhir"
                        value={number.getWithComa(values?.kmAkhir, { minimumFractionDigits: 0 })}
                        onChange={(val) => setValues({
                            ...values,
                            kmAkhir: val,
                            totalKm: values?.kmAwal !== undefined || values?.kmAwal !== 0 ? (val - values?.kmAwal) : val
                        })}
                        error={Boolean(errors.kmAkhir && touched.kmAkhir)}
                        errorText={Boolean(errors.kmAkhir && touched.kmAkhir) && errors.kmAkhir}
                    />
                    <Input
                        disabled
                        placeholder="Otomatis terisi"
                        name="totalKm"
                        label="Total Km"
                        value={values?.totalKm !== undefined && values?.totalKm >= 0 ? number.getWithComa(values?.totalKm, { minimumFractionDigits: 0 }) : 0}
                        onChange={handleChange}
                        error={Boolean(errors.totalKm && touched.totalKm)}
                        errorText={Boolean(errors.totalKm && touched.totalKm) && errors.totalKm}
                    />
                </Col>
            </Row>
        </>
    );
}

export const Content = ({ action }) => {
    const parentFormik = useFormikContext();
    const modalConfirm = useModalConfirm();
    const layout = useLayout();
    const [modal, setModal] = useState({
        show: false,
        action: "",
        status: "",
        data: {}
    });

    const getCustomer = useQuery(["customer", "dropdown"], () => InputTimeSheetConcretePumpApi.getCustomer());
    const getProyek = useQuery(["proyek", "dropdown", parentFormik?.values?.customerId], () => InputTimeSheetConcretePumpApi.getProyek({
        customer_id: parentFormik?.values?.customerId
    }));

    const createCustomer = useMutation((data) => InputTimeSheetConcretePumpApi.createCustomer(data), {
        onSuccess: () => {
            modalConfirm.infoSuccess({ typeInfo: "create" });
            getCustomer?.refetch()
        },
        onError: (err) => modalConfirm.infoError({ typeInfo: "create", ...errorSubmitMapper(err) })
    });
    const createProyek = useMutation((data) => InputTimeSheetConcretePumpApi.createProyek(data), {
        onSuccess: () => {
            modalConfirm.infoSuccess({ typeInfo: "create" });
            getProyek?.refetch();
        },
        onError: (err) => modalConfirm.infoError({ typeInfo: "create", ...errorSubmitMapper(err) })
    });

    const onHideModalHandler = () => setModal({ show: false, action: "", status: "", data: {} });
    const onCreateButtonClickHandler = (status) => setModal({ show: true, action: "CREATE", status: status, data: {} });

    const submitValidationHandler = (errors) =>
        new Promise((resolve, reject) => {
            const getError = Object.values(errors);

            if (getError.length > 0) {
                reject(getError);
            } else {
                resolve();
            }
        });

    const preSubmitHandler = (formik) => {
        const { values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting } = formik;

        setSubmitting(true);
        validateForm().then(async (err) => {
            setErrors(err);
            setTouched(err);

            await submitValidationHandler(err, values)
                .then(() => handleSubmit())
                .catch((err) =>
                    modalConfirm.trigger({
                        type: "error",
                        title: "Data Tidak Lengkap",
                        data: err,
                        onHide: () => modalConfirm.close()
                    })
                )
                .finally(() => {
                    setSubmitting(false);
                });
        });
    };

    const formSubmitHandler = (values, action, status) =>
        modalConfirm.trigger({
            type: action.toLowerCase(),
            onHide: modalConfirm.close,
            onSubmit: () => {
                modalConfirm.loading();
                if (status === "CUSTOMER") {
                    createCustomer.mutateAsync({ customer_nama: values?.nama }).then(() => onHideModalHandler());
                } else if (status === "PROYEK") {
                    createProyek.mutateAsync({ proyek_nama: values?.nama, customer_id: parentFormik?.values?.customerId }).then(() => onHideModalHandler());
                }
            }
        });

    return (
        <>
            <Card className="mb-4">
                <Card.Body>
                    <div className="mb-2">
                        <SectionInput action={action} onCreateButtonClickHandler={onCreateButtonClickHandler} getCustomer={getCustomer} getProyek={getProyek} />
                    </div>
                    <TabelDataPekerja action={action} />
                </Card.Body>
            </Card>


            {/**
         * Modal Create:
         * Digunakan untuk menambah data
         */}
            <Formik
                enableReinitialize
                initialValues={{
                    nama: ""
                }}
                validationSchema={Yup.object().shape({
                    nama: Yup.string().required("Nama wajib diisi"),
                })}
                onSubmit={(values) => formSubmitHandler(values, "CREATE", modal.status)}
            >
                {(formik) => {
                    return (
                        <Form>
                            <ModalCreate
                                title={layout.getActivePageName()}
                                show={Boolean(modal.show && modal.action === "CREATE")}
                                onHide={() => {
                                    onHideModalHandler();
                                    formik.resetForm();
                                }}
                                onSubmit={() => preSubmitHandler(formik)}
                            >
                                <ContentModal action="CREATE" status={modal.status} />
                            </ModalCreate>
                        </Form>
                    );
                }}
            </Formik>
        </>
    )
};
