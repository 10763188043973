export const formInitialValues = (data = {}) => ({
  pekerjaPyrollBulananId: data?.pekerja_payroll_bulanan_id ?? "",
  komponenPayroll: data?.komponen_payroll ?? "",

  nik: data?.nip ?? "",
  pekerjaId: data?.pekerja_id ?? "",
  pekerjaNama: data?.pekerja_nama ?? "",
  statusPekerja: data?.status_pekerja ?? "",
  unitOrganisasiId: data?.master_unit_organisasi_id ?? "",
  unitOrganisasiNama: data?.master_unit_organisasi_nama ?? "",
  jabatanId: data?.master_jabatan_id ?? "",
  jabatanNama: data?.master_jabatan_nama ?? "",

  tglAwal: data?.tgl_bulan_awal ?? "",
  tglAkhir: data?.tgl_bulan_akhir ?? "",
  tglSetup: data?.tgl_setup ?? "",

  statusPajak: data?.status_pajak ?? "",
  statusPenggajian: data?.status_penggajian ?? "",
  totalGaji: data?.total_gaji ?? "",
  totalPenyesuaian: data?.total_penyesuaian ?? "",
  gajiKotor: data?.gaji_kotor ?? "",
  totalPotongan: data?.total_pemotongan ?? "",
  totalPembulatan: data?.total_pembulatan ?? "",

  detailPenghasilan:
    data?.komponen
      ?.filter((obj) => {
        return obj.status === 1;
      })
      .map((val) => ({
        nama: val?.nama_komponen ?? "",
        status: val?.status ?? "",
        kode: val?.kode ?? "",
        nominal: val?.nominal ?? ""
      })) ?? [],

  detailPotongan:
    data?.komponen
      ?.filter((obj) => {
        return obj.status === 2;
      })
      .map((val) => ({
        nama: val?.nama_komponen ?? "",
        status: val?.status ?? "",
        kode: val?.kode ?? "",
        nominal: val?.nominal ?? ""
      })) ?? [],

  detailPenyesuaian:
    data?.komponen
      ?.filter((obj) => {
        return obj.status === 3;
      })
      .map((val) => ({
        nama: val?.nama_komponen ?? "",
        status: val?.status ?? "",
        kode: val?.kode ?? "",
        nominal: val?.nominal ?? ""
      })) ?? []
});
