import * as Yup from "yup";

/**
 * formValidationSchema:
 * type: create | update
 */
export const formValidationSchema = Yup.object().shape({
  jenis_jabatan_id: Yup.string().required("Jenis jabatan tidak boleh kosong"),
  jabatan_id: Yup.string().required("Jabatan tidak boleh kosong"),
  // unit_organisasi_id: Yup.string().required("Unit organisasi tidak boleh kosong"),
  grade_id: Yup.string().required("Grade tidak boleh kosong"),
  pangkat_id: Yup.string().required("Pangkat tidak boleh kosong"),
  f1_id: Yup.string().required("Kompetensi teknis tidak boleh kosong"),
  f2_id: Yup.string().required("Kompetensi manajerial tidak boleh kosong"),
  f3_id: Yup.string().required("Kompetensi komunikasi tidak boleh kosong"),
  f4_id: Yup.string().required("Kebebasan berfikir tidak boleh kosong"),
  f5_id: Yup.string().required("Tantangan berfikir tidak boleh kosong"),
  f6_id: Yup.string().required("Wewenang tidak boleh kosong"),
  f7_id: Yup.string().required("Nilai kelola harta tidak boleh kosong"),
  f8_id: Yup.string().required("Dampak tidak boleh kosong"),
  a1_id: Yup.string().required("Adjustment 1 tidak boleh kosong"),
  a2_id: Yup.string().required("Adjustment 2 tidak boleh kosong"),
  a3_id: Yup.string().required("Adjustment 3 tidak boleh kosong")
});
