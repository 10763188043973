import * as Yup from "yup";

export const formValidationSchema = Yup.object().shape({
  periodeGajianId: Yup.string().required("Periode gajian wajib dipilih"),
  kodePPh21: Yup.string().required("Kode tarif PPh 21 wajib diisi"),
  penghasilanTerendah: Yup.string().required("Penghasilan terendah wajib diisi"),
  penghasilanTertinggi: Yup.string().required("Penghasilan tertinggi wajib diisi"),
  persentasePPh21: Yup.string().required("Persentase PPh 21 wajib diisi"),
  status: Yup.string().required("Status wajib diisi"),
  keterangan: Yup.string().required("Keterangan wajib diisi")
});
