import {
  ButtonCreate, ButtonDelete, ButtonUpdate, dateConvert, DatePicker, InfoItemVertical, Input, InputNumber, ModalUpdate, rupiahConvert, Select, TableList, TextArea
} from "components";
import { Form, Formik, useFormikContext } from "formik";
import { useModalConfirm } from "hooks";
import { useState } from "react";
import { ButtonGroup, Card } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { errorFetchingSelectMapper, errorSubmitMapper } from "utilities";
import KoreksiKerjaApi from "../__KoreksiKerjaApi__";
import { formSubmitMapper, formValidationSchema } from "../__KoreksiKerjaUtils__";

const FormSearch = () => {
  const {
    values,
    errors,
    touched,
    setValues,
    validateForm,
    setTouched,
    setErrors,
    handleSubmit,
    setSubmitting
  } = useFormikContext();
  const modalConfirm = useModalConfirm();
  // const date = dateConvert();

  const getBulan = useQuery(["bulan", "dropdown"], () => KoreksiKerjaApi.getBulan());

  const getUnitOrganisasi = useQuery(["unit-organisasi", "dropdown"], () => KoreksiKerjaApi.getUnitOrganisasi());
  const getPekerja = useQuery(
    ["pekerja", "dropdown", values?.idUnitOrganisasi],
    () => KoreksiKerjaApi.getPekerja(values?.idUnitOrganisasi),
    {
      enabled: values?.idUnitOrganisasi !== undefined
    }
  );

  const submitValidationHandler = (errors, values) =>
    new Promise((resolve, reject) => {
      const getError = Object.values(errors);

      if (getError.length > 0) {
        reject(getError);
      } else {
        resolve();
      }
    });

  const preSubmitHandler = (values, setValues, validateForm, setTouched, setErrors, handleSubmit, setSubmitting) => {
    setSubmitting(true);
    validateForm().then(async (err) => {
      setErrors(err);
      setTouched(err);
      await submitValidationHandler(err, values)
        .then(() => handleSubmit())
        .catch((err) =>
          modalConfirm.trigger({
            type: "error",
            title: "Data Tidak Lengkap",
            data: err,
            onHide: () => modalConfirm.close()
          })
        )
        .finally(() => {
          setSubmitting(false);
        });
    });
  };

  return (
    <>
      <Card>
        <Card.Header>Setup Data</Card.Header>
        <Card.Body>
          <small className="text-danger">Jika mencari data yang sudah tersimpan, </small>
          <small className="text-danger">
            harap memastikan semua isian setup data sama persis saat penyimpanan pertama
          </small>

          <Input
            label="Periode"
            placeholder="Masukkan periode"
            value={values?.idPeriode}
            onChange={(val) =>
              setValues({
                ...values,
                idPeriode: val.target.value,
                namaPeriode: val.target.value
              })
            }
          />
          <Select
            label="Unit Organisasi"
            placeholder="Pilih unit organisasi"
            defaultValue={getUnitOrganisasi?.data?.find((item) => item?.value === values?.idUnitOrganisasi)}
            options={getUnitOrganisasi?.data ?? []}
            onChange={(val) => {
              setValues({
                ...values,
                idUnitOrganisasi: val.value,
                namaUnitOrganisasi: val.label,
                idPekerja: undefined,
                namaPekerja: undefined,
                idJabatan: undefined,
                namaJabatan: undefined
              });
            }}
            loading={getUnitOrganisasi.isFetching}
            errorFetch={getUnitOrganisasi.isError}
            errorFetchText={errorFetchingSelectMapper(getUnitOrganisasi.error)}
            error={Boolean(errors.idUnitOrganisasi && touched.idUnitOrganisasi)}
            errorText={Boolean(errors.idUnitOrganisasi && touched.idUnitOrganisasi) && errors.idUnitOrganisasi}
          />
          <Select
            disable={values?.idUnitOrganisasi === undefined}
            label="Pekerja"
            placeholder="Pilih pekerja"
            defaultValue={getPekerja?.data?.find((item) => item?.value === values?.idPekerja)}
            options={getPekerja?.data ?? []}
            onChange={(val) => {
              setValues({
                ...values,
                idPekerja: val.value,
                namaPekerja: val.label,
                idJabatan: val.idJabatan,
                namaJabatan: val.namaJabatan
              });
            }}
            loading={getPekerja.isFetching}
            errorFetch={getPekerja.isError}
            errorFetchText={errorFetchingSelectMapper(getPekerja.error)}
            error={Boolean(errors.idPekerja && touched.idPekerja)}
            errorText={Boolean(errors.idPekerja && touched.idPekerja) && errors.idPekerja}
          />
          <Input disabled label="Jabatan" value={values?.namaJabatan} />
          <Select
            label="Bulan Penggajian"
            options={getBulan?.data ?? []}
            placeholder="Pilih salah satu..."
            defaultValue={values?.idBulan ? getBulan?.data?.find((item) => item.value === values?.idBulan) : null}
            onChange={(val) => {
              const tglNewStart = val.value <= 1 ?
                `${values?.namaPeriode - 1}-12-26`
                : `${values?.namaPeriode}-${val?.value < 10 ? `0${val?.value - 1}` : val?.value - 1}-26`;

              const tglNewEnd = `${values?.namaPeriode}-${val?.value < 10 ? `0${val?.value}` : val?.value}-25`;

              setValues({
                ...values,
                idBulan: val.value,
                namaBulan: val.label,
                tglAwal: tglNewStart,
                tglAkhir: tglNewEnd
              });
            }}
            loading={getBulan?.isFetching}
            errorFetch={getBulan.isError}
            error={Boolean(errors.idBulan && touched.idBulan)}
            errorText={Boolean(errors.idBulan && touched.idBulan) && errors.idBulan}
          />
          <div>
            <div style={{ fontSize: 14 }}>Tanggal Penggajian</div>
            <div className="d-flex align-items-center justify-content-between">
              <div style={{ width: "44%" }}>
                <DatePicker
                  disabled
                  key={`${values?.namaPeriode} ${values?.idBulan}`}
                  placeholderText="dd/mm/yyyy"
                  selected={values?.namaPeriode && values?.idBulan ? values?.tglAwal : undefined}
                  error={Boolean(errors.tglAwal && touched.tglAwal)}
                  errorText={Boolean(errors.tglAwal && touched.tglAwal) && errors.tglAwal}
                />
              </div>
              <small>s/d</small>
              <div style={{ width: "44%" }}>
                <DatePicker
                  disabled
                  key={`${values?.namaPeriode} ${values?.idBulan}`}
                  placeholderText="dd/mm/yyyy"
                  selected={values?.namaPeriode && values?.idBulan ? values?.tglAkhir : undefined}
                  error={Boolean(errors.tglAkhir && touched.tglAkhir)}
                  errorText={Boolean(errors.tglAkhir && touched.tglAkhir) && errors.tglAkhir}
                />
              </div>
            </div>
          </div>
          <DatePicker
            label="Tanggal Setup"
            placeholderText="dd/mm/yy"
            selected={values?.tglSetup ? new Date(values?.tglSetup) : undefined}
            onChange={(val) => {
              setValues({
                ...values,
                tglSetup: val
              });
            }}
            error={Boolean(errors.tglSetup && touched.tglSetup)}
            errorText={Boolean(errors.tglSetup && touched.tglSetup) && errors.tglSetup}
          />
          <div className="text-end mt-2">
            <ButtonCreate
              tooltip={false}
              text="CARI"
              onClick={() =>
                preSubmitHandler(values, setValues, validateForm, setTouched, setErrors, handleSubmit, setSubmitting)
              }
            />
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

const FormKomponenUpah = ({ action, status }) => {
  const {
    values,
    errors,
    touched,
    setValues,
    validateForm,
    setTouched,
    setErrors,
    handleSubmit,
    setSubmitting,
    handleChange
  } = useFormikContext();
  const modalConfirm = useModalConfirm();

  const getKomponenUpah = useQuery(["komponen-upah", "dropdown"], () => KoreksiKerjaApi.getKomponenUpah());

  const getJenisKoreksi = KoreksiKerjaApi.getJenisKoreksi();

  const submitValidationHandler = (errors, values) =>
    new Promise((resolve, reject) => {
      const getError = Object.values(errors);

      if (getError.length > 0) {
        reject(getError);
      } else {
        resolve();
      }
    });

  const preSubmitHandler = (values, setValues, validateForm, setTouched, setErrors, handleSubmit, setSubmitting) => {
    setSubmitting(true);
    validateForm().then(async (err) => {
      setErrors(err);
      setTouched(err);
      await submitValidationHandler(err, values)
        .then(() => handleSubmit())
        .catch((err) =>
          modalConfirm.trigger({
            type: "error",
            title: "Data Tidak Lengkap",
            data: err,
            onHide: () => modalConfirm.close()
          })
        )
        .finally(() => {
          setSubmitting(false);
        });
    });
  };

  return (
    <>
      <div className={action === "UPDATE" ? "" : "d-flex"}>
        <div className={action === "UPDATE" ? "" : "flex-fill me-2"}>
          <Select
            label="Komponen Upah"
            placeholder="Pilih komponen upah"
            defaultValue={getKomponenUpah?.data?.find((item) => item?.value === values?.idKomponenUpah)}
            options={getKomponenUpah?.data ?? []}
            onChange={(val) => {
              setValues({
                ...values,
                idKomponenUpah: val.value,
                namaKomponenUpah: val.label
              });
            }}
            loading={getKomponenUpah.isFetching}
            errorFetch={getKomponenUpah.isError}
            errorFetchText={errorFetchingSelectMapper(getKomponenUpah.error)}
            error={Boolean(errors.idKomponenUpah && touched.idKomponenUpah)}
            errorText={Boolean(errors.idKomponenUpah && touched.idKomponenUpah) && errors.idKomponenUpah}
          />
        </div>
        <div className={action === "UPDATE" ? "" : "flex-fill me-2"}>
          <Select
            label="Jenis Koreksi"
            placeholder="Pilih jenis koreksi"
            defaultValue={getJenisKoreksi?.find((item) => item?.value === values?.idJenisKoreksi)}
            options={getJenisKoreksi ?? []}
            onChange={(val) => {
              setValues({
                ...values,
                idJenisKoreksi: val.value,
                namaJenisKoreksi: val.label
              });
            }}
            error={Boolean(errors.idJenisKoreksi && touched.idJenisKoreksi)}
            errorText={Boolean(errors.idJenisKoreksi && touched.idJenisKoreksi) && errors.idJenisKoreksi}
          />
        </div>
        <div className={action === "UPDATE" ? "" : "flex-fill me-2"}>
          <InputNumber
            label="Nominal"
            placeholder="Masukkan nominal"
            name="nominal"
            value={values?.nominal ?? ""}
            onChange={(val) =>
              setValues({
                ...values,
                nominal: val
              })
            }
            error={Boolean(errors.nominal && touched.nominal)}
            errorText={Boolean(errors.nominal && touched.nominal) && errors.nominal}
          />
        </div>
        <div className={action === "UPDATE" ? "" : "flex-fill me-2"}>
          <TextArea
            label="Keterangan"
            name="keterangan"
            placeholder="Masukkan keterangan"
            value={values.keterangan ?? ""}
            onChange={handleChange}
            error={Boolean(errors.keterangan && touched.keterangan)}
            errorText={Boolean(errors.keterangan && touched.keterangan) && errors.keterangan}
          />
        </div>
        <div className={action === "UPDATE" ? "d-none" : "mt-4"}>
          <ButtonCreate
            disabled={status}
            noText
            tooltip={false}
            icon
            onClick={() =>
              preSubmitHandler(values, setValues, validateForm, setTouched, setErrors, handleSubmit, setSubmitting)
            }
          />
        </div>
      </div>
    </>
  );
};

const TabelListKoreksi = ({ action, parents, setParents }) => {
  const modalConfirm = useModalConfirm();
  const rupiah = rupiahConvert();
  const [modal, setModal] = useState({
    show: false,
    action: "",
    data: {}
  });

  const formUpdateHandler = (values) => {
    let tempTabelListKoreksi = parents?.tabelListKoreksi;
    let finalValue = { ...values };

    if (values?.idJenisKoreksi === 1 && values?.nominal >= 1) {
      finalValue = { ...values, nominal: -1 * values?.nominal };
    }
    if (values?.idJenisKoreksi === 2 && values?.nominal <= -1) {
      finalValue = { ...values, nominal: -1 * values?.nominal };
    }

    tempTabelListKoreksi[values.index] = finalValue;
    setParents({
      ...parents,
      tabelListKoreksi: tempTabelListKoreksi
    });
    onHideModalHandler();
  };

  const onHideModalHandler = () => setModal({ show: false, action: "", data: {} });

  const onUpdateButtonClickHandler = ({ data, index }) =>
    setModal({ show: true, action: "UPDATE", data: { ...data, index: index } });

  const onDeleteButtonClickHandler = (index) => {
    let tempTabelListKoreksi = parents?.tabelListKoreksi;
    tempTabelListKoreksi.splice(index, 1);
    setParents({
      ...parents,
      tabelListKoreksi: tempTabelListKoreksi
    });
  };

  const submitValidationHandler = (errors) =>
    new Promise((resolve, reject) => {
      const getError = Object.values(errors);

      if (getError.length > 0) {
        reject(getError);
      } else {
        resolve();
      }
    });

  const preSubmitHandler = (formik) => {
    const { values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting } = formik;

    setSubmitting(true);
    validateForm().then(async (err) => {
      setErrors(err);
      setTouched(err);

      await submitValidationHandler(err, values)
        .then(() => handleSubmit())
        .catch((err) =>
          modalConfirm.trigger({
            type: "error",
            title: "Data Tidak Lengkap",
            data: err,
            onHide: () => modalConfirm.close()
          })
        )
        .finally(() => {
          setSubmitting(false);
        });
    });
  };

  const TABLE_HEADER = [
    {
      text: "Komponen Upah"
    },
    {
      text: "Jenis Koreksi"
    },
    {
      text: "Nominal"
    },
    {
      text: "Keterangan"
    },
    {
      text: "Aksi",
      props: {
        width: 50,
        fixed: true,
        className: action === "READ" ? "d-none" : ""
      }
    }
  ];
  const TABLE_BODY = [
    {
      field: "namaKomponenUpah"
    },
    {
      field: "namaJenisKoreksi"
    },
    {
      customField: (data) => rupiah.getWithComa(data.nominal ?? 0) + "",
      props: { textRight: true }
    },
    {
      field: "keterangan"
    },
    {
      props: { fixed: true, className: action === "READ" ? "d-none" : "" },
      customField: (data, index) => (
        <ButtonGroup size="sm">
          <ButtonUpdate icon noText onClick={() => onUpdateButtonClickHandler({ data: data, index: index })} />
          <ButtonDelete icon noText onClick={() => onDeleteButtonClickHandler(index)} />
        </ButtonGroup>
      )
    }
  ];

  return (
    <>
      <TableList
        maxHeight={"auto"}
        tableHeader={TABLE_HEADER}
        tableBody={TABLE_BODY}
        data={parents?.tabelListKoreksi}
      />
      {/**
       * Modal Update:
       * Modal untuk mengubah data
       */}
      <Formik
        enableReinitialize
        initialValues={modal.data}
        validationSchema={formValidationSchema.FormKomponenUpah}
        onSubmit={(values) => formUpdateHandler(values)}
      >
        {(formik) => {
          return (
            <Form>
              <ModalUpdate
                title={"Data Koreksi"}
                show={Boolean(modal.show && modal.action === "UPDATE")}
                onHide={() => {
                  onHideModalHandler();
                  formik.resetForm();
                }}
                onSubmit={() => preSubmitHandler(formik)}
              >
                <FormKomponenUpah action="UPDATE" />
              </ModalUpdate>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

const DetailPekerja = ({ data }) => {
  const convertDate = dateConvert();
  return (
    <>
      <Card>
        <Card.Header>Data Pekerja</Card.Header>
        <Card.Body>
          <InfoItemVertical
            label="Tanggal Setup"
            text={data?.tanggalSetup ? convertDate.getSlashDMY(new Date(data?.tanggalSetup)) : "-"}
          />
          <InfoItemVertical label="Periode Penggajian" text={data?.namaPeriode} />
          <InfoItemVertical label="Bulan Penggajian" text={data?.bulanPenggajian} />
          <InfoItemVertical label="Nama Unit Organisasi" text={data?.namaUnitOrganisasi} />
          <InfoItemVertical label="Nama Pekerja" text={data?.namaPekerja} />
          <InfoItemVertical label="Nama Jabatan" text={data?.namaJabatan} />
        </Card.Body>
      </Card>
    </>
  );
};

export const Content = ({ action }) => {
  const navigate = useNavigate();
  const date = dateConvert();
  let parents = useFormikContext();
  const [dataPekerja, setDataPekerja] = useState({
    ...parents?.values?.dataPekerja
  });
  const modalConfirm = useModalConfirm();
  const [dataTabelListKoreksi, setDataTabelListKoreksi] = useState({
    tabelListKoreksi: parents?.values?.tabelListKoreksi ?? []
  });
  const [search, setSearch] = useState({
    tglSetup: new Date(),
    idPeriode: new Date().getFullYear(),
    namaPeriode: new Date().getFullYear(),
    tglAwal: undefined,
    tglAkhir: undefined,
    minDateDatePickerAwal: undefined,
    minDateDatePickerAkhir: undefined,
    maxDatePickerAwal: undefined,
    maxDatePickerAkhir: undefined,
    idBulan: undefined,
    namaBulan: undefined,
    idUnitOrganisasi: undefined,
    namaUnitOrganisasi: undefined,
    idPekerja: undefined,
    namaPekerja: undefined,
    idJabatan: undefined,
    namaJabatan: undefined,
    jenisPekerja: 1,
    status: true
  });

  // const getList = useQuery(
  //   [
  //     "koreksi-kerja-create",
  //     "list",
  //     search.idPeriode,
  //     search.idUnitOrganisasi,
  //     search.idPekerja,
  //     search.tglAwal,
  //     search.tglSetup,
  //     search.jenisPekerja
  //   ],
  //   () =>
  //     KoreksiKerjaApi.getListCreate({
  //       periode_tahun: search.idPeriode,
  //       master_unit_organisasi_id: search.idUnitOrganisasi,
  //       tgl_setup: search.tglSetup,
  //       tgl_bulan_awal: search.tglAwal,
  //       tgl_bulan_akhir: search.tglAkhir,
  //       pekerja_id: search.idPekerja,
  //       jenis_pekerja: search.jenisPekerja
  //     }),
  //   {
  //     enabled: Boolean(
  //       search.idPeriode && search.idUnitOrganisasi && search.idPekerja && search.tglAwal && search.tglSetup
  //     ),
  //     onSuccess: (data) => {
  //       setDataTabelListKoreksi({
  //         tabelListKoreksi:
  //           data?.list_koreksi?.map?.((val) => ({
  //             id: val?.pekerja_koreksi_bulanan_id,
  //             namaKomponenUpah: val?.master_komponen_payroll_nama,
  //             idKomponenUpah: val?.master_komponen_payroll_id,
  //             namaJenisKoreksi: getFunction.getJenisKoreksi(val?.jenis_koreksi),
  //             idJenisKoreksi: val?.jenis_koreksi,
  //             nominal: val?.nominal_koreksi ? Number(val?.nominal_koreksi) + "" : undefined,
  //             keterangan: val?.keterangan
  //           })) ?? []
  //       });
  //     }
  //   }
  // );

  const createKoreksiPekerja = useMutation((data) => KoreksiKerjaApi.create(data), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "create" });
      navigate("/payroll/transaksi/koreksi-pekerja");
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "create", ...errorSubmitMapper(err) })
  });

  const formSimpanHandler = () =>
    modalConfirm.trigger({
      type: "create",
      // size: "lg",
      onHide: modalConfirm.close,
      onSubmit: () => {
        modalConfirm.loading();
        createKoreksiPekerja
          .mutateAsync(formSubmitMapper.FormCreate({ ...search, ...dataTabelListKoreksi }))
          .then(() => modalConfirm.close());
      }
      // component: (
      //   <Formik initialValues={{ ...dataTabelListKoreksi, dataPekerja: dataPekerja }}>
      //     <Content action="READ" />
      //   </Formik>
      // )
    });

  const formSearchHandler = (values) => {
    setSearch({
      ...values,
      status: false
    });
    setDataPekerja({
      tanggalSetup: values?.tglSetup,
      namaPeriode: values?.namaPeriode,
      bulanPenggajian:
        date?.getSlashDMY(new Date(values?.tglAwal)) + " s/d " + date?.getSlashDMY(new Date(values?.tglAkhir)),
      namaPekerja: values?.namaPekerja,
      namaUnitOrganisasi: values?.namaUnitOrganisasi,
      namaJabatan: values?.namaJabatan
    });
  };

  const formKomponenUpahHandler = (values, resetForm) => {
    let finalValue = { ...values };

    if (values?.idJenisKoreksi === 1 && values?.nominal >= 1) {
      finalValue = { ...values, nominal: -1 * values?.nominal };
    }
    if (values?.idJenisKoreksi === 2 && values?.nominal <= -1) {
      finalValue = { ...values, nominal: -1 * values?.nominal };
    }
    let tempTabelListKoreksi = [finalValue, ...dataTabelListKoreksi.tabelListKoreksi];

    setDataTabelListKoreksi({
      tabelListKoreksi: tempTabelListKoreksi
    });
    resetForm();
  };

  return (
    <>
      <div className="d-flex ">
        <div style={{ width: "30%" }} className="me-3">
          <div className={action === "READ" ? "d-none" : ""}>
            <Formik
              enableReinitialize
              initialValues={{
                idPeriode: new Date().getFullYear(),
                namaPeriode: new Date().getFullYear(),
                tglSetup: new Date(),
                jenisPekerja: 1,
              }}
              validationSchema={formValidationSchema.Search}
              onSubmit={(values) => formSearchHandler(values)}
            >
              {({ values }) => {
                return (
                  <>
                    <FormSearch />
                  </>
                );
              }}
            </Formik>
          </div>
          <div className={action === "READ" ? "" : "mt-2"}>
            <DetailPekerja data={dataPekerja} />
          </div>
        </div>
        <div style={{ width: "100%" }} className="d-flex flex-column justify-content-between">
          <div style={{ height: "100%" }}>
            <Card>
              <Card.Header>Input Koreksi Pekerja</Card.Header>
              <Card.Body>
                <div className={action === "READ" ? "d-none" : ""}>
                  <Formik
                    enableReinitialize
                    initialValues={{}}
                    validationSchema={formValidationSchema.FormKomponenUpah}
                    onSubmit={(values, { resetForm }) => formKomponenUpahHandler(values, resetForm)}
                  >
                    {({ values }) => {
                      return (
                        <>
                          <FormKomponenUpah action={action} status={search.status} />
                        </>
                      );
                    }}
                  </Formik>
                </div>
                <div>
                  <TabelListKoreksi
                    action={action}
                    parents={dataTabelListKoreksi}
                    setParents={setDataTabelListKoreksi}
                  />
                </div>
              </Card.Body>
            </Card>
          </div>
          <div className={action === "READ" ? "d-none" : "mt-2 text-end"}>
            <ButtonCreate
              disabled={search.status || dataTabelListKoreksi.tabelListKoreksi.length === 0}
              text="SIMPAN"
              tooltip={false}
              onClick={() => formSimpanHandler()}
            />
          </div>
        </div>
      </div>
    </>
  );
};
