import { IoSpeedometerOutline } from "react-icons/io5";
import { Dashboard as DashboardPage } from "pages/Dashboard";

export const Dashboard = {
  link: "",
  name: "Dashboard",
  icon: <IoSpeedometerOutline />,
  component: <DashboardPage />,
  alwaysShow: true
};
