import {
    ButtonFilter, dateConvert, InfoItemVertical, InputSearch, ModalFilter, numberConvert,
    Select, toastTrigger,
} from "components";
import { Formik, useFormikContext } from "formik";
import { useAccessRights, } from "hooks";
import { ListLayout } from "layouts";
import { debounce } from "lodash";
import { useState } from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import { FiFileText } from "react-icons/fi";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { exportHandler } from "utilities";
import LaporanGaji13Api from "./__LaporanGaji13Api__";

const ModalFilterBody = () => {
    const { values, setValues } = useFormikContext();

    const getPeriode = useQuery(["periode-penggajian", "dropdown"], () => LaporanGaji13Api.getPeriode());

    const getUnitOrganisasi = useQuery(
        ["unit-organisasi-for-list", "dropdown", values?.periodeGajianId],
        () =>
            LaporanGaji13Api.getUnitOrganisasiForList({
                periode_tahun: values?.periodeGajianId
            }),
        { enabled: Boolean(values?.periodeGajianId) }
    );

    return (
        <>
            <Select
                label="Periode Penggajian"
                placeholder="Pilih periode penggajian"
                defaultValue={getPeriode?.data?.find((item) => item.value === values?.periodeGajianId)}
                options={getPeriode?.data ?? []}
                onChange={(val) => setValues({ ...values, periodeGajianId: val.value, periodePerencanaan: val.label })}
                loading={getPeriode.isFetching}
                errorFetch={getPeriode.isError}
            />
            <Select
                disable={Boolean(!values?.periodeGajianId)}
                label="Unit Organisasi"
                placeholder="Pilih unit organisasi"
                defaultValue={getUnitOrganisasi?.data?.find((item) => item.value === values.unitOrganisasiId)}
                options={getUnitOrganisasi?.data ?? []}
                onChange={(val) => setValues({ ...values, unitOrganisasiId: val.value })}
                loading={getUnitOrganisasi.isFetching}
                errorFetch={getUnitOrganisasi.isError}
            />
        </>
    );
};

export const LaporanGaji13List = () => {
    const navigate = useNavigate();
    const number = numberConvert();
    const date = dateConvert();

    const [searchKey, setSearchKey] = useState(undefined);
    const [pagination, setPagination] = useState({
        pageCurrent: 1,
        dataLength: 10
    });

    const [filter, setFilter] = useState({
        show: false,
        active: false,
        data: {
            periodeGajianId: undefined,
            unitOrganisasiId: undefined,
            tglAwal: undefined,
            tglAkhir: undefined
        }
    });

    const [modal, setModal] = useState({
        show: false,
        action: "",
        data: {}
    });

    const getLaporanGajiKe13List = useQuery(
        [
            "gaji-13",
            "list",
            searchKey,
            pagination.pageCurrent,
            pagination.dataLength,
            filter.data?.periodeGajianId,
            filter.data?.unitOrganisasiId,
            filter.data?.tglAwal,
            filter.data?.tglAkhir
        ],
        () =>
            LaporanGaji13Api.getList({
                search: searchKey,
                page: pagination.pageCurrent,
                limit: pagination.dataLength,
                periode_tahun: filter.data?.periodeGajianId,
                master_unit_organisasi_id: filter.data?.unitOrganisasiId,
                tgl_bulan_awal: filter.data?.tglAwal,
                tgl_bulan_akhir: filter.data?.tglAkhir
            }),
        {
            enabled: Boolean(
                filter?.data?.periodeGajianId
            )
        }
    );

    const getLaporanGajiKe13Export = useMutation(
        (data) =>
            LaporanGaji13Api.getExport({
                master_unit_organisasi_id: data?.unitOrganisasiId,
                periode_tahun: data?.periodeGajianId
            }),
        {
            onSuccess: (link) => {
                toastTrigger({ variant: "success", message: "Export berhasil" });
                exportHandler(link, "Data Gaji 13");
            },
            onError: () => {
                toastTrigger({ variant: "danger", message: "Export gagal" });
            }
        }
    );

    const onHideModalHandler = () => setModal({ show: false, action: "", data: {} });
    const onCreateButtonClickHandler = () => navigate("tambah");
    const onPaginationChange = (data) => setPagination({ ...pagination, pageCurrent: data });

    const onPaginationDataLengthChange = (data) => setPagination({ ...pagination, dataLength: data });

    const searchHandler = (e) => {
        setSearchKey(e?.target?.value ? e.target.value : undefined);
    };

    const dataInfo = getLaporanGajiKe13List?.data?.info;

    const TABLE_HEADER = [
        {
            text: "NIK"
        },
        {
            text: "Pekerja"
        },
        {
            text: "Unit Organisasi"
        },
        {
            text: "Jabatan"
        },
        {
            text: "Tgl. Bergabung"
        },
        {
            text: "Gaji 13"
        },
    ];

    const TABLE_BODY = [
        { field: "nip" },
        { field: "pekerja_nama" },
        { field: "master_unit_organisasi_nama" },
        { field: "master_jabatan_nama" },
        { customField: (val) => date.getSlashDMY(new Date(val?.tanggal_bergabung)) },
        {
            props: { textRight: true },
            customField: (val) => "Rp" + number.getWithComa(val?.gaji13, { minimumFractionDigits: 2 })
        },

    ];

    return (
        <>
            <ListLayout
                customRightTopSection={() => (
                    <div className="d-flex flex-column flex-sm-row justify-content-center justify-content-md-end align-items-center">
                        <Dropdown align="end">
                            <Dropdown.Toggle
                                size="sm"
                                variant="outline-success"
                                className="mx-2"
                                disabled={getLaporanGajiKe13Export?.isLoading}
                            >
                                {getLaporanGajiKe13Export?.isLoading ? (
                                    <Spinner size="sm" className="me-1" animation="border" />
                                ) : (
                                    <FiFileText className="mb-1 mr-3" />
                                )}
                                <span className="mx-1">{getLaporanGajiKe13Export?.isLoading ? "Memproses..." : "EXPORT"}</span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="mt-2">
                                <Dropdown.Item onClick={() => getLaporanGajiKe13Export.mutate(filter?.data)}>Export Excel</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                )}
                customLeftTopSection={() => (
                    <>
                        <div className="d-flex align-items-end mb-3">
                            <div style={{ width: 300 }} className="me-2">
                                <InputSearch onChange={debounce(searchHandler, 1500)} />
                            </div>
                            <div>
                                <ButtonFilter
                                    active={filter?.active}
                                    className="text-nowrap"
                                    onClick={() => setFilter({ ...filter, show: true })}
                                />
                            </div>
                        </div>
                        <div className="d-flex align-items-end mb-3 gap-2">
                            <InfoItemVertical label="Komponen Payroll" text={dataInfo?.komponen_payroll} />
                            <InfoItemVertical
                                label="Periode Penggajian"
                                text={dataInfo?.periode_perencanaan_penggajian}
                            />
                        </div>
                    </>
                )}
                // showRightTopButton={access.canCreate() ? true : false}
                // showExportExcelButton={Boolean(filter?.data?.periodeGajianId)}
                // onExportExcelButtonClick={() => getLaporanGajiKe13Export.mutate(filter?.data)}
                // loadingExportButton={getLaporanGajiKe13Export.isLoading}
                loading={getLaporanGajiKe13List?.isFetching}
                error={getLaporanGajiKe13List?.isError}
                dataTotal={getLaporanGajiKe13List?.data?.total}
                dataLength={pagination.dataLength}
                pageCurrent={pagination.pageCurrent}
                pageTotal={getLaporanGajiKe13List?.data?.last_page}
                onSearchChange={debounce(searchHandler, 1500)}
                onCreateButtonClick={onCreateButtonClickHandler}
                onDataLengthChange={onPaginationDataLengthChange}
                onPaginationChange={onPaginationChange}
                data={getLaporanGajiKe13List?.data?.data}
                tableHeader={TABLE_HEADER}
                tableBody={TABLE_BODY}
            />

            {/* ModalFilter */}
            <Formik
                initialValues={{
                    unitOrganisasiId: filter?.data?.unitOrganisasiId,
                    periodeGajianId: filter?.data?.periodeGajianId,
                    tglAwal: filter?.data?.tglAwal,
                    tglAkhir: filter?.data?.tglAkhir
                }}
            >
                {({ resetForm, values }) => (
                    <ModalFilter
                        show={filter.show}
                        onReset={resetForm}
                        onHide={() =>
                            setFilter({
                                ...filter,
                                show: false
                            })
                        }
                        onSubmit={() => {
                            setFilter({
                                show: false,
                                active: Boolean(Object.values(values).find((val) => val)),
                                data: values
                            });
                        }}
                    >
                        <ModalFilterBody />
                    </ModalFilter>
                )}
            </Formik>
        </>
    );
};
