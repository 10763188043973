import React from "react";
import { useQuery } from "react-query";
import { useFormikContext } from "formik";
import {
    TableList,
    InfoItemVertical,
    numberConvert,
    Select,
    DatePicker,
    ButtonCreate,
    Input,
    InfoItemHorizontal,
    rupiahConvert
} from "components";
import { useAccessRights } from "hooks";
import { Row, Col, Card, ButtonGroup } from "react-bootstrap";
import Pph21TahunanApi from "../__Pph21TahunanApi__";
import { useNavigate } from "react-router-dom";

const SectionDataSetup = ({ onSearchButtonClick, onResetClickButton }) => {
    const { values, setValues, errors, touched, handleChange } = useFormikContext();

    const defaultSearch = {
        periodeGajianId: values?.periodeGajianId,
        periodePerencanaan: values?.periodePerencanaan,
        bulan: values?.bulan,
        unitOrganisasiId: values?.unitOrganisasiId,
        unitOrganisasiNama: values?.unitOrganisasiNama,
        pekerjaId: values?.pekerjaId,
        pekerjaNama: values?.pekerjaNama,
        tglAwal: values?.tglAwal,
        tglAkhir: values?.tglAkhir,
        tglSetup: values?.tglSetup,
    };

    const getUnitOrganisasi = useQuery(["unit-organisasi", "dropdown"], () =>
        Pph21TahunanApi.getUnitOrganisasi()
    );

    return (
        <>
            <Input name="periodeGajianId" label="Periode Penggajian" value={values?.periodeGajianId} onChange={handleChange} />
            <Select
                label="Unit Organisasi"
                options={getUnitOrganisasi?.data ?? []}
                placeholder="Pilih salah satu..."
                defaultValue={
                    values?.unitOrganisasiId
                        ? getUnitOrganisasi?.data?.find((item) => item.value === values?.unitOrganisasiId)
                        : null
                }
                onChange={(val) => {
                    setValues({
                        ...values,
                        unitOrganisasiId: val.value,
                        unitOrganisasiNama: val.label
                    });
                }}
                loading={getUnitOrganisasi?.isFetching}
                errorFetch={getUnitOrganisasi.isError}
                error={Boolean(errors.unitOrganisasiId && touched.unitOrganisasiId)}
                errorText={Boolean(errors.unitOrganisasiId && touched.unitOrganisasiId) && errors.unitOrganisasiId}
            />
            <DatePicker
                disabled
                label="Tanggal Setup"
                placeholderText="dd-mm-yyyy"
                selected={values?.tglSetup}
                onChange={(val) =>
                    setValues({
                        ...values,
                        tglSetup: val
                    })
                }
                error={Boolean(errors.tglSetup && touched.tglSetup)}
                errorText={Boolean(errors.tglSetup && touched.tglSetup) && errors.tglSetup}
            />

            <div className="d-flex justify-content-end">
                <div>
                    {/* <ButtonCancel text="RESET" className="mb-3 me-2" tooltip={false} onClick={() => onResetClickButton()} /> */}
                    <ButtonCreate
                        disabled={Boolean(
                            !values?.periodeGajianId
                        )}
                        text="CARI"
                        className="mb-3"
                        tooltip={false}
                        onClick={() =>
                            onSearchButtonClick({
                                active: true,
                                data: defaultSearch
                            })
                        }
                    />
                </div>
            </div>
        </>
    );
};

const TabelDataPekerja = ({ action }) => {
    const { values } = useFormikContext();
    const number = numberConvert();
    const rupiah = rupiahConvert();

    return (
        <>
            <Row className="mb-4">
                <Col md={6}>
                    <InfoItemVertical label="Periode Penggajian" text={values.periodePerencanaan} />
                    <InfoItemVertical label="NIK" text={values.nik} />
                    <InfoItemVertical label="Pekerja" text={values.pekerjaNama} />
                </Col>
                <Col md={6}>
                    <InfoItemVertical label="Unit Organisasi" text={values.unitOrganisasiNama} />
                    <InfoItemVertical label="Jabatan" text={values.jabatanNama} />
                    <InfoItemVertical label="Status PPH21" text={values.statusPajak} />
                    <InfoItemVertical label="NPWP" text={values.noNpwp} />
                </Col>
            </Row>

            <div className="mb-2">
                <div><b>Penghasilan</b></div>
                <TableList
                    maxHeight={550}
                    tableHeader={[
                        { text: "Komponen Gaji" },
                        { text: "Nominal" },
                    ]}
                    tableBody={[
                        {
                            field: "komponenNama"
                        },
                        {
                            props: { textRight: true },
                            customField: (val) => val?.nominalTahunan ? "Rp" + number.getWithComa(val?.nominalTahunan, { minimumFractionDigits: 2 }) : "Rp0"
                        },
                    ]}
                    data={values?.detail?.filter((obj) => obj.status === 1)}
                    tableFooter={[
                        {
                            text: "Penghasilan Bruto (Tahunan)",
                            props: { colSpan: 2 }
                        },
                        {
                            props: { textRight: true },
                            text: values?.penghasilanBrutoBulanan ? "Rp" + number.getWithComa(values?.penghasilanBrutoBulanan, { minimumFractionDigits: 2 })
                                : "Rp" + 0
                        }
                    ]}
                />
            </div>

            <div className="mb-2">
                <div><b>Pemotongan Penghasilan</b></div>
                <TableList
                    maxHeight={550}
                    tableHeader={[
                        { text: "Komponen Gaji" },
                        { text: "Nominal" },
                    ]}
                    tableBody={[
                        {
                            field: "komponenNama"
                        },
                        {
                            props: { textRight: true },
                            customField: (val) => val?.nominalTahunan ? "Rp" + number.getWithComa(val?.nominalTahunan, { minimumFractionDigits: 2 }) : "Rp0"
                        },
                    ]}
                    data={values?.detail?.filter((obj) => obj.status === 2)}
                    tableFooter={[
                        {
                            text: "Pemotongan (Tahunan)",
                            props: { colSpan: 2 }
                        },
                        {
                            props: { textRight: true },
                            text: values?.penghasilanBrutoBulanan ? "Rp" + number.getWithComa(values?.penghasilanBrutoBulanan, { minimumFractionDigits: 2 })
                                : "Rp" + 0
                        }
                    ]}
                />
            </div>

            <div className="mb-2">
                <Card>
                    <Card.Body>
                        <div><b>Penghasilan Netto</b></div>
                        <InfoItemHorizontal width={"30%"} label="Penghasilan Bruto (Tahunan)" text={values?.penghasilanBrutoTahunan ? rupiah.getWithComa(values?.penghasilanBrutoTahunan) : "Rp0"} />
                        <InfoItemHorizontal width={"30%"} label="Pengurangan Penghasilan (Tahunan)" text={values?.penguranganBrutoTahunan ? rupiah.getWithComa(values?.penguranganBrutoTahunan) : "Rp0"} />
                        <hr></hr>
                        <InfoItemHorizontal width={"30%"} label="Penghasilan Netto (Tahunan)" text={values?.penghasilanNettoTahunan ? rupiah.getWithComa(values?.penghasilanNettoTahunan) : "Rp0"} />
                    </Card.Body>
                </Card>
            </div>

            <div className="mb-2">
                <Card>
                    <Card.Body>
                        <div><b>Penghasilan Kena Pajak</b></div>
                        <InfoItemHorizontal width={"30%"} label="Penghasilan Netto" text={values?.penghasilanNettoTahunan ? rupiah.getWithComa(values?.penghasilanNettoTahunan) : "Rp0"} />
                        <InfoItemHorizontal width={"30%"} label="PTKP" text={values?.penghasilanTidakKenaPajak ? rupiah.getWithComa(values?.penghasilanTidakKenaPajak) : "Rp0"} />
                        <hr></hr>
                        <InfoItemHorizontal width={"30%"} label="Penghasilan Kena Pajak (Tahunan)" text={values?.penghasilanKenaPajakTahunan ? rupiah.getWithComa(values?.penghasilanKenaPajakTahunan) : "Rp0"} />
                    </Card.Body>
                </Card>
            </div>

            <div className="mb-2">
                <Card>
                    <Card.Body>
                        <div><b>Selisih PPh 21 (Tahunan)</b></div>
                        <InfoItemHorizontal width={"30%"} label="PPh 21(Tahunan)" text={values?.pph21Tahunan ? rupiah.getWithComa(values?.pph21Tahunan) : "Rp0"} />
                        <InfoItemHorizontal width={"30%"} label="PPh 21 (Sudah dibayar)" text={values?.pph21SudahDibayar ? rupiah.getWithComa(values?.pph21SudahDibayar) : "Rp0"} />
                        <hr></hr>
                        <InfoItemHorizontal width={"30%"} label="Kurang Bayar (Tahunan)" text={values?.pph21Selisih ? rupiah.getWithComa(values?.pph21Selisih) : "Rp0"} />
                    </Card.Body>
                </Card>
            </div>
        </>
    );
};

const TabelPostList = ({ action }) => {
    const { values } = useFormikContext();
    const access = useAccessRights("PEKERJA_PAJAK_TAHUNAN");
    const navigate = useNavigate();

    return (
        <>
            <TableList
                maxHeight={550}
                rowProps={(val) => ({ style: { background: (val.hasStatusPajak === false) ? "#fc7c7c" : "white" } })}
                tableHeader={[
                    { text: "NIK" },
                    { text: "Pekerja" },
                    { text: "Unit Organisasi" },
                    { text: "Jabatan" },
                    { text: "Status Pajak" },
                    { text: "NPWP" },
                    { text: "Aksi" }
                ]}
                tableBody={[
                    {
                        field: "nik"
                    },
                    {
                        field: "pekerjaNama"
                    },
                    {
                        field: "unitOrganisasiNama"
                    },
                    {
                        field: "jabatanNama"
                    },
                    {
                        field: "statusPajak"
                    },
                    {
                        field: "noNpwp"
                    },
                    {
                        props: {
                            width: 50,
                            fixed: true,
                            textCenter: true,
                            style: { backgroundColor: "white" }
                        },
                        customField: (val) => (
                            <ButtonGroup size="sm">
                                {access.canCreate() && (
                                    <ButtonCreate tooltip={false} disabled={(val?.hasPosted === true || val?.hasStatusPajak === false)} text="POST" onClick={() => {
                                        navigate("/payroll/transaksi/pph-21-tahunan/tambah",
                                            {
                                                state: {
                                                    periodeGajianId: val?.periodeGajianId,
                                                    unitOrganisasiId: val?.unitOrganisasiId,
                                                    unitOrganisasiNama: val?.unitOrganisasiNama,
                                                    pekerjaId: val?.pekerjaId,
                                                    pekerjaNama: val?.pekerjaNama,
                                                    jabatanNama: val?.jabatanNama,
                                                    nik: val?.nik,
                                                    tglSetup: val?.tglSetup
                                                }
                                            })
                                    }} />
                                )}
                            </ButtonGroup>
                        )
                    }
                ]}
                data={values?.detail}
            />
        </>
    )
}


export const Content = ({ action, onSearchButtonClick, onResetClickButton, setCreateViewState, filterData }) => {
    return (
        <>
            <Row>
                {action === "POST" ? (
                    <>
                        <Col md={4} className="mb-4">
                            <Card style={{ height: "100%" }}>
                                <Card.Header>
                                    <b>Setup Data</b>
                                </Card.Header>
                                <Card.Body>
                                    <SectionDataSetup onSearchButtonClick={onSearchButtonClick} onResetClickButton={onResetClickButton} />
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Header>
                                    <b>List Pekerja</b>
                                </Card.Header>
                                <Card.Body>
                                    <TabelPostList action={action} setCreateViewState={setCreateViewState} onPostButtonClickHandler={onSearchButtonClick} filterData={filterData} />
                                </Card.Body>
                            </Card>
                        </Col>
                    </>
                ) : (
                    <Col md={12}>
                        <Card style={{ height: "100%" }}>
                            <Card.Header>
                                <b>Data Pekerja</b>
                            </Card.Header>
                            <Card.Body>
                                <TabelDataPekerja action={action} />
                            </Card.Body>
                        </Card>
                    </Col>
                )}
            </Row>
        </>
    );
};
