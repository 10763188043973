import { Services } from "services";
class PerhitunganUangPhkApi {
  async getList(params) {
    const fetch = await Services.get("/transaksi/perhitungan-phk", { params });
    return fetch?.data?.data;
  }

  async getDataPhkList(params) {
    const fetch = await Services.get("/transaksi/perhitungan-phk/list_pekerja_phk", { params });
    return fetch?.data?.data;
  }

  async getDetail(id) {
    const fetch = await Services.get("/transaksi/perhitungan-phk/" + id);
    return fetch?.data?.data;
  }

  async getDataPhkDetail(id) {
    const fetch = await Services.get("/transaksi/perhitungan-phk/form/" + id);
    return fetch?.data?.data;
  }

  async getUnitOrganisasi(params) {
    const fetch = await Services.get("/transaksi/perhitungan-phk/dropdown-unit", { params });
    return fetch?.data?.data.map((val) => ({
      value: val?.master_unit_organisasi_id,
      label: val?.master_unit_organisasi_nama
    }));
  }

  async getPeriodePerencanaan() {
    const fetch = await Services.get("/utility/periode-tahun?feat=perhitungan-phk");
    return fetch?.data?.data.map((val) => ({
      value: val?.periode_tahun,
      label: val?.periode_tahun
    }));
  }

  async getPeriodePhk() {
    const fetch = await Services.get("/utility/periode-tahun?feat=pekerja-phk");
    return fetch?.data?.data.map((val) => ({
      value: val?.periode_tahun,
      label: val?.periode_tahun
    }));
  }

  async getBulan() {
    return Array.from({ length: 12 }, (item, i) => {
      return {
        value: i + 1,
        label: new Date(0, i).toLocaleString("id-ID", { month: "long" })
      };
    });
  }

  create(data) {
    return Services.post("/transaksi/perhitungan-phk/", data);
  }

  update(data, id) {
    return Services.put("/transaksi/perhitungan-phk/" + id, data);
  }

  delete(id) {
    return Services.delete("/transaksi/perhitungan-phk/" + id);
  }

  async getExport(params) {
    const fetch = await Services.get("/transaksi/perhitungan-phk/export", { params });
    return fetch?.data?.data;
  }
}

export default new PerhitunganUangPhkApi();
