export const formInitialValues = (data = {}) => ({
  id: data?.jaminan_bpjs_id ?? undefined,

  tglSetup: data?.tgl_setup ?? undefined,

  idPeriode: data?.periode_tahun ?? new Date().getFullYear(),
  namaPeriode: data?.periode_tahun ?? new Date().getFullYear(),

  idJenisUpahMinimum: data?.jenis_upah_id ?? undefined,
  namaJenisUpahMinimum: data?.jenis_upah_nama ?? undefined,

  idNominal: data?.nominal_upah_minimum_id ?? undefined,
  batasBawahUpah: data?.batas_bawah ? Number(data?.batas_bawah) : undefined,

  idKomponenBpjs: data?.komponen_bpjs_id ?? undefined,
  namaKomponenBpjs: data?.komponen_bpjs_nama ?? undefined,

  proporsiPerusahaan: data?.jaminan_perusahaan ? Number(data?.jaminan_perusahaan) : undefined,
  proporsiPekerja: data?.jaminan_pekerja ? Number(data?.jaminan_pekerja) : undefined,

  batasAtasUpah: data?.batas_atas ? Number(data?.batas_atas) : undefined,

  keterangan: data?.keterangan ?? undefined
});
