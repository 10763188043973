export const formInitialValues = (data = {}, filter) => ({
  dataFormSetup: {
    periodeGajianId: filter?.periodeGajianId,
    periodePerencanaan: filter?.periodePerencanaan,
    tglSetup: data?.komponen_bpjs?.tgl_setup ?? filter?.tglSetup,
    unitOrganisasiId: filter?.unitOrganisasiId,
    bulan: data?.komponen_bpjs?.bulan ?? filter?.bulan,
    tglAwal: data?.komponen_bpjs?.tgl_awal_bulan ?? filter?.tglAwal,
    tglAkhir: data?.komponen_bpjs?.tgl_akhir_bulan ?? filter?.tglAkhir,
    dataInfo: {
      periodeGajianId: data?.komponen_bpjs?.periode_gajian ?? "",
      periodePerencanaan: data?.komponen_bpjs?.periode_gajian ?? "",
      tglSetup: data?.komponen_bpjs?.tgl_setup ?? "",
      tglAwal: data?.komponen_bpjs?.tgl_bulan_awal ?? "",
      tglAkhir: data?.komponen_bpjs?.tgl_bulan_akhir ?? "",
      komponenBPJSId: data?.komponen_bpjs?.komponen_bpjs_id ?? "",
      komponenBPJSNama: data?.komponen_bpjs?.komponen_bpjs_nama ?? "",
      unitOrganisasiNama: data?.komponen_bpjs?.master_unit_organisasi_nama ?? "",
      jaminanBPJSId: data?.komponen_bpjs?.jaminan_bpjs_id ?? "",
      jenisUpahNama: data?.komponen_bpjs?.jenis_upah ?? "",
      jaminanPerusahaan: data?.komponen_bpjs?.proporsi_perusahaan ?? "",
      jaminanPekerja: data?.komponen_bpjs?.proporsi_pekerja ?? "",
      nominalUpahMinimumId: data?.komponen_bpjs?.nominal_upah_minimum_id ?? "",
      batasBawah: data?.komponen_bpjs?.batas_bawah ?? "",
      batasAtas: data?.komponen_bpjs?.batas_atas ?? "",
      keterangan: data?.komponen_bpjs?.keterangan ?? ""
    },
    detail:
      data?.pekerja?.map((val) => ({
        pekerjaUpahPokokBulananId: val?.pekerja_upah_pokok_bulanan_id ?? "",
        jaminanBPJSId: val?.jaminan_bpjs_id ?? "",
        periodeGajianId: val?.periode_tahun ?? "",
        tglSetup: val?.tgl_setup ?? "",
        nik: val?.nip ?? "",
        pekerjaId: val?.pekerja_id ?? "",
        pekerjaNama: val?.pekerja_nama ?? "",
        unitOrganisasiId: val?.master_unit_organisasi_id ?? "",
        unitOrganisasiNama: val?.master_unit_organisasi_nama ?? "",
        jabatanId: val?.master_jabatan_id ?? "",
        jabatanNama: val?.master_jabatan_nama ?? "",
        upahBpjs: val?.upah_bpjs ?? "",
        iuranPerusahaan: val?.iuran_perusahaan ?? "",
        iuranKaryawan: val?.iuran_karyawan ?? "",
        besaranUpah: val?.besaran_upah ?? "",
        tglAwal: val?.tgl_bulan_awal ?? "",
        tglAkhir: val?.tgl_bulan_akhir ?? "",
        jenis: val?.jenis ?? ""
      })) ?? []
  },

  detailJaminan: {
    id: data?.pekerja_jaminan_kehilangan_pekerjaan_bulanan_id ?? "",
    komponenPayroll: data?.komponen_payroll ?? "",
    periodePerencanaan: data?.periode_tahun ?? "",
    bulan: data?.bulan ?? "",
    nik: data?.nip ?? "",
    pekerjaId: data?.pekerja_id ?? "",
    pekerjaNama: data?.pekerja_nama ?? "",
    unitOrganisasiId: data?.master_unit_organisasi_id ?? "",
    unitOrganisasiNama: data?.master_unit_organisasi_nama ?? "",
    jabatanId: data?.master_jabatan_id ?? "",
    jabatanNama: data?.master_jabatan_nama ?? "",
    upahBpjs: data?.upah_bpjs ?? "",
    iuranPerusahaan: data?.iuran_perusahaan ?? "",
    iuranKaryawan: data?.iuran_karyawan ?? "",
    besaranUpah: data?.besaran_upah ?? "",
    totalIuran: data?.total_iuran ?? "",
    jenis: data?.jenis ?? ""
  }
});
