export const formInitialValues = (data = {}) => ({
  id: data?.tarif_ptpk_id ?? undefined,

  idPeriode: data?.periode_tahun ?? new Date().getFullYear(),
  namaPeriode: data?.periode_tahun ?? new Date().getFullYear(),

  statusPajakErr: undefined,
  statusPajak: data?.status_pajak,

  nominalPtkp: data?.nominal_pajak ? Number(data?.nominal_pajak) : undefined,
  keterangan: data?.keterangan
});
