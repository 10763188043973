import { Services } from "services";
import { fetchDropdownMapper } from "utilities";

const fetchDropdown = fetchDropdownMapper(Services);

class ProfesiApi {
  async getList(params) {
    const fetch = await Services.get("/master/master-profesi", { params });
    return fetch?.data?.data;
  }
  getJenisProfesi() {
    return fetchDropdown("/master/jenis-profesi/dropdown", {
      value: "master_jenis_profesi_id",
      label: "master_jenis_profesi_nama"
    });
  }

  async getExport(params) {
    const fetch = await Services.get("/master/master-profesi/export", { params });
    return fetch?.data?.data;
  }

  create(data) {
    return Services.post("/master/master-profesi", data);
  }

  update(data, id) {
    return Services.put("/master/master-profesi/" + id, data);
  }

  delete(id) {
    return Services.delete("/master/master-profesi/" + id);
  }
}
export default new ProfesiApi();
