import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { Formik } from "formik";
import { ContentLayout } from "layouts";
import { errorSubmitMapper } from "utilities";
import { ButtonBack, useModalConfirm, ButtonCreate } from "components";
import { Content } from "./__SuratPerintahLemburComps__";
import { formInitialValues, formSubmitValueMapper, formValidationSchema } from "./__SuratPerintahLemburUtilities__";
import SuratPerintahLemburApi from "./__SuratPerintahLemburApi__";

export const SuratPerintahLemburCreate = () => {
  const navigate = useNavigate();
  const modalConfirm = useModalConfirm();

  const createData = useMutation((data) => SuratPerintahLemburApi.create(data), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "create" });
      navigate("/operasional-sdm/transaksi/surat-perintah-lembur");
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "create", ...errorSubmitMapper(err) })
  });

  const submitValidationHandler = (errors) =>
    new Promise((resolve, reject) => {
      const getError = Object.values(errors);

      if (getError.length > 0) {
        reject(getError);
      } else {
        resolve();
      }
    });

  const preSubmitHandler = (values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting) => {
    setSubmitting(true);
    validateForm().then(async (err) => {
      setErrors(err);
      setTouched(err);

      await submitValidationHandler(err, values)
        .then(() => handleSubmit())
        .catch((err) =>
          modalConfirm.trigger({
            type: "error",
            title: "Data Tidak Lengkap",
            data: err,
            onHide: () => modalConfirm.close()
          })
        )
        .finally(() => {
          setSubmitting(false);
        });
    });
  };

  const formSubmitHandler = async (values) => {
    return await modalConfirm.trigger({
      type: "create",
      onHide: modalConfirm.close,
      onSubmit: () => {
        modalConfirm.loading();
        createData.mutateAsync(formSubmitValueMapper(values));
      }
    });
  };

  return (
    <>
      <ContentLayout showBreadcrumb>
        <>
          <div className="text-end mb-3">
            <ButtonBack tooltip={false} onClick={() => navigate("/operasional-sdm/transaksi/surat-perintah-lembur")} />
          </div>
          <Formik
            enableReinitialize
            initialValues={formInitialValues()}
            validationSchema={formValidationSchema}
            onSubmit={(values) => formSubmitHandler(values)}
          >
            {({ values, handleSubmit, validateForm, setTouched, setErrors, setSubmitting }) => (
              <>
                <Content action="CREATE" />
                <div className="text-end mb-3 mt-3">
                  <ButtonCreate
                    disabled={Boolean(values?.detail.length === 0)}
                    text="SIMPAN"
                    tooltip={false}
                    onClick={() =>
                      preSubmitHandler(values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting)
                    }
                  />
                </div>
              </>
            )}
          </Formik>
        </>
      </ContentLayout>
    </>
  );
};
