import { Services } from "services";

class InsentifBasePayApi {
  async getList(params) {
    const fetch = await Services.get("/master/insentif-basepay", { params });
    return fetch?.data?.data;
  }

  async getDetail(id) {
    const fetch = await Services.get("/master/insentif-basepay/" + id);
    return fetch?.data?.data;
  }

  async getPeriode() {
    const fetch = await Services.get("/master/grade-nominal/dropdown");
    return fetch?.data?.data.map((val) => ({
      value: val?.periode_gajian_id,
      label: val?.periode_perencanaan
    }));
  }

  getStatus() {
    return [
      {
        value: 1,
        label: "Aktif"
      },
      {
        value: 2,
        label: "Tidakk Aktif"
      }
    ];
  }

  async getExport(params) {
    const fetch = await Services.get("/master/insentif-basepay/export?to=", { params });
    return fetch?.data?.data;
  }

  create(data) {
    return Services.post("/master/insentif-basepay", data);
  }

  update(data, id) {
    return Services.put("/master/insentif-basepay/" + id, data);
  }

  delete(id) {
    return Services.delete("/master/insentif-basepay/" + id);
  }
}

export default new InsentifBasePayApi();
