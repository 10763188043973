import {
  ButtonCreate, DatePicker, InfoItemVertical, Input, InputCurrency, numberConvert,
  Select, TableList
} from "components";
import { useFormikContext } from "formik";
import { Card, Col, Row } from "react-bootstrap";
import { useQuery } from "react-query";
import SetupUpahPokokBulananApi from "../__SetupUpahPokokBulananApi__";

const SectionDataSetup = ({ onSearchButtonClick, onResetClickButton }) => {
  const { values, setValues, errors, touched } = useFormikContext();
  // const date = dateConvert();

  const getBulan = useQuery(["bulan", "dropdown"], () => SetupUpahPokokBulananApi.getBulan());
  const getUnitOrganisasi = useQuery(["unit-organisasi", "dropdown"], () =>
    SetupUpahPokokBulananApi.getUnitOrganisasi()
  );

  return (
    <>
      <Input disabled label="Periode Penggajian" value={values?.periodePerencanaan} />
      <Select
        label="Unit Organisasi"
        options={getUnitOrganisasi?.data ?? []}
        placeholder="Pilih salah satu..."
        defaultValue={
          values?.unitOrganisasiId
            ? getUnitOrganisasi?.data?.find((item) => item.value === values?.unitOrganisasiId)
            : null
        }
        onChange={(val) => {
          setValues({
            ...values,
            unitOrganisasiId: val.value,
            unitOrganisasiNama: val.label
          });
        }}
        loading={getUnitOrganisasi?.isFetching}
        errorFetch={getUnitOrganisasi.isError}
        error={Boolean(errors.unitOrganisasiId && touched.unitOrganisasiId)}
        errorText={Boolean(errors.unitOrganisasiId && touched.unitOrganisasiId) && errors.unitOrganisasiId}
      />
      <Select
        label="Bulan Penggajian"
        disable={Boolean(!values?.periodeGajianId)}
        options={getBulan?.data ?? []}
        placeholder="Pilih salah satu..."
        defaultValue={values?.bulan ? getBulan?.data?.find((item) => item.value === values?.bulan) : null}
        onChange={(val) => {
          const tglNewStart = val.value <= 1 ?
            `${values?.periodePerencanaan - 1}-12-26`
            : `${values?.periodePerencanaan}-${val?.value < 10 ? `0${val?.value - 1}` : val?.value - 1}-26`;

          const tglNewEnd = `${values?.periodePerencanaan}-${val?.value < 10 ? `0${val?.value}` : val?.value}-25`;

          setValues({
            ...values,
            bulan: val.value,
            bulanNama: val.label,
            tglAwal: tglNewStart,
            tglAkhir: tglNewEnd
          });
        }}
        loading={getBulan?.isFetching}
        errorFetch={getBulan.isError}
        error={Boolean((errors.bulan && touched.bulan) || errors.validateDate)}
        errorText={Boolean(errors.bulan && touched.bulan) && errors.bulan ? errors.bulan : errors.validateDate}
      />
      <div>
        <div style={{ fontSize: 14 }}>Tanggal Penggajian</div>
        <div className="d-flex align-items-center justify-content-between">
          <div style={{ width: "44%" }}>
            <DatePicker
              disabled
              key={`${values?.periodeGajianId} ${values?.bulan}`}
              placeholderText="dd/mm/yyyy"
              selected={values?.periodeGajianId && values?.bulan ? values?.tglAwal : undefined}
              disable={Boolean(!values?.bulan)}
              onChange={(val) =>
                setValues({
                  ...values,
                  tglAwal: val,
                  bulan: new Date(val).getMonth() + 1,
                  tglAkhir: new Date(new Date(val).setDate(new Date(val).getDate() + 30))
                })
              }
              error={Boolean(errors.tglAwal && touched.tglAwal) || errors.validateDate}
              errorText={
                Boolean(errors.tglAwal && touched.tglAwal) && errors.tglAwal ? errors.tglAwal : errors.validateDate
              }
            />
          </div>
          <small>s/d</small>
          <div style={{ width: "44%" }}>
            <DatePicker
              disabled
              key={`${values?.periodeGajianId} ${values?.bulan}`}
              placeholderText="dd/mm/yyyy"
              selected={values?.periodeGajianId && values?.bulan ? values?.tglAkhir : undefined}
              disable={Boolean(!values?.bulan)}
              onChange={(val) =>
                setValues({
                  ...values,
                  tglAkhir: val
                })
              }
              error={Boolean(errors.tglAkhir && touched.tglAkhir)}
              errorText={Boolean(errors.tglAkhir && touched.tglAkhir) && errors.tglAkhir}
            />
          </div>
        </div>
      </div>

      <DatePicker
        disabled
        label="Tanggal Setup"
        placeholderText="dd-mm-yyyy"
        selected={values?.tglSetup}
        onChange={(val) =>
          setValues({
            ...values,
            tglSetup: val
          })
        }
        error={Boolean(errors.tglSetup && touched.tglSetup)}
        errorText={Boolean(errors.tglSetup && touched.tglSetup) && errors.tglSetup}
      />

      <div className="d-flex justify-content-end">
        <div>
          {/* <ButtonCancel text="RESET" className="mb-3 me-2" tooltip={false} onClick={() => onResetClickButton()} /> */}
          <ButtonCreate
            disabled={Boolean(!values?.periodeGajianId || !values?.unitOrganisasiId)}
            text="CARI"
            className="mb-3"
            tooltip={false}
            onClick={() => onSearchButtonClick(values)}
          />
        </div>
      </div>
    </>
  );
};

const TabelDataPekerja = () => {
  const { values, setValues, errors, touched } = useFormikContext();
  const number = numberConvert();

  const setNewValues = ({ index, propname, val }) => {
    const detailTemp = [...values?.detail];
    const updatedDetail = detailTemp[index];
    updatedDetail[propname] = val;

    setValues({ ...values, detail: detailTemp });
  };

  const getError = (index, propname) => {
    return Boolean(errors?.detail?.[index]?.[propname] && touched?.detail?.[index]?.[propname]);
  };

  const getErrorText = (index, propname) => {
    return (
      Boolean(errors?.detail?.[index]?.[propname] && touched?.detail?.[index]?.[propname]) &&
      errors?.detail?.[index]?.[propname]
    );
  };

  return (
    <>
      <TableList
        maxHeight={550}
        tableHeader={[
          { text: "NIK" },
          { text: "Pekerja" },
          { text: "Unit Organisasi" },
          { text: "Jabatan" },
          { text: "Upah Pokok" },
          { text: "Denda Keterlambatan" },

          { text: "Final Upah Pokok" }
        ]}
        tableBody={[
          {
            field: "nik"
          },
          {
            field: "pekerjaNama"
          },
          {
            field: "unitOrganisasiNama"
          },
          {
            field: "jabatanNama"
          },

          {
            customField: (val, index) => (
              <InputCurrency
                name="Upah Pokok"
                disabled
                value={"Rp" + number.getWithComa(val?.upahPokok, { minimumFractionDigits: 2 })}
                error={getError(index, "upahPokok")}
                errorText={getErrorText(index, "upahPokok")}
              />
            )
          },
          {
            customField: (val, index) => (
              <InputCurrency
                name="dendaKeterlambatan"
                disabled
                value={"Rp" + number.getWithComa(val?.dendaKeterlambatan, { minimumFractionDigits: 2 })}
                error={getError(index, "dendaKeterlambatan")}
                errorText={getErrorText(index, "dendaKeterlambatan")}
              />
            )
          },
          {
            customField: (val, index) => (
              <InputCurrency
                name="finalUpahPokok"
                onChange={(x) => {
                  setNewValues({ index: index, propname: "checked", val: true });
                  setNewValues({
                    index: index,
                    propname: "finalUpahPokok",
                    val: val?.upahPokok - val?.dendaKeterlambatan
                  });
                }}
                value={"Rp" + number.getWithComa(val?.finalUpahPokok, { minimumFractionDigits: 2 })}
                error={getError(index, "finalUpahPokok")}
                errorText={getErrorText(index, "finalUpahPokok")}
              />
            )
          }
        ]}
        data={values?.detail}
      />
    </>
  );
};

export const Content = ({ action, onSearchButtonClick, onResetClickButton }) => {
  const { values, setValues, errors, touched } = useFormikContext();
  const number = numberConvert();

  // const getStatus = useQuery(["status", "dropdown"], () => SetupUpahPokokBulananApi.getStatus());

  if (action === "READ") {
    return (
      <>
        <InfoItemVertical label="Pekerja" text={values.pekerjaNama} />
        <InfoItemVertical label="Unit Organisasi" text={values.unitOrganisasiNama} />
        <InfoItemVertical label="Jabatan" text={values.jabatanNama} />

        <InfoItemVertical
          label="Upah Pokok"
          text={values.upahPokok ? "Rp" + number.getWithComa(values?.upahPokok, { minimumFractionDigits: 2 }) : "-"}
        />
        <InfoItemVertical
          label="Denda Keterlambatan"
          text={
            values.dendaKeterlambatan
              ? "Rp" + number.getWithComa(values?.dendaKeterlambatan, { minimumFractionDigits: 2 })
              : "-"
          }
        />
        <InfoItemVertical
          label="Final Upah Pokok"
          text={
            values.finalUpahPokok
              ? "Rp" + number.getWithComa(values?.finalUpahPokok, { minimumFractionDigits: 2 })
              : "-"
          }
        />
      </>
    );
  }

  if (action === "UPDATE") {
    return (
      <>
        {/* <InfoItemVertical label="Komponen Payrol" text={values.komponenPayroll} />
        <InfoItemVertical label="Periode Penggajian" text={values.periodeGajianId} /> */}

        <Input disabled label="Pekerja" value={values?.pekerjaNama} />
        <Input disabled label="Unit Organisasi" value={values?.unitOrganisasiNama} />
        <Input disabled label="Jabatan" value={values?.jabatanNama} />

        <InputCurrency
          name="upahPokok"
          label="Upah Pokok"
          disabled
          value={values.upahPokok ? "Rp" + number.getWithComa(values?.upahPokok, { minimumFractionDigits: 2 }) : "-"}
          onChange={(val) =>
            setValues({
              ...values,
              upahPokok: val
            })
          }
          error={Boolean(errors.upahPokok && touched.upahPokok)}
          errorText={Boolean(errors.upahPokok && touched.upahPokok) && errors.upahPokok}
        />
        <InputCurrency
          name="dendaKeterlambatan"
          label="Denda Keterlambatan"
          disabled
          value={
            values.dendaKeterlambatan
              ? "Rp" + number.getWithComa(values?.dendaKeterlambatan, { minimumFractionDigits: 2 })
              : "-"
          }
          onChange={(val) =>
            setValues({
              ...values,
              dendaKeterlambatan: val
            })
          }
          error={Boolean(errors.dendaKeterlambatan && touched.dendaKeterlambatan)}
          errorText={Boolean(errors.dendaKeterlambatan && touched.dendaKeterlambatan) && errors.dendaKeterlambatan}
        />
        <InputCurrency
          name="finalUpahPokok"
          label="Final Upah Pokok"
          value={
            values.finalUpahPokok
              ? "Rp" + number.getWithComa(values?.finalUpahPokok, { minimumFractionDigits: 2 })
              : "-"
          }
          onChange={(val) =>
            setValues({
              ...values,
              finalUpahPokok: val
            })
          }
          error={Boolean(errors.finalUpahPokok && touched.finalUpahPokok)}
          errorText={Boolean(errors.finalUpahPokok && touched.finalUpahPokok) && errors.finalUpahPokok}
        />
      </>
    );
  }

  return (
    <>
      <Row>
        <Col md={4} className="mb-4">
          <Card>
            <Card.Header>
              <b>Setup Data</b>
            </Card.Header>
            <Card.Body>
              <SectionDataSetup onSearchButtonClick={onSearchButtonClick} onResetClickButton={onResetClickButton} />
            </Card.Body>
          </Card>
        </Col>

        <Col md={8}>
          <Card>
            <Card.Header>
              <b>Data Pekerja</b>
            </Card.Header>
            <Card.Body>
              <TabelDataPekerja />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
