import { dateConvert } from "components";

const date = dateConvert();

export const formInitialValues = (data = {}, filter) => ({
    dataFormSetup: {
        periodeGajianId: data?.data?.info?.periode_tahun ?? filter?.periodeGajianId,
        periodePerencanaan: data?.data?.info?.periode_tahun ?? new Date().getFullYear(),
        bulan: filter?.bulan,
        tglAwal: filter?.tglAwal,
        tglAkhir: filter?.tglAkhir,
        tglSetup: filter?.tglSetup ?? new Date(),
        unitOrganisasiId: filter?.unitOrganisasiId ?? "",
        unitOrganisasiNama: filter?.unitOrganisasiNama ?? "",
        nominal: false,
        detail:
            data?.data?.map((val) => ({
                // checked: val?.gaji13 || val?.gaji13 === 0 ? true : false,
                periodeGajianId: val?.periode_tahun ?? "",
                tglSetup: filter?.tglSetup ?? date.getDashYMD(new Date()),
                tglAwal: filter?.tglAwal,
                tglAkhir: filter?.tglAkhir,
                nik: val?.nip ?? "",
                pekerjaId: val?.pekerja_id ?? "",
                pekerjaNama: val?.pekerja_nama ?? "",
                jabatanId: val?.master_jabatan_id ?? "",
                jabatanNama: val?.master_jabatan_nama ?? "",
                unitOrganisasiId: val?.master_unit_organisasi_id ?? "",
                unitOrganisasiNama: val?.master_unit_organisasi_nama ?? "",
                tglBergabung: val?.tanggal_bergabung ?? "",
                masaKerja: val?.masa_kerja ?? "",
                masaKerjaTampil: val?.masa_kerja_tampil ?? "",
                upahPokok: val?.upah_pokok ?? "",
                tunjanganTetap: val?.tunjangan_tetap ?? "",
                nominal: val?.gaji13 ?? ""
            })) ?? []
    },

    detailData: {
        id: data?.pekerja_gaji13_id ?? "",
        komponenPayroll: data?.komponen_payroll ?? "",
        periodeGajianId: data?.periode_tahun ?? "",
        periodePerencanaan: data?.periode_tahun ?? "",
        tglSetup: data?.tgl_setup ?? "",
        nik: data?.nip ?? "",
        pekerjaId: data?.pekerja_id ?? "",
        pekerjaNama: data?.pekerja_nama ?? "",
        unitOrganisasiId: data?.master_unit_organisasi_id ?? "",
        unitOrganisasiNama: data?.master_unit_organisasi_nama ?? "",
        jabatanId: data?.master_jabatan_id ?? "",
        jabatanNama: data?.master_jabatan_nama ?? "",
        tglBergabung: data?.tanggal_bergabung ?? "",
        masaKerja: data?.masa_kerja ?? "",
        masaKerjaTampil: data?.masa_kerja_tampil ?? "",
        upahPokok: data?.upah_pokok ?? "",
        tunjanganTetap: data?.tunjangan_tetap ?? "",
        nominal: data?.gaji13 ?? ""
    }
});
