export const formInitialValues = (data = {}) => ({
  id: data?.perintah_lembur_id ?? "",
  tglLembur: data?.tgl_lembur ?? "",
  unitOrganisasiId: data?.master_unit_organisasi_id ?? "",
  unitOrganisasiNama: data?.master_unit_organisasi_nama ?? "",
  nomor: data?.nomor ?? "",
  kompensasiLembur: data?.kompensasi_lembur ?? false,
  detail:
    data?.detail?.map((val) => ({
      nik: val?.nip ?? "",
      pekerjaId: val?.pekerja_id ?? "",
      pekerjaNama: val?.pekerja_nama ?? "",
      tugasLembur: val?.tugas ?? "",
      jamIn: val?.jam_in ?? "",
      jamOut: val?.jam_out ?? ""
    })) ?? []
});
