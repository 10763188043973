import { Services } from "services";
import { fetchDropdownMapper } from "utilities";

const fetchDropdown = fetchDropdownMapper(Services);

class LaporanPajakTahunanApi {
  async getList(params) {
    const fetch = await Services.get("/laporan/pajak-tahunan", { params });
    return fetch?.data?.data;
  }

  //Halaman Depan
  async getPeriodePenggajianHalden() {
    const getPeriodePenggajian = await fetchDropdown("/utility/periode-tahun?feat=pajak-tahunan", {
      value: "periode_tahun",
      label: "periode_tahun"
    });

    return getPeriodePenggajian;
  }
  async getTanggalAwalDanAkhir(params) {
    const fetch = await Services.get("/laporan/laporan-pajak-bulanan/dropdown-tanggal", { params });
    return fetch?.data?.data?.map((val) => ({
      value: val?.tgl_bulan_awal + " s/d " + val?.tgl_bulan_akhir,
      label: val?.tgl_bulan_awal + " s/d " + val?.tgl_bulan_akhir,
      tglAwal: val?.tgl_bulan_awal,
      tglAkhir: val?.tgl_bulan_akhir
    }));
  }
  async getUnitOrganisasiHalden(id) {
    const getUnitOrganisasi = await fetchDropdown("/laporan/pajak-tahunan/dropdown-unit?periode_tahun=" + id, {
      value: "master_unit_organisasi_id",
      label: "master_unit_organisasi_nama"
    });

    return [{ value: undefined, label: "All" }, ...getUnitOrganisasi];
  }
  //Halaman Depan

  async getExport(params) {
    const fetch = await Services.get("/laporan/pajak-tahunan/export", { params });
    return fetch?.data?.data;
  }
}
export default new LaporanPajakTahunanApi();
