import React from "react";
import { useQuery } from "react-query";
import { useFormikContext } from "formik";
import {
  TableList,
  InfoItemVertical,
  numberConvert,
  Select,
  ButtonCreate,
  InputCurrency,
  Input,
  DatePicker,
  dateConvert
} from "components";
import { Row, Col, Card } from "react-bootstrap";
import TunjanganTetapTahunanApi from "../__TunjanganTetapTahunanApi__";

const SectionDataSetup = ({ onSearchButtonClick, onResetClickButton }) => {
  const { values, setValues, errors, touched } = useFormikContext();

  const getUnitOrganisasi = useQuery(["unit-organisasi", "dropdown"], () =>
    TunjanganTetapTahunanApi.getUnitOrganisasi()
  );

  return (
    <>
      <Input disabled label="Periode Penggajian" value={values?.periodePerencanaan} />
      <Select
        label="Unit Organisasi"
        options={getUnitOrganisasi?.data ?? []}
        placeholder="Pilih salah satu..."
        defaultValue={
          values?.unitOrganisasiId
            ? getUnitOrganisasi?.data?.find((item) => item.value === values?.unitOrganisasiId)
            : null
        }
        onChange={(val) => {
          setValues({
            ...values,
            unitOrganisasiId: val.value,
            unitOrganisasiNama: val.label
          });
        }}
        loading={getUnitOrganisasi?.isFetching}
        errorFetch={getUnitOrganisasi.isError}
        error={Boolean(errors.unitOrganisasiId && touched.unitOrganisasiId)}
        errorText={Boolean(errors.unitOrganisasiId && touched.unitOrganisasiId) && errors.unitOrganisasiId}
      />
      <DatePicker
        label="Tanggal Setup"
        placeholderText="Pilih tanggal setup"
        selected={values?.tglSetup}
        onChange={(val) =>
          setValues({
            ...values,
            tglSetup: val
          })
        }
        error={Boolean(errors.tglSetup && touched.tglSetup)}
        errorText={Boolean(errors.tglSetup && touched.tglSetup) && errors.tglSetup}
      />

      <div className="d-flex justify-content-end">
        <div>
          {/* <ButtonCancel text="RESET" className="mb-3 me-2" tooltip={false} onClick={() => onResetClickButton()} /> */}
          <ButtonCreate
            disabled={Boolean(!values?.periodeGajianId)}
            text="CARI"
            className="mb-3"
            tooltip={false}
            onClick={() =>
              onSearchButtonClick({
                active: true,
                data: {
                  periodeGajianId: values?.periodeGajianId,
                  periodePerencanaan: values?.periodePerencanaan,
                  unitOrganisasiId: values?.unitOrganisasiId,
                  unitOrganisasiNama: values?.unitOrganisasiNama,
                  tglSetup: values?.tglSetup
                }
              })
            }
          />
        </div>
      </div>
    </>
  );
};

const TabelDataPekerja = () => {
  const { values, setValues, errors, touched } = useFormikContext();
  const number = numberConvert();
  const date = dateConvert();

  const setNewValues = ({ index, propname, val }) => {
    const detailTemp = [...values?.detail];
    const updatedDetail = detailTemp[index];
    updatedDetail[propname] = val;

    setValues({ ...values, detail: detailTemp });
  };

  const getError = (index, propname) => {
    return Boolean(errors?.detail?.[index]?.[propname] && touched?.detail?.[index]?.[propname]);
  };

  const getErrorText = (index, propname) => {
    return (
      Boolean(errors?.detail?.[index]?.[propname] && touched?.detail?.[index]?.[propname]) &&
      errors?.detail?.[index]?.[propname]
    );
  };

  return (
    <>
      <TableList
        maxHeight={550}
        tableHeader={[
          { text: "Tgl. Setup" },
          { text: "Pekerja" },
          { text: "Jabatan" },
          { text: "Unit Organisasi" },
          { text: "Nominal" }
        ]}
        tableBody={[
          {
            customField: (val) => date.getSlashDMY(new Date(val.tglSetup))
          },
          {
            field: "pekerjaNama"
          },
          {
            field: "jabatanNama"
          },
          {
            field: "unitOrganisasiNama"
          },
          {
            customField: (val, index) => (
              <InputCurrency
                name="nominal"
                placeholder="Masukkan nominal"
                value={"Rp" + number.getWithComa(val?.nominal, { minimumFractionDigits: 2 })}
                onChange={(x) => {
                  setNewValues({ index: index, propname: "checked", val: true });
                  setNewValues({ index: index, propname: "nominal", val: x });
                }}
                error={getError(index, "nominal")}
                errorText={getErrorText(index, "nominal")}
              />
            )
          }
        ]}
        data={values?.detail}
      />
    </>
  );
};

export const Content = ({ action, onSearchButtonClick, onResetClickButton }) => {
  const { values, setValues, errors, touched } = useFormikContext();
  const number = numberConvert();

  const getStatus = useQuery(["staus-upah", "dropdown"], () => TunjanganTetapTahunanApi.getStatus());

  if (action === "READ") {
    return (
      <>
        <InfoItemVertical label="Pekerja" text={values.pekerjaNama} />
        <InfoItemVertical label="Jabatan" text={values.jabatanNama} />
        <InfoItemVertical label="Unit Organisasi" text={values.unitOrganisasiNama} />
        <InfoItemVertical
          label="Nominal"
          text={values.nominal ? "Rp" + number.getWithComa(values?.nominal, { minimumFractionDigits: 2 }) : "-"}
        />
      </>
    );
  }

  if (action === "UPDATE") {
    return (
      <>
        <Input disabled label="Pekerja" value={values?.pekerjaNama} />
        <Input disabled label="Unit Organisasi" value={values?.unitOrganisasiNama} />
        <Input disabled label="Jabatan" value={values?.jabatanNama} />
        <InputCurrency
          name="nominal"
          label="Nominal"
          value={values.nominal ? "Rp" + number.getWithComa(values?.nominal, { minimumFractionDigits: 2 }) : "-"}
          onChange={(val) =>
            setValues({
              ...values,
              nominal: val
            })
          }
          error={Boolean(errors.nominal && touched.nominal)}
          errorText={Boolean(errors.nominal && touched.nominal) && errors.nominal}
        />
        <Select
          label="Status"
          options={getStatus?.data ?? []}
          placeholder="Pilih salah satu..."
          defaultValue={values?.status ? getStatus?.data?.find((item) => item.value === values?.status) : null}
          onChange={(val) => {
            setValues({
              ...values,
              status: val.value
            });
          }}
          loading={getStatus?.isFetching}
          errorFetch={getStatus.isError}
          error={Boolean(errors.status && touched.status)}
          errorText={Boolean(errors.status && touched.status) && errors.status}
        />
      </>
    );
  }

  return (
    <>
      <Row>
        <Col md={4} className="mb-4">
          <Card>
            <Card.Header>
              <b>Setup Data</b>
            </Card.Header>
            <Card.Body>
              <SectionDataSetup onSearchButtonClick={onSearchButtonClick} onResetClickButton={onResetClickButton} />
            </Card.Body>
          </Card>
        </Col>

        <Col md={8}>
          <Card>
            <Card.Header>
              <b>Data Pekerja</b>
            </Card.Header>
            <Card.Body>
              <div>
                <b className="text-danger">Silahkan Input nominal pada data yang ingin disimpan</b>
              </div>
              <TabelDataPekerja />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
