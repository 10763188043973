import { Services } from "services";

class SetupUangLemburKubikasiApi {
    async getList(params) {
        const fetch = await Services.get("/transaksi/pekerja-kubikasi", { params });
        return fetch?.data?.data;
    }

    async getDetail(id) {
        const fetch = await Services.get("/transaksi/pekerja-kubikasi/" + id);
        return fetch?.data?.data;
    }

    async getSetupForm(params) {
        const fetch = await Services.get("/transaksi/pekerja-kubikasi/form", { params });
        return fetch?.data;
    }

    async getUnitOrganisasi(type) {
        const fetch = await Services.get("/master/unit-organisasi/dropdown");
        const dataOrganisasi = fetch?.data?.data.map((val) => ({
            value: val?.master_unit_organisasi_id,
            label: val?.master_unit_organisasi_nama
        }));

        if (type === "FOR_LIST") {
            return [{ value: undefined, label: "Semua" }, ...dataOrganisasi];
        }
        return dataOrganisasi;
    }

    async getUnitOrganisasiForList() {
        const fetch = await Services.get("/transaksi/pekerja-kubikasi/dropdown-unit");
        return fetch?.data?.data.map((val) => ({
            value: val?.master_unit_organisasi_id,
            label: val?.master_unit_organisasi_nama
        }));
    }

    async getPeriode() {
        const fetch = await Services.get("/utility/periode-tahun?feat=pekerja-kubikasi");
        return fetch?.data?.data.map((val) => ({
            value: val?.periode_tahun,
            label: val?.periode_tahun
        }));
    }

    async getStatus() {
        return [
            {
                value: 1,
                label: "Aktif"
            },
            {
                value: 2,
                label: "Tidak Aktif"
            }
        ];
    }

    async getBulan() {
        return Array.from({ length: 12 }, (item, i) => {
            return {
                value: i + 1,
                label: new Date(0, i).toLocaleString("id-ID", { month: "long" })
            };
        });
    }

    async getTanggal(params) {
        const fetch = await Services.get("/transaksi/pekerja-kubikasi/dropdown-tanggal", { params });
        return fetch?.data?.data.map((val) => ({
            value: val?.tgl_bulan_awal + "/&/" + val?.tgl_bulan_akhir,
            label: val?.tgl_bulan_awal + " s/d " + val?.tgl_bulan_akhir,
            tglAwal: val?.tgl_bulan_awal,
            tglAkhir: val?.tgl_bulan_akhir
        }));
    }

    async getExport(params) {
        const fetch = await Services.get("/transaksi/pekerja-kubikasi/export", { params });
        return fetch?.data?.data;
    }

    create(data) {
        return Services.post("/transaksi/pekerja-kubikasi", data);
    }

    update(data, id) {
        return Services.put("/transaksi/pekerja-kubikasi/" + id, data);
    }

    delete(id) {
        return Services.delete("/transaksi/pekerja-kubikasi/" + id);
    }
}

export default new SetupUangLemburKubikasiApi();
