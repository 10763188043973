import { SetupUpahPokokBulananList, SetupUpahPokokBulananCreate } from "pages/Payroll";

export const SetupUpahPokokBulanan = {
  component: <SetupUpahPokokBulananList />,
  link: "setup-upah-pokok-bulanan",
  name: "Setup Upah Pokok Bulanan",
  access: "UPAH_POKOK_BULANAN",
  child: [
    {
      name: "Tambah",
      link: "/tambah",
      component: <SetupUpahPokokBulananCreate />
    }
  ]
};
