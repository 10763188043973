import { Services } from "services";
import { fetchDropdownMapper } from "utilities";

const fetchDropdown = fetchDropdownMapper(Services);
class RekapPresensiApi {
  async getList(params) {
    const fetch = await Services.get("/laporan/rekap-presensi", { params });
    return fetch?.data?.data;
  }
  async getUnitOrganisasi() {
    const fetch = await Services.get("/master/unit-organisasi/dropdown");
    return fetch?.data?.data.map((val) => ({
      value: val?.master_unit_organisasi_id,
      label: val?.master_unit_organisasi_nama
    }));
  }
  async getPeriode() {
    const getPeriodeDropdown = await fetchDropdown("/master/periode-perencanaan/dropdown", {
      value: "master_periode_perencanaan_id",
      label: "periode_perencanaan"
    });
    return [...getPeriodeDropdown];
  }
  async getDetail(id) {
    const fetch = await Services.get("/laporan/rekap-presensi/" + id + "/info");
    return fetch?.data?.data;
  }
  async getDetailWaktu(id, params) {
    const fetch = await Services.get("/laporan/rekap-presensi/" + id + "/info", { params });
    return fetch?.data?.data;
  }
  async getExport(params) {
    const fetch = await Services.get("/laporan/rekap-presensi/export", { params });
    return fetch?.data?.data;
  }
}

export default new RekapPresensiApi();
