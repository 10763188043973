import { dateConvert } from "components";

const date = dateConvert();

export const formInitialValues = (data = {}, filter) => ({
    dataFormSetup: {
        periodeGajianId: data?.data?.info?.periode_tahun ?? filter?.periodeGajianId,
        periodePerencanaan: data?.data?.info?.periode_tahun ?? new Date().getFullYear(),
        tahun: filter?.tahun,
        agamaId: filter?.agamaId,
        agamaNama: filter?.agamaNama,
        bulan: filter?.bulan,
        tglAwal: filter?.tglAwal,
        tglAkhir: filter?.tglAkhir,
        tglSetup: filter?.tglSetup ?? new Date(),
        statusPekerja: data?.data?.info?.statusPekerja ?? filter?.statusPekerja,
        unitOrganisasiId: filter?.unitOrganisasiId ?? "",
        unitOrganisasiNama: filter?.unitOrganisasiNama ?? "",
        persentase: false,
        detail:
            data?.data?.map((val) => ({
                checked: false,
                periodeGajianId: val?.periode_tahun ?? "",
                tglSetup: filter?.tglSetup ?? date.getDashYMD(new Date()),
                tglAwal: filter?.tglAwal,
                tglAkhir: filter?.tglAkhir,
                nik: val?.nip ?? "",
                pekerjaId: val?.pekerja_id ?? "",
                pekerjaNama: val?.pekerja_nama ?? "",
                unitOrganisasiId: val?.master_unit_organisasi_id ?? "",
                unitOrganisasiNama: val?.master_unit_organisasi_nama ?? "",
                jabatanId: val?.master_jabatan_id ?? "",
                jabatanNama: val?.master_jabatan_nama ?? "",
                agamaId: val?.master_agama_id ?? "",
                agamaNama: val?.agama ?? "",
                upahPokok: val?.upah_pokok ?? "",
                tunjanganTetap: val?.tunjangan_tetap ?? "",
                persentase: val?.persentase ?? "",
                nominal: val?.nominal ?? "",
                tahun: filter?.tahun,
                tglPembayaran: filter?.tglSetup ?? new Date(),
                persentaseSudah: val?.persentase_terbayarkan,
                nominalSudahDibayar: val?.nominal_terbayarkan,
                persentaseTotal: val?.persentase_terbayarkan
            })) ?? []
    },

    detailData: {
        id: data?.pekerja_thr_bulanan_id ?? "",
        komponenPayroll: data?.komponen_payroll ?? "",
        periodeGajianId: data?.periode_tahun ?? "",
        periodePerencanaan: data?.periode_tahun ?? "",
        tglSetup: data?.tgl_setup ?? "",
        nik: data?.nip ?? "",
        pekerjaId: data?.pekerja_id ?? "",
        pekerjaNama: data?.pekerja_nama ?? "",
        unitOrganisasiId: data?.master_unit_organisasi_id ?? "",
        unitOrganisasiNama: data?.master_unit_organisasi_nama ?? "",
        jabatanId: data?.master_jabatan_id ?? "",
        jabatanNama: data?.master_jabatan_nama ?? "",
        agamaId: data?.master_agama_id ?? "",
        agamaNama: data?.agama ?? "",
        upahPokok: data?.upah_pokok ?? "",
        tunjanganTetap: data?.tunjangan_tetap ?? "",
        persentase: data?.persentase ?? "",
        nominal: data?.nominal ?? ((parseFloat(data?.tunjangan_tetap) + parseFloat(data?.upah_pokok)) * (data?.persentase / 100)),
        persentaseBelum: (100 - data?.persentase),
        nominalBelumDibayar: ((parseFloat(data?.tunjangan_tetap) + parseFloat(data?.upah_pokok)) * ((100 - data?.persentase) / 100))
    }
});
