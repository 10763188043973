export const formInitialValues = (data = {}, action) => ({
  update: action === "UPDATE" ? true : false,
  id: data?.master_jabatan_id,
  nama: data?.master_jabatan_nama,
  kode: data?.master_jabatan_kode,

  jenis_jabatan_nama: data?.jenis_jabatan,
  jenis_jabatan_ket: data?.jenis_jabatan === 1 ? "STRUKTURAL" : "FUNGSIONAL",

  kelompok_jabatan_nama: data?.master_kelompok_jabatan_nama,
  kelompok_jabatan_id: data?.master_kelompok_jabatan_id,

  job_stream_nama: data?.master_job_stream_nama,
  job_stream_id: data?.master_job_stream_id,

  jenis_profesi_nama: data?.master_jenis_profesi_nama,
  jenis_profesi_id: data?.master_jenis_profesi_id,

  unit_organisasi_nama: data?.master_unit_organisasi_nama,
  unit_organisasi_id: data?.master_unit_organisasi_id,

  atasan_langsung_nama: data?.atasan_langsung_nama,
  atasan_langsung_id: data?.atasan_langsung
});
