import * as Yup from "yup";

export const formValidationSchema = Yup.object().shape({
  periodeGajianId: Yup.string().required("Periode gajian wajib diisi"),
  tglSetup: Yup.string().required("Tanggal setup wajib diisi"),
  proporsiYadapen: Yup.string().required("Proporsi yadapen wajib diisi"),
  status: Yup.string().required("Status wajib diisi"),

  keterangan: Yup.string().required("Keterangan wajib diisi")
});
