export const formInitialValues = (data = {}) => {
  const dateDiff = new Date(data?.tgl_akhir).getTime() - new Date(data?.tgl_mulai).getTime();
  const dayDiff = Math.round(dateDiff / (1000 * 3600 * 24)) + 1;

  const obj = {
    id: data?.surat_perjalanan_dinas_id ?? "",
    periodeGajianId: data?.periode_tahun ?? new Date().getFullYear(),
    periodePerencanaan: data?.periode_tahun ?? new Date().getFullYear(),
    tglInput: data?.tgl_input ?? new Date(),
    unitOrganisasiId: data?.master_unit_organisasi_id ?? "",
    unitOrganisasiNama: data?.master_unit_organisasi_nama ?? "",
    pekerjaId: data?.pekerja_id ?? "",
    pekerjaNama: data?.pekerja_nama ?? "",
    jabatanId: data?.master_jabatan_id ?? "",
    jabatanNama: data?.master_jabatan_nama ?? "",
    nomor: data?.nomor ?? "",
    tujuan: data?.tujuan ?? "",
    tglMulai: data?.tgl_mulai ?? "",
    tglAkhir: data?.tgl_akhir ?? "",
    lamaPenugasan: dayDiff
  };

  return obj;
};
