import { Services } from "services";
import { fetchDropdownMapper } from "utilities";

const fetchDropdown = fetchDropdownMapper(Services);

class StatusPajakPekerjaApi {
  async getList(params) {
    const fetch = await Services.get("/transaksi/status-pajak-pekerja", { params });
    return fetch?.data?.data;
  }
  async getPekerja(params) {
    const fetch = await Services.get("/transaksi/status-pajak-pekerja/dropdown-pekerja", { params });
    return fetch?.data?.data?.map((val) => ({
      value: val?.pekerja_id,
      label: val?.pekerja_nama,
      jabatanLabel: val?.master_jabatan_nama,
      npwpLabel: val?.no_npwp
    }));
  }
  async getStatusPekerja(params) {
    const fetch = await Services.get("/master/tarif-ptkp/dropdown", { params });
    return fetch?.data?.data?.map((val) => ({
      value: val?.tarif_ptpk_id,
      label: val?.status_pajak
    }));
  }
  async getPeriode() {
    const getPeriodeDropdown = await fetchDropdown("/utility/periode-tahun?feat=status-pajak-pekerja", {
      value: "periode_tahun",
      label: "periode_tahun"
    });
    return [...getPeriodeDropdown];
  }
  async getUnitOrganisasi() {
    const getUnitOrganisasi = await fetchDropdown("/master/unit-organisasi/dropdown", {
      value: "master_unit_organisasi_id",
      label: "master_unit_organisasi_nama"
    });
    return [...getUnitOrganisasi];
  }
  async getUnitOrganisasiForList(params) {
    const fetch = await Services.get("/transaksi/status-pajak-pekerja/dropdown-unit", { params });
    return fetch?.data?.data.map((val) => ({
      value: val?.master_unit_organisasi_id,
      label: val?.master_unit_organisasi_nama
    }));
  }

  async getDetail(id) {
    const fetch = await Services.get("/transaksi/status-pajak-pekerja" + id);
    return fetch?.data?.data;
  }
  async getExport(params) {
    const fetch = await Services.get("/transaksi/status-pajak-pekerja/export", { params });
    return fetch?.data?.data;
  }
  create(data) {
    return Services.post("/transaksi/status-pajak-pekerja", data);
  }
  update(data, id) {
    return Services.put("/transaksi/status-pajak-pekerja/" + id, data);
  }
  delete(id) {
    return Services.delete("/transaksi/status-pajak-pekerja/" + id);
  }
}

export default new StatusPajakPekerjaApi();
