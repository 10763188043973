import {
  ButtonCreate, ButtonDelete, dateConvert, DatePicker, InfoItemVertical, Input, Select, TableList, TextArea
} from "components";
import { Formik, useFormikContext } from "formik";
import { useModalConfirm } from "hooks";
import { ButtonGroup, Card, Col, Row } from "react-bootstrap";
import { useQuery } from "react-query";
import * as Yup from "yup";
import PermohonanIzinApi from "../__PermohonanIzinApi__";

const ContentTanggal = ({ action, onDelete }) => {
  const modalConfirm = useModalConfirm();
  const date = dateConvert();
  const { values } = useFormikContext();

  return (
    <TableList
      tableHeader={[
        {
          text: "Tanggal Izin"
        },
        {
          text: "Aksi",
          props: {
            width: 50,
            fixed: true,
            style: { display: action === "READ" ? "none" : "" }
          }
        }
      ]}
      tableBody={[
        {
          customField: (val) => date.getSlashDMY(new Date(val.tglIzin))
        },

        {
          props: {
            fixed: true,
            style: { display: action === "READ" && "none" }
          },
          customField: (val, index) => (
            <ButtonGroup size="sm" className="mx-1">
              {action !== "READ" && (
                <>
                  <ButtonDelete
                    icon
                    noText
                    tooltip={false}
                    onClick={() =>
                      modalConfirm.trigger({
                        type: "delete",
                        alert: true,
                        customAlertText: "Data yang terhapus tidak dapat dikembalikan",
                        onHide: modalConfirm.close,
                        onSubmit: () => {
                          modalConfirm.loading();
                          onDelete(index);
                          modalConfirm.close();
                        },
                        component: (
                          <>
                            <InfoItemVertical label="Tgl Izin" text={val.tglIzin} />
                          </>
                        )
                      })
                    }
                  />
                </>
              )}
            </ButtonGroup>
          )
        }
      ]}
      data={values?.detail}
    />
  );
};

export const Content = ({ action }) => {
  const { values, setValues, errors, touched, setFieldValue, handleChange } = useFormikContext();
  const date = dateConvert();

  const getUnitOrganisasi = useQuery(["unit-organisasi", "dropdown"], () => PermohonanIzinApi.getUnitOrganisasi());
  const getJenisIzin = useQuery(["jenis-izin", "dropdown"], () => PermohonanIzinApi.getJenisIzin());

  const getPekerja = useQuery(["pekerja", "dropdown", values?.unitOrganisasiId], () =>
    PermohonanIzinApi.getPekerja({
      master_unit_organisasi_id: values?.unitOrganisasiId
    })
  );

  const createHandler = (obj) => {
    let newValue = [...values?.detail];

    setFieldValue("detail", [...newValue, { ...obj }]);
  };

  const deleteHandler = (index) => {
    let newValue = [...values?.detail].filter((_v, i) => index !== i);

    setFieldValue("detail", newValue);
  };

  if (action === "READ") {
    return (
      <>
        <Row>
          <Col>
            <InfoItemVertical label="Tanggal" text={date.getSlashDMY(new Date(values?.tglInput))} />
          </Col>
          <Col>
            <InfoItemVertical label="Nomor" text={values?.nomor} />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <div className="mt-4">
              <p>Dengan hormat</p>
              <p>Saya yang bertanda tangan dibawah ini</p>
            </div>
            <InfoItemVertical label="Unit Organisasi" text={values?.unitOrganisasiNama} />
            <InfoItemVertical label="Pekerja" text={values?.pekerjaNama} />
            <InfoItemVertical label="Jabatan" text={values?.jabatanNama} />
            <div className="mt-2">
              <p>Bermaksud mengajukan izin untuk keperluan</p>
            </div>
            <InfoItemVertical label="Jenis Izin" text={values?.jenisIzinNama} />
            <InfoItemVertical label="Tujuan" text={values?.tujuan} />
            <InfoItemVertical label="Selama" text={values?.selama} />
            <ContentTanggal action={action} onDelete={deleteHandler} />
            <div className="mt-4">
              <p>Atas perhatian dan diberikannya permohonan ijin saya ini</p>
              <p>Saya ucapkan banyak terima kasih</p>
            </div>
            <InfoItemVertical label="Catatan" text={values?.catatan} />
          </Col>
        </Row>
      </>
    );
  }

  return (
    <>
      <Row>
        <Col>
          <Card style={{ height: "100%" }}>
            <Card.Header>
              <b>Surat Permohonan Izin</b>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <DatePicker
                    disabled={Boolean(action !== "CREATE")}
                    label="Tanggal"
                    placeholderText="Pilih tanggal input permohonan izin"
                    selected={values?.tglInput}
                    onChange={(val) =>
                      setValues({
                        ...values,
                        tglInput: val
                      })
                    }
                    error={Boolean(errors.tglInput && touched.tglInput)}
                    errorText={Boolean(errors.tglInput && touched.tglInput) && errors.tglInput}
                  />
                </Col>
                <Col>
                  <Input
                    name="nomor"
                    disabled={Boolean(action !== "CREATE")}
                    label="Nomor"
                    placeholder="Masukkan nomor surat"
                    value={values?.nomor}
                    onChange={handleChange}
                    error={Boolean(errors.nomor && touched.nomor)}
                    errorText={Boolean(errors.nomor && touched.nomor) && errors.nomor}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div className="mt-4">
                    <p>Dengan hormat</p>
                    <p>Saya yang bertanda tangan dibawah ini</p>
                  </div>

                  <Select
                    disable={Boolean(action !== "CREATE")}
                    label="Unit Organisasi"
                    placeholder="Pilih unit organisasi"
                    defaultValue={getUnitOrganisasi?.data?.find((item) => item.value === values.unitOrganisasiId)}
                    options={getUnitOrganisasi?.data ?? []}
                    onChange={(val) =>
                      setValues({
                        ...values,
                        unitOrganisasiId: val.value,
                        unitOrganisasiNama: val.label
                      })
                    }
                    loading={getUnitOrganisasi.isFetching}
                    errorFetch={getUnitOrganisasi.isError}
                    error={Boolean(errors.unitOrganisasiId && touched.unitOrganisasiId)}
                    errorText={Boolean(errors.unitOrganisasiId && touched.unitOrganisasiId) && errors.unitOrganisasiId}
                  />
                  <Select
                    label="Pekerja"
                    placeholder="Pilih pekerja"
                    defaultValue={getPekerja?.data?.find((item) => item.value === values.pekerjaId)}
                    options={getPekerja?.data ?? []}
                    onChange={(val) =>
                      setValues({
                        ...values,
                        pekerjaId: val.value,
                        pekerjaNama: val.label,
                        jabatanId: val.jabatanId,
                        jabatanNama: val.jabatanNama
                      })
                    }
                    loading={getPekerja.isFetching}
                    errorFetch={getPekerja.isError}
                    error={Boolean(errors.pekerjaId && touched.pekerjaId)}
                    errorText={Boolean(errors.pekerjaId && touched.pekerjaId) && errors.pekerjaId}
                  />
                  <Input
                    name="jabatan"
                    disabled
                    label="Jabatan"
                    placeholder="Pilih pekerja"
                    value={values?.jabatanNama}
                    onChange={handleChange}
                    error={Boolean(errors.jabatanId && touched.jabatanId)}
                    errorText={Boolean(errors.jabatanId && touched.jabatanId) && errors.jabatanId}
                  />
                  <div className="mt-2">
                    <p>Bermaksud mengajukan izin untuk keperluan</p>
                  </div>
                  <Select
                    label="Jenis Izin"
                    placeholder="Pilih jenis izin"
                    defaultValue={getJenisIzin?.data?.find((item) => item.value === values.jenisIzinId)}
                    options={getJenisIzin?.data ?? []}
                    onChange={(val) =>
                      setValues({
                        ...values,
                        jenisIzinId: val.value,
                        jenisIzinNama: val.label
                      })
                    }
                    loading={getJenisIzin.isFetching}
                    errorFetch={getJenisIzin.isError}
                    error={Boolean(errors.jenisIzinId && touched.jenisIzinId)}
                    errorText={Boolean(errors.jenisIzinId && touched.jenisIzinId) && errors.jenisIzinId}
                  />
                  <Input
                    name="tujuan"
                    label="Tujuan"
                    placeholder="Masukkan tujuan"
                    value={values?.tujuan}
                    onChange={handleChange}
                    error={Boolean(errors.tujuan && touched.tujuan)}
                    errorText={Boolean(errors.tujuan && touched.tujuan) && errors.tujuan}
                  />
                  <Input
                    name="selama"
                    label="Selama"
                    placeholder="Masukkan lama waktu izin"
                    value={values?.selama}
                    onChange={handleChange}
                    error={Boolean(errors.selama && touched.selama)}
                    errorText={Boolean(errors.selama && touched.selama) && errors.selama}
                  />

                  {action !== "READ" ? (
                    <Formik
                      enableReinitialize
                      initialValues={{
                        tglIzin: ""
                      }}
                      validationSchema={Yup.object().shape({
                        tglIzin: Yup.string().required("Tanggal izin wajib diisi")
                      })}
                      onSubmit={(val, { resetForm }) => {
                        createHandler(val);
                        resetForm();
                      }}
                    >
                      {({ values, errors, touched, setValues, handleSubmit }) => (
                        <>
                          <div className="d-flex justify-content-start gap-2">
                            <DatePicker
                              label="Tanggal Izin"
                              placeholderText="Pilih tanggal izin"
                              selected={values?.tglIzin}
                              onChange={(val) =>
                                setValues({
                                  ...values,
                                  tglIzin: val
                                })
                              }
                              error={Boolean(errors.tglIzin && touched.tglIzin)}
                              errorText={Boolean(errors.tglIzin && touched.tglIzin) && errors.tglIzin}
                            />

                            <div className="mt-4">
                              <ButtonCreate
                                icon={true}
                                noText
                                tooltip={false}
                                className="mb-3"
                                onClick={handleSubmit}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </Formik>
                  ) : (
                    <></>
                  )}
                  <ContentTanggal action={action} onDelete={deleteHandler} />

                  <div className="mt-4">
                    <p>Atas perhatian dan diberikannya permohonan ijin saya ini</p>
                    <p>Saya ucapkan banyak terima kasih</p>
                  </div>
                  <TextArea
                    label="Catatan"
                    name="catatan"
                    placeholder="Masukkan catatan"
                    value={values.catatan}
                    onChange={(val) =>
                      setValues({
                        ...values,
                        catatan: val.target.value
                      })
                    }
                    error={Boolean(errors.catatan && touched.catatan)}
                    errorText={Boolean(errors.catatan && touched.catatan) && errors.catatan}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
