import React from "react";
import { useFormikContext } from "formik";
import { Input, InfoItemVertical } from "components";

export const Content = ({ action }) => {
  const { values, errors, touched, handleChange } = useFormikContext();

  if (action === "READ") {
    return (
      <>
        <InfoItemVertical label="Nama Jenis Kelamin" text={values.nama} />
      </>
    );
  }

  return (
    <>
      <Input
        label="Nama Jenis Kelamin"
        name="nama"
        placeholder="Masukkan nama"
        value={values.nama}
        onChange={handleChange}
        error={Boolean(errors.nama && touched.nama)}
        errorText={Boolean(errors.nama && touched.nama) && errors.nama}
      />
    </>
  );
};
