import { getFileLink } from "utilities";

export const formInitialValues = (data = {}) => ({
  id: data?.pekerja_phk_id ?? "",
  pekerjaNama: data?.pekerja_nama ?? "",
  pekerjaId: data?.pekerja_id ?? "",
  nip: data?.nip ?? "",
  tglSetup: data?.tgl_setup ?? "",
  unitOrganisasiId: data?.master_unit_organisasi_id ?? "",
  unitOrganisasiNama: data?.master_unit_organisasi_nama ?? "",
  tglPengangkatan: data?.tgl_pengangkatan ?? "",
  periodePenggajianId: data?.periode_tahun ?? "",
  periodePenggajian: data?.periode_tahun ?? "",
  jabatanNama: data?.master_jabatan_nama ?? "",

  alasanPhk: data?.alasan_phk ?? "",
  alasanPhkId: data?.master_alasan_phk_id ?? "",
  tglPemberhentian: data?.tgl_pemberhentian ?? "",
  keterangan: data?.keterangan ?? "",
  masaKerja: data?.masa_kerja ?? "",

  suratKeputusan: data?.surat_keputusan ?? "",
  suratKeterangan: data?.surat_keterangan ?? "",

  fileSuratKeputusan: data?.fileSuratKeputusan ?? data?.file_surat_keputusan ?? undefined,
  fileSuratKeputusanFull:
    data?.fileSuratKeputusanFull ?? data?.file_surat_keputusan
      ? getFileLink({ link: data?.fileSuratKeputusan ?? data?.file_surat_keputusan, temporary: false })
      : undefined,
  fileSuratKeterangan: data?.fileSuratKeterangan ?? data?.file_surat_keterangan ?? undefined,
  fileSuratKeteranganFull:
    data?.fileSuratKeteranganFull ?? data?.file_surat_keterangan
      ? getFileLink({ link: data?.fileSuratKeterangan ?? data?.file_surat_keterangan, temporary: false })
      : undefined
});
