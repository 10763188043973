import { ListLayout } from "layouts";
import { debounce } from "lodash";
import { useState } from "react";
import { useQuery } from "react-query";
import { errorFetchingTableMapper, numberConvert } from "utilities";
import GradeApi from "./__GradeApi__";

export const GradeList = () => {
  // const navigate = useNavigate();
  const number = numberConvert();
  const [searchKey, setSearchKey] = useState("");

  const getGrade = useQuery(["grade", "list", searchKey], () => GradeApi.get({ q: searchKey }));

  const searchHandler = (e) => {
    setSearchKey(e?.target?.value ? e.target.value : undefined);
  };

  const TABLE_HEADER = [
    {
      text: "Grade"
    },
    {
      text: "JND"
    },
    {
      text: "Nilai Terendah Evaluasi Jabatan"
    },
    {
      text: "Nilai Tengah Evaluasi Jabatan"
    },
    {
      text: "Nilai Tertinggi Evaluasi Jabatan"
    }
  ];

  const TABLE_BODY = [
    {
      customField: (data) => data?.grade?.toString()
    },
    {
      customField: (data) => `${number.getWithComa(parseInt(data?.jnd), { minimumFractionDigits: 0 })}%`,
      props: { textRight: true }
    },
    {
      customField: (data) => number.getWithComa(parseInt(data?.nilai_terendah ?? 0), { minimumFractionDigits: 0 }),
      props: { textRight: true }
    },
    {
      customField: (data) => number.getWithComa(parseInt(data?.nilai_tengah ?? 0), { minimumFractionDigits: 0 }),
      props: { textRight: true }
    },
    {
      customField: (data) => number.getWithComa(parseInt(data?.nilai_tertinggi ?? 0), { minimumFractionDigits: 0 }),
      props: { textRight: true }
    }
  ];

  return (
    <>
      {/**
       * List Layout:
       * Menampilkan nama menu, breadcrumb, search input, create & export button, tabel dll
       * search section & button section dapat direplace menggunakan props customLeftTopSection, customRightTopSection dll
       */}
      <ListLayout
        showSearch={false}
        data={getGrade.data?.data}
        loading={getGrade.isFetching}
        error={getGrade.isError}
        errorText={errorFetchingTableMapper(getGrade.error)}
        tableHeader={TABLE_HEADER}
        tableBody={TABLE_BODY}
        dataTotal={getGrade?.data?.data_count}
        onSearchChange={debounce(searchHandler, 1500)}
        pagination={false}
        customRightTopSection={() => (
          <></>
        )}
      />
    </>
  );
};
