import { Formik, useFormikContext } from "formik";
import { ButtonGroup, Col, Row } from "react-bootstrap";
import { TableList, ButtonDelete, Input, ButtonCreate } from "components";
import { useMutation } from "react-query";
import { useModalConfirm } from "hooks";
import UraianJabatanApi from "../../__UraianJabatanApi__";
import { formSubmitMapper, formValidationSchema } from "../../__UraianJabatanUtils__";
import { errorSubmitMapper } from "utilities";
import { useParams } from "react-router-dom";

const SectionForm = () => {
  const { setFieldValue, values } = useFormikContext();

  const formSubmitHandler = (data) => {
    setFieldValue("detail", [{ ...data }, ...values.detail]);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        hubunganKerjaNama: ""
      }}
      validationSchema={formValidationSchema.hubunganKerja}
      onSubmit={(values) => {
        formSubmitHandler(values);
      }}
    >
      {({ setFieldValue, errors, touched, handleSubmit }) => {
        return (
          <form>
            <Row>
              <Col lg="6">
                <Input
                  label="Hubungan Kerja*"
                  name="hubunganKerjaNama"
                  placeholder="Masukan hubungan kerja"
                  onChange={(val) => setFieldValue("hubunganKerjaNama", val.target.value)}
                  error={Boolean(errors.hubunganKerjaNama && touched.hubunganKerjaNama)}
                  errorText={Boolean(errors.hubunganKerjaNama && touched.hubunganKerjaNama) && errors.hubunganKerjaNama}
                />
              </Col>
              <Col lg="6">
                <ButtonCreate
                  icon
                  noText
                  className="mt-4"
                  onClick={() => {
                    handleSubmit();
                  }}
                />
              </Col>
            </Row>
          </form>
        );
      }}
    </Formik>
  );
};

const TableSection = ({ action }) => {
  const { values, setValues } = useFormikContext();
  const modalConfirm = useModalConfirm();

  return (
    <TableList
      tableHeader={[
        {
          text: "Hubungan Kerja",
          props: {
            minWidth: 200
          }
        },
        {
          text: "Aksi",
          props: { fixed: true, width: 50, className: action === "READ" ? "d-none" : "" }
        }
      ]}
      data={values?.detail}
      className="mt-3"
      tableBody={[
        {
          field: "hubunganKerjaNama"
        },
        {
          props: { fixed: true, className: action === "READ" ? "d-none" : "" },
          customField: (data, index) => (
            <ButtonGroup size="sm">
              {!Boolean(action === "READ") && (
                <ButtonDelete
                  icon
                  noText
                  onClick={() => {
                    modalConfirm.trigger({
                      type: "delete",
                      data: [{ label: "Hubungan Kerja", text: data.hubunganKerjaNama }],
                      onSubmit: () => {
                        const deletedData = values?.detail?.filter((val, ind) => ind !== index);
                        setValues({ detail: deletedData });

                        modalConfirm.close();
                      },
                      onHide: modalConfirm.close
                    });
                  }}
                />
              )}
            </ButtonGroup>
          )
        }
      ]}
    />
  );
};

export const TabHubunganKerja = ({ action }) => {
  const modalConfirm = useModalConfirm();
  const { id } = useParams();
  const parentFormik = useFormikContext();

  const updateHubunganKerja = useMutation((data) => UraianJabatanApi.update(data, id), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "update", customTextInfoTitle: "Data Berhasil Disimpan" });
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "update", ...errorSubmitMapper(err) })
  });

  const submitValidationHandler = (errors) =>
    new Promise((resolve, reject) => {
      const getError = Object.values(errors);

      if (getError.length > 0) {
        reject(getError);
      } else {
        resolve();
      }
    });

  const preSubmitHandler = (values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting) => {
    setSubmitting(true);
    validateForm().then(async (err) => {
      setErrors(err);
      setTouched(err);

      await submitValidationHandler(err, values)
        .then(() => {
          handleSubmit();
        })
        .catch((err) =>
          modalConfirm.trigger({
            type: "error",
            title: "Data Tidak Lengkap",
            data: err,
            onHide: () => modalConfirm.close()
          })
        )
        .finally(() => {
          setSubmitting(false);
        });
    });
  };

  const formSubmitHandler = (values) =>
    modalConfirm.trigger({
      type: "custom",
      onHide: modalConfirm.close,
      title: "Konfirmasi",
      customButtonShow: true,
      customButtonText: "Simpan",
      customButtonVariant: "primary",
      customTextHeader: "Apakah anda yakin menyimpan data ini?",
      onSubmit: () => {
        modalConfirm.loading();

        updateHubunganKerja.mutate(formSubmitMapper().hubunganKerja(values));
      },

      component: (
        <>
          <Formik initialValues={values}>
            <TableSection action="READ" />
          </Formik>
        </>
      )
    });

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={parentFormik?.values?.hubunganKerja}
        onSubmit={(values) => {
          formSubmitHandler(values, action);
        }}
      >
        {({ values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting }) => {
          return (
            <>
              {action === "READ" ? (
                <TableSection action="READ" />
              ) : (
                <>
                  <SectionForm />
                  <TableSection />
                  <div className="d-flex justify-content-end mb-2 mt-3">
                    <ButtonCreate
                      text="Simpan"
                      onClick={() => {
                        preSubmitHandler(values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting);
                      }}
                      disabled={values?.detail?.length < 1}
                    />
                  </div>
                </>
              )}
            </>
          );
        }}
      </Formik>
    </>
  );
};
