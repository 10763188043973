import {
  ButtonDetail, ButtonFilter, ButtonUpdate, dateConvert, InfoItemVertical, InputSearch, ModalFilter, numberConvert,
  Select, toastTrigger
} from "components";
import { Formik, useFormikContext } from "formik";
import { useAccessRights } from "hooks";
import { ListLayout } from "layouts";
import { debounce } from "lodash";
import { useState } from "react";
import { ButtonGroup } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { exportHandler } from "utilities";
import TunjanganUangLemburKubikasiApi from "./__TunjanganUangLemburKubikasiApi__";

const ModalFilterBody = () => {
  const { values, setValues } = useFormikContext();

  const getPeriode = useQuery(["periode-penggajian", "dropdown"], () => TunjanganUangLemburKubikasiApi.getPeriode());

  const getTanggal = useQuery(
    ["tanggal-penggajian", "dropdown", values?.periodeGajianId],
    () =>
      TunjanganUangLemburKubikasiApi.getTanggal({
        periode_tahun: values?.periodeGajianId
      }),
    {
      enabled: Boolean(values?.periodeGajianId)
    }
  );

  const getUnitOrganisasi = useQuery(
    ["unit-organisasi-for-list", "dropdown", values?.periodeGajianId],
    () =>
      TunjanganUangLemburKubikasiApi.getUnitOrganisasiForList({
        periode_tahun: values?.periodeGajianId
      }),
    { enabled: Boolean(values?.periodeGajianId) }
  );

  return (
    <>
      <Select
        label="Periode Penggajian"
        placeholder="Pilih periode penggajian"
        defaultValue={getPeriode?.data?.find((item) => item.value === values?.periodeGajianId)}
        options={getPeriode?.data ?? []}
        onChange={(val) => setValues({ ...values, periodeGajianId: val.value, periodePerencanaan: val.label })}
        loading={getPeriode.isFetching}
        errorFetch={getPeriode.isError}
      />
      <Select
        disable={Boolean(!values?.periodeGajianId)}
        label="Tanggal Penggajian"
        placeholder="Pilih tanggal penggajian"
        defaultValue={getTanggal?.data?.find((item) => item.value === values?.tglId)}
        options={getTanggal?.data ?? []}
        onChange={(val) =>
          setValues({
            ...values,
            tglId: val.value,
            tglNama: val.label,
            tglAwal: val.tglAwal,
            tglAkhir: val.tglAkhir
          })
        }
        loading={getTanggal.isFetching}
        errorFetch={getTanggal.isError}
      />
      <Select
        disable={Boolean(!values?.periodeGajianId)}
        label="Unit Organisasi"
        placeholder="Pilih unit organisasi"
        defaultValue={getUnitOrganisasi?.data?.find((item) => item.value === values.unitOrganisasiId)}
        options={getUnitOrganisasi?.data ?? []}
        onChange={(val) => setValues({ ...values, unitOrganisasiId: val.value })}
        loading={getUnitOrganisasi.isFetching}
        errorFetch={getUnitOrganisasi.isError}
      />
    </>
  );
};

export const TunjanganUangLemburKubikasiList = () => {
  const access = useAccessRights("PEKERJA_KUBIKASI_BULANAN");
  const navigate = useNavigate();
  const number = numberConvert();
  // const modalConfirm = useModalConfirm();
  const date = dateConvert();

  const [searchKey, setSearchKey] = useState(undefined);
  const [pagination, setPagination] = useState({
    pageCurrent: 1,
    dataLength: 10
  });

  const [filter, setFilter] = useState({
    show: false,
    active: false,
    data: {
      periodeGajianId: undefined,
      unitOrganisasiId: undefined,
      tglAwal: undefined,
      tglAkhir: undefined
    }
  });

  const getList = useQuery(
    [
      "tunjangan-uang-lembur-kubikasi",
      "list",
      searchKey,
      pagination.pageCurrent,
      pagination.dataLength,
      filter.data?.periodeGajianId,
      filter.data?.unitOrganisasiId,
      filter.data?.tglAwal,
      filter.data?.tglAkhir
    ],
    () =>
      TunjanganUangLemburKubikasiApi.getList({
        search: searchKey,
        page: pagination.pageCurrent,
        limit: pagination.dataLength,
        periode_tahun: filter.data?.periodeGajianId,
        master_unit_organisasi_id: filter.data?.unitOrganisasiId,
        tgl_bulan_awal: filter.data?.tglAwal,
        tgl_bulan_akhir: filter.data?.tglAkhir
      }),
    {
      enabled: Boolean(
        filter?.data?.periodeGajianId &&
        filter?.data?.unitOrganisasiId &&
        filter?.data?.tglAwal &&
        filter?.data?.tglAkhir
      )
    }
  );

  // const deleteTunjanganUangLemburKbikasi = useMutation((id) => TunjanganUangLemburKubikasiApi.delete(id), {
  //   onSuccess: () => {
  //     modalConfirm.infoSuccess({ typeInfo: "delete" });
  //     getList.refetch();
  //   },
  //   onError: (err) => modalConfirm.infoError({ typeInfo: "delete", ...errorSubmitMapper(err) })
  // });

  const getTunjanganUangLemburKubikasiExport = useMutation(
    (data) =>
      TunjanganUangLemburKubikasiApi.getExport({
        master_unit_organisasi_id: data?.unitOrganisasiId,
        periode_tahun: data?.periodeGajianId,
        master_unit_organisasi_id: data?.unitOrganisasiId,
        tgl_bulan_awal: data?.tglAwal,
        tgl_bulan_akhir: data?.tglAkhir
      }),
    {
      onSuccess: (link) => {
        toastTrigger({ variant: "success", message: "Export berhasil" });
        exportHandler(link, "Data Tunjangan Uang Lembur Kubikasi");
      },
      onError: () => {
        toastTrigger({ variant: "danger", message: "Export gagal" });
      }
    }
  );

  const onCreateButtonClickHandler = () => navigate("tambah");
  const onUpdateButtonClickHandler = (data) => navigate("ubah/" + data.pekerja_kubikasi_bulanan_id);
  const onDetailButtonClickHandler = (data) => navigate("detail/" + data.pekerja_kubikasi_bulanan_id);

  const onPaginationChange = (data) => setPagination({ ...pagination, pageCurrent: data });

  const onPaginationDataLengthChange = (data) => setPagination({ ...pagination, dataLength: data });

  const searchHandler = (e) => {
    setSearchKey(e?.target?.value ? e.target.value : undefined);
  };

  // const submitValidationHandler = (errors) =>
  //   new Promise((resolve, reject) => {
  //     const getError = Object.values(errors);

  //     if (getError.length > 0) {
  //       reject(getError);
  //     } else {
  //       resolve();
  //     }
  //   });

  const dataInfo = getList?.data?.info;

  const TABLE_HEADER = [
    {
      text: "NIK"
    },
    {
      text: "Pekerja"
    },
    {
      text: "Unit Organisasi"
    },
    {
      text: "Jabatan"
    },
    {
      text: "Uang Kubikasi"
    },
    {
      text: "Aksi",
      props: {
        fixed: true
      }
    }
  ];

  const TABLE_BODY = [
    { field: "nip" },
    { field: "pekerja_nama" },
    { field: "master_unit_organisasi_nama" },
    { field: "master_jabatan_nama" },
    {
      props: { textRight: true },
      customField: (val) => "Rp" + number.getWithComa(val?.total_uang_kubikasi, { minimumFractionDigits: 2 })
    },
    {
      props: {
        width: 50,
        fixed: true,
        textCenter: true
      },
      customField: (data) => (
        <ButtonGroup size="sm">
          {access.canRead() && (
            <ButtonDetail icon tooltip={false} noText onClick={() => onDetailButtonClickHandler(data)} />
          )}
          {access.canUpdate() && (
            <ButtonUpdate tooltip={false} icon noText onClick={() => onUpdateButtonClickHandler(data)} />
          )}
          {/* {access.canDelete() && (
                    <ButtonDelete
                        tooltip={false}
                        icon
                        noText
                        onClick={() => formSubmitHandler(formInitialValues(data).detailData, "DELETE")}
                    />
                )} */}
        </ButtonGroup>
      )
    }
  ];

  return (
    <>
      <ListLayout
        customLeftTopSection={() => (
          <>
            <div className="d-flex align-items-end mb-3">
              <div style={{ width: 300 }} className="me-2">
                <InputSearch onChange={debounce(searchHandler, 1500)} />
              </div>
              <div>
                <ButtonFilter
                  active={filter?.active}
                  className="text-nowrap"
                  onClick={() => setFilter({ ...filter, show: true })}
                />
              </div>
            </div>
            <div className="d-flex align-items-end mb-3 gap-2">
              <InfoItemVertical label="Komponen Payroll" text={dataInfo?.komponen_payroll} />
              <InfoItemVertical
                label="Periode Perencanaan Penggajian"
                text={dataInfo?.periode_perencanaan_penggajian}
              />
              <InfoItemVertical
                label="Bulan Penggajian"
                text={
                  (dataInfo?.tgl_bulan_awal ? date.getSlashDMY(new Date(dataInfo?.tgl_bulan_awal)) : "-") +
                  " s/d " +
                  (dataInfo?.tgl_bulan_akhir ? date.getSlashDMY(new Date(dataInfo?.tgl_bulan_akhir)) : "-")
                }
              />
            </div>
          </>
        )}
        showRightTopButton={access.canCreate() ? true : false}
        showExportExcelButton={Boolean(filter?.data?.periodeGajianId && filter?.data?.unitOrganisasiId)}
        onExportExcelButtonClick={() => getTunjanganUangLemburKubikasiExport.mutate(filter?.data)}
        loadingExportButton={getTunjanganUangLemburKubikasiExport.isLoading}
        loading={getList?.isFetching}
        error={getList?.isError}
        dataTotal={getList?.data?.total}
        dataLength={pagination.dataLength}
        pageCurrent={pagination.pageCurrent}
        pageTotal={getList?.data?.last_page}
        onSearchChange={debounce(searchHandler, 1500)}
        onCreateButtonClick={onCreateButtonClickHandler}
        onDataLengthChange={onPaginationDataLengthChange}
        onPaginationChange={onPaginationChange}
        data={getList?.data?.data}
        tableHeader={TABLE_HEADER}
        tableBody={TABLE_BODY}
      />
      {/* ModalFilter */}
      <Formik
        initialValues={{
          unitOrganisasiId: filter?.data?.unitOrganisasiId,
          periodeGajianId: filter?.data?.periodeGajianId,
          tglAwal: filter?.data?.tglAwal,
          tglAkhir: filter?.data?.tglAkhir
        }}
      >
        {({ resetForm, values }) => (
          <ModalFilter
            show={filter.show}
            onReset={resetForm}
            onHide={() =>
              setFilter({
                ...filter,
                show: false
              })
            }
            onSubmit={() => {
              setFilter({
                show: false,
                active: Boolean(Object.values(values).find((val) => val)),
                data: values
              });
            }}
          >
            <ModalFilterBody />
          </ModalFilter>
        )}
      </Formik>
    </>
  );
};
