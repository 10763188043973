import { Services } from "services";

class AlasanPHKApi {
  async getList(params) {
    const fetch = await Services.get("/master/alasan-phk/", { params });
    return fetch?.data?.data;
  }
  async getPeriode(params) {
    const fetch = await Services.get("/master/grade-nominal/dropdown", { params });
    return fetch?.data?.data?.map((val) => ({ label: val?.periode_perencanaan, value: val?.periode_gajian_id }));
  }
  async getDetail(id) {
    const fetch = await Services.get("/master/alasan-phk/" + id);
    return fetch?.data?.data;
  }
  async getExport(params) {
    const fetch = await Services.get("/master/alasan-phk/export", { params });
    return fetch?.data?.data;
  }
  create(data) {
    return Services.post("/master/alasan-phk", data);
  }
  update(data, id) {
    return Services.put("/master/alasan-phk/" + id, data);
  }
  delete(id) {
    return Services.delete("/master/alasan-phk/" + id);
  }
}

export default new AlasanPHKApi();
