import { ButtonBack, ButtonUpdate } from "components";
import { Formik } from "formik";
import { useModalConfirm } from "hooks";
import { ContentLayout } from "layouts";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { errorSubmitMapper } from "utilities";
import SetupJadwalApi from "./__SetupJadwalApi__";
import { Content } from "./__SetupJadwalComps__";
import { formInitialValues, formSubmitValueMapper } from "./__SetupJadwalUtilities__";

export const SetupJadwalUpdate = () => {
  const navigate = useNavigate();
  const modalConfirm = useModalConfirm();
  const { id } = useParams();

  const [filter, setFilter] = useState({
    search: false,
    data: {
      weekNumber: 1
    }
  });

  const getSetupJadwalDetail = useQuery(["setup-jadwal", "detail", filter?.data?.weekNumber], () => SetupJadwalApi.getDetail(id, {
    week_number: filter?.data?.weekNumber
  }));

  const updateSetupJadwal = useMutation((data) => SetupJadwalApi.update(data, id), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "update" });
      getSetupJadwalDetail.refetch();
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "update", ...errorSubmitMapper(err) })
  });

  const submitValidationHandler = (errors) =>
    new Promise((resolve, reject) => {
      const t = Object.values(errors)
        .flat()
        .filter((v) => v !== undefined)
        .map((v) => Object.values(v))
        .flat();

      const getError = Array.from(new Set(t));

      if (getError.length > 0) {
        reject(getError ?? []);
      } else {
        resolve();
      }
    });

  const preSubmitHandler = (values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting) => {
    setSubmitting(true);
    validateForm().then(async (err) => {
      setErrors(err);
      setTouched(err);

      await submitValidationHandler(err, values)
        .then(() => handleSubmit())
        .catch((err) =>
          modalConfirm.trigger({
            type: "error",
            title: "Data Tidak Lengkap",
            data: err,
            onHide: () => modalConfirm.close()
          })
        )
        .finally(() => {
          setSubmitting(false);
        });
    });
  };

  const formSubmitHandler = async (values) => {
    return await modalConfirm.trigger({
      type: "update",
      onHide: () => {
        modalConfirm.close();
      },
      onSubmit: () => {
        modalConfirm.loading();
        updateSetupJadwal.mutateAsync(formSubmitValueMapper(values));
      }
    });
  };

  return (
    <>
      <ContentLayout showBreadcrumb>
        <>
          <div className="text-end mb-3">
            <ButtonBack tooltip={false} onClick={() => navigate("/time-attendance/transaksi/setup-jadwal")} />
          </div>
          <Formik
            enableReinitialize
            initialValues={formInitialValues.detailData(getSetupJadwalDetail?.data, filter?.data, "UPDATE")}
            // validationSchema={formValidationSchema}
            onSubmit={(values) => formSubmitHandler(values)}
          >
            {({ values, handleSubmit, validateForm, setTouched, setErrors, setSubmitting }) =>
              <>
                <Content
                  action="UPDATE"
                  isLoading={getSetupJadwalDetail.isFetching}
                  onSearchButtonClick={setFilter}
                />
                <div className="text-end mb-3 mt-3">
                  <ButtonUpdate
                    tooltip={false}
                    onClick={() => {
                      preSubmitHandler(values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting);
                    }}
                  />
                </div>
              </>
            }
          </Formik>
        </>
      </ContentLayout>
    </>
  );
};
