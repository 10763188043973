import {
  SetupKomponenJaminanKecelakaanKerjaBulananList,
  SetupKomponenJaminanKecelakaanKerjaBulananCreate
} from "pages/Payroll";
export const SetupJaminanKecelakaanKerjaBulanan = {
  component: <SetupKomponenJaminanKecelakaanKerjaBulananList />,
  link: "setup-komponen-jaminan-kecelakanan-kerja-bulanan",
  name: "Setup Komponen Jaminan Kecelakaan Kerja Bulanan",
  access: "SETUP_KOMPONEN_JAMINAN_KECELAKAAN_KERJA_BULANAN",
  child: [
    {
      name: "Tambah",
      link: "/tambah",
      component: <SetupKomponenJaminanKecelakaanKerjaBulananCreate />
    }
  ]
};
