import { getFileLink } from "utilities";

export const formInitialValues = {
  akun: (data = {}) => ({
    pekerjaNama: data?.akun?.pekerja_nama,
    akun: data?.akun?.akun,
    password: "",
    passwordKonfirmasi: ""
  }),
  profile: (data = {}) => ({
    // jenisIdentitasId: data?.profile?.master_identitas_id,
    // jenisIdentitasNama: data?.profile?.jenis_identitas,
    // noIdentitas: data?.profile?.noIdentitas,
    tempatLahir: data?.profile?.tempat_lahir,
    tglLahir: data?.profile?.tgl_lahir,
    jenisKelaminId: data?.profile?.master_kelamin_id,
    jenisKelaminNama: data?.profile?.jenis_kelamin,
    agamaId: data?.profile?.master_agama_id,
    agamaNama: data?.profile?.agama,
    statusPerkawinanId: data?.profile?.master_status_perkawinan_id,
    statusPerkawinanNama: data?.profile?.status_perkawinan,
    fotoProfile: data?.profile?.foto_profil,
    fotoProfileFull: data?.profile?.foto_profil
      ? getFileLink({ link: data?.profile?.foto_profil, temporary: false })
      : undefined,

    identitas: data?.profile
      ? data?.profile?.identitas?.map((val) => ({
        jenisIdentitasId: val?.master_identitas_id,
        jenisIdentitasNama: val?.jenis_identitas,
        noIdentitas: val?.no_identitas,
        fileIdentitas: val?.file_identitas,
        fileIdentitasFull: val?.file_identitas ? getFileLink({ link: val?.file_identitas, temporary: false }) : undefined
      }))
      : []
  }),
  jabatan: (data = {}) =>
    data?.jabatan
      ? data.jabatan.map((val) => ({
        jenisJabatanId: val?.jenis_jabatan,
        jenisJabatanNama: val?.jenis_jabatan_nama,
        jabatanId: val?.master_jabatan_id,
        jabatanKode: val?.master_jabatan_kode,
        jabatanNama: val?.master_jabatan_nama,
        unitOrganisasiNama: val?.master_unit_organisasi_nama,
        kelompokJabatanNama: val?.master_kelompok_jabatan_nama,
        grade: val?.grade,
        gradeId: val?.master_grade_id,
        step: val?.step,
        stepId: val?.master_step_id
      }))
      : [],
  kepegawaian: (data = {}) => ({
    nip: data?.kepegawaian?.nip,
    tanggalBergabung: data?.kepegawaian?.tanggal_bergabung,
    tanggalPengangkatan: data?.kepegawaian?.tanggal_pengangkatan,
    noPengangkatan: data?.kepegawaian?.no_pengangkatan,
    jenisPekerjaId: data?.kepegawaian?.master_jenis_pekerja_id,
    jenisPekerjaNama: data?.kepegawaian?.jenis_pekerja,
    statusPekerjaId: data?.kepegawaian?.master_status_pekerja_id,
    statusPekerjaNama: data?.kepegawaian?.status_pekerja,
    detailStatusPekerjaId: data?.kepegawaian?.master_detail_status_pekerja_id,
    detailStatusPekerjaNama: data?.kepegawaian?.detail_status_pekerja,
    unitOrganisasiId: data?.kepegawaian?.master_unit_organisasi_id,
    unitOrganisasiNama: data?.kepegawaian?.master_unit_organisasi_nama,
    lokasiKerjaId: data?.kepegawaian?.master_lokasi_kerja_id,
    lokasiKerjaNama: data?.kepegawaian?.lokasi_kerja,
    noNpwp: data?.kepegawaian?.no_npwp,
    noBpjsKetenagakerjaan: data?.kepegawaian?.no_bpjs_ketenagakerjaan,
    noBpjsKesehatan: data?.kepegawaian?.no_bpjs_kesehatan,
    noYadapen: data?.kepegawaian?.no_yadapen,
    tahunPensiun: data?.kepegawaian?.tahun_pensiun ? data?.kepegawaian?.tahun_pensiun + "-01-01" : "",
    bulanPensiun: data?.kepegawaian?.bulan_pensiun
      ? `${data.kepegawaian.tahun_pensiun}-${data.kepegawaian.bulan_pensiun}-01`
      : "",
    tanggalKeluar: data?.kepegawaian?.tanggal_keluar,

    namaWali: data?.kepegawaian?.nama_wali,
    jenisKontakId: data?.kepegawaian?.master_jenis_kontak_id,
    jenisKontakNama: data?.kepegawaian?.jenis_kontak,
    kontakWali: data?.kepegawaian?.kontak_wali,
    hubunganWaliId: data?.kepegawaian?.master_hubungan_keluarga_id,
    hubunganWaliNama: data?.kepegawaian?.hubungan_keluarga,

    namaWali1: data?.kepegawaian?.nama_wali_1,
    jenisKontakId1: data?.kepegawaian?.master_jenis_kontak_id_1,
    jenisKontakNama1: data?.kepegawaian?.jenis_kontak_1,
    kontakWali1: data?.kepegawaian?.kontak_wali_1,
    hubunganWaliId1: data?.kepegawaian?.master_hubungan_keluarga_id_1,
    hubunganWaliNama1: data?.kepegawaian?.hubungan_keluarga_1,

    namaWali2: data?.kepegawaian?.nama_wali_2,
    jenisKontakId2: data?.kepegawaian?.master_jenis_kontak_id_2,
    jenisKontakNama2: data?.kepegawaian?.jenis_kontak_2,
    kontakWali2: data?.kepegawaian?.kontak_wali_2,
    hubunganWaliId2: data?.kepegawaian?.master_hubungan_keluarga_id_2,
    hubunganWaliNama2: data?.kepegawaian?.hubungan_keluarga_2,

    skPengangkatan: data?.kepegawaian?.file_sk,
    skPengangkatanFull: data?.kepegawaian?.file_sk
      ? getFileLink({ link: data?.kepegawaian?.file_sk, temporary: false })
      : undefined,
    hasilPsikotes: data?.kepegawaian?.upload_hasil_psikotes,
    hasilPsikotesFull: data?.kepegawaian?.upload_hasil_psikotes
      ? getFileLink({ link: data?.kepegawaian?.upload_hasil_psikotes, temporary: false })
      : undefined
  }),
  alamat: (data = {}) => ({
    provinsiKode: data?.alamat?.provinsi_kode,
    provinsiNama: data?.alamat?.provinsi_nama,
    kabupatenKode: data?.alamat?.kabupaten_kode,
    kabupatenNama: data?.alamat?.kabupaten_nama,
    kecamatanKode: data?.alamat?.kecamatan_kode,
    kecamatanNama: data?.alamat?.kecamatan_nama,
    desaKode: data?.alamat?.desa_kode,
    desaNama: data?.alamat?.desa_nama,
    rt: data?.alamat?.rt_rw?.split("/")?.[0],
    rw: data?.alamat?.rt_rw?.split("/")?.[1],
    alamat: data?.alamat?.alamat,
    provinsiKode1: data?.alamat?.provinsi_kode_1,
    provinsiNama1: data?.alamat?.provinsi_nama_1,
    kabupatenKode1: data?.alamat?.kabupaten_kode_1,
    kabupatenNama1: data?.alamat?.kabupaten_nama_1,
    kecamatanKode1: data?.alamat?.kecamatan_kode_1,
    kecamatanNama1: data?.alamat?.kecamatan_nama_1,
    desaKode1: data?.alamat?.desa_kode_1,
    desaNama1: data?.alamat?.desa_nama_1,
    rt1: data?.alamat?.rt_rw_1?.split("/")?.[0],
    rw1: data?.alamat?.rt_rw_1?.split("/")?.[1],
    alamat1: data?.alamat?.alamat_1
  }),
  pendidikan: (data = {}) =>
    data?.pendidikan
      ? data.pendidikan.map((val) => ({
        jenjangPendidikanId: val?.master_jenjang_pendidikan_id,
        jenjangPendidikanNama: val?.master_jenjang_pendidikan_nama,
        namaSekolah: val?.nama_sekolah,
        nomorIjazah: val?.nomor_ijazah,
        fileIjazah: val?.file_ijazah,
        fileIjazahFull: val?.file_ijazah ? getFileLink({ link: val?.file_ijazah, temporary: false }) : undefined,
        fileTrnaskrip: val?.transkrip_nilai,
        fileTrnaskripFull: val?.transkrip_nilai
          ? getFileLink({ link: val?.transkrip_nilai, temporary: false })
          : undefined
      }))
      : [],
  kontak: (data = {}) =>
    data?.kontak
      ? data.kontak.map((val) => ({
        jenisKontakId: val?.master_jenis_kontak_id,
        jenisKontakNama: val?.jenis_kontak,
        dataKontak: val?.data_kontak,
        keterangan: val?.keterangan
      }))
      : [],
  bank: (data = {}) => ({
    bankId: data?.bank?.[0]?.master_bank_id,
    bankNama: data?.bank?.[0]?.bank_nama,
    noRekening: data?.bank?.[0]?.no_rekening,
    atasNama: data?.bank?.[0]?.atas_nama,
    cabangBank: data?.bank?.[0]?.cabang_bank
  }),
  rekamMedis: (data = {}) => ({
    noRekamMedis: data?.rekam_medis?.no_rekam_medis
  }),
  keluarga: (data = {}) =>
    data?.keluarga
      ? data.keluarga.map((val) => ({
        keluargaId: val?.id_keluarga,
        keluargaNama: val?.nama_keluarga,
        hubunganKeluargaId: val?.master_hubungan_keluarga_id,
        hubunganKeluargaNama: val?.hubungan_keluarga,
        statusPerkawinanId: val?.master_status_perkawinan_id,
        statusPerkawinanNama: val?.status_perkawinan,
        status: val?.status,
        tglCerai: val?.tgl_cerai,
        tglMeninggal: val?.tgl_meninggal,
        tempatLahir: val?.tempat_lahir,
        tglLahir: val?.tgl_lahir,
        jenisKelaminId: val?.master_kelamin_id,
        jenisKelaminNama: val?.jenis_kelamin,
        agamaId: val?.master_agama_id,
        agamaNama: val?.agama,
        noBpjsKesehatan: val?.bpjs_kesehatan,
        noRekamMedis: val?.rekam_medis,
        provinsiKode: val?.provinsi_kode,
        provinsiNama: val?.provinsi_nama,
        kabupatenKode: val?.kabupaten_kode,
        kabupatenNama: val?.kabupaten_nama,
        kecamatanKode: val?.kecamatan_kode,
        kecamatanNama: val?.kecamatan_nama,
        desaKode: val?.desa_kode,
        desaNama: val?.desa_nama,
        rt: val?.rt_rw?.split("/")?.[0],
        rw: val?.rt_rw?.split("/")?.[1],
        alamat: val?.alamat,
        pekerjaan: val?.pekerjaan,
        instansi: val?.instansi_sekolah,
        alamatInstansi: val?.alamat_instansi,
        pendidikanTerakhirId: val?.pendidikan_terakhir,
        pendidikanTerakhirNama: val?.pendidikan_terakhir_nama,
        tglLulus: val?.tgl_lulus,
        tempatPendidikan: val?.nama_tempat_pendidikan,
        telp: val?.telpon,
        hp: val?.hp,
        wa: val?.wa,
        email: val?.email,
        keteranganKontak: val?.keterangan
      }))
      : []
};
