import { useQuery } from "react-query";
import { useFormikContext } from "formik";
import { errorFetchingSelectMapper } from "utilities";
import { InfoItemVertical, Select, InputNumber } from "components";
import UraianKPIApi from "../__UraianKPIApi__";

const DetailTabPeriodeTarget = () => {
  const { values } = useFormikContext();
  return (
    <>
      <InfoItemVertical label="Periode Target" text={values.periode} />
      <InfoItemVertical label="Satuan Periode" text={values.periodeSatuanKpiNama} />
    </>
  );
};

const FormTabPeriodeTarget = () => {
  const { values, errors, touched, setValues } = useFormikContext();
  const getSatuanPeriode = useQuery(["satuan-periode", "dropdown"], () => UraianKPIApi.getSatuanKPI());

  return (
    <>
      {/* Periode Target */}
      <InputNumber
        label="Periode Target"
        placeholder="Masukan periode target"
        name="periode"
        value={values.periode}
        onChange={(val) =>
          setValues({
            ...values,
            periode: val
          })
        }
        error={Boolean(errors.periode && touched.periode)}
        errorText={Boolean(errors.periode && touched.periode) && errors.periode}
      />

      {/* Satuan Periode */}
      <Select
        label="Satuan Periode"
        placeholder="Pilih satuan periode"
        defaultValue={getSatuanPeriode?.data?.find((item) => item.value === values.periodeSatuanKpiId) ?? []}
        options={getSatuanPeriode?.data ?? []}
        onChange={(val) =>
          setValues({
            ...values,
            periodeSatuanKpiId: val.value,
            periodeSatuanKpiNama: val.label
          })
        }
        loading={getSatuanPeriode.isFetching}
        error={Boolean(errors.periodeSatuanKpiId && touched.periodeSatuanKpiId)}
        errorText={Boolean(errors.periodeSatuanKpiId && touched.periodeSatuanKpiId) && errors.periodeSatuanKpiId}
        errorFetch={getSatuanPeriode.isError}
        errorFetchText={errorFetchingSelectMapper(getSatuanPeriode.error)}
      />
    </>
  );
};

export const TabPeriodeTarget = ({ action }) => {
  return <>{action === "READ" ? <DetailTabPeriodeTarget /> : <FormTabPeriodeTarget />}</>;
};
