import * as Yup from "yup";

export const formValidationSchema = Yup.object().shape({
  periodePenggajianId: Yup.string().required("Periode penggajian wajib diisi !"),
  masaKerja: Yup.string().required("Masa kerja wajib diisi !"),
  faktorManfaatUangPesangon: Yup.string().required("Faktor manfaat uang pesangon wajib diisi !"),
  faktorManfaatUangPenghargaanMasaKerja: Yup.string().required("Faktor manfaat uang penghargaan kerja wajib diisi !"),
  faktorManfaatUangPenggantiHak: Yup.string().required("Faktor manfaat uang pengganti hak wajib diisi !"),
  faktorManfaatUangPisah: Yup.string().required("Faktor manfaat uang pisah wajib diisi !")
});
