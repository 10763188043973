import React from "react";
import { Card } from "react-bootstrap";
import { numberConvert } from "utilities";
import { Table, Thead, Th, Tbody, Td, ButtonCreate, ModalCreate, InfoItemVertical, dateConvert } from "components";
import { Formik, Form } from "formik";
import { useMutation } from "react-query";
import CreateGajiApi from "../__CreateGajiApi__";
import { useModalConfirm } from "hooks";
import { errorSubmitMapper } from "utilities";

export const KomponenPenghasilan = ({ dataDetail }) => {
  const number = numberConvert();
  const groupedData = dataDetail?.komponen?.reduce((result, item) => {
    (result[item.status] = result[item.status] || []).push(item);
    return result;
  }, {});
  // const sumPenghasilan = groupedData?.[1].reduce((accumulator, item) => {
  //   return accumulator + parseFloat(item.nominal);
  // }, 0);
  // const sumPotongan = groupedData?.[2].reduce((accumulator, item) => {
  //   return accumulator + parseFloat(item.nominal);
  // }, 0);
  // const sumPenyesuaian = groupedData?.[3].reduce((accumulator, item) => {
  //   return accumulator + parseFloat(item.nominal);
  // }, 0);
  // const grandTotal = dataDetail?.komponen?.reduce((accumulator, item) => {
  //   return accumulator + parseFloat(item.nominal);
  // }, 0);

  return (
    <Card>
      <Card.Header>
        <b>Komponen Penghasilan</b>
      </Card.Header>
      <Card.Body>
        <div>
          <b>Penghasilan</b>
          <Table maxHeight="200vh">
            <Thead>
              <tr>
                <Th width={50}>No</Th>
                <Th width={600}>Nama komponen</Th>
                <Th>Nominal</Th>
              </tr>
            </Thead>
            <Tbody>
              {groupedData?.[1]?.map((val, index) => {
                return (
                  <tr>
                    <Td textCenter>{parseFloat(index + 1).toString()}</Td>
                    <Td>{val?.nama_komponen}</Td>
                    <Td textRight>
                      {val?.nominal ? `Rp.${number.getWithComa(val.nominal, { minimumFractionDigits: 2 })}` : "Rp.0"}
                    </Td>
                  </tr>
                );
              })}
              <tr>
                {/* <Td colSpan={2}>TOTAL PENGHASILAN</Td> */}
                <Td colSpan={2}>PENGHASILAN BRUTO</Td>
                <Td textRight>
                  {/* {sumPenghasilan ? "Rp" + number.getWithComa(sumPenghasilan, { minimumFractionDigits: 2 }) : 0} */}
                  {dataDetail?.total_penghasilan ? "Rp" + number.getWithComa(dataDetail?.total_penghasilan, { minimumFractionDigits: 2 }) : "Rp.0"}
                </Td>
              </tr>
            </Tbody>
          </Table>
        </div>
        <div className="mt-4">
          <b>Potongan</b>
          <Table maxHeight="200vh">
            <Thead>
              <tr>
                <Th width={50}>No</Th>
                <Th width={600}>Nama komponen</Th>
                <Th>Nominal</Th>
              </tr>
            </Thead>
            <Tbody>
              {groupedData?.[2]?.map((val, index) => {
                return (
                  <tr>
                    <Td textCenter>{parseFloat(index + 1).toString()}</Td>
                    <Td>{val?.nama_komponen}</Td>
                    <Td textRight>
                      {val?.nominal ? `Rp.${number.getWithComa(val.nominal, { minimumFractionDigits: 2 })}` : "Rp.0"}
                    </Td>
                  </tr>
                );
              })}
              <tr>
                <Td colSpan={2}>TOTAL POTONGAN</Td>
                <Td textRight>
                  {/* {sumPotongan ? "Rp" + number.getWithComa(sumPotongan, { minimumFractionDigits: 2 }) : 0} */}
                  {dataDetail?.total_pemotongan ? "Rp" + number.getWithComa(dataDetail?.total_pemotongan, { minimumFractionDigits: 2 }) : "Rp.0"}
                </Td>
              </tr>
            </Tbody>
          </Table>
        </div>
        <div className="mt-4 mb-4">
          <b>Penyesuaian</b>
          <Table maxHeight="200vh">
            <Thead>
              <tr>
                <Th width={50}>No</Th>
                <Th width={600}>Nama komponen</Th>
                <Th>Nominal</Th>
              </tr>
            </Thead>
            <Tbody>
              {groupedData?.[3]?.map((val, index) => {
                return (
                  <tr>
                    <Td textCenter>{parseFloat(index + 1).toString()}</Td>
                    <Td>{val?.nama_komponen}</Td>
                    <Td textRight>
                      {val?.nominal ? "Rp" + number.getWithComa(val.nominal, { minimumFractionDigits: 2 }) : 0}
                    </Td>
                  </tr>
                );
              })}
              <tr>
                <Td colSpan={2}>TOTAL PENYESUAIAN</Td>
                <Td textRight>
                  {/* {sumPenyesuaian ? "Rp" + number.getWithComa(sumPenyesuaian, { minimumFractionDigits: 2 }) : "Rp" + 0} */}
                  {dataDetail?.total_penyesuaian ? "Rp" + number.getWithComa(dataDetail?.total_penyesuaian, { minimumFractionDigits: 2 }) : "Rp" + 0}
                </Td>
              </tr>
            </Tbody>
          </Table>
        </div>
        {/* <b>TOTAL GAJI : {grandTotal ? "Rp" + number.getWithComa(grandTotal, { minimumFractionDigits: 2 }) : 0}</b> */}
        <b>TOTAL GAJI : {dataDetail?.total_gaji ? "Rp" + number.getWithComa(dataDetail?.total_gaji, { minimumFractionDigits: 2 }) : 0}</b>
      </Card.Body>
    </Card>
  );
};
export const PekerjaPenerimaGajiTable = ({ dataDetail, dataDetailPost, modal, setModal }) => {
  const modalConfirm = useModalConfirm();
  const date = dateConvert();

  const createGaji = useMutation((data) => CreateGajiApi.createData(data), {
    onSuccess: () => modalConfirm.infoSuccess({ typeInfo: "create" }),
    onError: (err) => modalConfirm.infoError({ typeInfo: "create", ...errorSubmitMapper(err) })
  });
  const formSubmitHandler = async () => {
    return await modalConfirm.trigger({
      type: "create",
      size: "md",
      customButtonText: "Simpan",
      onHide: modalConfirm.close,
      onSubmit: () => {
        modalConfirm.loading();
        createGaji.mutateAsync(dataDetail);
      }
    });
  };

  return (
    <>
      <Card>
        <Card.Header>
          <b>Pekerja Penerima Gaji</b>
        </Card.Header>
        <Card.Body>
          <Table maxHeight="200vh">
            <Thead>
              <tr>
                <Th width={50}>No</Th>
                <Th width={100}>NIK</Th>
                <Th width={600}>Pekerja</Th>
                <Th width={600}>Unit Organisasi</Th>
                <Th width={600}>Jabatan</Th>
                <Th width={100}>Aksi</Th>
              </tr>
            </Thead>
            <Tbody>
              {dataDetailPost?.map((val, index) => {
                return (
                  <tr>
                    <Td textCenter>{parseFloat(index + 1).toString()}</Td>
                    <Td>{val?.nip ?? "-"}</Td>
                    <Td>{val?.pekerja_nama ?? "-"}</Td>
                    <Td>{val?.master_unit_organisasi_nama ?? "-"}</Td>
                    <Td>{val?.master_jabatan_nama ?? "-"}</Td>
                    <Td>
                      <ButtonCreate disabled={val?.has_posted} text="POST" onClick={() => setModal({ ...modal, show: true, data: val?.pekerja_id })} />
                    </Td>
                  </tr>
                );
              })}
            </Tbody>
          </Table>
        </Card.Body>
      </Card>
      <Formik>
        {(formik) => {
          return (
            <Form>
              <ModalCreate
                title="Pekerja Penerima Gaji"
                size="lg"
                customSubmitButtonText="IYA"
                show={Boolean(modal.show)}
                onHide={() => setModal({ ...modal, show: false })}
                onSubmit={() => formSubmitHandler()}
              >
                <div style={{ fontSize: "14px" }}><b>DATA POSTING</b></div>
                <InfoItemVertical label="Periode Penggajian" text={dataDetail?.periode_tahun ?? "-"} />
                <InfoItemVertical
                  label="Bulan Penggajian"
                  text={dataDetail?.tgl_bulan_awal && dataDetail?.tgl_bulan_akhir
                    ? `${date.getSlashDMY(new Date(dataDetail?.tgl_bulan_awal))} 
                      s/d 
                      ${date.getSlashDMY(new Date(dataDetail?.tgl_bulan_akhir))}`
                    : "-"
                  }
                />

                <div className="mt-5" style={{ fontSize: "14px" }}><b>DATA PEKERJA</b></div>
                <InfoItemVertical label="NIK" text={dataDetail?.nip ?? "-"} />
                <InfoItemVertical label="Pekerja" text={dataDetail?.pekerja_nama ?? "-"} />
                <InfoItemVertical label="Jabatan" text={dataDetail?.master_jabatan_nama ?? "-"} />
                <InfoItemVertical label="Unit Organisasi" text={dataDetail?.master_unit_organisasi_nama ?? "-"} />
                <InfoItemVertical label="Status PPPH21" text={dataDetail?.status_pajak ?? "-"} />
                <InfoItemVertical label="NPWP" text={dataDetail?.no_npwp ?? "-"} />

                <KomponenPenghasilan dataDetail={dataDetail} />
              </ModalCreate>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
