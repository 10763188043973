import { ButtonBack, ButtonUpdate, DataStatus, useModalConfirm } from "components";
import { Formik } from "formik";
import { ContentLayout } from "layouts";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { errorSubmitMapper } from "utilities";
import TunjanganUangLemburPerjamApi from "./__TunjanganUangLemburPerjamApi__";
import { Content } from "./__TunjanganUangLemburPerjamComps__";
import { formInitialValues, formSubmitValueMapper, formValidationSchema } from "./__TunjanganUangLemburPerjamUtilities__";

export const TunjanganUangLemburPerjamUpdate = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const modalConfirm = useModalConfirm();

    const getDetail = useQuery(
        [
            "detail",
            "tunjangan-uang-lembur-perjam",
            id
        ],
        () =>
            TunjanganUangLemburPerjamApi.getDetail(id)
    );

    const updateData = useMutation((data) => TunjanganUangLemburPerjamApi.update(data, id), {
        onSuccess: () => {
            modalConfirm.infoSuccess({ typeInfo: "update" });
            navigate("/payroll/transaksi/tunjangan-uang-lembur-perjam");
        },
        onError: (err) => modalConfirm.infoError({ typeInfo: "update", ...errorSubmitMapper(err) })
    });

    const submitValidationHandler = (errors) =>
        new Promise((resolve, reject) => {
            const getError = Object.values(errors);

            if (getError.length > 0) {
                reject(getError ?? []);
            } else {
                resolve();
            }
        });

    const preSubmitHandler = (values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting) => {
        setSubmitting(true);
        validateForm().then(async (err) => {
            setErrors(err);
            setTouched(err);

            await submitValidationHandler(err, values)
                .then(() => formSubmitHandler(values))
                .catch((err) =>
                    modalConfirm.trigger({
                        type: "error",
                        title: "Data Tidak Lengkap",
                        data: err,
                        onHide: () => modalConfirm.close()
                    })
                )
                .finally(() => {
                    setSubmitting(false);
                });
        });
    };

    const formSubmitHandler = async (values) => {
        return await modalConfirm.trigger({
            type: "update",
            onHide: modalConfirm.close,
            onSubmit: () => {
                modalConfirm.loading();
                updateData.mutateAsync(formSubmitValueMapper.detailData(values));
            }
        });
    };

    return (
        <>
            <ContentLayout showBreadcrumb>
                {getDetail?.isFetching ? (
                    <DataStatus loading />
                ) : (
                    <>
                        <div className="text-end mb-3">
                            <ButtonBack tooltip={false} onClick={() => navigate("/payroll/transaksi/tunjangan-uang-lembur-perjam")} />
                        </div>
                        <Formik
                            enableReinitialize
                            initialValues={formInitialValues.detailData(getDetail?.data)}
                            validationSchema={formValidationSchema}
                        >
                            {({ values, handleSubmit, validateForm, setTouched, setErrors, setSubmitting }) => (
                                <>
                                    <Content
                                        action="UPDATE"
                                        loading={getDetail.isFetching}
                                    />
                                    <div className="text-end mb-3 mt-3">
                                        <ButtonUpdate
                                            disabled={Boolean(values?.detail?.length === 0)}
                                            tooltip={false}
                                            onClick={() =>
                                                preSubmitHandler(values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting)
                                            }
                                        />
                                    </div>
                                </>
                            )}
                        </Formik>
                    </>
                )}
            </ContentLayout>
        </>
    );
};
