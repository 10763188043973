import { ButtonFilter, InputSearch, ModalFilter, numberConvert, Select, toastTrigger } from "components";
import { Formik, useFormikContext } from "formik";
import { ListLayout } from "layouts";
import { debounce } from "lodash";
import { useState } from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import { FiFileText } from "react-icons/fi";
import { useMutation, useQuery } from "react-query";
import { exportHandler } from "utilities";
import LaporanTransferGajiApi from "./__LaporanTransferGajiApi__";

const ModalFilterBody = () => {
  const { values, setValues } = useFormikContext();

  const getPeriode = useQuery(["periode-penggajian-for-list", "dropdown"], () =>
    LaporanTransferGajiApi.getPeriodeForList()
  );
  const getUnitOrganisasi = useQuery(
    ["unit-organisasi-for-list", "dropdown", values?.periodeGajianId],
    () =>
      LaporanTransferGajiApi.getUnitOrganisasi({
        periode_tahun: values?.periodeGajianId
      }),
    {
      enabled: Boolean(values?.periodeGajianId)
    }
  );
  const getTanggal = useQuery(
    ["tanggal-penggajian", "dropdown", values?.periodeGajianId],
    () =>
      LaporanTransferGajiApi.getTanggal({
        periode_tahun: values?.periodeGajianId
      }),
    {
      enabled: Boolean(values?.periodeGajianId)
    }
  );

  return (
    <>
      <Select
        label="Periode Penggajian"
        placeholder="Pilih periode penggajian"
        defaultValue={getPeriode?.data?.find((item) => item.value === values?.periodeGajianId)}
        options={getPeriode?.data ?? []}
        onChange={(val) => setValues({ ...values, periodeGajianId: val.value, periodePerencanaan: val.label })}
        loading={getPeriode.isFetching}
        errorFetch={getPeriode.isError}
      />
      <Select
        disable={Boolean(!values?.periodeGajianId)}
        label="Tanggal Penggajian"
        placeholder="Pilih tanggal penggajian"
        defaultValue={getTanggal?.data?.find((item) => item.value === values?.tglId)}
        options={getTanggal?.data ?? []}
        onChange={(val) =>
          setValues({
            ...values,
            tglId: val.value,
            tglNama: val.label,
            tglAwal: val.tglAwal,
            tglAkhir: val.tglAkhir
          })
        }
        loading={getTanggal.isFetching}
        errorFetch={getTanggal.isError}
      />
      <Select
        disable={Boolean(!values?.periodeGajianId)}
        label="Unit Organisasi"
        placeholder="Pilih unit organisasi"
        defaultValue={getUnitOrganisasi?.data?.find((item) => item.value === values.unitOrganisasiId)}
        options={getUnitOrganisasi?.data ?? []}
        onChange={(val) => setValues({ ...values, unitOrganisasiId: val.value })}
        loading={getUnitOrganisasi.isFetching}
        errorFetch={getUnitOrganisasi.isError}
      />
    </>
  );
};

export const LaporanTransferGajiList = () => {
  const number = numberConvert();
  // const navigate = useNavigate();

  const [searchKey, setSearchKey] = useState(undefined);
  const [pagination, setPagination] = useState({
    pageCurrent: 1,
    dataLength: 10
  });

  const [filter, setFilter] = useState({
    show: false,
    active: false,
    data: {
      periodeGajianId: undefined,
      unitOrganisasiId: undefined,
      tglId: undefined,
      tglAwal: undefined,
      tglAkhir: undefined
    }
  });

  const getLaporanTransferGajiList = useQuery(
    [
      "transfer-gaji",
      "list",
      searchKey,
      pagination.pageCurrent,
      pagination.dataLength,
      filter.data?.periodeGajianId,
      filter.data?.unitOrganisasiId,
      filter.data?.tglAwal,
      filter.data?.tglAkhir
    ],
    () =>
      LaporanTransferGajiApi.getList({
        search: searchKey,
        page: pagination.pageCurrent,
        limit: pagination.dataLength,
        periode_tahun: filter.data?.periodeGajianId,
        master_unit_organisasi_id: filter.data?.unitOrganisasiId,
        tgl_bulan_awal: filter.data?.tglAwal,
        tgl_bulan_akhir: filter.data?.tglAkhir
      }),
    {
      enabled: Boolean(
        filter?.data?.periodeGajianId &&
        filter?.data?.unitOrganisasiId &&
        filter?.data?.tglAwal &&
        filter?.data?.tglAkhir
      )
    }
  );

  const getExportTrasferGaji = useMutation(
    (data) =>
      LaporanTransferGajiApi.getExport({
        to: "excel",
        perioded_tahun: data?.periodeGajianId,
        master_unit_organisasi_id: data?.unitOrganisasiId,
        tgl_bulan_awal: data?.tglAwal,
        tgl_bulan_akhir: data?.tglAkhir
      }),
    {
      onSuccess: (link) => {
        toastTrigger({ variant: "success", message: "Export berhasil" });
        exportHandler(link, "Data Laporan Transfer Gaji");
      },
      onError: () => {
        toastTrigger({ variant: "danger", message: "Export gagal" });
      }
    }
  );

  const onPaginationChange = (data) => setPagination({ ...pagination, pageCurrent: data });

  const onPaginationDataLengthChange = (data) => setPagination({ ...pagination, dataLength: data });

  const searchHandler = (e) => {
    setSearchKey(e?.target?.value ? e.target.value : undefined);
  };

  const TABLE_HEADER = [
    {
      text: "NIK"
    },
    {
      text: "Pekerja"
    },
    {
      text: "Unit Organisasi"
    },
    {
      text: "Bank"
    },
    {
      text: "Cabang"
    },
    {
      text: "Atas Nama"
    },
    {
      text: "No. Rekening"
    },
    {
      text: "Jumlah"
    }
  ];

  const TABLE_BODY = [
    { field: "nip" },
    { field: "pekerja_nama" },
    { field: "master_unit_organisasi_nama" },
    { field: "master_bank_nama" },
    { field: "cabang_bank" },
    { field: "atas_nama" },
    { field: "no_rekening" },
    {
      props: { textRight: true },
      customField: (val) => (val?.jumlah ? "Rp" + number.getWithComa(val?.jumlah, { minimumFractionDigits: 0 }) : "Rp0")
    }
  ];

  return (
    <>
      <ListLayout
        customLeftTopSection={() => (
          <>
            <div className="d-flex align-items-end mb-3">
              <div style={{ width: 300 }} className="me-2">
                <InputSearch onChange={debounce(searchHandler, 1500)} />
              </div>
              <div>
                <ButtonFilter
                  active={filter?.active}
                  className="text-nowrap"
                  onClick={() => setFilter({ ...filter, show: true })}
                />
              </div>
            </div>
          </>
        )}
        customRightTopSection={() => (
          <div className="d-flex flex-column flex-sm-row justify-content-center justify-content-md-end align-items-center">
            {Boolean(
              filter.data.periodeGajianId &&
              filter.data.unitOrganisasiId &&
              filter.data.periodeGajianId &&
              filter.data.tglAwal &&
              filter.data.tglAkhir
            ) ? (
              <Dropdown align="end">
                <Dropdown.Toggle
                  size="sm"
                  variant="outline-success"
                  className="mx-2"
                  disabled={getExportTrasferGaji?.isLoading}
                >
                  {getExportTrasferGaji?.isLoading ? (
                    <Spinner size="sm" className="me-1" animation="border" />
                  ) : (
                    <FiFileText className="mb-1 mr-3" />
                  )}
                  <span className="mx-1">{getExportTrasferGaji?.isLoading ? "Memproses..." : "EXPORT"}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="mt-2">
                  <Dropdown.Item onClick={() => getExportTrasferGaji.mutate(filter.data)}>Export Excel</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <></>
            )}
          </div>
        )}
        loading={getLaporanTransferGajiList?.isFetching}
        error={getLaporanTransferGajiList?.isError}
        dataTotal={getLaporanTransferGajiList?.data?.total}
        dataLength={pagination.dataLength}
        pageCurrent={pagination.pageCurrent}
        pageTotal={getLaporanTransferGajiList?.data?.last_page}
        onSearchChange={debounce(searchHandler, 1500)}
        onDataLengthChange={onPaginationDataLengthChange}
        onPaginationChange={onPaginationChange}
        data={getLaporanTransferGajiList?.data?.data}
        tableHeader={TABLE_HEADER}
        tableBody={TABLE_BODY}
      />

      {/* ModalFilter */}
      <Formik
        initialValues={{
          periodeGajianId: filter?.data?.periodeGajianId,
          unitOrganisasiId: filter?.data?.unitOrganisasiId,
          tglAwal: filter?.data?.tglAwal,
          tglAkhir: filter?.data?.tglAkhir
        }}
      >
        {({ resetForm, values }) => (
          <ModalFilter
            show={filter.show}
            onReset={resetForm}
            onHide={() =>
              setFilter({
                ...filter,
                show: false
              })
            }
            onSubmit={() => {
              setFilter({
                show: false,
                active: Boolean(Object.values(values).find((val) => val)),
                data: values
              });
            }}
          >
            <ModalFilterBody />
          </ModalFilter>
        )}
      </Formik>
    </>
  );
};
