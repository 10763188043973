import { ButtonDelete, ButtonDetail, ButtonUpdate, DataStatus, InputSearch, Select, toastTrigger } from "components";
import { Formik } from "formik";
import { useAccessRights, useModalConfirm } from "hooks";
import { ListLayout } from "layouts";
import { debounce } from "lodash";
import { useState } from "react";
import { ButtonGroup } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { errorFetchingTableMapper, errorSubmitMapper, exportHandler, numberConvert } from "utilities";
import UraianKPIApi from "./__UraianKPIApi__";
import { Content } from "./__UraianKPIComps__";
import { formInitialValues } from "./__UraianKPIUtils__";

const DetailDeleteUraianKPI = ({ id }) => {
  const getUraianKPI = useQuery(["uraian-kpi", id], () => UraianKPIApi.getReadSingle(id));

  return getUraianKPI.isFetching || getUraianKPI.isError ? (
    <DataStatus loading={getUraianKPI.isFetching} text="Data gagal dimuat" />
  ) : (
    <>
      <Formik enableReinitialize initialValues={formInitialValues({ data: getUraianKPI?.data })}>
        {() => (
          <>
            <Content action="READ" />
          </>
        )}
      </Formik>
    </>
  );
};

export const UraianKPIList = () => {
  const number = numberConvert();
  const access = useAccessRights("URAIAN_KPI");

  const navigate = useNavigate();
  const modalConfirm = useModalConfirm();
  const [searchKey, setSearchKey] = useState(undefined);

  const [pagination, setPagination] = useState({
    pageCurrent: 1,
    dataLength: 10
  });

  const [filter, setFilter] = useState({
    unit_oraganisasi_id: undefined
  });

  const getUraianKPI = useQuery(
    ["uraian-kpi", "list", searchKey, pagination.pageCurrent, pagination.dataLength, filter],
    () =>
      UraianKPIApi.getList({
        search: searchKey,
        master_unit_organisasi_id: filter.unit_oraganisasi_id,
        page: pagination.pageCurrent,
        limit: pagination.dataLength
      })
  );

  const getUnitOrganisasiDropdown = useQuery(["unit-organisasi", "dropdown"], UraianKPIApi.getUnitOrganisasi);

  const deleteUraianKPI = useMutation((id) => UraianKPIApi.delete(id), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "delete" });
      getUraianKPI.refetch();
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "delete", ...errorSubmitMapper(err) })
  });

  const getExportUraianKPI = useMutation((type) => UraianKPIApi.getExport({ to: type }), {
    onSuccess: (link) => {
      toastTrigger({ variant: "success", message: "Export berhasil" });
      exportHandler(link, "Data Uraian KPI");
    },
    onError: () => {
      toastTrigger({ variant: "danger", message: "Export gagal" });
    }
  });

  const searchHandler = (e) => {
    setSearchKey(e?.target?.value ? e.target.value : undefined);
  };

  const onCreateButtonClickHandler = () => navigate("tambah");

  const onDetailButtonClickHandler = (data) => navigate("detail/" + data.uraian_kpi_id);

  const onUpdateButtonClickHandler = (data) => navigate("ubah/" + data.uraian_kpi_id);

  const onPaginationChange = (data) => setPagination({ ...pagination, pageCurrent: data });

  const onPaginationDataLengthChange = (data) => setPagination({ ...pagination, dataLength: data });

  const TABLE_HEADER = [
    [
      {
        text: "No",
        props: {
          width: 100,
          rowSpan: 2
        }
      },
      {
        text: "Unit Organisasi",
        props: {
          width: 100,
          rowSpan: 2
        }
      },
      {
        text: "Kode",
        props: {
          minWidth: 100,
          rowSpan: 2
        }
      },
      {
        text: "KPI",
        props: {
          width: 100,
          rowSpan: 2
        }
      },
      {
        text: "Perspektif",
        props: {
          width: 100,
          rowSpan: 2
        }
      },
      {
        text: "Sasaran",
        props: {
          width: 100,
          rowSpan: 2
        }
      },
      {
        text: "Definisi",
        props: {
          width: 100,
          rowSpan: 2
        }
      },
      {
        text: "Periode Target KPI",
        props: {
          width: 100,
          colSpan: 2
        }
      },
      {
        text: "Formula",
        props: {
          width: 100,
          rowSpan: 2
        }
      },
      {
        text: "Target",
        props: {
          width: 100,
          rowSpan: 2
        }
      },
      {
        text: "Frekuensi Monitoring",
        props: {
          width: 100,
          colSpan: 2
        }
      },
      {
        text: "Sumber Data",
        props: {
          width: 100,
          rowSpan: 2
        }
      },
      {
        text: "Aksi",
        props: {
          width: 50,
          fixed: true,
          rowSpan: 2
        }
      }
    ],
    [
      {
        text: "Periode Target",
        props: {
          width: 100
        }
      },
      {
        text: "Satuan",
        props: {
          width: 100
        }
      },
      {
        text: "Frekuensi",
        props: {
          width: 100
        }
      },
      {
        text: "Satuan",
        props: {
          width: 50,
          fixed: true
        }
      }
    ]
  ];

  const TABLE_BODY = [
    { field: "master_unit_organisasi_nama" },
    { field: "master_unit_organisasi_kode" },
    { field: "kpi_nama" },
    { field: "perspektif_kpi_nama" },
    { field: "sasaran_nama" },
    { field: "definisi" },
    {
      customField: (data) =>
        data?.periode_target != null
          ? `${number.getWithComa(parseInt(data?.periode_target), { minimumFractionDigits: 0 })}`
          : "-",
      props: { textRight: true }
    },
    { field: "satuan_periode_target" },
    { field: "formula" },
    {
      customField: (data) => (
        <>
          {data.target_kpi.map((val) => (
            <li>{val}</li>
          ))}
        </>
      )
    },
    {
      customField: (data) =>
        data?.frekuensi_monitoring != null
          ? `${number.getWithComa(parseInt(data?.frekuensi_monitoring), { minimumFractionDigits: 0 })}`
          : "-",
      props: { textRight: true }
    },
    { field: "satuan_frekuensi_monitoring" },
    {
      customField: (data) => (
        <>
          {data.sumber_data.map((val) => (
            <li>{val}</li>
          ))}
        </>
      )
    },
    {
      props: {
        fixed: true
      },
      customField: (data, index) => (
        <div className="d-flex align-items-center">
          <ButtonGroup size="sm">
            <ButtonDetail icon={true} noText onClick={() => onDetailButtonClickHandler(data)} />
            <ButtonUpdate icon={true} noText onClick={() => onUpdateButtonClickHandler(data)} />
            <ButtonDelete
              icon={true}
              noText
              onClick={() =>
                modalConfirm.trigger({
                  size: "lg",
                  type: "delete",
                  alert: true,
                  component: <DetailDeleteUraianKPI id={data.uraian_kpi_id} />,
                  onSubmit: () => {
                    modalConfirm.loading();
                    deleteUraianKPI.mutateAsync(data.uraian_kpi_id);
                  },
                  onHide: () => modalConfirm.close()
                })
              }
            />
          </ButtonGroup>
        </div>
      )
    }
  ];

  return (
    <>
      {/**
       * List Layout:
       * Menampilkan nama menu, breadcrumb, search input, create & export button, tabel dll
       * search section & button section dapat direplace menggunakan props customLeftTopSection, customRightTopSection dll
       */}
      <ListLayout
        customLeftTopSection={() => (
          <div className="d-flex align-items-end mb-3">
            <div className="me-2" style={{ width: "100%" }}>
              <Select
                noMargin
                label="Unit Organisasi"
                placeholder="Pilih unit organisasi"
                options={getUnitOrganisasiDropdown?.data ?? []}
                onChange={(val) => {
                  setFilter({
                    unit_oraganisasi_id: val.value
                  });
                }}
              />
            </div>
            <div style={{ width: "100%" }} className="me-2">
              <InputSearch onChange={debounce(searchHandler, 1500)} />
            </div>
          </div>
        )}
        showExportExcelButton
        showExportPdfButton
        onExportExcelButtonClick={() => getExportUraianKPI.mutate("excel")}
        onExportPdfButtonClick={() => getExportUraianKPI.mutate("pdf")}
        loadingExportButton={getExportUraianKPI.isLoading}
        showRightTopButton={access.canCreate() ? true : false}
        loading={getUraianKPI.isFetching}
        error={getUraianKPI.isError}
        errorText={errorFetchingTableMapper(getUraianKPI.error)}
        dataTotal={getUraianKPI?.data?.total}
        dataLength={pagination.dataLength}
        pageCurrent={pagination.pageCurrent}
        pageTotal={getUraianKPI?.data?.last_page}
        onCreateButtonClick={onCreateButtonClickHandler}
        onDataLengthChange={onPaginationDataLengthChange}
        onPaginationChange={onPaginationChange}
        data={getUraianKPI?.data?.data}
        multipleTableHeader={TABLE_HEADER}
        tableBody={TABLE_BODY}
      />
    </>
  );
};
