import { TunjanganUangLemburPerjamList, TunjanganUangLemburPerjamCreate, TunjanganUangLemburPerjamUpdate, TunjanganUangLemburPerjamDetail, TunjanganUangLemburPerjamPostList } from "pages/Payroll";

export const TunjanganUangLemburPerjam = {
    component: <TunjanganUangLemburPerjamList />,
    link: "tunjangan-uang-lembur-perjam",
    name: "Tunjangan Uang Lembur Perjam",
    access: "PEKERJA_LEMBUR_PERJAM_BULANAN",
    child: [
        {
            name: "List Pekerja",
            link: "/post-list",
            component: <TunjanganUangLemburPerjamPostList />
        },
        {
            name: "Tambah",
            link: "/tambah",
            component: <TunjanganUangLemburPerjamCreate />
        },
        {
            name: "Ubah",
            link: "/ubah/:id",
            component: <TunjanganUangLemburPerjamUpdate />
        },
        {
            name: "Detail",
            link: "/detail/:id",
            component: <TunjanganUangLemburPerjamDetail />
        }
    ]
};
