import * as Yup from "yup";

export const formValidationSchema = Yup.object().shape({
    kesehatanNominal: Yup.string().required("Nominal upah BPJS Kesehatan wajib diisi"),
    validateNominal: Yup.string().when(['tenagaKerjaNominal', 'bpuNominal'], {
        is: (tenagaKerjaNominal = Number, bpuNominal = Number) => {
            if (tenagaKerjaNominal === undefined || isNaN(tenagaKerjaNominal) || (tenagaKerjaNominal > 0 && bpuNominal > 0)) {
                return true;
            }
            else if (bpuNominal === undefined || isNaN(bpuNominal) || bpuNominal > 0 && tenagaKerjaNominal > 0) {
                return true // validate and go to then function
            }
            return false;
        },
        then: (schema) =>
            schema
                .required("Upah BPJS Tenaga Kerja dan upah BPJS BPU wajib diisi, serta salah satunya harus bernilai Rp0,00")
    })
});
