import * as Yup from "yup";

export const formValidationSchema = {
  form: Yup.object().shape({
    idPeriode: Yup.string().required("Periode wajib diisi"),
    idJenisUpahMinimum: Yup.string().required("Jenis upah minimum wajib dipilih"),
    tglSetup: Yup.string().required("Tanggal setup wajib dipilih"),
    idKomponenBpjs: Yup.string().required("Komponen bpjs wajib dipilih"),
    proporsiPerusahaan: Yup.string().required("Proporsi perusahaan wajib diisi"),
    proporsiPekerja: Yup.string().required("Proporsi pekerja wajib diisi"),
    batasAtasUpah: Yup.string().required("Batas atas upah wajib diisi"),
    keterangan: Yup.string().required("Keterangan wajib diisi"),
    idNominal: Yup.string().required("Data nominal tidak ditemukan silahkan mencari periode dan jenis upah yang lain")
  })
};
