export const formSubmitValueMapper = (data) => ({
  create: data?.dataPengirimanBeton,
  object: {
    pengiriman_beton_id: data?.id,
    tgl_pengiriman: data?.tglPengiriman,
    no_doket: data?.noDoket,
    nama_kustomer: data?.kustomer,
    proyek: data?.proyek,
    supir: data?.supir,
    noTm: data?.no_tm,
    mutu: data?.mutu,
    volume: data?.volume,
    jenis_ritase_id: data?.jenisRitaseId,
    jenis_ritase_kode: data?.jenisRitaseKode,
    jarak: data?.jarak,
    pekerja_id: data?.pekerjaId,
    status_ritase: data?.statusRitase,
    status_kubikasi: data?.statusKubikasi
  }
});
