import * as Yup from "yup";

export const formValidationSchema = Yup.object().shape({
  klienNama: Yup.string().required("Nama Klien wajib diisi"),
  provinsiId: Yup.string().required("Provinsi wajib diisi"),
  kabupatenKotaId: Yup.string().required("Kabupaten / Kota wajib diisi"),
  kecamatanId: Yup.string().required("Kecamatan wajib diisi"),
  desaKelurahanId: Yup.string().required("Desa / Kelurahan wajib diisi"),
  rt: Yup.string().required("RT wajib diisi"),
  rw: Yup.string().required("RW wajib diisi"),
  alamat: Yup.string().required("Alamat wajib diisi")
});
