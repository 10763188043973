import React from "react";
import { useQuery } from "react-query";
import { useFormikContext } from "formik";
import { Select, InfoItemVertical, Input } from "components";
import { errorFetchingSelectMapper } from "utilities";
import SasaranKPIApi from "../__SasaranKPIApi__";

export const Content = ({ action }) => {
  const { values, errors, touched, handleChange, setValues } = useFormikContext();
  const getUnitOrganisasi = useQuery(["unit-organisasi", "dropdown"], () => SasaranKPIApi.getUnitOrganisasi());

  if (action === "READ") {
    return (
      <>
        <InfoItemVertical label="Unit Organisasi" text={values.unit_organisasi_nama} />
        <InfoItemVertical label="Sasaran" text={values.nama} />
        <InfoItemVertical label="Keterangan" text={values.keterangan} />
      </>
    );
  }

  return (
    <>
      <Select
        label="Unit Organisasi"
        placeholder="Pilih unit organisasi"
        defaultValue={getUnitOrganisasi?.data?.find((item) => item.value === values.unit_organisasi_id)}
        options={getUnitOrganisasi?.data ?? []}
        onChange={(val) => {
          setValues({
            ...values,
            unit_organisasi_id: val.value,
            unit_organisasi_nama: val.label
          });
        }}
        loading={getUnitOrganisasi.isFetching}
        error={Boolean(errors.unit_organisasi_nama && touched.unit_organisasi_nama)}
        errorText={Boolean(errors.unit_organisasi_nama && touched.unit_organisasi_nama) && errors.unit_organisasi_nama}
        errorFetch={getUnitOrganisasi.isError}
        errorFetchText={errorFetchingSelectMapper(getUnitOrganisasi.error)}
      />

      <Input
        label="Sasaran"
        name="nama"
        placeholder="Masukkan nama sasaran"
        value={values.nama}
        onChange={handleChange}
        error={Boolean(errors.nama && touched.nama)}
        errorText={Boolean(errors.nama && touched.nama) && errors.nama}
      />

      <Input
        label="Keterangan"
        name="keterangan"
        placeholder="Masukkan keterangan"
        value={values.keterangan}
        onChange={handleChange}
        error={Boolean(errors.keterangan && touched.keterangan)}
        errorText={Boolean(errors.keterangan && touched.keterangan) && errors.keterangan}
      />
    </>
  );
};
