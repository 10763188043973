import { ButtonCreate } from "components";
import { Formik } from "formik";
import { useModalConfirm } from "hooks";
import { ContentLayout } from "layouts";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { errorSubmitMapper } from "utilities";
import PengajuanPekerjaPensiunApi from "./__PengajuanPekerjaPensiunApi__";
import { Content } from "./__PengajuanPekerjaPensiunComps__";
import { formInitialValues } from "./__PengajuanPekerjaPensiunUtils__";

export const PengajuanPekerjaPensiunCreate = () => {
  const navigate = useNavigate();
  const modalConfirm = useModalConfirm();
  const [onFilter, setOnFilter] = useState({
    periodeGajianId: undefined,
    tanggalBatasPensiun: undefined,
    unitOrganisasiId: undefined
  });

  const getListForm = useQuery(
    ["list-form", "list", onFilter],
    () =>
      PengajuanPekerjaPensiunApi.getListForm({
        periode_tahun: onFilter.periodeGajianId,
        master_unit_organisasi_id: onFilter.unitOrganisasiId,
        tgl_batas_pensiun: onFilter.tanggalBatasPensiun
      }),
    {
      enabled: Boolean(onFilter?.periodeGajianId && onFilter?.unitOrganisasiId && onFilter.tanggalBatasPensiun)
    }
  );
  const createPengajuanPekerjaPensiun = useMutation((data) => PengajuanPekerjaPensiunApi.create(data), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "create", size: "md" });
      navigate(-1);
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "create", size: "md", ...errorSubmitMapper(err) })
  });

  const formSubmitHandler = (values) =>
    modalConfirm.trigger({
      type: "create",
      // size: "lg",
      onHide: modalConfirm.close,
      onSubmit: () => {
        modalConfirm.loading();
        createPengajuanPekerjaPensiun.mutateAsync(getListForm?.data?.pekerja);
      },
      // component: (
      //   <Formik>
      //     <Content action="CONFRIM" getListForm={getListForm?.data} />
      //   </Formik>
      // )
    });
  const submitValidationHandler = (errors) =>
    new Promise((resolve, reject) => {
      const getError = Object.values(errors);

      if (getError.length > 0) {
        reject(getError);
      } else {
        resolve();
      }
    });
  const preSubmitHandler = (formik) => {
    const { validateForm, setTouched, setErrors, handleSubmit, setSubmitting } = formik;

    setSubmitting(true);
    validateForm().then(async (err) => {
      setErrors(err);
      setTouched(err);
      await submitValidationHandler(err, getListForm?.data?.pekerja)
        .then(() => handleSubmit())
        .catch((err) =>
          modalConfirm.trigger({
            type: "error",
            title: "Data Tidak Lengkap",
            data: err,
            onHide: () => modalConfirm.close()
          })
        )
        .finally(() => {
          setSubmitting(false);
        });
    });
  };

  return (
    <>
      <ContentLayout showBreadcrumb>
        <Formik
          enableReinitialize
          // validationSchema={formValidationSchema}
          initialValues={formInitialValues(getListForm?.data?.pekerja)}
          onSubmit={formSubmitHandler}
        >
          {(formik) => (
            <>
              <Content action="CREATE" onFilter={onFilter} setOnFilter={setOnFilter} getListForm={getListForm?.data} />
              <div className="text-end mt-3">
                <ButtonCreate tooltip={false} onClick={() => preSubmitHandler(formik)} className="px-4" />
              </div>
            </>
          )}
        </Formik>
      </ContentLayout>
    </>
  );
};
