import React from "react";
import { useParams } from "react-router-dom";
import { useMutation } from "react-query";
import { useQuery } from "react-query";
import { Formik, useFormikContext } from "formik";
import { Button, InfoItemVertical, Input, Select } from "components";
import { errorSubmitMapper } from "utilities";
import { useModalConfirm } from "hooks";
import { formInitialValues, formSubmitMapper, formValidationSchema } from "../__PekerjaUtils__";
import PekerjaApi from "../__PekerjaApi__";

const DetailBank = () => {
  const { values } = useFormikContext();

  return (
    <>
      <InfoItemVertical label="Nama Bank" text={values.bankNama} />
      <InfoItemVertical label="No. Rekening" text={values.noRekening} />
      <InfoItemVertical label="Atas ama" text={values.atasNama} />
      <InfoItemVertical label="Cabang" text={values.cabangBank} />
    </>
  );
};

const FormBank = () => {
  const { values, errors, touched, handleChange, setValues } = useFormikContext();
  const getBank = useQuery(["bank", "dropdown"], () => PekerjaApi.getBank());

  return (
    <>
      <Select
        label="Nama Bank"
        placeholder="Pilih nama bank"
        name="bankId"
        loading={getBank.isLoading}
        options={getBank?.data ?? []}
        defaultValue={getBank?.data?.find((item) => item?.value?.toString() === values?.bankId?.toString())}
        onChange={(val) => {
          setValues({ ...values, bankId: val.value, bankNama: val.label });
        }}
        error={Boolean(errors.bankId && touched.bankId)}
        errorText={Boolean(errors.bankId && touched.bankId) && errors.bankId}
      />
      <Input
        label="No. Rekening"
        name="noRekening"
        type="text"
        placeholder="Masukan nomor rekening"
        value={values?.noRekening}
        onChange={handleChange}
        error={Boolean(errors.noRekening && touched.noRekening)}
        errorText={Boolean(errors.noRekening && touched.noRekening) && errors.noRekening}
      />
      <Input
        label="Atas Nama"
        type="text"
        name="atasNama"
        placeholder="Masukan atas nama"
        value={values?.atasNama}
        onChange={handleChange}
        error={Boolean(errors.atasNama && touched.atasNama)}
        errorText={Boolean(errors.atasNama && touched.atasNama) && errors.atasNama}
      />
      <Input
        label="Cabang"
        type="text"
        name="cabangBank"
        placeholder="Masukan cabang"
        value={values?.cabangBank}
        onChange={handleChange}
        error={Boolean(errors.cabangBank && touched.cabangBank)}
        errorText={Boolean(errors.cabangBank && touched.cabangBank) && errors.cabangBank}
      />
    </>
  );
};

export const TabBank = ({ action, refreshData, isGeneralUser }) => {
  const { id } = useParams();
  const modalConfirm = useModalConfirm();
  const parentFormik = useFormikContext();

  const updateBank = useMutation((data) => PekerjaApi.update(data, id), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "update", customTextInfoTitle: "Data Berhasil Disimpan" });
      refreshData();
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "update", ...errorSubmitMapper(err) })
  });
  const updateBankSelf = useMutation((data) => PekerjaApi.updateSelf(data), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "update", customTextInfoTitle: "Data Berhasil Disimpan" });
      refreshData();
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "update", ...errorSubmitMapper(err) })
  });

  const submitValidationHandler = (errors) =>
    new Promise((resolve, reject) => {
      const getError = Object.values(errors);

      if (getError.length > 0) {
        reject(getError);
      } else {
        resolve();
      }
    });

  const preSubmitHandler = (values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting) => {
    setSubmitting(true);
    validateForm().then(async (err) => {
      setErrors(err);
      setTouched(err);

      await submitValidationHandler(err, values)
        .then(() => handleSubmit())
        .catch((err) =>
          modalConfirm.trigger({
            type: "error",
            title: "Data Tidak Lengkap",
            data: err,
            onHide: () => modalConfirm.close()
          })
        )
        .finally(() => {
          setSubmitting(false);
        });
    });
  };

  const formSubmitHandler = (values) =>
    modalConfirm.trigger({
      type: "custom",
      onHide: modalConfirm.close,
      title: "Konfirmasi",
      customButtonShow: true,
      customButtonText: "Simpan",
      customButtonVariant: "primary",
      customTextHeader: "Apakah anda yakin menyimpan data ini?",
      onSubmit: () => {
        modalConfirm.loading();
        if (isGeneralUser === true) {
          updateBankSelf.mutate(formSubmitMapper.bank(values));
        } else {
          updateBank.mutate(formSubmitMapper.bank(values));
        }
      },
      component: (
        <Formik initialValues={values}>
          <DetailBank />
        </Formik>
      )
    });

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues.bank(parentFormik.values)}
      validationSchema={formValidationSchema.bank}
      onSubmit={formSubmitHandler}
    >
      {({ values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting }) => {
        return (
          <>
            {action === "READ" ? <DetailBank /> : <FormBank />}
            {!Boolean(action === "READ") && (
              <div className="text-end mt-3">
                <Button
                  text="Simpan"
                  className="px-4"
                  onClick={() =>
                    preSubmitHandler(values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting)
                  }
                />
              </div>
            )}
          </>
        );
      }}
    </Formik>
  );
};
