import { UraianJabatanList, UraianJabatanCreate, UraianJabatanUpdate, UraianJabatanDetail } from "pages/Organisasi";

export const UraianJabatan = {
  name: "Uraian Jabatan",
  link: "uraian-jabatan",
  access: "URAIAN_JABATAN",
  component: <UraianJabatanList />,
  child: [
    {
      name: "Detail",
      link: "/detail/:id",
      component: <UraianJabatanDetail />
    },
    {
      name: "Tambah",
      link: "/tambah",
      component: <UraianJabatanCreate />
    },
    {
      name: "Ubah",
      link: "/ubah/:id",
      component: <UraianJabatanUpdate />
    }
  ]
};
