import { Services } from "services";

class UangHarianApi {
    async getList(params) {
        const fetch = await Services.get("/transaksi/setup-upah-harian", { params });
        return fetch?.data?.data;
    }

    async getDetail(id) {
        const fetch = await Services.get("/transaksi/setup-upah-harian/" + id);
        return fetch?.data?.data;
    }

    async getPeriode() {
        const fetch = await Services.get("/utility/periode-tahun?feat=setup-upah-harian");
        return fetch?.data?.data.map((val) => ({
            value: val?.periode_tahun,
            label: val?.periode_tahun
        }));
    }

    async getExport(params) {
        const fetch = await Services.get("/transaksi/setup-upah-harian/export", { params });
        return fetch?.data?.data;
    }

    create(data) {
        return Services.post("/transaksi/setup-upah-harian", data);
    }

    update(data, id) {
        return Services.put("/transaksi/setup-upah-harian/" + id, data);
    }

    delete(id) {
        return Services.delete("/transaksi/setup-upah-harian/" + id);
    }
}

export default new UangHarianApi();
