import { Services } from "services";

class KlienApi {
  async getList(params) {
    const fetch = await Services.get("/master/klien/", { params });
    return fetch?.data?.data;
  }
  async getDetail(id) {
    const fetch = await Services.get("/master/klien/" + id);
    return fetch?.data?.data;
  }
  async getExport(params) {
    const fetch = await Services.get("/master/klien/export", { params });
    return fetch?.data?.data;
  }
  async getWilayah(path, params) {
    const fetch = await Services.get(`/master/wilayah/${path}/dropdown`, { params });

    if (path === "provinsi") {
      return fetch?.data?.data.map((val) => ({
        value: val?.provinsi_kode,
        label: val?.provinsi_nama
      }));
    }

    if (path === "kabupaten") {
      return fetch?.data?.data.map((val) => ({
        value: val?.kabupaten_kode,
        label: val?.kabupaten_nama
      }));
    }

    if (path === "kecamatan") {
      return fetch?.data?.data.map((val) => ({
        value: val?.kecamatan_kode,
        label: val?.kecamatan_nama
      }));
    }

    if (path === "desa") {
      return fetch?.data?.data.map((val) => ({
        value: val?.desa_kode,
        label: val?.desa_nama
      }));
    }
  }
  create(data) {
    return Services.post("/master/klien/", data);
  }
  update(data, id) {
    return Services.put("/master/klien/" + id, data);
  }
  delete(id) {
    return Services.delete("/master/klien/" + id);
  }
}

export default new KlienApi();
