import * as Yup from "yup";

export const formValidationSchema = Yup.object().shape({
  tglInput: Yup.string().required("Tanggal input surat penugasan khusus wajib diisi"),
  nomor: Yup.string().required("Nomor wajib diisi"),
  unitOrganisasiId: Yup.string().required("Unit organisasi wajib diisi"),
  pekerjaId: Yup.string().required("Pekerja wajib diisi"),
  tglMulai: Yup.string().required("Tanggal mulai penugasan khusus wajib diisi"),
  tglAkhir: Yup.string().required("Tanggal akhir penugasan khusus wajib diisi"),
  lamaPenugasan: Yup.string().required("Lama penugasan wajib diisi")
});
