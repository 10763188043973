import { Services } from "services";
import { fetchDropdownMapper } from "utilities";

const fetchDropdown = fetchDropdownMapper(Services);

class TarifPPhApi {
  async getList(params) {
    const fetch = await Services.get("/master/tarif-pph-21", { params });
    return fetch?.data?.data;
  }

  async getPeriodePenggajian() {
    const getPeriodePenggajian = await fetchDropdown("/master/grade-nominal/dropdown", {
      value: "periode_gajian_id",
      label: "periode_perencanaan"
    });

    return getPeriodePenggajian;
  }

  getStatus() {
    const getStatusThr = [
      { value: 1, label: "Aktif" },
      { value: 2, label: "Tidak Aktif" }
    ];

    return getStatusThr;
  }

  async getExport(params) {
    const fetch = await Services.get("/master/tarif-pph-21/export", { params });
    return fetch?.data?.data;
  }

  create(data) {
    return Services.post("/master/tarif-pph-21", data);
  }

  update(data, id) {
    return Services.put("/master/tarif-pph-21/" + id, data);
  }

  delete(id) {
    return Services.delete("/master/tarif-pph-21/" + id);
  }
}
export default new TarifPPhApi();
