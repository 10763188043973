import { Services } from "services";
import { fetchDropdownMapper } from "utilities";

const fetchDropdown = fetchDropdownMapper(Services);

class SetupKomponenJaminanKematianBulananApi {
  async getList(params) {
    const fetch = await Services.get("/transaksi/setup-jaminan-kematian-bulanan", { params });
    return fetch?.data?.data;
  }
  async getListCreate(params) {
    const fetch = await Services.get("/transaksi/setup-jaminan-kematian-bulanan/form", { params });
    return fetch?.data?.data;
  }

  async getBulan() {
    return Array.from({ length: 12 }, (item, i) => {
      return {
        value: i + 1,
        label: new Date(0, i).toLocaleString("id-ID", { month: "long" })
      };
    });
  }

  async getTanggal(params) {
    const fetch = await Services.get("/transaksi/setup-jaminan-kematian-bulanan/dropdown-tanggal", { params });
    return fetch?.data?.data.map((val) => ({
      value: val?.tgl_bulan_awal + "/&/" + val?.tgl_bulan_akhir,
      label: val?.tgl_bulan_awal + " s/d " + val?.tgl_bulan_akhir,
      tglAwal: val?.tgl_bulan_awal,
      tglAkhir: val?.tgl_bulan_akhir
    }));
  }

  async getExport(params) {
    const fetch = await Services.get("/transaksi/setup-jaminan-kematian-bulanan/export", { params });
    return fetch?.data?.data;
  }

  async getPeriodePenggajian() {
    const getPeriodePenggajian = await fetchDropdown(
      "/utility/periode-tahun?feat=setup-komponen-jaminan-kematian-bulanan",
      {
        value: "periode_tahun",
        label: "periode_tahun"
      }
    );

    return getPeriodePenggajian;
  }

  async getUnitOrganisasi() {
    const getUnitOrganisasi = await fetchDropdown("/master/unit-organisasi", {
      value: "master_unit_organisasi_id",
      label: "master_unit_organisasi_nama"
    });

    return getUnitOrganisasi;
  }

  async getUnitOrganisasiForList() {
    const getUnitOrganisasi = await fetchDropdown("/transaksi/setup-jaminan-kematian-bulanan/dropdown-unit", {
      value: "master_unit_organisasi_id",
      label: "master_unit_organisasi_nama"
    });

    return getUnitOrganisasi;
  }

  create(data) {
    return Services.post("/transaksi/setup-jaminan-kematian-bulanan", data);
  }
}
export default new SetupKomponenJaminanKematianBulananApi();
