import { ButtonDelete, ButtonDetail, ButtonUpdate, InputSearch, Select } from "components";
import { useAccessRights, useModalConfirm } from "hooks";
import { ListLayout } from "layouts";
import { debounce } from "lodash";
import { useState } from "react";
import { ButtonGroup } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { errorFetchingTableMapper, errorSubmitMapper } from "utilities";
import EvaluasiJabatanApi from "./__EvaluasiJabatanApi__";

export const EvaluasiJabatanList = () => {
  const access = useAccessRights("MASTER_KOMPETENSI_JABATAN");
  const navigate = useNavigate();
  const modalConfirm = useModalConfirm();
  const [searchKey, setSearchKey] = useState(undefined);
  const [pagination, setPagination] = useState({
    pageCurrent: 1,
    dataLength: 10
  });
  const [filter, setFilter] = useState({
    jenis_jabatan_id: undefined,
    jenis_jabatan_nama: ""
  });

  const getJenisJabatan = useQuery(["jenis-jabatan", "dropdown"], () => EvaluasiJabatanApi.getJenisJabatan());

  const getEvaluasiJabatan = useQuery(
    ["evaluasi-jabatan", "list", searchKey, filter, pagination.pageCurrent, pagination.dataLength],
    () =>
      EvaluasiJabatanApi.getList({
        jenis_jabatan: filter.jenis_jabatan_id,
        search: searchKey,
        page: pagination.pageCurrent,
        limit: pagination.dataLength
      })
  );

  // const getExportEvaluasiJabatan = useMutation((type) => EvaluasiJabatanApi.getExport({ to: type }), {
  //   onSuccess: (link) => {
  //     toastTrigger({ variant: "success", message: "Export berhasil" });
  //     exportHandler(link, "Data EvaluasiJabatan");
  //   },
  //   onError: () => {
  //     toastTrigger({ variant: "danger", message: "Export gagal" });
  //   }
  // });

  const searchHandler = (e) => {
    setSearchKey(e?.target?.value ? e.target.value : undefined);
  };

  const onCreateButtonClickHandler = () => navigate("tambah");

  const onDetailButtonClickHandler = (data) => navigate("detail/" + data?.master_evaluasi_jabatan_id);

  const onUpdateButtonClickHandler = (data) => navigate("ubah/" + data?.master_evaluasi_jabatan_id);

  const onPaginationChange = (data) => setPagination({ ...pagination, pageCurrent: data });

  const onPaginationDataLengthChange = (data) => setPagination({ ...pagination, dataLength: data });

  const deleteEvaluasiJabatan = useMutation((id) => EvaluasiJabatanApi.delete(id), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "delete" });
      getEvaluasiJabatan.refetch();
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "delete", ...errorSubmitMapper(err) })
  });

  const formSubmitHandler = (values) =>
    modalConfirm.trigger({
      type: "delete",
      onHide: modalConfirm.close,
      onSubmit: () => {
        modalConfirm.loading();
        deleteEvaluasiJabatan.mutate(values.master_evaluasi_jabatan_id);
      },
      component: <></>
    });

  return (
    <>
      <ListLayout
        customLeftTopSection={() => (
          <div className="d-flex align-items-end mb-3">
            <div style={{ width: "100%" }} className="me-2">
              <InputSearch onChange={debounce(searchHandler, 1500)} />
            </div>
            <div style={{ width: "100%" }}>
              <Select
                noMargin
                label="Jenis Jabatan"
                placeholder="Pilih jenis jabatan"
                options={[{ label: "Semua", value: undefined }].concat(getJenisJabatan.data)}
                defaultValue={[{ label: "Semua", value: undefined }]
                  .concat(getJenisJabatan.data)
                  .find((val) => val.value === filter.jenis_jabatan_id)}
                loading={getJenisJabatan.isFetching}
                onChange={(val) => {
                  setFilter({
                    jenis_jabatan_id: val.value,
                    jenis_jabatan_nama: val.label
                  });
                }}
              />
            </div>
          </div>
        )}
        // showExportExcelButton
        // showExportPdfButton
        // onExportExcelButtonClick={() => getExportEvaluasiJabatan.mutate("excel")}
        // onExportPdfButtonClick={() => getExportEvaluasiJabatan.mutate("pdf")}
        // loadingExportButton={getExportEvaluasiJabatan.isLoading}
        showRightTopButton={access.canCreate() ? true : false}
        loading={getEvaluasiJabatan.isFetching}
        error={getEvaluasiJabatan.isError}
        errorText={errorFetchingTableMapper(getEvaluasiJabatan.error)}
        dataTotal={getEvaluasiJabatan?.data?.total}
        dataLength={pagination.dataLength}
        pageCurrent={pagination.pageCurrent}
        pageTotal={getEvaluasiJabatan?.data?.last_page}
        onCreateButtonClick={onCreateButtonClickHandler}
        onDataLengthChange={onPaginationDataLengthChange}
        onPaginationChange={onPaginationChange}
        data={getEvaluasiJabatan?.data?.data}
        tableHeader={[
          {
            text: "Jenis Jabatan",
            props: {
              minWidth: 150
            }
          },
          {
            text: "Kode Jabatan",
            props: {
              minWidth: 150
            }
          },
          {
            text: "Nama Jabatan",
            props: {
              minWidth: 150
            }
          },
          {
            text: "Unit Organisasi"
          },
          {
            text: "Grade"
          },
          {
            text: "Pangkat"
          },
          {
            text: "Aksi",
            props: {
              width: 50,
              fixed: true
            }
          }
        ]}
        tableBody={[
          {
            field: "jenis_jabatan_nama"
          },
          {
            field: "master_jabatan_kode"
          },
          {
            field: "master_jabatan_nama"
          },
          {
            field: "master_unit_organisasi_nama"
          },
          {
            customField: (val) => (val?.grade ? String(val.grade) : "-")
          },
          {
            field: "nama_pangkat"
          },
          {
            props: { fixed: true },
            customField: (data) => (
              <ButtonGroup size="sm">
                {access.canRead() && <ButtonDetail icon noText onClick={() => onDetailButtonClickHandler(data)} />}
                {access.canUpdate() && <ButtonUpdate icon noText onClick={() => onUpdateButtonClickHandler(data)} />}
                {access.canDelete() && <ButtonDelete icon noText onClick={() => formSubmitHandler(data)} />}
              </ButtonGroup>
            )
          }
        ]}
      />
    </>
  );
};
