import { Formik, useFormikContext } from "formik";
import { Input, TextArea, Select, InfoItemHorizontal, ButtonCreate } from "components";
import { formValidationSchema, formSubmitMapper } from "../../__UraianJabatanUtils__";
import { useModalConfirm } from "hooks";
import { errorSubmitMapper } from "utilities";
import UraianJabatanApi from "../../__UraianJabatanApi__";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";

const SectionDetail = () => {
  const { values } = useFormikContext();

  return (
    <>
      <InfoItemHorizontal label="Jenis Jabatan" text={values?.jenisJabatanNama} va />
      <InfoItemHorizontal label="Jabatan" text={values?.jabatanNama} />
      <InfoItemHorizontal label="Unit Organisasi" text={values?.unitOrganisasiNama} />
      <InfoItemHorizontal label="Kelompok Jabatan" text={values?.kelompokJabatanNama} />
      <InfoItemHorizontal label="Grade" text={values?.grade} />
      <InfoItemHorizontal label="Pangkat" text={values.pangkat} />
      <InfoItemHorizontal label="Atasan Langsung" text={values?.atasanLangsung} />
      <InfoItemHorizontal
        label="Bawahan Langsung"
        text={
          values?.bawahanLangsung &&
          values?.bawahanLangsung.map((val) => (
            <>
              - {val}
              <br />
            </>
          ))
        }
      />
      <InfoItemHorizontal
        label="Pemegang Jabatan"
        t
        text={
          values?.pemegangJabatan &&
          values?.pemegangJabatan.map((val) => (
            <>
              - {val}
              <br />
            </>
          ))
        }
      />
    </>
  );
};

const SectionForm = () => {
  const { values, setValues, touched, errors } = useFormikContext();

  const getJenisJabatan = useQuery(["jenisJabatan", "dropdown"], () => UraianJabatanApi.getJenisJabatan());
  const getJabatan = useQuery(
    ["jabatan", "dropdown", values?.jenisJabatanId],
    () => UraianJabatanApi.getJabatan({ jenis_jabatan: values?.jenisJabatanId }),
    {
      enabled: !!values?.jenisJabatanId
    }
  );

  return (
    <div>
      <Select
        label="Jenis Jabatan*"
        name="jenis_jabatan"
        loading={getJenisJabatan?.isFetching}
        options={getJenisJabatan?.data ?? []}
        defaultValue={getJenisJabatan?.data?.find((item) => item.value === values?.jenisJabatanId)}
        onChange={(val) => {
          setValues({
            ...values,
            jenisJabatanId: val?.value,
            jenisJabatanNama: val?.label,
            jabatanNama: "",
            jabatanId: "",
            unitOrganisasiNama: "",
            unitOrganisasiId: "",
            kelompokJabatanNama: "",
            kelompokJabatan: "",
            grade: "",
            pangkat: "",
            pemegangJabatan: [],
            bawahanLangsung: [],
            atasanLangsung: ""
          });
        }}
        error={Boolean(errors.jenisJabatanId && touched.jenisJabatanId)}
        errorText={Boolean(errors.jenisJabatanId && touched.jenisJabatanId) && errors.jenisJabatanId}
      />
      <Select
        label="Jabatan*"
        name="master_jabatan_id"
        loading={getJabatan.isFetching}
        disable={getJabatan.isIdle}
        options={getJabatan?.data ?? []}
        defaultValue={getJabatan?.data?.find((item) => item.value === values?.jabatanId)}
        onChange={(val) => {
          setValues({
            ...values,
            jabatanId: val?.value,
            jabatanNama: val?.label,
            unitOrganisasiNama: val?.master_unit_organisasi_nama,
            kelompokJabatanNama: val?.master_kelompok_jabatan_nama,
            grade: val?.grade,
            pangkat: val?.pangkat,
            atasanLangsung: val?.atasan_langsung_nama,
            bawahanLangsung: val?.bawahan_langsung,
            pemegangJabatan: val?.pemegang_jabatan
          });
        }}
        error={Boolean(errors.jabatanId && touched.jabatanId)}
        errorText={Boolean(errors.jabatanId && touched.jabatanId) && errors.jabatanId}
      />
      <Input
        label="Unit Organisasi"
        disabled
        type="text"
        placeholder="Pilih jabatan terlebih dahulu"
        value={values?.unitOrganisasiNama}
      />
      <Input
        label="Kelompok Jabatan"
        disabled
        type="text"
        placeholder="Pilih jabatan terlebih dahulu"
        value={values?.kelompokJabatanNama}
      />
      <Input label="Grade" disabled type="text" placeholder="Pilih jabatan terlebih dahulu" value={values?.grade} />
      <Input label="Pangkat" disabled type="text" placeholder="Pilih jabatan terlebih dahulu" value={values?.pangkat} />
      <Input
        label="Atasan Langsung"
        disabled
        type="text"
        placeholder="Pilih jabatan terlebih dahulu"
        value={values?.atasanLangsung}
      />
      <TextArea
        label="Bawahan langsung"
        disabled
        rows="4"
        value={values?.bawahanLangsung && values?.bawahanLangsung.map((val) => `- ${val}\r\n`).join("")}
      />
      <TextArea
        label="Pemegang Jabatan"
        disabled
        rows="4"
        value={values?.pemegangJabatan && values?.pemegangJabatan.map((val) => `- ${val}\r\n`).join("")}
      />
    </div>
  );
};

export const TabIdentitasJabatan = ({ action = "CREATE" }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const modalConfirm = useModalConfirm();
  const parentFormik = useFormikContext();

  const createIdentitasJabatan = useMutation((data) => UraianJabatanApi.create(data), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "create", customTextInfoTitle: "Data Berhasil Disimpan" });
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "create", ...errorSubmitMapper(err) })
  });

  const updateIdentitasJabatan = useMutation((data) => UraianJabatanApi.update(data, id), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "update", customTextInfoTitle: "Data Berhasil Disimpan" });
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "update", ...errorSubmitMapper(err) })
  });

  const submitValidationHandler = (errors) =>
    new Promise((resolve, reject) => {
      const getError = Object.values(errors);

      if (getError.length > 0) {
        reject(getError);
      } else {
        resolve();
      }
    });

  const preSubmitHandler = (values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting) => {
    setSubmitting(true);
    validateForm().then(async (err) => {
      setErrors(err);
      setTouched(err);

      await submitValidationHandler(err, values)
        .then(() => handleSubmit())
        .catch((err) =>
          modalConfirm.trigger({
            type: "error",
            title: "Data Tidak Lengkap",
            data: err,
            onHide: () => modalConfirm.close()
          })
        )
        .finally(() => {
          setSubmitting(false);
        });
    });
  };

  const formSubmitHandler = (values, action) =>
    modalConfirm.trigger({
      type: "custom",
      title: "Konfirmasi",
      customButtonShow: true,
      customButtonText: "Simpan",
      customButtonVariant: "primary",
      customTextHeader: "Apakah anda yakin menyimpan data ini?",
      onHide: modalConfirm.close,
      onSubmit: () => {
        modalConfirm.loading();

        if (action === "CREATE") {
          createIdentitasJabatan
            .mutateAsync(formSubmitMapper().identitasJabatan(values))
            .then((val) => navigate("/organisasi/master/uraian-jabatan/ubah/" + val.data.data));
        }

        if (action === "UPDATE") {
          updateIdentitasJabatan.mutate(formSubmitMapper().identitasJabatan(values));
        }
      },
      component: (
        <Formik enableReinitialize initialValues={values}>
          <SectionDetail />
        </Formik>
      )
    });

  return (
    <Formik
      enableReinitialize
      initialValues={parentFormik?.values?.identitasJabatan ?? {}}
      validationSchema={formValidationSchema?.identitasJabatan}
      onSubmit={(values) => formSubmitHandler(values, action)}
    >
      {({ values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting }) => {
        return (
          <form>
            {action === "READ" ? (
              <SectionDetail />
            ) : (
              <>
                <SectionForm />
                <div className="text-end mt-3">
                  <ButtonCreate
                    tooltip={false}
                    text="Simpan"
                    onClick={() =>
                      preSubmitHandler(values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting)
                    }
                  />
                </div>
              </>
            )}
          </form>
        );
      }}
    </Formik>
  );
};
