import { ButtonCreate, DatePicker, Input, Select } from "components";
import { Formik } from "formik";
import { Card } from "react-bootstrap";
import { useQuery } from "react-query";
import SetupKomponenJaminanHariTuaBulananApi from "../__SetupKomponenJaminanHariTuaBulananApi__";
import { formInitialValues, formValidationSchema } from "../__SetupKomponenJaminanHariTuaBulananUtils__";

const FormField = ({ dataFilter, setDataFilter, dropdownPeriode, dropdownUnitOrganisasi }) => {
  // const date = dateConvert();
  const getBulan = useQuery(["bulan", "dropdown"], () => SetupKomponenJaminanHariTuaBulananApi.getBulan());

  const formSubmitHandler = (values) => {
    setDataFilter({
      ...dataFilter,
      tanggalSetup: values.tanggalSetup,
      periodePenggajianId: values.periodePenggajianId,
      unitOrganisasiNama: values.unitOrganisasiNama,
      bulanId: values.bulanId,
      tglAwal: values.tglAwal,
      tglAkhir: values.tglAkhir,
      unitOrganisasiId: values.unitOrganisasiId
    });
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={formInitialValues()}
        validationSchema={formValidationSchema}
        onSubmit={(values) => formSubmitHandler(values)}
      >
        {({ values, handleSubmit, setValues, errors, touched }) => {
          return (
            <>
              <Input
                disabled
                label="Periode Penggajian"
                name="periodePenggajianId"
                placeholder="Masukkan periode penggajian"
                value={values.periodePenggajianId ? String(values?.periodePenggajianId) : "-"}
                onChange={(val) =>
                  setValues({
                    ...values,
                    periodePenggajianId: val.target.value,
                    periodePenggajianNama: val.target.value
                  })
                }
                error={Boolean(errors.periodePenggajianId && touched.periodePenggajianId)}
                errorText={
                  Boolean(errors.periodePenggajianId && touched.periodePenggajianId) && errors.periodePenggajianId
                }
              />
              <Select
                label="Unit Organisasi"
                placeholder="Pilih salah satu.."
                options={dropdownUnitOrganisasi?.data ?? []}
                onChange={(val) => setValues({ ...values, unitOrganisasiId: val.value, unitOrganisasiNama: val.label })}
                error={Boolean(errors.unitOrganisasiId && touched.unitOrganisasiId)}
                errorText={Boolean(errors.unitOrganisasiId && touched.unitOrganisasiId) && errors.unitOrganisasiId}
              />
              <Select
                label="Bulan Penggajian"
                disable={Boolean(!values?.periodePenggajianId)}
                options={getBulan?.data ?? []}
                placeholder="Pilih salah satu..."
                defaultValue={values?.bulanId ? getBulan?.data?.find((item) => item.value === values?.bulanId) : null}
                onChange={(val) => {
                  const tglNewStart = val.value <= 1 ?
                    `${values?.periodePenggajianNama - 1}-12-26`
                    : `${values?.periodePenggajianNama}-${val?.value < 10 ? `0${val?.value - 1}` : val?.value - 1}-26`;

                  const tglNewEnd = `${values?.periodePenggajianNama}-${val?.value < 10 ? `0${val?.value}` : val?.value}-25`;

                  setValues({
                    ...values,
                    bulanId: val.value,
                    bulanNama: val.label,
                    tglAwal: tglNewStart,
                    tglAkhir: tglNewEnd
                  });
                }}

                loading={getBulan?.isFetching}
                errorFetch={getBulan.isError}
                error={Boolean((errors.bulan && touched.bulan) || errors.validateDate)}
                errorText={Boolean(errors.bulan && touched.bulan) && errors.bulan ? errors.bulan : errors.validateDate}
              />
              <div>
                <div style={{ fontSize: 14 }}>Tanggal Penggajian</div>
                <div className="d-flex align-items-center justify-content-between">
                  <div style={{ width: "44%" }}>
                    <DatePicker
                      disabled
                      key={`${values?.periodePenggajianId} ${values?.bulan}`}
                      placeholderText="dd/mm/yyyy"
                      selected={values?.periodePenggajianId && values?.bulanId ? values?.tglAwal : undefined}
                      disable={Boolean(!values?.bulanId)}
                      onChange={(val) =>
                        setValues({
                          ...values,
                          tglAwal: val,
                          bulanId: new Date(val).getMonth() + 1,
                          tglAkhir: new Date(new Date(val).setDate(new Date(val).getDate() + 30))
                        })
                      }
                      error={Boolean(errors.tglAwal && touched.tglAwal) || errors.validateDate}
                      errorText={
                        Boolean(errors.tglAwal && touched.tglAwal) && errors.tglAwal
                          ? errors.tglAwal
                          : errors.validateDate
                      }
                    />
                  </div>
                  <small>s/d</small>
                  <div style={{ width: "44%" }}>
                    <DatePicker
                      disabled
                      key={`${values?.periodePenggajianId} ${values?.bulan}`}
                      placeholderText="dd/mm/yyyy"
                      selected={values?.periodePenggajianId && values?.bulanId ? values?.tglAkhir : undefined}
                      disable={Boolean(!values?.bulan)}
                      onChange={(val) =>
                        setValues({
                          ...values,
                          tglAkhir: val
                        })
                      }
                      error={Boolean(errors.tglAkhir && touched.tglAkhir)}
                      errorText={Boolean(errors.tglAkhir && touched.tglAkhir) && errors.tglAkhir}
                    />
                  </div>
                </div>
              </div>
              <DatePicker
                disabled
                label="Tanggal Setup"
                placeholderText="dd/mm/yy"
                selected={values?.tanggalSetup ? new Date(values?.tanggalSetup) : undefined}
                onChange={(val) => setValues({ ...values, tanggalSetup: val })}
                error={Boolean(errors.tanggalSetup && touched.tanggalSetup)}
                errorText={Boolean(errors.tanggalSetup && touched.tanggalSetup) && errors.tanggalSetup}
              />
              <div className="d-flex justify-content-end">
                <div>
                  <ButtonCreate
                    text="Cari"
                    className="mb-3"
                    tooltip={false}
                    disabled={
                      values.tanggalSetup === undefined ||
                      values.periodePenggajianId === undefined ||
                      values.tglAwal === undefined ||
                      values.tglAkhir === undefined ||
                      values.unitOrganisasiId === undefined
                    }
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export const SetupData = ({
  searchingData,
  setSearchingData,
  dataFilter,
  setDataFilter,
  dropdownPeriode,
  dropdownUnitOrganisasi
}) => {
  return (
    <Card>
      <Card.Header>
        <b>Setup Data</b>
      </Card.Header>
      <Card.Body>
        <FormField
          searchingData={searchingData}
          setSearchingData={setSearchingData}
          dataFilter={dataFilter}
          setDataFilter={setDataFilter}
          dropdownPeriode={dropdownPeriode}
          dropdownUnitOrganisasi={dropdownUnitOrganisasi}
        />
      </Card.Body>
    </Card>
  );
};
