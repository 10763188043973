import React from "react";
import { Row, Col } from "react-bootstrap";
import { SetupData, KomponenPenghasilan, PekerjaPenerimaGajiTable } from "../__CreateGajiComps__";

export const Content = ({ action, searchingData, setSearchingData, dataFilter, setDataFilter, dataDetail, dataDetailPost, modal, setModal }) => {
  if (action === "READ") {
    return (
      <Row>
        <Col lg={3}>
          <SetupData
            action="READ"
            dataDetail={dataDetail}
            // searchingData={searchingData}
            // setSearchingData={setSearchingData}
            // dropdownPeriode={getPeriodePengembangan}
            // dropdownSifat={getSifatPengembangan}
            // dropdownNamaPengembangan={getNamaPengembangan}
            // dataFilter={dataFilter}
            // setDataFilter={setDataFilter}
          />
        </Col>
        <Col lg={9}>
          <div className="mb-3">
            <KomponenPenghasilan
              action="READ"
              dataDetail={dataDetail}
              // dataFilter={dataFilter}
              // setDataFilter={setDataFilter}
              // getListSuratTugas={getListSuratTugas}
            />
          </div>
        </Col>
      </Row>
    );
  }

  return (
    <>
      <Row>
        <Col lg={3}>
          <SetupData
            action={action}
            dataDetail={dataDetail}
            searchingData={searchingData}
            setSearchingData={setSearchingData}
            dataFilter={dataFilter}
            setDataFilter={setDataFilter}
          />
          {/* <div className="mt-4">
            <SetupData action="READ" dataDetail={dataDetail} />
          </div> */}
        </Col>
        <Col lg={9}>
          <div className="mb-3">
            <PekerjaPenerimaGajiTable dataDetail={dataDetail} dataDetailPost={dataDetailPost} modal={modal} setModal={setModal} />
          </div>
        </Col>
      </Row>
    </>
  );
};
