import React from "react";
import { useFormikContext } from "formik";
import { Input, InfoItemVertical, rupiahConvert, InputCurrency } from "components";

export const Content = ({ action }) => {
    const { values, errors, touched, handleChange, setValues } = useFormikContext();
    const rupiah = rupiahConvert();

    if (action === "READ") {
        return (
            <>
                <InfoItemVertical label="Periode Gajian" text={values.periodeTahun} />
                <InfoItemVertical label="Upah Harian" text={values.upahHarian ? rupiah.getWithComa(values?.upahHarian) : "-"} />
            </>
        );
    }

    return (
        <>
            <Input
                label="Periode Tahun"
                name="periodeTahun"
                placeholder="Masukkan periode tahun"
                value={values.periodeTahun}
                onChange={handleChange}
                error={Boolean(errors.periodeTahun && touched.periodeTahun)}
                errorText={Boolean(errors.periodeTahun && touched.periodeTahun) && errors.periodeTahun}
            />
            <InputCurrency
                label="Upah Harian"
                name="upahHarian"
                placeholder="Masukkan upah harian"
                value={rupiah.getWithComa(values.upahHarian)}
                onChange={(val) => setValues({
                    ...values,
                    upahHarian: val
                })}
                error={Boolean(errors.upahHarian && touched.upahHarian)}
                errorText={Boolean(errors.upahHarian && touched.upahHarian) && errors.upahHarian}
            />
        </>
    );
};
