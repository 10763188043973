export const formSubmitValueMapper = {
    detailData: (data) => ({
        periode_tahun: data?.periodeGajianId,
        tgl_setup: data?.tglSetup,
        tgl_bulan_awal: data?.tglAwal,
        tgl_bulan_akhir: data?.tglAkhir,

        pekerja_id: data?.pekerjaId,
        master_unit_organisasi_id: data?.unitOrganisasiId,
        master_jabatan_id: data?.jabatanId,
        nik: data?.nip,
        status_pekerja: data?.statusPekerja,
        tanggal_pengangkatan: data?.tglPengangkatan,
        tanggal_bergabung: data?.tglBergabung,
        no_npwp: data?.noNpwp,
        tarif_ptkp_id: data?.tarifPtkpId,
        pekerja_status_ptkp_id: data?.pekerjaStatusPtkpId,
        nominal_pajak: data?.nominalPajak,
        status_pajak: data?.statusPajak,

        komponen: data?.detail?.map((val) => ({
            kode: val?.kode,
            nominal: val?.nominal,
            nominal_tahunan: val?.nominalTahunan,
            status: val?.status,
            nama_komponen: val?.komponenNama
        })),

        penghasilan_bruto_bulanan: data?.penghasilanBrutoBulanan,
        penghasilan_bruto_tahunan: data?.penghasilanBrutoTahunan,
        pengurangan_bruto_bulanan: data?.penguranganBrutoBulanan,
        pengurangan_bruto_tahunan: data?.penguranganBrutoTahunan,

        penghasilan_netto_bulanan: data?.penghasilanNettoBulanan,
        penghasilan_netto_tahunan: data?.penghasilanNettoTahunan,
        penghasilan_kena_pajak_bulanan: data?.penghasilanKenaPajakBulanan,
        penghasilan_kena_pajak_tahunan: data?.penghasilanKenaPajakTahunan,
        penghasilan_tidak_kena_pajak: data?.penghasilanTidakKenaPajak,

        pph21_bulanan: data?.pph21Bulanan,
        pph21_tahunan: data?.pph21Tahunan,
        biaya_jabatan: data?.biayaJabatan,
        pph21_sudahdibayar: data?.pph21SudahDibayar,
        selisih_pph21: data?.pph21Selisih
    }),

    modalData: (data) => ({
        pekerja_lembur_perjam_bulanan_id: data?.id,
        komponen_payroll: data?.komponenPayroll,
        periode_tahun: data?.periodeGajianId,
        tgl_bulan_awal: data?.tglAwal,
        tgl_bulan_akhir: data?.tglAkhir,
        tgl_setup: data?.tglSetup,
        pekerja_id: data?.pekerjaId,
        master_unit_organisasi_id: data?.unitOrganisasiId,
        total_upah_ritase_cp: data?.totalUpahRitase,
    }),

    quickPostData: (data) => ({
        periode_tahun: data?.periodeGajianId,
        master_unit_organisasi_id: data?.unitOrganisasiId,
        tgl_setup: data?.tglSetup,
    })
};
