import { Services } from "services";
class RekapCutiApi {
  async getList(params) {
    const fetch = await Services.get("/laporan/rekap-cuti", { params });
    return fetch?.data?.data;
  }

  async getUnitOrganisasi() {
    const fetch = await Services.get("/master/unit-organisasi/dropdown");
    return fetch?.data?.data.map((val) => ({
      value: val?.master_unit_organisasi_id,
      label: val?.master_unit_organisasi_nama
    }));
  }

  async getPeriodePerencanaan() {
    const fetch = await Services.get("/master/periode-perencanaan/dropdown");
    return fetch?.data?.data.map((val) => ({
      value: val?.master_periode_perencanaan_id,
      label: val?.periode_perencanaan
    }));
  }

  async getBulan() {
    return Array.from({ length: 12 }, (item, i) => {
      return {
        value: i + 1,
        label: new Date(0, i).toLocaleString("id-ID", { month: "long" })
      };
    });
  }

  async getExport(params) {
    const fetch = await Services.get("/laporan/rekap-cuti/export", { params });
    return fetch?.data?.data;
  }
}

export default new RekapCutiApi();
