import { GajiKe13List, GajiKe13Create } from "pages/Payroll";

export const GajiKe13 = {
    component: <GajiKe13List />,
    link: "gaji-13",
    name: "Gaji Ke 13",
    access: "PEKERJA_GAJI13",
    child: [
        {
            name: "Tambah",
            link: "/tambah",
            component: <GajiKe13Create />
        }
    ]
};
