import {
  ButtonFilter, ContentLayout, DataStatus, ModalFilter, numberConvert, Select, Table, Tbody,
  Td, Th, Thead, toastTrigger
} from "components";
import { Formik, useFormikContext } from "formik";
import { useState } from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import { FiFileText } from "react-icons/fi";
import { useMutation, useQuery } from "react-query";
import { exportHandler } from "utilities";
import RekapPenghasilanPekerjaApi from "./__RekapPenghasilanPekerjaApi__";

const ModalFilterBody = () => {
  const { values, setValues } = useFormikContext();

  const getUnitOrganisasi = useQuery(["unit-organisasi", "dropdown"], () =>
    RekapPenghasilanPekerjaApi.getUnitOrganisasi()
  );
  const getTanggal = useQuery(
    ["tanggal", "dropdown", values?.periodePerencanaanId],
    () => RekapPenghasilanPekerjaApi.getTanggal({ periode_tahun: values?.periodePerencanaanId }),
    { enabled: Boolean(values?.periodePerencanaanId) }
  );
  const getPeriodePerencanaan = useQuery(["periode-perencanaan", "dropdown"], () =>
    RekapPenghasilanPekerjaApi.getPeriodePerencanaan()
  );

  return (
    <>
      <Select
        label="Periode"
        placeholder="Pilih periode"
        defaultValue={getPeriodePerencanaan?.data?.find((item) => item.value === values.periodePerencanaanId)}
        options={getPeriodePerencanaan?.data ?? []}
        onChange={(val) => setValues({ ...values, periodePerencanaanId: val.value })}
        loading={getPeriodePerencanaan.isFetching}
        errorFetch={getPeriodePerencanaan.isError}
      />
      <Select
        label="Bulan"
        placeholder="Pilih bulan"
        defaultValue={getTanggal?.data?.find((item) => item.value === values.tglId)}
        options={getTanggal?.data ?? []}
        onChange={(val) =>
          setValues({
            ...values,
            tglId: val.value,
            valueAwal: val.valueAwal,
            valueAkhir: val.valueAkhir
          })
        }
        loading={getTanggal.isFetching}
        errorFetch={getTanggal.isError}
      />
      <Select
        label="Unit Organisasi"
        placeholder="Pilih unit organisasi"
        defaultValue={getUnitOrganisasi?.data?.find((item) => item.value === values.unitOrganisasiId)}
        options={getUnitOrganisasi?.data ?? []}
        onChange={(val) => setValues({ ...values, unitOrganisasiId: val.value })}
        loading={getUnitOrganisasi.isFetching}
        errorFetch={getUnitOrganisasi.isError}
      />
    </>
  );
};

export const RekapPenghasilanPekerjaList = () => {
  const number = numberConvert();
  const [searchKey, setSearchKey] = useState(undefined);
  const [filter, setFilter] = useState({
    show: false,
    active: false,
    data: {
      periodePerencanaanId: undefined,
      periodePerencanaan: undefined,
      unitOrganisasiId: undefined,
      unitOrganisasiNama: undefined,
      valueAwal: undefined,
      valueAkhir: undefined
    }
  });

  const getKomponenPayroll = useQuery(["komponen-payroll", "dropdown"], () =>
    RekapPenghasilanPekerjaApi.getKomponenPayroll()
  );
  const kodeWithName = getKomponenPayroll?.data?.map(({ kode, master_komponen_payroll_nama }) => ({
    kode,
    master_komponen_payroll_nama
  }));
  const getRekapPenghasilan = useQuery(["rekap-cuti", "list", searchKey, filter], () =>
    RekapPenghasilanPekerjaApi.getList({
      search: searchKey,
      master_unit_organisasi_id: filter.data?.unitOrganisasiId,
      periode_tahun: filter.data?.periodePerencanaanId,
      tgl_bulan_awal: filter.data?.valueAwal,
      tgl_bulan_akhir: filter.data?.valueAkhir,
      // status_penggajian: filter.data?.statusPenggajianId
    })
  );
  const getRekapPenghasilanExport = useMutation(
    (type) =>
      RekapPenghasilanPekerjaApi.getExport({
        to: type,
        master_unit_organisasi_id: filter.data?.unitOrganisasiId,
        periode_tahun: filter.data?.periodePerencanaanId,
        tgl_bulan_awal: filter.data?.valueAwal,
        tgl_bulan_akhir: filter.data?.valueAkhir,
        // status_penggajian: filter.data?.statusPenggajianId
      }),
    {
      onSuccess: (link) => {
        toastTrigger({ variant: "success", message: "Export berhasil" });
        exportHandler(link, "Data Rekap Penghasilan");
      },
      onError: () => {
        toastTrigger({ variant: "danger", message: "Export gagal" });
      }
    }
  );
  const getTotal = getRekapPenghasilan?.data?.akumulasi;

  const { master_unit_organisasi_nama, pekerja_nama, master_jabatan_nama, nip, PENGHASILAN_NETTO, ...keys } =
    getRekapPenghasilan?.data?.data?.[0] || {};
  const headKeys = Object.values(keys) ?? [];
  const column = headKeys
    ?.map((key) => Object.keys(key))
    .flat()
    .map((kode) => kodeWithName.find((k) => k.kode === kode));
  const headColumn = column?.map((head) => head?.master_komponen_payroll_nama);
  // const searchHandler = (e) => {
  //   setSearchKey(e?.target?.value ? e.target.value : undefined);
  // };
  const getInfoPekerja = getRekapPenghasilan?.data?.data;

  return (
    <ContentLayout showBreadcrumb>
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-end mb-3">
          {/* <div style={{ width: 300 }} className="me-2">
            <InputSearch onChange={debounce(searchHandler, 1500)} />
          </div> */}
          <div>
            <ButtonFilter
              active={filter?.active}
              className="text-nowrap"
              onClick={() => setFilter({ ...filter, show: true })}
            />
          </div>
        </div>
        <div className="d-flex align-items-end mb-3">
          <Dropdown align="end">
            <Dropdown.Toggle
              size="sm"
              variant="outline-success"
              className="mx-2"
              disabled={getRekapPenghasilanExport.isLoading}
            >
              {getRekapPenghasilanExport.isLoading ? (
                <Spinner size="sm" className="me-1" animation="border" />
              ) : (
                <FiFileText className="mb-1 mr-3" />
              )}
              <span className="mx-1">{getRekapPenghasilanExport.isLoading ? "Memuat..." : "EXPORT"}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="mt-2">
              <Dropdown.Item onClick={() => getRekapPenghasilanExport.mutate("excel")}>Export Excel</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <Table>
        <Thead>
          <tr>
            <Th rowSpan={2}>No</Th>
            <Th rowSpan={2} minWidth={150}>
              NIK
            </Th>
            <Th rowSpan={2} minWidth={260}>
              Pekerja
            </Th>
            <Th rowSpan={2} minWidth={260}>
              Jabatan
            </Th>
            <Th colSpan={9}>PENGHASILAN</Th>
            <Th colSpan={2}>PENYESUAIAN</Th>
            <Th colSpan={8}>PEMOTONGAN</Th>
            <Th rowSpan={2} minWidth={150}>
              Gaji Netto
            </Th>
          </tr>
          <tr>
            {headColumn.map((head) => (
              <Th minWidth={150}>{head}</Th>
            ))}
          </tr>
        </Thead>
        <Tbody>
          {filter.data?.unitOrganisasiId === undefined ||
            filter.data?.periodePerencanaanId === undefined ||
            filter.data?.valueAwal === undefined ||
            filter.data?.valueAkhir === undefined ? (
            <tr>
              <Td textCenter colSpan={100}>
                Tidak ada data
              </Td>
            </tr>
          ) : getRekapPenghasilan?.isFetching ? (
            <tr>
              <Td textCenter colSpan={32}>
                <DataStatus loading={getRekapPenghasilan?.isFetching} text="...Memuat" />
              </Td>
            </tr>
          ) : (
            getRekapPenghasilan?.data?.data?.map((item, index) => {
              const { master_unit_organisasi_nama, pekerja_nama, master_jabatan_nama, nip, ...keys } = item;
              const data = Object.values(keys)
                .map((val) => Object.values(val))
                .flat();
              const combinedData = [...data, keys?.PENGHASILAN_NETTO];

              return (
                <>
                  {/* <tr>
                    <Td colSpan={headColumn?.length + 3}>
                      <b>{item.master_unit_organisasi_nama}</b>
                    </Td>
                  </tr> */}
                  <tr>
                    <Td textCenter>{parseFloat(index + 1).toString()}</Td>
                    <Td>{getInfoPekerja?.[index]?.nip ?? "-"}</Td>
                    <Td>{getInfoPekerja?.[index]?.pekerja_nama ?? "-"}</Td>
                    <Td>{getInfoPekerja?.[index]?.master_jabatan_nama ?? "-"}</Td>
                    {combinedData?.map((e) => (
                      <Td textRight>{e ? "Rp" + number.getWithComa(e, { minimumFractionDigits: 2 }) : "0"}</Td>
                    ))}
                  </tr>
                </>
              );
            })
          )}
          {getRekapPenghasilan?.status === "success" && (
            <tr>
              <Td colSpan={4}>Total</Td>
              {/* Penghasilan */}
              <Td textRight>
                {getTotal?.KP01 ? "Rp" + number.getWithComa(getTotal?.KP01, { minimumFractionDigits: 2 }) : "0"}
              </Td>
              <Td textRight>
                {getTotal?.KP02 ? "Rp" + number.getWithComa(getTotal?.KP02, { minimumFractionDigits: 2 }) : "0"}
              </Td>
              <Td textRight>
                {getTotal?.KP03 ? "Rp" + number.getWithComa(getTotal?.KP03, { minimumFractionDigits: 2 }) : "0"}
              </Td>
              <Td textRight>
                {getTotal?.KP04 ? "Rp" + number.getWithComa(getTotal?.KP04, { minimumFractionDigits: 2 }) : "0"}
              </Td>
              <Td textRight>
                {getTotal?.KP05 ? "Rp" + number.getWithComa(getTotal?.KP05, { minimumFractionDigits: 2 }) : "0"}
              </Td>
              <Td textRight>
                {getTotal?.KP06 ? "Rp" + number.getWithComa(getTotal?.KP06, { minimumFractionDigits: 2 }) : "0"}
              </Td>
              <Td textRight>
                {getTotal?.KP07 ? "Rp" + number.getWithComa(getTotal?.KP07, { minimumFractionDigits: 2 }) : "0"}
              </Td>
              <Td textRight>
                {getTotal?.KP08 ? "Rp" + number.getWithComa(getTotal?.KP08, { minimumFractionDigits: 2 }) : "0"}
              </Td>
              <Td textRight>
                {getTotal?.KP09 ? "Rp" + number.getWithComa(getTotal?.KP09, { minimumFractionDigits: 2 }) : "0"}
              </Td>

              {/* Penyesuaian */}
              <Td textRight>
                {getTotal?.KP10 ? "Rp" + number.getWithComa(getTotal?.KP10, { minimumFractionDigits: 2 }) : "0"}
              </Td>
              <Td textRight>
                {getTotal?.KP17 ? "Rp" + number.getWithComa(getTotal?.KP17, { minimumFractionDigits: 2 }) : "0"}
              </Td>

              {/* Pemotongan */}
              <Td textRight>
                {getTotal?.KP11 ? "Rp" + number.getWithComa(getTotal?.KP11, { minimumFractionDigits: 2 }) : "0"}
              </Td>
              <Td textRight>
                {getTotal?.KP12 ? "Rp" + number.getWithComa(getTotal?.KP12, { minimumFractionDigits: 2 }) : "0"}
              </Td>
              <Td textRight>
                {getTotal?.KP13 ? "Rp" + number.getWithComa(getTotal?.KP13, { minimumFractionDigits: 2 }) : "0"}
              </Td>
              <Td textRight>
                {getTotal?.KP14 ? "Rp" + number.getWithComa(getTotal?.KP14, { minimumFractionDigits: 2 }) : "0"}
              </Td>
              <Td textRight>
                {getTotal?.KP15 ? "Rp" + number.getWithComa(getTotal?.KP15, { minimumFractionDigits: 2 }) : "0"}
              </Td>
              <Td textRight>
                {getTotal?.KP16 ? "Rp" + number.getWithComa(getTotal?.KP16, { minimumFractionDigits: 2 }) : "0"}
              </Td>

              <Td textRight>
                {getTotal?.KP18 ? "Rp" + number.getWithComa(getTotal?.KP18, { minimumFractionDigits: 2 }) : "0"}
              </Td>
              <Td textRight>
                {getTotal?.KP19 ? "Rp" + number.getWithComa(getTotal?.KP19, { minimumFractionDigits: 2 }) : "0"}
              </Td>

              <Td textRight>
                {getTotal?.PENGHASILAN_NETTO
                  ? "Rp" + number.getWithComa(getTotal?.PENGHASILAN_NETTO, { minimumFractionDigits: 2 })
                  : "0"}
              </Td>
            </tr>
          )}
        </Tbody>
      </Table>

      <Formik
        initialValues={{
          periodeId: filter?.data?.periodeId,
          valueAwal: filter?.data?.valueAwal,
          valueAkhir: filter?.data?.valueAkhir,
          unitOrganisasiId: filter?.data?.unitOrganisasiId
        }}
      >
        {({ resetForm, values }) => (
          <ModalFilter
            show={filter.show}
            onReset={resetForm}
            onHide={() =>
              setFilter({
                ...filter,
                show: false
              })
            }
            onSubmit={() => {
              setFilter({
                show: false,
                active: Boolean(Object.values(values).find((val) => val)),
                data: values
              });
            }}
          >
            <ModalFilterBody />
          </ModalFilter>
        )}
      </Formik>
    </ContentLayout>
  );
};
