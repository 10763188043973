import { useQuery } from "react-query";
import { Formik, useFormikContext } from "formik";
import { errorFetchingSelectMapper } from "utilities";
import * as Yup from "yup";
import { InfoItemVertical, TableList, ButtonCreate, ButtonDelete, Select } from "components";
import UraianKPIApi from "../__UraianKPIApi__";
import { useModalConfirm } from "hooks";

const DetailTabTarget = () => {
  const parents = useFormikContext();
  return (
    <>
      <InfoItemVertical
        label="Target"
        text={
          <>
            <ol>
              {parents.values.target.map((item) => (
                <li>{item.targetKpiNama}</li>
              ))}
            </ol>
          </>
        }
      />
    </>
  );
};

const FormTabTarget = () => {
  const parents = useFormikContext();
  const getTarget = useQuery(["target", "dropdown"], () => UraianKPIApi.getTargetKPI());

  const submitDetailTarget = (values, { resetForm }) => {
    parents.setValues({
      ...parents.values,
      target: [values, ...parents.values.target]
    });

    resetForm();
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{ targetKpiId: "", targetKpiNama: "" }}
        validationSchema={Yup.object().shape({ targetKpiId: Yup.string().required() })}
        onSubmit={submitDetailTarget}
      >
        {({ errors, touched, handleSubmit, setValues }) => (
          <div className="d-flex align-items-end mb-3">
            <div style={{ width: "50%" }} className="me-2">
              {/* Target */}

              <Select
                key={parents.values?.target?.length}
                label="Target"
                placeholder="Pilih target"
                defaultValue={""}
                options={getTarget?.data?.filter(
                  ({ value }) => !parents.values.target.map((d) => d.targetKpiId).includes(value)
                )}
                onChange={(val) =>
                  setValues({
                    targetKpiId: val.value,
                    targetKpiNama: val.label
                  })
                }
                loading={getTarget.isFetching}
                error={Boolean(errors.targetKpiId && touched.targetKpiId)}
                errorFetch={getTarget.isError}
                errorFetchText={errorFetchingSelectMapper(getTarget.error)}
              />
            </div>
            <div style={{ width: "100%" }} className="me-2">
              <ButtonCreate noText icon={true} tooltip={false} className="mb-2" onClick={handleSubmit} />
            </div>
          </div>
        )}
      </Formik>
    </>
  );
};

const TabelTabTarget = () => {
  const parents = useFormikContext();
  const modalConfirm = useModalConfirm();

  const DetailDeleteTabTarget = ({ index }) => {
    const datatargetkpinama = parents.values.target.map((item) => item.targetKpiNama);

    return (
      <>
        <InfoItemVertical label="Target KPI Nama" text={datatargetkpinama[index]} />
      </>
    );
  };

  const deleteDataTarget = (index) => {
    const temporarys = [...parents.values.target];
    temporarys.splice(index, 1);
    parents.setValues({
      ...parents.values,
      target: temporarys
    });
  };

  const TABLE_HEADER = [
    {
      text: "Target"
    },
    {
      text: "Aksi",
      props: {
        fixed: true,
        width: 50
      }
    }
  ];

  const TABLE_BODY = [
    {
      field: "targetKpiNama"
    },
    {
      props: {
        fixed: true,
        className: "text-center"
      },
      customField: (_data, index) => (
        <>
          <ButtonDelete
            icon
            noText
            onClick={() =>
              modalConfirm.trigger({
                size: "md",
                type: "delete",
                alert: true,
                component: <DetailDeleteTabTarget index={index} />,
                onSubmit: () => {
                  deleteDataTarget(index);
                  modalConfirm.close();
                },
                onHide: () => modalConfirm.close()
              })
            }
          />
        </>
      )
    }
  ];

  return (
    <>
      <TableList
        key={parents.values?.target?.length}
        data={parents.values?.target}
        tableHeader={TABLE_HEADER}
        tableBody={TABLE_BODY}
      />
    </>
  );
};

export const TabTarget = ({ action }) => {
  if (action === "READ") {
    return (
      <>
        <DetailTabTarget />
      </>
    );
  } else {
    return (
      <>
        <FormTabTarget />
        <TabelTabTarget />
      </>
    );
  }
};
