import React, { useState, useEffect } from "react";
import { Dropdown, Collapse, Badge } from "react-bootstrap";
import { FiMinus, FiCircle, FiMenu, FiGitCommit, FiChevronRight, FiGlobe } from "react-icons/fi";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useLayout, useAuth } from "../../hooks";

export const CustomLayout = ({
  MODUL,
  UNIT,
  LOGO,
  ICON,
  APP_NAME,
  SIDEBAR,
  NOTIF,
  withLandingPage = true,
  children
}) => {
  const layout = useLayout();
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const openSidebarHandler = () => setIsSidebarOpen(true);
  const closeSidebarHandler = () => setIsSidebarOpen(false);
  const checkShowSidebar = (value) => Boolean(value?.read);
  const checkIsHavingSubMenuSidebar = (value) => Boolean(value?.subMenu);

  const checkIsSidebarHaveNotif = (value) => {
    const { hak } = value;
    let totalNotif = 0;

    if (NOTIF) {
      for (const props in NOTIF) {
        if (hak.find((find) => find === props)) {
          totalNotif = totalNotif + NOTIF[props];
        }
      }
    }

    return totalNotif > 0 ? totalNotif : undefined;
  };

  const checkIsSidebarNavActive = (value) => {
    let link = value.link;

    if (isSidebarOpen) {
      if (value.child) {
        const removeParamsFromLink = (link) =>
          link
            .split("/")
            .map((val) => (val.search(":") ? val : undefined))
            .filter((val) => val !== undefined)
            .join("/");
        const checkIsChildLinkActive = Boolean(
          value.child.find(
            (val) => removeParamsFromLink(link + val.link) + "/" === layout.getActivePageRouteWithoutParams()
          )
        );

        if (checkIsChildLinkActive) return true;
      }

      return Boolean(link + "/" === layout.getActivePageRouteWithoutParams());
    }

    return false;
  };

  const checkIsSidebarNavDropdownActive = (value) => {
    const { subMenu } = value;

    if (isSidebarOpen) {
      for (const value of subMenu) {
        if (checkIsSidebarNavActive(value)) return true;

        if (Boolean(value.subMenu)) {
          const subMenuChild = value.subMenu;

          for (const valueChild of subMenuChild) {
            if (checkIsSidebarNavActive(valueChild)) return true;
          }
        }
      }
    }

    return false;
  };

  const onSidebarNavClickedHandler = (value) => {
    const { link, name, breadcrumb } = value;

    navigate(link);
    closeSidebarHandler();
    layout.setValue({
      activePageName: name,
      activePageRoute: link,
      breadcrumb: breadcrumb
    });
  };

  const setupUseLayoutValue = () => {
    let pageName = "";
    let breadcrumb = [];
    let canRead = false;
    let canWrite = false;
    let canDelete = false;
    const sidebarConfig = SIDEBAR;

    const removeParamsFromLink = (link) =>
      link
        .split("/")
        .map((val) => (val.search(":") ? val : undefined))
        .filter((val) => val !== undefined)
        .join("/");
    const checkIsChildrenHaveActivePage = (link) =>
      removeParamsFromLink(link) + "/" === layout.getActivePageRouteWithoutParams();
    const checkAlias = (alias, name) => (alias ? alias : name);

    // Looping SIDEBAR array sehingga menghasilkan page name & breadcrumb
    for (const parent of sidebarConfig) {
      if (parent.child) {
        for (const parentChild of parent.child) {
          if (checkIsChildrenHaveActivePage(`${parent.link}${parentChild.link}`)) {
            const newPageName = parentChild.name + " " + parent.name;

            pageName = newPageName;
            breadcrumb.push(parentChild.name, checkAlias(parentChild.alias, parentChild.name));
            canRead = Boolean(parentChild?.read);
            canWrite = Boolean(parentChild?.write);
            canDelete = Boolean(parentChild?.delete);

            break;
          }
        }
      }

      if (checkIsChildrenHaveActivePage(parent.link)) {
        pageName = parent.name;
        breadcrumb.push(parent.name);
        canRead = Boolean(parent?.read);
        canWrite = Boolean(parent?.write);
        canDelete = Boolean(parent?.delete);

        break;
      }

      if (parent.subMenu) {
        for (const child of parent.subMenu) {
          if (child.child) {
            for (const childChild of child.child) {
              if (checkIsChildrenHaveActivePage(`${child.link}${childChild.link}`)) {
                const newPageName = childChild.name + " " + child.name;
                pageName = newPageName;
                breadcrumb.push(
                  parent.name,
                  checkAlias(child.alias, child.name),
                  checkAlias(childChild.alias, childChild.name)
                );
                canRead = Boolean(child?.read);
                canWrite = Boolean(child?.write);
                canDelete = Boolean(child?.delete);

                break;
              }
            }
          }

          if (checkIsChildrenHaveActivePage(child.link)) {
            pageName = child.name;
            breadcrumb.push(parent.name, checkAlias(child.alias, child.name));
            canRead = Boolean(child?.read);
            canWrite = Boolean(child?.write);
            canDelete = Boolean(child?.delete);

            break;
          }

          if (child.subMenu) {
            for (const grandChild of child.subMenu) {
              if (grandChild.child) {
                for (const grandChildChild of grandChild.child) {
                  if (checkIsChildrenHaveActivePage(`${grandChild.link}${grandChildChild.link}`)) {
                    const newPageName = grandChildChild.name + " " + grandChild.name;

                    pageName = newPageName;
                    breadcrumb.push(
                      parent.name,
                      checkAlias(child.alias, child.name),
                      checkAlias(grandChild.alias, grandChild.name),
                      checkAlias(grandChildChild.alias, grandChildChild.name)
                    );
                    canRead = Boolean(grandChild?.read);
                    canWrite = Boolean(grandChild?.write);
                    canDelete = Boolean(grandChild?.delete);

                    break;
                  }
                }
              }

              if (checkIsChildrenHaveActivePage(grandChild.link)) {
                pageName = grandChild.name;
                breadcrumb.push(
                  parent.name,
                  checkAlias(child.alias, child.name),
                  checkAlias(grandChild.alias, grandChild.name)
                );
                canRead = Boolean(grandChild?.read);
                canWrite = Boolean(grandChild?.write);
                canDelete = Boolean(grandChild?.delete);

                break;
              }
            }
          }
        }
      }
    }

    // Merubah setiap karakter pertama menjadi kapital
    const capitalize = (text) => {
      return text.replace(/(^|\s)([a-z])/g, function (_m, p1, p2) {
        return p1 + p2.toUpperCase();
      });
    };

    // Merubah title pada window header
    document.title = `${capitalize(APP_NAME ?? "")} ${pageName ? `| ${capitalize(pageName.toString())}` : ""}`;

    return layout.setValue({
      activePageName: pageName,
      breadcrumb: breadcrumb,
      canRead: canRead,
      canWrite: canWrite,
      canDelete: canDelete
    });
  };

  const SidebarNav = ({ text, textStyle, childNumber, icon, active, notif, ...props }) => {
    const asAccountPage = text.toUpperCase() === "AKUN";
    return (
      <div {...props} className={`nav rounded ${asAccountPage ? "d-none" : ""} ${active ? "active" : ""}`}>
        <div className="d-flex justify-content-between align-items-center" style={{ width: "100%" }}>
          <div className="d-flex align-items-center">
            <div className="mb-1">
              {icon ? icon : childNumber === 1 ? <FiGitCommit /> : childNumber === 2 ? <FiMinus /> : <FiCircle />}
            </div>
            <div
              style={{
                ...textStyle,
                paddingLeft: 10,
                overflowWrap: "break-word",
                whiteSpace: "normal"
              }}
            >
              {text}
            </div>
          </div>
          <div>
            <Badge pill bg="danger" style={{ marginRight: "22px" }} className="ms-1">
              {notif}
            </Badge>
          </div>
        </div>
      </div>
    );
  };

  const SidebarNavDropdown = ({ text, textStyle, childNumber, active, icon, body, notif, ...props }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleNavDropdownHandler = () => setIsOpen(!isOpen);

    useEffect(() => {
      active && setIsOpen(true);
    }, []);

    return (
      <>
        <div
          {...props}
          onClick={toggleNavDropdownHandler}
          aria-controls="sidebar-nav-dropdown"
          aria-expanded={isOpen}
          className={`dropdown-item rounded mt-1 ${isOpen ? "open" : ""}`}
        >
          <div className="d-flex justify-content-between align-items-center" style={{ width: "100%" }}>
            <div className="d-flex align-items-center">
              <div className="mb-1 mr-3">
                {icon ? icon : childNumber === 1 ? <FiGitCommit /> : childNumber === 2 ? <FiMinus /> : <FiCircle />}
              </div>
              <div
                style={{
                  ...textStyle,
                  paddingLeft: 10,
                  paddingRight: 10,
                  overflowWrap: "break-word",
                  whiteSpace: "normal"
                }}
              >
                {text}
              </div>
            </div>
            <div>
              <Badge pill bg="danger" className="mx-1">
                {notif}
              </Badge>
            </div>
          </div>
          <div>
            <FiChevronRight size={18} className="chevron" />
          </div>
        </div>
        <Collapse in={isOpen}>
          <div id="sidebar-nav-dropdown" className="dropdown-body">
            {body}
          </div>
        </Collapse>
      </>
    );
  };

  const TopbarAccount = () => {
    const [isAccountOpen, setIsAccountOpen] = useState(false);
    const openAccountHandler = () => setIsAccountOpen(true);
    const closeAccountHandler = () => setIsAccountOpen(false);

    return (
      <>
        <div className="icon text-uppercase">{auth.getUsername() ? auth.getUsername().split("")[0] : ""}</div>
        <Dropdown
          show={isAccountOpen}
          onClick={openAccountHandler}
          onMouseEnter={openAccountHandler}
          onMouseLeave={closeAccountHandler}
        >
          <Dropdown.Toggle className="dropdown text-uppercase">
            <span className="account-name">{auth.getUsername()}</span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {auth.getUsername().toLowerCase() === "superadmin" || auth.getId() === 0 ? (
              <></>
            ) : (
              <>
                <Dropdown.Item as={Link} to="/akun">
                  Akun
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/akun/ubah">
                  Ubah Akun
                </Dropdown.Item>
                <Dropdown.Divider />
              </>
            )}
            <Dropdown.Item onClick={() => auth.logout()}>Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  };

  useEffect(() => {
    // Merubah favicon pada window header
    let favIcon = document?.querySelector("link[rel~='icon']");
    // favIcon !== null ? (favIcon.href = ICON) : '';
    if (favIcon !== null) {
      favIcon.href = ICON;
    }
  });

  useEffect(() => {
    setupUseLayoutValue();
  }, [location.pathname]);

  return (
    <div className="wrapper">
      {/* ========================== BACKDROP ========================== */}
      {isSidebarOpen && <div className="backdrop" onClick={closeSidebarHandler} />}

      {/* ========================== SIDEBAR ========================== */}
      <div className={`sidebar border ${isSidebarOpen ? "open" : ""}`} onMouseLeave={closeSidebarHandler}>
        <div className="head">
          <div>
            <img src={LOGO} className="logo" />
          </div>
          <div className="title px-3 text-center">
            {/* <b className="text-uppercase">MODUL {MODUL}</b> */}
            <div className="text-capitalize">{UNIT}</div>
          </div>
        </div>
        <div className="body">
          {withLandingPage && (
            <SidebarNav text="Halaman Utama" onClick={() => (window.location.href = "/")} icon={<FiGlobe />} />
          )}
          {SIDEBAR.map((parent, index) =>
            Boolean(checkShowSidebar(parent)) ? (
              !checkIsHavingSubMenuSidebar(parent) ? (
                <SidebarNav
                  key={index}
                  text={parent.name}
                  icon={parent.icon}
                  active={checkIsSidebarNavActive(parent)}
                  notif={checkIsSidebarHaveNotif(parent)}
                  onClick={() =>
                    onSidebarNavClickedHandler({
                      link: parent.link ?? "",
                      name: parent.name,
                      breadcrumb: [parent.name]
                    })
                  }
                />
              ) : (
                <SidebarNavDropdown
                  key={index}
                  text={parent.name}
                  icon={parent.icon}
                  active={checkIsSidebarNavDropdownActive(parent)}
                  body={parent.subMenu?.map((child, index) =>
                    Boolean(checkShowSidebar(child)) ? (
                      !checkIsHavingSubMenuSidebar(child) ? (
                        <SidebarNav
                          key={index}
                          text={child.name}
                          icon={child.icon}
                          style={{ paddingLeft: 30 }}
                          textStyle={{ fontSize: "13px" }}
                          childNumber={1}
                          active={checkIsSidebarNavActive(child)}
                          onClick={() =>
                            onSidebarNavClickedHandler({
                              link: child.link ?? "",
                              name: child.name,
                              breadcrumb: [parent.name, child.alias ?? child.name]
                            })
                          }
                        />
                      ) : (
                        <SidebarNavDropdown
                          key={index}
                          text={child.name}
                          icon={child.icon}
                          style={{ paddingLeft: 30 }}
                          textStyle={{ fontSize: "13px" }}
                          childNumber={1}
                          active={checkIsSidebarNavDropdownActive(child)}
                          body={child.subMenu?.map(
                            (grandChild, index) =>
                              Boolean(checkShowSidebar(grandChild)) && (
                                <SidebarNav
                                  key={index}
                                  text={grandChild.name}
                                  icon={grandChild.icon}
                                  style={{ paddingLeft: 50 }}
                                  textStyle={{ fontSize: "12px" }}
                                  childNumber={2}
                                  active={checkIsSidebarNavActive(grandChild)}
                                  onClick={() =>
                                    onSidebarNavClickedHandler({
                                      link: grandChild.link ?? "",
                                      name: grandChild.name,
                                      breadcrumb: [
                                        parent.name,
                                        child.alias ?? child.name,
                                        grandChild.alias ?? grandChild.name
                                      ]
                                    })
                                  }
                                />
                              )
                          )}
                        />
                      )
                    ) : null
                  )}
                />
              )
            ) : null
          )}
        </div>
      </div>

      {/* ========================== TOPBAR ========================== */}
      <div className="topbar">
        <div
          className="title"
          onClick={openSidebarHandler}
          onMouseEnter={window.innerWidth > 576 ? openSidebarHandler : () => { }}
        >
          <FiMenu size={25} className="toggle" />
          <b className="text">{APP_NAME}</b>
        </div>

        <div className="account">
          <TopbarAccount />
        </div>
      </div>

      {/* ========================== CONTENT ========================== */}
      <div className="content">{children}</div>
    </div>
  );
};
