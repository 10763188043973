import { Services } from "services";
import { fetchDropdownMapper } from "utilities";
const fetchDropdown = fetchDropdownMapper(Services);

class KPIApi {
  async getList(params) {
    const fetch = await Services.get("/master/kpi", { params });
    return fetch?.data?.data;
  }

  async getExport(params) {
    const fetch = await Services.get("/master/kpi/export", { params });
    return fetch?.data?.data;
  }

  async getUnitOrganisasi() {
    const getJenisDropdown = await fetchDropdown("/master/unit-organisasi/dropdown", {
      value: "master_unit_organisasi_id",
      label: "master_unit_organisasi_nama"
    });

    return [{ value: undefined, label: "Semua" }, ...getJenisDropdown];
  }

  create(data) {
    return Services.post("/master/kpi", data);
  }

  update(data, id) {
    return Services.put("/master/kpi/" + id, data);
  }

  delete(id) {
    return Services.delete("/master/kpi/" + id);
  }
}

export default new KPIApi();
