import { Services } from "services";

class LaporanGaji13Api {
    async getList(params) {
        const fetch = await Services.get("/laporan/gaji13", { params });
        return fetch?.data?.data;
    }

    async getUnitOrganisasi() {
        const fetch = await Services.get("/master/unit-organisasi/dropdown");
        return fetch?.data?.data.map((val) => ({
            value: val?.master_unit_organisasi_id,
            label: val?.master_unit_organisasi_nama
        }));
    }

    async getUnitOrganisasiForList() {
        const fetch = await Services.get("/transaksi/gaji13-bulanan/dropdown-unit");
        return fetch?.data?.data.map((val) => ({
            value: val?.master_unit_organisasi_id,
            label: val?.master_unit_organisasi_nama
        }));
    }

    async getPeriode() {
        const fetch = await Services.get("/utility/periode-tahun?feat=pekerja-gaji13");
        return fetch?.data?.data.map((val) => ({
            value: val?.periode_tahun,
            label: val?.periode_tahun
        }));
    }

    async getExport(params) {
        const fetch = await Services.get("/laporan/gaji13/export", { params });
        return fetch?.data?.data;
    }

}

export default new LaporanGaji13Api();
