import { Services } from "services";
import { fetchDropdownMapper } from "utilities";

const fetchDropdown = fetchDropdownMapper(Services);
class JenisKompetensiApi {
  async getList(params) {
    const fetch = await Services.get("/master/jenis-kompetensi", { params });
    return fetch?.data?.data;
  }

  async getJenisKompetensiDropdown() {
    const getDropdown = await fetchDropdown("/master/jenis-kompetensi/dropdown", {
      value: "master_jenis_kompetensi_id",
      label: "master_jenis_kompetensi_nama"
    });
    return [{ value: undefined, label: "Semua" }, ...getDropdown];
  }

  async getExport(params) {
    const fetch = await Services.get("/master/jenis-kompetensi/export", { params });
    return fetch?.data?.data;
  }

  create(data) {
    return Services.post("/master/jenis-kompetensi", data);
  }

  update(data, id) {
    return Services.put("/master/jenis-kompetensi/" + id, data);
  }

  delete(id) {
    return Services.delete("/master/jenis-kompetensi/" + id);
  }
}

export default new JenisKompetensiApi();
