import {
  ButtonDetail, ButtonFilter, dateConvert, InfoItemVertical, InputSearch, ModalDetail,
  ModalFilter, rupiahConvert, Select, toastTrigger
} from "components";
import { Formik, useFormikContext } from "formik";
import { useAccessRights, useLayout } from "hooks";
import { ListLayout } from "layouts";
import { debounce } from "lodash";
import { useState } from "react";
import { ButtonGroup, Col, Row } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { errorFetchingSelectMapper, errorFetchingTableMapper, exportHandler } from "utilities";
import SetupKomponenJaminanKecelakaanKerjaBulananApi from "./__SetupKomponenJaminanKematianBulananApi__";

const ModalFilterBody = () => {
  const { values, setValues } = useFormikContext();

  const getPeriodePenggajian = useQuery(["periode-rekap-order", "dropdown"], () =>
    SetupKomponenJaminanKecelakaanKerjaBulananApi.getPeriodePenggajian()
  );
  const getUnitOrganisasi = useQuery(["unit-organisasi", "dropdown"], () =>
    SetupKomponenJaminanKecelakaanKerjaBulananApi.getUnitOrganisasiForList()
  );

  const getTanggal = useQuery(
    ["tanggal-penggajian", "dropdown", values?.idPeriode],
    () =>
      SetupKomponenJaminanKecelakaanKerjaBulananApi.getTanggal({
        periode_tahun: values?.idPeriode
      }),
    {
      enabled: Boolean(values?.idPeriode)
    }
  );

  return (
    <>
      <Select
        noMargin
        label="Periode Penggajian"
        placeholder="Pilih periode penggajian"
        defaultValue={getPeriodePenggajian?.data?.find((val) => val.value === values.idPeriode)}
        loading={getPeriodePenggajian.isFetching}
        options={getPeriodePenggajian?.data ?? []}
        onChange={(val) => {
          setValues({
            ...values,
            idPeriode: val.value,
            namaPeriode: val.label
          });
        }}
        errorFetch={getPeriodePenggajian.isError}
        errorFetchText={errorFetchingSelectMapper(getPeriodePenggajian.error)}
      />

      <Select
        disable={Boolean(!values?.idPeriode)}
        label="Tanggal Penggajian"
        placeholder="Pilih tanggal penggajian"
        defaultValue={getTanggal?.data?.find((item) => item.value === values?.tglId)}
        options={getTanggal?.data ?? []}
        onChange={(val) =>
          setValues({
            ...values,
            tglId: val.value,
            tglNama: val.label,
            tglAwal: val.tglAwal,
            tglAkhir: val.tglAkhir
          })
        }
        loading={getTanggal.isFetching}
        errorFetch={getTanggal.isError}
      />

      <Select
        label="Unit Organisasi"
        placeholder="Pilih unit organisasi"
        loading={getUnitOrganisasi.isFetching}
        defaultValue={getUnitOrganisasi?.data?.find((val) => val.value === values.idUnitOrganisasi)}
        options={getUnitOrganisasi?.data ?? []}
        onChange={(val) => {
          setValues({
            ...values,
            idUnitOrganisasi: val.value
          });
        }}
        errorFetch={getUnitOrganisasi.isError}
        errorFetchText={errorFetchingSelectMapper(getUnitOrganisasi.error)}
      />
    </>
  );
};

const Detail = () => {
  const { values } = useFormikContext();
  const rupiah = rupiahConvert();
  // const date = dateConvert();

  const monthName = (val) => {
    return new Date(val).toLocaleString('default', { month: 'long' });
  }

  return (
    <>
      <InfoItemVertical label="Komponen Payroll" text={"BPJS TK- Jaminan Kematian"} />
      <InfoItemVertical label="Periode Penggajian" text={values?.namaPeriode} />
      <InfoItemVertical
        label="Bulan Penggajian"
        text={monthName(values?.tglAkhir)}
      />
      <InfoItemVertical label="NIK" text={values?.nik} />
      <InfoItemVertical label="Pekerja" text={values?.namaPekerja} />
      <InfoItemVertical label="Nama Unit Organisasi" text={values?.namaUnitOrganisasi} />
      <InfoItemVertical label="Jabatan" text={values?.namaJabatan} />
      <InfoItemVertical
        label="Upah Tetap BPJS"
        text={values?.upahBpjs ? rupiah?.getWithComa(Number(values?.upahBpjs)) : "-"}
      />
      <InfoItemVertical
        label="Besaran Upah"
        text={values?.besaranUpah ? rupiah?.getWithComa(Number(values?.besaranUpah)) : "-"}
      />
      <InfoItemVertical
        label="Iuran Perusahaan"
        text={values?.iuranPerusahaan ? rupiah?.getWithComa(Number(values?.iuranPerusahaan)) : "-"}
      />
      <InfoItemVertical
        label="Iuran Karyawan"
        text={values?.iuranKaryawan ? rupiah?.getWithComa(Number(values?.iuranKaryawan)) : "-"}
      />
      <InfoItemVertical
        label="Total Iuran BPJS TK - Jaminan Kematian"
        text={values?.totalIuranBpjsTk ? rupiah?.getWithComa(Number(values?.totalIuranBpjsTk)) : "-"}
      />
      <InfoItemVertical label="Jenis BPJS" text={values?.jenis} />
    </>
  );
};

export const SetupKomponenJaminanKematianBulananList = () => {
  const access = useAccessRights("SETUP_JAMINAN_KEMATIAN_BULANAN");
  const layout = useLayout();
  const navigate = useNavigate();
  const [searchKey, setSearchKey] = useState(undefined);
  const rupiah = rupiahConvert();
  const date = dateConvert();
  // const number = numberConvert();

  const searchHandler = (e) => {
    setSearchKey(e?.target?.value ? e.target.value : undefined);
  };

  const [pagination, setPagination] = useState({
    pageCurrent: 1,
    dataLength: 10
  });

  const [filter, setFilter] = useState({
    show: false,
    active: false,
    data: {
      idPeriode: undefined,
      namaPeriode: undefined,
      idBulan: undefined,
      namaBulan: undefined,
      idUnitOrganisasi: undefined,
      tglAwal: undefined,
      tglAkhir: undefined
    }
  });

  const [modal, setModal] = useState({
    show: false,
    action: "",
    data: {}
  });

  const getList = useQuery(
    [
      "setup-komponen-jaminan-kecelakaan-bulanan",
      "list",
      searchKey,
      pagination.pageCurrent,
      pagination.dataLength,
      filter?.data?.idPeriode,
      filter?.data?.idUnitOrganisasi,
      filter?.data?.tglAwal,
      filter?.data?.tglAkhir
    ],
    () =>
      SetupKomponenJaminanKecelakaanKerjaBulananApi.getList({
        search: searchKey,
        periode_tahun: filter?.data?.idPeriode,
        master_unit_organisasi_id: filter?.data?.idUnitOrganisasi,
        tgl_bulan_awal: filter?.data?.tglAwal,
        tgl_bulan_akhir: filter?.data?.tglAkhir,
        page: pagination.pageCurrent,
        limit: pagination.dataLength
      }),
    {
      enabled: Boolean(
        filter?.data?.idPeriode &&
        filter?.data?.tglAwal && filter?.data?.tglAkhir
      )
    }
  );

  const dataInfo = getList?.data?.info;

  const getExportSetupKomponenJaminanKematianBulanan = useMutation(
    (data) =>
      SetupKomponenJaminanKecelakaanKerjaBulananApi.getExport({
        master_unit_organisasi_id: data?.idUnitOrganisasi,
        periode_tahun: data?.idPeriode,
        tgl_bulan_awal: data?.tglAwal,
        tgl_bulan_akhir: data?.tglAkhir
      }),
    {
      onSuccess: (link) => {
        toastTrigger({ variant: "success", message: "Export berhasil" });
        exportHandler(link, "Data Setup Komponen Jaminan Kematian Bulanan");
      },
      onError: () => {
        toastTrigger({ variant: "danger", message: "Export gagal" });
      }
    }
  );

  const onHideModalHandler = () =>
    setModal({
      show: false,
      action: "",
      data: {}
    });

  const onDetailButtonClickHandler = (data) =>
    setModal({
      show: true,
      action: "READ",
      data: {
        ...modal.data,
        namaKomponenPayroll: data?.komponen_payroll,
        namaPeriode: data?.periode_tahun,
        tglAwal: data?.tgl_bulan_awal,
        tglAkhir: data?.tgl_bulan_akhir,
        nik: data?.nip,
        namaPekerja: data?.pekerja_nama,
        namaUnitOrganisasi: data?.master_unit_organisasi_nama,
        namaJabatan: data?.master_jabatan_nama,
        upahBpjs: data?.upah_bpjs,
        tunjanganTetap: data?.final_tunjangan_tetap,
        besaranUpah: data?.besaran_upah,
        iuranPerusahaan: data?.iuran_perusahaan,
        iuranKaryawan: data?.iuran_karyawan,
        totalIuranBpjsTk: data?.total_iuran,
        jenis: data?.jenis
      }
    });

  const onPaginationChange = (data) => setPagination({ ...pagination, pageCurrent: data });

  const onPaginationDataLengthChange = (data) => setPagination({ ...pagination, dataLength: data });

  const onCreateButtonClickHandler = () => navigate("tambah");

  const TABLE_HEADER = [
    {
      text: "NIK"
    },
    {
      text: "Pekerja"
    },
    {
      text: "Unit Organisasi"
    },
    {
      text: "Jabatan"
    },
    {
      text: "Upah Tetap BPJS"
    },
    {
      text: "Besaran Upah"
    },
    {
      text: "Iuran Perusahaan"
    },
    {
      text: "Iuran Pekerja"
    },
    {
      text: "Total Iuran BPJS TK-Jaminan Kematian"
    },
    {
      text: "Jenis BPJS"
    },
    {
      text: "Aksi",
      props: {
        width: 50,
        fixed: true
      }
    }
  ];

  const TABLE_BODY = [
    {
      field: "nip"
    },
    {
      field: "pekerja_nama"
    },
    {
      field: "master_unit_organisasi_nama"
    },
    {
      field: "master_jabatan_nama"
    },
    {
      props: { textRight: true },
      customField: (data) => (data.upah_bpjs ? rupiah.getWithComa(Number(data?.upah_bpjs)) : "-")
    },
    {
      props: { textRight: true },
      customField: (data) => (data.besaran_upah ? rupiah.getWithComa(Number(data?.besaran_upah)) : "-")
    },
    {
      props: { textRight: true },
      customField: (data) => (data.iuran_perusahaan ? rupiah.getWithComa(Number(data?.iuran_perusahaan)) : "-")
    },
    {
      props: { textRight: true },
      customField: (data) => (data.iuran_karyawan ? rupiah.getWithComa(Number(data?.iuran_karyawan)) : "-")
    },
    {
      props: { textRight: true },
      customField: (data) => (data.total_iuran ? rupiah.getWithComa(Number(data?.total_iuran)) : "-")
    },
    {
      field: "jenis"
    },
    {
      props: { fixed: true },
      customField: (data) => (
        <ButtonGroup size="sm">
          {access.canRead() && <ButtonDetail icon noText onClick={() => onDetailButtonClickHandler(data)} />}
        </ButtonGroup>
      )
    }
  ];
  return (
    <>
      <ListLayout
        customLeftTopSection={() => (
          <div className="align-items-end">
            <div className="d-flex align-items-end mb-3">
              <div style={{ width: 300 }} className="me-2">
                <InputSearch onChange={debounce(searchHandler, 1500)} />
              </div>
              <div>
                <ButtonFilter
                  active={filter?.active}
                  className="text-nowrap"
                  onClick={() => setFilter({ ...filter, show: true })}
                />
              </div>
            </div>
            <Row className="mb-4">
              <Col>
                <InfoItemVertical className="me-2" label="Komponen Payroll " text={dataInfo?.komponen_payroll} />
                <InfoItemVertical
                  className="me-2"
                  label="Bulan Penggajian"
                  text={
                    (dataInfo?.bulan_awal_penggajian
                      ? date.getSlashDMY(new Date(dataInfo?.bulan_awal_penggajian))
                      : "-") +
                    " s/d " +
                    (dataInfo?.bulan_akhir_penggajian
                      ? date.getSlashDMY(new Date(dataInfo?.bulan_akhir_penggajian))
                      : "-")
                  }
                />
                {/* <InfoItemVertical
                  className="me-2"
                  label="Proporsi Perusahaan"
                  text={
                    dataInfo?.proporsi_perusahaan
                      ? number.getWithComa(dataInfo?.proporsi_perusahaan, { minimumFractionDigits: 2, maximumFractionDigits: 4 }) + "%"
                      : "-"
                  }
                />
                <InfoItemVertical
                  className="me-2"
                  label="Batas Bawah Upah"
                  text={dataInfo?.batas_bawah ? rupiah.getWithComa(dataInfo?.batas_bawah) : "-"}
                /> */}
              </Col>
              <Col>
                <InfoItemVertical
                  className="me-2"
                  label="Periode Penggajian"
                  text={dataInfo?.periode_perencanaan_penggajian}
                />
                <InfoItemVertical className="me-2" label="Jenis Upah Minimum" text={dataInfo?.jenis_upah_nama} />
                {/* <InfoItemVertical
                  className="me-2"
                  label="Proporsi Pekerja"
                  text={
                    dataInfo?.proporsi_pekerja
                      ? number.getWithComa(dataInfo?.proporsi_pekerja, { minimumFractionDigits: 2, maximumFractionDigits: 4 }) + "%"
                      : "-"
                  }
                />
                <InfoItemVertical
                  className="me-2"
                  label="Batas Atas Upah"
                  text={dataInfo?.batas_atas ? rupiah.getWithComa(dataInfo?.batas_atas) : "-"}
                /> */}
              </Col>
            </Row>
          </div>
        )}
        showExportExcelButton={Boolean(filter?.data?.idPeriode && filter?.data?.idUnitOrganisasi)}
        onExportExcelButtonClick={() => getExportSetupKomponenJaminanKematianBulanan.mutate(filter?.data)}
        loadingExportButton={getExportSetupKomponenJaminanKematianBulanan.isLoading}
        showRightTopButton={access.canCreate() ? true : false}
        loading={getList.isFetching}
        error={getList.isError}
        errorText={errorFetchingTableMapper(getList.error)}
        dataTotal={getList?.data?.total}
        dataLength={pagination.dataLength}
        pageCurrent={pagination.pageCurrent}
        pageTotal={getList?.data?.last_page}
        onCreateButtonClick={onCreateButtonClickHandler}
        onDataLengthChange={onPaginationDataLengthChange}
        onPaginationChange={onPaginationChange}
        data={getList?.data?.data}
        tableHeader={TABLE_HEADER}
        tableBody={TABLE_BODY}
      />
      <Formik enableReinitialize initialValues={modal.data}>
        <ModalDetail
          title={layout.getActivePageName()}
          show={Boolean(modal.show && modal.action === "READ")}
          onHide={onHideModalHandler}
        >
          <Detail />
        </ModalDetail>
      </Formik>

      {/* ModalFilter */}
      <Formik
        initialValues={{
          idPeriode: filter?.data?.idPeriode,
          namaPeriode: filter?.data?.namaPeriode,
          idBulan: filter?.data?.idBulan,
          namaBulan: filter?.data?.namaBulan,
          idUnitOrganisasi: filter?.data?.idUnitOrganisasi,
          tglAwal: filter?.data?.tglAwal,
          tglAkhir: filter?.data?.tglAkhir
        }}
      >
        {({ resetForm, values }) => (
          <ModalFilter
            show={filter.show}
            onReset={resetForm}
            onHide={() =>
              setFilter({
                ...filter,
                show: false
              })
            }
            onSubmit={() => {
              setFilter({
                show: false,
                active: Boolean(Object.values(values).find((val) => val)),
                data: values
              });
            }}
          >
            <ModalFilterBody />
          </ModalFilter>
        )}
      </Formik>
    </>
  );
};
