import React from "react";
import { Card } from "react-bootstrap";
import { Select, ButtonCreate, InfoItemVertical, dateConvert, DatePicker, Input } from "components";
import CreateGajiApi from "../__CreateGajiApi__";
import { useQuery } from "react-query";

const FormSetupData = ({ searchingData, setSearchingData, dataFilter, setDataFilter }) => {
  // const date = dateConvert();

  const getBulan = [
    { value: 1, label: "Januari" },
    { value: 2, label: "Februari" },
    { value: 3, label: "Maret" },
    { value: 4, label: "April" },
    { value: 5, label: "Mei" },
    { value: 6, label: "Juni" },
    { value: 7, label: "Juli" },
    { value: 8, label: "Agustus" },
    { value: 9, label: "September" },
    { value: 10, label: "Oktober" },
    { value: 11, label: "November" },
    { value: 12, label: "Desember" }
  ];
  const getUnitOrganisasi = useQuery(["unit-organisasi-create", "dropdown"], () =>
    CreateGajiApi.getUnitOrganisasiCreate()
  );
  // const getPekerja = useQuery(
  //   ["unit-organisasi", "dropdown", searchingData?.unitOrganisasiId],
  //   () => CreateGajiApi.getPekerja({ master_unit_organisasi_id: searchingData?.unitOrganisasiId }),
  //   { enabled: Boolean(searchingData?.unitOrganisasiId) }
  // );

  return (
    <>
      <Input
        disabled
        label="Periode Penggajian"
        placeholder="Masukkan periode penggajian"
        defaultValue={searchingData?.periodePenggajianId}
        onChange={(val) =>
          setSearchingData({
            ...searchingData,
            periodePenggajianId: val.target.value,
            periodePengembanganNama: val.target.value
          })
        }
      />
      <Select
        label="Bulan Penggajian"
        placeholder="Pilih salah satu.."
        options={getBulan ?? []}
        onChange={(val) => {
          const tglNewStart = val.value <= 1 ?
            `${searchingData?.periodePenggajianId - 1}-12-26`
            : `${searchingData?.periodePenggajianId}-${val?.value < 10 ? `0${val?.value - 1}` : val?.value - 1}-26`;

          const tglNewEnd = `${searchingData?.periodePenggajianId}-${val?.value < 10 ? `0${val?.value}` : val?.value}-25`;

          // const start = new Date(new Date().getFullYear(), val?.value - 1, 1);
          // const end = new Date(new Date().getFullYear(), val?.value, 0);

          setSearchingData({
            ...searchingData,
            bulanId: val.value,
            bulanNama: val.label,
            tanggalMulai: tglNewStart,
            tanggalSelesai: tglNewEnd
          })
        }}
      />
      {/* <div style={{ fontSize: "14px" }}>Tanggal Penggajian</div> */}
      <div className="d-flex align-items-center">
        <div>
          <DatePicker
            disabled
            placeholderText="dd/mm/yy"
            selected={searchingData.tanggalMulai ? new Date(searchingData.tanggalMulai) : undefined}
            onChange={(val) => setSearchingData({ ...searchingData, tanggalMulai: val })}
          />
        </div>
        <small className="mx-2">s/d</small>
        <div>
          <DatePicker
            disabled
            placeholderText="dd/mm/yy"
            selected={searchingData.tanggalSelesai ? new Date(searchingData.tanggalSelesai) : undefined}
            onChange={(val) => setSearchingData({ ...searchingData, tanggalSelesai: val })}
          />
        </div>
      </div>
      <Select
        label="Unit Organisasi"
        placeholder="Pilih salah satu.."
        options={getUnitOrganisasi?.data ?? []}
        onChange={(val) =>
          setSearchingData({ ...searchingData, unitOrganisasiId: val.value, unitOrganisasiNama: val.label })
        }
      />
      <DatePicker
        disabled
        label="Tanggal Setup"
        placeholderText="dd/mm/yy"
        selected={searchingData.tanggalSetup ? new Date(searchingData.tanggalSetup) : undefined}
        onChange={(val) => setSearchingData({ ...searchingData, tanggalSetup: val })}
      />

      {/* <Select
        label="Pekerja"
        placeholder="Pilih salah satu.."
        options={getPekerja?.data ?? []}
        onChange={(val) =>
          setSearchingData({
            ...searchingData,
            pekerjaId: val.value,
            pekerjaNama: val.label,
            jabatanNama: val.valueJabatan
          })
        }
      />
      <Input label="Jabatan" placeholder="Masukkan jabatan" value={searchingData?.jabatanNama} disabled /> */}

      <div className="d-flex justify-content-end">
        <div>
          <ButtonCreate
            text="Cari"
            className="mb-3"
            tooltip={false}
            disabled={
              searchingData.tanggalSetup === undefined ||
              searchingData.periodePenggajianId === undefined ||
              searchingData.bulanId === undefined ||
              searchingData.tanggalMulai === undefined ||
              searchingData.tanggalSelesai === undefined ||
              searchingData.unitOrganisasiId === undefined
              // searchingData.pekerjaId === undefined
            }
            onClick={() => {
              setDataFilter({
                ...dataFilter,
                tanggalSetup: searchingData.tanggalSetup,
                periodePenggajianId: searchingData.periodePenggajianId,
                bulanId: searchingData.bulanId,
                tanggalMulai: searchingData.tanggalMulai,
                tanggalSelesai: searchingData.tanggalSelesai,
                unitOrganisasiId: searchingData.unitOrganisasiId,
                pekerjaId: searchingData.pekerjaId
              });
            }}
          />
        </div>
      </div>
    </>
  );
};

export const SetupData = ({ action, dataDetail, searchingData, setSearchingData, dataFilter, setDataFilter }) => {
  const date = dateConvert();

  if (action === "READ") {
    return (
      <Card>
        <Card.Header>
          <b>Data Pekerja</b>
        </Card.Header>
        <Card.Body>
          {/* <InfoItemVertical
            label="Tanggal Setup"
            text={dataDetail?.tgl_setup ? date.getSlashDMY(new Date(dataDetail?.tgl_setup)) : "-"}
          /> */}
          <InfoItemVertical label="Periode Penggajian" text={dataDetail?.periode_tahun ?? "-"} />
          <InfoItemVertical
            label="Bulan Penggajian"
            text={
              dataDetail?.tgl_bulan_awal && dataDetail?.tgl_bulan_akhir
                ? `${date.getSlashDMY(new Date(dataDetail?.tgl_bulan_awal))} s/d ${date.getSlashDMY(
                  new Date(dataDetail?.tgl_bulan_akhir)
                )}`
                : "-"
            }
          />
          <InfoItemVertical
            label="Tanggal Setup"
            text={dataDetail?.tgl_setup ? date.getSlashDMY(new Date(dataDetail?.tgl_setup)) : "-"}
          />
          {/* <InfoItemVertical label="Unit Organisasi" text={dataDetail?.master_unit_organisasi_nama ?? "-"} />
          <InfoItemVertical label="NIK" text={dataDetail?.nip ?? "-"} />
          <InfoItemVertical label="Pekerja" text={dataDetail?.pekerja_nama ?? "-"} />
          <InfoItemVertical label="Jabatan" text={dataDetail?.master_jabatan_nama ?? "-"} />
          <InfoItemVertical label="Status Pekerja" text={dataDetail?.status_pekerja ?? "-"} />
          <InfoItemVertical label="Status Pajak" text={dataDetail?.status_pajak ?? "-"} />
          <InfoItemVertical label="NPWP" text={dataDetail?.no_npwp ?? "-"} /> */}
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card>
      <Card.Header>
        <b>Data Pekerja</b>
      </Card.Header>
      <Card.Body>
        <FormSetupData
          searchingData={searchingData}
          setSearchingData={setSearchingData}
          dataFilter={dataFilter}
          setDataFilter={setDataFilter}
        />
      </Card.Body>
    </Card>
  );
};
