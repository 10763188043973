import React from "react";
import { useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { Formik } from "formik";
import { ButtonBack, DataStatus, ContentLayout } from "components";
import KoreksiKerjaApi from "./__KoreksiKerjaApi__";
import { Content } from "./__KoreksiKerjaComps__";
import { formInitialValues } from "./__KoreksiKerjaUtils__";
import { errorFetchingPageMapper } from "utilities";

export const KoreksiKerjaDetail = () => {
  const location = useLocation();
  const api = location.state;
  const { id } = useParams();
  const navigate = useNavigate();
  const getSingle = useQuery(["single-cash-setup-advance", id], () =>
    KoreksiKerjaApi.getSingle({
      idPeriode: api.periode_gajian_id,
      idPekerja: id,
      idOrganisasi: api.master_unit_organisasi_id,
      params: { tgl_bulan_awal: api.tglAwal, tgl_bulan_akhir: api.tglAkhir }
    })
  );

  return (
    <>
      <ContentLayout showBreadcrumb>
        <div className="text-end mb-3">
          <ButtonBack tooltip={false} onClick={() => navigate(-1)} />
        </div>
        {getSingle.isFetching || getSingle.isError ? (
          <DataStatus loading={getSingle.isFetching} text={errorFetchingPageMapper(getSingle.error)} />
        ) : (
          <Formik enableReinitialize initialValues={formInitialValues.Form(getSingle?.data)}>
            {() => {
              return (
                <>
                  <Content action="READ" refreshData={getSingle.refetch} />
                </>
              );
            }}
          </Formik>
        )}
      </ContentLayout>
    </>
  );
};
