export const formInitialValues = (data = {}) => ({
  id: data?.master_alasan_phk_id,
  periodePenggajianId: data?.periode_tahun ?? new Date().getFullYear(),
  periodePenggajianNama: data?.periode_tahun ?? new Date().getFullYear(),
  kode: data?.kode,
  alasanPHK: data?.nama,
  pesangon: data?.fm_pesangon,
  upahPenghargaanMasaKerja: data?.fm_upmk,
  upahPenggantiHAK: data?.fm_uph,
  upahPisah: data?.fm_up
});
