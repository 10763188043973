export const formSubmitValueMapper = (data) => ({
    array: data?.detail?.map((val) => ({
        periode_tahun: val?.periodeGajianId,
        tgl_setup: val?.tglSetup,
        tgl_bulan_awal: val?.tglAwal,
        tgl_bulan_akhir: val?.tglAkhir,
        pekerja_id: val?.pekerjaId,
        masa_kerja: val?.masaKerja,
        master_unit_organisasi_id: val?.unitOrganisasiId,
        tanggal_bergabung: val?.tglBergabung,
        upah_pokok: val?.upahPokok,
        tunjangan_tetap: val?.tunjanganTetap,
        gaji13: val?.nominal,
    })),
    object: {
        periode_tahun: data?.periodeGajianId,
        tgl_setup: data?.tglSetup,
        tgl_bulan_awal: data?.tglAwal,
        tgl_bulan_akhir: data?.tlAkhir,
        pekerja_id: data?.pekerjaId,
        master_unit_organisasi_id: data?.unitOrganisasiId,
        tanggal_bergabung: data?.tglBergabung,
        masa_kerja: data?.masaKerja,
        upah_pokok: data?.upahPokok,
        tunjangan_tetap: data?.tunjanganTetap,
        gaji13: data?.nominal,
    }
});
