import { dateConvert } from "components"

const date = dateConvert();

export const formInitialValues = (pekerjaList = [], data = {}) => ({
    id: data?.time_sheet_cp_id,
    periodeTahun: data?.periode_tahun ?? new Date().getFullYear(),
    tglNota: data?.tgl_nota ?? date.getDashYMD(new Date()),
    noNota: data?.nomor_nota,
    customerId: data?.customer_id,
    customerNama: data?.customer_nama,
    proyekId: data?.proyek_id,
    proyekNama: data?.proyek_nama,
    proyekAlamat: data?.alamat,

    jamMasuk: data?.masuk_proyek_jam,
    jamMulai: data?.mulai_proyek_jam,
    jamSelesai: data?.selesai_jam,
    jamKeluar: data?.keluar_proyek_jam,
    totalJam: data?.total_jam_keluar,

    jamMasukFormat: data?.masuk_proyek_jam_format,
    jamMulaiFormat: data?.mulai_proyek_jam_format,
    jamSelesaiFormat: data?.selesai_jam_format,
    jamKeluarFormat: data?.keluar_proyek_jam_format,
    totalJamFormat: data?.total_jam_keluar_format,

    sambunganPipa: data?.sambungan_pipa,
    volume: data?.volume,
    kmAwal: data?.km_awal,
    kmAkhir: data?.km_akhir,
    totalKm: data?.total_km ?? 0,

    detail: pekerjaList?.map((val) => ({
        pekerjaId: val?.pekerja_id,
        pekerjaNama: val?.pekerja_nama,
        jabatanId: val?.master_jabatan_id,
        jabatanNama: val?.master_jabatan_nama,
        checked: data?.crew?.some((obj) => obj.pekerja_id === val?.pekerja_id),
    })) ?? []

})
