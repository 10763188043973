import { Services } from "services";

class PeranPekerjaApi {
  async getList(params) {
    const fetch = await Services.get("/master/peran-pekerja/", { params });
    return fetch?.data?.data;
  }

  async getExport(params) {
    const fetch = await Services.get("/master/peran-pekerja/export", { params });
    return fetch?.data?.data;
  }

  create(data) {
    return Services.post("/master/peran-pekerja", data);
  }

  update(data, id) {
    return Services.put("/master/peran-pekerja/" + id, data);
  }

  delete(id) {
    return Services.delete("/master/peran-pekerja/" + id);
  }
}
export default new PeranPekerjaApi();
