import { InfoItemVertical, Input, Select } from "components";
import { useFormikContext } from "formik";
import { useQuery } from "react-query";
import JadwalRegularApi from "../__JadwalRegularApi__";

export const Content = ({ action }) => {
    const { values, errors, touched, handleChange, setValues } = useFormikContext();

    const getShift = useQuery(["shift", "dropdown"], () =>
        JadwalRegularApi.getShift()
    );

    if (action === "READ") {
        return (
            <>
                <InfoItemVertical label="Hari" text={values.hariNama} />
                <InfoItemVertical label="Shift" text={(values.shiftNama)} />
            </>
        );
    }

    return (
        <>
            <Input
                disabled
                label="Hari"
                name="hari"
                placeholder="Masukkan hari"
                value={values.hariNama}
                onChange={handleChange}
                error={Boolean(errors.hariNama && touched.hariNama)}
                errorText={Boolean(errors.hariNama && touched.hariNama) && errors.hariNama}
            />
            <Select
                label="Shift"
                placeholder="Pilih Shift"
                defaultValue={values.shiftId ? getShift?.data?.find((item) => item.value === values.shiftId) : null}
                options={getShift?.data ?? []}
                onChange={(val) => setValues({
                    ...values,
                    shiftId: val.value,
                    shiftNama: val.label,
                    jamIn: val.jamIn,
                    jamOut: val.jamOut
                })}
                loading={getShift.isFetching}
                errorFetch={getShift.isError}
                error={Boolean(errors.shiftId && touched.shiftId)}
                errorText={Boolean(errors.shiftId && touched.shiftId) && errors.shiftId}
            />
        </>
    );
};
