import { useState } from "react";
import { ButtonGroup } from "react-bootstrap";
import { useQuery, useMutation } from "react-query";
import { Form, Formik } from "formik";
import { debounce } from "lodash";
import { ListLayout } from "layouts";
import {
  ButtonDetail,
  ButtonUpdate,
  ButtonDelete,
  ModalDetail,
  ModalCreate,
  ModalUpdate,
  toastTrigger
} from "components";
import { useLayout, useModalConfirm, useAccessRights } from "hooks";
import { errorSubmitMapper, errorFetchingTableMapper, exportHandler } from "utilities";
import { formInitialValues, formValidationSchema, formSubmitMapper } from "./__JenisKelaminUtils__";
import JenisKelaminApi from "./__JenisKelaminApi__";
import { Content } from "./__JenisKelaminComps__";

export const JenisKelaminList = () => {
  const layout = useLayout();
  const access = useAccessRights("MASTER_JENIS_KELAMIN");
  const modalConfirm = useModalConfirm();
  const [searchKey, setSearchKey] = useState(undefined);
  const [pagination, setPagination] = useState({
    pageCurrent: 1,
    dataLength: 10
  });
  const [modal, setModal] = useState({
    show: false,
    type: "",
    data: {}
  });

  const getJenisKelamin = useQuery(
    ["jenis-kelamin", "list", searchKey, pagination.pageCurrent, pagination.dataLength],
    () =>
      JenisKelaminApi.getList({
        search: searchKey,
        page: pagination.pageCurrent,
        limit: pagination.dataLength
      })
  );

  const createJenisKelamin = useMutation((data) => JenisKelaminApi.create(data), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "create" });
      getJenisKelamin.refetch();
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "create", ...errorSubmitMapper(err) })
  });

  const updateJenisKelamin = useMutation(({ data, id }) => JenisKelaminApi.update(data, id), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "update" });
      getJenisKelamin.refetch();
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "update", ...errorSubmitMapper(err) })
  });

  const deleteJenisKelamin = useMutation((id) => JenisKelaminApi.delete(id), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "delete" });
      getJenisKelamin.refetch();
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "delete", ...errorSubmitMapper(err) })
  });

  const getExportJenisKelamin = useMutation((type) => JenisKelaminApi.getExport({ to: type }), {
    onSuccess: (link) => {
      toastTrigger({ variant: "success", message: "Export berhasil" });
      exportHandler(link, "Data Jenis Kelamin");
    },
    onError: () => {
      toastTrigger({ variant: "danger", message: "Export gagal" });
    }
  });

  const searchHandler = (e) => {
    setSearchKey(e?.target?.value ? e.target.value : undefined);
  };

  const onHideModalHandler = () => setModal({ show: false, action: "", data: {}, deleteLoading: false });

  const onCreateButtonClickHandler = () => setModal({ show: true, action: "CREATE", data: {} });

  const onDetailButtonClickHandler = (data) => setModal({ show: true, action: "READ", data: data });

  const onUpdateButtonClickHandler = (data) => setModal({ show: true, action: "UPDATE", data: data });

  const onPaginationChange = (data) => setPagination({ ...pagination, pageCurrent: data });

  const onPaginationDataLengthChange = (data) => setPagination({ ...pagination, dataLength: data });

  const submitValidationHandler = (errors) =>
    new Promise((resolve, reject) => {
      const getError = Object.values(errors);
      if (getError.length > 0) {
        reject(getError);
      } else {
        resolve();
      }
    });

  const TABLE_HEADER = [
    {
      text: "Nama Jenis Kelamin",
      props: {
        minWidth: 150
      }
    },
    {
      text: "Aksi",
      props: {
        width: 50,
        fixed: true
      }
    }
  ];

  const TABLE_BODY = [
    {
      field: "jenis_kelamin"
    },
    {
      props: { fixed: true },
      customField: (data) => (
        <ButtonGroup size="sm">
          {access.canRead() && <ButtonDetail icon noText onClick={() => onDetailButtonClickHandler(data)} />}
          {access.canUpdate() && <ButtonUpdate icon noText onClick={() => onUpdateButtonClickHandler(data)} />}
          {access.canDelete() && (
            <ButtonDelete icon noText onClick={() => formSubmitHandler(formInitialValues(data), "DELETE")} />
          )}
        </ButtonGroup>
      )
    }
  ];

  const preSubmitHandler = (formik) => {
    const { values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting } = formik;

    setSubmitting(true);

    validateForm().then(async (err) => {
      setErrors(err);
      setTouched(err);

      await submitValidationHandler(err, values)
        .then(() => handleSubmit())
        .catch((err) =>
          modalConfirm.trigger({
            type: "error",
            title: "Data Tidak Lengkap",
            data: err,
            onHide: () => modalConfirm.close()
          })
        )
        .finally(() => {
          setSubmitting(false);
        });
    });
  };

  const formSubmitHandler = (values, action) =>
    modalConfirm.trigger({
      type: action.toLowerCase(),
      onHide: modalConfirm.close,
      onSubmit: () => {
        modalConfirm.loading();

        if (action === "UPDATE") {
          updateJenisKelamin
            .mutateAsync({ data: formSubmitMapper(values), id: values.id })
            .then(() => onHideModalHandler());
        } else if (action === "DELETE") {
          deleteJenisKelamin.mutate(values.id);
        } else {
          createJenisKelamin.mutateAsync(formSubmitMapper(values)).then(() => onHideModalHandler());
        }
      },

      component: (
        <Formik initialValues={values}>
          <Content action="READ" />
        </Formik>
      )
    });

  return (
    <>
      <ListLayout
        showExportExcelButton
        showExportPdfButton
        onExportExcelButtonClick={() => getExportJenisKelamin.mutate("excel")}
        onExportPdfButtonClick={() => getExportJenisKelamin.mutate("pdf")}
        loadingExportButton={getExportJenisKelamin.isLoading}
        showRightTopButton={access.canCreate() ? true : false}
        loading={getJenisKelamin.isFetching}
        error={getJenisKelamin.isError}
        errorText={errorFetchingTableMapper(getJenisKelamin.error)}
        dataTotal={getJenisKelamin?.data?.total}
        dataLength={pagination.dataLength}
        pageCurrent={pagination.pageCurrent}
        pageTotal={getJenisKelamin?.data?.last_page}
        onSearchChange={debounce(searchHandler, 1500)}
        onCreateButtonClick={onCreateButtonClickHandler}
        onDataLengthChange={onPaginationDataLengthChange}
        onPaginationChange={onPaginationChange}
        data={getJenisKelamin.data?.data}
        tableHeader={TABLE_HEADER}
        tableBody={TABLE_BODY}
      />

      {/* menu detail  */}
      <Formik enableReinitialize initialValues={formInitialValues(modal.data)}>
        <ModalDetail
          title={layout.getActivePageName()}
          show={Boolean(modal.show && modal.action === "READ")}
          onHide={onHideModalHandler}
        >
          <Content action="READ" />
        </ModalDetail>
      </Formik>

      {/* menu create */}
      <Formik
        enableReinitialize
        initialValues={formInitialValues({})}
        validationSchema={formValidationSchema}
        onSubmit={(values) => formSubmitHandler(values, "CREATE")}
      >
        {(formik) => {
          return (
            <Form>
              <ModalCreate
                title={layout.getActivePageName()}
                show={Boolean(modal.show && modal.action === "CREATE")}
                onHide={() => {
                  onHideModalHandler();
                  formik.resetForm();
                }}
                onSubmit={() => preSubmitHandler(formik)}
              >
                <Content action="CREATE" />
              </ModalCreate>
            </Form>
          );
        }}
      </Formik>

      {/* menu update       */}
      <Formik
        enableReinitialize
        initialValues={formInitialValues(modal.data)}
        validationSchema={formValidationSchema}
        onSubmit={(values) => formSubmitHandler(values, "UPDATE")}
      >
        {(formik) => {
          return (
            <Form>
              <ModalUpdate
                title={layout.getActivePageName()}
                show={Boolean(modal.show && modal.action === "UPDATE")}
                onHide={() => {
                  onHideModalHandler();
                  formik.resetForm();
                }}
                onSubmit={() => preSubmitHandler(formik)}
              >
                <Content action="UPDATE" />
              </ModalUpdate>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
