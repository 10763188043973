export const formInitialValues = (data = {}) => ({
  id: data?.pekerja?.pekerja_phk_uang_id ?? "",
  pekerjaPhkId: data?.pekerja?.pekerja_phk_id ?? "",
  periodeGajianId: data?.pekerja?.periode_tahun ?? new Date().getFullYear(),
  periodePerencanaan: data?.pekerja?.periode_tahun ?? new Date().getFullYear(),
  unitOrganisasiId: data?.pekerja?.master_unit_organisasi_id ?? "",
  unitOrganisasiNama: data?.pekerja?.master_unit_organisasi_nama ?? "",
  nik: data?.pekerja?.nip ?? "",
  pekerjaId: data?.pekerja?.pekerja_id ?? "",
  pekerjaNama: data?.pekerja?.pekerja_nama ?? "",
  jabatanId: data?.pekerja?.master_jabatan_id ?? "",
  jabatanNama: data?.pekerja?.master_jabatan_nama ?? "",
  tglSetup: data?.pekerja?.tgl_setup ?? new Date(),
  tglPengangkatan: data?.pekerja?.tgl_pengangkatan ?? "",
  tglPemberhentian: data?.pekerja?.tgl_pemberhentian ?? "",
  masaKerja: data?.pekerja?.masa_kerja ?? "",
  kodePhk: data?.pekerja?.kode_phk ?? "",
  alasanPhk: data?.pekerja?.alasan_phk_nama ?? "",

  //section perhitungan uang PHK berdasar PP 35/2023
  constUangTetap: data?.perhitungan_uang_phk?.upah_tetap ?? 0,
  constUangPesangon: data?.perhitungan_uang_phk?.peraturan_uang_pesangon ?? 0,
  constUangPenghargaan: data?.perhitungan_uang_phk?.peraturan_uang_upmk ?? 0,
  constUangPenggantianHak: data?.perhitungan_uang_phk?.peraturan_uang_uph ?? 0,
  constUangPisah: data?.perhitungan_uang_phk?.peraturan_uang_up ?? 0,
  constTotalUangPhk: data?.perhitungan_uang_phk?.peraturan_total_uang_phk ?? 0,

  //section kebijakan perhitungan uang PHK
  uangTetap: data?.perhitungan_uang_phk?.upah_tetap ?? data?.perhitungan_uang_phk?.upah_tetap ?? 0,
  uangPesangon:
    data?.perhitungan_uang_phk?.kebijakan_uang_pesangon ?? data?.perhitungan_uang_phk?.peraturan_uang_pesangon ?? 0,
  uangPenghargaan:
    data?.perhitungan_uang_phk?.kebijakan_uang_upmk ?? data?.perhitungan_uang_phk?.peraturan_uang_upmk ?? 0,
  uangPenggantianHak:
    data?.perhitungan_uang_phk?.kebijakan_uang_uph ?? data?.perhitungan_uang_phk?.peraturan_uang_uph ?? 0,
  uangPisah: data?.perhitungan_uang_phk?.kebijakan_uang_up ?? data?.perhitungan_uang_phk?.peraturan_uang_up ?? 0,
  totalUangPhk:
    data?.perhitungan_uang_phk?.kebijakan_total_uang_phk ?? data?.perhitungan_uang_phk?.peraturan_total_uang_phk ?? 0
});
