import React, { useState } from "react";
import { useQuery, useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { ListLayout } from "layouts";
import { ButtonGroup } from "react-bootstrap";
import {
  ButtonDetail,
  ButtonUpdate,
  ButtonFilter,
  ModalFilter,
  InputSearch,
  toastTrigger,
  Select,
  DatePicker,
  InfoItemVertical
} from "components";
import { useAccessRights } from "hooks";
import { exportHandler, dateConvert } from "utilities";
import { Formik, useFormikContext } from "formik";
import { debounce } from "lodash";
import SetupJadwalApi from "./__SetupJadwalApi__";

const ModalFilterBody = () => {
  const { values, setValues } = useFormikContext();
  const date = dateConvert();

  const getJabatan = useQuery(["jabatan-for-list", "dropdown"], () =>
    SetupJadwalApi.getJabatanForList()
  );
  const getPekerja = useQuery(["pekerja", "dropdown", values?.jabatanId], () =>
    SetupJadwalApi.getPekerja({
      master_jabatan_id: values?.jabatanId
    })
  );
  const getBulan = useQuery(["bulan", "dropdown"], () => SetupJadwalApi.getBulan());
  const getPeriode = useQuery(["periode", "dropdown"], () => SetupJadwalApi.getPeriode());

  return (
    <>
      <Select
        label="Periode"
        placeholder="Pilih periode"
        defaultValue={getPeriode?.data?.find((item) => item.value === values.periodePerencanaanId)}
        options={getPeriode?.data ?? []}
        onChange={(val) =>
          setValues({
            ...values,
            periodePerencanaanId: val.value,
            periodePerencanaan: val.label
          })
        }
        loading={getPeriode.isFetching}
        errorFetch={getPeriode.isError}
      />

      <Select
        label="Bulan Penggajian"
        disable={Boolean(!values?.periodePerencanaanId)}
        options={getBulan?.data ?? []}
        placeholder="Pilih salah satu..."
        defaultValue={values?.bulan ? getBulan?.data?.find((item) => item.value === values?.bulan) : null}
        onChange={(val) => {
          setValues({
            ...values,
            bulan: val.value,
            bulanNama: val.label,
            tglAwal: `${values?.periodePerencanaan}-${val?.value < 10 ? `0${val?.value}` : val?.value}-1`,
            tglAkhir: date.getDashYMD(new Date(values?.periodePerencanaan, val?.value < 10 ? `0${val?.value}` : val?.value, 0))
          });
        }}
        loading={getBulan?.isFetching}
        errorFetch={getBulan.isError}
      />
      <div>
        <div style={{ fontSize: 14 }}>Tanggal Penggajian</div>
        <div className="d-flex align-items-center justify-content-between">
          <div style={{ width: "44%" }}>
            <DatePicker
              disabled
              key={`${values?.periodePerencanaanId} ${values?.bulan}`}
              placeholderText="dd/mm/yyyy"
              selected={values?.periodePerencanaanId && values?.bulan ? values?.tglAwal : undefined}
            />
          </div>
          <small>s/d</small>
          <div style={{ width: "44%" }}>
            <DatePicker
              disabled
              key={`${values?.periodePerencanaanId} ${values?.bulan}`}
              placeholderText="dd/mm/yyyy"
              selected={values?.periodePerencanaanId && values?.bulan ? values?.tglAkhir : undefined}
            />
          </div>
        </div>
      </div>

      <Select
        label="Jabatan"
        placeholder="Pilih jabatan"
        defaultValue={getJabatan?.data?.find((item) => item.value === values.jabatanId)}
        options={getJabatan?.data ?? []}
        onChange={(val) => setValues({ ...values, jabatanId: val.value, jabatanNama: val.label })}
        loading={getJabatan.isFetching}
        errorFetch={getJabatan.isError}
      />
      <Select
        label="Pekerja"
        placeholder="Pilih pekerja"
        defaultValue={getPekerja?.data?.find((item) => item.value === values.pekerjaId)}
        options={getPekerja?.data ?? []}
        onChange={(val) => setValues({ ...values, pekerjaId: val.value, pekerjaNama: val.label })}
        loading={getPekerja.isFetching}
        errorFetch={getPekerja.isError}
      />
    </>
  );
};

export const SetupJadwalList = () => {
  const access = useAccessRights("SETUP_JADWAL");
  const navigate = useNavigate();

  const [searchKey, setSearchKey] = useState(undefined);
  const [pagination, setPagination] = useState({
    pageCurrent: 1,
    dataLength: 10
  });

  const [filter, setFilter] = useState({
    show: false,
    active: false,
    data: {
      periodePerencanaanId: undefined,
      unitOrganisasiId: undefined,
      bulan: undefined,
      pekerjaId: undefined,
      jabatanId: undefined,
      tglAwal: undefined,
      tglAkhir: undefined
    }
  });

  const getSetupJadwalList = useQuery(
    [
      "setup-jadwal",
      "list",
      searchKey,
      pagination.pageCurrent,
      pagination.dataLength,
      filter.data?.periodePerencanaanId,
      filter.data?.jabatanId,
      filter?.data?.tglAwal,
      filter.data?.tglAkhir,
      filter.data?.pekerjaId
    ],
    () =>
      SetupJadwalApi.getList({
        search: searchKey,
        page: pagination.pageCurrent,
        limit: pagination.dataLength,
        periode_tahun: filter?.data?.periodePerencanaanId,
        master_jabatan_id: filter.data?.jabatanId,
        pekerja_id: filter?.data?.pekerjaId,
        bulan: filter?.data?.bulan,
        tgl_bulan_awal: filter?.data?.tglAwal,
        tgl_bulan_akhir: filter?.data?.tglAkhir

      }),
    {
      enabled: Boolean(
        filter.data?.periodePerencanaanId &&
        filter.data?.tglAwal &&
        filter.data?.tglAkhir
      )
    }
  );

  const dataInfo = getSetupJadwalList?.data?.info;

  const getSetupJadwalExport = useMutation((data) => SetupJadwalApi.getExport({
    periode_tahun: data?.periodePerencanaanId,
    tgl_bulan_awal: data?.tglAwal,
    tgl_bulan_akhir: data?.tglAkhir,
    master_jabatan_id: data?.jabatanId,
    pekerja_id: data?.pekerjaId
  }), {
    onSuccess: (link) => {
      toastTrigger({ variant: "success", message: "Export berhasil" });
      exportHandler(link, "Data Setup Jadwal");
    },
    onError: () => {
      toastTrigger({ variant: "danger", message: "Export gagal" });
    }
  });

  const onCreateButtonClickHandler = () => navigate("tambah");

  const onDetailButtonClickHandler = (data) => navigate("detail/" + data?.setup_jadwal_id);

  const onUpdateButtonClickHandler = (data) => navigate("ubah/" + data?.setup_jadwal_id);

  const onPaginationChange = (data) => setPagination({ ...pagination, pageCurrent: data });

  const onPaginationDataLengthChange = (data) => setPagination({ ...pagination, dataLength: data });

  const searchHandler = (e) => {
    setSearchKey(e?.target?.value ? e.target.value : undefined);
  };

  const TABLE_HEADER = [
    {
      text: "Pekerja Nama"
    },
    {
      text: "Jabatan"
    },
    {
      text: "Minggu ke-1"
    },
    {
      text: "Minggu ke-2"
    },
    {
      text: "Minggu ke-3"
    },
    {
      text: "Minggu ke-4"
    },
    {
      text: "Minggu ke-5"
    },
    {
      text: "Minggu ke-6"
    },
    {
      text: "Aksi",
      props: {
        fixed: true,
        rowSpan: 2
      }
    }
  ];

  const TABLE_BODY = [
    { field: "pekerja_nama" },
    { field: "master_jabatan_nama" },
    { field: "minggu_1" },
    { field: "minggu_2" },
    { field: "minggu_3" },
    { field: "minggu_4" },
    { field: "minggu_5" },
    { field: "minggu_6" },
    {
      props: {
        width: 50,
        fixed: true,
        textCenter: true
      },
      customField: (data) => (
        <ButtonGroup size="sm">
          {access.canRead() && (
            <ButtonDetail icon tooltip={false} noText onClick={() => onDetailButtonClickHandler(data)} />
          )}
          {access.canUpdate() && (
            <ButtonUpdate icon tooltip={false} noText onClick={() => onUpdateButtonClickHandler(data)} />
          )}
        </ButtonGroup>
      )
    }
  ];

  return (
    <>
      <ListLayout
        customLeftTopSection={() => (
          <>
            <div className="d-flex align-items-end mb-3">
              <div style={{ width: 300 }} className="me-2">
                <InputSearch onChange={debounce(searchHandler, 1500)} />
              </div>
              <div>
                <ButtonFilter
                  active={filter?.active}
                  className="text-nowrap"
                  onClick={() => setFilter({ ...filter, show: true })}
                />
              </div>
            </div>
            <div className="d-flex align-items-end mb-3 gap-2">
              <InfoItemVertical label="Tahun" text={dataInfo?.periode_tahun} />
              <InfoItemVertical
                label="Bulan"
                text={dataInfo?.bulan}
              />
              <InfoItemVertical
                label="Jabatan"
                text={dataInfo?.master_jabatan_nama}
              />
              <InfoItemVertical
                label="Pekerja"
                text={dataInfo?.pekerja_nama}
              />
            </div>
          </>
        )}
        showRightTopButton={access.canCreate() ? true : false}
        showExportExcelButton
        // showExportPdfButton
        onExportExcelButtonClick={() => getSetupJadwalExport.mutate(filter?.data)}
        // onExportPdfButtonClick={() => getSetupJadwalExport.mutate("pdf")}
        loadingExportButton={getSetupJadwalExport.isLoading}
        loading={getSetupJadwalList?.isFetching}
        error={getSetupJadwalList?.isError}
        dataTotal={getSetupJadwalList?.data?.total}
        dataLength={pagination.dataLength}
        pageCurrent={pagination.pageCurrent}
        pageTotal={getSetupJadwalList?.data?.last_page}
        onSearchChange={debounce(searchHandler, 1500)}
        onCreateButtonClick={onCreateButtonClickHandler}
        onDataLengthChange={onPaginationDataLengthChange}
        onPaginationChange={onPaginationChange}
        data={getSetupJadwalList?.data?.data?.data}
        tableHeader={TABLE_HEADER}
        tableBody={TABLE_BODY}
      />

      {/* ModalFilter */}
      <Formik
        initialValues={{
          periodePerencanaanId: filter?.data?.periodePerencanaanId,
          pekerjaId: filter?.data?.pekerjaId,
          jabatanId: filter?.data?.jabatanId,
          tglAwal: filter?.data?.tglAwal,
          tglAkhir: filter?.data?.tglAkhir,
        }}
      >
        {({ resetForm, values }) => (
          <ModalFilter
            show={filter.show}
            onReset={resetForm}
            onHide={() =>
              setFilter({
                ...filter,
                show: false
              })
            }
            onSubmit={() => {
              setFilter({
                show: false,
                active: Boolean(Object.values(values).find((val) => val)),
                data: values
              });
            }}
          >
            <ModalFilterBody />
          </ModalFilter>
        )}
      </Formik>
    </>
  );
};
