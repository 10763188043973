import * as Yup from "yup";

export const formValidationSchema = {
  identitasJabatan: Yup.object().shape({
    jabatanId: Yup.string().required("Jabatan wajib diisi"),
    jenisJabatanId: Yup.string().required("Jenis Jabatan wajib diisi")
  }),
  tujuanJabatan: Yup.object().shape({
    tujuanJabatan: Yup.string().required("Tujuan jabatan wajib diisi")
  }),
  akuntabilitasJabatan: Yup.object().shape({
    akuntabilitasUtama: Yup.string().required("Akuntabilitas utama wajib diisi")
  }),
  tugasRutin: Yup.object().shape({
    tugasRutinNama: Yup.string().required("Tugas-tugas rutin wajib diisi")
  }),
  wewenang: Yup.object().shape({
    wewenangNama: Yup.string().required("Wewenang wajib diisi")
  }),
  hubunganKerja: Yup.object().shape({
    hubunganKerjaNama: Yup.string().required("Hubungan kerja wajib diisi")
  }),
  dimensi: Yup.object().shape({
    dimensiNama: Yup.string().required("Dimensi nama kerja wajib diisi")
  }),
  spesifikasi: Yup.object().shape({
    kompetensiJabatanId: Yup.string().required("Jenis kompetensi wajib diisi"),
    skalaKemahiranId: Yup.string().required("Skala wajib diisi")
  }),
  sertifikatPelatihan: Yup.object().shape({
    sertifikatPelatihanId: Yup.string().required("Sertifikat pelatihan wajib diisi")
  }),
  sertifikatProfesi: Yup.object().shape({
    sertifikatProfesiId: Yup.string().required("Sertifikat profesi wajib diisi")
  }),
  kondisiKerja: Yup.array().of(
    Yup.object().shape({
      jawabanKondisiKerja: Yup.string().required("Kolom wajib diisi")
    })
  )
};
