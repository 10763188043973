import { ButtonBack, ButtonCreate, useModalConfirm } from "components";
import { Formik } from "formik";
import { ContentLayout } from "layouts";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { errorSubmitMapper } from "utilities";
import InputTimeSheetConcretePumpApi from "./__InputTimeSheetConcretePumpApi__";
import { Content } from "./__InputTimeSheetConcretePumpComps__";
import { formInitialValues, formSubmitValueMapper, formValidationSchema } from "./__InputTimeSheetConcretePumpUtilities__";

export const InputTimeSheetConcretePumpCreate = () => {
    const navigate = useNavigate();
    const modalConfirm = useModalConfirm();

    const getPekerja = useQuery(
        ["pekerja", "list"], () =>
        InputTimeSheetConcretePumpApi.getPekerjaList()
    );

    const createData = useMutation((data) => InputTimeSheetConcretePumpApi.create(data), {
        onSuccess: () => {
            modalConfirm.infoSuccess({ typeInfo: "create" });
            navigate("/payroll/transaksi/time-sheet-concrete-pump");
        },
        onError: (err) => modalConfirm.infoError({ typeInfo: "create", ...errorSubmitMapper(err) })
    });

    const submitValidationHandler = (errors) =>
        new Promise((resolve, reject) => {
            const getError = Object.values(errors);

            if (getError.length > 0) {
                reject(getError ?? []);
            } else {
                resolve();
            }
        });

    const preSubmitHandler = (values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting) => {
        setSubmitting(true);
        validateForm().then(async (err) => {
            setErrors(err);
            setTouched(err);

            await submitValidationHandler(err, values)
                .then(() => formSubmitHandler(values))
                .catch((err) =>
                    modalConfirm.trigger({
                        type: "error",
                        title: "Data Tidak Lengkap",
                        data: err,
                        onHide: () => modalConfirm.close()
                    })
                )
                .finally(() => {
                    setSubmitting(false);
                });
        });
    };

    const formSubmitHandler = async (values) => {
        return await modalConfirm.trigger({
            type: "create",
            onHide: modalConfirm.close,
            onSubmit: () => {
                modalConfirm.loading();
                createData.mutateAsync(formSubmitValueMapper(values));
            }
        });
    };

    return (
        <>
            <ContentLayout showBreadcrumb>
                <>
                    <div className="text-end mb-3">
                        <ButtonBack tooltip={false} onClick={() => navigate("/payroll/transaksi/time-sheet-concrete-pump")} />
                    </div>
                    <Formik
                        enableReinitialize
                        initialValues={formInitialValues(getPekerja?.data)}
                        validationSchema={formValidationSchema}
                    >
                        {({ values, handleSubmit, validateForm, setTouched, setErrors, setSubmitting }) => (
                            <>
                                <Content
                                    action="CREATE"
                                />
                                <div className="text-end mb-3 mt-3">
                                    <ButtonCreate
                                        text="SIMPAN"
                                        tooltip={false}
                                        onClick={() =>
                                            preSubmitHandler(values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting)
                                        }
                                    />
                                </div>
                            </>
                        )}
                    </Formik>
                </>
            </ContentLayout>
        </>
    );
};
