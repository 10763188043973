import React from "react";
import { Row, Col } from "react-bootstrap";
import { Tabs } from "components";
import {
  TabIdentitasKPI,
  TabPeriodeTarget,
  TabFormula,
  TabTarget,
  TabPolaritas,
  TabFrekuensiMonitoring,
  TabSumberData
} from "../__UraianKPIComps__";

export const Content = ({ action }) => {
  return (
    <>
      <Row>
        {/*Tabs Section*/}
        <Col>
          <Tabs
            tabs={[
              {
                key: "IdentitasKpi",
                title: "Identitas KPI",
                component: <TabIdentitasKPI action={action} />
              },
              {
                key: "PeriodeTarget",
                title: "Periode Target",
                component: <TabPeriodeTarget action={action} />
              },
              {
                key: "Formula",
                title: "Formula",
                component: <TabFormula action={action} />
              },
              {
                key: "Target",
                title: "Target",
                component: <TabTarget action={action} />
              },
              {
                key: "Polaritast",
                title: "Polaritas",
                component: <TabPolaritas action={action} />
              },
              {
                key: "FrekuensiMonitoring",
                title: "Frekuensi Monitoring",
                component: <TabFrekuensiMonitoring action={action} />
              },
              {
                key: "SumberData",
                title: "Sumber Data",
                component: <TabSumberData action={action} />
              }
            ]}
          ></Tabs>
        </Col>
      </Row>
    </>
  );
};
