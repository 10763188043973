import { Services } from "services";
import { fetchDropdownMapper } from "utilities";
const fetchDropdown = fetchDropdownMapper(Services);
class PangkatApi {
  async getList(params) {
    const fetch = await Services.get("/master/pangkat", { params });
    return fetch?.data?.data;
  }

  async getExport(params) {
    const fetch = await Services.get("/master/pangkat/export", { params });
    return fetch?.data?.data;
  }

  getGrade() {
    return fetchDropdown("/master/grade", {
      value: "master_grade_id",
      label: "grade"
    });
  }

  getJenisJabatan() {
    return [
      { label: "Struktural", value: 1 },
      { label: "Fungsional", value: 2 }
    ];
  }

  create(data) {
    return Services.post("/master/pangkat", data);
  }

  update(data, id) {
    return Services.put("/master/pangkat/" + id, data);
  }

  delete(id) {
    return Services.delete("/master/pangkat/" + id);
  }
}

export default new PangkatApi();
