import { ButtonBack, ButtonCreate } from "components";
import { Formik } from "formik";
import { useModalConfirm } from "hooks";
import { ContentLayout } from "layouts";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { errorSubmitMapper } from "utilities";
import PerhitunganUangPhkApi from "./__PerhitunganUangPhkApi__";
import { Content } from "./__PerhitunganUangPhkComps__";
import { formInitialValues, formSubmitValueMapper, formValidationSchema } from "./__PerhitunganUangPhkUtilities__";

export const PerhitunganUangPhkCreate = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const modalConfirm = useModalConfirm();

  const getDetailPerhitunganUangPhk = useQuery(["detail-perhitungan-uang-phk"], () =>
    PerhitunganUangPhkApi.getDataPhkDetail(id)
  );

  const createPerhitunganUangPhk = useMutation((data) => PerhitunganUangPhkApi.create(data), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "create" });
      navigate("/operasional-sdm/transaksi/perhitungan-uang-phk");
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "create", ...errorSubmitMapper(err) })
  });

  const submitValidationHandler = (errors) =>
    new Promise((resolve, reject) => {
      const getError = Object.values(errors);

      if (getError.length > 0) {
        reject(getError);
      } else {
        resolve();
      }
    });

  const preSubmitHandler = (values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting) => {
    setSubmitting(true);
    validateForm().then(async (err) => {
      setErrors(err);
      setTouched(err);

      await submitValidationHandler(err, values)
        .then(() => handleSubmit())
        .catch((err) =>
          modalConfirm.trigger({
            type: "error",
            title: "Data Tidak Lengkap",
            data: err,
            onHide: () => modalConfirm.close()
          })
        )
        .finally(() => {
          setSubmitting(false);
        });
    });
  };

  const formSubmitHandler = async (values) => {
    return await modalConfirm.trigger({
      type: "create",
      size: "lg",
      onHide: modalConfirm.close,
      onSubmit: () => {
        modalConfirm.loading();
        createPerhitunganUangPhk.mutateAsync(formSubmitValueMapper(values));
      },
      component: (
        <>
          <Formik initialValues={values}>
            <Content action="READ" />
          </Formik>
        </>
      )
    });
  };

  return (
    <>
      <ContentLayout showBreadcrumb>
        {/* {getDetailPerhitunganUangPhk?.isFetching ? (
                    <DataStatus loading />
                ) : ( */}
        <>
          <div className="text-end mb-3">
            <ButtonBack
              tooltip={false}
              onClick={() => navigate("/operasional-sdm/transaksi/perhitungan-uang-phk/list-data-phk")}
            />
          </div>
          <Formik
            enableReinitialize
            initialValues={formInitialValues(getDetailPerhitunganUangPhk?.data)}
            validationSchema={formValidationSchema}
            onSubmit={(values) => formSubmitHandler(values)}
          >
            {({ values, handleSubmit, validateForm, setTouched, setErrors, setSubmitting }) => (
              <>
                <Content action="UPDATE" />
                <div className="text-end mb-3 mt-3">
                  <ButtonCreate
                    tooltip={false}
                    onClick={() =>
                      preSubmitHandler(values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting)
                    }
                  />
                </div>
              </>
            )}
          </Formik>
        </>
        {/* )} */}
      </ContentLayout>
    </>
  );
};
