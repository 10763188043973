import React from "react";
import { useQuery } from "react-query";
import { useFormikContext } from "formik";
import { Input, Select, InfoItemVertical, TextArea } from "components";
import { errorFetchingSelectMapper } from "utilities";
import KompetensiApi from "../__KompetensiApi__";

export const Content = ({ action }) => {
  const { values, errors, touched, handleChange, setValues } = useFormikContext();
  const getJenisKompetensi = useQuery(["jeniskompetensi", "dropdown"], KompetensiApi.getJenisKompetensi);

  if (action === "READ") {
    return (
      <>
        <InfoItemVertical label="Jenis Kompetensi" text={values.jenis_kompetensi_nama} />
        <InfoItemVertical label="Kode Kompetensi" text={values.kode_kompetensi_jabatan} />
        <InfoItemVertical label="Nama Kompetensi" text={values.nama} />
        <InfoItemVertical label="Keterangan" text={values.nama_keterangan} />
      </>
    );
  }

  return (
    <>
      <Select
        label="Jenis Kompetensi"
        placeholder="Pilih jenis kompetensi"
        defaultValue={getJenisKompetensi?.data
          ?.filter((val) => val.value)
          ?.find((item) => item.value === values.jenis_kompetensi_id)}
        options={getJenisKompetensi?.data?.filter((val) => val.value)}
        onChange={(val) => {
          setValues({
            ...values,
            jenis_kompetensi_id: val.value,
            jenis_kompetensi_nama: val.label
          });
        }}
        loading={getJenisKompetensi.isFetching}
        error={Boolean(errors.jenis_kompetensi_nama && touched.jenis_kompetensi_nama)}
        errorText={
          Boolean(errors.jenis_kompetensi_nama && touched.jenis_kompetensi_nama) && errors.jenis_kompetensi_nama
        }
        errorFetch={getJenisKompetensi.isError}
        errorFetchText={errorFetchingSelectMapper(getJenisKompetensi.error)}
      />

      <Input
        label="Kode Kompetensi"
        name="kode_kompetensi_jabatan"
        placeholder="Masukkan kode kompetensi"
        value={values.kode_kompetensi_jabatan}
        onChange={handleChange}
        error={Boolean(errors.kode_kompetensi_jabatan && touched.kode_kompetensi_jabatan)}
        errorText={
          Boolean(errors.kode_kompetensi_jabatan && touched.kode_kompetensi_jabatan) && errors.kode_kompetensi_jabatan
        }
      />

      <Input
        label="Nama Kompetensi"
        name="nama"
        placeholder="Masukkan nama kompetensi"
        value={values.nama}
        onChange={handleChange}
        error={Boolean(errors.nama && touched.nama)}
        errorText={Boolean(errors.nama && touched.nama) && errors.nama}
      />

      <TextArea
        label="Keterangan"
        name="nama_keterangan"
        placeholder="Masukkan keterangan"
        value={values.nama_keterangan}
        onChange={handleChange}
        error={Boolean(errors.nama_keterangan && touched.nama_keterangan)}
        errorText={Boolean(errors.nama_keterangan && touched.nama_keterangan) && errors.nama_keterangan}
      />
    </>
  );
};
