import {
    ButtonCreate, DatePicker, InfoItemVertical, Input, InputCurrency, InputNumber, numberConvert,
    Select, TableList
} from "components";
import { useFormikContext } from "formik";
import { Card, Col, Row } from "react-bootstrap";
import { useQuery } from "react-query";
import TunjanganHariRayaApi from "../__TunjanganHariRayaApi__";
import { checkMonth } from "../__TunjanganHariRayaUtilities__";

const SectionDataSetup = ({ onSearchButtonClick, onResetClickButton }) => {
    const { values, setValues, errors, touched } = useFormikContext();

    const getAgama = useQuery(["agama", "dropdown"], () => TunjanganHariRayaApi.getAgama());
    const getBulan = useQuery(["bulan", "dropdown"], () => TunjanganHariRayaApi.getBulan());

    return (
        <>

            <Input
                disabled
                label="Periode Penggajian"
                value={values?.periodePerencanaan}
            />
            <Input
                label="Periode Thr"
                placeholder="Masukkan periode thr"
                value={values?.tahun}
                onChange={(val) => setValues({
                    ...values,
                    tahun: val?.target?.value
                })}
            />
            <Select
                label="Agama"
                options={getAgama?.data ?? []}
                placeholder="Pilih salah satu..."
                defaultValue={
                    values?.agamaId
                        ? getAgama?.data?.find((item) => item.value === values?.agamaId)
                        : null
                }
                onChange={(val) => {
                    setValues({
                        ...values,
                        agamaId: val.value,
                        agamaNama: val.label
                    });
                }}
                loading={getAgama?.isFetching}
                errorFetch={getAgama.isError}
                error={Boolean(errors.agamaId && touched.agamaId)}
                errorText={Boolean(errors.agamaId && touched.agamaId) && errors.agamaId}
            />
            {/* <Select
                label="Bulan Penggajian"
                disable={Boolean(!values?.periodeGajianId)}
                options={getBulan?.data ?? []}
                placeholder="Pilih salah satu..."
                defaultValue={values?.bulan ? getBulan?.data?.find((item) => item.value === values?.bulan) : null}
                onChange={(val) => {
                    const tglNewStart = val.value <= 1 ?
                        `${values?.periodePerencanaan - 1}-12-26`
                        : `${values?.periodePerencanaan}-${val?.value < 10 ? `0${val?.value - 1}` : val?.value - 1}-26`;

                    const tglNewEnd = `${values?.periodePerencanaan}-${val?.value < 10 ? `0${val?.value}` : val?.value}-25`;

                    setValues({
                        ...values,
                        bulan: val.value,
                        bulanNama: val.label,
                        tglAwal: tglNewStart,
                        tglAkhir: tglNewEnd
                    });
                }}
                loading={getBulan?.isFetching}
                errorFetch={getBulan.isError}
                error={Boolean((errors.bulan && touched.bulan) || errors.validateDate)}
                errorText={Boolean(errors.bulan && touched.bulan) && errors.bulan ? errors.bulan : errors.validateDate}
            />
            <div>
                <div style={{ fontSize: 14 }}>Tanggal Penggajian</div>
                <div className="d-flex align-items-center justify-content-between">
                    <div style={{ width: "44%" }}>
                        <DatePicker
                            disabled
                            key={`${values?.periodeGajianId} ${values?.bulan}`}
                            placeholderText="dd/mm/yyyy"
                            selected={values?.periodeGajianId && values?.bulan ? values?.tglAwal : undefined}
                            disable={Boolean(!values?.bulan)}
                            onChange={(val) =>
                                setValues({
                                    ...values,
                                    tglAwal: val,
                                    bulan: new Date(val).getMonth() + 1,
                                    tglAkhir: new Date(new Date(val).setDate(new Date(val).getDate() + 30)),
                                    validateDate: checkMonth(val, values?.tglSetup)
                                })
                            }
                            error={Boolean(errors.tglAwal && touched.tglAwal) || errors.validateDate}
                            errorText={
                                Boolean(errors.tglAwal && touched.tglAwal) && errors.tglAwal ? errors.tglAwal : errors.validateDate
                            }
                        />
                    </div>
                    <small>s/d</small>
                    <div style={{ width: "44%" }}>
                        <DatePicker
                            disabled
                            key={`${values?.periodeGajianId} ${values?.bulan}`}
                            placeholderText="dd/mm/yyyy"
                            selected={values?.periodeGajianId && values?.bulan ? values?.tglAkhir : undefined}
                            disable={Boolean(!values?.bulan)}
                            onChange={(val) =>
                                setValues({
                                    ...values,
                                    tglAkhir: val
                                })
                            }
                            error={Boolean(errors.tglAkhir && touched.tglAkhir)}
                            errorText={Boolean(errors.tglAkhir && touched.tglAkhir) && errors.tglAkhir}
                        />
                    </div>
                </div>
            </div> */}

            <DatePicker
                // disabled
                label="Tanggal"
                placeholderText="dd-mm-yyyy"
                selected={values?.tglSetup}
                onChange={(val) =>
                    setValues({
                        ...values,
                        tglSetup: val
                    })
                }
                error={Boolean(errors.tglSetup && touched.tglSetup)}
                errorText={Boolean(errors.tglSetup && touched.tglSetup) && errors.tglSetup}
            />

            <div className="d-flex justify-content-end">
                <div>
                    {/* <ButtonCancel text="RESET" className="mb-3 me-2" tooltip={false} onClick={() => onResetClickButton()} /> */}
                    <ButtonCreate
                        disabled={Boolean(
                            !values?.periodeGajianId
                            || !values?.agamaId
                            || !values?.tahun
                        )}
                        text="CARI"
                        className="mb-3"
                        tooltip={false}
                        onClick={() => onSearchButtonClick(values)}
                    />
                </div>
            </div>
        </>
    );
};

// const TabelDataPekerja = () => {
//     const { values, setValues, errors, touched } = useFormikContext();
//     const number = numberConvert();

//     const setNewValues = ({ index, propname, val }) => {
//         const detailTemp = [...values?.detail];
//         const updatedDetail = detailTemp[index];
//         updatedDetail[propname] = val;

//         setValues({ ...values, detail: detailTemp });
//     };

//     const getError = (index, propname) => {
//         return Boolean(errors?.detail?.[index]?.[propname] && touched?.detail?.[index]?.[propname]);
//     };

//     const getErrorText = (index, propname) => {
//         return (
//             Boolean(errors?.detail?.[index]?.[propname] && touched?.detail?.[index]?.[propname]) &&
//             errors?.detail?.[index]?.[propname]
//         );
//     };

//     return (
//         <>
//             <TableList
//                 maxHeight={550}
//                 tableHeader={[
//                     { text: "NIK" },
//                     { text: "Pekerja" },
//                     { text: "Unit Organisasi" },
//                     { text: "Jabatan" },
//                     { text: "Agama" },
//                     { text: "Upah Pokok" },
//                     { text: "Tunjangan Tetap" },
//                     { text: "Persentase" },
//                     { text: "Nominal" }
//                 ]}
//                 tableBody={[
//                     {
//                         field: "nik"
//                     },
//                     {
//                         field: "pekerjaNama"
//                     },
//                     {
//                         field: "unitOrganisasiNama"
//                     },
//                     {
//                         field: "jabatanNama"
//                     },
//                     {
//                         field: "agamaNama"
//                     },
//                     {
//                         customField: (val) => val?.upahPokok ? "Rp" + number.getWithComa(val?.upahPokok, { minimumFractionDigits: 2 }) : "Rp0"
//                     },
//                     {
//                         customField: (val) => val?.tunjanganTetap ? "Rp" + number.getWithComa(val?.tunjanganTetap, { minimumFractionDigits: 2 }) : "Rp0"
//                     },
//                     {
//                         customField: (val, index) => (
//                             <InputNumber
//                                 name="persentase"
//                                 suffix="%"
//                                 value={number.getWithComa(val?.persentase, { minimumFractionDigits: 0 }) + "%"}
//                                 onChange={(x) => {
//                                     setNewValues({ index: index, propname: "persentase", val: x })
//                                     setNewValues({ index: index, propname: "checked", val: true })
//                                     setNewValues({ index: index, propname: "nominal", val: ((parseFloat(val?.tunjanganTetap) + parseFloat(val?.upahPokok)) * (x / 100)) })
//                                 }}
//                                 error={getError(index, "persentase")}
//                                 errorText={getErrorText(index, "persentase")}
//                             />
//                         )
//                     },
//                     {
//                         customField: (val, index) => (
//                             <InputCurrency
//                                 name="nominal"
//                                 disabled
//                                 value={"Rp" + number.getWithComa(val?.nominal, { minimumFractionDigits: 2 })}
//                                 error={getError(index, "nominal")}
//                                 errorText={getErrorText(index, "nominal")}
//                             />
//                         )
//                     }
//                 ]}
//                 data={values?.detail}
//             />
//         </>
//     );
// };

const TabelDataPekerja2 = ({ action }) => {
    const { values, setValues, errors, touched } = useFormikContext();
    const number = numberConvert();

    const setNewValues = ({ index, propname, val }) => {
        const detailTemp = [...values?.detail];
        const updatedDetail = detailTemp[index];
        updatedDetail[propname] = val;

        setValues({ ...values, detail: detailTemp });
    };

    const getError = (index, propname) => {
        return Boolean(errors?.detail?.[index]?.[propname] && touched?.detail?.[index]?.[propname]);
    };

    const getErrorText = (index, propname) => {
        return (
            Boolean(errors?.detail?.[index]?.[propname] && touched?.detail?.[index]?.[propname]) &&
            errors?.detail?.[index]?.[propname]
        );
    };

    return (
        <>
            <Col md={3} className="mb-2">
                <InputNumber
                    id="persentase"
                    placeholder="Masukkan persentase"
                    disabled={Boolean(values?.detail.length === 0 || action === "READ")}
                    label="Persentase THR"
                    suffix="%"
                    value={number.getWithComa(values?.persentase, { minimumFractionDigits: 2 })}
                    onChange={(val) => {
                        if (values?.detail.length !== 0) {
                            const arr = values?.detail;
                            const newArr = arr.reduce((accumulator, obj) => {
                                return [
                                    ...accumulator,
                                    {
                                        checked: false,
                                        periodeGajianId: obj.periodeGajianId,
                                        tglSetup: obj.tglSetup,
                                        tglAwal: obj.tglAwal,
                                        tglAkhir: obj.tglAkhir,
                                        nik: obj.nik,
                                        pekerjaId: obj.pekerjaId,
                                        pekerjaNama: obj.pekerjaNama,
                                        unitOrganisasiId: obj.unitOrganisasiId,
                                        unitOrganisasiNama: obj.unitOrganisasiNama,
                                        jabatanId: obj.jabatanId,
                                        jabatanNama: obj.jabatanNama,
                                        agamaId: obj.agamaId,
                                        agamaNama: obj.agamaNama,
                                        upahPokok: obj.upahPokok,
                                        tunjanganTetap: obj.tunjanganTetap,
                                        persentase: val,
                                        nominal: ((parseFloat(obj?.tunjanganTetap) + parseFloat(obj?.upahPokok)) * (val / 100)),
                                        tahun: obj.tahun,
                                        tglPembayaran: obj.tglPembayaran,
                                        persentaseSudah: obj.persentaseSudah,
                                        nominalSudahDibayar: obj.nominalSudahDibayar,
                                        persentaseTotal: obj.persentaseTotal
                                    }
                                ];
                            }, []);

                            setValues({
                                ...values,
                                persentase: val,
                                detail: newArr
                            });
                        }
                    }}
                    error={Boolean(errors.persentase && touched.persentase)}
                    errorText={Boolean(errors.persentase && touched.persentase) && errors.persentase}
                />
            </Col>
            <TableList
                maxHeight={550}
                tableHeader={[
                    { text: "NIK" },
                    { text: "Pekerja" },
                    { text: "Unit Organisasi" },
                    { text: "Jabatan" },
                    { text: "Agama" },
                    { text: "Upah Pokok" },
                    { text: "Tunjangan Tetap" },
                    { text: "Persentase Sudah diberikan" },
                    { text: "Nominal Sudah dibayar" },
                    { text: "Persentase" },
                    { props: { fixed: true }, text: "Nominal" },
                ]}
                tableBody={[
                    {
                        field: "nik"
                    },
                    {
                        field: "pekerjaNama"
                    },
                    {
                        field: "unitOrganisasiNama"
                    },
                    {
                        field: "jabatanNama"
                    },
                    {
                        field: "agamaNama"
                    },
                    {
                        props: {
                            textRight: true
                        },
                        customField: (val) => val?.upahPokok ? "Rp" + number.getWithComa(val?.upahPokok, { minimumFractionDigits: 2 }) : "Rp0"
                    },
                    {
                        props: {
                            textRight: true
                        },
                        customField: (val) => val?.tunjanganTetap ? "Rp" + number.getWithComa(val?.tunjanganTetap, { minimumFractionDigits: 2 }) : "Rp0"
                    },
                    {
                        props: {
                            textRight: true,
                            width: 100
                        },
                        customField: (val) => val?.persentaseSudah ? number.getWithComa(val?.persentaseSudah, { minimumFractionDigits: 2 }) + "%" : "0%"
                    },
                    {
                        props: {
                            textRight: true
                        },
                        customField: (val) => val?.nominalSudahDibayar ? "Rp" + number.getWithComa(val?.nominalSudahDibayar, { minimumFractionDigits: 2 }) : "Rp0"
                    },
                    {
                        props: {
                            width: 50
                        },
                        customField: (val, index) => (
                            <InputNumber
                                disabled={Boolean(parseInt(val?.persentaseSudah) === 100)}
                                className="text-end"
                                name="persentase"
                                suffix="%"
                                value={number.getWithComa(val?.persentase, { minimumFractionDigits: 0 }) + "%"}
                                onChange={function (x) {
                                    if (((x + val?.persentaseSudah) <= 100)) {
                                        // console.log("MASUK Y", x)
                                        setNewValues({ index: index, propname: "persentase", val: x })
                                        setNewValues({ index: index, propname: "persentaseTotal", val: (x + val?.persentaseSudah) })
                                        setNewValues({ index: index, propname: "checked", val: true })
                                        setNewValues({ index: index, propname: "nominal", val: ((parseFloat(val?.tunjanganTetap) + parseFloat(val?.upahPokok)) * (x / 100)) })
                                    } else if (x === undefined || x === "") {
                                        // console.log("MASUK X", x)
                                        setNewValues({ index: index, propname: "persentase", val: x })
                                        setNewValues({ index: index, propname: "persentaseTotal", val: (x + val?.persentaseSudah) })
                                        setNewValues({ index: index, propname: "checked", val: false })
                                        setNewValues({ index: index, propname: "nominal", val: ((parseFloat(val?.tunjanganTetap) + parseFloat(val?.upahPokok)) * (x / 100)) })
                                    }
                                    else {
                                        // console.log("MASUK Z", x)
                                        setNewValues({ index: index, propname: "persentase", val: x })
                                        setNewValues({ index: index, propname: "persentaseTotal", val: (x + val?.persentaseSudah) })
                                        setNewValues({ index: index, propname: "nominal", val: 0 })
                                        setNewValues({ index: index, propname: "checked", val: true })
                                    }
                                }}
                                error={getError(index, "persentase")}
                                errorText={getErrorText(index, "persentase")}
                            />
                        )
                    },
                    {
                        props: {
                            fixed: true,
                            width: 200
                        },
                        customField: (val, index) => (
                            <InputCurrency
                                className="text-end"
                                name="nominal"
                                disabled
                                value={"Rp" + number.getWithComa(val?.nominal, { minimumFractionDigits: 2 })}
                                error={getError(index, "nominal")}
                                errorText={getErrorText(index, "nominal")}
                            />
                        )
                    }
                ]}
                data={values?.detail}
            />
        </>
    );
};

export const Content = ({ action, onSearchButtonClick, onResetClickButton }) => {
    const { values, setValues, errors, touched } = useFormikContext();
    const number = numberConvert();

    if (action === "READ") {
        return (
            <>
                <InfoItemVertical label="NIK" text={values.nik} />
                <InfoItemVertical label="Pekerja" text={values.pekerjaNama} />
                <InfoItemVertical label="Unit Organisasi" text={values.unitOrganisasiNama} />
                <InfoItemVertical label="Jabatan" text={values.jabatanNama} />
                <InfoItemVertical label="Agama" text={values?.agamaNama} />
                <InfoItemVertical
                    label="Upah Pokok"
                    text={values?.upahPokok ? "Rp" + number.getWithComa(values?.upahPokok, { minimumFractionDigits: 2 }) : "Rp0"}
                />
                <InfoItemVertical
                    label="Tunjangan Tetap"
                    text={values?.tunjanganTetap ? "Rp" + number.getWithComa(values?.tunjanganTetap, { minimumFractionDigits: 2 }) : "Rp0"}
                />
                <InfoItemVertical
                    label="Persentase Belum diberikan"
                    text={values?.persentase ? number.getWithComa(values?.persentaseBelum, { minimumFractionDigits: 2 }) + "%" : "0%"}
                />
                <InfoItemVertical
                    label="Nominal Belum dibayar"
                    text={values.nominal ? "Rp" + number.getWithComa(values?.nominalBelumDibayar, { minimumFractionDigits: 2 }) : "Rp0"}
                />
                <InfoItemVertical
                    label="Persentase Sudah diberikan"
                    text={values?.persentase ? number.getWithComa(values?.persentase, { minimumFractionDigits: 2 }) + "%" : "0%"}
                />
                <InfoItemVertical
                    label="Nominal Sudah dibayar"
                    text={values.nominal ? "Rp" + number.getWithComa(values?.nominal, { minimumFractionDigits: 2 }) : "Rp0"}
                />
            </>
        );
    }

    if (action === "UPDATE") {
        return (
            <>
                <Input disabled label="NIK" value={values?.nik} />
                <Input disabled label="Pekerja" value={values?.pekerjaNama} />
                <Input disabled label="Unit Organisasi" value={values?.unitOrganisasiNama} />
                <Input disabled label="Jabatan" value={values?.jabatanNama} />
                <Input disabled label="Agama" value={values?.agamaNama} />
                <Input disabled label="Upah Pokok" value={values?.upahPokok ? "Rp" + number.getWithComa(values?.upahPokok, { minimumFractionDigits: 2 }) : "-"} />
                <Input disabled label="Tunjangan Tetap" value={values?.tunjanganTetap ? "Rp" + number.getWithComa(values?.tunjanganTetap, { minimumFractionDigits: 2 }) : "-"} />
                <InfoItemVertical
                    label="Persentase Belum diberikan"
                    text={values?.persentase ? number.getWithComa(values?.persentaseBelum, { minimumFractionDigits: 2 }) + "%" : "0%"}
                />
                <InfoItemVertical
                    label="Nominal Belum dibayar"
                    text={values.nominal ? "Rp" + number.getWithComa(values?.nominalBelumDibayar, { minimumFractionDigits: 2 }) : "Rp0"}
                />
                <InputNumber
                    name="persentase"
                    label="Persentase Sudah diberikan"
                    suffix="%"
                    value={values.persentase ? "Rp" + number.getWithComa(values?.persentase, { minimumFractionDigits: 2 }) : ""}
                    onChange={(val) =>
                        setValues({
                            ...values,
                            persentaseBelum: (100 - val),
                            nominalBelumDibayar: ((parseFloat(values?.tunjanganTetap) + parseFloat(values?.upahPokok)) * ((100 - val) / 100)),
                            persentase: val,
                            nominal: ((parseFloat(values?.tunjanganTetap) + parseFloat(values?.upahPokok)) * (val / 100))
                        })
                    }
                    error={Boolean(errors.persentase && touched.persentase)}
                    errorText={Boolean(errors.persentase && touched.persentase) && errors.persentase}
                />
                <InputCurrency
                    disabled
                    name="nominal"
                    label="Nominal Sudah dibayar"
                    value={values.nominal ? "Rp" + number.getWithComa(values?.nominal, { minimumFractionDigits: 2 }) : "-"}
                    onChange={(val) =>
                        setValues({
                            ...values,
                            nominal: val
                        })
                    }
                    error={Boolean(errors.nominal && touched.nominal)}
                    errorText={Boolean(errors.nominal && touched.nominal) && errors.nominal}
                />
            </>
        );
    }

    return (
        <>
            <Row>
                <Col md={3} className="mb-4">
                    <Card>
                        <Card.Header>
                            <b>Setup Data</b>
                        </Card.Header>
                        <Card.Body>
                            <SectionDataSetup onSearchButtonClick={onSearchButtonClick} onResetClickButton={onResetClickButton} />
                        </Card.Body>
                    </Card>
                </Col>

                <Col md={9}>
                    <Card>
                        <Card.Header>
                            <b>Data Pekerja</b>
                        </Card.Header>
                        <Card.Body>
                            <TabelDataPekerja2 action={action} />
                            {/* <div className="mt-2">
                                <TabelDataPekerja />
                            </div> */}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};
