export const formInitialValues = (data = {}, filter) => ({
  periodeGajianId: data?.periode_tahun ?? filter?.periodeGajianId,
  periodePerencanaan: data?.periode_tahun ?? new Date().getFullYear(),
  pekerjaId: data?.pekerja_id ?? filter?.pekerjaId,
  pekerjaNama: data?.pekerja_nama ?? filter?.pekerjaNama,
  nik: data?.nip,
  bulan: filter?.bulan,
  tglAwal: data?.tgl_bulan_awal ?? filter?.tglAwal,
  tglAkhir: data?.tgl_bulan_akhir ?? filter?.tglAkhir,
  tglSetup: data?.tgl_setup ?? filter?.tglSetup ?? new Date(),
  unitOrganisasiId: data?.master_unit_organisasi_id ?? filter?.unitOrganisasiId ?? "",
  unitOrganisasiNama: data?.master_unit_organisasi_nama ?? filter?.unitOrganisasiNama ?? "",
  uangPerkubik: data?.uang_perkubik,
  totalUpah: data?.total_upah,

  detail:
    data?.detail?.map((val) => ({
      tglPengiriman: val?.tgl_pengiriman,
      upah: val?.upah,
      noDoket: val?.no_doket,
      urutanRitase: val?.urutan_ritase,

      customer: val?.customer,
      proyek: val?.proyek,

      jarak: val?.jarak,
      jenisRitaseKode: val?.jenis_ritase_kode,
      jenisRitaseNama: val?.jenis_ritase_nama
    })) ?? []
});
