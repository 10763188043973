import React from "react";
import { Row, Col } from "react-bootstrap";
import { SectionEvaluasiJabatan, SectionJabatan } from "../__EvaluasiJabatanComps__";

export const Content = ({ action }) => {
  return (
    <Row>
      <Col md={3}>
        <SectionJabatan action={action} />
      </Col>
      <Col>
        <SectionEvaluasiJabatan action={action} />
      </Col>
    </Row>
  );
};
