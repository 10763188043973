import { TunjanganUpahHarianList, TunjanganUpahHarianCreate, TunjanganUpahHarianDetail, TunjanganUpahHarianPostList } from "pages/Payroll";

export const TunjanganUpahHarian = {
    component: <TunjanganUpahHarianList />,
    link: "tunjangan-upah-harian",
    name: "Tunjangan Upah Harian",
    access: "TUNJANGAN_UPAH_HARIAN",
    child: [
        {
            name: "List Pekerja",
            link: "/post-list",
            component: <TunjanganUpahHarianPostList />
        },
        {
            name: "Tambah",
            link: "/tambah",
            component: <TunjanganUpahHarianCreate />
        },
        {
            name: "Detail",
            link: "/detail/:id",
            component: <TunjanganUpahHarianDetail />
        }
    ]
};
