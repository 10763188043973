export const formInitialValues = (data = {}) => ({
  id: data?.master_unit_organisasi_id,
  kode: data?.master_unit_organisasi_kode,
  nama: data?.master_unit_organisasi_nama,
  level: data?.master_hirarki_level,
  parent_id: data?.parent_id,
  parent_nama: data?.parent_unit_organisasi_nama,
  hirarki_unit_organisasi_id: data?.master_hirarki_id,
  hirarki_unit_organisasi_nama: data?.master_hirarki_nama,
  hirarki_unit_organisasi_level: data?.master_hirarki_level,
  relasi_parent: data?.relasi_parent
});
