import { Pph21TahunanList, Pph21TahunanCreate, Pph21TahunanDetail, Pph21TahunanPostList } from "pages/Payroll";

export const Pph21Tahunan = {
    component: <Pph21TahunanList />,
    link: "pph-21-tahunan",
    name: "Pajak Penghasilan Tahunan",
    access: "PEKERJA_PAJAK_TAHUNAN",
    child: [
        {
            name: "List Pekerja",
            link: "/post-list",
            component: <Pph21TahunanPostList />
        },
        {
            name: "Tambah",
            link: "/tambah",
            component: <Pph21TahunanCreate />
        },
        {
            name: "Detail",
            link: "/detail/:id",
            component: <Pph21TahunanDetail />
        }
    ]
};
