import { Services } from "services";

class InputTimeSheetConcretePumpApi {
    async getList(params) {
        const fetch = await Services.get("/transaksi/time-sheet-concrete", { params });
        return fetch?.data?.data;
    }

    async getDetail(id) {
        const fetch = await Services.get("/transaksi/time-sheet-concrete/" + id);
        return fetch?.data?.data;
    }

    async getPekerjaList() {
        const fetch = await Services.get("/transaksi/time-sheet-concrete/list-pekerja");
        return fetch?.data?.data;
    }

    async getPeriode() {
        const fetch = await Services.get("/utility/periode-tahun?feat=time-sheet-concrete");
        return fetch?.data?.data.map((val) => ({
            value: val?.periode_tahun,
            label: val?.periode_tahun
        }));
    }

    async getCustomer() {
        const fetch = await Services.get("/master/customer/dropdown");
        return fetch?.data?.data.map((val) => ({
            value: val?.customer_id,
            label: val?.customer_nama
        }));
    }
    async getProyek(params) {
        const fetch = await Services.get("/master/proyek/dropdown", { params });
        return fetch?.data?.data.map((val) => ({
            value: val?.proyek_id,
            label: val?.proyek_nama,
            alamat: val?.alamat
        }));
    }

    async getBulan() {
        return Array.from({ length: 12 }, (item, i) => {
            return {
                value: i + 1,
                label: new Date(0, i).toLocaleString("id-ID", { month: "long" })
            };
        });
    }

    async getTanggal(params) {
        const fetch = await Services.get("/transaksi/time-sheet-concrete/dropdown-tanggal", { params });
        return fetch?.data?.data.map((val) => ({
            value: val?.tgl_bulan_awal + "/&/" + val?.tgl_bulan_akhir,
            label: val?.tgl_bulan_awal + " s/d " + val?.tgl_bulan_akhir,
            tglAwal: val?.tgl_bulan_awal,
            tglAkhir: val?.tgl_bulan_akhir
        }));
    }

    async getExport(params) {
        const fetch = await Services.get("/transaksi/time-sheet-concrete/export", { params });
        return fetch?.data?.data;
    }

    create(data) {
        return Services.post("/transaksi/time-sheet-concrete", data);
    }

    createCustomer(data) {
        return Services.post("/master/customer", data);
    }
    createProyek(data) {
        return Services.post("/master/proyek", data);
    }

    update(data, id) {
        return Services.put("/transaksi/time-sheet-concrete/" + id, data);
    }

    delete(id) {
        return Services.delete("/transaksi/time-sheet-concrete/" + id);
    }
}

export default new InputTimeSheetConcretePumpApi();
