import React from "react";
import { useQuery } from "react-query";
import { useFormikContext } from "formik";
import {
  TableList,
  InfoItemVertical,
  numberConvert,
  Select,
  DatePicker,
  ButtonCancel,
  ButtonCreate,
  dateConvert,
  Input
} from "components";
import { Row, Col, Card } from "react-bootstrap";
import SetupKomponenJaminanKehilanganPekerjaanBulananApi from "../__SetupKomponenJaminanKehilanganPekerjaanBulananApi__";
import { bulanMapper } from "../__SetupKomponenJaminanKehilanganPekerjaanBulananUtilities__";

const SectionDataSetup = ({ onSearchButtonClick, onResetClickButton }) => {
  const { values, setValues, errors, touched } = useFormikContext();

  const defaultSearch = {
    periodeGajianId: values?.periodeGajianId,
    periodePerencanaan: values?.periodePerencanaan,
    bulan: values?.bulan,
    unitOrganisasiId: values?.unitOrganisasiId,
    unitOrganisasiNama: values?.unitOrganisasiNama,
    tglAwal: values?.tglAwal,
    tglAkhir: values?.tglAkhir,
    tglSetup: values?.tglSetup
  };

  const getUnitOrganisasi = useQuery(["jabatan", "dropdown"], () =>
    SetupKomponenJaminanKehilanganPekerjaanBulananApi.getUnitOrganisasi()
  );
  const getBulan = useQuery(["bulan", "dropdown"], () => SetupKomponenJaminanKehilanganPekerjaanBulananApi.getBulan());

  return (
    <>
      <Input
        disabled
        label="Periode Penggajian"
        name="periodeGajianId"
        placeholder="Masukkan periode penggajian"
        value={values?.periodeGajianId ? String(values?.periodeGajianId) : "-"}
        onChange={(val) =>
          setValues({
            ...values,
            periodeGajianId: val.target.value,
            periodePerencanaan: val.target.value
          })
        }
        error={Boolean(errors.periodeGajianId && touched.periodeGajianId)}
        errorText={Boolean(errors.periodeGajianId && touched.periodeGajianId) && errors.periodeGajianId}
      />
      <Select
        label="Unit Organisasi"
        options={getUnitOrganisasi?.data ?? []}
        placeholder="Pilih salah satu..."
        defaultValue={
          values?.unitOrganisasiId
            ? getUnitOrganisasi?.data?.find((item) => item.value === values?.unitOrganisasiId)
            : null
        }
        onChange={(val) => {
          setValues({
            ...values,
            unitOrganisasiId: val.value,
            unitOrganisasiNama: val.label
          });
        }}
        loading={getUnitOrganisasi?.isFetching}
        errorFetch={getUnitOrganisasi.isError}
        error={Boolean(errors.unitOrganisasiId && touched.unitOrganisasiId)}
        errorText={Boolean(errors.unitOrganisasiId && touched.unitOrganisasiId) && errors.unitOrganisasiId}
      />
      <Select
        label="Bulan Penggajian"
        options={getBulan?.data ?? []}
        placeholder="Pilih salah satu..."
        defaultValue={values?.bulan ? getBulan?.data?.find((item) => item.value === values?.bulan) : null}
        onChange={(val) => {
          const tglNewStart = val.value <= 1 ?
            `${values?.periodePerencanaan - 1}-12-26`
            : `${values?.periodePerencanaan}-${val?.value < 10 ? `0${val?.value - 1}` : val?.value - 1}-26`;

          const tglNewEnd = `${values?.periodePerencanaan}-${val?.value < 10 ? `0${val?.value}` : val?.value}-25`;

          setValues({
            ...values,
            bulan: val.value,
            bulanNama: val.label,
            tglAwal: tglNewStart,
            tglAkhir: tglNewEnd
          });
        }}
        loading={getBulan?.isFetching}
        errorFetch={getBulan.isError}
        error={Boolean(errors.bulan && touched.bulan)}
        errorText={Boolean(errors.bulan && touched.bulan) && errors.bulan}
      />
      <div>
        <div style={{ fontSize: 14 }}>Tanggal Penggajian</div>
        <div className="d-flex align-items-center justify-content-between">
          <div style={{ width: "44%" }}>
            <DatePicker
              key={`${values?.periodeGajianId} ${values?.bulan}`}
              placeholderText="dd/mm/yyyy"
              selected={values?.periodeGajianId && values?.bulan ? values?.tglAwal : undefined}
              disabled={true}
            />
          </div>
          <small>s/d</small>
          <div style={{ width: "44%" }}>
            <DatePicker
              key={`${values?.periodeGajianId} ${values?.bulan}`}
              placeholderText="dd/mm/yyyy"
              selected={values?.periodeGajianId && values?.bulan ? values?.tglAkhir : undefined}
              disabled={true}
            />
          </div>
        </div>
      </div>
      <DatePicker
        disabled
        label="Tanggal Setup"
        placeholderText="dd-mm-yyyy"
        selected={values?.tglSetup}
        onChange={(val) =>
          setValues({
            ...values,
            tglSetup: val
          })
        }
        error={Boolean(errors.tglSetup && touched.tglSetup)}
        errorText={Boolean(errors.tglSetup && touched.tglSetup) && errors.tglSetup}
      />

      <div className="d-flex justify-content-end">
        <div>
          <ButtonCancel text="RESET" className="mb-3 me-2" tooltip={false} onClick={() => onResetClickButton()} />
          <ButtonCreate
            disabled={Boolean(
              !values?.periodeGajianId ||
              !values?.unitOrganisasiId ||
              !values?.tglSetup ||
              !values?.tglAwal ||
              !values?.tglAkhir
            )}
            text="CARI"
            className="mb-3"
            tooltip={false}
            onClick={() =>
              onSearchButtonClick({
                active: true,
                data: defaultSearch
              })
            }
          />
        </div>
      </div>
    </>
  );
};

const TabelDataPekerja = () => {
  const { values } = useFormikContext();
  // const date = dateConvert();
  const number = numberConvert();

  return (
    <>
      <TableList
        maxHeight={550}
        tableHeader={[
          { text: "NIK" },
          { text: "Pekerja" },
          { text: "Jabatan" },
          { text: "Upah Tetap BPJS" },
          { text: "Besaran Upah" },
          { text: "Iuran Perusahaan" },
          { text: "Iuran Karyawan" },
          { text: "Jenis BPJS" },
        ]}
        tableBody={[
          {
            field: "nik"
          },
          {
            field: "pekerjaNama"
          },
          {
            field: "jabatanNama"
          },
          {
            props: { textRight: true },
            customField: (val) => "Rp" + number.getWithComa(val?.upahBpjs, { minimumFractionDigits: 2 })
          },
          {
            props: { textRight: true },
            customField: (val) => "Rp" + number.getWithComa(val?.besaranUpah, { minimumFractionDigits: 2 })
          },
          {
            props: { textRight: true },
            customField: (val) => "Rp" + number.getWithComa(val?.iuranPerusahaan, { minimumFractionDigits: 2 })
          },
          {
            props: { textRight: true },
            customField: (val) => "Rp" + number.getWithComa(val?.iuranKaryawan, { minimumFractionDigits: 2 })
          },
          { field: "jenis" }
        ]}
        data={values?.detail}
      />
    </>
  );
};

export const Content = ({ action, onSearchButtonClick, onResetClickButton }) => {
  const { values } = useFormikContext();
  const number = numberConvert();
  const date = dateConvert();

  if (action === "READ") {
    return (
      <>
        <InfoItemVertical label="Komponen Payroll" text={values.komponenPayroll} />
        <InfoItemVertical label="Periode Penggajian" text={values.periodePerencanaan} />
        <InfoItemVertical label="Bulan Penggajian" text={bulanMapper(values.bulan)} />
        <InfoItemVertical label="NIK" text={values.nik} />
        <InfoItemVertical label="Pekerja" text={values.pekerjaNama} />
        <InfoItemVertical label="Unit Organisasi" text={values.unitOrganisasiNama} />
        <InfoItemVertical label="Jabatan" text={values.jabatanNama} />
        <InfoItemVertical
          label="Upah Tetap BPJS"
          text={values.upahBpjs ? "Rp" + number.getWithComa(values?.upahBpjs, { minimumFractionDigits: 2 }) : "-"}
        />

        <InfoItemVertical
          label="Besaran Upah"
          text={values.besaranUpah ? "Rp" + number.getWithComa(values?.besaranUpah, { minimumFractionDigits: 2 }) : "-"}
        />
        <InfoItemVertical
          label="Iuran Perusahaan"
          text={
            values.iuranPerusahaan
              ? "Rp" + number.getWithComa(values?.iuranPerusahaan, { minimumFractionDigits: 2 })
              : "-"
          }
        />
        <InfoItemVertical
          label="Iuran Karyawan"
          text={
            values.iuranKaryawan ? "Rp" + number.getWithComa(values?.iuranKaryawan, { minimumFractionDigits: 2 }) : "-"
          }
        />
        <InfoItemVertical
          label="Total Iuran BPJS TK - Jaminan Kehilangan Pekerjaan"
          text={values.totalIuran ? "Rp" + number.getWithComa(values?.totalIuran, { minimumFractionDigits: 2 }) : "-"}
        />
        <InfoItemVertical label="Jenis BPJS" text={values.jenis} />
      </>
    );
  }

  return (
    <>
      <Row>
        <Col md={4}>
          <Card>
            <Card.Header>
              <b>Setup Data</b>
            </Card.Header>
            <Card.Body>
              <SectionDataSetup onSearchButtonClick={onSearchButtonClick} onResetClickButton={onResetClickButton} />
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card>
            <Card.Header>
              <b>Data Pekerja</b>
            </Card.Header>
            <Card.Body>
              <Row className="mb-4">
                <Col>
                  <InfoItemVertical label="Periode Penggajian" text={values?.dataInfo?.periodePerencanaan} />
                  <InfoItemVertical
                    label="Bulan Penggajian"
                    text={
                      (values?.dataInfo?.tglAwal ? date.getSlashDMY(new Date(values?.dataInfo?.tglAwal)) : "-") +
                      " s/d " +
                      (values?.dataInfo?.tglAkhir ? date.getSlashDMY(new Date(values?.dataInfo?.tglAkhir)) : "-")
                    }
                  />
                  <InfoItemVertical
                    label="Tanggal Setup"
                    text={values?.dataInfo?.tglSetup ? date.getSlashDMY(new Date(values?.dataInfo?.tglSetup)) : "-"}
                  />
                </Col>
                <Col>
                  <InfoItemVertical label="Unit Organisasi" text={values?.dataInfo?.unitOrganisasiNama} />
                  <InfoItemVertical label="Jenis Upah Minimum" text={values?.dataInfo?.jenisUpahNama} />
                  <InfoItemVertical label="Komponen BPJS" text={values?.dataInfo?.komponenBPJSNama} />
                  {/* <InfoItemVertical
                    label="Proporsi Perusahaan"
                    text={
                      values?.dataInfo?.jaminanPerusahaan
                        ? number?.getWithComa(values?.dataInfo?.jaminanPerusahaan, { minimumFractionDigits: 2, maximumFractionDigits: 4 }) + "%"
                        : "-"
                    }
                  />
                  <InfoItemVertical
                    label="Proporsi Karyawan"
                    text={
                      values?.dataInfo?.jaminanPekerja
                        ? number?.getWithComa(values?.dataInfo?.jaminanPekerja, { minimumFractionDigits: 2, maximumFractionDigits: 4 }) + "%"
                        : "-"
                    }
                  />
                  <InfoItemVertical
                    label="Batas Bawah Upah"
                    text={
                      values?.dataInfo?.batasBawah
                        ? "Rp" + number?.getWithComa(values?.dataInfo?.batasBawah, { minimumFractionDigits: 2 })
                        : "-"
                    }
                  />
                  <InfoItemVertical
                    label="Batas Atas Upah"
                    text={
                      values?.dataInfo?.batasAtas
                        ? "Rp" + number?.getWithComa(values?.dataInfo?.batasAtas, { minimumFractionDigits: 2 })
                        : "-"
                    }
                  />
                  <InfoItemVertical label="Keterangan" text={values?.dataInfo?.keterangan} /> */}
                </Col>
              </Row>
              <TabelDataPekerja />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
