import { Services } from "services";
import { dateConvert } from "utilities";
const date = dateConvert();

class RekapPenghasilanPekerjaApi {
  async getList(params) {
    const fetch = await Services.get("/laporan/rekap-penghasilan-pekerja", { params });
    return fetch?.data?.data;
  }
  async getTanggal(params) {
    const fetch = await Services.get("/laporan/rekap-penghasilan-pekerja/dropdown-tanggal", { params });
    return fetch?.data?.data.map((val) => ({
      value: `${date.getSlashDMY(new Date(val?.tgl_bulan_awal))} - ${date.getSlashDMY(new Date(val?.tgl_bulan_akhir))}`,
      label: `${date.getSlashDMY(new Date(val?.tgl_bulan_awal))} - ${date.getSlashDMY(new Date(val?.tgl_bulan_akhir))}`,
      valueAwal: val?.tgl_bulan_awal,
      valueAkhir: val?.tgl_bulan_akhir
    }));
  }
  async getUnitOrganisasi() {
    const fetch = await Services.get("/master/unit-organisasi/dropdown");
    return fetch?.data?.data.map((val) => ({
      value: val?.master_unit_organisasi_id,
      label: val?.master_unit_organisasi_nama
    }));
  }
  async getPeriodePerencanaan() {
    const fetch = await Services.get("/utility/periode-tahun?feat=rekap-penghasilan-pekerja");
    return fetch?.data?.data.map((val) => ({
      value: val?.periode_tahun,
      label: val?.periode_tahun
    }));
  }
  async getKomponenPayroll() {
    const fetch = await Services.get("/master/komponen-payroll");
    return fetch?.data?.data;
  }
  async getExport(params) {
    const fetch = await Services.get("/laporan/rekap-penghasilan-pekerja/export", { params });
    return fetch?.data?.data;
  }
}

export default new RekapPenghasilanPekerjaApi();
