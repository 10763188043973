import {
  InfoItemVertical, Input,
  InputCurrency, InputNumber, Select, TextArea
} from "components";
import { useFormikContext } from "formik";
import { numberConvert } from "utilities";
import TarifPPhApi from "../__TarifPPhApi__";

export const Content = ({ action }) => {
  const { values, errors, touched, handleChange, setValues } = useFormikContext();
  const number = numberConvert();
  // const date = dateConvert();
  // const getPeriodePenggajian = useQuery(["periode-penggajian", "dropdown"], () => TarifPPhApi.getPeriodePenggajian());

  const getStatus = TarifPPhApi.getStatus();

  if (action === "READ") {
    return (
      <>
        <InfoItemVertical label="Periode Penggajian" text={values.periodeGajian} />
        <InfoItemVertical label="Kode Tarif" text={values?.kodePPh21} />
        <InfoItemVertical
          label="Penghasilan Tahunan"
          text={
            "Rp" +
            (values?.penghasilanTerendah
              ? number.getWithComa(parseInt(values?.penghasilanTerendah), { minimumFractionDigits: 2 })
              : "-") +
            " s/d " +
            "Rp" +
            (values?.penghasilanTertinggi
              ? number.getWithComa(parseInt(values?.penghasilanTertinggi), { minimumFractionDigits: 2 })
              : "-")
          }
        />
        <InfoItemVertical
          label="Persentase PPh 21"
          text={
            values?.persentasePPh21
              ? number.getWithComa(values?.persentasePPh21, { minimumFractionDigits: 0 }) + "%"
              : "-"
          }
        />
        <InfoItemVertical label="Status" text={parseInt(values?.status) === 1 ? "Aktif" : "Tidak Aktif"} />
        <InfoItemVertical label="Keterangan" text={values?.keterangan ? values?.keterangan : "-"} />
      </>
    );
  }

  return (
    <>
      <Input
        label="Periode Penggajian"
        name="periodePenggajianId"
        placeholder="Masukkan periode penggajian"
        value={values.periodeGajianId ? String(values?.periodeGajianId) : "-"}
        onChange={(val) =>
          setValues({
            ...values,
            periodeGajianId: val.value,
            periodeGajian: val.label
          })
        }
        error={Boolean(errors.periodeGajianId && touched.periodeGajianId)}
        errorText={Boolean(errors.periodeGajianId && touched.periodeGajianId) && errors.periodeGajianId}
      />
      <Input
        label="Kode Tarif PPh 21"
        name="kodePPh21"
        placeholder="Masukkan kode tarif PPh 21"
        value={values.kodePPh21}
        onChange={handleChange}
        error={Boolean(errors.kodePPh21 && touched.kodePPh21)}
        errorText={Boolean(errors.kodePPh21 && touched.kodePPh21) && errors.kodePPh21}
      />
      <InputCurrency
        label="Penghasilan Terendah"
        name="penghasilanTerendah"
        placeholder="Masukkan penghasilan terendah"
        value={
          values?.penghasilanTerendah
            ? number.getWithComa(values?.penghasilanTerendah, { minimumFractionDigits: 2 })
            : ""
        }
        onChange={(val) => setValues({ ...values, penghasilanTerendah: val })}
        error={Boolean(errors.penghasilanTerendah && touched.penghasilanTerendah)}
        errorText={Boolean(errors.penghasilanTerendah && touched.penghasilanTerendah) && errors.penghasilanTerendah}
      />
      <InputCurrency
        label="Penghasilan Tertinggi"
        name="penghasilanTertinggi"
        placeholder="Masukkan penghasilan Tertinggi"
        value={
          values?.penghasilanTertinggi
            ? number.getWithComa(values?.penghasilanTertinggi, { minimumFractionDigits: 2 })
            : ""
        }
        onChange={(val) => setValues({ ...values, penghasilanTertinggi: val })}
        error={Boolean(errors.penghasilanTertinggi && touched.penghasilanTertinggi)}
        errorText={Boolean(errors.penghasilanTertinggi && touched.penghasilanTertinggi) && errors.penghasilanTertinggi}
      />

      <InputNumber
        label="Persentase PPh 21"
        name="persentasePPh21"
        suffix="%"
        placeholder="Masukkan persentase PPh21"
        value={values?.persentasePPh21 ? number.getWithComa(values?.persentasePPh21, { minimumFractionDigits: 0 }) : ""}
        onChange={(val) => setValues({ ...values, persentasePPh21: val })}
        error={Boolean(errors.persentasePPh21 && touched.persentasePPh21)}
        errorText={Boolean(errors.persentasePPh21 && touched.persentasePPh21) && errors.persentasePPh21}
      />
      <Select
        label="Status"
        placeholder="Pilih status"
        defaultValue={getStatus?.find((item) => item?.value === parseInt(values?.status))}
        options={getStatus ?? []}
        onChange={(val) =>
          setValues({
            ...values,
            status: val.value
          })
        }
        error={Boolean(errors.status && touched.status)}
        errorText={Boolean(errors.status && touched.status) && errors.status}
      />
      <TextArea
        label="Keterangan"
        name="keterangan"
        placeholder="Masukkan keterangan"
        value={values.keterangan}
        onChange={handleChange}
        error={Boolean(errors.keterangan && touched.keterangan)}
        errorText={Boolean(errors.keterangan && touched.keterangan) && errors.keterangan}
      />
    </>
  );
};
