import { Services } from "services";

class KomponenBPJSApi {
  async getList(params) {
    const fetch = await Services.get("/master/komponen-bpjs", { params });
    return fetch?.data?.data;
  }
  async getDetail(id) {
    const fetch = await Services.get("/master/komponen-bpjs/" + id);
    return fetch?.data?.data;
  }
  async getExport(params) {
    const fetch = await Services.get("/master/komponen-bpjs/export?to=", { params });
    return fetch?.data?.data;
  }
  create(data) {
    return Services.post("/master/komponen-bpjs", data);
  }
  update(data, id) {
    return Services.put("/master/komponen-bpjs/" + id, data);
  }
  delete(id) {
    return Services.delete("/master/komponen-bpjs/" + id);
  }
}

export default new KomponenBPJSApi();
