import * as Yup from "yup";

export const formValidationSchema = Yup.object().shape({
  tglInput: Yup.string().required("Tanggal input permohonan izin wajib diisi"),
  nomor: Yup.string().required("Nomor wajib diisi"),
  unitOrganisasiId: Yup.string().required("Unit organisasi wajib diisi"),
  jabatanId: Yup.string().required("Jabatan wajib diisi"),
  pekerjaId: Yup.string().required("Pekerja wajib diisi"),
  tujuan: Yup.string().required("Tujuan wajib diisi"),
  selama: Yup.string().required("Lama waktu izin wajib diisi"),
  jenisIzinId: Yup.string().required("Jenis izin wajib diisi"),
  catatan: Yup.string().required("Catatan izin wajib diisi")
});
