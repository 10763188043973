import { Services } from "services";
import { fetchDropdownMapper } from "utilities";

const fetchDropdown = fetchDropdownMapper(Services);

class RekapLemburApi {
  async getList(params) {
    const fetch = await Services.get("/laporan/rekap-lembur", { params });
    return fetch?.data?.data;
  }

  async getUnitOrganisasi() {
    const fetch = await Services.get("/master/unit-organisasi/dropdown");
    return fetch?.data?.data.map((val) => ({
      value: val?.master_unit_organisasi_id,
      label: val?.master_unit_organisasi_nama
    }));
  }

  async getPeriodePerencanaan() {
    const fetch = await Services.get("/master/periode-perencanaan/dropdown");
    return fetch?.data?.data.map((val) => ({
      value: val?.master_periode_perencanaan_id,
      label: val?.periode_perencanaan
    }));
  }

  async getPeriodeGajian() {
    const getDropdown = await fetchDropdown("/master/grade-nominal/dropdown", {
      value: "periode_gajian_id",
      label: "periode_perencanaan"
    });
    return [{ value: undefined, label: "Semua" }, ...getDropdown];
  }

  async getExport(params) {
    const fetch = await Services.get("/laporan/rekap-lembur/export", { params });
    return fetch?.data?.data;
  }
}

export default new RekapLemburApi();
