import {
  PermohonanCutiList,
  PermohonanCutiCreate,
  PermohonanCutiUpdate,
  PermohonanCutiDetail
} from "pages/OprasionalSdm";

export const PermohonanCuti = {
  component: <PermohonanCutiList />,
  link: "permohonan-cuti",
  name: "Permohonan Cuti",
  access: "SURAT_CUTI",
  child: [
    {
      name: "Detail",
      link: "/detail/:id",
      component: <PermohonanCutiDetail />
    },
    {
      name: "Ubah",
      link: "/ubah/:id",
      component: <PermohonanCutiUpdate />
    },
    {
      name: "Tambah",
      link: "/tambah",
      component: <PermohonanCutiCreate />
    }
  ]
};
