import {
  ButtonBack, ButtonCreate,
  dateConvert, DatePicker,
  InfoItemVertical, Input, numberConvert, Select,
  Table,
  Tbody,
  Td,
  Th,
  Thead
} from "components";
import { useFormikContext } from "formik";
import { Card, Col, Row } from "react-bootstrap";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import PengajuanPekerjaPensiunApi from "../__PengajuanPekerjaPensiunApi__";

const FormPelanggaran = ({ onFilter, setOnFilter }) => {
  const navigate = useNavigate();

  const { values, errors, touched, setValues, setFieldValue, handleChange } = useFormikContext();

  // const currentYear = new Date().getFullYear();
  // // const getPeriodeAnggaranPenggajian = useQuery(
  // //   ["periode-penggajian", "dropdown"],
  // //   PengajuanPekerjaPensiunApi.getGradenominal
  // // );
  const getUnitOrganisasi = useQuery(["unit-organisasi", "dropdown"], PengajuanPekerjaPensiunApi.getUnitOrganisasi);

  return (
    <>
      <div className="d-flex align-items-end justify-content-between mb-3">
        <div className="d-flex align-items-end">
          <div className="me-2" style={{ width: 260 }}>
            <Input
              name="periodeGajianId"
              label="Periode"
              placeholder="Masukan periode"
              value={values.periodePerencanaan}
              noMargin
              onChange={handleChange}
              error={Boolean(errors.periodeGajianId && touched.periodeGajianId)}
              errorText={Boolean(errors.periodeGajianId && touched.periodeGajianId) && errors.periodeGajianId}
            />
          </div>
          <div className="me-2" style={{ width: 260 }}>
            <DatePicker
              noMargin
              label="Tanggal Batas Pensiun "
              placeholderText="Masukan tanggal batas pensiun"
              name="tanggalBatasPensiun"
              selected={values?.tanggalBatasPensiun ? new Date(values.tanggalBatasPensiun) : ""}
              onChange={(date) => setFieldValue("tanggalBatasPensiun", date)}
              error={Boolean(errors.tanggalBatasPensiun && touched.tanggalBatasPensiun)}
              errorText={
                Boolean(errors.tanggalBatasPensiun && touched.tanggalBatasPensiun) && errors.tanggalBatasPensiun
              }
            />
          </div>
          <div className="me-2" style={{ width: 260 }}>
            <Select
              noMargin
              label="Unit Organisasi"
              placeholder="Pilih salah satu.."
              options={getUnitOrganisasi?.data}
              onChange={(val) => setValues({ ...values, unitOrganisasiId: val.value })}
              error={Boolean(errors.unitOrganisasiId && touched.unitOrganisasiId)}
              errorText={Boolean(errors.unitOrganisasiId && touched.unitOrganisasiId) && errors.unitOrganisasiId}
            />
          </div>
          <div>
            <ButtonCreate
              text="CARI"
              tooltip={false}
              onClick={() =>
                setOnFilter({
                  ...onFilter,
                  periodeGajianId: values?.periodePerencanaan,
                  tanggalBatasPensiun: values?.tanggalBatasPensiun,
                  unitOrganisasiId: values?.unitOrganisasiId
                })
              }
            />
          </div>
        </div>
        <div>
          <ButtonBack tooltip={false} onClick={() => navigate(-1)} />
        </div>
      </div>
    </>
  );
};
const SetupData = ({ dataInfo }) => {
  const date = dateConvert();
  return (
    <Card>
      <Card.Header>
        <b>Setup Data</b>
      </Card.Header>
      <Card.Body>
        <InfoItemVertical label="Periode Penggajian" text={dataInfo?.periode_gajian ?? "-"} />
        <InfoItemVertical
          label="Umur Pensiun"
          text={dataInfo?.umur_pensiun ? `${dataInfo?.umur_pensiun} Tahun` : "-"}
        />
        <InfoItemVertical
          label="Tanggal Setup"
          text={dataInfo?.tgl_setup ? date.getSlashDMY(new Date(dataInfo?.tgl_setup)) : "-"}
        />
        <InfoItemVertical
          label="Tanggal Batas Rencana Pensiun"
          text={dataInfo?.tgl_batas_pensiun ? date.getSlashDMY(new Date(dataInfo?.tgl_batas_pensiun)) : "-"}
        />
        <InfoItemVertical label="Alasan Pensiun" text={dataInfo?.alasan_phk ?? "-"} />
      </Card.Body>
    </Card>
  );
};
const PengajuanDanaPensiunList = ({ action, dataList }) => {
  const date = dateConvert();
  const number = numberConvert();
  const sum =
    action === "CREATE" &&
    dataList?.reduce((accumulator, currentValue) => accumulator + currentValue?.peraturan_total_uang_phk, 0);

  return (
    <Card>
      <Card.Header>
        <b>Pengajuan Anggaran Dana Pensiun</b>
      </Card.Header>
      <Card.Body>
        <Table>
          <Thead>
            <tr>
              <Th rowSpan={2}>No</Th>
              <Th rowSpan={2}>NIK</Th>
              <Th rowSpan={2}>Pekerja</Th>
              <Th rowSpan={2}>Jabatan</Th>
              <Th rowSpan={2}>Unit Organisasi</Th>
              <Th rowSpan={2}>Tanggal Lahir</Th>
              <Th rowSpan={2}>Umur</Th>
              <Th rowSpan={2}>Tanggal Pengangkatan</Th>
              <Th rowSpan={2}>Masa Kerja</Th>
              <Th rowSpan={2}>Uang Tetap</Th>
              <Th colSpan={5}>Perhitungan Uang PHK (Pensiun) (PP35/2021)</Th>
            </tr>
            <tr>
              <Th>Uang Pesangon</Th>
              <Th>Uang Penghargaan Masa Kerja</Th>
              <Th>Uang Pengganti Hak</Th>
              <Th>Uang Pisah</Th>
              <Th>Total Uang PHK (Pensiun)</Th>
            </tr>
          </Thead>
          <Tbody>
            {action === "CREATE" ? (
              dataList?.map((val, index) => (
                <tr>
                  <Td textCenter>{String(index + 1)}</Td>
                  <Td>{val?.nip ?? "-"}</Td>
                  <Td>{val?.pekerja_nama ?? "-"}</Td>
                  <Td>{val?.master_jabatan_nama ?? "-"}</Td>
                  <Td>{val?.master_unit_organisasi_nama ?? "-"}</Td>
                  <Td>{val?.tgl_lahir ? date.getSlashDMY(new Date(val?.tgl_lahir)) : "-"}</Td>
                  <Td>
                    {val?.umur_pekerja
                      ? number.getWithComa(parseInt(val?.umur_pekerja), { minimumFractionDigits: 0 })
                      : "0"}
                  </Td>
                  <Td>{val?.tgl_pengangkatan ? date.getSlashDMY(new Date(val?.tgl_pengangkatan)) : "-"}</Td>
                  <Td textRight>
                    {val?.masa_kerja
                      ? number.getWithComa(parseInt(val?.masa_kerja), { minimumFractionDigits: 0 })
                      : "0"}
                  </Td>
                  <Td textRight>
                    {val?.upah_tetap
                      ? number.getWithComa(parseInt(val?.upah_tetap), { minimumFractionDigits: 0 })
                      : "0"}
                  </Td>
                  <Td textRight>
                    {val?.peraturan_uang_pesangon
                      ? number.getWithComa(parseInt(val?.peraturan_uang_pesangon), { minimumFractionDigits: 0 })
                      : "0"}
                  </Td>
                  <Td textRight>
                    {val?.peraturan_uang_upmk
                      ? number.getWithComa(parseInt(val?.peraturan_uang_upmk), { minimumFractionDigits: 0 })
                      : "0"}
                  </Td>
                  <Td textRight>
                    {val?.peraturan_uang_uph
                      ? number.getWithComa(parseInt(val?.peraturan_uang_uph), { minimumFractionDigits: 0 })
                      : "0"}
                  </Td>
                  <Td textRight>
                    {val?.peraturan_uang_up
                      ? number.getWithComa(parseInt(val?.peraturan_uang_up), { minimumFractionDigits: 0 })
                      : "0"}
                  </Td>
                  <Td textRight>
                    {val?.peraturan_total_uang_phk
                      ? number.getWithComa(parseInt(val?.peraturan_total_uang_phk), { minimumFractionDigits: 0 })
                      : "0"}
                  </Td>
                </tr>
              ))
            ) : (
              <tr>
                <Td textCenter>1</Td>
                <Td>{dataList?.nip ?? "-"}</Td>
                <Td>{dataList?.pekerja_nama ?? "-"}</Td>
                <Td>{dataList?.master_jabatan_nama ?? "-"}</Td>
                <Td>{dataList?.master_unit_organisasi_nama ?? "-"}</Td>
                <Td>{dataList?.tgl_lahir ? date.getSlashDMY(new Date(dataList?.tgl_lahir)) : "-"}</Td>
                <Td>
                  {dataList?.umur_pekerja
                    ? number.getWithComa(parseInt(dataList?.umur_pekerja), { minimumFractionDigits: 0 })
                    : "0"}
                </Td>
                <Td>{dataList?.tgl_pengangkatan ? date.getSlashDMY(new Date(dataList?.tgl_pengangkatan)) : "-"}</Td>
                <Td textRight>
                  {dataList?.masa_kerja
                    ? number.getWithComa(parseInt(dataList?.masa_kerja), { minimumFractionDigits: 0 })
                    : "0"}
                </Td>
                <Td textRight>
                  {dataList?.upah_tetap
                    ? number.getWithComa(parseInt(dataList?.upah_tetap), { minimumFractionDigits: 0 })
                    : "0"}
                </Td>
                <Td textRight>
                  {dataList?.peraturan_uang_pesangon
                    ? number.getWithComa(parseInt(dataList?.peraturan_uang_pesangon), { minimumFractionDigits: 0 })
                    : "0"}
                </Td>
                <Td textRight>
                  {dataList?.peraturan_uang_upmk
                    ? number.getWithComa(parseInt(dataList?.peraturan_uang_upmk), { minimumFractionDigits: 0 })
                    : "0"}
                </Td>
                <Td textRight>
                  {dataList?.peraturan_uang_uph
                    ? number.getWithComa(parseInt(dataList?.peraturan_uang_uph), { minimumFractionDigits: 0 })
                    : "0"}
                </Td>
                <Td textRight>
                  {dataList?.peraturan_uang_up
                    ? number.getWithComa(parseInt(dataList?.peraturan_uang_up), { minimumFractionDigits: 0 })
                    : "0"}
                </Td>
                <Td textRight>
                  {dataList?.peraturan_total_uang_phk
                    ? number.getWithComa(parseInt(dataList?.peraturan_total_uang_phk), { minimumFractionDigits: 0 })
                    : "0"}
                </Td>
              </tr>
            )}
            {action === "CREATE" && (
              <tr>
                <Td textRight colSpan={14}>
                  Total
                </Td>
                <Td textRight>{sum ? number.getWithComa(parseInt(sum), { minimumFractionDigits: 0 }) : "0"}</Td>
              </tr>
            )}
          </Tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export const Content = ({ action, onFilter, setOnFilter, getListForm, getDetail }) => {
  const navigate = useNavigate();
  return (
    <>
      <Row className={action !== "CREATE" && "mb-3"}>
        {action === "CREATE" && (
          <Col>
            <FormPelanggaran onFilter={onFilter} setOnFilter={setOnFilter} />
          </Col>
        )}
        {action !== "CREATE" && (
          <Col>
            <div className="d-flex justify-content-end">
              <ButtonBack tooltip={false} onClick={() => navigate(-1)} />
            </div>
          </Col>
        )}
      </Row>
      <Row>
        <Col md={3}>
          <SetupData dataInfo={action === "CREATE" ? getListForm?.info : getDetail?.data} />
        </Col>
        <Col md={9}>
          <PengajuanDanaPensiunList
            action={action}
            dataList={action === "CREATE" ? getListForm?.pekerja : getDetail?.data}
          />
        </Col>
      </Row>
    </>
  );
};
