import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { Formik } from "formik";
import { ContentLayout } from "layouts";
import { Content } from "./__SetupKomponenJaminanPensiunBulananComps__";
import { formInitialValues } from "./__SetupKomponenJaminanPensiunBulananUtils__";
import { useModalConfirm } from "hooks";
import { ButtonBack, ButtonCreate, dateConvert } from "components";
import SetupKomponenJaminanPensiunBulananApi from "./__SetupKomponenJaminanPensiunBulananApi__";
import { errorSubmitMapper } from "utilities";

export const SetupKomponenJaminanPensiunBulananCreate = () => {
  const navigate = useNavigate();
  const date = dateConvert();
  const modalConfirm = useModalConfirm();

  const [dataFilter, setDataFilter] = useState({
    tanggalSetup: date.getDashYMD(new Date()),
    periodePenggajianId: new Date().getFullYear(),
    unitOrganisasiNama: undefined,
    bulanId: undefined,
    unitOrganisasiId: undefined
  });

  const getListForm = useQuery(
    ["setup-komponen-jaminan-pensiun-form", "list", dataFilter],
    () =>
      SetupKomponenJaminanPensiunBulananApi.getListForm({
        tgl_setup: dataFilter?.tanggalSetup,
        periode_tahun: dataFilter?.periodePenggajianId,
        bulan: dataFilter?.bulanId,
        tgl_bulan_awal: dataFilter?.tglAwal,
        tgl_bulan_akhir: dataFilter?.tglAkhir,
        master_unit_organisasi_id: dataFilter?.unitOrganisasiId
      }),
    {
      enabled: Boolean(
        dataFilter?.periodePenggajianId && dataFilter?.unitOrganisasiId && dataFilter?.tglAwal && dataFilter?.tglAkhir
      )
    }
  );
  const createJaminanPensiun = useMutation((data) => SetupKomponenJaminanPensiunBulananApi.create(data.data), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "create", size: "md" });
      navigate(-1);
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "create", size: "md", ...errorSubmitMapper(err) })
  });

  const formSubmitHandler = () =>
    modalConfirm.trigger({
      type: "create",
      size: "md",
      onHide: modalConfirm.close,
      onSubmit: () => {
        modalConfirm.loading();
        createJaminanPensiun.mutate({
          data: getListForm?.data?.pekerja?.map((val) => ({
            jaminan_bpjs_id: val?.jaminan_bpjs_id ?? "",
            periode_tahun: val?.periode_tahun ?? "",
            tgl_bulan_awal: val?.tgl_bulan_awal ?? "",
            tgl_bulan_akhir: val?.tgl_bulan_akhir ?? "",
            tgl_setup: val?.tgl_setup ?? "",
            master_unit_organisasi_id: val?.master_unit_organisasi_id ?? "",
            pekerja_id: val?.pekerja_id ?? "",
            master_jabatan_id: val?.master_jabatan_id ?? "",
            upah_bpjs: val?.upah_bpjs ?? "",
            besaran_upah: val?.besaran_upah ?? "",
            iuran_perusahaan: val?.iuran_perusahaan ?? "",
            iuran_karyawan: val?.iuran_karyawan ?? "",
            final_upah_pokok: val?.iuran_karyawan ?? "",
            jenis: val?.jenis ?? ""
          }))
        });
      }
    });
  const submitValidationHandler = (errors) =>
    new Promise((resolve, reject) => {
      const getError = Object.values(errors);

      if (getError.length > 0) {
        reject(getError);
      } else {
        resolve();
      }
    });
  const preSubmitHandler = (formik) => {
    const { validateForm, setTouched, setErrors, handleSubmit, setSubmitting } = formik;

    setSubmitting(true);
    validateForm().then(async (err) => {
      setErrors(err);
      setTouched(err);
      await submitValidationHandler(err, getListForm?.data)
        .then(() => handleSubmit())
        .catch((err) =>
          modalConfirm.trigger({
            type: "error",
            title: "Data Tidak Lengkap",
            data: err,
            onHide: () => modalConfirm.close()
          })
        )
        .finally(() => {
          setSubmitting(false);
        });
    });
  };

  return (
    <>
      <ContentLayout showBreadcrumb>
        <Formik enableReinitialize initialValues={formInitialValues()} onSubmit={formSubmitHandler}>
          {(formik) => (
            <>
              <div className="text-end mb-3">
                <ButtonBack tooltip={false} onClick={() => navigate(-1)} />
              </div>
              <Content
                action="CREATE"
                getListForm={getListForm}
                dataFilter={dataFilter}
                setDataFilter={setDataFilter}
              />
              <div className="text-end mt-3">
                <ButtonCreate
                  tooltip={false}
                  disabled={getListForm?.data === undefined ? true : false}
                  onClick={() => preSubmitHandler(formik)}
                  className="px-4"
                />
              </div>
            </>
          )}
        </Formik>
      </ContentLayout>
    </>
  );
};
