import { Services } from "services";
import { fetchDropdownMapper } from "utilities";

const fetchDropdown = fetchDropdownMapper(Services);

class SetupInsentifKelangkaanKompetensiApi {
  async getList(params) {
    const fetch = await Services.get("/master/insentif-kelangkaan-kompetensi", { params });
    return fetch?.data?.data;
  }

  async getDetail(id) {
    const fetch = await Services.get("/master/insentif-kelangkaan-kompetensi/" + id);
    return fetch?.data?.data;
  }

  getJabatan() {
    return fetchDropdown("/master/jabatan/dropdown", {
      value: "master_jabatan_id",
      label: "master_jabatan_nama"
    });
  }

  getStatus() {
    return [
      {
        value: 1,
        label: "Aktif"
      },
      {
        value: 2,
        label: "Tidak Aktif"
      }
    ];
  }

  async getExport(params) {
    const fetch = await Services.get("/master/insentif-kelangkaan-kompetensi/export?to=", { params });
    return fetch?.data?.data;
  }

  create(data) {
    return Services.post("/master/insentif-kelangkaan-kompetensi", data);
  }

  update(data, id) {
    return Services.put("/master/insentif-kelangkaan-kompetensi/" + id, data);
  }

  delete(id) {
    return Services.delete("/master/insentif-kelangkaan-kompetensi/" + id);
  }
}

export default new SetupInsentifKelangkaanKompetensiApi();
