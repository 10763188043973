import { Services } from "services";

class KategoriTempatTujuanApi {
  async getList(params) {
    const fetch = await Services.get("/master/kategori-tempat-tujuan", { params });
    return fetch?.data?.data;
  }

  async getExport(params) {
    const fetch = await Services.get("/master/kategori-tempat-tujuan/export", { params });
    return fetch?.data?.data;
  }

  create(data) {
    return Services.post("/master/kategori-tempat-tujuan", data);
  }

  update(data, id) {
    return Services.put("/master/kategori-tempat-tujuan/" + id, data);
  }

  delete(id) {
    return Services.delete("/master/kategori-tempat-tujuan/" + id);
  }
}

export default new KategoriTempatTujuanApi();
