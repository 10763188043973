import { useState } from "react";
import { useMutation, UseMutationOptions } from "react-query";
import { ServicesUploads } from "services";

/**
 * Hooks yang menangani hal-hal yang berkaitan dengan upload data
 * @param {string} link - endpoint uploads
 * @param {UseMutationOptions} config - konfigurasi useMutation React Query
 */
export const useUploads = (link, config) => {
  const [progress, setProgress] = useState(0);

  const uploads = useMutation((data) => {
    setProgress(0);

    return ServicesUploads.post(link, data, {
      onUploadProgress: (process) => setProgress(Math.round((process.loaded * 100) / process.total))
    });
  }, config);

  return {
    ...uploads,
    progress
  };
};
