import React, { useState } from "react";
import { useMutation } from "react-query";
import { ButtonGroup, Row, Col } from "react-bootstrap";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { Formik, useFormikContext } from "formik";
import {
  Button,
  TableList,
  ButtonCreate,
  ButtonUpdate,
  ButtonDelete,
  ModalCreate,
  ModalUpdate,
  Select,
  Input,
  TextArea,
  RadioButton,
  DatePicker,
  RadioButtonWrapper,
  ModalDetail,
  InfoItemVertical,
  ButtonDetail
} from "components";
import { useModalConfirm } from "hooks";
import { errorSubmitMapper, errorFetchingSelectMapper, dateConvert } from "utilities";
import { formInitialValues, formSubmitMapper, formValidationSchema } from "../__PekerjaUtils__";
import PekerjaApi from "../__PekerjaApi__";

const ModalKeluargaDetail = () => {
  const { values } = useFormikContext();
  const date = dateConvert();
  return (
    <div>
      <div className="d-flex">
        <h3>Profile</h3>
        <div
          style={{
            borderBottom: "1px solid #adb5bd",
            width: "100%",
            margin: "10px 0 20px 10px"
          }}
        />
      </div>
      <InfoItemVertical label="Nama" text={values.keluargaNama} />
      <InfoItemVertical label="Hubungan" text={values.hubunganKeluargaNama} />
      <InfoItemVertical label="Status Kawin" text={values.statusPerkawinanNama} />
      <InfoItemVertical label="Status Kawin" text={values.statusPerkawinanNama} />
      <InfoItemVertical
        label="Tgl. Cerai"
        text={values?.tglCerai ? date.getDetailFull(new Date(values.tglCerai)) : ""}
      />
      <InfoItemVertical label="Status Kehidupan" text={values.status === true ? "Hidup" : "Meninggal"} />
      <InfoItemVertical
        label="Tgl. Meninggal"
        text={values?.tglMeninggal ? date.getDetailFull(new Date(values.tglMeninggal)) : ""}
      />
      <InfoItemVertical label="Tempat Lahir" text={values.tempatLahir} />
      <InfoItemVertical
        label="Tgl. Lahir"
        text={values?.tglLahir ? date.getDetailFull(new Date(values.tglLahir)) : ""}
      />
      <InfoItemVertical label="Jenis Kelamin" text={values.jenisKelaminNama} />
      <InfoItemVertical label="Agama" text={values.agamaNama} />
      <InfoItemVertical label="No. BPJS Kesehatan" text={values.noBpjsKesehatan} />
      <InfoItemVertical label="No. Rekam Medis" text={values.noRekamMedis} />

      <div className="d-flex mt-4">
        <h3>Alamat</h3>
        <div
          style={{
            borderBottom: "1px solid #adb5bd",
            width: "100%",
            margin: "10px 0 20px 10px"
          }}
        />
      </div>
      <InfoItemVertical label="Provinsi" text={values.provinsiNama} />
      <InfoItemVertical label="Kabupaten" text={values.kabupatenNama} />
      <InfoItemVertical label="Kecamatan" text={values.kecamatanNama} />
      <InfoItemVertical label="Desa" text={values.desaNama} />
      <InfoItemVertical label="RT" text={values.rt} />
      <InfoItemVertical label="RW" text={values.rw} />
      <InfoItemVertical label="Alamat" text={values.alamat} />

      <div className="d-flex mt-4">
        <h3>Pekerjaan</h3>
        <div
          style={{
            borderBottom: "1px solid #adb5bd",
            width: "100%",
            margin: "10px 0 20px 10px"
          }}
        />
      </div>
      <InfoItemVertical label="Pekerjaan" text={values.pekerjaan} />
      <InfoItemVertical label="Nama Instansi / Sekolah" text={values.instansi} />
      <InfoItemVertical label="Alamat Instansi" text={values.alamatInstansi} />

      <div className="d-flex mt-4">
        <h3>Pendidikan</h3>
        <div
          style={{
            borderBottom: "1px solid #adb5bd",
            width: "100%",
            margin: "10px 0 20px 10px"
          }}
        />
      </div>
      <InfoItemVertical label="Pendidikan Terakhir" text={values.pendidikanTerakhirNama} />
      <InfoItemVertical
        label="Tgl. Lulus"
        text={values?.tglLulus ? date.getDetailFull(new Date(values.tglLulus)) : ""}
      />
      <InfoItemVertical label="Nama Universitas / Sekolah" text={values.tempatPendidikan} />

      <div className="d-flex mt-4">
        <h3>Kontak</h3>
        <div
          style={{
            borderBottom: "1px solid #adb5bd",
            width: "100%",
            margin: "10px 0 20px 10px"
          }}
        />
      </div>
      <InfoItemVertical label="Telepon" text={values.telp} />
      <InfoItemVertical label="Hp" text={values.hp} />
      <InfoItemVertical label="Whats App" text={values.wa} />
      <InfoItemVertical label="Email" text={values.email} />
      <InfoItemVertical label="Keterangan" text={values.keterangan} />
    </div>
  );
};

const ModalKeluargaForm = ({ dataParentAlamat }) => {
  const { values, handleChange, errors, touched, setValues, setFieldValue } = useFormikContext();
  const getHubunganKeluarga = useQuery(["hubunganKeluarga", "dropdown"], () => PekerjaApi.getHubunganKeluarga());
  const getStatusKawin = useQuery(["statusKawin", "dropdown"], () => PekerjaApi.getStatusKawin());
  const getAgama = useQuery(["agama", "dropdown"], () => PekerjaApi.getAgama());
  const getJenisKelamin = useQuery(["jenisKelamin", "dropdown"], () => PekerjaApi.getJenisKelamin());
  const getJenjangPendidikan = useQuery(["jenjangPendidikan", "dropdown"], () => PekerjaApi.getJenjangPendidikan());
  const getProvinsi = useQuery(["wilayah", "dropdown"], () => PekerjaApi.getWilayah("provinsi"));
  const getKabupaten = useQuery(
    ["kabupaten", "dropdown", values.provinsiKode],
    () => PekerjaApi.getWilayah("kabupaten", { provinsi_kode: values.provinsiKode }),
    {
      enabled: !!values.provinsiKode
    }
  );

  const getKecamatan = useQuery(
    ["kecamatan", "dropdown", values.kabupatenKode],
    () => PekerjaApi.getWilayah("kecamatan", { kabupaten_kode: values.kabupatenKode }),
    {
      enabled: !!values.kabupatenKode
    }
  );

  const getDesa = useQuery(
    ["desa", "dropdown", values.kecamatanKode],
    () => PekerjaApi.getWilayah("desa", { kecamatan_kode: values.kecamatanKode }),
    {
      enabled: !!values.kecamatanKode
    }
  );

  return (
    <div>
      <div className="d-flex">
        <h3>Profile</h3>
        <div
          style={{
            borderBottom: "1px solid #adb5bd",
            width: "100%",
            margin: "10px 0 20px 10px"
          }}
        />
      </div>
      <Input
        label="Nama"
        name="keluargaNama"
        type="text"
        placeholder="Masukan nama"
        value={values?.keluargaNama}
        onChange={handleChange}
        error={Boolean(errors.keluargaNama && touched.keluargaNama)}
        errorText={Boolean(errors.keluargaNama && touched.keluargaNama) && errors.keluargaNama}
      />
      <Select
        label="Hubungan"
        loading={getHubunganKeluarga.isLoading}
        options={getHubunganKeluarga?.data ?? []}
        defaultValue={getHubunganKeluarga?.data?.find((item) => item.value === values.hubunganKeluargaId)}
        onChange={(val) => {
          setValues({
            ...values,
            hubunganKeluargaId: val.value,
            hubunganKeluargaNama: val.label
          });
        }}
        error={Boolean(errors.hubunganKeluargaId && touched.hubunganKeluargaId)}
        errorText={Boolean(errors.hubunganKeluargaId && touched.hubunganKeluargaId) && errors.hubunganKeluargaId}
        errorFetch={getHubunganKeluarga.isError}
        errorFetchText={errorFetchingSelectMapper(getHubunganKeluarga.error)}
      />
      <Select
        label="Status Perkawin"
        name="statusPerkawinanId"
        loading={getStatusKawin.isLoading}
        options={getStatusKawin.data ?? []}
        defaultValue={getStatusKawin?.data?.find((item) => item.value === values.statusPerkawinanId)}
        onChange={(val) => {
          setValues({
            ...values,
            statusPerkawinanId: val.value,
            statusPerkawinanNama: val.label
          });
        }}
        error={Boolean(errors.statusPerkawinanId && touched.statusPerkawinanId)}
        errorText={Boolean(errors.statusPerkawinanId && touched.statusPerkawinanId) && errors.statusPerkawinanId}
        errorFetch={getStatusKawin.isError}
        errorFetchText={errorFetchingSelectMapper(getStatusKawin.error)}
      />
      <DatePicker
        label="Tgl. Cerai"
        placeholderText="Masukan tgl. cerai"
        selected={values?.tglCerai ? new Date(values.tglCerai) : ""}
        onChange={(date) => {
          setFieldValue("tglCerai", date);
        }}
        error={Boolean(errors.tglCerai && touched.tglCerai)}
        errorText={Boolean(errors.tglCerai && touched.tglCerai) && errors.tglCerai}
      />
      <RadioButtonWrapper label="Status">
        <RadioButton
          label="Hidup"
          checked={Boolean(values.status === true)}
          name="status"
          value={values.status}
          onClick={() => setFieldValue("status", true)}
        />
        <RadioButton
          label="Meninggal"
          checked={Boolean(values.status === false)}
          name="status"
          value={values.status}
          onClick={() => setFieldValue("status", false)}
        />
      </RadioButtonWrapper>

      <DatePicker
        disabled={Boolean(values.status !== false)}
        label="Tgl. Meninggal"
        placeholderText="Masukan tgl. meninggal"
        selected={values?.tglMeninggal ? new Date(values.tglMeninggal) : ""}
        onChange={(date) => setFieldValue("tglMeninggal", date)}
        error={Boolean(errors.tglMeninggal && touched.tglMeninggal)}
        errorText={Boolean(errors.tglMeninggal && touched.tglMeninggal) && errors.tglMeninggal}
      />
      <Row>
        <Col lg="6">
          <Input
            label="Tempat Lahir"
            type="text"
            name="tempatLahir"
            placeholder="Masukan tempat lahir"
            value={values?.tempatLahir}
            onChange={handleChange}
            error={Boolean(errors.tempatLahir && touched.tempatLahir)}
            errorText={Boolean(errors.tempatLahir && touched.tempatLahir) && errors.tempatLahir}
          />
        </Col>
        <Col lg="6">
          <DatePicker
            label="Tgl. Lahir"
            name="tglLahir"
            placeholderText="Masukan tgl lahir"
            selected={values?.tglLahir ? new Date(values.tglLahir) : ""}
            onChange={(val) => setFieldValue("tglLahir", val)}
            error={Boolean(errors.tglLahir && touched.tglLahir)}
            errorText={Boolean(errors.tglLahir && touched.tglLahir) && errors.tglLahir}
          />
        </Col>
      </Row>
      <Select
        label="Jenis Kelamin"
        placeholder="Pilih jenis kelamin"
        loading={getJenisKelamin.isLoading}
        options={getJenisKelamin?.data ?? []}
        defaultValue={getJenisKelamin?.data?.find((item) => item.value === values.jenisKelaminId)}
        onChange={(val) => {
          setFieldValue("jenisKelaminId", val?.value);
          setValues({
            ...values,
            jenisKelaminId: val.value,
            jenisKelaminNama: val.label
          });
        }}
        error={Boolean(errors.jenisKelaminId && touched.jenisKelaminId)}
        errorText={Boolean(errors.jenisKelaminId && touched.jenisKelaminId) && errors.jenisKelaminId}
        errorFetch={getJenisKelamin.isError}
        errorFetchText={errorFetchingSelectMapper(getJenisKelamin.error)}
      />
      <Select
        label="Agama"
        placeholder="Pilih agama"
        loading={getAgama.isLoading}
        options={getAgama?.data ?? []}
        defaultValue={getAgama?.data?.find((item) => item.value === values.agamaId)}
        onChange={(val) => {
          setValues({
            ...values,
            agamaId: val.value,
            agamaNama: val.label
          });
        }}
        error={Boolean(errors.agamaId && touched.agamaId)}
        errorText={Boolean(errors.agamaId && touched.agamaId) && errors.agamaId}
        errorFetch={getAgama.isError}
        errorFetchText={errorFetchingSelectMapper(getAgama.error)}
      />
      <Input
        label="No. BPJS Kesehatan"
        name="noBpjsKesehatan"
        type="text"
        placeholder="Masukan no. bpjs kesehatan"
        value={values?.noBpjsKesehatan}
        onChange={handleChange}
        error={Boolean(errors.noBpjsKesehatan && touched.noBpjsKesehatan)}
        errorText={Boolean(errors.noBpjsKesehatan && touched.noBpjsKesehatan) && errors.noBpjsKesehatan}
      />
      {/* <Input
        label="No. Rekam Medis di RSPR"
        name="noRekamMedis"
        type="text"
        placeholder="Masukan rekam medis"
        value={values?.noRekamMedis}
        onChange={handleChange}
        error={Boolean(errors.noRekamMedis && touched.noRekamMedis)}
        errorText={Boolean(errors.noRekamMedis && touched.noRekamMedis) && errors.noRekamMedis}
      /> */}

      <div className="d-flex mt-4">
        <h3>Alamat</h3>
        <div
          style={{
            borderBottom: "1px solid #adb5bd",
            width: "100%",
            margin: "10px 0 20px 10px"
          }}
        />
      </div>

      <Button size="sm" text="Copy alamat pegawai" onClick={() => setValues({ ...values, ...dataParentAlamat })} />
      <Select
        label="Provinsi"
        placeholder="Pilih Provinsi"
        loading={getProvinsi.isLoading}
        options={getProvinsi.data ?? []}
        defaultValue={getProvinsi.data?.find((item) => item?.value?.toString() === values?.provinsiKode?.toString())}
        onChange={(val) => {
          setValues({
            ...values,
            provinsiKode: val?.value,
            provinsiNama: val?.label,
            kabupatenKode: "",
            kabupatenNama: "",
            kecamatanKode: "",
            kecamatanNama: "",
            desaKode: "",
            desaNama: ""
          });
        }}
        error={Boolean(errors.provinsiKode && touched.provinsiKode)}
        errorText={Boolean(errors.provinsiKode && touched.provinsiKode) && errors.provinsiKode}
        errorFetch={getProvinsi.isError}
        errorFetchText={errorFetchingSelectMapper(getProvinsi.error)}
      />

      <Select
        disable={!values.provinsiKode}
        label="Kabupaten"
        placeholder="Pilih kabupaten"
        loading={getKabupaten.isLoading}
        options={getKabupaten?.data ?? []}
        defaultValue={getKabupaten?.data?.find((item) => item?.value?.toString() === values?.kabupatenKode?.toString())}
        onChange={(val) => {
          setFieldValue("kabupatenKode", val?.value);
          setValues({
            ...values,
            kabupatenKode: val?.value,
            kabupatenNama: val?.label,
            kecamatanKode: "",
            kecamatanNama: "",
            desaKode: "",
            desaNama: ""
          });
        }}
        error={Boolean(errors.kabupatenKode && touched.kabupatenKode)}
        errorText={Boolean(errors.kabupatenKode && touched.kabupatenKode) && errors.kabupatenKode}
        errorFetch={getKabupaten.isError}
        errorFetchText={errorFetchingSelectMapper(getKabupaten.error)}
      />

      <Select
        disable={!values.kabupatenKode}
        label="Kecamatan"
        placeholder="Pilih kecamatan"
        loading={getKecamatan.isLoading}
        options={getKecamatan?.data ?? []}
        defaultValue={getKecamatan?.data?.find((item) => item?.value?.toString() === values?.kecamatanKode?.toString())}
        onChange={(val) => {
          setValues({
            ...values,
            kecamatanKode: val?.value,
            kecamatanNama: val?.label,
            desaKode: "",
            desaNama: ""
          });
        }}
        error={Boolean(errors.kecamatanKode && touched.kecamatanKode)}
        errorText={Boolean(errors.kecamatanKode && touched.kecamatanKode) && errors.kecamatanKode}
        errorFetch={getKecamatan.isError}
        errorFetchText={errorFetchingSelectMapper(getKecamatan.error)}
      />

      <Select
        disable={!values.kecamatanKode}
        label="Desa"
        placeholder="Pilih desa"
        loading={getDesa.isLoading}
        options={getDesa?.data ?? []}
        defaultValue={getDesa?.data?.find((item) => item?.value?.toString() === values?.desaKode?.toString())}
        onChange={(val) => {
          setValues({
            ...values,
            desaKode: val?.value,
            desaNama: val?.label
          });
        }}
        error={Boolean(errors.desaKode && touched.desaKode)}
        errorText={Boolean(errors.desaKode && touched.desaKode) && errors.desaKode}
        errorFetch={getDesa.isError}
        errorFetchText={errorFetchingSelectMapper(getDesa.error)}
      />

      <Row>
        <Col lg="6">
          <Input
            label="RT"
            type="number"
            name="rt"
            placeholder="Masukan RT"
            value={values?.rt}
            onChange={handleChange}
            error={Boolean(errors.rt && touched.rt)}
            errorText={Boolean(errors.rt && touched.rt) && errors.rt}
          />
        </Col>
        <Col lg="6">
          <Input
            label="RW"
            type="number"
            name="rw"
            placeholder="Masukan RW"
            value={values?.rw}
            onChange={handleChange}
            error={Boolean(errors.rw && touched.rw)}
            errorText={Boolean(errors.rw && touched.rw) && errors.rw}
          />
        </Col>
      </Row>
      <TextArea
        label="Alamat"
        name="alamat"
        placeholder="Masukan alamat"
        rows="4"
        value={values?.alamat}
        onChange={handleChange}
        error={Boolean(errors.alamat && touched.alamat)}
        errorText={Boolean(errors.alamat && touched.alamat) && errors.alamat}
      />

      <div className="d-flex mt-4">
        <h3>Pekerjaan</h3>
        <div
          style={{
            borderBottom: "1px solid #adb5bd",
            width: "100%",
            margin: "10px 0 20px 10px"
          }}
        />
      </div>

      <Input
        label="Pekerjaan"
        type="text"
        name="pekerjaan"
        placeholder="Masukan pekerjaan"
        value={values?.pekerjaan}
        onChange={handleChange}
        error={Boolean(errors.pekerjaan && touched.pekerjaan)}
        errorText={Boolean(errors.pekerjaan && touched.pekerjaan) && errors.pekerjaan}
      />
      <Input
        label="Nama Instansi / Sekolah"
        type="text"
        name="instansi"
        placeholder="Masukan nama instansi / sekolah"
        value={values?.instansi}
        onChange={handleChange}
        error={Boolean(errors.instansi && touched.instansi)}
        errorText={Boolean(errors.instansi && touched.instansi) && errors.instansi}
      />
      <TextArea
        label="Alamat"
        name="alamatInstansi"
        placeholder="Masukan alamat"
        rows="4"
        value={values?.alamatInstansi}
        onChange={handleChange}
        error={Boolean(errors.alamatInstansi && touched.alamatInstansi)}
        errorText={Boolean(errors.alamatInstansi && touched.alamatInstansi) && errors.alamatInstansi}
      />

      <div className="d-flex mt-4">
        <h3>Pendidikan</h3>
        <div
          style={{
            borderBottom: "1px solid #adb5bd",
            width: "100%",
            margin: "10px 0 20px 10px"
          }}
        />
      </div>
      <Select
        label="Pendidikan Terakhir"
        name="pendidikanTerakhir"
        loading={getJenjangPendidikan.isLoading}
        options={getJenjangPendidikan?.data ?? []}
        defaultValue={getJenjangPendidikan?.data?.find((item) => item.value === values.pendidikanTerakhirId)}
        onChange={(val) => {
          setValues({
            ...values,
            pendidikanTerakhirId: val.value,
            pendidikanTerakhirNama: val.label
          });
        }}
        error={Boolean(errors.pendidikanTerakhirId && touched.pendidikanTerakhirId)}
        errorText={Boolean(errors.pendidikanTerakhirId && touched.pendidikanTerakhirId) && errors.pendidikanTerakhirId}
      />
      <DatePicker
        label="Tgl. Lulus"
        placeholderText="Masukan tgl. lulus"
        selected={values?.tglLulus ? new Date(values.tglLulus) : ""}
        onChange={(date) => setFieldValue("tglLulus", date)}
        error={Boolean(errors.tglLulus && touched.tglLulus)}
        errorText={Boolean(errors.tglLulus && touched.tglLulus) && errors.tglLulus}
      />

      <Input
        label="Nama Universitas / Sekolah"
        type="text"
        name="tempatPendidikan"
        placeholder="Masukan nama universitas / sekolah"
        value={values?.tempatPendidikan}
        onChange={handleChange}
        error={Boolean(errors.tempatPendidikan && touched.tempatPendidikan)}
        errorText={Boolean(errors.tempatPendidikan && touched.tempatPendidikan) && errors.tempatPendidikan}
      />
      <div className="d-flex mt-4">
        <h3>Kontak</h3>
        <div
          style={{
            borderBottom: "1px solid #adb5bd",
            width: "100%",
            margin: "10px 0 20px 10px"
          }}
        />
      </div>
      <Input
        label="Telepon"
        type="text"
        name="telp"
        placeholder="Masukan telepon"
        value={values?.telp}
        onChange={handleChange}
        error={Boolean(errors.telp && touched.telp)}
        errorText={Boolean(errors.telp && touched.telp) && errors.telp}
      />
      <Input
        label="Handphone"
        type="text"
        name="hp"
        placeholder="Masukan handphone"
        value={values?.hp}
        onChange={handleChange}
        error={Boolean(errors.hp && touched.hp)}
        errorText={Boolean(errors.hp && touched.hp) && errors.hp}
      />
      <Input
        label="Whatsapp"
        type="text"
        name="wa"
        placeholder="Masukan whatsapp"
        value={values?.wa}
        onChange={handleChange}
        error={Boolean(errors.wa && touched.wa)}
        errorText={Boolean(errors.wa && touched.wa) && errors.wa}
      />
      <Input
        label="Email"
        type="text"
        name="email"
        placeholder="Masukan email"
        value={values?.email}
        onChange={handleChange}
        error={Boolean(errors.email && touched.email)}
        errorText={Boolean(errors.email && touched.email) && errors.email}
      />
      <TextArea
        label="Keterangan"
        name="keteranganKontak"
        placeholder="Masukan keterangan"
        rows="4"
        value={values?.keteranganKontak}
        onChange={handleChange}
        error={Boolean(errors.keteranganKontak && touched.keteranganKontak)}
        errorText={Boolean(errors.keteranganKontak && touched.keteranganKontak) && errors.keteranganKontak}
      />
    </div>
  );
};

const TableKeluarga = ({ action, dataParentAlamat }) => {
  const parentFormik = useFormikContext();
  const modalConfirm = useModalConfirm();
  const [modal, setModal] = useState({
    show: false,
    action: "",
    index: "",
    data: {}
  });

  const formInitialValues = {
    keluargaId: modal?.data?.keluargaId,
    keluargaNama: modal?.data?.keluargaNama,
    hubunganKeluargaId: modal?.data?.hubunganKeluargaId,
    hubunganKeluargaNama: modal?.data?.hubunganKeluargaNama,
    statusPerkawinanId: modal?.data?.statusPerkawinanId,
    statusPerkawinanNama: modal?.data?.statusPerkawinanNama,
    status: modal?.data?.status,
    tglCerai: modal?.data?.tglCerai,
    tglMeninggal: modal?.data?.tglMeninggal,
    tempatLahir: modal?.data?.tempatLahir,
    tglLahir: modal?.data?.tglLahir,
    jenisKelaminId: modal?.data?.jenisKelaminId,
    jenisKelaminNama: modal?.data?.jenisKelaminNama,
    agamaId: modal?.data?.agamaId,
    agamaNama: modal?.data?.agamaNama,
    noBpjsKesehatan: modal?.data?.noBpjsKesehatan,
    noRekamMedis: modal?.data?.noRekamMedis,
    provinsiKode: modal?.data?.provinsiKode,
    provinsiNama: modal?.data?.provinsiNama,
    kabupatenKode: modal?.data?.kabupatenKode,
    kabupatenNama: modal?.data?.kabupatenNama,
    kecamatanKode: modal?.data?.kecamatanKode,
    kecamatanNama: modal?.data?.kecamatanNama,
    desaKode: modal?.data?.desaKode,
    desaNama: modal?.data?.desaNama,
    rt: modal?.data?.rt,
    rw: modal?.data?.rw,
    alamat: modal?.data?.alamat,
    pekerjaan: modal?.data?.pekerjaan,
    instansi: modal?.data?.instansi,
    alamatInstansi: modal?.data?.alamatInstansi,
    pendidikanTerakhirId: modal?.data?.pendidikanTerakhirId,
    pendidikanTerakhirNama: modal?.data?.pendidikanTerakhirNama,
    tglLulus: modal?.data?.tglLulus,
    tempatPendidikan: modal?.data?.tempatPendidikan,
    telp: modal?.data?.telp,
    hp: modal?.data?.hp,
    wa: modal?.data?.wa,
    email: modal?.data?.email,
    keteranganKontak: modal?.data?.keteranganKontak
  };

  const closeModalHandler = () => setModal({ show: false, action: "", data: {}, index: "" });

  const createDataHandler = (values, { resetForm }) => {
    let newData = [{ ...values }, ...parentFormik.values.detail];

    parentFormik.setFieldValue("detail", newData);
    resetForm();
    closeModalHandler();
  };

  const updateDataHandler = (values, { resetForm }) => {
    let newData = [...parentFormik.values.detail];

    newData.splice(modal.index, 1, values);
    parentFormik.setFieldValue("detail", newData);
    resetForm();
    closeModalHandler();
  };

  const deleteDataHandler = (index) => {
    let newData = [...parentFormik.values.detail];

    newData.splice(index, 1);
    parentFormik.setFieldValue("detail", newData);
  };

  const onButtonDetailClick = (val, index) => setModal({ show: true, data: val, action: "READ", index });

  const onButtonCreateClick = () => setModal({ show: true, data: {}, action: "CREATE", index: "" });

  const onButtonUpdateClick = (val, index) => setModal({ show: true, data: val, action: "UPDATE", index });

  const onButtonDeleteClick = (val, index) =>
    modalConfirm.trigger({
      type: "delete",
      component: (
        <Formik initialValues={val}>
          <ModalKeluargaDetail />
        </Formik>
      ),
      onSubmit: () => {
        deleteDataHandler(index);
        modalConfirm.close();
      },
      onHide: modalConfirm.close
    });

  return (
    <>
      {!Boolean(action === "READ") && (
        <div className="text-end mb-4">
          <ButtonCreate tooltip={false} onClick={onButtonCreateClick} />
        </div>
      )}

      <TableList
        responsive
        data={parentFormik.values?.detail}
        tableHeader={[
          {
            text: "Nama"
          },
          {
            text: "Hubungan"
          },
          {
            text: "Jenis Kelamin"
          },
          {
            text: "Alamat"
          },

          {
            text: "No. Handphone"
          },
          {
            text: "Email"
          },
          {
            text: "Aksi",
            props: { fixed: true, width: 50, className: action === "READ" ? "d-none" : "" }
          }
        ]}
        tableBody={[
          {
            field: "keluargaNama"
          },
          {
            field: "hubunganKeluargaNama"
          },
          {
            field: "jenisKelaminNama"
          },
          {
            field: "alamat"
          },

          {
            field: "hp"
          },
          {
            field: "email"
          },
          {
            props: { fixed: true, className: action === "READ" ? "d-none" : "" },
            customField: (data, index) => (
              <ButtonGroup size="sm">
                <ButtonDetail icon noText onClick={() => onButtonDetailClick(data, index)} />
                <ButtonUpdate icon noText onClick={() => onButtonUpdateClick(data, index)} />
                <ButtonDelete icon noText onClick={() => onButtonDeleteClick(data, index)} />
              </ButtonGroup>
            )
          }
        ]}
      />

      {/* Read Modal */}
      <Formik enableReinitialize initialValues={formInitialValues}>
        {() => (
          <ModalDetail show={modal.show && modal.action === "READ"} onHide={closeModalHandler}>
            <ModalKeluargaDetail />
          </ModalDetail>
        )}
      </Formik>

      {/* Create Modal */}
      <Formik
        initialValues={formInitialValues}
        validationSchema={formValidationSchema.keluarga}
        onSubmit={createDataHandler}
      >
        {({ handleSubmit }) => (
          <ModalCreate
            show={modal.show && modal.action === "CREATE"}
            onHide={closeModalHandler}
            onSubmit={handleSubmit}
          >
            <ModalKeluargaForm dataParentAlamat={dataParentAlamat} />
          </ModalCreate>
        )}
      </Formik>

      {/* Update Modal */}
      <Formik
        enableReinitialize
        initialValues={formInitialValues}
        validationSchema={formValidationSchema.keluarga}
        onHide={closeModalHandler}
        onSubmit={updateDataHandler}
      >
        {({ handleSubmit }) => (
          <ModalUpdate
            show={modal.show && modal.action === "UPDATE"}
            onHide={closeModalHandler}
            onSubmit={handleSubmit}
          >
            <ModalKeluargaForm dataParentAlamat={dataParentAlamat} />
          </ModalUpdate>
        )}
      </Formik>
    </>
  );
};

export const TabKeluarga = ({ action, refreshData, isGeneralUser }) => {
  const { id } = useParams();
  const modalConfirm = useModalConfirm();
  const parentFormik = useFormikContext();

  const updateKeluarga = useMutation((data) => PekerjaApi.update(data, id), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "update", size: "md", customTextInfoTitle: "Data Berhasil Disimpan" });
      refreshData();
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "update", size: "md", ...errorSubmitMapper(err) })
  });
  const updateKeluargaSelf = useMutation((data) => PekerjaApi.updateSelf(data), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "update", size: "md", customTextInfoTitle: "Data Berhasil Disimpan" });
      refreshData();
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "update", size: "md", ...errorSubmitMapper(err) })
  });

  const submitValidationHandler = (errors) =>
    new Promise((resolve, reject) => {
      const getError = Object.values(errors);

      if (getError.length > 0) {
        reject(getError);
      } else {
        resolve();
      }
    });

  const preSubmitHandler = (values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting) => {
    setSubmitting(true);
    validateForm().then(async (err) => {
      setErrors(err);
      setTouched(err);

      await submitValidationHandler(err, values)
        .then(() => handleSubmit())
        .catch((err) =>
          modalConfirm.trigger({
            type: "error",
            title: "Data Tidak Lengkap",
            data: err,
            onHide: () => modalConfirm.close()
          })
        )
        .finally(() => {
          setSubmitting(false);
        });
    });
  };

  const formSubmitHandler = (values) =>
    modalConfirm.trigger({
      size: "lg",
      type: "custom",
      onHide: modalConfirm.close,
      title: "Konfirmasi",
      customButtonShow: true,
      customButtonText: "Simpan",
      customButtonVariant: "primary",
      customTextHeader: "Apakah anda yakin menyimpan data ini?",
      onSubmit: () => {
        modalConfirm.loading();
        if (isGeneralUser === true) {
          updateKeluargaSelf.mutate(formSubmitMapper.keluarga(values.detail));
        } else {
          updateKeluarga.mutate(formSubmitMapper.keluarga(values.detail));
        }
      },
      component: (
        <Formik initialValues={values}>
          <TableKeluarga action="READ" />
        </Formik>
      )
    });

  return (
    <Formik
      enableReinitialize
      initialValues={{ detail: formInitialValues.keluarga(parentFormik.values) }}
      onSubmit={formSubmitHandler}
    >
      {({ values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting }) => {
        return (
          <>
            <TableKeluarga action={action} dataParentAlamat={formInitialValues.alamat(parentFormik.values)} />
            {!Boolean(action === "READ") && (
              <div className="text-end mt-3">
                <Button
                  text="Simpan"
                  className="px-4"
                  onClick={() =>
                    preSubmitHandler(values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting)
                  }
                />
              </div>
            )}
          </>
        );
      }}
    </Formik>
  );
};
