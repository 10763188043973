import { Services } from "services";

class SetupJenisUpahMinimumApi {
  async getList(params) {
    const fetch = await Services.get("/master/jenis-upah-minimum", { params });
    return fetch?.data?.data;
  }
  async getDetail(id) {
    const fetch = await Services.get("/master/jenis-upah-minimum/" + id);
    return fetch?.data?.data;
  }
  async getExport(params) {
    const fetch = await Services.get("/master/jenis-upah-minimum/export?to=", { params });
    return fetch?.data?.data;
  }
  create(data) {
    return Services.post("/master/jenis-upah-minimum", data);
  }
  update(data, id) {
    return Services.put("/master/jenis-upah-minimum/" + id, data);
  }
  delete(id) {
    return Services.delete("/master/jenis-upah-minimum/" + id);
  }
}

export default new SetupJenisUpahMinimumApi();
