import React, { useState } from "react";
import { useQuery, useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { ListLayout } from "layouts";
import { ButtonGroup } from "react-bootstrap";
import {
  ButtonDetail,
  ButtonUpdate,
  ButtonDelete,
  ButtonFilter,
  ModalFilter,
  InputSearch,
  toastTrigger,
  Select,
  dateConvert,
  DatePicker
} from "components";
import { useAccessRights, useModalConfirm } from "hooks";
import { exportHandler, errorSubmitMapper } from "utilities";
import { Formik, useFormikContext } from "formik";
import { debounce } from "lodash";
import { formInitialValues } from "./__SuratPerintahLemburUtilities__";
import SuratPerintahLemburApi from "./__SuratPerintahLemburApi__";

const ModalFilterBody = () => {
  const { values, setValues } = useFormikContext();

  const getUnitOrganisasi = useQuery(["unit-organisasi-for-list", "dropdown"], () =>
    SuratPerintahLemburApi.getUnitOrganisasiForList()
  );

  return (
    <>
      <DatePicker
        label="Tanggal"
        placeholderText="Pilih tanggal perintah"
        selected={values?.tglLembur}
        onChange={(val) =>
          setValues({
            ...values,
            tglLembur: val
          })
        }
      />
      <Select
        label="Unit Organisasi"
        placeholder="Pilih unit organisasi"
        defaultValue={getUnitOrganisasi?.data?.find((item) => item.value === values.unitOrganisasiId)}
        options={getUnitOrganisasi?.data ?? []}
        onChange={(val) => setValues({ ...values, unitOrganisasiId: val.value })}
        loading={getUnitOrganisasi.isFetching}
        errorFetch={getUnitOrganisasi.isError}
      />
    </>
  );
};

export const SuratPerintahLemburList = () => {
  const access = useAccessRights("PERINTAH_LEMBUR");
  const navigate = useNavigate();
  const modalConfirm = useModalConfirm();
  const date = dateConvert();

  const [searchKey, setSearchKey] = useState(undefined);
  const [pagination, setPagination] = useState({
    pageCurrent: 1,
    dataLength: 10
  });

  const [filter, setFilter] = useState({
    show: false,
    active: false,
    data: {
      periodeGajianId: undefined,
      unitOrganisasiId: undefined,
      tglLembur: undefined
    }
  });

  const getSuratPerintahLemburList = useQuery(
    [
      "surat-perintah-lembur",
      "list",
      searchKey,
      pagination.pageCurrent,
      pagination.dataLength,
      filter.data?.tglLembur,
      filter.data?.unitOrganisasiId
    ],
    () =>
      SuratPerintahLemburApi.getList({
        search: searchKey,
        page: pagination.pageCurrent,
        limit: pagination.dataLength,
        tgl_lembur: filter.data?.tglLembur,
        master_unit_organisasi_id: filter.data?.unitOrganisasiId
      }),
    {
      enabled: Boolean(filter?.data?.tglLembur)
    }
  );

  // const updateSuratPerintahLembur = useMutation(({ data, id }) => SuratPerintahLemburApi.update(data, id), {
  //   onSuccess: () => {
  //     modalConfirm.infoSuccess({ typeInfo: "update" });
  //     getSuratPerintahLemburList.refetch();
  //   },
  //   onError: (err) => modalConfirm.infoError({ typeInfo: "update", ...errorSubmitMapper(err) })
  // });

  const deleteSuratPerintahLembur = useMutation((id) => SuratPerintahLemburApi.delete(id), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "delete" });
      getSuratPerintahLemburList.refetch();
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "delete", ...errorSubmitMapper(err) })
  });

  const getSuratPerintahLemburExport = useMutation(
    (data) =>
      SuratPerintahLemburApi.getExport({
        master_unit_organisasi_id: data?.unitOrganisasiId,
        periode_tahun: data?.periodeGajianId,
        tgl_lembur: data?.tglLembur
      }),
    {
      onSuccess: (link) => {
        toastTrigger({ variant: "success", message: "Export berhasil" });
        exportHandler(link, "Data Surat Perintah Lembur");
      },
      onError: () => {
        toastTrigger({ variant: "danger", message: "Export gagal" });
      }
    }
  );

  const onCreateButtonClickHandler = () => navigate("tambah");
  const onUpdateButtonClickHandler = (data) => navigate("ubah/" + data?.perintah_lembur_id);
  const onDetailButtonClickHandler = (data) => navigate("detail/" + data?.perintah_lembur_id);
  const onPaginationChange = (data) => setPagination({ ...pagination, pageCurrent: data });

  const onPaginationDataLengthChange = (data) => setPagination({ ...pagination, dataLength: data });

  const searchHandler = (e) => {
    setSearchKey(e?.target?.value ? e.target.value : undefined);
  };

  const formSubmitHandler = (values, action) =>
    modalConfirm.trigger({
      type: action.toLowerCase(),
      onHide: modalConfirm.close,
      onSubmit: () => {
        modalConfirm.loading();
        deleteSuratPerintahLembur.mutate(values.id);
      },
      component: <Formik initialValues={values}>{/* <Content action="READ" /> */}</Formik>
    });

  const TABLE_HEADER = [
    {
      text: "Tanggal  "
    },
    {
      text: "Unit Organisasi"
    },
    {
      text: "Nomor"
    },
    {
      text: "Aksi",
      props: {
        fixed: true
      }
    }
  ];

  const TABLE_BODY = [
    { customField: (val) => date.getSlashDMY(new Date(val.tgl_lembur)) },
    { field: "master_unit_organisasi_nama" },
    { field: "nomor" },
    {
      props: {
        width: 50,
        fixed: true,
        textCenter: true
      },
      customField: (data) => (
        <ButtonGroup size="sm">
          {access.canRead() && (
            <ButtonDetail icon tooltip={false} noText onClick={() => onDetailButtonClickHandler(data)} />
          )}
          {access.canUpdate() && (
            <ButtonUpdate tooltip={false} icon noText onClick={() => onUpdateButtonClickHandler(data)} />
          )}
          {access.canDelete() && (
            <ButtonDelete
              tooltip={false}
              icon
              noText
              onClick={() => formSubmitHandler(formInitialValues(data), "DELETE")}
            />
          )}
        </ButtonGroup>
      )
    }
  ];

  return (
    <>
      <ListLayout
        customLeftTopSection={() => (
          <>
            <div className="d-flex align-items-end mb-3">
              <div style={{ width: 300 }} className="me-2">
                <InputSearch onChange={debounce(searchHandler, 1500)} />
              </div>
              <div>
                <ButtonFilter
                  active={filter?.active}
                  className="text-nowrap"
                  onClick={() => setFilter({ ...filter, show: true })}
                />
              </div>
            </div>
          </>
        )}
        showRightTopButton={access.canCreate() ? true : false}
        showExportExcelButton={Boolean(filter?.data?.tglLembur && filter?.data?.unitOrganisasiId)}
        onExportExcelButtonClick={() => getSuratPerintahLemburExport.mutate(filter?.data)}
        loadingExportButton={getSuratPerintahLemburExport.isLoading}
        loading={getSuratPerintahLemburList?.isFetching}
        error={getSuratPerintahLemburList?.isError}
        dataTotal={getSuratPerintahLemburList?.data?.total}
        dataLength={pagination.dataLength}
        pageCurrent={pagination.pageCurrent}
        pageTotal={getSuratPerintahLemburList?.data?.last_page}
        onSearchChange={debounce(searchHandler, 1500)}
        onCreateButtonClick={onCreateButtonClickHandler}
        onDataLengthChange={onPaginationDataLengthChange}
        onPaginationChange={onPaginationChange}
        data={getSuratPerintahLemburList?.data?.data}
        tableHeader={TABLE_HEADER}
        tableBody={TABLE_BODY}
      />

      {/* ModalFilter */}
      <Formik
        initialValues={{
          unitOrganisasiId: filter?.data?.unitOrganisasiId,
          tglLembur: filter?.data?.tglLembur
        }}
      >
        {({ resetForm, values }) => (
          <ModalFilter
            show={filter.show}
            onReset={resetForm}
            onHide={() =>
              setFilter({
                ...filter,
                show: false
              })
            }
            onSubmit={() => {
              setFilter({
                show: false,
                active: Boolean(Object.values(values).find((val) => val)),
                data: values
              });
            }}
          >
            <ModalFilterBody />
          </ModalFilter>
        )}
      </Formik>
    </>
  );
};
