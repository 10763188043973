import { Services } from "services";
import { fetchDropdownMapper } from "utilities";

const fetchDropdown = fetchDropdownMapper(Services);

class DetailStatusPekerjaApi {
  async getList(params) {
    const fetch = await Services.get("/master/detail-status-pekerja", { params });
    return fetch?.data?.data;
  }

  async getExport(params) {
    const fetch = await Services.get("/master/detail-status-pekerja/export", { params });
    return fetch?.data?.data;
  }

  getStatusPekerja() {
    return fetchDropdown("/master/status-pekerja/dropdown", {
      value: "master_status_pekerja_id",
      label: "status_pekerja"
    });
  }

  create(data) {
    return Services.post("/master/detail-status-pekerja", data);
  }

  update(data, id) {
    return Services.put("/master/detail-status-pekerja/" + id, data);
  }

  delete(id) {
    return Services.delete("/master/detail-status-pekerja/" + id);
  }
}
export default new DetailStatusPekerjaApi();
