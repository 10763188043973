import {
  EvaluasiJabatanList,
  EvaluasiJabatanCreate,
  EvaluasiJabatanUpdate,
  EvaluasiJabatanDetail
} from "pages/Organisasi";

export const EvaluasiJabatan = {
  name: "Evaluasi Jabatan",
  link: "evaluasi-jabatan",
  access: "EVALUASI_JABATAN",
  component: <EvaluasiJabatanList />,
  child: [
    {
      name: "Detail",
      link: "/detail/:id",
      component: <EvaluasiJabatanDetail />
    },
    {
      name: "Tambah",
      link: "/tambah",
      component: <EvaluasiJabatanCreate />
    },
    {
      name: "Ubah",
      link: "/ubah/:id",
      component: <EvaluasiJabatanUpdate />
    }
  ]
};
