import { useFormikContext } from "formik";
import React from "react";
import { useParams } from "react-router-dom";
import { Card, Row, Col } from "react-bootstrap";
import { InfoItemVertical, TableList, dateConvert, numberConvert, Table, Td, Th, Thead, Tbody, InfoItemHorizontal, ButtonPrint } from "components";

export const Content = () => {
  const { values } = useFormikContext();
  const date = dateConvert();
  const number = numberConvert();

  return (
    <>
      <Row>
        <Col className="mb-4" md={3}>
          <Card>
            <Card.Header className="position-sticky">
              <div>
                <b>Data Penggajian</b>
              </div>
            </Card.Header>
            <Card.Body>
              <InfoItemVertical label="Periode Penggajian" text={new Date(values?.tglAwal).getFullYear()} />
              <InfoItemVertical
                label="Bulan Penggajian"
                text={
                  (values?.tglAwal ? date.getSlashDMY(new Date(values?.tglAwal)) : "-") +
                  " s/d " +
                  (values?.tglAkhir ? date.getSlashDMY(new Date(values?.tglAkhir)) : "-")
                }
              />
              <InfoItemVertical label="Pekerja" text={values?.pekerjaNama} />
              <InfoItemVertical label="NIK" text={values?.nik} />
              <InfoItemVertical label="Jabatan" text={values?.jabatanNama} />
              <InfoItemVertical label="Unit Organisasi" text={values?.unitOrganisasiNama} />
              <InfoItemVertical
                label="Status Penggajian"
                text={values?.statusPenggajian === 1 ? "Aktif" : "Tidak Aktif"}
              />
            </Card.Body>
          </Card>
        </Col>
        <Col md={9}>
          <div className="mb-4">
            <Card>
              <Card.Header>
                <div>
                  <b>Slip Gaji</b>
                </div>
              </Card.Header>
              {/* <div style={{ height: "680px", overflowY: "auto" }}> */}
              <Card.Body className="mb-2">
                <div>
                  <b>Penghasilan</b>
                </div>
                <TableList
                  maxHeight="100%"
                  tableHeader={[{ text: "Komponen Payroll" }, { props: { width: "200px" }, text: "Nominal" }]}
                  tableBody={[
                    { field: "nama" },
                    {
                      props: { textRight: true },
                      customField: (val) =>
                        val.nominal ? "Rp" + number.getWithComa(val.nominal, { minimumFractionDigits: 2 }) : "Rp0"
                    }
                  ]}
                  data={values?.detailPenghasilan}
                />
              </Card.Body>
              <Card.Body>
                <div>
                  <b>Pemotongan</b>
                </div>
                <TableList
                  maxHeight="100%"
                  tableHeader={[{ text: "Komponen Payroll" }, { props: { width: "200px" }, text: "Nominal" }]}
                  tableBody={[
                    { field: "nama" },
                    {
                      props: { textRight: true },
                      customField: (val) =>
                        val.nominal ? "Rp" + number.getWithComa(val.nominal, { minimumFractionDigits: 2 }) : "Rp0"
                    }
                  ]}
                  data={values?.detailPotongan}
                />
              </Card.Body>
              <Card.Body>
                <div>
                  <b>Penyesuaian</b>
                </div>
                <TableList
                  maxHeight="100%"
                  tableHeader={[{ text: "Komponen Payroll" }, { props: { width: "200px" }, text: "Nominal" }]}
                  tableBody={[
                    { field: "nama" },
                    {
                      props: { textRight: true },
                      customField: (val) =>
                        val.nominal ? "Rp" + number.getWithComa(val.nominal, { minimumFractionDigits: 2 }) : "Rp0"
                    }
                  ]}
                  data={values?.detailPenyesuaian}
                />
              </Card.Body>
              <Card.Body>
                <div>
                  <b>Total Penghasilan</b>
                </div>
                <Table>
                  <Thead className="position-sticky">
                    <Th width="40px">No</Th>
                    <Th>Komponen Payroll</Th>
                    <Th width="200px">Nominal</Th>
                  </Thead>
                  <Tbody>
                    <tr>
                      <Td textCenter>1</Td>
                      <Td>Gaji Kotor</Td>
                      <Td textRight>{"Rp" + number.getWithComa(values?.gajiKotor, { minimumFractionDigits: 2 })}</Td>
                    </tr>
                    <tr>
                      <Td textCenter>2</Td>
                      <Td>Total Potongan</Td>
                      <Td textRight>
                        {"Rp" + number.getWithComa(values?.totalPotongan, { minimumFractionDigits: 2 })}
                      </Td>
                    </tr>
                    <tr>
                      <Td textCenter>3</Td>
                      <Td>Penyesuaian</Td>
                      <Td textRight>
                        {"Rp" + number.getWithComa(values?.totalPenyesuaian, { minimumFractionDigits: 2 })}
                      </Td>
                    </tr>
                    <tr>
                      <Td textCenter>4</Td>
                      <Td>Netto</Td>
                      <Td textRight>{"Rp" + number.getWithComa(values?.totalGaji, { minimumFractionDigits: 2 })}</Td>
                    </tr>
                    <tr>
                      <Td textCenter>5</Td>
                      <Td>
                        <b>Pembulatan</b>
                      </Td>
                      <Td textRight>
                        {<b>{"Rp" + number.getWithComa(values?.totalPembulatan, { minimumFractionDigits: 2 })}</b>}
                      </Td>
                    </tr>
                  </Tbody>
                </Table>
              </Card.Body>
              {/* </div> */}
            </Card>
          </div>
        </Col>
      </Row>
    </>
  );
};
export const ContentNew = ({ onPrintButtonClickHandler }) => {
  const { values } = useFormikContext();
  const date = dateConvert();
  const number = numberConvert();
  const { id } = useParams();

  return (
    <div className="mb-4">
      <Card>
        <Card.Header style={{ backgroundColor: "transparent" }}>
          <div className="text-center">
            <b>SLIP GAJI</b>
          </div>
        </Card.Header>
        <div className="d-flex justify-content-between mx-4 mt-4 mb-2">
          <InfoItemHorizontal width={50} label="Periode" text={new Date(values?.tglAwal).getFullYear()} />
          <InfoItemHorizontal width={110}
            label="Bulan Penggajian"
            text={
              (values?.tglAwal ? date.getSlashDMY(new Date(values?.tglAwal)) : "-") +
              " s/d " +
              (values?.tglAkhir ? date.getSlashDMY(new Date(values?.tglAkhir)) : "-")
            }
          />
        </div>
        <hr />
        <div style={{ fontSize: "14px" }} className="d-flex mx-4">{`${values?.nik} ${values?.pekerjaNama} ( ${values?.unitOrganisasiNama} - ${values?.jabatanNama} )`}</div>
        <hr />
        <Card.Body className="mb-2">
          <hr />
          <div className="text-center" style={{ fontSize: "14px" }}><b>PENGHASILAN</b></div>
          <hr />

          {values?.detailPenghasilan?.map(val => (
            <div className="d-flex justify-content-between" style={{ fontSize: "14px" }}>
              <div className="mb-2">{val?.nama ?? "-"}</div>
              <div className="mb-2">{val.nominal ? "Rp" + number.getWithComa(val.nominal, { minimumFractionDigits: 2 }) : "Rp0"}</div>
            </div>
          ))}

          <hr className="mt-5" />
          <div className="text-center" style={{ fontSize: "14px" }}><b>PENGURANGAN PENNGHASILAN</b></div>
          <hr />

          {values?.detailPotongan?.map(val => (
            <div className="d-flex justify-content-between" style={{ fontSize: "14px" }}>
              <div className="mb-2">{val?.nama ?? "-"}</div>
              <div className="mb-2">{val.nominal ? "Rp" + number.getWithComa(val.nominal, { minimumFractionDigits: 2 }) : "Rp0"}</div>
            </div>
          ))}

          <hr className="mt-5" />
          <div className="text-center" style={{ fontSize: "14px" }}><b>PENYESUAIAN</b></div>
          <hr />

          {values?.detailPenyesuaian?.map(val => (
            <div className="d-flex justify-content-between" style={{ fontSize: "14px" }}>
              <div className="mb-2">{val?.nama ?? "-"}</div>
              <div className="mb-2">{val.nominal ? "Rp" + number.getWithComa(val.nominal, { minimumFractionDigits: 2 }) : "Rp0"}</div>
            </div>
          ))}

          <hr className="mt-5" />

          <div className="d-flex justify-content-between" style={{ fontSize: "14px" }}>
            <div className="mb-2">GAJI BRUTO</div>
            <div className="mb-2">{values?.gajiKotor ? "Rp" + number.getWithComa(values?.gajiKotor, { minimumFractionDigits: 2 }) : "Rp0"}</div>
          </div>
          <div className="d-flex justify-content-between" style={{ fontSize: "14px" }}>
            <div className="mb-2">PEMOTONGAN</div>
            <div className="mb-2">{values?.totalPotongan ? "Rp" + number.getWithComa(values?.totalPotongan, { minimumFractionDigits: 2 }) : "Rp0"}</div>
          </div>
          <div className="d-flex justify-content-between" style={{ fontSize: "14px" }}>
            <div className="mb-2">PENYESUAIAN</div>
            <div className="mb-2">{values?.totalPenyesuaian ? "Rp" + number.getWithComa(values?.totalPenyesuaian, { minimumFractionDigits: 2 }) : "Rp0"}</div>
          </div>
          <div className="d-flex justify-content-between" style={{ fontSize: "14px" }}>
            <div className="mb-2">GAJI NETTO</div>
            <div className="mb-2">{values?.totalGaji ? "Rp" + number.getWithComa(values?.totalGaji, { minimumFractionDigits: 2 }) : "Rp0"}</div>
          </div>
        </Card.Body>
        <Card.Footer style={{ backgroundColor: "transparent" }}>
          <ButtonPrint tooltip={false} onClick={() => onPrintButtonClickHandler(id)} />
        </Card.Footer>
      </Card>
    </div>
  );
};
