import { Services } from "services";

class SatuanKPIApi {
  async getList(params) {
    const fetch = await Services.get("/master/satuan-kpi", { params });
    return fetch?.data?.data;
  }

  async getExport(params) {
    const fetch = await Services.get("/master/satuan-kpi/export", { params });
    return fetch?.data?.data;
  }

  create(data) {
    return Services.post("/master/satuan-kpi", data);
  }

  delete(id) {
    return Services.delete("/master/satuan-kpi/" + id);
  }

  update(data, id) {
    return Services.put("/master/satuan-kpi/" + id, data);
  }
}
export default new SatuanKPIApi();
