import { SetupUangLemburPerjamList, SetupUangLemburPerjamCreate } from "pages/Payroll";

export const SetupUangLemburPerjam = {
    component: <SetupUangLemburPerjamList />,
    link: "uang-lembur-perjam",
    name: "Setup Uang Lembur Perjam",
    access: "PEKERJA_LEMBUR_PERJAM",
    child: [
        {
            name: "Tambah",
            link: "/tambah",
            component: <SetupUangLemburPerjamCreate />
        }
    ]
};
