import {
  ButtonCreate, Checkbox,
  CheckboxWrapper, DataStatus, dateConvert, DatePicker, InfoItemVertical, Input, Select, Tabs
} from "components";
import { useFormikContext } from "formik";
import { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useQuery } from "react-query";
import SetupJadwalApi from "../__SetupJadwalApi__";
import { TabSetupJadwal } from "./TabSetupJadwal";

const SectionDataSetup = ({ action, onSearchButtonClick, onResetClickButton }) => {
  const { values, setValues, errors, touched } = useFormikContext();
  const date = dateConvert();

  const defaultSearch = {
    periodeGajianId: values?.periodeGajianId,
    periodePerencanaan: values?.periodePerencanaan,
    bulan: values?.bulan,
    jabatanId: values?.jabatanId,
    jabatanNama: values?.jabatanNama,
    pekerjaId: values?.pekerjaId,
    pekerjaNama: values?.pekerjaNama,
    tglAwal: values?.tglAwal,
    tglAkhir: values?.tglAkhir,
    tglSetup: values?.tglSetup,
    regularShift: values?.regularShift,
    weekNumber: values?.weekNumber
  };

  const getJabatan = useQuery(["jabatan", "dropdown"], () =>
    SetupJadwalApi.getJabatan()
  );
  const getPekerja = useQuery(["pekerja", "dropdown", values?.jabatanId], () =>
    SetupJadwalApi.getPekerja({
      master_jabatan_id: values?.jabatanId
    })
  );
  const getBulan = useQuery(["bulan", "dropdown"], () => SetupJadwalApi.getBulan());

  if (action === "UPDATE") {
    return (
      <>
        <Input disabled label="Periode" value={values?.periodeGajianId} />
        <Input disabled label="Bulan" value={new Date(values?.bulan).toLocaleString("id-ID", { month: "long" })} />
        <div>
          <div style={{ fontSize: 14 }}>Tanggal Penggajian</div>
          <div className="d-flex align-items-center justify-content-between">
            <div style={{ width: "44%" }}>
              <DatePicker
                disabled
                key={`${values?.periodeGajianId} ${values?.bulan}`}
                placeholderText="dd/mm/yyyy"
                selected={values?.periodeGajianId && values?.bulan ? values?.tglAwal : undefined}
                disable={Boolean(!values?.bulan)}
                onChange={(val) =>
                  setValues({
                    ...values,
                    tglAwal: val,
                    bulan: new Date(val).getMonth() + 1,
                    tglAkhir: new Date(new Date(val).setDate(new Date(val).getDate() + 30))
                  })
                }
              />
            </div>
            <small>s/d</small>
            <div style={{ width: "44%" }}>
              <DatePicker
                disabled
                key={`${values?.periodeGajianId} ${values?.bulan}`}
                placeholderText="dd/mm/yyyy"
                selected={values?.periodeGajianId && values?.bulan ? values?.tglAkhir : undefined}
                disable={Boolean(!values?.bulan)}
                onChange={(val) =>
                  setValues({
                    ...values,
                    tglAkhir: val
                  })
                }
              />
            </div>
          </div>
        </div>
        <Input disabled label="Jabatan" value={values?.jabatanNama} />
        <Input disabled label="Pekerja" value={values?.pekerjaNama} />
      </>
    );
  }

  return (
    <>
      <Input disabled label="Periode Penggajian" value={values?.periodePerencanaan} />
      <Select
        label="Jabatan"
        options={getJabatan?.data ?? []}
        placeholder="Pilih salah satu..."
        defaultValue={
          values?.jabatanId
            ? getJabatan?.data?.find((item) => item.value === values?.jabatanId)
            : null
        }
        onChange={(val) => {
          setValues({
            ...values,
            jabatanId: val.value,
            jabatanNama: val.label
          });
        }}
        loading={getJabatan?.isFetching}
        errorFetch={getJabatan.isError}
        error={Boolean(errors.jabatanId && touched.jabatanId)}
        errorText={Boolean(errors.jabatanId && touched.jabatanId) && errors.jabatanId}
      />
      <Select
        label="Pekerja"
        options={getPekerja?.data ?? []}
        placeholder="Pilih salah satu..."
        defaultValue={values?.pekerjaId ? getPekerja?.data?.find((item) => item.value === parseInt(values?.pekerjaId)) : null}
        onChange={(val) => {
          setValues({
            ...values,
            pekerjaId: val.value,
            pekerjaNama: val.label
          });
        }}
        loading={getPekerja?.isFetching}
        errorFetch={getPekerja.isError}
        error={Boolean(errors.pekerjaId && touched.pekerjaId)}
        errorText={Boolean(errors.pekerjaId && touched.pekerjaId) && errors.pekerjaId}
      />
      <Select
        label="Bulan Penggajian"
        disable={Boolean(!values?.periodeGajianId)}
        options={getBulan?.data ?? []}
        placeholder="Pilih salah satu..."
        defaultValue={values?.bulan ? getBulan?.data?.find((item) => item.value === values?.bulan) : null}
        onChange={(val) => {
          setValues({
            ...values,
            bulan: val.value,
            bulanNama: val.label,
            tglAwal: `${values?.periodePerencanaan}-${val?.value < 10 ? `0${val?.value}` : val?.value}-1`,
            tglAkhir: date.getDashYMD(new Date(values?.periodePerencanaan, val?.value < 10 ? `0${val?.value}` : val?.value, 0))
          });
        }}
        loading={getBulan?.isFetching}
        errorFetch={getBulan.isError}
        error={Boolean((errors.bulan && touched.bulan) || errors.validateDate)}
        errorText={Boolean(errors.bulan && touched.bulan) && errors.bulan ? errors.bulan : errors.validateDate}
      />
      <div>
        <div style={{ fontSize: 14 }}>Tanggal Penggajian</div>
        <div className="d-flex align-items-center justify-content-between">
          <div style={{ width: "44%" }}>
            <DatePicker
              disabled
              key={`${values?.periodeGajianId} ${values?.bulan}`}
              placeholderText="dd/mm/yyyy"
              selected={values?.periodeGajianId && values?.bulan ? values?.tglAwal : undefined}
              disable={Boolean(!values?.bulan)}
              onChange={(val) =>
                setValues({
                  ...values,
                  tglAwal: val,
                  bulan: new Date(val).getMonth() + 1,
                  tglAkhir: new Date(new Date(val).setDate(new Date(val).getDate() + 30))
                })
              }
              error={Boolean(errors.tglAwal && touched.tglAwal) || errors.validateDate}
              errorText={
                Boolean(errors.tglAwal && touched.tglAwal) && errors.tglAwal ? errors.tglAwal : errors.validateDate
              }
            />
          </div>
          <small>s/d</small>
          <div style={{ width: "44%" }}>
            <DatePicker
              disabled
              key={`${values?.periodeGajianId} ${values?.bulan}`}
              placeholderText="dd/mm/yyyy"
              selected={values?.periodeGajianId && values?.bulan ? values?.tglAkhir : undefined}
              disable={Boolean(!values?.bulan)}
              onChange={(val) =>
                setValues({
                  ...values,
                  tglAkhir: val
                })
              }
              error={Boolean(errors.tglAkhir && touched.tglAkhir)}
              errorText={Boolean(errors.tglAkhir && touched.tglAkhir) && errors.tglAkhir}
            />
          </div>
        </div>
      </div>

      <DatePicker
        disabled
        label="Tanggal Setup"
        placeholderText="dd-mm-yyyy"
        selected={values?.tglSetup}
        onChange={(val) =>
          setValues({
            ...values,
            tglSetup: val
          })
        }
        error={Boolean(errors.tglSetup && touched.tglSetup)}
        errorText={Boolean(errors.tglSetup && touched.tglSetup) && errors.tglSetup}
      />

      <CheckboxWrapper>
        <Checkbox
          label="Gunakan Shift Reguler"
          name="regularShift"
          checked={values?.checked === true}
          onChange={() =>
            values?.checked === true
              ? setValues({
                ...values,
                checked: false,
                regularShift: 2
              })
              : setValues({
                ...values,
                checked: true,
                regularShift: 1
              })
          }
        />
      </CheckboxWrapper>

      <div className="d-flex justify-content-end">
        <div>
          {/* <ButtonCancel text="RESET" className="mb-3 me-2" tooltip={false} onClick={() => onResetClickButton()} /> */}
          <ButtonCreate
            disabled={Boolean(
              !values?.periodeGajianId || !values?.jabatanId || !values?.bulan
            )}
            text="CARI"
            className="mb-3"
            tooltip={false}
            onClick={() =>
              onSearchButtonClick({
                active: true,
                data: defaultSearch
              })
            }
          />
        </div>
      </div>
    </>
  );
};

const TabsSection = ({ action, refreshData, activeTab, setActiveTab, setWeekNumber }) => {
  const { values } = useFormikContext();
  let tabHeader = [];

  for (let i = 1; i <= 6; i++) {
    if (values?.maxWeek >= i && values?.maxWeek !== undefined) {
      tabHeader.push(
        {
          key: "week_" + i,
          title: "Minggu ke-" + i,
          component: <TabSetupJadwal action={action} refreshData={refreshData} />
        },
      )
    }
  }

  return (
    <Row>
      {/* Tabs Section */}
      <Col>
        <Tabs
          defaultActiveKey={activeTab}
          tabs={
            tabHeader?.length !== 0 ? tabHeader : (
              [{
                key: "week_default",
                title: "Minggu ke-",
                component: <TabSetupJadwal action="READ" refreshData={refreshData} />
              }]
            )
          }
          onSelect={(val) => {
            setActiveTab(val)
            setWeekNumber({
              active: true,
              data: {
                periodeGajianId: values?.periodeGajianId,
                periodePerencanaan: values?.periodePerencanaan,
                bulan: values?.bulan,
                jabatanId: values?.jabatanId,
                jabatanNama: values?.jabatanNama,
                pekerjaId: values?.pekerjaId,
                pekerjaNama: values?.pekerjaNama,
                tglAwal: values?.tglAwal,
                tglAkhir: values?.tglAkhir,
                tglSetup: values?.tglSetup,
                regularShift: values?.regularShift,
                tabKey: val,
                weekNumber: val.split('_').at([1]),
                // weekNumber: val,
              }
            })
          }} />
      </Col>
    </Row>
  )
}

export const Content = ({ action, onSearchButtonClick, onResetClickButton, isLoading }) => {
  const { values } = useFormikContext();
  const [activeTab, setActiveTab] = useState("week_".concat(values?.weekNumber));

  const bulanMapper = (bulan) => {
    return new Date(bulan).toLocaleString("id-ID", { month: "long" });
  };

  const dataTable = (maxWeek, act) => {
    let tableComponents = [];
    for (let i = 1; i <= 6; i++) {
      if (values?.maxWeek >= i && values?.maxWeek !== undefined) {
        tableComponents.push(
          <>
            <div className="mb-4">
              {"Minggu ke-" + i + " "}
              <TabSetupJadwal action="READ" mingguKeI={i} />
            </div>
          </>
        )
      }
    }
    return tableComponents;
  }

  if (action === "READ") {
    return (
      <>
        <Card style={{ height: "100%" }} className="mb-4">
          <Card.Body>
            <div className="d-flex gap-md-4 gap-sm-4 flex-column flex-sm-row">
              <InfoItemVertical label="Tahun" text={values.periodeGajianId} />
              <InfoItemVertical label="Bulan" text={bulanMapper(values.bulan)} />
              <InfoItemVertical label="Pekerja" text={values.pekerjaNama} />
              <InfoItemVertical label="Jabatan" text={values.jabatanNama} />
            </div>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <div>
              {dataTable(values?.maxWeek, action)}
            </div>
          </Card.Body>
        </Card>
      </>
    );
  }

  return (
    <>
      <Row>
        <Col md={3} className="mb-4">
          <Card style={{ height: "100%" }}>
            <Card.Header>
              <b>Setup Data</b>
            </Card.Header>
            <Card.Body>
              <SectionDataSetup onSearchButtonClick={onSearchButtonClick} onResetClickButton={onResetClickButton} action={action} />
            </Card.Body>
          </Card>
        </Col>
        <Col md={9}>
          <Card style={{ height: "100%" }}>
            <Card.Header>
              <b>Data Pekerja</b>
            </Card.Header>
            <Card.Body>
              {isLoading ? (
                <DataStatus loading />
              ) : (
                <TabsSection className="mb-4" action={action} activeTab={activeTab} setActiveTab={setActiveTab} setWeekNumber={onSearchButtonClick} />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
