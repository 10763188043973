import { Services } from "services";
import { fetchDropdownMapper, dateConvert } from "utilities";

const fetchDropdown = fetchDropdownMapper(Services);
class TukarJadwalApi {
  async getList(params) {
    const fetch = await Services.get("/transaksi/tukar-jadwal", { params });
    return fetch?.data?.data;
  }
  async getListJadwal(params) {
    const fetch = await Services.get("/transaksi/tukar-jadwal/list-jadwal", { params });
    return fetch?.data?.data;
  }
  async getHistory(params) {
    const fetch = await Services.get("/transaksi/tukar-jadwal/history", { params });
    return fetch?.data?.data;
  }
  async getInfoJadwal(params) {
    const fetch = await Services.get("/transaksi/tukar-jadwal/info-jadwal", { params });
    return fetch?.data?.data;
  }
  async getFormPenugasanKhusus(id) {
    const fetch = await Services.get("/transaksi/pelaksanaan-penugasan-khusus/" + id + "/form");
    return fetch?.data?.data;
  }
  async getPeriode() {
    const getPeriodeDropdown = await fetchDropdown("/master/periode-perencanaan/dropdown", {
      value: "master_periode_perencanaan_id",
      label: "periode_perencanaan"
    });
    return [...getPeriodeDropdown];
  }
  async getUnitOrganisasi() {
    const getUnitOrganisasi = await fetchDropdown("/master/unit-organisasi/dropdown", {
      value: "master_unit_organisasi_id",
      label: "master_unit_organisasi_nama"
    });
    return [...getUnitOrganisasi];
  }
  async getPekerja(params) {
    const fetch = await Services.get("/transaksi/tukar-jadwal/dropdown-pekerja", { params });
    return fetch?.data?.data.map((val) => ({
      label: val?.pekerja_nama,
      value: val?.pekerja_id
    }));
  }
  async getTanggal(params) {
    const fetch = await Services.get("/transaksi/tukar-jadwal/dropdown-tanggal", { params });
    return fetch?.data?.data.map((val) => ({
      label: val?.tanggal ? dateConvert().getSlashDMY(new Date(val?.tanggal)) : "-",
      value: val?.tanggal
    }));
  }
  async getDetail(id) {
    const fetch = await Services.get("/transaksi/tukar-jadwal/" + id);
    return fetch?.data?.data;
  }
  create(data) {
    return Services.post("/transaksi/tukar-jadwal", data);
  }
  async getExport() {
    const fetch = await Services.get("/transaksi/tukar-jadwal/export");
    return fetch?.data?.data;
  }
}

export default new TukarJadwalApi();
