export const formSubmitValueMapper = (values = {}) => ({
    customer_id: values?.id,
    customer_nama: values?.nama,
    contact_person: values?.contactPerson,
    hp: values?.hp,
    provinsi_kode: values?.provinsiKode,
    kabupaten_kode: values?.kabupatenKode,
    kecamatan_kode: values?.kecamatanKode,
    desa_kode: values?.desaKode,
    provinsi_nama: values?.provinsiNama,
    kabupaten_nama: values?.kabupatenNama,
    kecamatan_nama: values?.kecamatanNama,
    desa_nama: values?.desaNama,
    alamat: values?.alamat
});
