import { Services } from "services";

class JenisOverheadApi {
    async getList(params) {
        const fetch = await Services.get("/master/jenis-overhead", { params });
        return fetch?.data?.data;
    }

    async getDetail(id) {
        const fetch = await Services.get("/master/jenis-overhead/" + id);
        return fetch?.data?.data;
    }

    async getExport(params) {
        const fetch = await Services.get("/master/jenis-overhead/export?to=", { params });
        return fetch?.data?.data;
    }

    create(data) {
        return Services.post("/master/jenis-overhead", data);
    }

    update(data, id) {
        return Services.put("/master/jenis-overhead/" + id, data);
    }

    delete(id) {
        return Services.delete("/master/jenis-overhead/" + id);
    }
}

export default new JenisOverheadApi();
