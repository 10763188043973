import React from "react";
import { useFormikContext } from "formik";
import { InfoItemVertical, InputNumber, Input } from "components";

export const Content = ({ action }) => {
  const { values, errors, touched, setValues } = useFormikContext();

  if (action === "READ") {
    return (
      <>
        <InfoItemVertical label="Periode Penggajian" text={values.periodePenggajianNama} />
        <InfoItemVertical label="Umur" text={values.umur} />
      </>
    );
  }

  return (
    <>
      <Input
        name="periodePenggajianId"
        label="Periode Penggajian"
        placeholder="Masukan periode pengggajian"
        value={values?.periodePenggajianId ? String(values?.periodePenggajianId) : ""}
        onChange={(val) =>
          setValues({
            ...values,
            periodePenggajianId: val.target.value,
            periodePenggajianNama: val.target.value
          })
        }
        error={Boolean(errors.periodePenggajianId && touched.periodePenggajianId)}
        errorText={Boolean(errors.periodePenggajianId && touched.periodePenggajianId) && errors.periodePenggajianId}
      />
      <InputNumber
        name="umur"
        label="Umur"
        placeholder="Masukan umur"
        value={values?.umur ? values?.umur : ""}
        onChange={(val) => setValues({ ...values, umur: val })}
        error={Boolean(errors.umur && touched.umur)}
        errorText={Boolean(errors.umur && touched.umur) && errors.umur}
      />
    </>
  );
};
