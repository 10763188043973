import { ContentLayout } from "layouts";
import { useState } from "react";
import { Content } from "./__PresensiComps__";

export const PresensiList = () => {
  const [activeTab, setActiveTab] = useState("data-scan");

  return (
    <ContentLayout showBreadcrumb>
      <Content activeTab={activeTab} setActiveTab={setActiveTab} />
    </ContentLayout>
  );
};
