import {
    dateConvert, InfoItemVertical, Input, InputCurrency, InputFileSingle, numberConvert,
    Select
} from "components";
import { useFormikContext } from "formik";
import { Card, Col, ProgressBar, Row } from "react-bootstrap";
import { useQuery } from "react-query";
import SetupUpahTetapBpjsApi from "../__SetupUpahTetapBpjsApi__";

// const SectionDataSetup = ({ onSearchButtonClick, onResetClickButton }) => {
//     const { values, setValues, errors, touched } = useFormikContext();

//     const getUnitOrganisasi = useQuery(["unit-organisasi", "dropdown"], () => SetupUpahTetapBpjsApi.getUnitOrganisasi());

//     return (
//         <>

//             <Input
//                 disabled
//                 label="Periode Penggajian"
//                 value={values?.periodePerencanaan}
//             />
//             <Select
//                 label="Unit Organisasi"
//                 options={getUnitOrganisasi?.data ?? []}
//                 placeholder="Pilih salah satu..."
//                 defaultValue={
//                     values?.unitOrganisasiId
//                         ? getUnitOrganisasi?.data?.find((item) => item.value === values?.unitOrganisasiId)
//                         : null
//                 }
//                 onChange={(val) => {
//                     setValues({
//                         ...values,
//                         unitOrganisasiId: val.value,
//                         unitOrganisasiNama: val.label
//                     });
//                 }}
//                 loading={getUnitOrganisasi?.isFetching}
//                 errorFetch={getUnitOrganisasi.isError}
//                 error={Boolean(errors.unitOrganisasiId && touched.unitOrganisasiId)}
//                 errorText={Boolean(errors.unitOrganisasiId && touched.unitOrganisasiId) && errors.unitOrganisasiId}
//             />

//             <div className="d-flex justify-content-end">
//                 <div>
//                     {/* <ButtonCancel text="RESET" className="mb-3 me-2" tooltip={false} onClick={() => onResetClickButton()} /> */}
//                     <ButtonCreate
//                         disabled={Boolean(!values?.periodeGajianId)}
//                         text="CARI"
//                         className="mb-3"
//                         tooltip={false}
//                         onClick={() => onSearchButtonClick(values)}
//                     />
//                 </div>
//             </div>
//         </>
//     );
// };

// const TabelDataPekerja = () => {
//     const { values, setValues, errors, touched } = useFormikContext();
//     const number = numberConvert();

//     const setNewValues = ({ index, propname, val }) => {
//         const detailTemp = [...values?.detail];
//         const updatedDetail = detailTemp[index];
//         updatedDetail[propname] = val;

//         setValues({ ...values, detail: detailTemp });
//     };

//     const getError = (index, propname) => {
//         return Boolean(errors?.detail?.[index]?.[propname] && touched?.detail?.[index]?.[propname]);
//     };

//     const getErrorText = (index, propname) => {
//         return (
//             Boolean(errors?.detail?.[index]?.[propname] && touched?.detail?.[index]?.[propname]) &&
//             errors?.detail?.[index]?.[propname]
//         );
//     };

//     return (
//         <>
//             <TableList
//                 maxHeight={550}
//                 tableHeader={[
//                     { text: "NIK" },
//                     { text: "Pekerja" },
//                     { text: "Jabatan" },
//                     { text: "Unit Organisasi" },
//                     { text: "Status Pekerja" },
//                     { text: "Upah Tetap BPJS" },
//                 ]}
//                 tableBody={[
//                     {
//                         field: "nik"
//                     },
//                     {
//                         field: "pekerjaNama"
//                     },
//                     {
//                         field: "jabatanNama"
//                     },
//                     {
//                         field: "unitOrganisasiNama"
//                     },
//                     {
//                         field: "statusPekerja"
//                     },
//                     {
//                         customField: (val, index) => (
//                             <InputCurrency
//                                 name="nominal"
//                                 value={"Rp" + number.getWithComa(val?.nominal, { minimumFractionDigits: 2 })}
//                                 onChange={(x) => {
//                                     setNewValues({ index: index, propname: "checked", val: true })
//                                     setNewValues({ index: index, propname: "nominal", val: x })
//                                 }}
//                                 error={getError(index, "nominal")}
//                                 errorText={getErrorText(index, "nominal")}
//                             />
//                         )
//                     }
//                 ]}
//                 data={values?.detail}
//             />
//         </>
//     );
// };

const SetupUpahTetapBPJSImport = ({ uploadData }) => {
    const { values, setValues, errors, touched } = useFormikContext();
    const date = dateConvert();
    const currYear = date.getYear(new Date());

    const getPeriode = [
        {
            label: (currYear - 1),
            value: (currYear - 1)
        },
        {
            label: currYear,
            value: currYear
        },
        {
            label: currYear + 1,
            value: currYear + 1
        },
    ];

    return (
        <>
            <Select
                label="Periode Penggajian"
                placeholder="Pilih periode penggajian"
                defaultValue={getPeriode?.find((item) => item.value === currYear)}
                options={getPeriode ?? []}
                onChange={(val) => setValues({ ...values, periodeGajianId: val.value, periodePerencanaan: val.label })}
            />
            <InputFileSingle
                label="Upload File Setup Upah Tetap BPJS"
                onClick={(e) => (uploadData.isLoading ? e.preventDefault() : {})}
                disabled={uploadData.isLoading}
                onChange={(val) => {
                    let formData = new FormData();
                    formData.append("periode_tahun", values.periodeGajianId);
                    formData.append("file_upah_pokok", val.data);

                    setValues({
                        ...values,
                        dataImport: formData
                    });
                }}
                error={Boolean(errors.dataImport && touched.dataImport)}
                errorText={Boolean(errors.dataImport && touched.dataImport) && errors.dataImport}
                acceptFileType={["xlsx"]}
            />
            {uploadData.isLoading && (
                <ProgressBar now={uploadData.progress} label={`Mengunggah file ${uploadData.progress}%`} />
            )}
        </>
    );
}

export const Content = ({ action, onSearchButtonClick, onResetClickButton, uploadData }) => {
    const { values, setValues, errors, touched } = useFormikContext();
    const number = numberConvert();

    const getStatus = useQuery(["staus-upah", "dropdown"], () => SetupUpahTetapBpjsApi.getStatus());

    if (action === "READ") {
        return (
            <>
                <InfoItemVertical label="NIK" text={values.nik} />
                <InfoItemVertical label="Pekerja" text={values.pekerjaNama} />
                <InfoItemVertical label="Jabatan" text={values.jabatanNama} />
                <InfoItemVertical label="Unit Organisasi" text={values.unitOrganisasiNama} />
                <InfoItemVertical label="Status Pekerja" text={values?.statusPekerja} />
                <InfoItemVertical
                    label="Upah Tetap BPJS Kesehatan"
                    text={values.kesehatanNominal ? "Rp" + number.getWithComa(values?.kesehatanNominal, { minimumFractionDigits: 2 }) : "Rp0,00"}
                />
                <InfoItemVertical
                    label="Upah Tetap BPJS Tenaga Kerja"
                    text={values.tenagaKerjaNominal ? "Rp" + number.getWithComa(values?.tenagaKerjaNominal, { minimumFractionDigits: 2 }) : "Rp0,00"}
                />
                <InfoItemVertical
                    label="Upah Tetap BPJS BPU"
                    text={values.bpuNominal ? "Rp" + number.getWithComa(values?.bpuNominal, { minimumFractionDigits: 2 }) : "Rp0,00"}
                />
                <InfoItemVertical label="Status Upah" text={values?.status === 1 ? "Aktif" : "Tidak Aktif"} />
            </>
        );
    }

    if (action === "UPDATE") {
        return (
            <>
                <Input disabled label="NIK" value={values?.nik} />
                <Input disabled label="Pekerja" value={values?.pekerjaNama} />
                <Input disabled label="Unit Organisasi" value={values?.unitOrganisasiNama} />
                <Input disabled label="Jabatan" value={values?.jabatanNama} />
                <Input disabled label="Status Pekerja" value={values?.statusPekerja} />
                <InputCurrency
                    name="kesehatanNominal"
                    label="Upah Tetap BPJS Kesehatan"
                    value={number.getWithComa(values?.kesehatanNominal, { minimumFractionDigits: 2 })}
                    onChange={(val) =>
                        setValues({
                            ...values,
                            kesehatanNominal: val
                        })
                    }
                    error={Boolean(errors.kesehatanNominal && touched.kesehatanNominal)}
                    errorText={Boolean(errors.kesehatanNominal && touched.kesehatanNominal) && errors.kesehatanNominal}
                />
                <InputCurrency
                    // disabled={values?.bpuNominal}
                    name="tenagaKerjaNominal"
                    label="Upah Tetap BPJS Ketenagakerjaan"
                    value={number.getWithComa(values?.tenagaKerjaNominal, { minimumFractionDigits: 2 })}
                    onChange={(val) =>
                        setValues({
                            ...values,
                            tenagaKerjaNominal: val,
                            // bpuNominal: 0
                        })
                    }
                    error={Boolean(errors.validateNominal && touched.validateNominal)}
                    errorText={Boolean(errors.validateNominal && touched.validateNominal) && errors.validateNominal}
                />
                <InputCurrency
                    // disabled={values?.tenagaKerjaNominal}
                    name="bpuNominal"
                    label="Upah Tetap BPJS Bukan Penerima Upah"
                    value={number.getWithComa(values?.bpuNominal, { minimumFractionDigits: 2 })}
                    onChange={(val) =>
                        setValues({
                            ...values,
                            bpuNominal: val,
                        })
                    }
                    error={Boolean(errors.validateNominal && touched.validateNominal)}
                    errorText={Boolean(errors.validateNominal && touched.validateNominal) && errors.validateNominal}
                />
                <Select
                    disable
                    label="Status Upah"
                    options={getStatus?.data ?? []}
                    placeholder="Pilih salah satu..."
                    defaultValue={
                        values?.status
                            ? getStatus?.data?.find((item) => item.value === values?.status)
                            : null
                    }
                    onChange={(val) => {
                        setValues({
                            ...values,
                            status: val.value
                        });
                    }}
                    loading={getStatus?.isFetching}
                    errorFetch={getStatus.isError}
                    error={Boolean(errors.status && touched.status)}
                    errorText={Boolean(errors.status && touched.status) && errors.status}
                />
            </>
        );
    }

    return (
        <>
            {/* <Row>
                <Col md={4} className="mb-4">
                    <Card>
                        <Card.Header>
                            <b>Setup Data</b>
                        </Card.Header>
                        <Card.Body>
                            <SectionDataSetup onSearchButtonClick={onSearchButtonClick} onResetClickButton={onResetClickButton} />
                        </Card.Body>
                    </Card>
                </Col>

                <Col md={8}>
                    <Card>
                        <Card.Header>
                            <b>Data Pekerja</b>
                        </Card.Header>
                        <Card.Body>
                            <div>
                                <b className="text-danger">Silahkan Input nominal pada data yang ingin disimpan</b>
                            </div>
                            <TabelDataPekerja />
                        </Card.Body>
                    </Card>
                </Col>
            </Row> */}
            <Row>
                <Col md={12} className="mb-4">
                    <Card>
                        <Card.Header>
                            <b>Setup Data</b>
                        </Card.Header>
                        <Card.Body>
                            <SetupUpahTetapBPJSImport uploadData={uploadData} />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};
