import { Services } from "services";
class BankApi {
  async getList(params) {
    const fetch = await Services.get("/master/bank", { params });
    return fetch?.data?.data;
  }

  async getExport(params) {
    const fetch = await Services.get("/master/bank/export", { params });
    return fetch?.data?.data;
  }

  create(data) {
    return Services.post("/master/bank", data);
  }

  update(data, id) {
    return Services.put("/master/bank/" + id, data);
  }

  delete(id) {
    return Services.delete("/master/bank/" + id);
  }
}

export default new BankApi();
