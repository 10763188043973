export const formInitialValues = {
    detailData: (data, filter) => ({
        id: data?.pekerja_pajak_bulanan_id,
        periodeGajianId: data?.periode_tahun ?? filter?.periodeGajianId ?? new Date().getFullYear(),
        periodePerencanaan: data?.periode_tahun ?? new Date().getFullYear(),
        bulan: filter?.bulan,
        tglAwal: data?.tgl_bulan_awal ?? filter?.tglAwal,
        tglAkhir: data?.tgl_bulan_akhir ?? filter?.tglAkhir,
        tglSetup: data?.tgl_setup ?? filter?.tglSetup ?? new Date(),

        pekerjaId: data?.pekerja_id ?? filter?.pekerjaId,
        pekerjaNama: data?.pekerja_nama ?? filter?.pekerjaNama,
        nik: data?.nip ?? filter?.nik,
        unitOrganisasiId: data?.master_unit_organisasi_id ?? filter?.unitOrganisasiId ?? "",
        unitOrganisasiNama: data?.master_unit_organisasi_nama ?? filter?.unitOrganisasiNama ?? "",
        jabatanId: data?.master_jabatan_id ?? filter?.jabatanNama ?? "",
        jabatanNama: data?.master_jabatan_nama ?? filter?.jabatanNama ?? "",
        statusPekerja: data?.status_pekerja,
        tglPengangkatan: data?.tanggal_pengangkatan,
        tglBergabung: data?.tanggal_bergabung,
        noNpwp: data?.no_npwp,
        tarifPtkpId: data?.tarif_ptkp_id,
        pekerjaStatusPtkpId: data?.pekerja_status_ptkp_id,
        nominalPajak: data?.nominal_pajak,
        statusPajak: data?.status_pajak,

        detail:
            data?.komponen?.map((val) => ({
                kode: val?.kode,
                nominal: val?.nominal,
                nominalTahunan: val?.nominal_tahunan,
                status: val?.status,
                komponenNama: val?.nama_komponen
            })) ?? [],

        penghasilanBrutoBulanan: data?.penghasilan_bruto_bulanan,
        penghasilanBrutoTahunan: data?.penghasilan_bruto_tahunan,
        penguranganBrutoBulanan: data?.pengurangan_bruto_bulanan,
        penguranganBrutoTahunan: data?.pengurangan_bruto_tahunan,

        penghasilanNettoBulanan: data?.penghasilan_netto_bulanan,
        penghasilanNettoTahunan: data?.penghasilan_netto_tahunan,
        penghasilanKenaPajakBulanan: data?.penghasilan_kena_pajak_bulanan,
        penghasilanKenaPajakTahunan: data?.penghasilan_kena_pajak_tahunan,
        penghasilanTidakKenaPajak: data?.penghasilan_tidak_kena_pajak,

        pph21Bulanan: data?.pph21_bulanan,
        pph21Tahunan: data?.pph21_tahunan,
        biayaJabatan: data?.biaya_jabatan,
        pph21SudahDibayar: data?.pph21_sudahdibayar,
        pph21Selisih: data?.selisih_pph21
    }),

    modalData: (data) => ({
        id: data?.pekerja_pajak_bulanan_id,
        komponenPayroll: data?.komponen_payroll ?? "",
        periodeGajianId: data?.periode_tahun,
        periodePerencanaan: data?.periode_tahun,
        pekerjaId: data?.pekerja_id,
        pekerjaNama: data?.pekerja_nama,
        nik: data?.nip,
        tglAwal: data?.tgl_bulan_awal,
        tglAkhir: data?.tgl_bulan_akhir,
        tglSetup: data?.tgl_setup,
        unitOrganisasiId: data?.master_unit_organisasi_id,
        unitOrganisasiNama: data?.master_unit_organisasi_nama,
        jabatanNama: data?.master_jabatan_nama,
        totalUpahRitase: data?.total_upah_ritase_cp
    }),

    formData: (data, filter) => ({
        periodeGajianId: filter?.periodeGajianId,
        periodePerencanaan: new Date().getFullYear(),
        bulan: filter?.bulan,
        tglAwal: filter?.tglAwal,
        tglAkhir: filter?.tglAkhir,
        tglSetup: filter?.tglSetup ?? new Date(),
        statusPekerjaId: filter?.statusPekerjaId,
        unitOrganisasiId: filter?.unitOrganisasiId,
        unitOrganisasiNama: filter?.unitOrganisasiNama,

        detail:
            data?.map((val) => ({
                nik: val?.nip,
                pekerjaId: val?.pekerja_id,
                pekerjaNama: val?.pekerja_nama,
                unitOrganisasiId: val?.master_unit_organisasi_id,
                unitOrganisasiNama: val?.master_unit_organisasi_nama,
                jabatanNama: val?.master_jabatan_nama,
                periodeGajianId: val?.periode_tahun,
                statusPajak: val?.status_pajak,
                noNpwp: val?.no_npwp,
                tglAwal: val?.tgl_bulan_awal,
                tglAkhir: val?.tgl_bulan_akhir,
                tglSetup: val?.tgl_setup,
                hasPosted: val?.has_posted,
                hasStatusPajak: val?.has_status_pajak
            })) ?? [],

    }),

};
