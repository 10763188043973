import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { Row, Col, Card } from "react-bootstrap";
import { useFormikContext } from "formik";
import { InputNumber, Select, InfoItemVertical } from "components";
import { errorFetchingSelectMapper, numberConvert } from "utilities";
import { useModalConfirm } from "hooks";
import EvaluasiJabatanApi from "../__EvaluasiJabatanApi__";

const generateColor = (code) => {
  switch (String(code)) {
    case "3":
      return "bg-info";

    case "2":
      return "bg-warning";

    case "1":
      return "bg-white";

    default:
      return "";
  }
};

const DetailEvaluasiJabatan = () => {
  const { values } = useFormikContext();

  const totalSkor =
    parseFloat(values?.skor_1 ?? 0) +
    parseFloat(Math.round(values?.skor_1 * (values?.skor_2 / 100))) +
    parseFloat(values?.skor_3 ?? 0);

  return (
    <Row>
      <Col>
        {/* Title */}
        <div className="mb-2">
          <b>KNOW HOW (INPUT)</b>
        </div>

        {/* Kompetensi Teknis */}
        <InfoItemVertical
          label="Kompetensi Teknis (F1)"
          text={`${values?.f1_kode ? `${values.f1_kode} - ` : ""} ${values.f1_nama}`}
        />

        {/* Kompetensi Manajerial */}
        <InfoItemVertical
          label="Kompetensi Manajerial (F2)"
          text={`${values?.f2_kode ? `${values.f2_kode} - ` : ""} ${values.f2_nama}`}
        />

        {/* Kompetensi Komunikasi */}
        <InfoItemVertical label="Kompetensi Komunikasi (F3)" text={values?.f3_nama} />

        {/* Adjustment 1 */}
        <InfoItemVertical label="Adjustment 1" text={values?.a1_nama} />

        {/* Skor 1 */}
        <InfoItemVertical
          label="Skor 1"
          text={
            <div className={`p-1 px-2 rounded border ${generateColor(values.skor_1_color)}`}>
              {values.skor_1 ? parseFloat(values.skor_1) : ""}
            </div>
          }
        />
      </Col>

      <Col>
        {/* Title */}
        <div className="mb-2">
          <b>PROBLEM SOLVING (PROSES)</b>
        </div>

        {/* Kebebasan Berfikir */}
        <InfoItemVertical
          label="Kebebasan Berfikir (F4)"
          text={`${values?.f4_kode ? `${values.f4_kode} - ` : ""} ${values.f4_nama}`}
        />

        {/* Tantangan Berfikir */}
        <InfoItemVertical
          label="Tantangan Berfikir (F5)"
          text={`${values?.f5_kode ? `${values.f5_kode} - ` : ""} ${values.f5_nama}`}
        />

        {/* Adjustment 2 */}
        <InfoItemVertical label="Adjustment 2" text={values?.a2_nama} />

        {/* Presentase Skor 2 */}
        <InfoItemVertical
          label="Presentase Skor 2"
          text={
            <div className={`p-1 px-2 rounded border ${generateColor(values.skor_2_color)}`}>
              {values.skor_2 ? `${parseFloat(values.skor_2)}%` : "-"}
            </div>
          }
        />

        {/* Skor 2 */}
        <InfoItemVertical
          label="Skor 2"
          text={
            <div className={`p-1 px-2 rounded border ${generateColor(values.skor_2_color)}`}>
              {Math.round(values?.skor_1 * (values?.skor_2 / 100)).toFixed(0)}
            </div>
          }
        />
      </Col>

      <Col>
        {/* Title */}
        <div className="mb-2">
          <b>AKUNTABILITAS (OUTPUT)</b>
        </div>

        {/* Wewenang */}
        <InfoItemVertical
          label="Wewenang (F6)"
          text={`${values?.f6_kode ? `${values.f6_kode} - ` : ""} ${values.f6_nama}`}
        />

        {/* Nilai Kelola Harta */}
        <InfoItemVertical
          label="Nilai Kelola Harta (F7)"
          text={`${values?.f7_kode ? `${values.f7_kode} - ` : ""} ${values.f7_nama}`}
        />

        {/* Dampak */}
        <InfoItemVertical label="Dampak (F8)" text={values?.f8_nama} />

        {/* Adjustment 3 */}
        <InfoItemVertical label="Adjustment 3" text={values?.a3_nama} />

        {/* Skor 3 */}
        <InfoItemVertical
          label="Presentase Skor 3"
          text={
            <div className={`p-1 px-2 rounded border ${generateColor(values.skor_3_color)}`}>
              {values.skor_3 ? parseFloat(values.skor_3) : "-"}
            </div>
          }
        />
      </Col>

      <Col lg={2}>
        {/* Title */}
        <div className="mb-2">
          <b>HASIL EVALUASI</b>
        </div>

        {/* Total Skor */}
        <InfoItemVertical label="Total Skor" text={totalSkor} />

        {/* Grade */}
        <InfoItemVertical label="Grade" text={values?.grade_nama} />

        {/* Pangkat */}
        <InfoItemVertical label="Pangkat" text={values?.pangkat_nama} />
      </Col>
    </Row>
  );
};

const FormEvaluasiJabatan = () => {
  const modalConfirm = useModalConfirm();
  const number = numberConvert();
  const cacheTime = 1000 * 60 * 60 * 24;
  const { values, touched, errors, setValues } = useFormikContext();

  const getF1 = useQuery(["evaluasi-jabatan", "f1", "dropdown"], () => EvaluasiJabatanApi.getF1(), {
    cacheTime: cacheTime,
    staleTime: cacheTime
  });
  const getF2 = useQuery(["evaluasi-jabatan", "f2", "dropdown"], () => EvaluasiJabatanApi.getF2(), {
    cacheTime: cacheTime,
    staleTime: cacheTime
  });
  const getF3 = useQuery(["evaluasi-jabatan", "f3", "dropdown"], () => EvaluasiJabatanApi.getF3(), {
    cacheTime: cacheTime,
    staleTime: cacheTime
  });
  const getF4 = useQuery(["evaluasi-jabatan", "f4", "dropdown"], () => EvaluasiJabatanApi.getF4(), {
    cacheTime: cacheTime,
    staleTime: cacheTime
  });
  const getF5 = useQuery(["evaluasi-jabatan", "f5", "dropdown"], () => EvaluasiJabatanApi.getF5(), {
    cacheTime: cacheTime,
    staleTime: cacheTime
  });
  const getF6 = useQuery(["evaluasi-jabatan", "f6", "dropdown"], () => EvaluasiJabatanApi.getF6(), {
    cacheTime: cacheTime,
    staleTime: cacheTime
  });
  const getF7 = useQuery(["evaluasi-jabatan", "f7", "dropdown"], () => EvaluasiJabatanApi.getF7(), {
    cacheTime: cacheTime,
    staleTime: cacheTime
  });
  const getF8 = useQuery(["evaluasi-jabatan", "f8", "dropdown"], () => EvaluasiJabatanApi.getF8(), {
    cacheTime: cacheTime,
    staleTime: cacheTime
  });
  const getA1 = useQuery(["evaluasi-jabatan", "a1", "dropdown"], () => EvaluasiJabatanApi.getA1(), {
    cacheTime: cacheTime,
    staleTime: cacheTime
  });
  const getA2 = useQuery(["evaluasi-jabatan", "a2", "dropdown"], () => EvaluasiJabatanApi.getA2(), {
    cacheTime: cacheTime,
    staleTime: cacheTime
  });
  const getA3 = useQuery(["evaluasi-jabatan", "a3", "dropdown"], () => EvaluasiJabatanApi.getA3(), {
    cacheTime: cacheTime,
    staleTime: cacheTime
  });
  const getSkor1 = useQuery(
    ["evaluasi-jabatan", "skor1", values?.f1_id, values?.f2_id, values?.f3_id, values?.a1_id],
    () =>
      EvaluasiJabatanApi.getSkor1({
        master_f1_kode: values?.f1_kode,
        master_f2_kode: values?.f2_kode,
        master_f3_kode: values?.f3_kode,
        master_judgement1_kode: values?.a1_id
      }),
    {
      enabled: !!(!!values.f1_id && !!values.f2_id && !!values.f3_id && !!values.a1_id),
      onSuccess: (data) => {
        if (!!!data?.data?.data?.score) {
          modalConfirm.trigger({
            type: "error",
            data: ["Tidak ada skor 1 yang cocok dengan evaluasi"],
            onHide: () => modalConfirm.close()
          });
        }
      }
    }
  );
  const getSkor2 = useQuery(
    ["evaluasi-jabatan", "skor2", values?.f4_id, values?.f5_id, values?.a2_id],
    () =>
      EvaluasiJabatanApi.getSkor2({
        master_f4_kode: values?.f4_kode,
        master_f5_kode: values?.f5_kode,
        master_judgement2_kode: values?.a2_id
      }),
    {
      enabled: !!(!!values.f4_id, !!values.f5_id, !!values.a2_id),
      onSuccess: (data) => {
        if (!!!data?.data?.data?.persentase) {
          modalConfirm.trigger({
            type: "error",
            data: ["Tidak ada skor 2 yang cocok dengan evaluasi"],
            onHide: () => modalConfirm.close()
          });
        }
      }
    }
  );
  const getSkor3 = useQuery(
    ["evaluasi-jabatan", "skor3", values?.f6_id, values?.f7_id, values?.f8_id, values?.a3_id],
    () =>
      EvaluasiJabatanApi.getSkor3({
        master_f6_kode: values?.f6_kode,
        master_f7_kode: values?.f7_kode,
        master_f8_kode: values?.f8_kode,
        master_judgement3_kode: values?.a3_kode
      }),
    {
      enabled: !!(!!values.f6_id && !!values.f7_id && !!values.f8_id && !!values.a3_id),
      onSuccess: (data) => {
        if (!!!data?.data?.data?.score) {
          modalConfirm.trigger({
            type: "error",
            data: ["Tidak ada skor 3 yang cocok dengan evaluasi"],
            onHide: () => modalConfirm.close()
          });
        }
      }
    }
  );
  const skor1Value = getSkor1?.data?.data?.data?.score;
  const skor2Value = getSkor2?.data?.data?.data?.persentase;
  const skor3Value = getSkor3?.data?.data?.data?.score;
  const skor1Color = getSkor1?.data?.data?.data?.master_warna_kode;
  const skor2Color = getSkor2?.data?.data?.data?.master_warna_kode;
  const skor3Color = getSkor3?.data?.data?.data?.master_warna_kode;
  const skor2Total = Math.round(skor1Value * (skor2Value / 100));
  const totalSkor = parseFloat(skor1Value ?? 0) + parseFloat(skor2Total ?? 0) + parseFloat(skor3Value ?? 0);
  const checkGradeDepedencyFullfiled = !!(!!skor1Value && !!skor2Value && !!skor3Value);
  const checkPangkatDepedencyFullfiled = !!(
    !!values?.grade_id &&
    !!values?.jenis_jabatan_id &&
    checkGradeDepedencyFullfiled
  );

  const getGrade = useQuery(
    ["evaluasi-jabatan", "grade", skor1Value, skor2Value, skor3Value],
    () => EvaluasiJabatanApi.getGrade(totalSkor),
    {
      cacheTime: cacheTime,
      staleTime: cacheTime,
      enabled: checkGradeDepedencyFullfiled,
      onSuccess: (data) => {
        if (!!data?.data?.data?.grade) {
          setValues({
            ...values,
            grade_nama: data.data?.data?.grade,
            grade_id: data.data?.data?.grade,
            total_skor: totalSkor
          });
        } else {
          setValues({ ...values, grade_nama: "", grade_id: "", total_skor: "", pangkat_nama: "" });
          modalConfirm.trigger({
            type: "error",
            data: ["Tidak ada grade yang cocok dengan evaluasi"],
            onHide: () => modalConfirm.close()
          });
        }
      }
    }
  );

  const getPangkat = useQuery(
    ["evaluasi-jabatan", "pangkat", values?.grade_id, values?.jenis_jabatan_id],
    () => EvaluasiJabatanApi.getPangkat(values?.grade_id, values?.jenis_jabatan_id),
    {
      cacheTime: cacheTime,
      staleTime: cacheTime,
      enabled: false,
      onSuccess: (data) => {
        if (!!data?.data?.data?.nama_pangkat) {
          setValues({
            ...values,
            pangkat_nama: data.data?.data?.nama_pangkat,
            pangkat_id: data.data?.data?.master_pangkat_id
          });
        } else {
          setValues({ ...values, pangkat_nama: "" });
          modalConfirm.trigger({
            type: "error",
            data: ["Tidak ada pangkat yang cocok dengan evaluasi"],
            onHide: () => modalConfirm.close()
          });
        }
      }
    }
  );

  useEffect(() => {
    setValues({ ...values, skor_1: skor1Value, skor_2: skor2Value, skor_3: skor3Value, pangkat_nama: "" });

    if (checkGradeDepedencyFullfiled) {
      getGrade.refetch();
    }
  }, [skor1Value, skor2Value, skor3Value]);

  useEffect(() => {
    if (checkPangkatDepedencyFullfiled && !getGrade.isFetching) {
      getPangkat.refetch();
    }
  }, [values?.grade_id, values?.jenis_jabatan_id, getGrade.isFetching]);

  return (
    <Row>
      <Col>
        {/* Title */}
        <div className="mb-2">
          <b>KNOW HOW (INPUT)</b>
        </div>

        {/* Kompetensi Teknis */}
        <Select
          label="Kompetensi Teknis (F1)*"
          placeholder="Pilih kompetensi teknis"
          options={getF1.data}
          loading={getF1.isLoading}
          errorFetch={getF1.isError}
          errorFetchText={errorFetchingSelectMapper(getF1.error)}
          defaultValue={values.f1_id ? getF1?.data?.find((val) => val.value === values.f1_id) : []}
          onChange={(val) => {
            setValues({ ...values, f1_id: val.value, f1_nama: val.label, f1_kode: val.kode });
            getSkor1.refetch();
          }}
          error={Boolean(errors.f1_id && touched.f1_id)}
          errorText={Boolean(errors.f1_id && touched.f1_id) && errors.f1_id}
        />

        {/* Kompetensi Manajerial */}
        <Select
          disable={!values?.f1_id}
          label="Kompetensi Manajerial (F2)*"
          placeholder="Pilih kompetensi manajerial"
          options={getF2.data}
          loading={getF2.isLoading}
          errorFetch={getF2.isError}
          errorFetchText={errorFetchingSelectMapper(getF2.error)}
          defaultValue={values.f2_id ? getF2?.data?.find((val) => val.value === values.f2_id) : []}
          onChange={(val) => {
            setValues({ ...values, f2_id: val.value, f2_nama: val.label, f2_kode: val.kode });
            getSkor1.refetch();
          }}
          error={Boolean(errors.f2_id && touched.f2_id)}
          errorText={Boolean(errors.f2_id && touched.f2_id) && errors.f2_id}
        />

        {/* Kompetensi Komunikasi */}
        <Select
          disable={!values?.f2_id}
          label="Kompetensi Komunikasi (F3)*"
          placeholder="Pilih kompetensi komunikasi"
          options={getF3.data}
          loading={getF3.isLoading}
          errorFetch={getF3.isError}
          errorFetchText={errorFetchingSelectMapper(getF3.error)}
          defaultValue={values.f3_id ? getF3?.data?.find((val) => val.value === values.f3_id) : []}
          onChange={(val) => {
            setValues({ ...values, f3_id: val.value, f3_nama: val.label, f3_kode: val.kode });
            getSkor1.refetch();
          }}
          error={Boolean(errors.f3_id && touched.f3_id)}
          errorText={Boolean(errors.f3_id && touched.f3_id) && errors.f3_id}
        />

        {/* Adjustment 1 */}
        <Select
          disable={!values?.f3_id}
          label="Adjustment 1*"
          placeholder="Pilih adjustment 1"
          options={getA1.data}
          loading={getA1.isLoading}
          errorFetch={getA1.isError}
          errorFetchText={errorFetchingSelectMapper(getA1.error)}
          defaultValue={values.a1_id ? getA1?.data?.find((val) => val.value === values.a1_id) : []}
          onChange={(val) => {
            setValues({ ...values, a1_id: val.value, a1_nama: val.label, a1_kode: val.kode });
            getSkor1.refetch();
          }}
          error={Boolean(errors.a1_id && touched.a1_id)}
          errorText={Boolean(errors.a1_id && touched.a1_id) && errors.a1_id}
        />

        {/* Skor 1 */}
        <InputNumber
          disabled
          decimalScale={0}
          label="Skor 1"
          placeholder="0"
          name="skor_1"
          className={generateColor(skor1Color)}
          loading={getSkor1.isFetching}
          errorFetch={getSkor1.isError}
          errorFetchText={errorFetchingSelectMapper(getSkor1.error)}
          value={skor1Value ? number.getWithComa(skor1Value) : ""}
        />
      </Col>

      <Col>
        {/* Title */}
        <div className="mb-2">
          <b>PROBLEM SOLVING (PROSES)</b>
        </div>

        {/* Kebebasan Berfikir */}
        <Select
          label="Kebebasan Berfikir (F4)*"
          placeholder="Pilih kebebadsan berfikir"
          options={getF4.data}
          loading={getF4.isLoading}
          errorFetch={getF4.isError}
          errorFetchText={errorFetchingSelectMapper(getF4.error)}
          defaultValue={values.f4_id ? getF4?.data?.find((val) => val.value === values.f4_id) : []}
          onChange={(val) => {
            setValues({ ...values, f4_id: val.value, f4_nama: val.label, f4_kode: val.kode });
            getSkor2.refetch();
          }}
          error={Boolean(errors.f4_id && touched.f4_id)}
          errorText={Boolean(errors.f4_id && touched.f4_id) && errors.f4_id}
        />

        {/* Tantangan Berfikir */}
        <Select
          disable={!values?.f4_id}
          label="Tantangan Berfikir (F5)*"
          placeholder="Pilih tantangan berfikir"
          options={getF5.data}
          loading={getF5.isLoading}
          errorFetch={getF5.isError}
          errorFetchText={errorFetchingSelectMapper(getF5.error)}
          defaultValue={values.f5_id ? getF5?.data?.find((val) => val.value === values.f5_id) : []}
          onChange={(val) => {
            setValues({ ...values, f5_id: val.value, f5_nama: val.label, f5_kode: val.kode });
            getSkor2.refetch();
          }}
          error={Boolean(errors.f5_id && touched.f5_id)}
          errorText={Boolean(errors.f5_id && touched.f5_id) && errors.f5_id}
        />

        {/* Adjustment 2 */}
        <Select
          disable={!values?.f5_id}
          label="Adjustment 2*"
          placeholder="Pilih adjustment 2"
          options={getA2.data}
          loading={getA2.isLoading}
          errorFetch={getA2.isError}
          errorFetchText={errorFetchingSelectMapper(getA2.error)}
          defaultValue={values.a2_id ? getA2?.data?.find((val) => val.value === values.a2_id) : []}
          onChange={(val) => {
            setValues({ ...values, a2_id: val.value, a2_nama: val.label, a2_kode: val.kode });
            getSkor2.refetch();
          }}
          error={Boolean(errors.a2_id && touched.a2_id)}
          errorText={Boolean(errors.a2_id && touched.a2_id) && errors.a2_id}
        />

        {/* Presentase Skor 2 */}
        <InputNumber
          disabled
          label="Persentase Skor 2"
          placeholder="0%"
          name="persentase_skor_2"
          suffix="%"
          decimalScale={0}
          loading={getSkor2.isFetching}
          errorFetch={getSkor2.isError}
          className={generateColor(skor2Color)}
          value={skor2Value ? number.getWithComa(skor2Value) : ""}
        />

        {/* Skor 2 */}
        <InputNumber
          disabled
          decimalScale={0}
          label="Skor 2"
          placeholder="0"
          name="skor_2"
          loading={getSkor2.isFetching}
          errorFetch={getSkor2.isError}
          errorFetchText={errorFetchingSelectMapper(getSkor2.error)}
          className={generateColor(skor2Color)}
          value={skor2Total}
        />
      </Col>

      <Col>
        {/* Title */}
        <div className="mb-2">
          <b>AKUNTABILITAS (OUTPUT)</b>
        </div>

        {/* Wewenang */}
        <Select
          label="Wewenang (F6)*"
          placeholder="Pilih wewenang"
          options={getF6.data}
          loading={getF6.isLoading}
          errorFetch={getF6.isError}
          defaultValue={values.f6_id ? getF6?.data?.find((val) => val.value === values.f6_id) : []}
          errorFetchText={errorFetchingSelectMapper(getF6.error)}
          onChange={(val) => {
            setValues({ ...values, f6_id: val.value, f6_nama: val.label, f6_kode: val.kode });
            getSkor3.refetch();
          }}
          error={Boolean(errors.f6_id && touched.f6_id)}
          errorText={Boolean(errors.f6_id && touched.f6_id) && errors.f6_id}
        />

        {/* Nilai Kelola Harta */}
        <Select
          disable={!values?.f6_id}
          label="Nilai Kelola Harta (F7)*"
          placeholder="Pilih nilai kelola harta"
          options={getF7.data}
          loading={getF7.isLoading}
          errorFetch={getF7.isError}
          errorFetchText={errorFetchingSelectMapper(getF7.error)}
          defaultValue={values.f7_id ? getF7?.data?.find((val) => val.value === values.f7_id) : []}
          onChange={(val) => {
            setValues({
              ...values,
              f7_id: val.value,
              f7_nama: val.label,
              f7_kode: val.kode,
              f8_id: "",
              f8_nama: "",
              f8_kode: ""
            });
            getSkor3.refetch();
          }}
          error={Boolean(errors.f7_id && touched.f7_id)}
          errorText={Boolean(errors.f7_id && touched.f7_id) && errors.f7_id}
        />

        {/* Dampak */}
        {/* <Select
              id={values.f7_id}
              disable={!values.f7_id}
              label="Dampak (F8)*"
              placeholder="Pilih dampak"
              options={getF8.data?.filter((fil) =>
                values?.f7_nama?.toUpperCase()?.[0] === "N" ? fil?.is_n === true : fil?.is_n === false
              )}
              loading={getF8.isLoading}
              errorFetch={getF8.isError}
              defaultValue={values.f8_id ? getF8?.data?.find((val) => val.value === values.f8_id) : []}
              onChange={(val) => {
                setValues({ ...values, f8_id: val.value, f8_nama: val.label, f8_kode: val.kode });
                getSkor3.refetch();
              }}
              error={Boolean(errors.f8_id && touched.f8_id)}
              errorText={Boolean(errors.f8_id && touched.f8_id) && errors.f8_id}
            /> */}
        <Select
          id={values.f7_id}
          disable={!values.f7_id}
          label="Dampak (F8)*"
          placeholder="Pilih dampak"
          options={getF8.data}
          loading={getF8.isLoading}
          errorFetch={getF8.isError}
          errorFetchText={errorFetchingSelectMapper(getF8.error)}
          defaultValue={values.f8_id ? getF8?.data?.find((val) => val.value === values.f8_id) : []}
          onChange={(val) => {
            setValues({ ...values, f8_id: val.value, f8_nama: val.label, f8_kode: val.kode });
            getSkor3.refetch();
          }}
          error={Boolean(errors.f8_id && touched.f8_id)}
          errorText={Boolean(errors.f8_id && touched.f8_id) && errors.f8_id}
        />

        {/* Adjustment 3 */}
        <Select
          disable={!values.f8_id}
          label="Adjustment 3*"
          placeholder="Pilih adjustment 3"
          options={getA3.data}
          loading={getA3.isLoading}
          errorFetch={getA3.isError}
          errorFetchText={errorFetchingSelectMapper(getA3.error)}
          defaultValue={values.a3_id ? getA3?.data?.find((val) => val.value === values.a3_id) : []}
          onChange={(val) => {
            setValues({ ...values, a3_id: val.value, a3_nama: val.label, a3_kode: val.kode });
            getSkor3.refetch();
          }}
          error={Boolean(errors.a3_id && touched.a3_id)}
          errorText={Boolean(errors.a3_id && touched.a3_id) && errors.a3_id}
        />

        {/* Skor 3 */}
        <InputNumber
          disabled
          decimalScale={0}
          label="Skor 3"
          placeholder="0"
          name="skor_3"
          loading={getSkor3.isFetching}
          errorFetch={getSkor3.isError}
          errorFetchText={errorFetchingSelectMapper(getSkor3.error)}
          className={generateColor(skor3Color)}
          value={skor3Value ? parseFloat(skor3Value) : ""}
        />
      </Col>

      <Col lg={2}>
        {/* Title */}
        <div className="mb-2">
          <b>HASIL EVALUASI</b>
        </div>
        <InfoItemVertical label="Total Skor" text={totalSkor} />
        <InfoItemVertical
          label="Grade"
          text={
            getGrade.isFetching
              ? "Memuat data . . ."
              : getGrade.isError
              ? errorFetchingSelectMapper(getGrade.error)
              : !checkGradeDepedencyFullfiled
              ? "-"
              : values?.grade_nama
          }
        />
        <InfoItemVertical
          label="Pangkat"
          text={
            getPangkat.isFetching
              ? "Memuat data . . ."
              : getPangkat.isError
              ? errorFetchingSelectMapper(getPangkat.error)
              : !checkPangkatDepedencyFullfiled
              ? "-"
              : values?.pangkat_nama
          }
        />
      </Col>
    </Row>
  );
};

export const SectionEvaluasiJabatan = ({ action }) => {
  return (
    <Card>
      <Card.Header>
        <b>Evaluasi Jabatan</b>
      </Card.Header>
      <Card.Body>
        {action === "READ" || action === "CONFIRM" ? <DetailEvaluasiJabatan /> : <FormEvaluasiJabatan />}
      </Card.Body>
    </Card>
  );
};
