import { InfoItemVertical, Input, Select } from "components";
import { useFormikContext } from "formik";
import { useQuery } from "react-query";
import { errorFetchingSelectMapper } from "utilities";
import JabatanApi from "../__JabatanApi__";
import { jenisJabatanMapper } from "../__JabatanUtils__";

export const Content = ({ action }) => {
  const { values, errors, touched, handleChange, setValues } = useFormikContext();
  const getJenisJabatan = useQuery(["jenis-jabatan", "dropdown"], () => JabatanApi.getJenisJabatan());
  const getKelompokJabatan = useQuery(["kelompok-jabatan", "dropdown"], () => JabatanApi.getKelompokJabatan());
  const getJobStream = useQuery(["job-stream", "dropdown"], () => JabatanApi.getJobStream());
  const getJenisProfesi = useQuery(["jenis-profesi", "dropdown"], () => JabatanApi.getJenisProfesi());
  const getUnitOrganisasi = useQuery(["unit-organisasi", "dropdown"], () => JabatanApi.getUnitOrganisasi());
  const getAtasanLangsung = useQuery(["atasan-langsung", "dropdown"], () => JabatanApi.getAtasanLangsung());

  if (action === "READ") {
    return (
      <>
        <InfoItemVertical label="Jenis Jabatan" text={jenisJabatanMapper(values.jenis_jabatan_nama)} />
        <InfoItemVertical label="Kode Jabatan" text={values.kode} />
        <InfoItemVertical label="Nama Jabatan" text={values.nama} />
        <InfoItemVertical label="Kelompok Jabatan" text={values.kelompok_jabatan_nama} />
        <InfoItemVertical label="Job Stream" text={values.job_stream_nama} />
        <InfoItemVertical label="Jenis Profesi" text={values.jenis_profesi_nama} />
        {values.jenis_jabatan_nama === 1 && (
          <>
            <InfoItemVertical label="Unit Organisasi" text={values.unit_organisasi_nama} />
            <InfoItemVertical label="Atasan Langsung" text={values.atasan_langsung_nama} />
          </>
        )}
      </>
    );
  }

  return (
    <>
      <Select
        label="Jenis Jabatan"
        disable={values?.update}
        placeholder="Pilih jenis jabatan"
        defaultValue={getJenisJabatan?.data
          ?.filter((val) => val.value)
          ?.find((item) => item.value === values.jenis_jabatan_nama)}
        options={getJenisJabatan?.data?.filter((val) => val.value)}
        onChange={(val) => {
          setValues({
            jenis_jabatan_nama: val.value,
            jenis_jabatan_ket: (val.value === 1 ? "STRUKTURAL" : "FUNGSIONAL"),
          });
        }}
        loading={getJenisJabatan.isFetching}
        error={Boolean(errors.jenis_jabatan_nama && touched.jenis_jabatan_nama)}
        errorText={Boolean(errors.jenis_jabatan_nama && touched.jenis_jabatan_nama) && errors.jenis_jabatan_nama}
        errorFetch={getJenisJabatan.isError}
        errorFetchText={errorFetchingSelectMapper(getJenisJabatan.error)}
      />

      <Input
        label="Kode Jabatan"
        name="kode"
        placeholder="Masukkan kode jabatan"
        value={values.kode}
        onChange={handleChange}
        error={Boolean(errors.kode && touched.kode)}
        errorText={Boolean(errors.kode && touched.kode) && errors.kode}
      />

      <Input
        label="Nama Jabatan"
        name="nama"
        placeholder="Masukkan nama jabatan"
        value={values.nama}
        onChange={handleChange}
        error={Boolean(errors.nama && touched.nama)}
        errorText={Boolean(errors.nama && touched.nama) && errors.nama}
      />

      <Select
        label="Kelompok Jabatan"
        placeholder="Pilih kelompok jabatan"
        defaultValue={getKelompokJabatan?.data?.find((item) => item.value === values.kelompok_jabatan_id)}
        options={getKelompokJabatan?.data ?? []}
        onChange={(val) => {
          setValues({
            ...values,
            kelompok_jabatan_id: val.value,
            kelompok_jabatan_nama: val.label
          });
        }}
        loading={getKelompokJabatan.isFetching}
        error={Boolean(errors.kelompok_jabatan_nama && touched.kelompok_jabatan_nama)}
        errorText={
          Boolean(errors.kelompok_jabatan_nama && touched.kelompok_jabatan_nama) && errors.kelompok_jabatan_nama
        }
        errorFetch={getKelompokJabatan.isError}
        errorFetchText={errorFetchingSelectMapper(getKelompokJabatan.error)}
      />

      <Select
        label="Job Stream"
        placeholder="Pilih job stream"
        defaultValue={getJobStream?.data?.find((item) => item.value === values.job_stream_id)}
        options={getJobStream?.data ?? []}
        onChange={(val) => {
          setValues({
            ...values,
            job_stream_id: val.value,
            job_stream_nama: val.label
          });
        }}
        loading={getJobStream.isFetching}
        error={Boolean(errors.job_stream_nama && touched.job_stream_nama)}
        errorText={Boolean(errors.job_stream_nama && touched.job_stream_nama) && errors.job_stream_nama}
        errorFetch={getJobStream.isError}
        errorFetchText={errorFetchingSelectMapper(getJobStream.error)}
      />

      <Select
        label="Jenis Profesi"
        placeholder="Pilih jenis profesi"
        defaultValue={getJenisProfesi?.data?.find((item) => item.value === values.jenis_profesi_id)}
        options={getJenisProfesi?.data ?? []}
        onChange={(val) => {
          setValues({
            ...values,
            jenis_profesi_id: val.value,
            jenis_profesi_nama: val.label
          });
        }}
        loading={getJenisProfesi.isFetching}
        error={Boolean(errors.job_stream_nama && touched.job_stream_nama)}
        errorText={Boolean(errors.job_stream_nama && touched.job_stream_nama) && errors.job_stream_nama}
        errorFetch={getJenisProfesi.isError}
        errorFetchText={errorFetchingSelectMapper(getJenisProfesi.error)}
      />
      {values.jenis_jabatan_nama === 1 && (
        <>
          <Select
            disable={values.jenis_jabatan_nama === 2}
            label="Unit Organisasi"
            placeholder="Pilih unit organisasi"
            defaultValue={getUnitOrganisasi?.data?.find((item) => item.value === values.unit_organisasi_id)}
            options={getUnitOrganisasi?.data ?? []}
            onChange={(val) => {
              setValues({
                ...values,
                unit_organisasi_id: val.value,
                unit_organisasi_nama: val.label
              });
            }}
            loading={getUnitOrganisasi.isFetching}
            errorFetch={getUnitOrganisasi.isError}
            errorFetchText={errorFetchingSelectMapper(getUnitOrganisasi.error)}
            error={Boolean(errors.unit_organisasi_id && touched.unit_organisasi_id)}
            errorText={Boolean(errors.unit_organisasi_id && touched.unit_organisasi_id) && errors.unit_organisasi_id}
          />

          <Select
            disable={values.jenis_jabatan_nama === 2}
            label="Atasan Langsung"
            placeholder="Pilih Atasan"
            defaultValue={getAtasanLangsung?.data?.find((item) => item.value === values.atasan_langsung_id)}
            options={getAtasanLangsung?.data ?? []}
            onChange={(val) => {
              setValues({
                ...values,
                atasan_langsung_id: val.value,
                atasan_langsung_nama: val.label
              });
            }}
            loading={getAtasanLangsung.isFetching}
            errorFetch={getAtasanLangsung.isError}
            errorFetchText={errorFetchingSelectMapper(getAtasanLangsung.error)}
            error={Boolean(errors.atasan_langsung_id && touched.atasan_langsung_id)}
            errorText={Boolean(errors.atasan_langsung_id && touched.atasan_langsung_id) && errors.atasan_langsung_id}
          />
        </>
      )}
    </>
  );
};
