import { TunjanganUangMakanList, TunjanganUangMakanCreate, TunjanganUangMakanUpdate, TunjanganUangMakanDetail } from "pages/Payroll";

export const TunjanganUangMakan = {
    component: <TunjanganUangMakanList />,
    link: "tunjangan-uang-makan",
    name: "Tunjangan Uang Makan",
    access: "PEKERJA_MAKAN_BULANAN",
    child: [
        {
            name: "Tambah",
            link: "/tambah",
            component: <TunjanganUangMakanCreate />
        },
        {
            name: "Ubah",
            link: "/ubah/:id",
            component: <TunjanganUangMakanUpdate />
        },
        {
            name: "Detail",
            link: "/detail/:id",
            component: <TunjanganUangMakanDetail />
        }
    ]
};
