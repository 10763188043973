import * as Yup from "yup";

export const formValidationSchema = Yup.object().shape({
  nama: Yup.string().required("Nama shift wajib diisi"),
  kode: Yup.string().required("Kode shift wajib diisi"),
  // scanId: Yup.string().required("Scan shift wajib diisi"),
  // durasiId: Yup.string().required("Durasi shift wajib diisi"),
  // jamMasuk: Yup.string().required("Jam masuk wajib diisi"),
  // jamPulang: Yup.string().required("Jam pulang wajib diisi"),
  // keterangan: Yup.string().required("Keterangan wajib diisi")
});
