import React, { useState } from "react";
import { Form, Card, InputGroup, FormControl } from "react-bootstrap";
import { HiUser, HiLockClosed, HiEyeOff, HiEye } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { Button, toastTrigger } from "components";
import { useAuth } from "hooks";
import config from "../../config";
import LoginApi from "./__LoginApi__";

export const Login = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  const formInitialValues = {
    akun: "",
    password: ""
  };

  const formValidationSchema = Yup.object().shape({
    akun: Yup.string().required("Masukan username"),
    password: Yup.string().required("Masukan password")
  });

  const formSubmitHandler = (values, formik) => {
    const { resetForm } = formik;

    return LoginApi.login(values)
      .then((res) => {
        const accessToken = res.data?.data?.access_token ?? null;
        const refreshToken = res.data?.data?.refresh_token ?? null;

        localStorage.setItem("access_token", accessToken);
        localStorage.setItem("refresh_token", refreshToken);
        auth.login(accessToken);

        toastTrigger({ variant: "success", message: "Login berhasil!" });
        navigate("/");
      })
      .catch((err) => {
        const statusCode = err?.response?.status ?? 500;
        const formConfig = {
          values: {
            username: values.username,
            password: ""
          },
          touched: {
            username: true,
            password: true
          }
        };

        toastTrigger({ variant: "danger", message: "Login gagal!" });

        switch (statusCode) {
          case 404:
            return resetForm({
              ...formConfig,
              errors: {
                username: "Username tidak terdaftar"
              }
            });

          case 500:
            return resetForm({
              ...formConfig,
              errors: {
                username: "Server error"
              }
            });

          default:
            return resetForm({
              ...formConfig,
              errors: {
                username: "Username dan password tidak valid"
              }
            });
        }
      });
  };

  const InputUsername = ({ error, errorText, ...props }) => {
    return (
      <InputGroup size="sm" className="mb-3">
        <InputGroup.Text className={`bg-transparent border-end-0 ${error ? "border-danger" : ""}`}>
          <HiUser className="text-primary" />
        </InputGroup.Text>
        <FormControl {...props} className={`border-start-0 ${error ? "border-danger" : ""}`} isInvalid={error} />
        <Form.Control.Feedback type="invalid">{errorText}</Form.Control.Feedback>
      </InputGroup>
    );
  };

  const InputPassword = ({ error, errorText, ...props }) => {
    const [show, setShow] = useState(false);

    return (
      <InputGroup size="sm" className="mb-3">
        <InputGroup.Text className={`bg-transparent ${error ? "border-danger" : ""}`}>
          <HiLockClosed className="text-primary" />
        </InputGroup.Text>
        <FormControl
          {...props}
          type={show ? "text" : "password"}
          className="border-start-0 border-end-0"
          isInvalid={error}
        />
        <InputGroup.Text
          className={`bg-transparent ${error ? "border-danger" : ""}`}
          style={{ cursor: "pointer" }}
          onClick={() => setShow(!show)}
        >
          {show ? <HiEye className="text-primary" /> : <HiEyeOff className="text-primary" />}
        </InputGroup.Text>
        <Form.Control.Feedback type="invalid">{errorText}</Form.Control.Feedback>
      </InputGroup>
    );
  };

  return (
    <div
      className="bg-light d-flex justify-content-center align-items-center"
      style={{ height: "100vh", width: "100vw" }}
    >
      <Card
        className="d-flex flex-column justify-content-center align-items-center border-0 bg-transparent rounded"
        style={{ width: 350, marginTop: -150 }}
      >
        <Card.Header className="d-flex justify-content-center py-2 bg-light border-0">
          <div className="d-flex justify-content-end align-items-center">
            {/* <div className="border-end my-3 d-flex justify-content-end"> */}
            <div className="my-3 d-flex justify-content-end">
              <img src={config.LOGO} width={180} className="me-3" alt="logo" />
            </div>
            {/* <div className="d-flex justify-content-start align-items-center">
              <b className="ms-3 text-capitalize" style={{ fontSize: 22, lineHeight: 1.2 }}>
                Modul <br />
                {config.MODUL}
              </b>
            </div> */}
          </div>
        </Card.Header>

        <Card.Body style={{ width: "100%" }} className="pb-4 rounded border bg-white">
          <div className="text-center mb-3">
            <b>Form Login</b>
          </div>

          <Formik
            initialValues={formInitialValues}
            validationSchema={formValidationSchema}
            onSubmit={formSubmitHandler}
          >
            {({ values, errors, touched, isSubmitting, handleChange, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                {/* Username */}
                <InputUsername
                  name="akun"
                  placeholder="Masukan username"
                  value={values.akun}
                  onChange={handleChange}
                  error={Boolean(errors.akun && touched.akun)}
                  errorText={Boolean(errors.akun && touched.akun) && errors.akun}
                />

                {/* Password */}
                <InputPassword
                  name="password"
                  placeholder="Masukan password"
                  value={values.password}
                  onChange={handleChange}
                  error={Boolean(errors.password && touched.password)}
                  errorText={Boolean(errors.password && touched.password) && errors.password}
                />

                {/* Button */}
                <Button type="submit" text="Login" className="mt-2" style={{ width: "100%" }} loading={isSubmitting} />
              </form>
            )}
          </Formik>
        </Card.Body>

        <Card.Footer className="text-center pt-4 bg-light border-0">
          <small style={{ width: 350, fontSize: "12px" }}>Copyright &copy; 2023 PT Bhawana Piranti Semesta</small>
        </Card.Footer>
      </Card>
    </div>
  );
};
