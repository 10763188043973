import React from "react";
import { Tree, TreeNode } from "react-organizational-chart";
import { DataStatus } from "@bhawanadevteam/react-core";
import { useFormikContext } from "formik";

const BoxNode = ({ label }) => (
  <div
    className="d-inline-block border border-dark rounded-3 align-middle py-3"
    style={{ maxWidth: 200, minWidth: 150 }}
  >
    <span className="">{label}</span>
  </div>
);

export const TabStrukturOrganisasi = ({ getDetailIsFetching, getDetailIsError }) => {
  const parentFormik = useFormikContext();

  return getDetailIsFetching ? (
    <DataStatus loading />
  ) : getDetailIsError ? (
    <DataStatus text="Tidak Ada Data" />
  ) : (
    <Tree
      lineWidth="3px"
      lineHeight="50px"
      label={<BoxNode label={parentFormik?.values?.identitasJabatan?.atasanLangsung ?? "-"} />}
    >
      <TreeNode label={<BoxNode label={parentFormik?.values?.identitasJabatan?.jabatanNama ?? "-"} />}>
        {parentFormik?.values?.identitasJabatan?.bawahanLangsung &&
          parentFormik?.values?.identitasJabatan?.bawahanLangsung.map((val) => (
            <TreeNode label={<BoxNode label={val ?? "-"} />} />
          ))}
      </TreeNode>
    </Tree>
  );
};
