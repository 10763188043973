import {
  ButtonDelete, ButtonDetail,
  ButtonUpdate, ModalCreate, ModalDetail, ModalUpdate,
  toastTrigger
} from "components";
import { Form, Formik } from "formik";
import { useAccessRights, useLayout, useModalConfirm } from "hooks";
import { ListLayout } from "layouts";
import { debounce } from "lodash";
import { useState } from "react";
import { ButtonGroup } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import { errorFetchingTableMapper, errorSubmitMapper, exportHandler } from "utilities";
import JenisCutiApi from "./__JenisCutiApi__";
import { Content } from "./__JenisCutiComps__";
import { formInitialValues, formSubmitMapper, formValidationSchema } from "./__JenisCutiUtils__";

export const JenisCutiList = () => {
  const access = useAccessRights("JENIS_CUTI");
  const layout = useLayout();
  const modalConfirm = useModalConfirm();
  const [searchKey, setSearchKey] = useState(undefined);
  const [pagination, setPagination] = useState({
    pageCurrent: 1,
    dataLength: 10
  });
  const [modal, setModal] = useState({
    show: false,
    action: "",
    data: {}
  });

  const getList = useQuery(["jenis-cuti", "list", searchKey, pagination.pageCurrent, pagination.dataLength], () =>
    JenisCutiApi.getList({
      search: searchKey,
      page: pagination.pageCurrent,
      limit: pagination.dataLength
    })
  );

  const createJenisCuti = useMutation((data) => JenisCutiApi.create(data), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "create" });
      getList.refetch();
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "create", ...errorSubmitMapper(err) })
  });

  const updateJenisCuti = useMutation(({ data, id }) => JenisCutiApi.update(data, id), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "update" });
      getList.refetch();
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "update", ...errorSubmitMapper(err) })
  });

  const deleteJenisCuti = useMutation((id) => JenisCutiApi.delete(id), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "delete" });
      getList.refetch();
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "delete", ...errorSubmitMapper(err) })
  });

  const getExportJenisCuti = useMutation((type) => JenisCutiApi.getExport({ to: type }), {
    onSuccess: (link) => {
      toastTrigger({ variant: "success", message: "Export berhasil" });
      exportHandler(link, "Data Jenis Cuti");
    },
    onError: () => {
      toastTrigger({ variant: "danger", message: "Export gagal" });
    }
  });

  const searchHandler = (e) => {
    setSearchKey(e?.target?.value ? e.target.value : undefined);
  };

  const onHideModalHandler = () => setModal({ show: false, action: "", data: {} });

  const onCreateButtonClickHandler = () => setModal({ show: true, action: "CREATE", data: {} });

  const onDetailButtonClickHandler = (data) => setModal({ show: true, action: "READ", data: data });

  const onUpdateButtonClickHandler = (data) => setModal({ show: true, action: "UPDATE", data: data });

  const onPaginationChange = (data) => setPagination({ ...pagination, pageCurrent: data });

  const onPaginationDataLengthChange = (data) => setPagination({ ...pagination, dataLength: data });

  const submitValidationHandler = (errors) =>
    new Promise((resolve, reject) => {
      const getError = Object.values(errors);

      if (getError.length > 0) {
        reject(getError);
      } else {
        resolve();
      }
    });

  const preSubmitHandler = (formik) => {
    const { values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting } = formik;

    setSubmitting(true);
    validateForm().then(async (err) => {
      setErrors(err);
      setTouched(err);

      await submitValidationHandler(err, values)
        .then(() => handleSubmit())
        .catch((err) =>
          modalConfirm.trigger({
            type: "error",
            title: "Data Tidak Lengkap",
            data: err,
            onHide: () => modalConfirm.close()
          })
        )
        .finally(() => {
          setSubmitting(false);
        });
    });
  };

  const formSubmitHandler = (values, action, resetForm) =>
    modalConfirm.trigger({
      type: action.toLowerCase(),
      onHide: modalConfirm.close,
      onSubmit: () => {
        modalConfirm.loading();

        if (action === "UPDATE") {
          updateJenisCuti
            .mutateAsync({ data: formSubmitMapper(values), id: values.id })
            .then(() => onHideModalHandler());
        } else if (action === "DELETE") {
          deleteJenisCuti.mutate(values.id);
        } else {
          createJenisCuti.mutateAsync(formSubmitMapper(values)).then(() => onHideModalHandler());
          resetForm();
        }
      },
      component: (
        <Formik initialValues={values}>
          <Content action="READ" />
        </Formik>
      )
    });

  const TABLE_HEADER = [
    {
      text: "Kode"
    },
    {
      text: "Jenis Cuti"
    },
    {
      text: "Maksimal Cuti (hari)"
    },
    {
      text: "Keterangan"
    },
    {
      text: "Aksi",
      props: {
        width: 50,
        fixed: true
      }
    }
  ];

  const TABLE_BODY = [
    { field: "master_jenis_cuti_kode" },
    { field: "master_jenis_cuti_nama" },
    { props: { TextRight: true }, customField: (data) => data.maksimal_cuti + "" },
    { field: "keterangan" },
    {
      props: {
        width: 50,
        fixed: true
      },
      customField: (data, index) => (
        <div className="d-flex align-items-center">
          <ButtonGroup size="sm">
            {access.canRead() && (
              <ButtonDetail icon noText tooltip={false} onClick={() => onDetailButtonClickHandler(data)} />
            )}
            {access.canUpdate() && (
              <ButtonUpdate icon noText tooltip={false} onClick={() => onUpdateButtonClickHandler(data)} />
            )}
            {access.canDelete() && (
              <ButtonDelete
                icon
                noText
                tooltip={false}
                onClick={() => formSubmitHandler(formInitialValues(data), "DELETE")}
              />
            )}
          </ButtonGroup>
        </div>
      )
    }
  ];
  return (
    <>
      {/**
       * List Layout:
       * Menampilkan nama menu, breadcrumb, search input, create & export button, tabel dll
       * search section & button section dapat direplace menggunakan props customLeftTopSection, customRightTopSection dll
       */}
      <ListLayout
        showRightTopButton={access.canCreate() ? true : false}
        showExportExcelButton
        showExportPdfButton
        onExportExcelButtonClick={() => getExportJenisCuti.mutate("excel")}
        onExportPdfButtonClick={() => getExportJenisCuti.mutate("pdf")}
        loadingExportButton={getExportJenisCuti.isLoading}
        loading={getList.isFetching}
        error={getList.isError}
        errorText={errorFetchingTableMapper(getList.error)}
        dataTotal={getList?.data?.total}
        dataLength={pagination.dataLength}
        pageCurrent={pagination.pageCurrent}
        pageTotal={getList?.data?.last_page}
        onSearchChange={debounce(searchHandler, 1500)}
        onCreateButtonClick={onCreateButtonClickHandler}
        onDataLengthChange={onPaginationDataLengthChange}
        onPaginationChange={onPaginationChange}
        data={getList?.data?.data}
        tableHeader={TABLE_HEADER}
        tableBody={TABLE_BODY}
      />
      {/**
       * Modal Detail:
       * Digunakan untuk melihat detail data
       */}
      <Formik enableReinitialize initialValues={formInitialValues(modal.data)}>
        <ModalDetail
          title={layout.getActivePageName()}
          show={Boolean(modal.show && modal.action === "READ")}
          onHide={onHideModalHandler}
        >
          <Content action="READ" />
        </ModalDetail>
      </Formik>
      {/**
       * Modal Create:
       * Digunakan untuk menambah data
       */}
      <Formik
        enableReinitialize
        initialValues={formInitialValues({})}
        validationSchema={formValidationSchema}
        onSubmit={(values, { resetForm }) => formSubmitHandler(values, "CREATE", resetForm)}
      >
        {(formik) => {
          return (
            <Form>
              <ModalCreate
                title={layout.getActivePageName()}
                show={Boolean(modal.show && modal.action === "CREATE")}
                onHide={() => {
                  onHideModalHandler();
                  formik.resetForm();
                }}
                onSubmit={() => {
                  preSubmitHandler(formik);
                }}
              >
                <Content action="CREATE" />
              </ModalCreate>
            </Form>
          );
        }}
      </Formik>
      {/**
       * Modal Update:
       * Modal untuk mengubah data
       */}
      <Formik
        enableReinitialize
        initialValues={formInitialValues(modal.data)}
        validationSchema={formValidationSchema}
        onSubmit={(values) => formSubmitHandler(values, "UPDATE")}
      >
        {(formik) => {
          return (
            <Form>
              <ModalUpdate
                title={layout.getActivePageName()}
                show={Boolean(modal.show && modal.action === "UPDATE")}
                onHide={() => {
                  onHideModalHandler();
                  formik.resetForm();
                }}
                onSubmit={() => preSubmitHandler(formik)}
              >
                <Content action="UPDATE" />
              </ModalUpdate>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
