import { Services } from "services";

class PermohonanIzinApi {
  async getList(params) {
    const fetch = await Services.get("/transaksi/permohonan-ijin", { params });
    return fetch?.data?.data;
  }

  async getDetail(id) {
    const fetch = await Services.get("/transaksi/permohonan-ijin/" + id);
    return fetch?.data?.data;
  }
  s;
  async getUnitOrganisasi() {
    const fetch = await Services.get("/master/unit-organisasi/dropdown");
    return fetch?.data?.data.map((val) => ({
      value: val?.master_unit_organisasi_id,
      label: val?.master_unit_organisasi_nama
    }));
  }

  async getUnitOrganisasiForList() {
    const fetch = await Services.get("/transaksi/permohonan-ijin/dropdown-unit");
    return fetch?.data?.data.map((val) => ({
      value: val?.master_unit_organisasi_id,
      label: val?.master_unit_organisasi_nama
    }));
  }

  async getJenisIzin() {
    const fetch = await Services.get("/master/jenis-ijin/dropdown");
    return fetch?.data?.data.map((val) => ({
      value: val?.master_jenis_ijin_id,
      label: val?.master_jenis_ijin_nama
    }));
  }

  async getPekerja(params) {
    const fetch = await Services.get("/transaksi/registrasi-pekerja/dropdown", { params });
    return fetch?.data?.data.map((val) => ({
      value: val?.pekerja_id,
      label: val?.pekerja_nama,
      jabatanId: val?.master_jabatan_id,
      jabatanNama: val?.master_jabatan_nama
    }));
  }

  async getExport(params) {
    const fetch = await Services.get("/transaksi/permohonan-ijin/export", { params });
    return fetch?.data?.data;
  }

  create(data) {
    return Services.post("/transaksi/permohonan-ijin", data);
  }

  update(data, id) {
    return Services.put("/transaksi/permohonan-ijin/" + id, data);
  }

  delete(id) {
    return Services.delete("/transaksi/permohonan-ijin/" + id);
  }
}

export default new PermohonanIzinApi();
