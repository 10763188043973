import { Services } from "services";
import { fetchDropdownMapper } from "utilities";

const fetchDropdown = fetchDropdownMapper(Services);

class SetupNominalUpahMinimumBPJSApi {
  async getList(params) {
    const fetch = await Services.get("/master/nominal-upah-bpjs", { params });
    return fetch?.data?.data;
  }
  // async getPeriode() {
  //   const getPeriodeDropdown = await fetchDropdown("/utility/periode-tahun?feat=nominal-upah-bpjs", {
  //     value: "periode_tahun",
  //     label: "periode_tahun"
  //   });
  //   return [...getPeriodeDropdown];
  // }
  async getJenisUpahMinimum() {
    const getPeriodeDropdown = await fetchDropdown("/master/jenis-upah-minimum/dropdown", {
      value: "jenis_upah_id",
      label: "jenis_upah_nama"
    });
    return [...getPeriodeDropdown];
  }
  async getDetail(id) {
    const fetch = await Services.get("/master/nominal-upah-bpjs/" + id);
    return fetch?.data?.data;
  }
  async getExport(params) {
    const fetch = await Services.get("/master/nominal-upah-bpjs/export?to=", { params });
    return fetch?.data?.data;
  }
  create(data) {
    return Services.post("/master/nominal-upah-bpjs", data);
  }
  update(data, id) {
    return Services.put("/master/nominal-upah-bpjs/" + id, data);
  }
  delete(id) {
    return Services.delete("/master/nominal-upah-bpjs/" + id);
  }
}

export default new SetupNominalUpahMinimumBPJSApi();
