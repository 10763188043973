import { SetupUpahTetapBpjsList, SetupUpahTetapBpjsCreate } from "pages/Payroll";

export const SetupUpahTetapBpjs = {
    component: <SetupUpahTetapBpjsList />,
    link: "upah-tetap-bpjs",
    name: "Setup Upah Tetap BPJS",
    access: "PEKERJA_UPAH_BPJS",
    child: [
        {
            name: "Tambah",
            link: "/tambah",
            component: <SetupUpahTetapBpjsCreate />
        }
    ]
};
