import React from "react";
import { useFormikContext } from "formik";
import { InfoItemVertical, Select, numberConvert, rupiahConvert, InputCurrency, InputNumber } from "components";
import { useQuery } from "react-query";
import TerPersenTarifApi from "../__TerPersenTarifApi__";

export const Content = ({ action }) => {
    const { values, errors, touched, setValues } = useFormikContext();
    const number = numberConvert();
    const rupiah = rupiahConvert();

    const getTer = useQuery(["ter", "dropdown"], () => TerPersenTarifApi.getTer());

    if (action === "READ") {
        return (
            <>
                <InfoItemVertical label="TER" text={values.ter} />
                <InfoItemVertical label="Batas Bawah" text={values.batasBawah ? rupiah.getWithComa(values?.batasBawah) : "-"} />
                <InfoItemVertical label="Batas Atas" text={values.batasAtas ? rupiah.getWithComa(values?.batasAtas) : "-"} />
                <InfoItemVertical label="Persentase Tarif" text={values.persenTarif ? number.getWithComa(values?.persenTarif, { minimumFractionDigits: 2 }) + "%" : "-"} />
            </>
        );
    }

    return (
        <>
            <Select
                label="TER"
                options={getTer?.data ?? []}
                placeholder="Pilih salah satu..."
                defaultValue={
                    values?.ter
                        ? getTer?.data?.find((item) => item.value === values?.ter)
                        : null
                }
                onChange={(val) => {
                    setValues({
                        ...values,
                        ter: val.value,
                    });
                }}
                loading={getTer?.isFetching}
                errorFetch={getTer.isError}
                error={Boolean(errors.statusPtkpId && touched.statusPtkpId)}
                errorText={Boolean(errors.statusPtkpId && touched.statusPtkpId) && errors.statusPtkpId}
            />
            <InputCurrency
                label="Batas Bawah"
                name="batasBawah"
                placeholder="Masukkan batas bawah"
                value={values.batasBawah}
                onChange={(val) => setValues({
                    ...values,
                    batasBawah: val
                })}
                error={Boolean(errors.batasBawah && touched.batasBawah)}
                errorText={Boolean(errors.batasBawah && touched.batasBawah) && errors.batasBawah}
            />
            <InputCurrency
                label="Batas Atas"
                name="batasAtas"
                placeholder="Masukkan batas atas"
                value={values.batasAtas}
                onChange={(val) => setValues({
                    ...values,
                    batasAtas: val
                })}
                error={Boolean(errors.batasAtas && touched.batasAtas)}
                errorText={Boolean(errors.batasAtas && touched.batasAtas) && errors.batasAtas}
            />
            <InputNumber
                label="Persen Tarif"
                name="persenTarif"
                suffix="%"
                placeholder="Masukkan batas atas"
                value={values.persenTarif ? number.getWithComa(values?.persenTarif, { minimumFractionDigits: 2 }) : ""}
                onChange={(val) => setValues({
                    ...values,
                    persenTarif: val
                })}
                error={Boolean(errors.persenTarif && touched.persenTarif)}
                errorText={Boolean(errors.persenTarif && touched.persenTarif) && errors.persenTarif}
            />
        </>
    );
};
