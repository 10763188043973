export const formSubmitMapper = () => ({
  identitasJabatan: (values = {}) => ({ master_jabatan_id: values?.jabatanId }),
  tujuanJabatan: (values = {}) => ({ tujuan_jabatan: values?.tujuanJabatan }),
  akuntabilitasJabatan: (values = {}) => ({
    akuntabilitas_utama: values?.detail?.map((val) => val?.akuntabilitasUtama)
  }),
  tugasRutin: (values = {}) => ({
    tugas_rutin: values?.detail?.map((val) => val?.tugasRutinNama)
  }),
  wewenang: (values = {}) => ({
    wewenang: values?.detail?.map((val) => val?.wewenangNama)
  }),
  hubunganKerja: (values = {}) => ({
    hubungan_kerja: values?.detail?.map((val) => val?.hubunganKerjaNama)
  }),
  dimensi: (values = {}) => ({
    dimensi: values?.detail?.map((val) => val?.dimensiNama)
  }),
  spesifikasi: (values = {}) => ({
    spesifikasi: {
      master_jenjang_pendidikan_id: values?.jenjanganPendidikanId,
      pengalaman_kerja: values?.pengalamanKerja,
      kompetensi: [
        ...values?.jeniskompetensiCore,
        ...values?.jeniskompetensiRole,
        ...values?.jeniskompetensiFunction
      ].map((val) => ({
        master_kompetensi_jabatan_id: val?.kompetensiJabatanId,
        master_skala_kemahiran_id: val?.skalaKemahiranId
      })),
      sertifikat_pelatihan: values?.sertifikatPelatihan?.map((val) => ({
        master_sertifikat_pelatihan_id: val?.sertifikatPelatihanId
      })),
      sertifikat_profesi: values?.sertifikatProfesi?.map((val) => ({
        master_sertifikat_profesi_id: val?.sertifikatProfesiId
      }))
    }
  }),
  kondisiKerja: (values = []) => ({
    kondisi_kerja: values.map((val) => ({
      master_kondisi_kerja_id: val?.masterKondisiKerjaId,
      jawaban_kondisi_kerja: val?.jawabanKondisiKerja
    }))
  })
});
