import { ButtonBack, DataStatus } from "components";
import { Formik } from "formik";
import { ContentLayout } from "layouts";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import InputTimeSheetConcretePumpApi from "./__InputTimeSheetConcretePumpApi__";
import { Content } from "./__InputTimeSheetConcretePumpComps__";
import { formInitialValues, formValidationSchema } from "./__InputTimeSheetConcretePumpUtilities__";

export const InputTimeSheetConcretePumpDetail = () => {
    const navigate = useNavigate();
    // const modalConfirm = useModalConfirm();
    const { id } = useParams();

    const getDetail = useQuery(
        ["time-sheet-concrete-pump", "detail", id], () =>
        InputTimeSheetConcretePumpApi.getDetail(id)
    );

    const getPekerja = useQuery(
        ["pekerja", "list"], () =>
        InputTimeSheetConcretePumpApi.getPekerjaList()
    );

    return (
        <>
            <ContentLayout showBreadcrumb>
                {getDetail?.isFetching ? (
                    <DataStatus loading />
                ) : (
                    <>
                        <div className="text-end mb-3">
                            <ButtonBack tooltip={false} onClick={() => navigate("/payroll/transaksi/time-sheet-concrete-pump")} />
                        </div>
                        <Formik
                            enableReinitialize
                            initialValues={formInitialValues(getPekerja?.data, getDetail?.data)}
                            validationSchema={formValidationSchema}
                        >
                            {({ values, handleSubmit, validateForm, setTouched, setErrors, setSubmitting }) => (
                                <Content action="READ" />
                            )}
                        </Formik>
                    </>
                )}
            </ContentLayout>
        </>
    );
};
