import { Services } from "services";
import { fetchDropdownMapper } from "utilities";

const fetchDropdown = fetchDropdownMapper(Services);

class SetupAktivitasApi {
  async getList(params) {
    const fetch = await Services.get("/transaksi/setup-aktivitas", { params });
    return fetch?.data?.data;
  }

  async getReadSingle(id) {
    const fetch = await Services.get("/transaksi/setup-aktivitas/" + id);
    return fetch?.data?.data;
  }

  async getListOpenSetup(params) {
    const fetch = await Services.get("/transaksi/setup-aktivitas/list-pekerja", { params });
    return fetch?.data?.data;
  }

  async getSingleOpenSetup(params) {
    const fetch = await Services.get("/transaksi/setup-aktivitas/form", { params });
    return fetch?.data?.data;
  }

  async getExport(params) {
    const fetch = await Services.get("transaksi/setup-aktivitas/export", { params });
    return fetch?.data?.data;
  }

  async getPekerjaHalamanDepan(params) {
    const fetch = await Services.get("/transaksi/registrasi-pekerja/dropdown", { params });
    const getPekerja = fetch?.data?.data?.map((val) => ({
      value: val?.pekerja_id,
      label: val?.pekerja_nama
    }));
    return getPekerja;
  }

  getBulan() {
    const getBulan = [
      { value: 1, label: "Januari", mm: "01" },
      { value: 2, label: "Februari", mm: "02" },
      { value: 3, label: "Maret", mm: "03" },
      { value: 4, label: "April", mm: "04" },
      { value: 5, label: "Mei", mm: "05" },
      { value: 6, label: "Juni", mm: "06" },
      { value: 7, label: "Juli", mm: "07" },
      { value: 8, label: "Agustus", mm: "08" },
      { value: 9, label: "September", mm: "09" },
      { value: 10, label: "Oktober", mm: "10" },
      { value: 11, label: "November", mm: "11" },
      { value: 12, label: "Desember", mm: "12" }
    ];
    return getBulan;
  }

  async getTanggalAktivitasHalamanDepan(params) {
    const fetch = await Services.get("/transaksi/setup-aktivitas/dropdown-tanggal", { params });
    const getTanggalAktivitasHalamanDepan = fetch?.data?.data?.map((val) => ({
      value: val,
      label: val
    }));
    return getTanggalAktivitasHalamanDepan;
  }

  async getPeriodePerencanaan() {
    const getPeriodePerencanaan = await fetchDropdown("/master/periode-perencanaan/dropdown", {
      value: "master_periode_perencanaan_id",
      label: "periode_perencanaan"
    });

    return getPeriodePerencanaan;
  }

  async getKegiatan() {
    const getKegiatan = await fetchDropdown("/master/kegiatan", {
      value: "master_kegiatan_id",
      label: "master_kegiatan_nama"
    });

    return getKegiatan;
  }

  async getSubKegiatan(idKegiatan) {
    const getSubKegiatan = await fetchDropdown("/master/sub-kegiatan", {
      value: "master_sub_kegiatan_id",
      idKegiatan: "master_kegiatan_id",
      label: "sub_kegiatan"
    });

    return getSubKegiatan?.filter((val) => val.idKegiatan === idKegiatan);
  }

  getStatusKegiatan() {
    const getStatusKegiatan = [
      { value: 1, label: "Belum Selesai" },
      { value: 2, label: "Selesai" },
      { value: 3, label: "Ditunda" }
    ];

    return getStatusKegiatan;
  }

  async getUnitOrganisasi() {
    const getUnitOrganisasi = await fetchDropdown("/master/unit-organisasi", {
      value: "master_unit_organisasi_id",
      label: "master_unit_organisasi_nama"
    });

    return getUnitOrganisasi.sort((x, y) => {
      if (x.label < y.label) {
        return -1;
      }
      if (x.label > y.label) {
        return 1;
      }
      if (x.label === y.label) {
        return 0;
      }
    });
  }

  create(data) {
    return Services.post("/transaksi/setup-aktivitas", data);
  }

  update(data, id) {
    return Services.put("/transaksi/setup-aktivitas/" + id, data);
  }

  delete(id) {
    return Services.delete("/transaksi/setup-aktivitas/" + id);
  }
}
export default new SetupAktivitasApi();
