import { Services } from "services";

class LaporanThr1Api {
  async getList(params) {
    const fetch = await Services.get("/laporan/laporan-thr-tahap-2", { params });
    return fetch?.data?.data;
  }

  async getUnitOrganisasi(params) {
    const fetch = await Services.get("/laporan/laporan-thr-tahap-2/dropdown-unit", { params });
    return fetch?.data?.data.map((val) => ({
      value: val?.master_unit_organisasi_id,
      label: val?.master_unit_organisasi_nama
    }));
  }

  async getPeriodeForList() {
    const fetch = await Services.get("/laporan/laporan-thr-tahap-2/dropdown-periode");
    return fetch?.data?.data.map((val) => ({
      value: val?.periode_gajian_id,
      label: val?.periode_gajian_perencanaan
    }));
  }

  async getExport(params) {
    const fetch = await Services.get("/laporan/laporan-thr-tahap-2/export", { params });
    return fetch?.data?.data;
  }
}

export default new LaporanThr1Api();
