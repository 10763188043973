import { Button, InfoItemVertical, Input, Select, TextArea } from "components";
import { Formik, useFormikContext } from "formik";
import { useModalConfirm } from "hooks";
import { Card, Col, Row } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { errorFetchingSelectMapper, errorSubmitMapper } from "utilities";
import PekerjaApi from "../__PekerjaApi__";
import { formInitialValues, formSubmitMapper, formValidationSchema } from "../__PekerjaUtils__";

const DetailAlamat = () => {
  const { values } = useFormikContext();

  return (
    <>
      <Card className="mb-3">
        <Card.Header>Alamat sesuai KTP</Card.Header>
        <Card.Body>
          <InfoItemVertical label="Provinsi" text={values.provinsiNama} />
          <InfoItemVertical label="Kabupaten" text={values.kabupatenNama} />
          <InfoItemVertical label="Kecamatan" text={values.kecamatanNama} />
          <InfoItemVertical label="Desa" text={values.desaNama} />
          <InfoItemVertical label="RT" text={values.rt} />
          <InfoItemVertical label="RW" text={values.rw} />
          <InfoItemVertical label="alamat" text={values.alamat} />
        </Card.Body>
      </Card>
      <Card className="mb-3">
        <Card.Header>Alamat Domisili</Card.Header>
        <Card.Body>
          <InfoItemVertical label="Provinsi" text={values.provinsiNama1} />
          <InfoItemVertical label="Kabupaten" text={values.kabupatenNama1} />
          <InfoItemVertical label="Kecamatan" text={values.kecamatanNama1} />
          <InfoItemVertical label="Desa" text={values.desaNama1} />
          <InfoItemVertical label="RT" text={values.rt1} />
          <InfoItemVertical label="RW" text={values.rw1} />
          <InfoItemVertical label="alamat" text={values.alamat1} />
        </Card.Body>
      </Card>
    </>
  );
};

const FormAlamat = () => {
  const { values, errors, touched, handleChange, setFieldValue, setValues } = useFormikContext();
  const getProvinsi = useQuery(["wilayah", "dropdown"], () => PekerjaApi.getWilayah("provinsi"));
  const getProvinsi1 = useQuery(["wilayah", "dropdown"], () => PekerjaApi.getWilayah("provinsi"));
  const getKabupaten = useQuery(
    ["kabupaten", "dropdown", values.provinsiKode],
    () => PekerjaApi.getWilayah("kabupaten", { provinsi_kode: values.provinsiKode }),
    {
      enabled: !!values.provinsiKode
    }
  );

  const getKecamatan = useQuery(
    ["kecamatan", "dropdown", values.kabupatenKode],
    () => PekerjaApi.getWilayah("kecamatan", { kabupaten_kode: values.kabupatenKode }),
    {
      enabled: !!values.kabupatenKode
    }
  );

  const getDesa = useQuery(
    ["desa", "dropdown", values.kecamatanKode],
    () => PekerjaApi.getWilayah("desa", { kecamatan_kode: values.kecamatanKode }),
    {
      enabled: !!values.kecamatanKode
    }
  );
  return (
    <>
      <Card className="mb-3">
        <Card.Header>Alamat sesuai KTP</Card.Header>
        <Card.Body>
          <Select
            label="Provinsi"
            placeholder="Pilih Provinsi"
            loading={getProvinsi.isLoading}
            options={getProvinsi.data ?? []}
            defaultValue={getProvinsi.data?.find(
              (item) => item?.value?.toString() === values?.provinsiKode?.toString()
            )}
            onChange={(val) => {
              setValues({
                ...values,
                provinsiKode: val?.value,
                provinsiNama: val?.label,
                kabupatenKode: "",
                kabupatenNama: "",
                kecamatanKode: "",
                kecamatanNama: "",
                desaKode: "",
                desaNama: ""
              });
            }}
            error={Boolean(errors.provinsiKode && touched.provinsiKode)}
            errorText={Boolean(errors.provinsiKode && touched.provinsiKode) && errors.provinsiKode}
            errorFetch={getProvinsi.isError}
            errorFetchText={errorFetchingSelectMapper(getProvinsi.error)}
          />

          <Select
            disable={!values.provinsiKode}
            label="Kabupaten"
            placeholder="Pilih kabupaten"
            loading={getKabupaten.isLoading}
            options={getKabupaten?.data ?? []}
            defaultValue={getKabupaten?.data?.find(
              (item) => item?.value?.toString() === values?.kabupatenKode?.toString()
            )}
            onChange={(val) => {
              setFieldValue("kabupatenKode", val?.value);
              setValues({
                ...values,
                kabupatenKode: val?.value,
                kabupatenNama: val?.label,
                kecamatanKode: "",
                kecamatanNama: "",
                desaKode: "",
                desaNama: ""
              });
            }}
            error={Boolean(errors.kabupatenKode && touched.kabupatenKode)}
            errorText={Boolean(errors.kabupatenKode && touched.kabupatenKode) && errors.kabupatenKode}
            errorFetch={getKabupaten.isError}
            errorFetchText={errorFetchingSelectMapper(getKabupaten.error)}
          />

          <Select
            disable={!values.kabupatenKode}
            label="Kecamatan"
            placeholder="Pilih kecamatan"
            loading={getKecamatan.isLoading}
            options={getKecamatan?.data ?? []}
            defaultValue={getKecamatan?.data?.find(
              (item) => item?.value?.toString() === values?.kecamatanKode?.toString()
            )}
            onChange={(val) => {
              setValues({
                ...values,
                kecamatanKode: val?.value,
                kecamatanNama: val?.label,
                desaKode: "",
                desaNama: ""
              });
            }}
            error={Boolean(errors.kecamatanKode && touched.kecamatanKode)}
            errorText={Boolean(errors.kecamatanKode && touched.kecamatanKode) && errors.kecamatanKode}
            errorFetch={getKecamatan.isError}
            errorFetchText={errorFetchingSelectMapper(getKecamatan.error)}
          />

          <Select
            disable={!values.kecamatanKode}
            label="Desa"
            placeholder="Pilih desa"
            loading={getDesa.isLoading}
            options={getDesa?.data ?? []}
            defaultValue={getDesa?.data?.find((item) => item?.value?.toString() === values?.desaKode?.toString())}
            onChange={(val) => {
              setValues({
                ...values,
                desaKode: val?.value,
                desaNama: val?.label
              });
            }}
            error={Boolean(errors.desaKode && touched.desaKode)}
            errorText={Boolean(errors.desaKode && touched.desaKode) && errors.desaKode}
            errorFetch={getDesa.isError}
            errorFetchText={errorFetchingSelectMapper(getDesa.error)}
          />

          <Row>
            <Col lg="6">
              <Input
                label="RT"
                type="number"
                name="rt"
                placeholder="Masukan RT"
                value={values?.rt}
                onChange={handleChange}
                error={Boolean(errors.rt && touched.rt)}
                errorText={Boolean(errors.rt && touched.rt) && errors.rt}
              />
            </Col>
            <Col lg="6">
              <Input
                label="RW"
                type="number"
                name="rw"
                placeholder="Masukan RW"
                value={values?.rw}
                onChange={handleChange}
                error={Boolean(errors.rw && touched.rw)}
                errorText={Boolean(errors.rw && touched.rw) && errors.rw}
              />
            </Col>
          </Row>
          <TextArea
            label="Alamat"
            name="alamat"
            placeholder="Masukan alamat"
            rows="4"
            value={values?.alamat}
            onChange={handleChange}
            error={Boolean(errors.alamat && touched.alamat)}
            errorText={Boolean(errors.alamat && touched.alamat) && errors.alamat}
          />
        </Card.Body>
      </Card>

      <Card className="my-2">
        <Card.Header>Alamat Domisili</Card.Header>
        <Card.Body>
          <Button
            size="sm"
            className="mb-2"
            text="Copy alamat KTP"
            onClick={(val) =>
              setValues({
                ...values,
                provinsiKode1: values.provinsiKode,
                provinsiNama1: values.provinsiNama,
                kabupatenKode1: values?.kabupatenKode,
                kabupatenNama1: values?.kabupatenNama,
                kecamatanKode1: values?.kecamatanKode,
                kecamatanNama1: values?.kecamatanNama,
                desaKode1: values?.desaKode,
                desaNama1: values?.desaNama,
                rt1: values?.rt,
                rw1: values?.rw,
                alamat1: values?.alamat
              })
            }
          />
          <Select
            label="Provinsi"
            placeholder="Pilih Provinsi"
            loading={getProvinsi1.isLoading}
            options={getProvinsi1.data ?? []}
            defaultValue={getProvinsi1.data?.find(
              (item) => item?.value?.toString() === values?.provinsiKode1?.toString()
            )}
            onChange={(val) => {
              setValues({
                ...values,
                provinsiKode1: val?.value,
                provinsiNama1: val?.label,
                kabupatenKode1: "",
                kabupatenNama1: "",
                kecamatanKode1: "",
                kecamatanNama1: "",
                desaKode1: "",
                desaNama1: ""
              });
            }}
            error={Boolean(errors.provinsiKode1 && touched.provinsiKode1)}
            errorText={Boolean(errors.provinsiKode1 && touched.provinsiKode1) && errors.provinsiKode1}
            errorFetch={getProvinsi.isError}
            errorFetchText={errorFetchingSelectMapper(getProvinsi.error)}
          />

          <Select
            disable={!values.provinsiKode}
            label="Kabupaten"
            placeholder="Pilih kabupaten"
            loading={getKabupaten.isLoading}
            options={getKabupaten?.data ?? []}
            defaultValue={getKabupaten?.data?.find(
              (item) => item?.value?.toString() === values?.kabupatenKode1?.toString()
            )}
            onChange={(val) => {
              setFieldValue("kabupatenKode", val?.value);
              setValues({
                ...values,
                kabupatenKode1: val?.value,
                kabupatenNama1: val?.label,
                kecamatanKode1: "",
                kecamatanNama1: "",
                desaKode1: "",
                desaNama1: ""
              });
            }}
            error={Boolean(errors.kabupatenKode1 && touched.kabupatenKode1)}
            errorText={Boolean(errors.kabupatenKode1 && touched.kabupatenKode1) && errors.kabupatenKode1}
            errorFetch={getKabupaten.isError}
            errorFetchText={errorFetchingSelectMapper(getKabupaten.error)}
          />

          <Select
            disable={!values.kabupatenKode1}
            label="Kecamatan"
            placeholder="Pilih kecamatan"
            loading={getKecamatan.isLoading}
            options={getKecamatan?.data ?? []}
            defaultValue={getKecamatan?.data?.find(
              (item) => item?.value?.toString() === values?.kecamatanKode1?.toString()
            )}
            onChange={(val) => {
              setValues({
                ...values,
                kecamatanKode1: val?.value,
                kecamatanNama1: val?.label,
                desaKode1: "",
                desaNama1: ""
              });
            }}
            error={Boolean(errors.kecamatanKode1 && touched.kecamatanKode1)}
            errorText={Boolean(errors.kecamatanKode1 && touched.kecamatanKode1) && errors.kecamatanKode1}
            errorFetch={getKecamatan.isError}
            errorFetchText={errorFetchingSelectMapper(getKecamatan.error)}
          />

          <Select
            disable={!values.kecamatanKode1}
            label="Desa"
            placeholder="Pilih desa"
            loading={getDesa.isLoading}
            options={getDesa?.data ?? []}
            defaultValue={getDesa?.data?.find((item) => item?.value?.toString() === values?.desaKode1?.toString())}
            onChange={(val) => {
              setValues({
                ...values,
                desaKode1: val?.value,
                desaNama1: val?.label
              });
            }}
            error={Boolean(errors.desaKode1 && touched.desaKode1)}
            errorText={Boolean(errors.desaKode1 && touched.desaKode1) && errors.desaKode1}
            errorFetch={getDesa.isError}
            errorFetchText={errorFetchingSelectMapper(getDesa.error)}
          />

          <Row>
            <Col lg="6">
              <Input
                label="RT"
                type="number"
                name="rt1"
                placeholder="Masukan RT"
                value={values?.rt1}
                onChange={handleChange}
                error={Boolean(errors.rt1 && touched.rt1)}
                errorText={Boolean(errors.rt1 && touched.rt1) && errors.rt1}
              />
            </Col>
            <Col lg="6">
              <Input
                label="RW"
                type="number"
                name="rw1"
                placeholder="Masukan RW"
                value={values?.rw1}
                onChange={handleChange}
                error={Boolean(errors.rw1 && touched.rw1)}
                errorText={Boolean(errors.rw1 && touched.rw1) && errors.rw1}
              />
            </Col>
          </Row>
          <TextArea
            label="Alamat"
            name="alamat1"
            placeholder="Masukan alamat"
            rows="4"
            value={values?.alamat1}
            onChange={handleChange}
            error={Boolean(errors.alamat1 && touched.alamat1)}
            errorText={Boolean(errors.alamat1 && touched.alamat1) && errors.alamat1}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export const TabAlamat = ({ action, refreshData, isGeneralUser }) => {
  const { id } = useParams();
  const modalConfirm = useModalConfirm();
  const parentFormik = useFormikContext();

  const updateAlamat = useMutation((data) => PekerjaApi.update(data, id), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "update", customTextInfoTitle: "Data Berhasil Disimpan" });
      refreshData();
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "update", ...errorSubmitMapper(err) })
  });

  const updateAlamatSelf = useMutation((data) => PekerjaApi.updateSelf(data), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "update", customTextInfoTitle: "Data Berhasil Disimpan" });
      refreshData();
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "update", ...errorSubmitMapper(err) })
  });

  const submitValidationHandler = (errors) =>
    new Promise((resolve, reject) => {
      const getError = Object.values(errors);

      if (getError.length > 0) {
        reject(getError);
      } else {
        resolve();
      }
    });

  const preSubmitHandler = (values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting) => {
    setSubmitting(true);
    validateForm().then(async (err) => {
      setErrors(err);
      setTouched(err);

      await submitValidationHandler(err, values)
        .then(() => handleSubmit())
        .catch((err) =>
          modalConfirm.trigger({
            type: "error",
            title: "Data Tidak Lengkap",
            data: err,
            onHide: () => modalConfirm.close()
          })
        )
        .finally(() => {
          setSubmitting(false);
        });
    });
  };

  const formSubmitHandler = (values) =>
    modalConfirm.trigger({
      type: "custom",
      onHide: modalConfirm.close,
      title: "Konfirmasi",
      customButtonShow: true,
      customButtonText: "Simpan",
      customButtonVariant: "primary",
      customTextHeader: "Apakah anda yakin menyimpan data ini?",
      onSubmit: () => {
        modalConfirm.loading();
        if (isGeneralUser === true) {
          updateAlamatSelf.mutate(formSubmitMapper.alamat(values));
        } else {
          updateAlamat.mutate(formSubmitMapper.alamat(values));
        }
      },
      component: (
        <Formik initialValues={values}>
          <DetailAlamat />
        </Formik>
      )
    });

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues.alamat(parentFormik.values)}
      validationSchema={formValidationSchema.alamat}
      onSubmit={formSubmitHandler}
    >
      {({ values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting }) => {
        return (
          <>
            {action === "READ" ? <DetailAlamat /> : <FormAlamat />}
            {!Boolean(action === "READ") && (
              <div className="text-end mt-3">
                <Button
                  text="Simpan"
                  className="px-4"
                  onClick={() =>
                    preSubmitHandler(values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting)
                  }
                />
              </div>
            )}
          </>
        );
      }}
    </Formik>
  );
};
