import { Services } from "services";
import { fetchDropdownMapper } from "utilities";

const fetchDropdown = fetchDropdownMapper(Services);

class SetupKomponenJaminanHariTuaBulananApi {
  async getList(params) {
    const fetch = await Services.get("/transaksi/setup-komponen-jaminan-hari-tua-bulanan", { params });
    return fetch?.data?.data;
  }
  async getListForm(params) {
    const fetch = await Services.get("/transaksi/setup-komponen-jaminan-hari-tua-bulanan/form", { params });
    return fetch?.data?.data;
  }

  async getPeriode() {
    const fetch = await Services.get("/utility/periode-tahun?feat=setup-komponen-jaminan-hari-tua-bulanan");
    return fetch?.data?.data.map((val) => ({
      value: val?.periode_tahun,
      label: val?.periode_tahun
    }));
  }

  async getUnitOrganisasi() {
    const getUnit = await fetchDropdown("/master/unit-organisasi/dropdown", {
      value: "master_unit_organisasi_id",
      label: "master_unit_organisasi_nama"
    });
    return [...getUnit];
  }

  async getUnitOrganisasiForList() {
    const fetch = await Services.get("/transaksi/setup-komponen-jaminan-hari-tua-bulanan/dropdown-unit");
    return fetch?.data?.data.map((val) => ({
      value: val?.master_unit_organisasi_id,
      label: val?.master_unit_organisasi_nama
    }));
  }

  async getBulan() {
    return Array.from({ length: 12 }, (item, i) => {
      return {
        value: i + 1,
        label: new Date(0, i).toLocaleString("id-ID", { month: "long" })
      };
    });
  }

  async getTanggal(params) {
    const fetch = await Services.get("/transaksi/setup-komponen-jaminan-hari-tua-bulanan/dropdown-tanggal", { params });
    return fetch?.data?.data.map((val) => ({
      value: val?.tgl_bulan_awal + "/&/" + val?.tgl_bulan_akhir,
      label: val?.tgl_bulan_awal + " s/d " + val?.tgl_bulan_akhir,
      tglAwal: val?.tgl_bulan_awal,
      tglAkhir: val?.tgl_bulan_akhir
    }));
  }

  async getExport(params) {
    const fetch = await Services.get("/transaksi/setup-komponen-jaminan-hari-tua-bulanan/export", { params });
    return fetch?.data?.data;
  }
  create(data) {
    return Services.post("/transaksi/setup-komponen-jaminan-hari-tua-bulanan", data);
  }
}
export default new SetupKomponenJaminanHariTuaBulananApi();
