import { Services } from "services";
import { fetchDropdownMapper } from "utilities";

const fetchDropdown = fetchDropdownMapper(Services);

class PerhitunganPajakBulananApi {
  async getList(params) {
    const fetch = await Services.get("/transaksi/pekerja-pajak-bulanan", { params });
    return fetch?.data?.data;
  }

  async getListCreate(params) {
    const fetch = await Services.get("/transaksi/pekerja-pajak-bulanan/form", { params });
    return fetch?.data?.data;
  }

  async getSingle(id) {
    const fetch = await Services.get("/transaksi/pekerja-pajak-bulanan/" + id);
    return fetch?.data?.data;
  }

  async getBulan() {
    return Array.from({ length: 12 }, (item, i) => {
      return {
        value: i + 1,
        label: new Date(0, i).toLocaleString("id-ID", { month: "long" })
      };
    });
  }

  //Halaman Depan
  async getPeriodePenggajianHalden() {
    const getPeriodePenggajian = await fetchDropdown("/utility/periode-tahun?feat=pekerja-pajak-bulanan", {
      value: "periode_tahun",
      label: "periode_tahun"
    });

    return getPeriodePenggajian;
  }
  async getTanggalAwalDanAkhir(params) {
    const fetch = await Services.get("/transaksi/pekerja-pajak-bulanan/dropdown-tanggal", { params });
    return fetch?.data?.data?.map((val) => ({
      value: val?.tgl_bulan_awal + " s/d " + val?.tgl_bulan_akhir,
      label: val?.tgl_bulan_awal + " s/d " + val?.tgl_bulan_akhir,
      tglAwal: val?.tgl_bulan_awal,
      tglAkhir: val?.tgl_bulan_akhir
    }));
  }
  async getUnitOrganisasiHalden(id) {
    const getUnitOrganisasi = await fetchDropdown(
      "/transaksi/pekerja-pajak-bulanan/dropdown-unit?periode_tahun=" + id,
      {
        value: "master_unit_organisasi_id",
        label: "master_unit_organisasi_nama"
      }
    );

    return getUnitOrganisasi;
  }
  //Halaman Depan

  async getExport(params) {
    const fetch = await Services.get("/transaksi/pekerja-pajak-bulanan/export", { params });
    return fetch?.data?.data;
  }

  async getPeriodePenggajian() {
    const getPeriodePenggajian = await fetchDropdown("/master/grade-nominal/dropdown", {
      value: "periode_gajian_id",
      label: "periode_perencanaan"
    });

    return getPeriodePenggajian;
  }

  async getUnitOrganisasi() {
    const getUnitOrganisasi = await fetchDropdown("/master/unit-organisasi", {
      value: "master_unit_organisasi_id",
      label: "master_unit_organisasi_nama"
    });

    return getUnitOrganisasi;
  }

  async getPekerja(id) {
    const fetch = await Services.get("/transaksi/registrasi-pekerja?master_unit_organisasi_id=" + id);
    return fetch?.data?.data?.data?.map((val) => ({
      value: val.pekerja_id,
      label: val.pekerja_nama,
      idJabatan: val.master_jabatan_id,
      namaJabatan: val.master_jabatan_nama
    }));
  }

  async getJabatan() {
    const getJabatan = await fetchDropdown("/master/jabatan/dropdown", {
      value: "master_jabatan_id",
      label: "master_jabatan_nama"
    });

    return getJabatan;
  }
  async getKomponenUpah() {
    const getKomponenUpah = await fetchDropdown("/master/komponen-payroll/dropdown", {
      value: "master_komponen_payroll_id",
      label: "master_komponen_payroll_nama"
    });

    return getKomponenUpah;
  }

  getJenisKoreksi() {
    const Koreksi = [
      { value: 1, label: "Koreksi Minus" },
      { value: 2, label: "Koreksi Plus" }
    ];
    return Koreksi;
  }

  create(data) {
    return Services.post("/transaksi/pekerja-pajak-bulanan", data);
  }
}
export default new PerhitunganPajakBulananApi();
