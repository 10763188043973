import { Services } from "services";

class SetupProporsiUpahLemburPerjamApi {
  async getList(params) {
    const fetch = await Services.get("/master/proporsi-upah-lembur-perjam", { params });
    return fetch?.data?.data;
  }

  async getDetail(id) {
    const fetch = await Services.get("/master/proporsi-upah-lembur-perjam/" + id);
    return fetch?.data?.data;
  }

  async getPeriodePenggajian() {
    const fetch = await Services.get("/master/grade-nominal/dropdown");
    return fetch?.data?.data.map((val) => ({
      value: val?.periode_gajian_id,
      label: val?.periode_perencanaan
    }));
  }

  async getStatus() {
    return [
      {
        value: 1,
        label: "Aktif"
      },
      {
        value: 2,
        label: "Tidak Aktif"
      }
    ];
  }

  async getExport(params) {
    const fetch = await Services.get("/master/proporsi-upah-lembur-perjam/export", { params });
    return fetch?.data?.data;
  }

  create(data) {
    return Services.post("/master/proporsi-upah-lembur-perjam", data);
  }

  update(data, id) {
    return Services.put("/master/proporsi-upah-lembur-perjam/" + id, data);
  }

  delete(id) {
    return Services.delete("/master/proporsi-upah-lembur-perjam/" + id);
  }
}

export default new SetupProporsiUpahLemburPerjamApi();
