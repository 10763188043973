import { Services } from "services";

class CompensableFactor {
  async getList(params) {
    const fetch = await Services.get("/master/compensable-factors", { params });
    console.log("fetch?.data?.data", fetch?.data?.data);
    return fetch?.data?.data;
  }

  async getExport(params) {
    const fetch = await Services.get("/master/compensable-factors/export", { params });
    return fetch?.data?.data;
  }

  create(data) {
    return Services.post("/master/compensable-factors", data);
  }

  update(data, id) {
    return Services.put("/master/compensable-factors/" + id, data);
  }

  delete(id) {
    return Services.delete("/master/compensable-factors/" + id);
  }
}
export default new CompensableFactor();
