import {
  dateConvert, DatePicker, InfoItemVertical, Input, InputFileSingle, Select, TextArea
} from "components";
import { useFormikContext } from "formik";
import { useModalConfirm, useUploads } from "hooks";
import { Card, Col, ProgressBar, Row } from "react-bootstrap";
import { useQuery } from "react-query";
import { errorSubmitMapper, getFileLink } from "utilities";
import PhkApi from "../__PhkApi__";

export const SectionPhk = ({ action }) => {
  const { values, errors, touched, setValues, setFieldValue, handleChange } = useFormikContext();
  const modalConfirm = useModalConfirm();
  const date = dateConvert();
  const getAlasanPhk = useQuery(["alasan-phk", "dropdown"], PhkApi.getAlasanPhk);

  const getMasaKerja = () => {
    const jumlahHari = values?.masaKerja;

    const tahun = Math.floor(jumlahHari / 365.25);
    const sisaTahun = jumlahHari % 365.25;
    const bulan = Math.floor(sisaTahun / 30.44);
    const sisaBulan = sisaTahun % 30.44;
    const hari = Math.round(sisaBulan);

    return tahun + " Tahun " + bulan + " Bulan " + hari + " Hari";
  };

  const uploadSuratKeputusan = useUploads(PhkApi.linkUploadSuratKeputusan, {
    onSuccess: (val) => {
      const link = val?.data?.data;
      setValues({
        ...values,
        fileSuratKeputusan: link,
        fileSuratKeputusanFull: getFileLink({ link, temporary: true })
      });
    },
    onError: (err) =>
      modalConfirm.infoError({
        customTextInfoTitle: "Upload file surat keputusan bersama gagal",
        ...errorSubmitMapper(err),
        onHide: modalConfirm.close
      })
  });

  const uploadSuratKeterangan = useUploads(PhkApi.linkUploadSuratKeterangan, {
    onSuccess: (val) => {
      const link = val?.data?.data;
      setValues({
        ...values,
        fileSuratKeterangan: link,
        fileSuratKeteranganFull: getFileLink({ link, temporary: true })
      });
    },
    onError: (err) =>
      modalConfirm.infoError({
        customTextInfoTitle: "Upload file surat keterangan gagal",
        ...errorSubmitMapper(err),
        onHide: modalConfirm.close
      })
  });

  if (action === "READ") {
    return (
      <>
        <Card style={{ height: "100%" }}>
          <Card.Header>
            <b>Input PHK</b>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col md={6}>
                <InfoItemVertical label="Alasan PHK" text={values?.alasanPhk} />
                <InfoItemVertical
                  label="Tanggal Pemberhentian"
                  text={values?.tglPemberhentian ? date.getSlashDMY(new Date(values?.tglPemberhentian)) : "-"}
                />
                <InfoItemVertical label="Masa Kerja" text={getMasaKerja()} />
                <InfoItemVertical label="Keterangan" text={values?.keterangan} />
              </Col>
              <Col md={6}>
                <InfoItemVertical label="Surat Keputusan Bersama" text={values?.suratKeputusan} />
                <InfoItemVertical
                  label="File Surat Keputusan Bersama"
                  text={
                    <a href={values?.fileSuratKeputusanFull} target="_blank" rel="noreferrer">
                      Lihat File
                    </a>
                  }
                />
                <InfoItemVertical label="Surat Keterangan" text={values?.suratKeterangan} />
                <InfoItemVertical
                  label="File Surat Keterangan"
                  text={
                    <a href={values?.fileSuratKeteranganFull} target="_blank" rel="noreferrer">
                      Lihat File
                    </a>
                  }
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </>
    );
  }

  return (
    <>
      <Card style={{ height: "100%" }}>
        <Card.Header>
          <b>Input PHK</b>
        </Card.Header>
        <Card.Body>
          <Row className="mb-2">
            <Col>
              <InfoItemVertical label="Periode Penggajian" text={values?.periodePenggajian} />
              <InfoItemVertical
                label="Tanggal Setup"
                text={values?.tglSetup ? date.getSlashDMY(new Date(values?.tglSetup)) : "-"}
              />
              <InfoItemVertical label="Unit Organisasi" text={values?.unitOrganisasiNama} />
            </Col>
            <Col>
              <InfoItemVertical label="NIK" text={values?.nip} />
              <InfoItemVertical label="Pekerja" text={values?.pekerjaNama} />
              <InfoItemVertical label="Jabatan" text={values?.jabatanNama} />
              <InfoItemVertical
                label="Tanggal Pengangkatan"
                text={values?.tglPengangkatan ? date.getSlashDMY(new Date(values?.tglPengangkatan)) : "-"}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Select
                label="Alasan PHK"
                placeholder="Pilih periode"
                defaultValue={getAlasanPhk?.data?.find((item) => item.value === values?.alasanPhkId)}
                options={getAlasanPhk?.data ?? []}
                onChange={(val) =>
                  setValues({
                    ...values,
                    alasanPhkId: val.value,
                    alasanPhkKode: val.label,
                    alasanPhk: val.alasan
                  })
                }
                loading={getAlasanPhk.isFetching}
                errorFetch={getAlasanPhk.isError}
                error={Boolean(errors.alasanPhkId && touched.alasanPhkId)}
                errorText={Boolean(errors.alasanPhkId && touched.alasanPhkId) && errors.alasanPhkId}
              />
              <TextArea
                hidden={!values?.alasanPhk}
                name="alasanPhk"
                value={values?.alasanPhk}
                onChange={handleChange}
                error={Boolean(errors.alasanPhk && touched.alasanPhk)}
                errorText={Boolean(errors.alasanPhk && touched.alasanPhk) && errors.alasanPhk}
              />

              <DatePicker
                label="Tanggal Pemberhentian"
                placeholderText="Pilih tanggal pemberhentian"
                selected={values?.tglPemberhentian ? new Date(values.tglPemberhentian) : ""}
                onChange={(date) => {
                  setFieldValue("tglPemberhentian", date);
                  const start = new Date(values.tglPengangkatan);
                  const end = new Date(date);
                  const timeDifference = end.getTime() - start.getTime();

                  const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
                  setFieldValue("masaKerja", daysDifference);
                }}
                error={Boolean(errors.tglPemberhentian && touched.tglPemberhentian)}
                errorText={Boolean(errors.tglPemberhentian && touched.tglPemberhentian) && errors.tglPemberhentian}
              />
              <Input
                label="Masa Kerja"
                type="text"
                placeholder="Pilih tanggal pemberhentian"
                value={values?.tglPemberhentian ? getMasaKerja() : ""}
                disabled
              />
              <TextArea
                label="Keterangan"
                name="keterangan"
                placeholder="Masukkan keterangan"
                value={values?.keterangan}
                onChange={handleChange}
                error={Boolean(errors.keterangan && touched.keterangan)}
                errorText={Boolean(errors.keterangan && touched.keterangan) && errors.keterangan}
              />
            </Col>
            <Col md={6}>
              <Input
                label="Surat Keputusan Bersama"
                type="text"
                placeholder="Masukan nama surat keputusan bersama"
                name="suratKeputusan"
                value={values?.suratKeputusan}
                onChange={handleChange}
                error={Boolean(errors.suratKeputusan && touched.suratKeputusan)}
                errorText={Boolean(errors.suratKeputusan && touched.suratKeputusan) && errors.suratKeputusan}
              />
              <InputFileSingle
                label="File Surat Keputusan Bersama"
                onClick={(e) => (uploadSuratKeputusan.isLoading ? e.preventDefault() : {})}
                disabled={uploadSuratKeputusan.isLoading}
                link={values?.fileSuratKeputusanFull}
                preview={Boolean(values?.fileSuratKeputusanFull)}
                onChange={(val) => {
                  let formData = new FormData();
                  formData.append("file", val.data);
                  uploadSuratKeputusan.mutateAsync(formData);
                }}
                error={Boolean(errors.fileSuratKeputusan && touched.fileSuratKeputusan)}
                errorText={
                  Boolean(errors.fileSuratKeputusan && touched.fileSuratKeputusan) && errors.fileSuratKeputusan
                }
                acceptFileType={["pdf", "png", "jpg", "jpeg"]}
              />
              {uploadSuratKeputusan.isLoading && (
                <ProgressBar
                  now={uploadSuratKeputusan.progress}
                  label={`Mengunggah file ${uploadSuratKeputusan.progress}%`}
                />
              )}
              <Input
                label="Surat Keterangan PHK"
                type="text"
                placeholder="Masukan nama surat keterangan PHK"
                name="suratKeterangan"
                value={values?.suratKeterangan}
                onChange={handleChange}
                error={Boolean(errors.suratKeterangan && touched.suratKeterangan)}
                errorText={Boolean(errors.suratKeterangan && touched.suratKeterangan) && errors.suratKeterangan}
              />
              <InputFileSingle
                label="File Surat Keterangan PHK"
                onClick={(e) => (uploadSuratKeterangan.isLoading ? e.preventDefault() : {})}
                disabled={uploadSuratKeterangan.isLoading}
                link={values?.fileSuratKeteranganFull}
                preview={Boolean(values?.fileSuratKeteranganFull)}
                onChange={(val) => {
                  let formData = new FormData();
                  formData.append("file", val.data);
                  uploadSuratKeterangan.mutateAsync(formData);
                }}
                error={Boolean(errors.fileSuratKeterangan && touched.fileSuratKeterangan)}
                errorText={
                  Boolean(errors.fileSuratKeterangan && touched.fileSuratKeterangan) && errors.fileSuratKeterangan
                }
                acceptFileType={["pdf", "png", "jpg", "jpeg"]}
              />
              {uploadSuratKeterangan.isLoading && (
                <ProgressBar
                  now={uploadSuratKeterangan.progress}
                  label={`Mengunggah file ${uploadSuratKeterangan.progress}%`}
                />
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};
