import { useState } from "react";
import { ButtonGroup } from "react-bootstrap";
import { useQuery, useMutation } from "react-query";
import { Form, Formik } from "formik";
import { debounce } from "lodash";
import { ListLayout } from "layouts";
import {
  ButtonDetail,
  ButtonUpdate,
  ButtonDelete,
  ModalDetail,
  ModalCreate,
  ModalUpdate,
  toastTrigger,
  InputSearch,
  Select
} from "components";
import { useLayout, useModalConfirm, useAccessRights } from "hooks";
import { errorFetchingTableMapper, errorSubmitMapper, exportHandler } from "utilities";
import SasaranKPIApi from "./__SasaranKPIApi__";
import { formInitialValues, formValidationSchema, formSubmitMapper } from "./__SasaranKPIUtils__";
import { Content } from "./__SasaranKPIComps__";

export const SasaranKPIList = () => {
  const layout = useLayout();
  const access = useAccessRights("MASTER_SASARAN_KPI");
  const modalConfirm = useModalConfirm();
  const [searchKey, setSearchKey] = useState(undefined);
  const [pagination, setPagination] = useState({
    pageCurrent: 1,
    dataLength: 10
  });
  const [modal, setModal] = useState({
    show: false,
    action: "",
    data: {}
  });

  const [filter, setFilter] = useState({
    unit_organisasi_id: undefined
  });

  const getUnitOrganisasiDropdown = useQuery(["unit-organisasi", "dropdown"], SasaranKPIApi.getUnitOrganisasiDropdown);

  const getSasaranKPI = useQuery(
    ["sasaran-kpi", "list", searchKey, pagination.pageCurrent, pagination.dataLength, filter],
    () =>
      SasaranKPIApi.getList({
        search: searchKey,
        master_unit_organisasi_id: filter.unit_organisasi_id,
        page: pagination.pageCurrent,
        limit: pagination.dataLength
      })
  );

  const createSasaranKPI = useMutation((data) => SasaranKPIApi.create(data), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "create" });
      getSasaranKPI.refetch();
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "create", ...errorSubmitMapper(err) })
  });

  const updateSasaranKPI = useMutation(({ data, id }) => SasaranKPIApi.update(data, id), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "update" });
      getSasaranKPI.refetch();
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "update", ...errorSubmitMapper(err) })
  });

  const deleteSasaranKPI = useMutation((id) => SasaranKPIApi.delete(id), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "delete" });
      getSasaranKPI.refetch();
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "delete", ...errorSubmitMapper(err) })
  });

  const getExportSasaranKPI = useMutation((type) => SasaranKPIApi.getExport({ to: type }), {
    onSuccess: (link) => {
      toastTrigger({ variant: "success", message: "Export berhasil" });
      exportHandler(link, "Data Sasaran KPI");
    },
    onError: () => {
      toastTrigger({ variant: "danger", message: "Export gagal" });
    }
  });

  const searchHandler = (e) => {
    setSearchKey(e?.target?.value ? e.target.value : undefined);
  };

  const onHideModalHandler = () => setModal({ show: false, action: "", data: {} });

  const onCreateButtonClickHandler = () => setModal({ show: true, action: "CREATE", data: {} });

  const onDetailButtonClickHandler = (data) => setModal({ show: true, action: "READ", data: data });

  const onUpdateButtonClickHandler = (data) => setModal({ show: true, action: "UPDATE", data: data });

  const onPaginationChange = (data) => setPagination({ ...pagination, pageCurrent: data });

  const onPaginationDataLengthChange = (data) => setPagination({ ...pagination, dataLength: data });

  const submitValidationHandler = (errors) =>
    new Promise((resolve, reject) => {
      const getError = Object.values(errors);

      if (getError.length > 0) {
        reject(getError);
      } else {
        resolve();
      }
    });

  const preSubmitHandler = (formik) => {
    const { values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting } = formik;

    setSubmitting(true);
    validateForm().then(async (err) => {
      setErrors(err);
      setTouched(err);

      await submitValidationHandler(err, values)
        .then(() => handleSubmit())
        .catch((err) =>
          modalConfirm.trigger({
            type: "error",
            title: "Data Tidak Lengkap",
            data: err,
            onHide: () => modalConfirm.close()
          })
        )
        .finally(() => {
          setSubmitting(false);
        });
    });
  };

  const formSubmitHandler = (values, action) =>
    modalConfirm.trigger({
      type: action.toLowerCase(),
      onHide: modalConfirm.close,
      onSubmit: () => {
        modalConfirm.loading();

        if (action === "UPDATE") {
          updateSasaranKPI
            .mutateAsync({ data: formSubmitMapper(values), id: values.id })
            .then(() => onHideModalHandler());
        } else if (action === "DELETE") {
          deleteSasaranKPI.mutate(values.id);
        } else {
          createSasaranKPI.mutateAsync(formSubmitMapper(values)).then(() => onHideModalHandler());
        }
      },
      component: (
        <Formik initialValues={values}>
          <Content action="READ" />
        </Formik>
      )
    });

  return (
    <>
      {/**
       * List Layout:
       * Menampilkan nama menu, breadcrumb, search input, create & export button, tabel dll
       * search section & button section dapat direplace menggunakan props customLeftTopSection, customRightTopSection dll
       */}
      <ListLayout
        customLeftTopSection={() => (
          <div className="d-flex align-items-end mb-3">
            <div style={{ width: "100%" }} className="me-2">
              <InputSearch onChange={debounce(searchHandler, 1500)} />
            </div>
            <div style={{ width: "100%" }}>
              <Select
                noMargin
                label="Unit Organisasi"
                placeholder="Pilih salah satu"
                options={getUnitOrganisasiDropdown?.data ?? []}
                onChange={(val) => {
                  setFilter({
                    unit_organisasi_id: val.value
                  });
                }}
              />
            </div>
          </div>
        )}
        showExportExcelButton
        showExportPdfButton
        onExportExcelButtonClick={() => getExportSasaranKPI.mutate("excel")}
        onExportPdfButtonClick={() => getExportSasaranKPI.mutate("pdf")}
        loadingExportButton={getExportSasaranKPI.isLoading}
        showRightTopButton={access.canCreate() ? true : false}
        loading={getSasaranKPI.isFetching}
        error={getSasaranKPI.isError}
        errorText={errorFetchingTableMapper(getSasaranKPI.error)}
        dataTotal={getSasaranKPI?.data?.total}
        dataLength={pagination.dataLength}
        pageCurrent={pagination.pageCurrent}
        pageTotal={getSasaranKPI?.data?.last_page}
        onSearchChange={debounce(searchHandler, 1500)}
        onCreateButtonClick={onCreateButtonClickHandler}
        onDataLengthChange={onPaginationDataLengthChange}
        onPaginationChange={onPaginationChange}
        data={getSasaranKPI?.data?.data}
        tableHeader={[
          {
            text: "Unit Organisasi",
            props: {
              minWidth: 100
            }
          },
          {
            text: "Sasaran",
            props: {
              minWidth: 200
            }
          },
          {
            text: "Keterangan",
            props: {
              minWidth: 200
            }
          },
          {
            text: "Aksi",
            props: {
              width: 50,
              fixed: true
            }
          }
        ]}
        tableBody={[
          {
            field: "master_unit_organisasi_nama"
          },
          {
            field: "sasaran_nama"
          },
          {
            field: "keterangan"
          },
          {
            props: { fixed: true },
            customField: (data) => (
              <ButtonGroup size="sm">
                {access.canRead() && <ButtonDetail icon noText onClick={() => onDetailButtonClickHandler(data)} />}
                {access.canUpdate() && <ButtonUpdate icon noText onClick={() => onUpdateButtonClickHandler(data)} />}
                {access.canDelete() && (
                  <ButtonDelete icon noText onClick={() => formSubmitHandler(formInitialValues(data), "DELETE")} />
                )}
              </ButtonGroup>
            )
          }
        ]}
      />

      {/**
       * Modal Detail:
       * Digunakan untuk melihat detail data
       */}
      <Formik enableReinitialize initialValues={formInitialValues(modal.data)}>
        <ModalDetail
          title={layout.getActivePageName()}
          show={Boolean(modal.show && modal.action === "READ")}
          onHide={onHideModalHandler}
        >
          <Content action="READ" />
        </ModalDetail>
      </Formik>

      {/**
       * Modal Create:
       * Digunakan untuk menambah data
       */}
      <Formik
        enableReinitialize
        initialValues={formInitialValues({})}
        validationSchema={formValidationSchema}
        onSubmit={(values) => formSubmitHandler(values, "CREATE")}
      >
        {(formik) => {
          return (
            <Form>
              <ModalCreate
                title={layout.getActivePageName()}
                show={Boolean(modal.show && modal.action === "CREATE")}
                onHide={() => {
                  onHideModalHandler();
                  formik.resetForm();
                }}
                onSubmit={() => preSubmitHandler(formik)}
              >
                <Content action="CREATE" />
              </ModalCreate>
            </Form>
          );
        }}
      </Formik>

      {/**
       * Modal Update:
       * Modal untuk mengubah data
       */}
      <Formik
        enableReinitialize
        initialValues={formInitialValues(modal.data)}
        validationSchema={formValidationSchema}
        onSubmit={(values) => formSubmitHandler(values, "UPDATE")}
      >
        {(formik) => {
          return (
            <Form>
              <ModalUpdate
                title={layout.getActivePageName()}
                show={Boolean(modal.show && modal.action === "UPDATE")}
                onHide={() => {
                  onHideModalHandler();
                  formik.resetForm();
                }}
                onSubmit={() => preSubmitHandler(formik)}
              >
                <Content action="UPDATE" />
              </ModalUpdate>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
