import { useAuth } from "hooks";

/**
 * useAccessRights:
 * Custom hooks yang digunakan untuk menangani hak akses
 */
export const useAccessRights = (menu = "") => {
  const auth = useAuth();
  const accessRights = auth?.getAccessRights() ?? [];
  const parseMenu = menu?.toUpperCase();

  /**
   * Cek apakah sudah memiliki hak akses
   */
  const getAccess = accessRights.find((val) => val?.access === parseMenu);

  return {
    access: () => getAccess?.access,
    canRead: () => getAccess?.canRead,
    canCreate: () => getAccess?.canCreate,
    canUpdate: () => getAccess?.canUpdate,
    canDelete: () => getAccess?.canDelete,
    canVerify: () => getAccess?.canVerify,
    canApprove: () => getAccess?.canApprove
  };
};
