import * as Yup from "yup";

export const formValidationSchema = Yup.object().shape({
  // detail: Yup.array().of(
  //   Yup.object().shape({
  //     jadwalShiftId: Yup.string().required("Jadwal shift wajib diisi"),
  //     lokasiKerjaId: Yup.string().required("Lokasi kerja wajib diisi"),
  //     peranPekerjaId: Yup.string().required("Peran pekerja wajib diisi")
  //   })

  //   // Yup.object().shape({
  //   //   jadwalShiftId: Yup.string().when("status", {
  //   //     is: true,
  //   //     then: Yup.string().required("Jadwal shift wajib diisi")
  //   //   }),
  //   //   lokasiKerjaId: Yup.string().when("status", {
  //   //     is: true,
  //   //     then: Yup.string().required("Lokasi kerja wajib diisi")
  //   //   }),
  //   //   peranPekerjaId: Yup.string().when("status", {
  //   //     is: true,
  //   //     then: Yup.string().required("Peran pekerja wajib diisi")
  //   //   })
  //   // })
  // )
});
