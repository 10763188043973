import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { Formik } from "formik";
import { ContentLayout } from "layouts";
import { ButtonBack, DataStatus } from "components";
import { Content } from "./__TunjanganUpahHarianComps__";
import { formInitialValues } from "./__TunjanganUpahHarianUtilities__";
import TunjanganUpahHarianApi from "./__TunjanganUpahHarianApi__";

export const TunjanganUpahHarianDetail = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const getDetail = useQuery(
        [
            "detail",
            "tunjangan-upah-harian",
            id
        ],
        () =>
            TunjanganUpahHarianApi.getDetail(id)
    );


    return (
        <>
            <ContentLayout showBreadcrumb>
                {getDetail?.isFetching ? (
                    <DataStatus loading />
                ) : (
                    <>
                        <div className="text-end mb-3">
                            <ButtonBack tooltip={false} onClick={() => navigate("/payroll/transaksi/tunjangan-upah-harian")} />
                        </div>
                        <Formik
                            enableReinitialize
                            initialValues={formInitialValues.detailData(getDetail?.data)}
                        >
                            <Content
                                action="READ"
                                loading={getDetail.isFetching}
                            />
                        </Formik>
                    </>
                )}
            </ContentLayout>
        </>
    );
};
