import { SetupUpahPokokTahunanList, SetupUpahPokokTahunanCreate } from "pages/Payroll";

export const SetupUpahPokokTahunan = {
  component: <SetupUpahPokokTahunanList />,
  link: "setup-upah-pokok-tahunan",
  name: "Setup Upah Pokok Tahunan",
  access: "SETUP_UPAH_POKOK_PEKERJA",
  child: [
    {
      name: "Tambah",
      link: "/tambah",
      component: <SetupUpahPokokTahunanCreate />
    }
  ]
};
