import React from "react";
import { useFormikContext } from "formik";
import { Input, InfoItemVertical, TextArea } from "components";

export const Content = ({ action }) => {
  const { values, errors, touched, handleChange } = useFormikContext();

  if (action === "READ") {
    return (
      <>
        <InfoItemVertical label="Nama Jenis Pekerja" text={values.nama} />
        <InfoItemVertical label="Keterangan" text={values.keterangan} />
      </>
    );
  }

  return (
    <>
      <Input
        label="Nama Jenis Pekerja"
        name="nama"
        placeholder="Masukkan nama jenis pekerja"
        value={values.nama}
        onChange={handleChange}
        error={Boolean(errors.nama && touched.nama)}
        errorText={Boolean(errors.nama && touched.nama) && errors.nama}
      />
      <TextArea
        label="Keterangan"
        name="keterangan"
        placeholder="Masukkan keterangan"
        value={values.keterangan}
        onChange={handleChange}
        error={Boolean(errors.keterangan && touched.keterangan)}
        errorText={Boolean(errors.keterangan && touched.keterangan) && errors.keterangan}
      />
    </>
  );
};
