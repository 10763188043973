import * as Yup from "yup";

export const formValidationSchema = {
  FormSearch: Yup.object().shape({
    idPeriode: Yup.string().required("Periode wajib diisi"),
    tglSetup: Yup.string().required("Tanggal Setup wajib diisi"),
    idUnitOrganisasi: Yup.string().required("Unit Organisasi wajib diisi"),
    idBulan: Yup.string().required("Bulan wajib diisi"),
    tglAwal: Yup.string().required("Tanggal awal wajib diisi"),
    tglAkhir: Yup.string().required("Tanggal akhir wajib diisi")
  })
};
