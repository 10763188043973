import { Services, ServicesUploads } from "services";

class UploadPengirimanBetonApi {
  async getList(params) {
    const fetch = await Services.get("/transaksi/pengiriman-beton", { params });
    return fetch?.data?.data;
  }

  async getDetail(id) {
    const fetch = await Services.get("/transaksi/pengiriman-beton/" + id);
    return fetch?.data?.data;
  }

  async getSetupForm(params) {
    const fetch = await Services.get("/transaksi/pengiriman-beton/form", { params });
    return fetch?.data;
  }

  async getUnitOrganisasi(type) {
    const fetch = await Services.get("/master/unit-organisasi/dropdown");
    const dataOrganisasi = fetch?.data?.data.map((val) => ({
      value: val?.master_unit_organisasi_id,
      label: val?.master_unit_organisasi_nama
    }));

    if (type === "FOR_LIST") {
      return [{ value: undefined, label: "Semua" }, ...dataOrganisasi];
    }
    return dataOrganisasi;
  }

  async getUnitOrganisasiForList() {
    const fetch = await Services.get("/transaksi/pengiriman-beton/dropdown-unit");
    return fetch?.data?.data.map((val) => ({
      value: val?.master_unit_organisasi_id,
      label: val?.master_unit_organisasi_nama
    }));
  }

  async getPeriode() {
    const fetch = await Services.get("/transaksi/pengiriman-beton/dropdown-periode");
    return fetch?.data?.data.map((val) => ({
      value: val?.periode_tahun,
      label: val?.periode_tahun
    }));
  }

  async getPekerja() {
    const fetch = await Services.get("/transaksi/registrasi-pekerja/dropdown");
    return fetch?.data?.data.map((val) => ({
      value: val?.pekerja_id,
      label: val?.pekerja_nama
    }));
  }

  async getJenisRitase() {
    const fetch = await Services.get("/master/jenis-ritase/dropdown");
    return fetch?.data?.data.map((val) => ({
      value: val?.jenis_ritase_id,
      label: val?.jenis_ritase_nama,
      kode: val?.jenis_ritase_kode
    }));
  }

  async getStatus() {
    return [
      {
        value: 1,
        label: "Belum diposting"
      },
      {
        value: 2,
        label: "Sudah diposting"
      }
    ];
  }

  async getBulan() {
    return Array.from({ length: 12 }, (item, i) => {
      return {
        value: i + 1,
        label: new Date(0, i).toLocaleString("id-ID", { month: "long" })
      };
    });
  }

  async getBulanForList(params) {
    const fetch = await Services.get("/transaksi/pengiriman-beton/dropdown-bulan", { params });
    return fetch?.data?.data.map((val) => ({
      value: val?.periode_bulan,
      label: val?.bulan
    }));
  }

  async getTanggal(params) {
    const fetch = await Services.get("/transaksi/pengiriman-beton/dropdown-tanggal", { params });
    return fetch?.data?.data.map((val) => ({
      value: val?.tgl_bulan_awal + "/&/" + val?.tgl_bulan_akhir,
      label: val?.tgl_bulan_awal + " s/d " + val?.tgl_bulan_akhir,
      tglAwal: val?.tgl_bulan_awal,
      tglAkhir: val?.tgl_bulan_akhir
    }));
  }

  async getExport(params) {
    const fetch = await Services.get("/transaksi/pengiriman-beton/export", { params });
    return fetch?.data?.data;
  }

  create(data) {
    console.log("API", data);
    return ServicesUploads.post("/transaksi/pengiriman-beton", data);
  }

  update(data, id) {
    return Services.put("/transaksi/pengiriman-beton/" + id, data);
  }

  delete(id) {
    return Services.delete("/transaksi/pengiriman-beton/" + id);
  }

  linkUploadData = "/transaksi/pengiriman-beton";
}

export default new UploadPengirimanBetonApi();
