import { Services } from "services";
class KondisiKerjaApi {
  async getList(params) {
    const fetch = await Services.get("/master/kondisi-kerja", { params });
    return fetch?.data?.data;
  }

  async getExport(params) {
    const fetch = await Services.get("/master/kondisi-kerja/export", { params });
    return fetch?.data?.data;
  }

  create(data) {
    return Services.post("/master/kondisi-kerja", data);
  }

  update(data, id) {
    return Services.put("/master/kondisi-kerja/" + id, data);
  }

  delete(id) {
    return Services.delete("/master/kondisi-kerja/" + id);
  }
}
export default new KondisiKerjaApi();
