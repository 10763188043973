export const formInitialValues = (data = {}) => ({
  id: data?.tarif_pph21_id,
  periodeGajianId: data?.periode_tahun ?? new Date().getFullYear(),
  periodeGajian: data?.periode_tahun ?? new Date().getFullYear(),
  kodePPh21: data?.kode_tarif_pph21,
  penghasilanTerendah: data?.penghasilan_terendah,
  penghasilanTertinggi: data?.penghasilan_tertinggi,
  persentasePPh21: data?.persentase_pph21,
  status: data?.status,
  keterangan: data?.keterangan
});
