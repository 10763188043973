import { Services } from "services";

class UmurPensiunApi {
  async getList(params) {
    const fetch = await Services.get("/master/umur-pensiun", { params });
    return fetch?.data?.data;
  }
  async getDropdownPeriode(params) {
    const fetch = await Services.get("/master/grade-nominal/dropdown", { params });
    return fetch?.data?.data?.map((val) => ({ label: val?.periode_perencanaan, value: val?.periode_gajian_id }));
  }
  async getDetail(id) {
    const fetch = await Services.get("/master/umur-pensiun/" + id);
    return fetch?.data?.data;
  }
  async getExport(params) {
    const fetch = await Services.get("/master/umur-pensiun/export", { params });
    return fetch?.data?.data;
  }
  create(data) {
    return Services.post("/master/umur-pensiun", data);
  }
  update(data, id) {
    return Services.put("/master/umur-pensiun/" + id, data);
  }
  delete(id) {
    return Services.delete("/master/umur-pensiun/" + id);
  }
}

export default new UmurPensiunApi();
