import {
    ButtonDetail, ButtonFilter, ButtonUpdate, InputSearch, ModalDetail, ModalFilter, ModalUpdate, numberConvert,
    Select, toastTrigger, useLayout
} from "components";
import { Form, Formik, useFormikContext } from "formik";
import { useAccessRights, useModalConfirm } from "hooks";
import { ListLayout } from "layouts";
import { debounce } from "lodash";
import { useState } from "react";
import { ButtonGroup } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { errorSubmitMapper, exportHandler } from "utilities";
import SetupUpahTetapBpjsApi from "./__SetupUpahTetapBpjsApi__";
import { Content } from "./__SetupUpahTetapBpjsComps__";
import { formInitialValues, formSubmitValueMapper, formValidationSchema } from "./__SetupUpahTetapBpjsUtilities__";


const ModalFilterBody = () => {
    const { values, setValues } = useFormikContext();

    const getPeriode = useQuery(["periode-penggajian", "dropdown"], () => SetupUpahTetapBpjsApi.getPeriode());

    const getUnitOrganisasi = useQuery(
        ["unit-organisasi-for-list", "dropdown", values?.periodeGajianId],
        () =>
            SetupUpahTetapBpjsApi.getUnitOrganisasiForList({
                periode_tahun: values?.periodeGajianId
            }),
        { enabled: Boolean(values?.periodeGajianId) }
    );

    return (
        <>
            <Select
                label="Periode Penggajian"
                placeholder="Pilih periode penggajian"
                defaultValue={getPeriode?.data?.find((item) => item.value === values?.periodeGajianId)}
                options={getPeriode?.data ?? []}
                onChange={(val) => setValues({ ...values, periodeGajianId: val.value, periodePerencanaan: val.label })}
                loading={getPeriode.isFetching}
                errorFetch={getPeriode.isError}
            />
            <Select
                disable={Boolean(!values?.periodeGajianId)}
                label="Unit Organisasi"
                placeholder="Pilih unit organisasi"
                defaultValue={getUnitOrganisasi?.data?.find((item) => item.value === values.unitOrganisasiId)}
                options={getUnitOrganisasi?.data ?? []}
                onChange={(val) => setValues({ ...values, unitOrganisasiId: val.value })}
                loading={getUnitOrganisasi.isFetching}
                errorFetch={getUnitOrganisasi.isError}
            />
        </>
    );
};

export const SetupUpahTetapBpjsList = () => {
    const access = useAccessRights("PEKERJA_UPAH_BPJS");
    const navigate = useNavigate();
    const number = numberConvert();
    const layout = useLayout();
    const modalConfirm = useModalConfirm();
    // const date = dateConvert();

    const [searchKey, setSearchKey] = useState(undefined);
    const [pagination, setPagination] = useState({
        pageCurrent: 1,
        dataLength: 10
    });

    const [filter, setFilter] = useState({
        show: false,
        active: false,
        data: {
            periodeGajianId: undefined,
            unitOrganisasiId: undefined
        }
    });

    const [modal, setModal] = useState({
        show: false,
        action: "",
        data: {}
    });

    const getSetupUpahTetapBpjsList = useQuery(
        [
            "upah-tetap-bpjs",
            "list",
            searchKey,
            pagination.pageCurrent,
            pagination.dataLength,
            filter.data?.periodeGajianId,
            filter.data?.unitOrganisasiId
        ],
        () =>
            SetupUpahTetapBpjsApi.getList({
                search: searchKey,
                page: pagination.pageCurrent,
                limit: pagination.dataLength,
                periode_tahun: filter.data?.periodeGajianId,
                master_unit_organisasi_id: filter.data?.unitOrganisasiId,
            }),
        {
            enabled: Boolean(
                filter?.data?.periodeGajianId
            )
        }
    );

    // const getSetupUpahTetapBpjsDetail = useQuery(
    //     ["upah-tetap-bpjs", "detail"],
    //     () => SetupUpahTetapBpjsApi.getDetail(modal?.data?.pekerja_upah_bpjs_id),
    //     {
    //         enabled: Boolean(modal?.data?.pekerja_upah_bpjs_id)
    //     }
    // );

    const updateSetupUpahTetapBpjs = useMutation(({ data, id }) => SetupUpahTetapBpjsApi.update(data, id), {
        onSuccess: () => {
            modalConfirm.infoSuccess({ typeInfo: "update" });
            getSetupUpahTetapBpjsList.refetch();
        },
        onError: (err) => modalConfirm.infoError({ typeInfo: "update", ...errorSubmitMapper(err) })
    });

    const deleteSetupUpahTetapBpjs = useMutation((id) => SetupUpahTetapBpjsApi.delete(id), {
        onSuccess: () => {
            modalConfirm.infoSuccess({ typeInfo: "delete" });
            getSetupUpahTetapBpjsList.refetch();
        },
        onError: (err) => modalConfirm.infoError({ typeInfo: "delete", ...errorSubmitMapper(err) })
    });

    const getSetupUpahTetapBpjsExport = useMutation(
        (data) =>
            SetupUpahTetapBpjsApi.getExport({
                master_unit_organisasi_id: data?.unitOrganisasiId,
                periode_tahun: data?.periodeGajianId
            }),
        {
            onSuccess: (link) => {
                toastTrigger({ variant: "success", message: "Export berhasil" });
                exportHandler(link, "Data Upah Tetap BPJS");
            },
            onError: () => {
                toastTrigger({ variant: "danger", message: "Export gagal" });
            }
        }
    );

    const onHideModalHandler = () => setModal({ show: false, action: "", data: {} });
    const onCreateButtonClickHandler = () => navigate("tambah");
    const onUpdateButtonClickHandler = (data) => setModal({ show: true, action: "UPDATE", data: data });
    const onDetailButtonClickHandler = (data) => setModal({ show: true, action: "READ", data: data });

    const onPaginationChange = (data) => setPagination({ ...pagination, pageCurrent: data });

    const onPaginationDataLengthChange = (data) => setPagination({ ...pagination, dataLength: data });

    const searchHandler = (e) => {
        setSearchKey(e?.target?.value ? e.target.value : undefined);
    };

    const submitValidationHandler = (errors) =>
        new Promise((resolve, reject) => {
            const getError = Object.values(errors);

            if (getError.length > 0) {
                reject(getError);
            } else {
                resolve();
            }
        });

    const preSubmitHandler = (formik) => {
        const { values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting } = formik;

        setSubmitting(true);
        validateForm().then(async (err) => {
            setErrors(err);
            setTouched(err);

            await submitValidationHandler(err, values)
                .then(() => handleSubmit())
                .catch((err) =>
                    modalConfirm.trigger({
                        type: "error",
                        title: "Data Tidak Lengkap",
                        data: err,
                        onHide: () => modalConfirm.close()
                    })
                )
                .finally(() => {
                    setSubmitting(false);
                });
        });
    };

    const formSubmitHandler = (values, action) =>
        modalConfirm.trigger({
            type: action.toLowerCase(),
            onHide: modalConfirm.close,
            onSubmit: () => {
                modalConfirm.loading();

                if (action === "UPDATE") {
                    updateSetupUpahTetapBpjs
                        .mutateAsync({ data: formSubmitValueMapper(values).object, id: values.id })
                        .then(() => onHideModalHandler());
                } else if (action === "DELETE") {
                    deleteSetupUpahTetapBpjs.mutate(values.id);
                }
            },
            component: (
                <Formik initialValues={values}>
                    {/* <Content action="READ" /> */}
                </Formik>
            )
        });

    const TABLE_HEADER = [
        [
            {
                text: "No",
                props: {
                    rowSpan: 2
                }
            },
            {
                text: "NIK",
                props: {
                    rowSpan: 2
                }
            },
            {
                text: "Pekerja",
                props: {
                    rowSpan: 2
                }
            },
            {
                text: "Jabatan",
                props: {
                    rowSpan: 2
                }
            },
            {
                text: "Unit Organisasi",
                props: {
                    rowSpan: 2
                }
            },
            {
                text: "Status Pekerja",
                props: {
                    rowSpan: 2
                }
            },
            {
                text: "Upah Tetap BPJS",
                props: {
                    colSpan: 3
                }
            },
            {
                text: "Status Upah",
                props: {
                    rowSpan: 2
                }
            },
            {
                text: "Aksi",
                props: {
                    fixed: true,
                    rowSpan: 2
                }
            }
        ],
        [
            {
                props: { width: 150 },
                text: "Kesehatan"
            },
            {
                props: { width: 150 },
                text: "Tenaga Kerja"
            },
            {
                props: { width: 150 },
                text: "Bukan Penerima Upah"
            },
        ]
    ];

    const TABLE_BODY = [
        { field: "nip" },
        { field: "pekerja_nama" },
        { field: "master_jabatan_nama" },
        { field: "master_unit_organisasi_nama" },
        { field: "status_pekerja" },
        {
            props: { textRight: true },
            customField: (val) => "Rp" + number.getWithComa(val?.upah_bpjs_kesehatan, { minimumFractionDigits: 2 })
        },
        {
            props: { textRight: true },
            customField: (val) => "Rp" + number.getWithComa(val?.upah_bpjs_tenaga_kerja, { minimumFractionDigits: 2 })
        },
        {
            props: { textRight: true },
            customField: (val) => "Rp" + number.getWithComa(val?.upah_bpjs_bpu, { minimumFractionDigits: 2 })
        },
        { customField: (val) => val?.status === 1 ? "Aktif" : "Tidak Aktif" },
        {
            props: {
                width: 50,
                fixed: true,
                textCenter: true
            },
            customField: (data) => (
                <ButtonGroup size="sm">
                    {access.canRead() && (
                        <ButtonDetail icon tooltip={false} noText onClick={() => onDetailButtonClickHandler(data)} />
                    )}
                    {access.canUpdate() && (
                        <ButtonUpdate tooltip={false} icon noText onClick={() => onUpdateButtonClickHandler(data)} />
                    )}
                    {/* {access.canDelete() && (
                        <ButtonDelete
                            tooltip={false}
                            icon
                            noText
                            onClick={() => formSubmitHandler(formInitialValues(data).detailData, "DELETE")}
                        />
                    )} */}
                </ButtonGroup>
            )
        }
    ];

    return (
        <>
            <ListLayout
                customLeftTopSection={() => (
                    <>
                        <div className="d-flex align-items-end mb-3">
                            <div style={{ width: 300 }} className="me-2">
                                <InputSearch onChange={debounce(searchHandler, 1500)} />
                            </div>
                            <div>
                                <ButtonFilter
                                    active={filter?.active}
                                    className="text-nowrap"
                                    onClick={() => setFilter({ ...filter, show: true })}
                                />
                            </div>
                        </div>
                    </>
                )}
                showRightTopButton={access.canCreate() ? true : false}
                showExportExcelButton={Boolean(filter?.data?.periodeGajianId && filter?.data?.unitOrganisasiId)}
                onExportExcelButtonClick={() => getSetupUpahTetapBpjsExport.mutate(filter?.data)}
                loadingExportButton={getSetupUpahTetapBpjsExport.isLoading}
                loading={getSetupUpahTetapBpjsList?.isFetching}
                error={getSetupUpahTetapBpjsList?.isError}
                dataTotal={getSetupUpahTetapBpjsList?.data?.total}
                dataLength={pagination.dataLength}
                pageCurrent={pagination.pageCurrent}
                pageTotal={getSetupUpahTetapBpjsList?.data?.last_page}
                onSearchChange={debounce(searchHandler, 1500)}
                onCreateButtonClick={onCreateButtonClickHandler}
                onDataLengthChange={onPaginationDataLengthChange}
                onPaginationChange={onPaginationChange}
                data={getSetupUpahTetapBpjsList?.data?.data}
                multipleTableHeader={TABLE_HEADER}
                tableBody={TABLE_BODY}
            />

            {/**
       * Modal Detail:
       * Digunakan untuk melihat detail data
       */}
            <Formik enableReinitialize initialValues={formInitialValues(modal?.data).detailData}>
                <ModalDetail
                    title={layout.getActivePageName()}
                    show={Boolean(modal.show && modal.action === "READ")}
                    onHide={onHideModalHandler}
                >
                    <Content action="READ" />
                </ModalDetail>
            </Formik>

            {/**
       * Modal Update:
       * Modal untuk mengubah data
       */}
            <Formik
                enableReinitialize
                initialValues={formInitialValues(modal?.data).detailData}
                validationSchema={formValidationSchema}
                onSubmit={(values) => formSubmitHandler(values, "UPDATE")}
            >
                {(formik) => {
                    return (
                        <Form>
                            <ModalUpdate
                                title={layout.getActivePageName()}
                                show={Boolean(modal.show && modal.action === "UPDATE")}
                                onHide={() => {
                                    onHideModalHandler();
                                    formik.resetForm();
                                }}
                                onSubmit={() => preSubmitHandler(formik)}
                            >
                                <Content action="UPDATE" />
                            </ModalUpdate>
                        </Form>
                    );
                }}
            </Formik>

            {/* ModalFilter */}
            <Formik
                initialValues={{
                    unitOrganisasiId: filter?.data?.unitOrganisasiId,
                    periodeGajianId: filter?.data?.periodeGajianId,
                    tglAwal: filter?.data?.tglAwal,
                    tglAkhir: filter?.data?.tglAkhir
                }}
            >
                {({ resetForm, values }) => (
                    <ModalFilter
                        show={filter.show}
                        onReset={resetForm}
                        onHide={() =>
                            setFilter({
                                ...filter,
                                show: false
                            })
                        }
                        onSubmit={() => {
                            setFilter({
                                show: false,
                                active: Boolean(Object.values(values).find((val) => val)),
                                data: values
                            });
                        }}
                    >
                        <ModalFilterBody />
                    </ModalFilter>
                )}
            </Formik>
        </>
    );
};
