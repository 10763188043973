import { useNavigate, useParams } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import { Formik } from "formik";
import { ContentLayout } from "layouts";
import { errorSubmitMapper } from "utilities";
import { ButtonBack, DataStatus, useModalConfirm, ButtonUpdate } from "components";
import { Content } from "./__SuratPenugasanKhususComps__";
import { formInitialValues, formSubmitValueMapper, formValidationSchema } from "./__SuratPenugasanKhususUtilities__";
import SuratPenugasanKhususApi from "./__SuratPenugasanKhususApi__";

export const SuratPenugasanKhususUpdate = () => {
  const { id } = useParams();
  const modalConfirm = useModalConfirm();
  const navigate = useNavigate();

  const getDetail = useQuery(["surat-penugasan-khusus", "detail", id], () => SuratPenugasanKhususApi.getDetail(id));

  const updateData = useMutation((data) => SuratPenugasanKhususApi.update(data, id), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "update" });
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "update", ...errorSubmitMapper(err) })
  });

  const submitValidationHandler = (errors) =>
    new Promise((resolve, reject) => {
      const getError = Object.values(errors);

      if (getError.length > 0) {
        reject(getError);
      } else {
        resolve();
      }
    });

  const preSubmitHandler = (values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting) => {
    setSubmitting(true);
    validateForm().then(async (err) => {
      setErrors(err);
      setTouched(err);

      await submitValidationHandler(err, values)
        .then(() => handleSubmit())
        .catch((err) =>
          modalConfirm.trigger({
            type: "error",
            title: "Data Tidak Lengkap",
            data: err,
            onHide: () => modalConfirm.close()
          })
        )
        .finally(() => {
          setSubmitting(false);
        });
    });
  };

  const formSubmitHandler = async (values) => {
    return await modalConfirm.trigger({
      type: "update",
      onHide: modalConfirm.close,
      onSubmit: () => {
        modalConfirm.loading();
        updateData.mutateAsync(formSubmitValueMapper(values));
      }
    });
  };

  return (
    <>
      <ContentLayout showBreadcrumb>
        {getDetail?.isFetching ? (
          <DataStatus loading />
        ) : (
          <>
            <div className="text-end mb-3">
              <ButtonBack
                tooltip={false}
                onClick={() => navigate("/operasional-sdm/transaksi/surat-penugasan-khusus")}
              />
            </div>
            <Formik
              enableReinitialize
              initialValues={formInitialValues(getDetail?.data)}
              validationSchema={formValidationSchema}
              onSubmit={(values) => formSubmitHandler(values)}
            >
              {({ values, handleSubmit, validateForm, setTouched, setErrors, setSubmitting }) => (
                <>
                  <Content action="UPDATE" />
                  <div className="text-end mb-3 mt-3">
                    <ButtonUpdate
                      tooltip={false}
                      onClick={() =>
                        preSubmitHandler(values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting)
                      }
                    />
                  </div>
                </>
              )}
            </Formik>
          </>
        )}
      </ContentLayout>
    </>
  );
};
