export const formSubmitValueMapper = (data) => ({
    array: data?.detail
        ?.filter((obj) => {
            return obj.checked;
        })
        .map((val) => ({
            periode_tahun: val?.periodeGajianId,
            pekerja_id: val?.pekerjaId,
            master_unit_organisasi_id: val?.unitOrganisasiId,
            upah_bpjs: val?.nominal,
        })),
    object: {
        periode_tahun: data?.periodeGajianId,
        pekerja_id: data?.pekerjaId,
        master_unit_organisasi_id: data?.unitOrganisasiId,
        upah_bpjs: data?.nominal,
        status: data?.status,
        upah_bpjs_kesehatan: data?.kesehatanNominal,
        upah_bpjs_tenaga_kerja: data?.tenagaKerjaNominal,
        upah_bpjs_bpu: data?.bpuNominal
    },
    create: data?.dataImport
});
