const dataTglHeader = (maxWeek, dataTgl) => {
  let obj = {};
  for (let i = 1; i <= maxWeek; i++) {
    obj['minggu' + i] = dataTgl["minggu_" + i]?.map((val) => ({
      day: val?.hari,
      date: val?.tgl
    }))
  }
  return obj;
}

export const formInitialValues = {
  dataFormSetup: (data = {}, filter) => ({
    id: data?.setup_jadwal_id,
    periodeGajianId: data?.info_request?.periode_tahun ?? filter?.periodeGajianId ?? new Date().getFullYear(),
    periodePerencanaan: data?.info_request?.periode_tahun ?? new Date().getFullYear(),
    pekerjaId: data?.info_request?.pekerja_id ?? filter?.pekerjaId,
    pekerjaNama: data?.pekerja_nama ?? filter?.pekerjaNama,
    nik: data?.nip,
    bulan: filter?.bulan,
    tglAwal: data?.tgl_bulan_awal ?? filter?.tglAwal,
    tglAkhir: data?.tgl_bulan_akhir ?? filter?.tglAkhir,
    tglSetup: data?.tgl_setup ?? filter?.tglSetup ?? new Date(),
    unitOrganisasiId: data?.master_unit_organisasi_id ?? filter?.unitOrganisasiId ?? "",
    unitOrganisasiNama: data?.master_unit_organisasi_nama ?? filter?.unitOrganisasiNama ?? "",
    jabatanId: data?.master_jabatan_id ?? filter?.jabatanId,
    jabatanNama: data?.master_jabatan_nama ?? filter?.jabatanNama,
    checked: (data?.info_request?.reguler_shift === "1" ? true : false) ?? false,
    regularShift: data?.info_request?.reguler_shift ?? filter?.data?.regularShift ?? 2,
    weekNumber: data?.info_request?.week_number ?? filter?.data?.weekNumber ?? 1,
    maxWeek: data?.info_request?.maks_week,
    tabKey: filter?.data?.tabKey,

    tglHeader: data?.header_tanggal?.map((val) => ({
      day: val?.hari,
      date: val?.tgl
    })) ?? [],

    detail:
      data?.pekerja?.map((val) => ({
        pekerjaId: val?.pekerja_id,
        pekerjaNama: val?.pekerja_nama,
        jabatanId: val?.master_jabatan_id,
        jadwal: val?.jadwal?.map((item) => ({
          weekNumber: item?.week_number,
          dates: item?.dates?.map((x) => ({
            tgl: x.tgl,
            isDisabled: x.disabled,
            shiftId: x.master_shift_id,
            shiftNama: x.shift_nama,
            weekOrder: x.week_order
          })) ?? []
        })) ?? []
      })) ?? [],

    tabs: data?.tabs?.map((val) => ({
      weekNumber: val?.week_number,
      weekName: val?.week_name
    }))
  }),

  detailData: (data = {}, filter, action = "READ") => ({
    id: data?.setup_jadwal_id,
    periodeGajianId: data?.periode_tahun,
    periodePerencanaan: data?.periode_tahun,
    pekerjaId: parseInt(data?.pekerja_id),
    pekerjaNama: data?.pekerja_nama,
    nik: data?.nip,
    bulan: parseInt(data?.bulan),
    tglAwal: data?.tgl_bulan_awal,
    tglAkhir: data?.tgl_bulan_akhir,
    tglSetup: data?.tgl_setup,

    unitOrganisasiId: data?.master_unit_organisasi_id ?? filter?.unitOrganisasiId ?? "",
    unitOrganisasiNama: data?.master_unit_organisasi_nama ?? filter?.unitOrganisasiNama ?? "",
    jabatanId: data?.master_jabatan_id ?? filter?.jabatanId,
    jabatanNama: data?.master_jabatan_nama ?? filter?.jabatanNama,

    checked: (data?.reguler_shift === "1" ? true : false) ?? false,
    regularShift: data?.reguler_shift ?? filter?.data?.regularShift ?? 2,
    weekNumber: data?.week_number ?? filter?.data?.weekNumber ?? 1,
    maxWeek: data?.maks_week,
    tabKey: filter?.data?.tabKey,

    tglHeader: action !== "READ" ? (
      data?.header_tanggal?.map((val) => ({
        day: val?.hari,
        date: val?.tgl
      })) ?? []
    ) : (dataTglHeader(data?.maks_week, data?.header_tanggal)),

    detail: [
      {
        pekerjaId: data?.pekerja_id,
        pekerjaNama: data?.pekerja_nama,
        jabatanId: data?.master_jabatan_id,
        jabatanNama: data?.master_jabatan_nama,
        jadwal: data?.jadwal?.map((item) => ({
          weekNumber: item?.week_number,
          dates: item?.dates?.map((x) => ({
            tgl: x.tgl,
            isDisabled: x.disabled,
            shiftId: x.master_shift_id,
            shiftNama: x.shift_nama,
            weekOrder: x.week_order
          })) ?? []
        })) ?? []
      }
    ],

    tabs: data?.tabs?.map((val) => ({
      weekNumber: val?.week_number,
      weekName: val?.week_name
    }))
  })
}

