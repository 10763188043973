export const formSubmitMapper = {
  Form: (values = {}) => ({
    periode_tahun: values?.idPeriode,
    tgl_setup: values?.tglSetup,
    jenis_upah_id: values?.idJenisUpahMinimum,
    komponen_bpjs_id: values?.idKomponenBpjs,
    jaminan_perusahaan: values?.proporsiPerusahaan,
    jaminan_pekerja: values?.proporsiPekerja,
    nominal_upah_minimum_id: values?.idNominal,
    batas_atas: values?.batasAtasUpah,
    keterangan: values?.keterangan
  })
};
