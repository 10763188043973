import { useFormikContext } from "formik";
import React from "react";
import { useQuery } from "react-query";
import {
    dateConvert,
    TableList,
    Select
} from "components";
import SetupJadwalApi from "../__SetupJadwalApi__";

export const TabSetupJadwal = ({ action, mingguKeI }) => {
    const { values, setValues, errors, touched } = useFormikContext();
    const date = dateConvert();

    const getShift = useQuery(["shift", "dropdown"], () =>
        SetupJadwalApi.getShift()
    );

    const somethingArray = [];

    const setNewValues = ({ index, propname, indexDate, val }) => {
        const detailTemp = [...values?.detail];
        const updatedDetail = detailTemp[index].jadwal[0].dates[indexDate];
        updatedDetail[propname] = val;

        setValues({ ...values, detail: detailTemp });
    };

    const arrayforTglHeader = action !== "READ" ? (
        values?.tglHeader?.reduce((prev, curr) => {
            let obj = {
                text: curr.day + " \n" + date.getSlashDMY(new Date(curr?.date))
            };
            return [...prev, obj];
        }, [])
    ) : (
        values?.tglHeader["minggu" + mingguKeI]?.reduce((prev, curr) => {
            let obj = {
                text: curr.day + " \n" + date.getSlashDMY(new Date(curr?.date))
            };
            return [...prev, obj];
        }, [])
    )
        ;

    const TABLE_HEADER = action === "READ" ? (
        arrayforTglHeader ? arrayforTglHeader : []
    ) : (
        arrayforTglHeader ? [
            { text: "Pekerja Nama" }
        ].concat(arrayforTglHeader) : []
    )

    const TABLE_BODY = somethingArray ? somethingArray : []


    for (let i = 0; i < 7; i++) {
        somethingArray.push(
            {
                props: { textRight: true },
                customField: (val, index) =>
                    action === "READ" ?
                        val?.jadwal[0]?.dates[i]?.shiftNama :
                        <Select
                            menuFixed
                            disable={val?.jadwal[0]?.dates[i]?.isDisabled}
                            options={getShift?.data ?? []}
                            placeholder="Pilih salah satu..."
                            defaultValue={
                                val?.jadwal[0]?.dates[i]?.tgl
                                    ? getShift?.data?.find((item) => item.value === val?.jadwal[0]?.dates[i]?.shiftId)
                                    : null
                            }
                            onChange={(x) => {
                                setNewValues({ index: index, propname: "shiftId", indexDate: i, val: x.value });
                            }}
                            loading={getShift?.isFetching}
                            errorFetch={getShift.isError}
                            error={Boolean(errors.shiftId && touched.shiftId)}
                            errorText={Boolean(errors.shiftId && touched.shiftId) && errors.shiftId}
                        />
            }
        )
    }

    return (
        <>
            <TableList
                removeNo={Boolean(action === "READ")}
                maxHeight={550}
                tableHeader={TABLE_HEADER}
                tableBody={action === "READ" ? (
                    TABLE_BODY
                ) : (
                    [
                        {
                            field: "pekerjaNama"
                        },
                        ...somethingArray
                    ]
                )}
                data={values?.detail}
            />
        </>
    );
};
