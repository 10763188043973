import {
  ButtonDelete, ButtonDetail, ButtonFilter, ButtonUpdate, InputSearch, ModalCreate, ModalDetail, ModalFilter, ModalUpdate, numberConvert, rupiahConvert, Select, toastTrigger
} from "components";
import { Form, Formik, useFormikContext } from "formik";
import { useAccessRights, useLayout, useModalConfirm } from "hooks";
import { ListLayout } from "layouts";
import { debounce } from "lodash";
import { useState } from "react";
import { ButtonGroup } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import { errorFetchingTableMapper, errorSubmitMapper, exportHandler } from "utilities";
import SetupJaminanBpjsApi from "./__SetupJaminanBpjsApi__";
import { Content } from "./__SetupJaminanBpjsComps__";
import { formInitialValues, formSubmitMapper, formValidationSchema } from "./__SetupJaminanBpjsUtils__";

const ModalFilterBody = () => {
  const { values, setValues } = useFormikContext();

  const getPeriode = useQuery(["periode-penggajian", "dropdown"], () => SetupJaminanBpjsApi.getPeriodePenggajian());
  // const getKomponenBpjs = useQuery(["komponen-bpjs", "dropdown"], () => SetupJaminanBpjsApi.getKomponenBpjs());

  return (
    <>
      <Select
        label="Periode Penggajian"
        placeholder="Pilih periode penggajian"
        defaultValue={getPeriode?.data?.find((item) => item.value === values?.idPeriode)}
        options={getPeriode?.data ?? []}
        onChange={(val) => setValues({ ...values, idPeriode: val.value, namaPeriode: val.label })}
        loading={getPeriode.isFetching}
        errorFetch={getPeriode.isError}
      />
      {/* <Select
        label="Komponen BPJS"
        placeholder="Pilih komponen BPJS"
        defaultValue={getKomponenBpjs?.data?.find((item) => item.value === values?.idKomponenBpjs)}
        options={getKomponenBpjs?.data ?? []}
        onChange={(val) =>
          setValues({
            ...values,
            idKomponenBpjs: val.value
          })
        }
        loading={getKomponenBpjs.isFetching}
        errorFetch={getKomponenBpjs.isError}
      /> */}
    </>
  );
};

export const SetupJaminanBpjsList = () => {
  const access = useAccessRights("SETUP_JAMINAN_BPJS");
  const rupiah = rupiahConvert();
  const layout = useLayout();
  const number = numberConvert();
  const modalConfirm = useModalConfirm();
  const [searchKey, setSearchKey] = useState(undefined);
  const [pagination, setPagination] = useState({
    pageCurrent: 1,
    dataLength: 10
  });
  const [modal, setModal] = useState({
    show: false,
    action: "",
    data: {}
  });

  const [filter, setFilter] = useState({
    show: false,
    active: false,
    data: {
      idPeriode: undefined,
      idKomponenBpjs: undefined
    }
  });

  const getList = useQuery(
    [
      "setup-jaminan-bpjs",
      "list",
      searchKey,
      pagination.pageCurrent,
      pagination.dataLength,
      filter?.data?.idKomponenBpjs,
      filter?.data?.idPeriode
    ],
    () =>
      SetupJaminanBpjsApi.getList({
        search: searchKey,
        periode_tahun: filter?.data?.idPeriode,
        komponen_bpjs_id: filter?.data?.idKomponenBpjs,
        page: pagination.pageCurrent,
        limit: pagination.dataLength
      })
  );

  const createSetupJaminanBpjs = useMutation((data) => SetupJaminanBpjsApi.create(data), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "create" });
      getList.refetch();
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "create", ...errorSubmitMapper(err) })
  });

  const updateSetupJaminanBpjs = useMutation(({ data, id }) => SetupJaminanBpjsApi.update(data, id), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "update" });
      getList.refetch();
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "update", ...errorSubmitMapper(err) })
  });

  const deleteSetupJaminanBpjs = useMutation((id) => SetupJaminanBpjsApi.delete(id), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "delete" });
      getList.refetch();
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "delete", ...errorSubmitMapper(err) })
  });

  const getExportSetupJaminanBpjs = useMutation(
    (data) =>
      SetupJaminanBpjsApi.getExport({
        periode_tahun: data?.idPeriode,
        // komponen_bpjs_id: data?.idKomponenBpjs
      }),
    {
      onSuccess: (link) => {
        toastTrigger({ variant: "success", message: "Export berhasil" });
        exportHandler(link, "Data Setup Jaminan BPJS");
      },
      onError: () => {
        toastTrigger({ variant: "danger", message: "Export gagal" });
      }
    }
  );

  const searchHandler = (e) => {
    setSearchKey(e?.target?.value ? e.target.value : undefined);
  };

  const onHideModalHandler = () => setModal({ show: false, action: "", data: {} });

  const onCreateButtonClickHandler = () => setModal({ show: true, action: "CREATE", data: {} });

  const onDetailButtonClickHandler = (data) => setModal({ show: true, action: "READ", data: data });

  const onUpdateButtonClickHandler = (data) => setModal({ show: true, action: "UPDATE", data: data });

  const onPaginationChange = (data) => setPagination({ ...pagination, pageCurrent: data });

  const onPaginationDataLengthChange = (data) => setPagination({ ...pagination, dataLength: data });

  const submitValidationHandler = (errors, values) =>
    new Promise((resolve, reject) => {
      const getError = Object.values(errors);

      if (Number(values.batasBawahUpah) > Number(values?.batasAtasUpah)) {
        getError.push("Batas atas upah harus lebih besar dari batas bawah upah");
      }

      if (getError.length > 0) {
        reject(getError);
      } else {
        resolve();
      }
    });

  const preSubmitHandler = (formik) => {
    const { values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting } = formik;

    setSubmitting(true);
    validateForm().then(async (err) => {
      setErrors(err);
      setTouched(err);

      await submitValidationHandler(err, values)
        .then(() => handleSubmit())
        .catch((err) =>
          modalConfirm.trigger({
            type: "error",
            title: "Data Tidak Lengkap",
            data: err,
            onHide: () => modalConfirm.close()
          })
        )
        .finally(() => {
          setSubmitting(false);
        });
    });
  };

  const formSubmitHandler = (values, action, resetForm) =>
    modalConfirm.trigger({
      type: action.toLowerCase(),
      onHide: modalConfirm.close,
      onSubmit: () => {
        modalConfirm.loading();

        if (action === "UPDATE") {
          updateSetupJaminanBpjs
            .mutateAsync({ data: formSubmitMapper.Form(values), id: values.id })
            .then(() => onHideModalHandler());
        } else if (action === "DELETE") {
          deleteSetupJaminanBpjs.mutate(values.id);
        } else {
          createSetupJaminanBpjs.mutateAsync(formSubmitMapper.Form(values)).then(() => onHideModalHandler());
          resetForm();
        }
      },
      component: (
        <Formik initialValues={values}>
          <Content action="READ" />
        </Formik>
      )
    });
  const TABLE_HEADER = [
    {
      text: "Periode Penggajian"
    },
    {
      text: "Jenis Upah Minimum"
    },
    {
      text: "Komponen BPJS"
    },
    {
      text: "Proporsi Perusahaan"
    },
    {
      text: "Proporsi Pekerja"
    },
    {
      text: "Batas Bawah Upah"
    },
    {
      text: "Batas Atas Upah"
    },
    {
      text: "Keterangan"
    },
    {
      text: "Aksi",
      props: {
        width: 50,
        fixed: true
      }
    }
  ];

  const TABLE_BODY = [
    { customField: (data) => String(data?.periode_tahun) },
    { field: "jenis_upah_nama" },
    { field: "komponen_bpjs_nama" },
    {
      props: { textRight: true },
      customField: (data) => number.getWithComa(data?.jaminan_perusahaan, { minimumFractionDigits: 2, maximumFractionDigits: 4 }) + "%"
    },
    {
      props: { textRight: true },
      customField: (data) => number.getWithComa(data?.jaminan_pekerja, { minimumFractionDigits: 2, maximumFractionDigits: 4 }) + "%"
    },
    {
      props: { textRight: true },
      customField: (data) => rupiah.getWithComa(data?.batas_bawah, { minimumFractionDigits: 2 })
    },
    {
      props: { textRight: true },
      customField: (data) => rupiah.getWithComa(data?.batas_atas, { minimumFractionDigits: 2 })
    },
    { field: "keterangan" },
    {
      props: {
        width: 50,
        fixed: true
      },
      customField: (data, index) => (
        <div className="d-flex align-items-center">
          <ButtonGroup size="sm">
            {access.canRead() && (
              <ButtonDetail icon noText tooltip={false} onClick={() => onDetailButtonClickHandler(data)} />
            )}
            {access.canUpdate() && (
              <ButtonUpdate icon noText tooltip={false} onClick={() => onUpdateButtonClickHandler(data)} />
            )}
            {access.canDelete() && (
              <ButtonDelete
                icon
                noText
                tooltip={false}
                onClick={() => formSubmitHandler(formInitialValues(data), "DELETE")}
              />
            )}
          </ButtonGroup>
        </div>
      )
    }
  ];
  return (
    <>
      {/**
       * List Layout:
       * Menampilkan nama menu, breadcrumb, search input, create & export button, tabel dll
       * search section & button section dapat direplace menggunakan props customLeftTopSection, customRightTopSection dll
       */}
      <ListLayout
        customLeftTopSection={() => (
          <div className="d-flex align-items-end mb-3">
            <div style={{ width: 300 }} className="me-2">
              <InputSearch onChange={debounce(searchHandler, 1500)} />
            </div>
            <div>
              <ButtonFilter
                active={filter?.active}
                className="text-nowrap"
                onClick={() => setFilter({ ...filter, show: true })}
              />
            </div>
          </div>
        )}
        showRightTopButton={access.canCreate() ? true : false}
        showExportExcelButton={Boolean(filter?.data?.idPeriode)}
        onExportExcelButtonClick={() => getExportSetupJaminanBpjs.mutate(filter?.data)}
        loadingExportButton={getExportSetupJaminanBpjs.isLoading}
        loading={getList.isFetching}
        error={getList.isError}
        errorText={errorFetchingTableMapper(getList.error)}
        dataTotal={getList?.data?.total}
        dataLength={pagination.dataLength}
        pageCurrent={pagination.pageCurrent}
        pageTotal={getList?.data?.last_page}
        onCreateButtonClick={onCreateButtonClickHandler}
        onDataLengthChange={onPaginationDataLengthChange}
        onPaginationChange={onPaginationChange}
        data={getList?.data?.data}
        tableHeader={TABLE_HEADER}
        tableBody={TABLE_BODY}
      />
      {/**
       * Modal Detail:
       * Digunakan untuk melihat detail data
       */}
      <Formik enableReinitialize initialValues={formInitialValues(modal.data)}>
        <ModalDetail
          size="md"
          title={layout.getActivePageName()}
          show={Boolean(modal.show && modal.action === "READ")}
          onHide={onHideModalHandler}
        >
          <Content action="READ" />
        </ModalDetail>
      </Formik>
      {/**
       * Modal Create:
       * Digunakan untuk menambah data
       */}
      <Formik
        enableReinitialize
        initialValues={formInitialValues({})}
        validationSchema={formValidationSchema.form}
        onSubmit={(values, { resetForm }) => formSubmitHandler(values, "CREATE", resetForm)}
      >
        {(formik) => {
          return (
            <Form>
              <ModalCreate
                title={layout.getActivePageName()}
                show={Boolean(modal.show && modal.action === "CREATE")}
                onHide={() => {
                  onHideModalHandler();
                  formik.resetForm();
                }}
                onSubmit={() => {
                  preSubmitHandler(formik);
                }}
              >
                <Content action="CREATE" />
              </ModalCreate>
            </Form>
          );
        }}
      </Formik>
      {/**
       * Modal Update:
       * Modal untuk mengubah data
       */}
      <Formik
        enableReinitialize
        initialValues={formInitialValues(modal.data)}
        validationSchema={formValidationSchema.form}
        onSubmit={(values) => formSubmitHandler(values, "UPDATE")}
      >
        {(formik) => {
          return (
            <Form>
              <ModalUpdate
                title={layout.getActivePageName()}
                show={Boolean(modal.show && modal.action === "UPDATE")}
                onHide={() => {
                  onHideModalHandler();
                  formik.resetForm();
                }}
                onSubmit={() => preSubmitHandler(formik)}
              >
                <Content action="UPDATE" />
              </ModalUpdate>
            </Form>
          );
        }}
      </Formik>

      {/* ModalFilter */}
      <Formik
        initialValues={{
          idKomponenBpjs: filter?.data?.idKomponenBpjs,
          idPeriode: filter?.data?.idPeriode
        }}
      >
        {({ resetForm, values }) => (
          <ModalFilter
            show={filter.show}
            onReset={resetForm}
            onHide={() =>
              setFilter({
                ...filter,
                show: false
              })
            }
            onSubmit={() => {
              setFilter({
                show: false,
                active: Boolean(Object.values(values).find((val) => val)),
                data: values
              });
            }}
          >
            <ModalFilterBody />
          </ModalFilter>
        )}
      </Formik>
    </>
  );
};
