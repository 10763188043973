import { Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import { InfoItemVertical, TableList, ButtonCreate, ButtonDelete, Input } from "components";
import { useModalConfirm } from "hooks";

const DetailTabSumberData = () => {
  const { values } = useFormikContext();

  return (
    <>
      <InfoItemVertical
        label="Sumber Data"
        text={
          <>
            <ol>
              {values.nama.map((val) => (
                <li>{val}</li>
              ))}
            </ol>
          </>
        }
      />
    </>
  );
};

const FormTabSumberData = () => {
  const parents = useFormikContext();

  const submitDetailSumberData = (values, { resetForm }) => {
    parents.setValues({
      ...parents.values,
      nama: [values.nama, ...parents.values.nama]
    });

    resetForm();
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{ nama: "" }}
        validationSchema={Yup.object().shape({ nama: Yup.string().required() })}
        onSubmit={submitDetailSumberData}
      >
        {({ errors, touched, handleSubmit, setValues }) => (
          <div className="d-flex align-items-end mb-3">
            <div style={{ width: "50%" }} className="me-2">
              <Input
                label="Sumber Data"
                placeholder="Masukan sumber data"
                name="nama"
                onChange={(val) =>
                  setValues({
                    nama: val.target.value
                  })
                }
                error={Boolean(errors.nama && touched.nama)}
              />
            </div>
            <div style={{ width: "100%" }} className="me-2">
              <ButtonCreate noText icon={true} tooltip={false} className="mb-2" onClick={handleSubmit} />
            </div>
          </div>
        )}
      </Formik>
    </>
  );
};

const TabelTabSumberData = () => {
  const { values, setValues } = useFormikContext();
  const modalConfirm = useModalConfirm();

  const DetailDeleteTabSumberData = ({ index }) => {
    return (
      <>
        <InfoItemVertical label="Target KPI Nama" text={values.nama[index]} />
      </>
    );
  };

  const deleteDataSumberData = (index) => {
    const temporary = [...values.nama];
    temporary.splice(index, 1);
    setValues({
      ...values,
      nama: temporary
    });
  };

  const TABLE_HEADER = [
    {
      text: "Sumber Data"
    },
    {
      text: "Aksi",
      props: {
        fixed: true,
        width: 50
      }
    }
  ];

  const TABLE_BODY = [
    {
      customField: (data) => data
    },
    {
      props: {
        fixed: true,
        className: "text-center"
      },
      customField: (_data, index) => (
        <>
          <ButtonDelete
            icon
            noText
            onClick={() =>
              modalConfirm.trigger({
                size: "md",
                type: "delete",
                alert: true,
                component: <DetailDeleteTabSumberData index={index} />,
                onSubmit: () => {
                  deleteDataSumberData(index);
                  modalConfirm.close();
                },
                onHide: () => modalConfirm.close()
              })
            }
          />
        </>
      )
    }
  ];

  return (
    <>
      <TableList data={values.nama} tableHeader={TABLE_HEADER} tableBody={TABLE_BODY} />
    </>
  );
};

export const TabSumberData = ({ action }) => {
  if (action === "READ") {
    return (
      <>
        <DetailTabSumberData />
      </>
    );
  } else {
    return (
      <>
        <FormTabSumberData />
        <TabelTabSumberData />
      </>
    );
  }
};
