import * as Yup from "yup";

export const formValidationSchema = Yup.object().shape({
    nominal: Yup.string().required("Nominal wajib diisi"),
    // detail: Yup.array().of(
    //     Yup.object().shape({
    //         nominal: Yup.string().required("Nominal wajib diisi")
    //     })
    // )
});
