import React from "react";
import { useFormikContext } from "formik";
import { Input, InfoItemVertical, Select } from "components";
import StatusPajakPekerjaApi from "../__StatusPajakPekerjaApi__";
import { useQuery } from "react-query";

export const Content = ({ action }) => {
  const { values, errors, touched, setValues } = useFormikContext();

  const getUnitOrganisasi = useQuery(["unit-organisasi", "dropdown"], () => StatusPajakPekerjaApi.getUnitOrganisasi());
  const getPekerja = useQuery(
    ["pekerja", "dropdown", values],
    () =>
      StatusPajakPekerjaApi.getPekerja({
        master_unit_organisasi_id: values?.unitOrganisasiId
      }),
    { enabled: !!values?.unitOrganisasiId }
  );
  const getStatusPekerja = useQuery(
    ["status-pekerja", "dropdown", values],
    () =>
      StatusPajakPekerjaApi.getStatusPekerja({
        periode_gajian_id: values?.periodePenggajianId
      }),
    { enabled: !!values?.periodePenggajianId }
  );

  if (action === "READ") {
    return (
      <>
        <InfoItemVertical label="Periode Penggajian" text={values?.periodePenggajianNama ?? "-"} />
        <InfoItemVertical label="Unit Organisasi" text={values?.unitOrganisasiNama ?? "-"} />
        <InfoItemVertical label="Pekerja" text={values?.pekerjaNama ?? "-"} />
        <InfoItemVertical label="Jabatan" text={values?.jabatanNama ?? "-"} />
        <InfoItemVertical label="NPWP" text={values?.npwp ?? "-"} />
        <InfoItemVertical label="Status Pajak" text={values?.statusPajakNama ?? "-"} />
      </>
    );
  }

  return (
    <>
      <Input
        disabled={Boolean(action === "UPDATE")}
        label="Periode Penggajian"
        name="periodePenggajianId"
        placeholder="Masukkan periode penggajian"
        value={values.periodePenggajianId ? String(values?.periodePenggajianId) : "-"}
        onChange={(val) =>
          setValues({
            ...values,
            periodePenggajianId: val.target.value,
            periodePenggajianNama: val.target.value
          })
        }
        error={Boolean(errors.periodePenggajianId && touched.periodePenggajianId)}
        errorText={Boolean(errors.periodePenggajianId && touched.periodePenggajianId) && errors.periodePenggajianId}
      />
      <Select
        disable={Boolean(action === "UPDATE")}
        label="Unit Organisasi"
        placeholder="Pilih unit organisasi"
        defaultValue={
          values.unitOrganisasiId
            ? getUnitOrganisasi?.data?.find((item) => item.value === values.unitOrganisasiId)
            : null
        }
        options={getUnitOrganisasi?.data ?? []}
        onChange={(val) => setValues({ ...values, unitOrganisasiId: val.value, unitOrganisasiNama: val.label })}
        loading={getUnitOrganisasi.isFetching}
        errorFetch={getUnitOrganisasi.isError}
        error={Boolean(errors.unitOrganisasiId && touched.unitOrganisasiId)}
        errorText={Boolean(errors.unitOrganisasiId && touched.unitOrganisasiId) && errors.unitOrganisasiId}
      />
      <Select
        disable={Boolean(action === "UPDATE")}
        label="Pekerja"
        placeholder="Pilih pekerja"
        defaultValue={values.pekerjaId ? getPekerja?.data?.find((item) => item.value === values.pekerjaId) : null}
        options={getPekerja?.data ?? []}
        onChange={(val) =>
          setValues({
            ...values,
            pekerjaId: val.value,
            pekerjaNama: val.label,
            jabatanNama: val.jabatanLabel,
            npwp: val.npwpLabel
          })
        }
        loading={getPekerja.isFetching}
        errorFetch={getPekerja.isError}
        error={Boolean(errors.pekerjaId && touched.pekerjaId)}
        errorText={Boolean(errors.pekerjaId && touched.pekerjaId) && errors.pekerjaId}
      />
      <Input label="Jabatan" placeholder="Masukkan jabatan" value={values.jabatanNama} disabled />
      <Input label="NPWP" placeholder="Masukkan npwp" value={values.npwp ? values.npwp : "-"} disabled />
      <Select
        label="Status Pajak"
        placeholder="Pilih status pajak"
        defaultValue={
          values.statusPajakId ? getStatusPekerja?.data?.find((item) => item.value === values.statusPajakId) : null
        }
        options={getStatusPekerja?.data ?? []}
        onChange={(val) =>
          setValues({
            ...values,
            statusPajakId: val.value,
            statusPajakNama: val.label
          })
        }
        loading={getStatusPekerja.isFetching}
        errorFetch={getStatusPekerja.isError}
        error={Boolean(errors.statusPajakId && touched.statusPajakId)}
        errorText={Boolean(errors.statusPajakId && touched.statusPajakId) && errors.statusPajakId}
      />
    </>
  );
};
