export const formInitialValues = (data = {}) => ({
  id: data?.master_kompetensi_jabatan_id,
  nama: data?.kompetensi_jabatan_nama,
  kode_kompetensi_jabatan: data?.kode,

  jenis_kompetensi_id: data?.master_jenis_kompetensi_id,
  jenis_kompetensi_nama: data?.master_jenis_kompetensi_nama,

  nama_keterangan: data?.keterangan
});
