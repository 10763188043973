export const formSubmitValueMapper = (data) => ({
  tgl_input: data?.tglInput,
  master_unit_organisasi_id: data?.unitOrganisasiId,
  pekerja_id: data?.pekerjaId,
  master_jabatan_id: data?.jabatanId,
  nomor: data?.nomor,
  tujuan: data?.tujuan,
  selama: data?.selama,
  catatan: data?.catatan,
  master_jenis_ijin_id: data?.jenisIzinId,
  tgl_ijin: data?.detail?.map((val) => val.tglIzin)
});
