import { Services } from "services";
class JobStreamApi {
  async getList(params) {
    const fetch = await Services.get("/master/job-stream", { params });
    return fetch?.data?.data;
  }

  async getExport(params) {
    const fetch = await Services.get("/master/job-stream/export", { params });
    return fetch?.data?.data;
  }

  create(data) {
    return Services.post("/master/job-stream", data);
  }

  update(data, id) {
    return Services.put("/master/job-stream/" + id, data);
  }

  delete(id) {
    return Services.delete("/master/job-stream/" + id);
  }
}

export default new JobStreamApi();
