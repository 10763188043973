import { Services } from "services";

class LaporanTransferGajiApi {
  async getList(params) {
    const fetch = await Services.get("/laporan/transfer-gaji", { params });
    return fetch?.data?.data;
  }

  async getUnitOrganisasi(params) {
    const fetch = await Services.get("/laporan/transfer-gaji/dropdown-unit", { params });
    return fetch?.data?.data.map((val) => ({
      value: val?.master_unit_organisasi_id,
      label: val?.master_unit_organisasi_nama
    }));
  }

  async getPeriodeForList() {
    const fetch = await Services.get("/utility/periode-tahun?feat=transfer-gaji");
    return fetch?.data?.data.map((val) => ({
      value: val?.periode_tahun,
      label: val?.periode_tahun
    }));
  }

  async getTanggal(params) {
    const fetch = await Services.get("/laporan/transfer-gaji/dropdown-tanggal", { params });
    return fetch?.data?.data.map((val) => ({
      value: val?.tgl_bulan_awal + "/&/" + val?.tgl_bulan_akhir,
      label: val?.tgl_bulan_awal + " s/d " + val?.tgl_bulan_akhir,
      tglAwal: val?.tgl_bulan_awal,
      tglAkhir: val?.tgl_bulan_akhir
    }));
  }

  getStatus() {
    return [
      {
        value: undefined,
        label: "All"
      },
      {
        value: 1,
        label: "Aktif"
      },
      {
        value: 2,
        label: "Tidakk Aktif"
      }
    ];
  }

  async getExport(params) {
    const fetch = await Services.get("/laporan/transfer-gaji/export", { params });
    return fetch?.data?.data;
  }
}

export default new LaporanTransferGajiApi();
