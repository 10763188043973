import React from "react";
import { useQuery } from "react-query";
import { useFormikContext } from "formik";
import { Input, Select, InfoItemVertical } from "components";
import { errorFetchingSelectMapper } from "utilities";
import UnitOrganisasiApi from "../__UnitOrganisasiApi__";
import { relasiParentMapper } from "../__UnitOrganisasiUtils__";

export const Content = ({ action }) => {
  const { values, errors, touched, handleChange, setValues, setFieldValue } = useFormikContext();
  const getLevel = useQuery(["level", "dropdown"], () => UnitOrganisasiApi.getLevel());
  const getHirarki = useQuery(
    ["hirarki-unit-organisasi", "dropdown", values.level],
    () => UnitOrganisasiApi.getHirarkiUnitOrganisasi(values.level),
    { enabled: Boolean(values.level) }
  );
  const getParentUnitOrganisasi = useQuery(
    ["unit-organisasi-parent", "dropdown", values.hirarki_unit_organisasi_level],
    () => UnitOrganisasiApi.getUnitOrganisasiParent(values.hirarki_unit_organisasi_level),
    { enabled: Boolean(values.hirarki_unit_organisasi_level) }
  );
  const getRelasiParent = useQuery(["relasi-parent", "dropdown"], () => UnitOrganisasiApi.getRelasiParent());

  if (action === "READ") {
    return (
      <>
        <InfoItemVertical label="Kode Unit Organisasi" text={values.kode} />
        <InfoItemVertical label="Nama Unit Organisasi" text={values.nama} />
        <InfoItemVertical label="Level Unit Organisasi" text={values.level} />
        <InfoItemVertical label="Hirarki Unit Organisasi" text={values.hirarki_unit_organisasi_nama} />
        <InfoItemVertical label="Unit Organisasi Parent" text={values.parent_nama} />
        <InfoItemVertical label="Relasi Parent" text={relasiParentMapper(values.relasi_parent)} />
      </>
    );
  }

  return (
    <>
      <Input
        label="Kode Unit Organisasi"
        name="kode"
        placeholder="Masukkan kode unit organisasi"
        value={values.kode}
        onChange={handleChange}
        error={Boolean(errors.kode && touched.kode)}
        errorText={Boolean(errors.kode && touched.kode) && errors.kode}
      />
      <Input
        label="Nama Unit Organisasi"
        name="nama"
        placeholder="Masukkan nama unit organisasi"
        value={values.nama}
        onChange={handleChange}
        error={Boolean(errors.nama && touched.nama)}
        errorText={Boolean(errors.nama && touched.nama) && errors.nama}
      />
      <Select
        label="Level Unit Organisasi"
        placeholder="Pilih level hirarki unit organisasi"
        defaultValue={getLevel?.data?.find((item) => item.value === values.level)}
        options={getLevel?.data ?? []}
        onChange={(val) =>
          setValues({
            ...values,
            level: val.value,
            hirarki_unit_organisasi_id: "",
            hirarki_unit_organisasi_nama: "",
            hirarki_unit_organisasi_level: "",
            parent_id: "",
            parent_nama: ""
          })
        }
        loading={getLevel.isFetching}
        error={Boolean(errors.level && touched.level)}
        errorText={Boolean(errors.level && touched.level) && errors.level}
        errorFetch={getLevel.isError}
        errorFetchText={errorFetchingSelectMapper(getLevel.error)}
      />
      <Select
        disable={!Boolean(values.level)}
        label="Hirarki Unit Organisasi"
        placeholder="Pilih hirarki hirarki unit organisasi"
        defaultValue={getHirarki?.data?.find((item) => item.value === values.hirarki_unit_organisasi_id)}
        options={getHirarki?.data ?? []}
        onChange={(val) =>
          setValues({
            ...values,
            hirarki_unit_organisasi_id: val.value,
            hirarki_unit_organisasi_nama: val.label,
            hirarki_unit_organisasi_level: val.level,
            parent_id: "",
            parent_nama: ""
          })
        }
        loading={getHirarki.isFetching}
        error={Boolean(errors.hirarki_unit_organisasi_id && touched.hirarki_unit_organisasi_id)}
        errorText={
          Boolean(errors.hirarki_unit_organisasi_id && touched.hirarki_unit_organisasi_id) &&
          errors.hirarki_unit_organisasi_id
        }
        errorFetch={getHirarki.isError}
        errorFetchText={errorFetchingSelectMapper(getHirarki.error)}
      />
      <Select
        disable={!Boolean(values.hirarki_unit_organisasi_id)}
        label="Unit Organisasi Parent"
        placeholder="Pilih unit organisasi parent"
        defaultValue={getParentUnitOrganisasi?.data?.find((item) => item.value === values.parent_id)}
        options={getParentUnitOrganisasi?.data ?? []}
        onChange={(val) => setValues({ ...values, parent_id: val.value, parent_nama: val.label })}
        loading={getParentUnitOrganisasi.isFetching}
        error={Boolean(errors.parent_id && touched.parent_id)}
        errorText={Boolean(errors.parent_id && touched.parent_id) && errors.parent_id}
        errorFetch={getParentUnitOrganisasi.isError}
        errorFetchText={errorFetchingSelectMapper(getParentUnitOrganisasi.error)}
      />
      <Select
        label="Relasi Parent"
        placeholder="Pilih relasi parent"
        defaultValue={getRelasiParent?.data?.find((item) => item.value === values.relasi_parent)}
        options={getRelasiParent?.data ?? []}
        onChange={(val) => setFieldValue("relasi_parent", val.value)}
        loading={getRelasiParent.isFetching}
        error={Boolean(errors.relasi_parent && touched.relasi_parent)}
        errorText={Boolean(errors.relasi_parent && touched.relasi_parent) && errors.relasi_parent}
        errorFetch={getRelasiParent.isError}
        errorFetchText={errorFetchingSelectMapper(getRelasiParent.error)}
      />
    </>
  );
};
