import { InfoItemVertical, InputNumber, Select } from "components";
import { useFormikContext } from "formik";
import { useQuery } from "react-query";
import { errorFetchingSelectMapper } from "utilities";
import UraianKPIApi from "../__UraianKPIApi__";

const DetailTabFrekuensiMonitoring = () => {
  const { values } = useFormikContext();
  return (
    <>
      <InfoItemVertical label="Frekuensi Monitoring" text={values.frekuensi} />
      <InfoItemVertical label="Satuan Monitoring" text={values.frekuensiSatuanKpiNama} />
    </>
  );
};

const FormTabFrekuensiMonitoring = () => {
  const { values, errors, touched, setValues } = useFormikContext();
  const getSatuanPeriode = useQuery(["satuan-periode", "dropdown"], () => UraianKPIApi.getSatuanKPI());

  return (
    <>
      {/* Frekuensi Monitoring */}
      <InputNumber
        label="Frekuensi Monitoring"
        placeholder="Masukan frekuensi monitoring"
        name="frekuensi"
        value={values.frekuensi}
        onChange={(val) =>
          setValues({
            ...values,
            frekuensi: val
          })
        }
        error={Boolean(errors.frekuensi && touched.frekuensi)}
        errorText={Boolean(errors.frekuensi && touched.frekuensi) && errors.frekuensi}
      />

      {/* Satuan Monitoring */}
      <Select
        label="Satuan Monitoring"
        placeholder="Pilih satuan Monitoring"
        defaultValue={getSatuanPeriode?.data?.find((item) => item.value === values.frekuensiSatuanKpiId) ?? []}
        options={getSatuanPeriode?.data ?? []}
        onChange={(val) =>
          setValues({
            ...values,
            frekuensiSatuanKpiId: val.value,
            frekuensiSatuanKpiNama: val.label
          })
        }
        loading={getSatuanPeriode.isFetching}
        error={Boolean(errors.frekuensiSatuanKpiId && touched.frekuensiSatuanKpiId)}
        errorText={Boolean(errors.frekuensiSatuanKpiId && touched.frekuensiSatuanKpiId) && errors.frekuensiSatuanKpiId}
        errorFetch={getSatuanPeriode.isError}
        errorFetchText={errorFetchingSelectMapper(getSatuanPeriode.error)}
      />
    </>
  );
};

export const TabFrekuensiMonitoring = ({ action, data }) => {
  return <>{action === "READ" ? <DetailTabFrekuensiMonitoring /> : <FormTabFrekuensiMonitoring />}</>;
};
