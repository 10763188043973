import { SetupKomponenJaminanPensiunBulananList, SetupKomponenJaminanPensiunBulananCreate } from "pages/Payroll";

export const SetupKomponenJaminanPensiunBulanan = {
  component: <SetupKomponenJaminanPensiunBulananList />,
  link: "setup-komponen-jaminan-pensiun-bulanan",
  name: "Setup Komponen Jaminan Pensiun Bulanan",
  access: "SETUP_KOMPONEN_JAMINAN_PENSIUN_BULANAN",
  child: [
    {
      name: "Tambah",
      link: "/tambah",
      component: <SetupKomponenJaminanPensiunBulananCreate />
    }
  ]
};
