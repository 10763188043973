export const formInitialValues = (data = {}) => ({
  id: data?.pekerja_status_ptkp_id ?? "",
  periodePenggajianId: data?.periode_tahun ?? new Date().getFullYear(),
  periodePenggajianNama: data?.periode_tahun ?? new Date().getFullYear(),
  unitOrganisasiId: data?.master_unit_organisasi_id ?? "",
  unitOrganisasiNama: data?.master_unit_organisasi_nama ?? "",
  pekerjaId: data?.pekerja_id ?? "",
  pekerjaNama: data?.pekerja_nama ?? "",
  jabatanNama: data?.master_jabatan_nama ?? "",
  npwp: data?.no_npwp ?? "",
  statusPajakId: data?.tarif_ptkp_id ?? "",
  statusPajakNama: data?.status_pajak ?? ""
});
