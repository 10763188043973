import { PekerjaList, PekerjaDetail, PekerjaCreate, PekerjaUpdate } from "pages/Pekerja";

export const Pekerja = {
  link: "pekerja",
  name: "Pekerja",
  access: "REGISTRASI_PEKERJA",
  component: <PekerjaList />,
  child: [
    {
      name: "Detail",
      link: "/detail/:id",
      component: <PekerjaDetail />
    },
    {
      name: "Tambah",
      link: "/tambah",
      component: <PekerjaCreate />
    },
    {
      name: "Ubah",
      link: "/ubah/:id",
      component: <PekerjaUpdate />
    }
  ]
};
