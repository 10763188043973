import React, { useState } from "react";
import { useQuery, useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { ListLayout } from "layouts";
import { ButtonGroup, Row, Col } from "react-bootstrap";
import {
  ButtonDetail,
  ButtonFilter,
  ModalFilter,
  InputSearch,
  toastTrigger,
  numberConvert,
  Select,
  InfoItemVertical,
  ModalDetail,
  useLayout,
  dateConvert
} from "components";
import { useAccessRights } from "hooks";
import { exportHandler } from "utilities";
import { Formik, useFormikContext } from "formik";
import { debounce } from "lodash";
import { Content } from "./__SetupKomponenJaminanKehilanganPekerjaanBulananComps__";
import { formInitialValues } from "./__SetupKomponenJaminanKehilanganPekerjaanBulananUtilities__";
import SetupKomponenJaminanKehilanganPekerjaanBulananApi from "./__SetupKomponenJaminanKehilanganPekerjaanBulananApi__";

const ModalFilterBody = () => {
  const { values, setValues } = useFormikContext();

  const getUnitOrganisasi = useQuery(["unit-organisasi", "dropdown"], () =>
    SetupKomponenJaminanKehilanganPekerjaanBulananApi.getUnitOrganisasiForList()
  );

  const getTanggal = useQuery(
    ["tanggal-penggajian", "dropdown", values?.periodeGajianId],
    () =>
      SetupKomponenJaminanKehilanganPekerjaanBulananApi.getTanggal({
        periode_tahun: values?.periodeGajianId
      }),
    {
      enabled: Boolean(values?.periodeGajianId)
    }
  );

  const getPeriode = useQuery(["periode-penggajian", "dropdown"], () =>
    SetupKomponenJaminanKehilanganPekerjaanBulananApi.getPeriode()
  );

  return (
    <>
      <Select
        label="Periode Penggajian"
        placeholder="Pilih periode penggajian"
        defaultValue={getPeriode?.data?.find((item) => item.value === values?.periodeGajianId)}
        options={getPeriode?.data ?? []}
        onChange={(val) => setValues({ ...values, periodeGajianId: val.value, periodePerencanaan: val.label })}
        loading={getPeriode.isFetching}
        errorFetch={getPeriode.isError}
      />
      <Select
        disable={Boolean(!values?.periodeGajianId)}
        label="Tanggal Penggajian"
        placeholder="Pilih tanggal penggajian"
        defaultValue={getTanggal?.data?.find((item) => item.value === values?.tglId)}
        options={getTanggal?.data ?? []}
        onChange={(val) =>
          setValues({
            ...values,
            tglId: val.value,
            tglNama: val.label,
            tglAwal: val.tglAwal,
            tglAkhir: val.tglAkhir
          })
        }
        loading={getTanggal.isFetching}
        errorFetch={getTanggal.isError}
      />
      <Select
        label="Unit Organisasi"
        placeholder="Pilih unit organisasi"
        defaultValue={getUnitOrganisasi?.data?.find((item) => item.value === values.unitOrganisasiId)}
        options={getUnitOrganisasi?.data ?? []}
        onChange={(val) => setValues({ ...values, unitOrganisasiId: val.value })}
        loading={getUnitOrganisasi.isFetching}
        errorFetch={getUnitOrganisasi.isError}
      />
    </>
  );
};

export const SetupKomponenJaminanKehilanganPekerjaanBulananList = () => {
  const access = useAccessRights("SETUP_KOMPONEN_JAMINAN_KEHILANGAN_PEKERJAAN_BULANAN");
  const navigate = useNavigate();
  const number = numberConvert();
  const layout = useLayout();
  const date = dateConvert();

  const [searchKey, setSearchKey] = useState(undefined);
  const [pagination, setPagination] = useState({
    pageCurrent: 1,
    dataLength: 10
  });

  const [filter, setFilter] = useState({
    show: false,
    active: false,
    data: {
      periodeGajianId: undefined,
      unitOrganisasiId: undefined,
      tglAwal: undefined,
      tglAkhir: undefined
    }
  });

  const [modal, setModal] = useState({
    show: false,
    action: "",
    data: {}
  });

  const getSetupKomponenJaminanKehilanganPekerjaanBulananList = useQuery(
    [
      "setup-komponen-jaminan-kehilangan-pekerjaan-bulanan",
      "list",
      searchKey,
      pagination.pageCurrent,
      pagination.dataLength,
      filter.data?.periodeGajianId,
      filter.data?.unitOrganisasiId,
      filter.data?.tglAwal,
      filter.data?.tglAkhir
    ],
    () =>
      SetupKomponenJaminanKehilanganPekerjaanBulananApi.getList({
        search: searchKey,
        page: pagination.pageCurrent,
        limit: pagination.dataLength,
        periode_tahun: filter.data?.periodeGajianId,
        master_unit_organisasi_id: filter.data?.unitOrganisasiId,
        tgl_bulan_awal: filter?.data?.tglAwal,
        tgl_bulan_akhir: filter?.data?.tglAkhir
      }),
    {
      enabled: Boolean(
        filter?.data?.periodeGajianId &&
        filter?.data?.tglAwal &&
        filter?.data?.tglAkhir
      )
    }
  );

  // const getSetupKomponenJaminanKehilanganPekerjaanBulananDetail = useQuery(
  //   ["setup-komponen-jaminan-kehilangan-pekerjaan-bulanan", "detail"],
  //   () =>
  //     SetupKomponenJaminanKehilanganPekerjaanBulananApi.getDetail(
  //       modal?.data?.pekerja_jaminan_kehilangan_pekerjaan_bulanan_id
  //     ),
  //   {
  //     enabled: Boolean(modal?.data?.pekerja_jaminan_kehilangan_pekerjaan_bulanan_id)
  //   }
  // );

  const getSetupKomponenJaminanKehilanganPekerjaanBulananExport = useMutation(
    (data) =>
      SetupKomponenJaminanKehilanganPekerjaanBulananApi.getExport({
        master_unit_organisasi_id: data?.unitOrganisasiId,
        periode_tahun: data?.periodeGajianId,
        tgl_bulan_awal: data?.tglAwal,
        tgl_bulan_akhir: data?.tglAkhir
      }),
    {
      onSuccess: (link) => {
        toastTrigger({ variant: "success", message: "Export berhasil" });
        exportHandler(link, "Data Setup Komponen Jaminan Kehilangan Pekerjaan Bulanan");
      },
      onError: () => {
        toastTrigger({ variant: "danger", message: "Export gagal" });
      }
    }
  );

  const onHideModalHandler = () => setModal({ show: false, action: "", data: {} });

  const onCreateButtonClickHandler = () => navigate("tambah");

  const onDetailButtonClickHandler = (data) => setModal({ show: true, action: "READ", data: data });

  const onPaginationChange = (data) => setPagination({ ...pagination, pageCurrent: data });

  const onPaginationDataLengthChange = (data) => setPagination({ ...pagination, dataLength: data });

  const searchHandler = (e) => {
    setSearchKey(e?.target?.value ? e.target.value : undefined);
  };

  const dataInfo = getSetupKomponenJaminanKehilanganPekerjaanBulananList?.data?.info;

  const TABLE_HEADER = [
    {
      text: "NIK"
    },
    {
      text: "Pekerja"
    },
    {
      text: "Unit Organisasi"
    },
    {
      text: "Jabatan"
    },
    {
      text: "Upah Tetap BPJS"
    },
    {
      text: "Besaran Upah"
    },
    {
      text: "Iuran Perusahaan"
    },
    {
      text: "Iuran Pekerja"
    },
    {
      text: "Total Iuran BPJS TK - Jaminan Kehilangan Pekerjaan"
    },
    {
      text: "Jenis BPJS"
    },
    {
      text: "Aksi",
      props: {
        fixed: true
      }
    }
  ];

  const TABLE_BODY = [
    { field: "nip" },
    { field: "pekerja_nama" },
    { field: "master_unit_organisasi_nama" },
    { field: "master_jabatan_nama" },
    {
      props: { textRight: true },
      customField: (val) => "Rp" + number.getWithComa(val?.upah_bpjs, { minimumFractionDigits: 2 })
    },
    {
      props: { textRight: true },
      customField: (val) => "Rp" + number.getWithComa(val?.besaran_upah, { minimumFractionDigits: 2 })
    },
    {
      props: { textRight: true },
      customField: (val) => "Rp" + number.getWithComa(val?.iuran_perusahaan, { minimumFractionDigits: 2 })
    },
    {
      props: { textRight: true },
      customField: (val) => "Rp" + number.getWithComa(val?.iuran_karyawan, { minimumFractionDigits: 2 })
    },
    {
      props: { textRight: true },
      customField: (val) => "Rp" + number.getWithComa(val?.total_iuran, { minimumFractionDigits: 2 })
    },
    { field: "jenis" },
    {
      props: {
        width: 50,
        fixed: true,
        textCenter: true
      },
      customField: (data) => (
        <ButtonGroup size="sm">
          {access.canRead() && (
            <ButtonDetail icon tooltip={false} noText onClick={() => onDetailButtonClickHandler(data)} />
          )}
        </ButtonGroup>
      )
    }
  ];

  return (
    <>
      <ListLayout
        customLeftTopSection={() => (
          <>
            <div className="d-flex align-items-end mb-3">
              <div style={{ width: 300 }} className="me-2">
                <InputSearch onChange={debounce(searchHandler, 1500)} />
              </div>
              <div>
                <ButtonFilter
                  active={filter?.active}
                  className="text-nowrap"
                  onClick={() => setFilter({ ...filter, show: true })}
                />
              </div>
            </div>
            <Row className="mb-4">
              <Col>
                <InfoItemVertical label="Komponen Payroll" text={dataInfo?.komponen_payroll} />
                <InfoItemVertical
                  label="Bulan Penggajian"
                  text={
                    (dataInfo?.bulan_awal_penggajian
                      ? date.getSlashDMY(new Date(dataInfo?.bulan_awal_penggajian))
                      : "-") +
                    " s/d " +
                    (dataInfo?.bulan_akhir_penggajian
                      ? date.getSlashDMY(new Date(dataInfo?.bulan_akhir_penggajian))
                      : "-")
                  }
                />
                {/* <InfoItemVertical
                  label="Proporsi Perusahaan"
                  text={
                    dataInfo?.proporsi_perusahaan
                      ? number.getWithComa(dataInfo?.proporsi_perusahaan, { minimumFractionDigits: 2, maximumFractionDigits: 4 }) + "%"
                      : "-"
                  }
                />
                <InfoItemVertical
                  label="Batas Bawah Upah"
                  text={
                    dataInfo?.batas_bawah
                      ? "Rp" + number.getWithComa(dataInfo?.batas_bawah, { minimumFractionDigits: 2 })
                      : "-"
                  }
                /> */}
              </Col>
              <Col>
                <InfoItemVertical
                  label="Periode Perencanaan Penggajian"
                  text={dataInfo?.periode_perencanaan_penggajian}
                />
                <InfoItemVertical label="Jenis Upah Minimum" text={dataInfo?.jenis_upah_nama} />
                {/* <InfoItemVertical
                  label="Proporsi Pekerja"
                  text={
                    dataInfo?.proporsi_pekerja
                      ? number.getWithComa(dataInfo?.proporsi_pekerja, { minimumFractionDigits: 2, maximumFractionDigits: 4 }) + "%"
                      : "-"
                  }
                />
                <InfoItemVertical
                  label="Batas Atas Upah"
                  text={
                    dataInfo?.batas_atas
                      ? "Rp" + number.getWithComa(dataInfo?.batas_atas, { minimumFractionDigits: 2 })
                      : "-"
                  }
                /> */}
              </Col>
            </Row>
          </>
        )}
        showRightTopButton={access.canCreate() ? true : false}
        showExportExcelButton={Boolean(filter?.data?.periodeGajianId && filter?.data?.unitOrganisasiId)}
        onExportExcelButtonClick={() => getSetupKomponenJaminanKehilanganPekerjaanBulananExport.mutate(filter?.data)}
        loadingExportButton={getSetupKomponenJaminanKehilanganPekerjaanBulananExport.isLoading}
        loading={getSetupKomponenJaminanKehilanganPekerjaanBulananList?.isFetching}
        error={getSetupKomponenJaminanKehilanganPekerjaanBulananList?.isError}
        dataTotal={getSetupKomponenJaminanKehilanganPekerjaanBulananList?.data?.total}
        dataLength={pagination.dataLength}
        pageCurrent={pagination.pageCurrent}
        pageTotal={getSetupKomponenJaminanKehilanganPekerjaanBulananList?.data?.last_page}
        onSearchChange={debounce(searchHandler, 1500)}
        onCreateButtonClick={onCreateButtonClickHandler}
        onDataLengthChange={onPaginationDataLengthChange}
        onPaginationChange={onPaginationChange}
        data={getSetupKomponenJaminanKehilanganPekerjaanBulananList?.data?.data}
        tableHeader={TABLE_HEADER}
        tableBody={TABLE_BODY}
      />

      {/**
       * Modal Detail:
       * Digunakan untuk melihat detail data
       */}
      <Formik enableReinitialize initialValues={formInitialValues(modal?.data).detailJaminan}>
        <ModalDetail
          title={layout.getActivePageName()}
          show={Boolean(modal.show && modal.action === "READ")}
          onHide={onHideModalHandler}
        >
          <Content action="READ" />
        </ModalDetail>
      </Formik>

      {/* ModalFilter */}
      <Formik
        initialValues={{
          unitOrganisasiId: filter?.data?.unitOrganisasiId,
          periodeGajianId: filter?.data?.periodeGajianId,
          bulan: filter?.data?.bulan
        }}
      >
        {({ resetForm, values }) => (
          <ModalFilter
            show={filter.show}
            onReset={resetForm}
            onHide={() =>
              setFilter({
                ...filter,
                show: false
              })
            }
            onSubmit={() => {
              setFilter({
                show: false,
                active: Boolean(Object.values(values).find((val) => val)),
                data: values
              });
            }}
          >
            <ModalFilterBody />
          </ModalFilter>
        )}
      </Formik>
    </>
  );
};
