import { ButtonBack, ButtonCreate, useModalConfirm } from "components";
import { Formik } from "formik";
import { useUploads } from "hooks";
import { ContentLayout } from "layouts";
import { useNavigate } from "react-router-dom";
import { errorSubmitMapper } from "utilities";
import * as Yup from "yup";
import UploadPengirimanBetonApi from "./__UploadPengirimanBetonApi__";
import { Content } from "./__UploadPengirimanBetonComps__";
import { formInitialValues, formSubmitValueMapper } from "./__UploadPengirimanBetonUtilities__";

export const UploadPengirimanBetonCreate = () => {
    const navigate = useNavigate();
    const modalConfirm = useModalConfirm();

    // const createData = useMutation((data) => UploadPengirimanBetonApi.create(data), {
    //     onSuccess: () => {
    //         modalConfirm.infoSuccess({ typeInfo: "create" });
    //         navigate("/payroll/transaksi/upload-pengiriman-beton");
    //     },
    //     onError: (err) => modalConfirm.infoError({ typeInfo: "create", ...errorSubmitMapper(err) })
    // });

    const submitValidationHandler = (errors) =>
        new Promise((resolve, reject) => {
            const getError = Object.values(errors);

            if (getError.length > 0) {
                reject(getError ?? []);
            } else {
                resolve();
            }
        });

    const preSubmitHandler = (values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting) => {
        setSubmitting(true);
        validateForm().then(async (err) => {
            setErrors(err);
            setTouched(err);

            await submitValidationHandler(err, values)
                .then(() => formSubmitHandler(values))
                .catch((err) =>
                    modalConfirm.trigger({
                        type: "error",
                        title: "Data Tidak Lengkap",
                        data: err,
                        onHide: () => modalConfirm.close()
                    })
                )
                .finally(() => {
                    setSubmitting(false);
                });
        });
    };

    const uploadData = useUploads(UploadPengirimanBetonApi.linkUploadData, {
        onSuccess: () => {
            modalConfirm.infoSuccess({
                customTextInfoTitle: "Upload data pengiriman beton berhasil",
                onHide: modalConfirm.close
            })
            navigate("/payroll/transaksi/upload-pengiriman-beton")
        },
        onError: (err) =>
            modalConfirm.infoError({
                customTextInfoTitle: "Upload data pengiriman beton gagal",
                ...errorSubmitMapper(err),
                onHide: modalConfirm.close
            })
    });

    const formSubmitHandler = async (values) => {
        return await modalConfirm.trigger({
            type: "create",
            onHide: modalConfirm.close,
            onSubmit: () => {
                modalConfirm.loading();
                // console.log("create values", formSubmitValueMapper(values).create)
                uploadData.mutateAsync(formSubmitValueMapper(values).create);
                modalConfirm.close();
            }
        });
    };

    return (
        <>
            <ContentLayout showBreadcrumb>
                {/* {getSetupForm?.isFetching ? (
                    <DataStatus loading />
                ) : ( */}
                <>
                    <div className="text-end mb-3">
                        <ButtonBack tooltip={false} onClick={() => navigate("/payroll/transaksi/upload-pengiriman-beton")} />
                    </div>
                    <Formik
                        // enableReinitialize
                        initialValues={formInitialValues().dataFormSetup}
                        validationSchema={Yup.object().shape({
                            dataPengirimanBeton: Yup.string().required("File wajib diisi"),
                        })}
                        onSubmit={(val, { resetForm }) => {
                            resetForm();
                        }}
                    >
                        {({ values, handleSubmit, validateForm, setTouched, setErrors, setSubmitting }) => (
                            <>
                                <Content
                                    action="CREATE"
                                    uploadData={uploadData}
                                />
                                <div className="text-end mb-3 mt-3">
                                    <ButtonCreate
                                        text="SIMPAN"
                                        tooltip={false}
                                        onClick={() =>
                                            preSubmitHandler(values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting)
                                        }
                                    />
                                </div>
                            </>
                        )}
                    </Formik>
                </>
                {/* )} */}
            </ContentLayout>
        </>
    );
};
