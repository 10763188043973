import * as Yup from "yup";

export const formValidationSchema = Yup.object().shape({
    // persentase: Yup.string().required("Persentase wajib diisi"),
    detail: Yup.array().of(
        Yup.object().shape({
            // persentase: Yup.string().required("Persentase wajib diisi"),
            // persentase: Yup.string().when(['checked', 'persentaseTotal'], {
            //     is: (checked, persentaseTotal) => checked === true || parseInt(persentaseTotal) === 101,
            //     // is: true,
            //     then: () => Yup.string().required("Hey")
            // }),
            persentase: Yup.number().test('presentaseTotal', "Persentase melebihi 100%", function (val) {
                let persentaseSudah = this.parent.persentaseSudah;
                let checked = this.parent.checked;
                let persentaseTotal = (val + persentaseSudah);
                return (checked === false) || ((checked === true) && (isNaN(persentaseTotal))) || ((checked === true) && ((persentaseTotal) <= 100))
            })
        })
    )
});
