import {
  Input,
  InputFileSingle,
  InputNumber, numberConvert,
  Select
} from "components";
import { useFormikContext } from "formik";
import { Card, Col, ProgressBar, Row } from "react-bootstrap";
import { useQuery } from "react-query";
import { dateConvert } from "utilities";
import UploadPengirimanBetonApi from "../__UploadPengirimanBetonApi__";

const SectionDataSetup = ({ uploadData }) => {
  const { values, setValues, errors, touched } = useFormikContext();

  return (
    <>
      <InputFileSingle
        label="Upload File Pengiriman Beton"
        onClick={(e) => (uploadData.isLoading ? e.preventDefault() : {})}
        disabled={uploadData.isLoading}
        onChange={(val) => {
          let formData = new FormData();
          formData.append("file_pengiriman_beton", val.data);

          setValues({
            ...values,
            dataPengirimanBeton: formData
          });
        }}
        error={Boolean(errors.dataPengirimanBeton && touched.dataPengirimanBeton)}
        errorText={Boolean(errors.dataPengirimanBeton && touched.dataPengirimanBeton) && errors.dataPengirimanBeton}
        acceptFileType={["xlsx"]}
      />
      {uploadData.isLoading && (
        <ProgressBar now={uploadData.progress} label={`Mengunggah file ${uploadData.progress}%`} />
      )}
    </>
  );
};

export const Content = ({ action, uploadData }) => {
  const { values, setValues, errors, touched } = useFormikContext();
  const number = numberConvert();
  const date = dateConvert();

  const getPekerja = useQuery(["pekerja", "dropdown"], () => UploadPengirimanBetonApi.getPekerja());
  const getJenisRitase = useQuery(["jenis-ritase", "dropdown"], () => UploadPengirimanBetonApi.getJenisRitase());
  const getStatusRitase = useQuery(["status-ritase", "dropdown"], () => UploadPengirimanBetonApi.getStatus());
  const getStatusKubikasi = useQuery(["status-kubikasi", "dropdown"], () => UploadPengirimanBetonApi.getStatus());

  if (action === "UPDATE") {
    return (
      <>
        <Input disabled label="Tanggal" value={date.getSlashDMY(new Date(values?.tglPengiriman))} />
        <Input disabled label="No. Doket" value={values?.noDoket} />
        <Input disabled label="Kustomer" value={values?.kustomer} />
        <Input disabled label="Proyek" value={values?.proyek} />
        <Input disabled label="Supir" value={values?.supir} />
        <Select
          label="Pekerja"
          options={getPekerja?.data ?? []}
          placeholder="Pilih salah satu..."
          defaultValue={values?.pekerjaId ? getPekerja?.data?.find((item) => item.value === values?.pekerjaId) : null}
          onChange={(val) => {
            setValues({
              ...values,
              pekerjaId: val.value,
              pekerjaNama: val.label
            });
          }}
          loading={getPekerja?.isFetching}
          errorFetch={getPekerja.isError}
          error={Boolean(errors.pekerjaId && touched.pekerjaId)}
          errorText={Boolean(errors.pekerjaId && touched.pekerjaId) && errors.pekerjaId}
        />
        <Input disabled label="No. TM" value={values?.noTm} />
        <Input disabled label="Mutu" value={values?.mutu} />
        <Input disabled label="Volume" value={number.getWithComa(values?.volume, { minimumFractionDigits: 0 })} />
        <Select
          label="Jenis Ritase"
          options={getJenisRitase?.data ?? []}
          placeholder="Pilih salah satu..."
          defaultValue={
            values?.jenisRitaseKode
              ? getJenisRitase?.data?.find((item) => item.kode === values?.jenisRitaseKode)
              : null
          }
          onChange={(val) => {
            setValues({
              ...values,
              jenisRitaseId: val.value,
              jenisRitaseNama: val.label,
              jenisRitaseKode: val.kode
            });
          }}
          loading={getJenisRitase?.isFetching}
          errorFetch={getJenisRitase.isError}
          error={Boolean(errors.jenisRitaseKode && touched.jenisRitaseKode)}
          errorText={Boolean(errors.jenisRitaseKode && touched.jenisRitaseKode) && errors.jenisRitaseKode}
        />
        <InputNumber
          label="Jarak"
          suffix="km"
          value={number.getWithComa(values?.jarak, { minimumFractionDigits: 0 })}
          onChange={(val) =>
            setValues({
              ...values,
              jarak: val
            })
          }
          error={Boolean(errors.jarak && touched.jarak)}
          errorText={Boolean(errors.jarak && touched.jarak) && errors.jarak}
        />
        <Select
          disable
          label="Status Ritase"
          options={getStatusRitase?.data ?? []}
          placeholder="Pilih salah satu..."
          defaultValue={
            values?.statusRitase ? getStatusRitase?.data?.find((item) => item.value === values?.statusRitase) : null
          }
          onChange={(val) => {
            setValues({
              ...values,
              statusRitase: val.value
            });
          }}
          loading={getStatusRitase?.isFetching}
          errorFetch={getStatusRitase.isError}
          error={Boolean(errors.statusRitase && touched.statusRitase)}
          errorText={Boolean(errors.statusRitase && touched.statusRitase) && errors.statusRitase}
        />
        <Select
          disable
          label="Status Kubikasi"
          options={getStatusKubikasi?.data ?? []}
          placeholder="Pilih salah satu..."
          defaultValue={
            values?.statusKubikasi
              ? getStatusKubikasi?.data?.find((item) => item.value === values?.statusKubikasi)
              : null
          }
          onChange={(val) => {
            setValues({
              ...values,
              statusKubikasi: val.value
            });
          }}
          loading={getStatusKubikasi?.isFetching}
          errorFetch={getStatusKubikasi.isError}
          error={Boolean(errors.statusKubikasi && touched.statusKubikasi)}
          errorText={Boolean(errors.statusKubikasi && touched.statusKubikasi) && errors.statusKubikasi}
        />
      </>
    );
  }

  return (
    <>
      <Row>
        <Col md={12} className="mb-4">
          <Card>
            <Card.Header>
              <b>Setup Data</b>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col md={8}>
                  <SectionDataSetup uploadData={uploadData} />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
