import { PresensiList } from "pages/TimeAttendance";

export const Presensi = {
  component: <PresensiList />,
  link: "presensi",
  name: "Presensi",
  access: "REKAP_PRESENSI"
  // child: [
  //   {
  //     name: "Kelola",
  //     link: "/kelola/:id",
  //     component: <PresensiDetail />
  //   }
  // ]
};
