import { SetupUangLemburKubikasiList, SetupUangLemburKubikasiCreate } from "pages/Payroll";

export const SetupUangLemburKubikasi = {
    component: <SetupUangLemburKubikasiList />,
    link: "uang-lembur-kubikasi",
    name: "Setup Uang Lembur Kubikasi",
    access: "PEKERJA_KUBIKASI",
    child: [
        {
            name: "Tambah",
            link: "/tambah",
            component: <SetupUangLemburKubikasiCreate />
        }
    ]
};
