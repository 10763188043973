import { Services } from "services";
import { fetchDropdownMapper } from "utilities";

const fetchDropdown = fetchDropdownMapper(Services);

class SetupPersentaseUpahPokokApi {
  async getList(params) {
    const fetch = await Services.get("/master/upah-pokok", { params });
    return fetch?.data?.data;
  }

  async getDetail(id) {
    const fetch = await Services.get("/master/upah-pokok" + id);
    return fetch?.data?.data;
  }

  getStatusPekerja() {
    return fetchDropdown("/master/status-pekerja/dropdown", {
      value: "master_status_pekerja_id",
      label: "status_pekerja"
    });
  }

  getDetailStatusPekerja() {
    return fetchDropdown("/master/detail-status-pekerja/dropdown", {
      value: "master_detail_status_pekerja_id",
      label: "detail_status_pekerja"
    });
  }

  async getExport(params) {
    const fetch = await Services.get("/master/upah-pokok/export?to=", { params });
    return fetch?.data?.data;
  }

  create(data) {
    return Services.post("/master/upah-pokok", data);
  }

  update(data, id) {
    return Services.put("/master/upah-pokok/" + id, data);
  }

  delete(id) {
    return Services.delete("/master/upah-pokok/" + id);
  }
}

export default new SetupPersentaseUpahPokokApi();
