import React from "react";
import { Tabs } from "components";
import { TabDataScan, TabRekapPeriode, TabRincianHarian } from "../__PresensiComps__";
import { Col } from "react-bootstrap";

export const Content = ({ activeTab = "data-scan", setActiveTab }) => {
  const tabs = [
    {
      key: "data-scan",
      title: "Data Scan",
      component: (
        <div className="d-flex justify-content-center">
          <Col>
            <TabDataScan />
          </Col>
        </div>
      )
    },
    {
      key: "rincian-harian",
      title: "Rincian Harian",
      component: (
        <div className="d-flex justify-content-center">
          <Col>
            <TabRincianHarian />
          </Col>
        </div>
      )
    },
    {
      key: "rekap-periode",
      title: "Rekap Periode",
      component: (
        <div className="d-flex justify-content-center">
          <Col>
            <TabRekapPeriode />
          </Col>
        </div>
      )
    }
  ];

  return (
    <div>
      <Tabs defaultActiveKey={activeTab} tabs={tabs} onSelect={(val) => setActiveTab(val)} />
    </div>
  );
};
