import { Services } from "services";
import { fetchDropdownMapper } from "utilities";

const fetchDropdown = fetchDropdownMapper(Services);

class PhkApi {
  async getList(params) {
    const fetch = await Services.get("/transaksi/pekerja-phk", { params });
    return fetch?.data?.data;
  }
  async getSingle(id) {
    const fetch = await Services.get("/transaksi/pekerja-phk/" + id);
    return fetch?.data?.data;
  }
  async getForm(params) {
    const fetch = await Services.get("/transaksi/pekerja-phk/form", { params });
    return fetch?.data?.data;
  }
  getPeriodeGajian() {
    return fetchDropdown("/utility/periode-tahun?feat=pekerja-phk", {
      value: "periode_tahun",
      label: "periode_tahun"
    });
  }
  async getUnitOrganisasi(action) {
    const getUnit = await fetchDropdown("/master/unit-organisasi/dropdown", {
      value: "master_unit_organisasi_id",
      label: "master_unit_organisasi_nama"
    });

    if (action === "UNFILTER") {
      return getUnit;
    } else {
      return [{ value: undefined, label: "Semua" }, ...getUnit];
    }
  }

  async getUnitOrganisasiForList(params) {
    const fetch = await Services.get("/transaksi/pekerja-phk/dropdown-unit", { params });
    return fetch?.data?.data.map((val) => ({
      value: val?.master_unit_organisasi_id,
      label: val?.master_unit_organisasi_nama
    }));
  }

  // async getInfoPekerja(id) {
  //   const fetch = await Services.get("/transaksi/pekerja-phk/dropdown-pekerja?master_unit_organisasi_id="+id);
  //   return fetch?.data?.data;
  // }

  async getInfoPekerja(params) {
    const fetch = await Services.get("/transaksi/pekerja-phk/dropdown-pekerja", { params });
    return fetch?.data?.data.map((val) => ({
      value: val?.pekerja_id,
      label: val?.pekerja_nama
    }));
  }

  getAlasanPhk() {
    return fetchDropdown("/master/alasan-phk/dropdown", {
      value: "master_alasan_phk_id",
      label: "kode",
      alasan: "nama"
    });
  }

  async getExport(params) {
    const fetch = await Services.get("/transaksi/pekerja-phk/export", { params });
    return fetch?.data?.data;
  }
  create(data) {
    return Services.post("/transaksi/pekerja-phk", data);
  }
  update(data, id) {
    return Services.put("/transaksi/pekerja-phk/" + id, data);
  }
  delete(id) {
    return Services.delete("/transaksi/pekerja-phk/" + id);
  }

  linkUploadSuratKeputusan = "/upload/suart-keputusan-phk";
  linkUploadSuratKeterangan = "/upload/surat-keterangan-phk";
}

export default new PhkApi();
