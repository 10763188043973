import { Services } from "services";
import { fetchDropdownMapper } from "utilities";

const fetchDropdown = fetchDropdownMapper(Services);

class EvaluasiJabatanApi {
  async getList(params) {
    const fetch = await Services.get("/transaksi/evaluasi-jabatan", { params });
    return fetch?.data?.data;
  }

  async getSingle(id) {
    const fetch = await Services.get("/transaksi/evaluasi-jabatan/" + id);
    return fetch.data.data;
  }

  async getExport(params) {
    const fetch = await Services.get("/master/evaluasi-jabatan/export", { params });
    return fetch?.data?.data;
  }

  getJabatan(jenis_jabatan_id) {
    return fetchDropdown("/master/jabatan?jenis_jabatan=" + jenis_jabatan_id, {
      value: "master_jabatan_id",
      label: "master_jabatan_nama",
      unit_organisasi_id: "master_unit_organisasi_id",
      unit_organisasi_nama: "master_unit_organisasi_nama"
    });
  }

  getJenisJabatan() {
    return fetchDropdown("/master/jenis-jabatan", {
      value: "jenis_jabatan_id",
      label: "jenis_jabatan_nama"
    });
  }

  async getF1() {
    const fetch = await Services.get("/transaksi/evaluasi-jabatan/f1");
    return fetch?.data?.data?.map((val) => ({
      value: val.master_f1_id,
      label: `${val?.f1_kode ? `${val.master_f1_kode} - ` : ""} ${val.master_f1_nama}`,
      nama: val.master_f1_nama,
      kode: val.master_f1_kode
    }));
  }

  async getF2() {
    const fetch = await Services.get("/transaksi/evaluasi-jabatan/f2");
    return fetch?.data?.data?.map((val) => ({
      value: val.master_f2_id,
      label: `${val?.f2_kode ? `${val.master_f2_kode} - ` : ""} ${val.master_f2_nama}`,
      nama: val.master_f2_nama,
      kode: val.master_f2_kode
    }));
  }

  async getF3() {
    const fetch = await Services.get("/transaksi/evaluasi-jabatan/f3");
    return fetch?.data?.data?.map((val) => ({
      value: val.master_f3_id,
      // label: `${val?.f3_kode ? `${val.master_f3_kode} - ` : ''} ${val.master_f3_nama}`,
      label: val.master_f3_nama,
      nama: val.master_f3_nama,
      kode: val.master_f3_kode
    }));
  }

  async getF4() {
    const fetch = await Services.get("/transaksi/evaluasi-jabatan/f4");
    return fetch?.data?.data?.map((val) => ({
      value: val.master_f4_id,
      label: `${val?.f4_kode ? `${val.master_f4_kode} - ` : ""} ${val.master_f4_nama}`,
      nama: val.master_f4_nama,
      kode: val.master_f4_kode
    }));
  }

  async getF5() {
    const fetch = await Services.get("/transaksi/evaluasi-jabatan/f5");
    return fetch?.data?.data?.map((val) => ({
      value: val.master_f5_id,
      label: `${val?.f5_kode ? `${val.master_f5_kode} - ` : ""} ${val.master_f5_nama}`,
      nama: val.master_f5_nama,
      kode: val.master_f5_kode
    }));
  }

  async getF6() {
    const fetch = await Services.get("/transaksi/evaluasi-jabatan/f6");
    return fetch?.data?.data?.map((val) => ({
      value: val.master_f6_id,
      label: `${val?.f6_kode ? `${val.master_f6_kode} - ` : ""} ${val.master_f6_nama}`,
      nama: val.master_f6_nama,
      kode: val.master_f6_kode
    }));
  }

  async getF7() {
    const fetch = await Services.get("/transaksi/evaluasi-jabatan/f7");
    return fetch?.data?.data?.map((val) => ({
      value: val.master_f7_id,
      label: `${val?.f7_kode ? `${val.master_f7_kode} - ` : ""} ${val.master_f7_nama}`,
      nama: val.master_f7_nama,
      kode: val.master_f7_kode
    }));
  }

  async getF8() {
    const fetch = await Services.get("/transaksi/evaluasi-jabatan/f8");
    return fetch?.data?.data?.map((val) => ({
      value: val.master_f8_id,
      // label: `${val?.f8_kode ? `${val.master_f8_kode} - ` : ''} ${val.master_f8_nama}`,
      label: val.master_f8_nama,
      nama: val.master_f8_nama,
      kode: val.master_f8_kode,
      is_n: val.is_n
    }));
  }

  getA1() {
    return fetchDropdown("/transaksi/evaluasi-jabatan/judgement1", {
      value: "master_judgement1_id",
      label: "master_judgement1_nama",
      kode: "master_judgement1_kode"
    });
  }

  getA2() {
    return fetchDropdown("/transaksi/evaluasi-jabatan/judgement2", {
      value: "master_judgement2_id",
      label: "master_judgement2_nama",
      kode: "master_judgement2_kode"
    });
  }

  getA3() {
    return fetchDropdown("/transaksi/evaluasi-jabatan/judgement3", {
      value: "master_judgement3_id",
      label: "master_judgement3_nama",
      kode: "master_judgement3_kode"
    });
  }

  getSkor1(data) {
    return Services.post("/transaksi/evaluasi-jabatan/skor/p1", data);
  }

  getSkor2(data) {
    return Services.post("/transaksi/evaluasi-jabatan/skor/p2", data);
  }

  getSkor3(data) {
    return Services.post("/transaksi/evaluasi-jabatan/skor/p3", data);
  }

  getGrade(skor) {
    return Services.get(`/transaksi/evaluasi-jabatan/grade/${skor}`);
  }

  getPangkat(grade_id, jenis_jabatan_id) {
    return Services.get(`/transaksi/evaluasi-jabatan/pangkat/${grade_id}/${jenis_jabatan_id}`);
  }

  checkAvailable(params) {
    return Services.get("/transaksi/evaluasi-jabatan/available", { params });
  }

  show(id) {
    return Services.put(`/hrd/jabatan/${id}/active`);
  }

  hide(id) {
    return Services.put(`/hrd/jabatan/${id}/inactive`);
  }

  create(data) {
    return Services.post("/transaksi/evaluasi-jabatan", data);
  }

  update(data, id) {
    return Services.put("/transaksi/evaluasi-jabatan/" + id, data);
  }

  delete(id) {
    return Services.delete("/transaksi/evaluasi-jabatan/" + id);
  }
}

export default new EvaluasiJabatanApi();
