import {
  dateConvert, InfoItemVertical, InputCurrency, numberConvert
} from "components";
import { useFormikContext } from "formik";
import { Card, Col, Row } from "react-bootstrap";

const SectionDataPekerja = () => {
  const { values } = useFormikContext();
  const date = dateConvert();

  return (
    <>
      <InfoItemVertical label="Periode Penggajian" text={values?.periodePerencanaan} />
      <InfoItemVertical
        label="Tanggal Setup"
        text={values?.tglSetup ? date.getSlashDMY(new Date(values?.tglSetup)) : "-"}
      />
      <InfoItemVertical label="Unit Organisasi" text={values?.unitOrganisasiNama} />
      <InfoItemVertical label="NIK" text={values?.nik} />
      <InfoItemVertical label="Pekerja" text={values?.pekerjaNama} />
      <InfoItemVertical label="Jabatan" text={values?.jabatanNama} />
      <InfoItemVertical
        label="Tanggal Pengangkatan"
        text={values?.tglPengangkatan ? date.getSlashDMY(new Date(values?.tglPengangkatan)) : "-"}
      />
      <InfoItemVertical
        label="Tanggal Pemberhentian"
        text={values?.tglPemberhentian ? date.getSlashDMY(new Date(values?.tglPemberhentian)) : "-"}
      />
      <InfoItemVertical label="Masa Kerja" text={values?.masaKerja ? values?.masaKerja + " Tahun" : "-"} />
      <InfoItemVertical label="Alasan PHK" text={values?.alasanPhk} />
    </>
  );
};

export const Content = ({ action }) => {
  const { values, errors, touched, setValues } = useFormikContext();
  const number = numberConvert();

  if (action === "READ") {
    return (
      <>
        <Row>
          <Col md={4}>
            <Card className="mb-4">
              <Card.Header>
                <b>Data Pekerja</b>
              </Card.Header>
              <Card.Body>
                <SectionDataPekerja />
              </Card.Body>
            </Card>
          </Col>
          <Col md={8}>
            <Card className="mb-4">
              <Card.Header>
                <b>Perhitungan Uang PHK</b>
              </Card.Header>

              <Card.Body>
                <Row>
                  <Col>
                    <div className="mb-2">
                      <b>Perhitungan Uang PHK Berdasar PP 35/2023</b>
                    </div>
                    <InfoItemVertical
                      label="Uang Tetap"
                      text={
                        values?.constUangTetap
                          ? "Rp" + number.getWithComa(values?.constUangTetap, { minimumFractionDigits: 2 })
                          : "Rp0"
                      }
                    />
                    <InfoItemVertical
                      label="Uang Pesangon"
                      text={
                        values?.constUangPesangon
                          ? "Rp" + number.getWithComa(values?.constUangPesangon, { minimumFractionDigits: 2 })
                          : "Rp0"
                      }
                    />
                    <InfoItemVertical
                      label="Uang Penghargaan Masa Kerja"
                      text={
                        values?.constUangPenghargaan
                          ? "Rp" + number.getWithComa(values?.constUangPenghargaan, { minimumFractionDigits: 2 })
                          : "Rp0"
                      }
                    />
                    <InfoItemVertical
                      label="Uang Penggantian Hak"
                      text={
                        values?.constUangPenggantianHak
                          ? "Rp" + number.getWithComa(values?.constUangPenggantianHak, { minimumFractionDigits: 2 })
                          : "Rp0"
                      }
                    />
                    <InfoItemVertical
                      label="Uang Pisah"
                      text={
                        values?.constUangPisah
                          ? "Rp" + number.getWithComa(values?.constUangPisah, { minimumFractionDigits: 2 })
                          : "Rp0"
                      }
                    />
                    <InfoItemVertical
                      label="Total Uang PHK"
                      text={
                        values?.constTotalUangPhk
                          ? "Rp" + number.getWithComa(values?.constTotalUangPhk, { minimumFractionDigits: 2 })
                          : "Rp0"
                      }
                    />
                  </Col>
                  <Col>
                    <div className="mb-2">
                      <b>Kebijakan Perhitungan Uang PHK</b>
                    </div>
                    <InfoItemVertical
                      label="Uang Tetap"
                      text={
                        values?.uangTetap
                          ? "Rp" + number.getWithComa(values?.uangTetap, { minimumFractionDigits: 2 })
                          : "Rp0"
                      }
                    />
                    <InfoItemVertical
                      label="Uang Pesangon"
                      text={
                        values?.uangPesangon
                          ? "Rp" + number.getWithComa(values?.uangPesangon, { minimumFractionDigits: 2 })
                          : "Rp0"
                      }
                    />
                    <InfoItemVertical
                      label="Uang Penghargaan Masa Kerja"
                      text={
                        values?.uangPenghargaan
                          ? "Rp" + number.getWithComa(values?.uangPenghargaan, { minimumFractionDigits: 2 })
                          : "Rp0"
                      }
                    />
                    <InfoItemVertical
                      label="Uang Penggantian Hak"
                      text={
                        values?.uangPenggantianHak
                          ? "Rp" + number.getWithComa(values?.uangPenggantianHak, { minimumFractionDigits: 2 })
                          : "Rp0"
                      }
                    />
                    <InfoItemVertical
                      label="Uang Pisah"
                      text={
                        values?.uangPisah
                          ? "Rp" + number.getWithComa(values?.uangPisah, { minimumFractionDigits: 2 })
                          : "Rp0"
                      }
                    />
                    <InfoItemVertical
                      label="Total Uang PHK"
                      text={
                        values?.totalUangPhk
                          ? "Rp" + number.getWithComa(values?.totalUangPhk, { minimumFractionDigits: 2 })
                          : "Rp0"
                      }
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </>
    );
  }

  return (
    <>
      <Row>
        <Col md={4}>
          <Card className="mb-4">
            <Card.Header>
              <b>Data Pekerja</b>
            </Card.Header>
            <Card.Body>
              <SectionDataPekerja />
            </Card.Body>
          </Card>
        </Col>
        <Col md={8}>
          <Card>
            <Card.Header>
              <b>Perhitungan Uang PHK</b>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col className="mb-4" md={6}>
                  <div className="mb-2">
                    <b>Perhitungan Uang PHK Berdasar PP 35/2023</b>
                  </div>
                  <InputCurrency
                    disabled
                    label="Uang Tetap"
                    placeholder="Masukkan uang tetap"
                    value={values?.constUangTetap}
                    onChange={(val) =>
                      setValues({
                        ...values,
                        constUangTetap: val
                      })
                    }
                    error={Boolean(errors.constUangTetap && touched.constUangTetap)}
                    errorText={Boolean(errors.constUangTetap && touched.constUangTetap) && errors.constUangTetap}
                  />
                  <InputCurrency
                    disabled
                    label="Uang Pesangon"
                    placeholder="Masukkan uang pesangon"
                    value={values?.constUangPesangon}
                    onChange={(val) =>
                      setValues({
                        ...values,
                        constUangPesangon: val
                      })
                    }
                    error={Boolean(errors.constUangPesangon && touched.constUangPesangon)}
                    errorText={
                      Boolean(errors.constUangPesangon && touched.constUangPesangon) && errors.constUangPesangon
                    }
                  />
                  <InputCurrency
                    disabled
                    label="Uang Penghargaan Masa Kerja"
                    placeholder="Masukkan uang penghargaan masa kerja"
                    value={values?.constUangPenghargaan}
                    onChange={(val) =>
                      setValues({
                        ...values,
                        constUangPenghargaan: val
                      })
                    }
                    error={Boolean(errors.constUangPenghargaan && touched.constUangPenghargaan)}
                    errorText={
                      Boolean(errors.constUangPenghargaan && touched.constUangPenghargaan) &&
                      errors.constUangPenghargaan
                    }
                  />
                  <InputCurrency
                    disabled
                    label="Uang Penggatian Hak"
                    placeholder="Masukkan uang penggantian hak"
                    value={values?.constUangPenggantianHak}
                    onChange={(val) =>
                      setValues({
                        ...values,
                        constUangPenggantianHak: val
                      })
                    }
                    error={Boolean(errors.constUangPenggantianHak && touched.constUangPenggantianHak)}
                    errorText={
                      Boolean(errors.constUangPenggantianHak && touched.constUangPenggantianHak) &&
                      errors.constUangPenggantianHak
                    }
                  />
                  <InputCurrency
                    disabled
                    label="Uang Pisah"
                    placeholder="Masukkan uang pisah"
                    value={values?.constUangPisah}
                    onChange={(val) =>
                      setValues({
                        ...values,
                        constUangPisah: val
                      })
                    }
                    error={Boolean(errors.constUangPisah && touched.constUangPisah)}
                    errorText={Boolean(errors.constUangPisah && touched.constUangPisah) && errors.constUangPisah}
                  />
                  <InputCurrency
                    disabled
                    label="Total Uang PHK"
                    placeholder="Total uang PHK"
                    value={values?.constTotalUangPhk}
                    onChange={(val) =>
                      setValues({
                        ...values,
                        constTotalUangPhk: val
                      })
                    }
                    error={Boolean(errors.constTotalUangPhk && touched.constTotalUangPhk)}
                    errorText={
                      Boolean(errors.constTotalUangPhk && touched.constTotalUangPhk) && errors.constTotalUangPhk
                    }
                  />
                </Col>
                <Col className="mb-4" md={6}>
                  <div className="mb-2">
                    <b>Kebijakan Perhitungan Uang PHK</b>
                  </div>
                  <InputCurrency
                    disabled
                    label="Uang Tetap"
                    placeholder="Masukkan uang tetap"
                    value={values?.uangTetap}
                    onChange={(val) =>
                      setValues({
                        ...values,
                        uangTetap: val
                      })
                    }
                    error={Boolean(errors.uangTetap && touched.uangTetap)}
                    errorText={Boolean(errors.uangTetap && touched.uangTetap) && errors.uangTetap}
                  />
                  <InputCurrency
                    label="Uang Pesangon"
                    placeholder="Masukkan uang pesangon"
                    value={values?.uangPesangon}
                    onChange={(val) =>
                      setValues({
                        ...values,
                        uangPesangon: val,
                        totalUangPhk: val
                          ? values?.uangPenghargaan + values?.uangPenggantianHak + values?.uangPisah + val
                          : values?.uangPenghargaan + values?.uangPenggantianHak + values?.uangPisah + 0
                      })
                    }
                    error={Boolean(errors.uangPesangon && touched.uangPesangon)}
                    errorText={Boolean(errors.uangPesangon && touched.uangPesangon) && errors.uangPesangon}
                  />
                  <InputCurrency
                    label="Uang Penghargaan Masa Kerja"
                    placeholder="Masukkan uang penghargaan masa kerja"
                    value={values?.uangPenghargaan}
                    onChange={(val) =>
                      setValues({
                        ...values,
                        uangPenghargaan: val,
                        totalUangPhk: val
                          ? values?.uangPesangon + values?.uangPenggantianHak + values?.uangPisah + val
                          : values?.uangPesangon + values?.uangPenggantianHak + values?.uangPisah + 0
                      })
                    }
                    error={Boolean(errors.uangPenghargaan && touched.uangPenghargaan)}
                    errorText={Boolean(errors.uangPenghargaan && touched.uangPenghargaan) && errors.uangPenghargaan}
                  />
                  <InputCurrency
                    label="Uang Penggatian Hak"
                    placeholder="Masukkan uang penggantian hak"
                    value={values?.uangPenggantianHak}
                    onChange={(val) =>
                      setValues({
                        ...values,
                        uangPenggantianHak: val,
                        totalUangPhk: val
                          ? values?.uangPesangon + values?.uangPenghargaan + values?.uangPisah + val
                          : values?.uangPesangon + values?.uangPenghargaan + values?.uangPisah + 0
                      })
                    }
                    error={Boolean(errors.uangPenggantianHak && touched.uangPenggantianHak)}
                    errorText={
                      Boolean(errors.uangPenggantianHak && touched.uangPenggantianHak) && errors.uangPenggantianHak
                    }
                  />
                  <InputCurrency
                    label="Uang Pisah"
                    placeholder="Masukkan uang pisah"
                    value={values?.uangPisah}
                    onChange={(val) =>
                      setValues({
                        ...values,
                        uangPisah: val,
                        totalUangPhk: val
                          ? values?.uangPesangon + values?.uangPenghargaan + values?.uangPenggantianHak + val
                          : values?.uangPesangon + values?.uangPenghargaan + values?.uangPenggantianHak + 0
                      })
                    }
                    error={Boolean(errors.uangPisah && touched.uangPisah)}
                    errorText={Boolean(errors.uangPisah && touched.uangPisah) && errors.uangPisah}
                  />
                  <InputCurrency
                    disabled
                    label="Total Uang PHK"
                    placeholder="Total uang PHK"
                    value={values?.totalUangPhk}
                    onChange={(val) =>
                      setValues({
                        ...values,
                        totalUangPhk: val
                      })
                    }
                    error={Boolean(errors.totalUangPhk && touched.totalUangPhk)}
                    errorText={Boolean(errors.totalUangPhk && touched.totalUangPhk) && errors.totalUangPhk}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
