import { Services } from "services";

class MappingOverheadApi {
    async getList(params) {
        const fetch = await Services.get("/master/mapping-overhead", { params });
        return fetch?.data?.data;
    }

    async getDetail(id) {
        const fetch = await Services.get("/master/mapping-overhead/" + id);
        return fetch?.data?.data;
    }

    async getJenisOverhead() {
        const fetch = await Services.get("/master/jenis-overhead/dropdown");
        return fetch?.data?.data.map((val) => ({
            value: val?.jenis_overhead_id,
            label: val?.jenis_overhead_nama
        }));
    }

    async getUnitOrganisasi() {
        const fetch = await Services.get("/master/unit-organisasi");
        return fetch?.data?.data.map((val) => ({
            value: val?.master_unit_organisasi_id,
            label: val?.master_unit_organisasi_nama
        }));
    }

    async getExport(params) {
        const fetch = await Services.get("/master/mapping-overhead/export?to=", { params });
        return fetch?.data?.data;
    }

    create(data) {
        return Services.post("/master/mapping-overhead", data);
    }

    update(data, id) {
        return Services.put("/master/mapping-overhead/" + id, data);
    }

    delete(id) {
        return Services.delete("/master/mapping-overhead/" + id);
    }
}

export default new MappingOverheadApi();
