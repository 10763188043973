import { dateConvert } from "components";
const date = dateConvert();

export const formInitialValues = {
  Form: (data) => ({
    penghasilan: {
      tabelListPenghasilan: data?.komponen
        ?.filter((val) => val.status === 1)
        .map((data) => ({
          namaKomponenGaji: data?.nama_komponen,
          nominal: data?.nominal,
          kode: data?.kode
        })),
      brutoBulanIni: data?.penghasilan_bruto_bulanan,
      brutoTahunan: data?.penghasilan_bruto_tahunan
    },
    penguranganPenghasilan: {
      tabelListPenguranganPenghasilan: data?.komponen
        ?.filter((val) => val.status === 2)
        .map((data) => ({
          namaKomponenGaji: data?.nama_komponen,
          nominal: data?.nominal,
          kode: data?.kode
        })),
      brutoBulanIni: data?.pengurangan_bruto_bulanan,
      brutoTahunan: data?.pengurangan_bruto_tahunan
    },
    netto: {
      penghasilanNettoTahunan: data?.penghasilan_netto_tahunan
    },
    ptkp: data?.penghasilan_tidak_kena_pajak,
    finalPph: data?.penghasilan_kena_pajak_tahunan,
    pph21: {
      bulan: data?.pph21_bulanan,
      tahun: data?.pph21_tahunan
    },
    dataPekerja: {
      tanggalSetup: data?.tgl_setup,
      namaPeriode: data?.periode_perencanaan_gajian,
      bulanPenggajian: data?.tgl_bulan_awal
        ? date?.getSlashDMY(new Date(data?.tgl_bulan_awal)) +
        " s/d " +
        (data?.tgl_bulan_akhir ? date?.getSlashDMY(new Date(data?.tgl_bulan_akhir)) : undefined)
        : undefined +
        " s/d " +
        (data?.tgl_bulan_akhir ? date?.getSlashDMY(new Date(data?.tgl_bulan_akhir)) : undefined),
      namaUnitOrganisasi: data?.master_unit_organisasi_nama,
      namaPekerja: data?.pekerja_nama,
      namaJabatan: data?.master_jabatan_nama,
      namaStatusPekerja: data?.status_pekerja,
      namaStatusPajak: data?.status_pajak,
      npwp: data?.no_npwp
    }
  })
};
