import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import { Formik } from "formik";
import { ContentLayout } from "layouts";
import { Content } from "./__CreateGajiComps__";
import { formInitialValues } from "./__CreateGajiUtils__";
import { useModalConfirm } from "hooks";
import { ButtonBack, Button } from "components";
import CreateGajiApi from "./__CreateGajiApi__";
import { errorSubmitMapper } from "utilities";

export const CreateGajiCreate = () => {
  const navigate = useNavigate();
  const modalConfirm = useModalConfirm();

  const [searchingData, setSearchingData] = useState({
    periodePenggajianId: new Date().getFullYear(),
    tanggalSetup: new Date(),
    tanggalMulai: undefined,
    tanggalSelesai: undefined,
    unitOrganisasiId: undefined,
    pekerjaId: undefined
  });
  const [dataFilter, setDataFilter] = useState({
    periodePenggajianId: undefined,
    tanggalSetup: undefined,
    tanggalMulai: undefined,
    tanggalSelesai: undefined,
    unitOrganisasiId: undefined,
    pekerjaId: undefined
  });
  const [modal, setModal] = useState({
    show: false,
    action: "",
    data: {}
  });

  const getListForm = useQuery(
    ["create-gaji-form", "list", dataFilter, modal],
    () =>
      CreateGajiApi.getListForm({
        periode_tahun: dataFilter?.periodePenggajianId,
        tgl_setup: dataFilter?.tanggalSetup,
        tgl_bulan_awal: dataFilter?.tanggalMulai,
        tgl_bulan_akhir: dataFilter?.tanggalSelesai,
        master_unit_organisasi_id: dataFilter?.unitOrganisasiId,
        pekerja_id: modal?.data
      }),
    {
      enabled: Boolean(
        dataFilter?.periodePenggajianId &&
          dataFilter?.tanggalSetup &&
          dataFilter?.tanggalMulai &&
          dataFilter?.tanggalSelesai &&
          dataFilter?.unitOrganisasiId &&
          modal?.data
      )
    }
  );
  const getListPost = useQuery(["create-gaji-post", "list", dataFilter],
    () =>
      CreateGajiApi.getListPost({
        periode_tahun: dataFilter?.periodePenggajianId,
        tgl_bulan_awal: dataFilter?.tanggalMulai,
        tgl_bulan_akhir: dataFilter?.tanggalSelesai,
        master_unit_organisasi_id: dataFilter?.unitOrganisasiId
      }),
    {
      enabled: Boolean(
        dataFilter?.periodePenggajianId &&
        dataFilter?.tanggalMulai &&
        dataFilter?.tanggalSelesai &&
        dataFilter?.unitOrganisasiId
      )
    }
  );

  const quickPost = useMutation((data) => CreateGajiApi.quickPost(data), {
    onSuccess: () => {
      modalConfirm.infoSuccess({ typeInfo: "create" });
      getListPost.refetch();
    },
    onError: (err) => modalConfirm.infoError({ typeInfo: "create", ...errorSubmitMapper(err) })
  });
  const submitValidationHandler = (errors) =>
    new Promise((resolve, reject) => {
      const t = Object.values(errors)
        .flat()
        .filter((v) => v !== undefined)
        .map((v) => Object.values(v))
        .flat();
      const getError = Array.from(new Set(t));

      if (getError.length > 0) {
        reject(getError);
      } else {
        resolve();
      }
  });
  const preSubmitHandler = (values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting) => {
    setSubmitting(true);
    validateForm().then(async (err) => {
      setErrors(err);
      setTouched(err);

      await submitValidationHandler(err, getListPost)
        .then(() => handleSubmit())
        .catch((err) =>
          modalConfirm.trigger({
            type: "error",
            title: "Data Tidak Lengkap",
            data: err,
            onHide: () => modalConfirm.close()
          })
        )
        .finally(() => {
          setSubmitting(false);
        });
    });
  };
  const formSubmitHandler = async (values) => {
    return await modalConfirm.trigger({
      type: "create",
      size: "md",
      customButtonText: "IYA",
      onHide: modalConfirm.close,
      onSubmit: () => {
        modalConfirm.loading();
        quickPost.mutateAsync({
          periode_tahun: dataFilter?.periodePenggajianId,
          tgl_bulan_awal: dataFilter?.tanggalMulai,
          tgl_bulan_akhir: dataFilter?.tanggalSelesai,
          master_unit_organisasi_id: dataFilter?.unitOrganisasiId,
          tgl_setup: dataFilter?.tanggalSetup
        }).then(() => navigate("/payroll/transaksi/create-gaji"));
      }
    });
  };

  return (
    <>
      <ContentLayout showBreadcrumb>
        <>
          <div className="text-end mb-3">
            <ButtonBack tooltip={false} onClick={() => navigate(-1)} />
          </div>
          <Formik
            enableReinitialize
            initialValues={formInitialValues(getListForm?.data)}
            onSubmit={(values) => formSubmitHandler(values)}
          >
            {({ values, handleSubmit, validateForm, setTouched, setErrors, setSubmitting }) => (
              <>
                <Content
                  action="CREATE"
                  searchingData={searchingData}
                  setSearchingData={setSearchingData}
                  dataFilter={dataFilter}
                  setDataFilter={setDataFilter}
                  dataDetail={getListForm?.data}
                  dataDetailPost={getListPost?.data}
                  modal={modal}
                  setModal={setModal}
                />
                <div className="text-end mt-3">
                  <Button
                    text="Quick Post"
                    tooltip={false}
                    variant="success"
                    color="success"
                    onClick={() =>
                      preSubmitHandler(values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting)
                    }
                    className="px-4"
                    // disabled={getListForm?.status !== "success"}
                  />
                </div>
              </>
            )}
          </Formik>
        </>
      </ContentLayout>
    </>
  );
};
