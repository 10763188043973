export const formInitialValues = (data = {}) => ({
  periode_gajian_id: data?.periode_gajian_id,
  periodePerencanaan: data?.data?.info?.periode_tahun ?? new Date().getFullYear(),
  pekerja_id: data?.pekerja_id,
  master_unit_organisasi_id: data?.master_unit_organisasi_id,
  tgl_pengangkatan: data?.tgl_pengangkatan,
  tgl_setup: data?.tgl_setup,
  master_alasan_phk_id: data?.master_alasan_phk_id,
  tgl_batas_pensiun: data?.tgl_batas_pensiun,
  tgl_lahir: data?.tgl_lahir,
  peraturan_uang_pesangon: data?.peraturan_uang_pesangon,
  peraturan_uang_upmk: data?.peraturan_uang_upmk,
  peraturan_uang_uph: data?.peraturan_uang_uph,
  peraturan_uang_up: data?.peraturan_uang_up,
  peraturan_total_uang_phk: data?.peraturan_total_uang_phk,
  masa_kerja: data?.masa_kerja
});
