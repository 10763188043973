import { Services } from "services";
import { fetchDropdownMapper } from "utilities";

const fetchDropdown = fetchDropdownMapper(Services);

class SetupTunjanganMasaKerjaApi {
  async getList(params) {
    const fetch = await Services.get("/master/setup-tunjangan-masa-kerja/", { params });
    return fetch?.data?.data;
  }

  async getPeriodePenggajian() {
    const getPeriodePenggajian = await fetchDropdown("/master/grade-nominal/dropdown", {
      value: "periode_gajian_id",
      label: "periode_perencanaan"
    });
    return getPeriodePenggajian;
  }

  async getExport(params) {
    const fetch = await Services.get("/master/setup-tunjangan-masa-kerja/export", { params });
    return fetch?.data?.data;
  }

  create(data) {
    return Services.post("/master/setup-tunjangan-masa-kerja", data);
  }

  update(data, id) {
    return Services.put("/master/setup-tunjangan-masa-kerja/" + id, data);
  }

  delete(id) {
    return Services.delete("/master/setup-tunjangan-masa-kerja/" + id);
  }
}
export default new SetupTunjanganMasaKerjaApi();
