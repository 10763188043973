import { Services } from "services";
import { fetchDropdownMapper } from "utilities";

const fetchDropdown = fetchDropdownMapper(Services);

class RekapOrderMakanApi {
  async getList(params) {
    const fetch = await Services.get("/laporan/rekap-order-makan", { params });
    return fetch?.data?.data;
  }

  async getExport(params) {
    const fetch = await Services.get("/laporan/rekap-order-makan/export", { params });
    return fetch?.data?.data;
  }

  async getBulanPenjadwalan(params) {
    const fetch = await Services.get("/laporan/rekap-order-makan/bulan-penjadwalan/dropdown", { params });
    return fetch?.data?.data;
  }

  async getTanggalOrder(params) {
    const fetch = await Services.get("/laporan/rekap-order-makan/bulan-penjadwalan/dropdown", { params });
    return fetch?.data?.data?.map((val) => ({ value: val, label: val }));
  }

  async getPeriode() {
    const getPeriode = await fetchDropdown("/master/periode-perencanaan/", {
      value: "master_periode_perencanaan_id",
      label: "periode_perencanaan"
    });

    return getPeriode;
  }

  async getUnitOrganisasi() {
    const getUnitOrganisasi = await fetchDropdown("/master/unit-organisasi", {
      value: "master_unit_organisasi_id",
      label: "master_unit_organisasi_nama"
    });

    return getUnitOrganisasi.sort((x, y) => {
      if (x.label < y.label) {
        return -1;
      }
      if (x.label > y.label) {
        return 1;
      }
      if (x.label === y.label) {
        return 0;
      }
    });
  }

  create(data) {
    return Services.post("/transaksi/setup-cash-advance", data);
  }

  update(data, id) {
    return Services.put("/transaksi/setup-cash-advance/" + id, data);
  }

  delete(id) {
    return Services.delete("/transaksi/setup-cash-advance/" + id);
  }
}
export default new RekapOrderMakanApi();
