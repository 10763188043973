export const formSubmitValueMapper = (data) => ({
  tgl_input: data?.tglInput,
  periode_tahun: data?.periodeGajianId,
  master_unit_organisasi_id: data?.unitOrganisasiId,
  pekerja_id: data?.pekerjaId,
  master_jabatan_id: data?.jabatanId,
  nomor: data?.nomor,
  tujuan: data?.tujuan,
  tgl_mulai: data?.tglMulai,
  tgl_akhir: data?.tglAkhir,
  lama_dinas: data?.lamaPenugasan
});
