import {
  TunjanganUangMakanLemburList,
  TunjanganUangMakanLemburCreate,
  TunjanganUangMakanLemburUpdate,
  TunjanganUangMakanLemburDetail
} from "pages/Payroll";

export const TunjanganUangMakanLembur = {
  component: <TunjanganUangMakanLemburList />,
  link: "tunjangan-uang-lembur-makan",
  name: "Tunjangan Uang Makan Lembur",
  access: "PEKERJA_LEMBUR_MAKAN_BULANAN",
  child: [
    {
      name: "Tambah",
      link: "/tambah",
      component: <TunjanganUangMakanLemburCreate />
    },
    {
      name: "Ubah",
      link: "/ubah/:id",
      component: <TunjanganUangMakanLemburUpdate />
    },
    {
      name: "Detail",
      link: "/detail/:id",
      component: <TunjanganUangMakanLemburDetail />
    }
  ]
};
