import { DatePicker, Input, Select } from "components";
import { useFormikContext } from "formik";
import { Card, Col, Row } from "react-bootstrap";
import { useQuery } from "react-query";
import SuratPenugasanKhususApi from "../__SuratPenugasanKhususApi__";

export const Content = ({ action }) => {
  const { values, setValues, errors, touched, handleChange } = useFormikContext();
  // const date = dateConvert();

  const getUnitOrganisasi = useQuery(["unit-organisasi", "dropdown"], () =>
    SuratPenugasanKhususApi.getUnitOrganisasi()
  );

  const getPekerja = useQuery(["pekerja", "dropdown", values?.unitOrganisasiId], () =>
    SuratPenugasanKhususApi.getPekerja({
      master_unit_organisasi_id: values?.unitOrganisasiId
    })
  );

  return (
    <>
      <Row>
        <Col>
          <Card style={{ height: "100%" }}>
            <Card.Body>
              <Row>
                <Col>
                  <DatePicker
                    disabled={Boolean(action !== "CREATE")}
                    label="Tanggal"
                    placeholderText="Pilih tanggal input permohonan izin"
                    selected={values?.tglInput}
                    onChange={(val) =>
                      setValues({
                        ...values,
                        tglInput: val
                      })
                    }
                    error={Boolean(errors.tglInput && touched.tglInput)}
                    errorText={Boolean(errors.tglInput && touched.tglInput) && errors.tglInput}
                  />
                </Col>
                <Col>
                  <Input
                    name="nomor"
                    disabled={Boolean(action !== "CREATE")}
                    label="Nomor"
                    placeholder="Masukkan nomor surat"
                    value={values?.nomor}
                    onChange={handleChange}
                    error={Boolean(errors.nomor && touched.nomor)}
                    errorText={Boolean(errors.nomor && touched.nomor) && errors.nomor}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div className="mt-4">
                    <p>Dengan hormat</p>
                    <p>Saya yang bertanda tangan dibawah ini</p>
                  </div>
                  <Select
                    disable={Boolean(action !== "CREATE")}
                    label="Unit Organisasi"
                    placeholder="Pilih unit organisasi"
                    defaultValue={getUnitOrganisasi?.data?.find((item) => item.value === values.unitOrganisasiId)}
                    options={getUnitOrganisasi?.data ?? []}
                    onChange={(val) =>
                      setValues({
                        ...values,
                        unitOrganisasiId: val.value,
                        unitOrganisasiNama: val.label
                      })
                    }
                    loading={getUnitOrganisasi.isFetching}
                    errorFetch={getUnitOrganisasi.isError}
                    error={Boolean(errors.unitOrganisasiId && touched.unitOrganisasiId)}
                    errorText={Boolean(errors.unitOrganisasiId && touched.unitOrganisasiId) && errors.unitOrganisasiId}
                  />
                  <Select
                    label="Pekerja"
                    placeholder="Pilih pekerja"
                    defaultValue={getPekerja?.data?.find((item) => item.value === values.pekerjaId)}
                    options={getPekerja?.data ?? []}
                    onChange={(val) =>
                      setValues({
                        ...values,
                        pekerjaId: val.value,
                        pekerjaNama: val.label,
                        jabatanId: val.jabatanId,
                        jabatanNama: val.jabatanNama
                      })
                    }
                    loading={getPekerja.isFetching}
                    errorFetch={getPekerja.isError}
                    error={Boolean(errors.pekerjaId && touched.pekerjaId)}
                    errorText={Boolean(errors.pekerjaId && touched.pekerjaId) && errors.pekerjaId}
                  />
                  <Input
                    name="jabatan"
                    disabled
                    label="Jabatan"
                    value={values?.jabatanNama}
                    onChange={handleChange}
                    error={Boolean(errors.jabatanId && touched.jabatanId)}
                    errorText={Boolean(errors.jabatanId && touched.jabatanId) && errors.jabatanId}
                  />
                  <div className="mt-2">
                    <p>Mengajukan surat perjalanan dinas dengan :</p>
                  </div>
                  <Input
                    name="tujuan"
                    label="Tujuan"
                    placeholder="Masukkan tujuan"
                    value={values?.tujuan}
                    onChange={handleChange}
                    error={Boolean(errors.tujuan && touched.tujuan)}
                    errorText={Boolean(errors.tujuan && touched.tujuan) && errors.tujuan}
                  />
                  <DatePicker
                    label="Tanggal Mulai"
                    placeholderText="Pilih tanggal mulai"
                    selected={values?.tglMulai}
                    onChange={(val) => {
                      const dateDiff = new Date(val?.tglAkhir).getTime() - new Date(val).getTime();
                      const dayDiff = Math.round(dateDiff / (1000 * 3600 * 24)) + 1;

                      setValues({
                        ...values,
                        tglMulai: val,
                        lamaPenugasan: dayDiff
                      });
                    }}
                    error={Boolean(errors.tglMulai && touched.tglMulai)}
                    errorText={Boolean(errors.tglMulai && touched.tglMulai) && errors.tglMulai}
                  />
                  <DatePicker
                    label="Tanggal Akhir"
                    placeholderText="Pilih tanggal akhir"
                    selected={values?.tglAkhir}
                    onChange={(val) => {
                      const dateDiff = new Date(val).getTime() - new Date(values?.tglMulai).getTime();
                      const dayDiff = Math.round(dateDiff / (1000 * 3600 * 24)) + 1;

                      setValues({
                        ...values,
                        tglAkhir: val,
                        lamaPenugasan: dayDiff
                      });
                    }}
                    error={Boolean(errors.tglAkhir && touched.tglAkhir)}
                    errorText={Boolean(errors.tglAkhir && touched.tglAkhir) && errors.tglAkhir}
                  />
                  <Input
                    disabled
                    name="lamaPenugasan"
                    label="Lama Penugasan"
                    value={values?.lamaPenugasan ? values?.lamaPenugasan + " Hari" : ""}
                    error={Boolean(errors.lamaPenugasan && touched.lamaPenugasan)}
                    errorText={Boolean(errors.lamaPenugasan && touched.lamaPenugasan) && errors.lamaPenugasan}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
