import { ButtonBack, DataStatus, toastTrigger } from "components";
import { Formik } from "formik";
import { ContentLayout } from "layouts";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { exportHandler } from "utilities";
import SlipGajiApi from "./__SlipGajiApi__";
import { ContentNew } from "./__SlipGajiComps__";
import { formInitialValues } from "./__SlipGajiUtilities__";

export const SlipGajiDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const getSlipGajiDetail = useQuery(["slip-gaji", "detail"], () => SlipGajiApi.getDetail(id));

  const getPrintSlipGaji = useMutation((id) => SlipGajiApi.getPrint(id), {
    onSuccess: (link) => {
      toastTrigger({ variant: "success", message: "Print berhasil" });
      exportHandler(link, "Data Slip Gaji");
    },
    onError: () => {
      toastTrigger({ variant: "danger", message: "Print gagal" });
    }
  });

  const onPrintButtonClickHandler = (id) => getPrintSlipGaji.mutate(id);

  return (
    <>
      <ContentLayout showBreadcrumb>
        {getSlipGajiDetail?.isFetching ? (
          <DataStatus loading />
        ) : (
          <>
            <div className="d-flex flex-column flex-sm-row justify-content-center justify-content-md-end align-items-center mb-4 gap-2">
              {/* <ButtonPrint tooltip={false} onClick={() => onPrintButtonClickHandler(id)} /> */}
              <ButtonBack tooltip={false} onClick={() => navigate("/payroll/transaksi/slip-gaji")} />
            </div>
            <Formik enableReinitialize initialValues={formInitialValues(getSlipGajiDetail?.data)}>
              <ContentNew onPrintButtonClickHandler={onPrintButtonClickHandler} action="READ" />
            </Formik>
          </>
        )}
      </ContentLayout>
    </>
  );
};
