import { Services } from "services";
class HirarkiUnitOrganisasiApi {
  async getList(params) {
    const fetch = await Services.get("/master/hirarki-unit-organisasi", { params });
    return fetch?.data?.data;
  }

  async getExport(params) {
    const fetch = await Services.get("/master/hirarki-unit-organisasi/export", { params });
    return fetch?.data?.data;
  }

  getLevel() {
    return Array.from({ length: 10 }).map((_, index) => ({
      label: index + 1,
      value: index + 1
    }));
  }

  create(data) {
    return Services.post("/master/hirarki-unit-organisasi", data);
  }

  update(data, id) {
    return Services.put("/master/hirarki-unit-organisasi/" + id, data);
  }

  delete(id) {
    return Services.delete("/master/hirarki-unit-organisasi/" + id);
  }
}

export default new HirarkiUnitOrganisasiApi();
