import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useQuery } from "react-query";
import { InfoItemVertical } from "components";
import SetupKomponenJaminanHariTuaBulananApi from "../__SetupKomponenJaminanHariTuaBulananApi__";
import { numberConvert } from "utilities";
import { SetupData } from "./SetupData";
import { DataPekerja } from "./DataPekerja";

export const Content = ({ action, data, dataInfo, dataFilter, setDataFilter, getListForm }) => {
  const number = numberConvert();

  const [searchingData, setSearchingData] = useState({
    periodePenggajianId: undefined,
    periodePenggajianNama: undefined,
    tanggalMulai: undefined,
    tanggalSelesai: undefined,
    unitOrganisasiId: undefined,
    tanggalSetup: undefined
  });

  const getPeriodePenggajian = useQuery(
    ["periode-penggajian", "dropdown"],
    SetupKomponenJaminanHariTuaBulananApi.getPeriodePenggajian
  );
  const getUnitOrganisasi = useQuery(
    ["unit-organisasi", "dropdown"],
    SetupKomponenJaminanHariTuaBulananApi.getUnitOrganisasi
  );

  if (action === "READ") {
    return (
      <>
        <InfoItemVertical label="Komponen Payroll" text="BPJS TK – JAMINAN HARI TUA" />
        <InfoItemVertical label="Periode Penggajian" text={dataInfo?.periode_perencanaan_penggajian ?? "-"} />
        <InfoItemVertical label="Bulan Penggajian" text={"Maret"} />
        <InfoItemVertical label="NIK" text={data?.nip ?? "-"} />
        <InfoItemVertical label="Pekerja" text={data?.pekerja_nama ?? "-"} />
        <InfoItemVertical label="Unit Organisasi" text={data?.master_unit_organisasi_nama ?? "-"} />
        <InfoItemVertical label="Jabatan" text={data?.master_jabatan_nama ?? "-"} />
        <InfoItemVertical
          className="me-4"
          label="Upah Tetap BPJS"
          text={
            data?.upah_bpjs ? `Rp${number.getWithComa(parseInt(data?.upah_bpjs), { minimumFractionDigits: 2 })}` : "-"
          }
        />
        <InfoItemVertical
          className="me-4"
          label="Besaran Upah"
          text={
            data?.besaran_upah
              ? `Rp${number.getWithComa(parseInt(data?.besaran_upah), { minimumFractionDigits: 2 })}`
              : "-"
          }
        />
        <InfoItemVertical
          className="me-4"
          label="Iuran Perusahaan"
          text={
            data?.iuran_perusahaan
              ? `Rp${number.getWithComa(parseInt(data?.iuran_perusahaan), { minimumFractionDigits: 2 })}`
              : "-"
          }
        />
        <InfoItemVertical
          className="me-4"
          label="Iuran Karyawan"
          text={
            data?.iuran_karyawan
              ? `Rp${number.getWithComa(parseInt(data?.iuran_karyawan), { minimumFractionDigits: 2 })}`
              : "-"
          }
        />
        <InfoItemVertical
          className="me-4"
          label="Total Iuran BPJS TK - Jaminan Hari Tua"
          text={
            data?.iuran_perusahaan && data?.iuran_karyawan
              ? `Rp${number.getWithComa(parseInt(Number(data?.iuran_perusahaan) + Number(data?.iuran_karyawan)), {
                minimumFractionDigits: 2
              })}`
              : "-"
          }
        />
        <InfoItemVertical label="Jenis BPJS" text={data?.jenis ?? "-"} />
      </>
    );
  }
  if (action === "CREATE") {
    return (
      <Row>
        <Col lg={3}>
          <SetupData
            searchingData={searchingData}
            setSearchingData={setSearchingData}
            dataFilter={dataFilter}
            setDataFilter={setDataFilter}
            dropdownPeriode={getPeriodePenggajian}
            dropdownUnitOrganisasi={getUnitOrganisasi}
          />
        </Col>
        <Col lg={9}>
          <div className="mb-3">
            <DataPekerja
              action={action}
              dataFilter={dataFilter}
              setDataFilter={setDataFilter}
              getListForm={getListForm}
            />
          </div>
        </Col>
      </Row>
    );
  }
};
