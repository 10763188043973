import * as Yup from "yup";

export const formValidationSchema = {
  FormSearch: Yup.object().shape({
    idPeriode: Yup.string().required("Periode gajian wajib dipilih"),
    tglId: Yup.string().required("Tanggal penggajian wajib dipilih"),
    idUnitOrganisasi: Yup.string().required("Unit Organisasi wajib dipilih")
  }),
  Search: Yup.object().shape({
    idPeriode: Yup.string().required("Periode gajian wajib dipilih"),
    idBulan: Yup.string().required("Bulan penggajian wajib dipilih"),
    tglAwal: Yup.string().required("Tanggal penggajian wajib dipilih"),
    tglSetup: Yup.string().required("Tanggal setup wajib dipilih"),
    idUnitOrganisasi: Yup.string().required("Unit Organisasi wajib dipilih"),
    idPekerja: Yup.string().required("Pekerja wajib dipilih")
  }),
  FormKomponenUpah: Yup.object().shape({
    idKomponenUpah: Yup.string().required("Komponen upah wajib dipilih"),
    idJenisKoreksi: Yup.string().required("Jenis koreksi wajib dipilih"),
    nominal: Yup.string().required("Nominal wajib diisi"),
    keterangan: Yup.string().required("keterangan wajib diisi")
  })
};
