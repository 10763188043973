import { Services } from "services";

import { fetchDropdownMapper } from "components";

const fetchDropdown = fetchDropdownMapper(Services);

class DashboardApi {
    async getSummaryTotal(params) {
        const fetch = await Services.get("/dashboard/summary", { params });
        return fetch?.data?.data;
    }

    async getAktivitas(params) {
        const fetch = await Services.get("/dashboard/chart-stats-kehadiran", { params });
        return fetch?.data?.data;
    }

    async getPekerja(params) {
        const fetch = await Services.get("/dashboard/summary-pekerja", { params });
        return fetch?.data?.data;
    }

    async getStatusPekerja(params) {
        const fetch = await Services.get("/dashboard/summary-status-pekerja", { params });
        return fetch?.data?.data;
    }

    // async getPeriode() {
    //     const fetch = await Services.get("/master/periode-perencanaan/dropdown");
    //     return fetch?.data?.data.map((val) => ({
    //         value: val?.master_periode_perencanaan_id,
    //         label: val?.periode_perencanaan
    //     }));
    // }

    async getPeriode() {
        const getDropdown = await fetchDropdown("/master/periode-perencanaan/dropdown", {
            value: "master_periode_perencanaan_id",
            label: "periode_perencanaan"
        });
        return [{ value: undefined, label: "Default " + new Date().getFullYear() }, ...getDropdown];
    }
}

export default new DashboardApi();
