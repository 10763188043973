import { InfoItemVertical, Input, TextArea } from "components";
import { useFormikContext } from "formik";

export const Content = ({ action }) => {
  const { values, errors, touched, handleChange } = useFormikContext();

  if (action === "READ") {
    return (
      <>
        <InfoItemVertical label="Nama" text={values?.nama} />
        <InfoItemVertical label="Kode" text={values?.kode} />
        <InfoItemVertical label="Definisi" text={values?.definisi} />
      </>
    );
  }

  return (
    <>
      <Input
        label="Nama"
        name="nama"
        placeholder="Masukkan nama compensable factor"
        value={values?.nama === undefined ? "" : values?.nama}
        onChange={handleChange}
        error={Boolean(errors?.nama && touched?.nama)}
        errorText={Boolean(errors?.nama && touched?.nama) && errors?.nama}
      />
      <Input
        label="Kode"
        name="kode"
        placeholder="Masukkan kode"
        value={values?.kode === undefined ? "" : values?.kode}
        onChange={handleChange}
        error={Boolean(errors?.kode && touched?.kode)}
        errorText={Boolean(errors?.kode && touched?.kode) && errors?.kode}
      />
      <TextArea
        label="Definisi"
        name="definisi"
        placeholder="Masukkan definisi"
        value={values?.definisi === undefined ? "" : values?.definisi}
        onChange={handleChange}
        error={Boolean(errors?.definisi && touched?.definisi)}
        errorText={Boolean(errors?.definisi && touched?.definisi) && errors?.definisi}
      />
    </>
  );
};
