import React from "react";
import { useFormikContext } from "formik";
import { useQuery } from "react-query";
import { Input, InfoItemVertical, TextArea, Select } from "components";
import DetailStatusPekerjaApi from "../__DetailStatusPekerjaApi__";

export const Content = ({ action }) => {
  const { values, errors, touched, handleChange, setValues } = useFormikContext();
  const getStatusPekerja = useQuery(["status-pekerja"], () => DetailStatusPekerjaApi.getStatusPekerja());

  if (action === "READ") {
    return (
      <>
        <InfoItemVertical label="Status Pekerja" text={values.statusPekerjaNama} />
        <InfoItemVertical label="Nama Detail Status Pekerja" text={values.nama} />
        <InfoItemVertical label="Keterangan" text={values.nama_keterangan} />
      </>
    );
  }

  return (
    <>
      <Select
        label="Status Pekerja"
        placeholder="Pilih status pekerja"
        loading={getStatusPekerja.isFetching}
        options={getStatusPekerja?.data ?? []}
        defaultValue={getStatusPekerja?.data?.find((item) => item.value === values.statusPekerjaId)}
        onChange={(val) =>
          setValues({
            ...values,
            statusPekerjaId: val.value,
            statusPekerjaNama: val.label
          })
        }
        error={Boolean(errors.statusPekerjaId && touched.statusPekerjaId)}
        errorText={Boolean(errors.statusPekerjaId && touched.statusPekerjaId) && errors.statusPekerjaId}
        errorFetch={getStatusPekerja.isError}
      />
      <Input
        label="Nama Detail Status Pekerja"
        name="nama"
        placeholder="Masukkan nama detail status pekerja"
        value={values.nama}
        onChange={handleChange}
        error={Boolean(errors.nama && touched.nama)}
        errorText={Boolean(errors.nama && touched.nama) && errors.nama}
      />
      <TextArea
        label="Keterangan"
        name="keterangan"
        placeholder="Masukkan keterangan"
        value={values.keterangan}
        onChange={handleChange}
        error={Boolean(errors.keterangan && touched.keterangan)}
        errorText={Boolean(errors.keterangan && touched.keterangan) && errors.keterangan}
      />
    </>
  );
};
