export const formSubmitValueMapper = (values = {}) => ({
  pekerja_phk_uang_id: values?.id,
  pekerja_phk_id: values?.pekerjaPhkId,
  pekerja_id: values?.pekerjaId,

  upah_tetap: values?.uangTetap,
  peraturan_uang_pesangon: values?.constUangPesangon,
  peraturan_uang_upmk: values?.constUangPenghargaan,
  peraturan_uang_uph: values?.constUangPenggantianHak,
  peraturan_uang_up: values?.constUangPisah,
  peraturan_total_uang_phk: values?.constTotalUangPhk,

  kebijakan_uang_pesangon: values?.uangPesangon,
  kebijakan_uang_upmk: values?.uangPenghargaan,
  kebijakan_uang_uph: values?.uangPenggantianHak,
  kebijakan_uang_up: values?.uangPisah,
  kebijakan_total_uang_phk: values?.totalUangPhk
});
