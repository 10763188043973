export const formSubmitMapper = (values = {}) => ({
  master_jabatan_nama: values.nama,
  master_jabatan_kode: values.kode,

  jenis_jabatan: values.jenis_jabatan_nama,

  master_kelompok_jabatan_nama: values.kelompok_jabatan_nama,
  master_kelompok_jabatan_id: values.kelompok_jabatan_id,

  master_job_stream_nama: values.job_stream_nama,
  master_job_stream_id: values.job_stream_id,

  master_jenis_profesi_nama: values.jenis_profesi_nama,
  master_jenis_profesi_id: values.jenis_profesi_id,

  master_unit_organisasi_nama: values.unit_organisasi_nama,
  master_unit_organisasi_id: values.unit_organisasi_id,

  atasan_langsung_nama: values.atasan_langsung_nama,
  atasan_langsung: values.atasan_langsung_id
});
