import * as Yup from "yup";

export const formValidationSchema = Yup.object().shape({
  nama: Yup.string().required("Nama jabatan wajib diisi").nullable(),

  kode: Yup.string().required("Kode jabatan wajib diisi").nullable(),

  jenis_jabatan_nama: Yup.string().required("Jenis jabatan wajib diisi").nullable(),

  kelompok_jabatan_nama: Yup.string().required("Kelompok jabatan wajib diisi").nullable(),

  job_stream_nama: Yup.string().required("Job stream wajib diisi").nullable(),

  jenis_profesi_nama: Yup.string().required("Jenis profesi wajib diisi").nullable(),

  unit_organisasi_id: Yup.string().when(['jenis_jabatan_nama', 'jenis_jabatan_ket'], {
    is: (jenis_jabatan_nama = Number, jenis_jabatan_ket = String) => {
      if ((parseInt(jenis_jabatan_nama) === 1 && jenis_jabatan_ket === "STRUKTURAL")) {
        return true;
      } else if (jenis_jabatan_nama === undefined || isNaN(jenis_jabatan_nama)) {
        return false;
      }
      return false;
    },
    then: (schema) =>
      schema
        .required("Unit organisasi wajib diisi")
  }).nullable(),

  atasan_langsung_id: Yup.string().when(['jenis_jabatan_nama', 'jenis_jabatan_ket'], {
    is: (jenis_jabatan_nama = Number, jenis_jabatan_ket = String) => {
      if ((parseInt(jenis_jabatan_nama) === 1 && jenis_jabatan_ket === "STRUKTURAL")) {
        return true;
      } else if (jenis_jabatan_nama === undefined || isNaN(jenis_jabatan_nama)) {
        return false;
      }
      return false;
    },
    then: (schema) =>
      schema
        .required("Atasan langsung wajib diisi")
  }).nullable()


});
