import {
  SuratPerintahLemburList,
  SuratPerintahLemburCreate,
  SuratPerintahLemburUpdate,
  SuratPerintahLemburDetail
} from "pages/OprasionalSdm";

export const SuratPerintahLembur = {
  component: <SuratPerintahLemburList />,
  link: "surat-perintah-lembur",
  name: "Surat Perintah Lembur",
  access: "PERINTAH_LEMBUR",
  child: [
    {
      name: "Detail",
      link: "/detail/:id",
      component: <SuratPerintahLemburDetail />
    },
    {
      name: "Ubah",
      link: "/ubah/:id",
      component: <SuratPerintahLemburUpdate />
    },
    {
      name: "Tambah",
      link: "/tambah",
      component: <SuratPerintahLemburCreate />
    }
  ]
};
