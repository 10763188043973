export const formSubmitValueMapper = (data) => ({
  tgl_input: data?.tglInput,
  periode_tahun: data?.periodeGajianId,
  master_unit_organisasi_id: data?.unitOrganisasiId,
  pekerja_id: data?.pekerjaId,
  master_jabatan_id: data?.jabatanId,
  master_jenis_cuti_id: data?.jenisCutiId,
  nomor: data?.nomor,
  maksimal_cuti: data?.maxCuti,
  total_cuti_tahun_ini: data?.cutiSudah,
  total_sisa_cuti: data?.sisaCuti,
  tgl_mulai: data?.tglMulai,
  tgl_akhir: data?.tglAkhir,
  lama_cuti: data?.lamaCuti,
  alasan: data?.alasan
});
