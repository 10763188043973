export const formSubmitMapper = (values = {}) => ({
  periode_gajian_id: values?.periodePenggajianId,
  periode_tahun: values?.periodePenggajian,
  pekerja_id: values?.pekerjaId,
  master_unit_organisasi_id: values?.unitOrganisasiId,
  tgl_pengangkatan: values?.tglPengangkatan,
  tgl_setup: values?.tglSetup,
  master_alasan_phk_id: values?.alasanPhkId,
  tgl_pemberhentian: values?.tglPemberhentian,
  masa_kerja: values?.masaKerja,
  surat_keputusan: values?.suratKeputusan,
  file_surat_keputusan: values?.fileSuratKeputusan,
  surat_keterangan: values?.suratKeterangan,
  file_surat_keterangan: values?.fileSuratKeterangan,
  keterangan: values?.keterangan
});
