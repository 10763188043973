import { Services } from "services";

class ShiftApi {
  async getList(params) {
    const fetch = await Services.get("/master/shift/", { params });
    return fetch?.data?.data;
  }

  async scan(params) {
    const fetch = await Services.get("/service/fingerspot/dropdown/scan-tipe", { params });
    return fetch?.data?.data.map(val => ({ label: val?.scan_tipe_nama, value: val?.scan_tipe_id }))
  }

  async durasi(params) {
    const fetch = await Services.get("/service/fingerspot/dropdown/durasi-opsi", { params });
    return fetch?.data?.data.map(val => ({ label: val?.durasi_opsi_nama ?? val?.scan_tipe_nama, value: val?.durasi_opsi_id }))
  }

  async getExport(params) {
    const fetch = await Services.get("/master/shift/export", { params });
    return fetch?.data?.data;
  }

  create(data) {
    return Services.post("/master/shift", data);
  }

  update(data, id) {
    return Services.put("/master/shift/" + id, data);
  }

  delete(id) {
    return Services.delete("/master/shift/" + id);
  }
}
export default new ShiftApi();
