import * as Yup from "yup";

export const formValidationSchema = {
  akun: (action) =>
    action === "CREATE"
      ? Yup.object().shape({
        pekerjaNama: Yup.string().required("Nama pekerja wajib diisi"),
        akun: Yup.string().required("Username wajib diisi"),
        password: Yup.string().required("Password wajib diisi"),
        passwordKonfirmasi: Yup.string()
          .required("Konfirmasi password wajib diisi")
          .oneOf([Yup.ref("password"), null], "Password tidak cocok")
      })
      : Yup.object().shape({
        pekerjaNama: Yup.string().required("Nama pekerja wajib diisi"),
        akun: Yup.string().required("Username wajib diisi")
      }),
  profile: Yup.object().shape({
    // jenisIdentitasId: Yup.string().required("Jenis identitas wajib diisi"),
    // noIdentitas: Yup.string().required("No identitas wajib diisi"),
    tempatLahir: Yup.string().required("Tempat lahir wajib diisi"),
    tglLahir: Yup.string().required("Tanggal lahir wajib diisi"),
    jenisKelaminId: Yup.string().required("Jenis kelamin wajib diisi"),
    agamaId: Yup.string().required("Agama wajib diisi"),
    statusPerkawinanId: Yup.string().required("Status perkawinan wajib diisi")
    // fotoProfile: Yup.string().required("Dokumen identitas wajib diisi")
  }),
  identitas: Yup.object().shape({
    jenisIdentitasId: Yup.string().required("Jenis identitas wajib diisi"),
    noIdentitas: Yup.string().required("No identitas wajib diisi"),

    fileIdentitas: Yup.string().required("Dokumen identitas wajib diisi")
  }),

  jabatan: Yup.object().shape({
    jenisJabatanId: Yup.string().required("Jenis jabatan wajib diisi"),
    jabatanId: Yup.string().required("Jabatan wajib diisi"),
    gradeId: Yup.string().required("Grade wajib diisi"),
    stepId: Yup.string().required("Step wajib diisi")
  }),
  kepegawaian: Yup.object().shape({
    nip: Yup.string().required("NIP wajib diisi"),
    tanggalBergabung: Yup.string().required("Tgl. bergabung wajib diisi"),
    tanggalPengangkatan: Yup.string().required("Tgl. pengangkatan wajib diisi"),
    // noPengangkatan: Yup.string().required("No. pengangkatan wajib diisi"),
    jenisPekerjaId: Yup.string().required("Jenis pekerja wajib diisi"),
    statusPekerjaId: Yup.string().required("Status pekerja wajib diisi"),
    detailStatusPekerjaId: Yup.string().required("Detail Status Pekerja wajib diisi"),
    unitOrganisasiId: Yup.string().required("Unit Organisasi wajib diisi"),
    lokasiKerjaId: Yup.string().required("Lokasi kerja wajib diisi"),
    // namaWali: Yup.string().required("Nama Penanggung Jawab / Wali wajib diisi"),
    // jenisKontakId: Yup.string().required("Kontak Penanggung Jawab / Wali wajib diisi"),
    // kontakWali: Yup.string().required("Kontak Penanggung Jawab / Wali wajib diisi"),
    // hubunganWaliId: Yup.string().required("Hubungan Penanggung Jawab / Wali wajib diisi"),
    // hasilPsikotes: Yup.string().required("Hasil psikotest wajib diisi"),
    // skPengangkatan: Yup.string().required("Upload SK pengangkatan wajib diisi"),
    // noNpwp: Yup.string().required("Nomor npwp wajib diisi"),
    // noBpjsKetenagakerjaan: Yup.string().required("Nomor bpjs ketenagakerjaan wajib diisi"),
    // noBpjsKesehatan: Yup.string().required("Nomor bpjs kesehatan wajib diisi"),
    // noYadapen: Yup.string().required("Nomor yadapen wajib diisi"),
    // tahunPensiun: Yup.string().required("Tahun pensiun wajib diisi"),
    // bulanPensiun: Yup.string().required("Bulan pensiun wajib diisi"),
    // tanggalKeluar: Yup.string().required("Tanggal keluar wajib diisi")
  }),
  alamat: Yup.object().shape({
    provinsiKode: Yup.string().required("Provinsi wajib diisi"),
    kabupatenKode: Yup.string().required("Kabupaten wajib diisi"),
    kecamatanKode: Yup.string().required("Kecamatan wajib diisi"),
    desaKode: Yup.string().required("Desa wajib diisi"),
    alamat: Yup.string().required("Alamat wajib diisi")
  }),
  pendidikan: Yup.object().shape({
    jenjangPendidikanId: Yup.string().required("Jenjang pendidikan wajib diisi"),
    namaSekolah: Yup.string().required("Nama sekolah wajib diisi"),
    nomorIjazah: Yup.string().required("No. ijazah wajib diisi"),
    fileIjazah: Yup.string().required("File ijazah wajib diisi"),
    fileTrnaskrip: Yup.string().required("File transkrip nilai  wajib diisi")
  }),
  kontak: Yup.object().shape({
    jenisKontakId: Yup.string().required("Jenis kontak wajib diisi"),
    dataKontak: Yup.string().required("Data kontak wajib diisi")
  }),
  bank: Yup.object().shape({
    bankId: Yup.string().required("Nama bank wajib diisi"),
    noRekening: Yup.string().required("No. rekening wajib diisi"),
    atasNama: Yup.string().required("Atas nama wajib diisi"),
    cabangBank: Yup.string().required("Cabang wajib diisi")
  }),
  // rekamMedis: Yup.object().shape({
  //   noRekamMedis: Yup.string().required("No. rekam medis wajib diisi")
  // }),
  keluarga: Yup.object().shape({
    keluargaNama: Yup.string().required("Nama keluarga wajib diisi"),
    hubunganKeluargaId: Yup.string().required("Hubungan wajib diisi"),
    statusPerkawinanId: Yup.string().required("Status kawin wajib diisi"),
    provinsiKode: Yup.string().required("Provinsi wajib diisi"),
    kabupatenKode: Yup.string().required("Kabupaten wajib diisi"),
    kecamatanKode: Yup.string().required("Kecamatan wajib diisi"),
    desaKode: Yup.string().required("Desa wajib diisi"),
    alamat: Yup.string().required("Alamat wajib diisi"),
    tempatLahir: Yup.string().required("Tempat lahir wajib diisi"),
    tglLahir: Yup.string().required("Tgl. lahir wajib diisi"),
    agamaId: Yup.string().required("Agama wajib diisi"),
    jenisKelaminId: Yup.string().required("Jenis kelamin wajib diisi"),
    // pekerjaan: Yup.string().required("Pekerjaan wajib diisi"),
    // instansi: Yup.string().required("Nama instansi / sekolah wajib diisi"),
    // alamatInstansi: Yup.string().required("Alamat instansi wajib diisi"),
    // pendidikanTerakhirId: Yup.string().required("Pendidikan terakhir wajib diisi"),
    // tglLulus: Yup.string().required("Tgl. Lulus wajib diisi"),
    // tempatPendidikan: Yup.string().required("Nama universitas / sekolah wajib diisi")
  })
};
