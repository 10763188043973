import { Services } from "services";
import { fetchDropdownMapper } from "utilities";

const fetchDropdown = fetchDropdownMapper(Services);

class SetupPersentaseTunjanganTetapApi {
  async getList(params) {
    const fetch = await Services.get("/master/persentase-tunjangan-tetap", { params });
    return fetch?.data?.data;
  }

  async getJobStream() {
    const getJobStream = await fetchDropdown("/master/job-stream/dropdown", {
      value: "master_job_stream_id",
      label: "master_job_stream_nama"
    });

    return getJobStream;
  }

  async getExport(params) {
    const fetch = await Services.get("/master/persentase-tunjangan-tetap/export", { params });
    return fetch?.data?.data;
  }

  create(data) {
    return Services.post("/master/persentase-tunjangan-tetap", data);
  }

  update(data, id) {
    return Services.put("/master/persentase-tunjangan-tetap/" + id, data);
  }

  delete(id) {
    return Services.delete("/master/persentase-tunjangan-tetap/" + id);
  }
}
export default new SetupPersentaseTunjanganTetapApi();
