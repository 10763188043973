import {
    ButtonDelete, ButtonUpdate, InputSearch, ModalCreate, ModalDetail, ModalUpdate, rupiahConvert, Select, toastTrigger
} from "components";
import { Form, Formik } from "formik";
import { useAccessRights, useLayout, useModalConfirm } from "hooks";
import { ListLayout } from "layouts";
import { debounce } from "lodash";
import { useState } from "react";
import { ButtonGroup } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import { errorFetchingTableMapper, errorSubmitMapper, exportHandler } from "utilities";
import UangHarianApi from "./__UangHarianApi__";
import { Content } from "./__UangHarianComps__";
import {
    formInitialValues, formSubmitValueMapper, formValidationSchema
} from "./__UangHarianUtilities__";

export const UangHarianList = () => {
    const layout = useLayout();
    const access = useAccessRights("SETUP_UPAH_HARIAN");
    const modalConfirm = useModalConfirm();
    const rupiah = rupiahConvert()
    const [searchKey, setSearchKey] = useState(undefined);
    const [pagination, setPagination] = useState({
        pageCurrent: 1,
        dataLength: 10
    });
    const [filter, setFilter] = useState({
        periodeTahun: undefined
    });

    const [modal, setModal] = useState({
        show: false,
        action: "",
        data: {}
    });

    const getUangHarian = useQuery(
        ["upah-harian", "list", filter?.periodeTahun, searchKey, pagination.pageCurrent, pagination.dataLength],
        () =>
            UangHarianApi.getList({
                search: searchKey,
                page: pagination.pageCurrent,
                limit: pagination.dataLength,
                periode_tahun: filter?.periodeTahun
            })
    );

    const getPeriode = useQuery(["periode-tahun", "dropdown"], () => UangHarianApi.getPeriode());

    const createUangHarian = useMutation((data) => UangHarianApi.create(data), {
        onSuccess: () => {
            modalConfirm.infoSuccess({ typeInfo: "create" });
            getUangHarian.refetch();
        },
        onError: (err) => modalConfirm.infoError({ typeInfo: "create", ...errorSubmitMapper(err) })
    });

    const updateUangHarian = useMutation(({ data, id }) => UangHarianApi.update(data, id), {
        onSuccess: () => {
            modalConfirm.infoSuccess({ typeInfo: "update" });
            getUangHarian.refetch();
        },
        onError: (err) => modalConfirm.infoError({ typeInfo: "update", ...errorSubmitMapper(err) })
    });

    const deleteUangHarian = useMutation((id) => UangHarianApi.delete(id), {
        onSuccess: () => {
            modalConfirm.infoSuccess({ typeInfo: "delete" });
            getUangHarian.refetch();
        },
        onError: (err) => modalConfirm.infoError({ typeInfo: "delete", ...errorSubmitMapper(err) })
    });

    const getExportUangHarian = useMutation((type) => UangHarianApi.getExport({ to: type }), {
        onSuccess: (link) => {
            toastTrigger({ variant: "success", message: "Export berhasil" });
            exportHandler(link, "Data Status Rekomendasi");
        },
        onError: () => {
            toastTrigger({ variant: "danger", message: "Export gagal" });
        }
    });

    const searchHandler = (e) => {
        setSearchKey(e?.target?.value ? e.target.value : undefined);
    };

    const onHideModalHandler = () => setModal({ show: false, action: "", data: {} });

    const onCreateButtonClickHandler = () => setModal({ show: true, action: "CREATE", data: {} });

    // const onDetailButtonClickHandler = (data) => setModal({ show: true, action: "READ", data: data });

    const onUpdateButtonClickHandler = (data) => setModal({ show: true, action: "UPDATE", data: data });

    const onPaginationChange = (data) => setPagination({ ...pagination, pageCurrent: data });

    const onPaginationDataLengthChange = (data) => setPagination({ ...pagination, dataLength: data });

    const submitValidationHandler = (errors) =>
        new Promise((resolve, reject) => {
            const getError = Object.values(errors);

            if (getError.length > 0) {
                reject(getError);
            } else {
                resolve();
            }
        });

    const preSubmitHandler = (formik) => {
        const { values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting } = formik;

        setSubmitting(true);
        validateForm().then(async (err) => {
            setErrors(err);
            setTouched(err);

            await submitValidationHandler(err, values)
                .then(() => handleSubmit())
                .catch((err) =>
                    modalConfirm.trigger({
                        type: "error",
                        title: "Data Tidak Lengkap",
                        data: err,
                        onHide: () => modalConfirm.close()
                    })
                )
                .finally(() => {
                    setSubmitting(false);
                });
        });
    };

    const formSubmitHandler = (values, action) =>
        modalConfirm.trigger({
            type: action.toLowerCase(),
            onHide: modalConfirm.close,
            onSubmit: () => {
                modalConfirm.loading();

                if (action === "UPDATE") {
                    updateUangHarian
                        .mutateAsync({ data: formSubmitValueMapper(values), id: values.id })
                        .then(() => onHideModalHandler());
                } else if (action === "DELETE") {
                    deleteUangHarian.mutate(values.id);
                } else {
                    createUangHarian.mutateAsync(formSubmitValueMapper(values)).then(() => onHideModalHandler());
                }
            },
            component: (
                <Formik initialValues={values}>
                    <Content action="READ" />
                </Formik>
            )
        });

    return (
        <>
            <ListLayout
                customLeftTopSection={() => (
                    <div className="d-flex flex-sm-row align-items-end mb-3 gap-2">
                        <div style={{ width: 300 }}>
                            <InputSearch onChange={debounce(searchHandler, 1500)} />
                        </div>
                        <div style={{ width: 180 }}>
                            <Select
                                noMargin
                                label="Periode Tahun"
                                options={getPeriode?.data ?? []}
                                placeholder="Pilih salah satu..."
                                onChange={(val) => {
                                    setFilter({
                                        ...filter,
                                        periodeTahun: val.value
                                    });
                                }}
                                loading={getPeriode?.isFetching}
                                errorFetch={getPeriode.isError}
                            />
                        </div>
                    </div>
                )}
                showRightTopButton={access.canCreate() ? true : false}
                showExportExcelButton
                showExportPdfButton
                onExportExcelButtonClick={() => getExportUangHarian.mutate("excel")}
                onExportPdfButtonClick={() => getExportUangHarian.mutate("pdf")}
                loadingExportButton={getExportUangHarian.isLoading}
                loading={getUangHarian.isFetching}
                error={getUangHarian.isError}
                errorText={errorFetchingTableMapper(getUangHarian.error)}
                dataTotal={getUangHarian?.data?.total}
                dataLength={pagination.dataLength}
                pageCurrent={pagination.pageCurrent}
                pageTotal={getUangHarian?.data?.last_page}
                onCreateButtonClick={onCreateButtonClickHandler}
                onDataLengthChange={onPaginationDataLengthChange}
                onPaginationChange={onPaginationChange}
                data={getUangHarian?.data?.data}
                tableHeader={[
                    {
                        text: "Periode Penggajian",
                    },
                    {
                        text: "Upah Harian",
                    },
                    {
                        text: "Aksi",
                        props: {
                            width: 50,
                            fixed: true
                        }
                    }
                ]}
                tableBody={[
                    {
                        customField: (val) => String(val?.periode_tahun)
                    },
                    {
                        props: { textRight: true },
                        customField: (val) => rupiah.getWithComa(val?.upah_harian)
                    },
                    {
                        props: { fixed: true },
                        customField: (data) => (
                            <ButtonGroup size="sm">
                                {/* {access.canRead() && <ButtonDetail icon noText onClick={() => onDetailButtonClickHandler(data)} />} */}
                                {access.canUpdate() && <ButtonUpdate icon noText onClick={() => onUpdateButtonClickHandler(data)} />}
                                {access.canDelete() && (
                                    <ButtonDelete icon noText onClick={() => formSubmitHandler(formInitialValues(data), "DELETE")} />
                                )}
                            </ButtonGroup>
                        )
                    }
                ]}
            />

            {/**
       * Modal Detail:
       * Digunakan untuk melihat detail data
       */}
            <Formik enableReinitialize initialValues={formInitialValues(modal.data)}>
                <ModalDetail
                    title={layout.getActivePageName()}
                    show={Boolean(modal.show && modal.action === "READ")}
                    onHide={onHideModalHandler}
                >
                    <Content action="READ" />
                </ModalDetail>
            </Formik>

            {/**
       * Modal Create:
       * Digunakan untuk menambah data
       */}
            <Formik
                enableReinitialize
                initialValues={formInitialValues({})}
                validationSchema={formValidationSchema}
                onSubmit={(values) => formSubmitHandler(values, "CREATE")}
            >
                {(formik) => {
                    return (
                        <Form>
                            <ModalCreate
                                title={layout.getActivePageName()}
                                show={Boolean(modal.show && modal.action === "CREATE")}
                                onHide={() => {
                                    onHideModalHandler();
                                    formik.resetForm();
                                }}
                                onSubmit={() => preSubmitHandler(formik)}
                            >
                                <Content action="CREATE" />
                            </ModalCreate>
                        </Form>
                    );
                }}
            </Formik>

            {/**
       * Modal Update:
       * Modal untuk mengubah data
       */}
            <Formik
                enableReinitialize
                initialValues={formInitialValues(modal.data)}
                validationSchema={formValidationSchema}
                onSubmit={(values) => formSubmitHandler(values, "UPDATE")}
            >
                {(formik) => {
                    return (
                        <Form>
                            <ModalUpdate
                                title={layout.getActivePageName()}
                                show={Boolean(modal.show && modal.action === "UPDATE")}
                                onHide={() => {
                                    onHideModalHandler();
                                    formik.resetForm();
                                }}
                                onSubmit={() => preSubmitHandler(formik)}
                            >
                                <Content action="UPDATE" />
                            </ModalUpdate>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
};
