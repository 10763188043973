import {
    ButtonCreate, dateConvert, DatePicker, InfoItemVertical, Input, InputCurrency, numberConvert,
    Select, TableList
} from "components";
import { useFormikContext } from "formik";
import { Card, Col, Row } from "react-bootstrap";
import { useQuery } from "react-query";
import GajiKe13Api from "../__GajiKe13Api__";
import { checkMonth } from "../__GajiKe13Utilities__";

const SectionDataSetup = ({ onSearchButtonClick, onResetClickButton }) => {
    const { values, setValues, errors, touched } = useFormikContext();
    // const date = dateConvert();

    const getUnitOrganisasi = useQuery(["unit-organisasi", "dropdown"], () => GajiKe13Api.getUnitOrganisasi());
    const getBulan = useQuery(["bulan", "dropdown"], () => GajiKe13Api.getBulan());

    return (
        <>
            <Input
                disabled
                label="Periode Penggajian"
                value={values?.periodePerencanaan}
            />
            <Select
                label="Unit Organisasi"
                options={getUnitOrganisasi?.data ?? []}
                placeholder="Pilih salah satu..."
                defaultValue={
                    values?.unitOrganisasiId
                        ? getUnitOrganisasi?.data?.find((item) => item.value === values?.unitOrganisasiId)
                        : null
                }
                onChange={(val) => {
                    setValues({
                        ...values,
                        unitOrganisasiId: val.value,
                        unitOrganisasiNama: val.label
                    });
                }}
                loading={getUnitOrganisasi?.isFetching}
                errorFetch={getUnitOrganisasi.isError}
                error={Boolean(errors.unitOrganisasiId && touched.unitOrganisasiId)}
                errorText={Boolean(errors.unitOrganisasiId && touched.unitOrganisasiId) && errors.unitOrganisasiId}
            />
            {/* <Select
                label="Bulan Penggajian"
                disable={Boolean(!values?.periodeGajianId)}
                options={getBulan?.data ?? []}
                placeholder="Pilih salah satu..."
                defaultValue={values?.bulan ? getBulan?.data?.find((item) => item.value === values?.bulan) : null}
                onChange={(val) => {
                    const tglNewStart = val.value <= 1 ?
                        `${values?.periodePerencanaan - 1}-12-26`
                        : `${values?.periodePerencanaan}-${val?.value < 10 ? `0${val?.value - 1}` : val?.value - 1}-26`;

                    const tglNewEnd = `${values?.periodePerencanaan}-${val?.value < 10 ? `0${val?.value}` : val?.value}-25`;

                    setValues({
                        ...values,
                        bulan: val.value,
                        bulanNama: val.label,
                        tglAwal: tglNewStart,
                        tglAkhir: tglNewEnd
                    });
                }}
                loading={getBulan?.isFetching}
                errorFetch={getBulan.isError}
                error={Boolean((errors.bulan && touched.bulan) || errors.validateDate)}
                errorText={Boolean(errors.bulan && touched.bulan) && errors.bulan ? errors.bulan : errors.validateDate}
            />
            <div>
                <div style={{ fontSize: 14 }}>Tanggal Penggajian</div>
                <div className="d-flex align-items-center justify-content-between">
                    <div style={{ width: "44%" }}>
                        <DatePicker
                            disabled
                            key={`${values?.periodeGajianId} ${values?.bulan}`}
                            placeholderText="dd/mm/yyyy"
                            selected={values?.periodeGajianId && values?.bulan ? values?.tglAwal : undefined}
                            disable={Boolean(!values?.bulan)}
                            onChange={(val) =>
                                setValues({
                                    ...values,
                                    tglAwal: val,
                                    bulan: new Date(val).getMonth() + 1,
                                    tglAkhir: new Date(new Date(val).setDate(new Date(val).getDate() + 30)),
                                    validateDate: checkMonth(val, values?.tglSetup)
                                })
                            }
                            error={Boolean(errors.tglAwal && touched.tglAwal) || errors.validateDate}
                            errorText={
                                Boolean(errors.tglAwal && touched.tglAwal) && errors.tglAwal ? errors.tglAwal : errors.validateDate
                            }
                        />
                    </div>
                    <small>s/d</small>
                    <div style={{ width: "44%" }}>
                        <DatePicker
                            disabled
                            key={`${values?.periodeGajianId} ${values?.bulan}`}
                            placeholderText="dd/mm/yyyy"
                            selected={values?.periodeGajianId && values?.bulan ? values?.tglAkhir : undefined}
                            disable={Boolean(!values?.bulan)}
                            onChange={(val) =>
                                setValues({
                                    ...values,
                                    tglAkhir: val
                                })
                            }
                            error={Boolean(errors.tglAkhir && touched.tglAkhir)}
                            errorText={Boolean(errors.tglAkhir && touched.tglAkhir) && errors.tglAkhir}
                        />
                    </div>
                </div>
            </div> */}

            <DatePicker
                // disabled
                label="Tanggal Setup"
                placeholderText="dd-mm-yyyy"
                selected={values?.tglSetup}
                onChange={(val) =>
                    setValues({
                        ...values,
                        tglSetup: val
                    })
                }
                error={Boolean(errors.tglSetup && touched.tglSetup)}
                errorText={Boolean(errors.tglSetup && touched.tglSetup) && errors.tglSetup}
            />

            <div className="d-flex justify-content-end">
                <div>
                    {/* <ButtonCancel text="RESET" className="mb-3 me-2" tooltip={false} onClick={() => onResetClickButton()} /> */}
                    <ButtonCreate
                        disabled={Boolean(
                            !values?.periodeGajianId
                            // || !values?.unitOrganisasiId 
                            // || !values?.bulan
                        )}
                        text="CARI"
                        className="mb-3"
                        tooltip={false}
                        onClick={() => onSearchButtonClick(values)}
                    />
                </div>
            </div>
        </>
    );
};

const TabelDataPekerja = () => {
    const { values, setValues, errors, touched } = useFormikContext();
    const number = numberConvert();
    const date = dateConvert();

    const setNewValues = ({ index, propname, val }) => {
        const detailTemp = [...values?.detail];
        const updatedDetail = detailTemp[index];
        updatedDetail[propname] = val;

        setValues({ ...values, detail: detailTemp });
    };

    const getError = (index, propname) => {
        return Boolean(errors?.detail?.[index]?.[propname] && touched?.detail?.[index]?.[propname]);
    };

    const getErrorText = (index, propname) => {
        return (
            Boolean(errors?.detail?.[index]?.[propname] && touched?.detail?.[index]?.[propname]) &&
            errors?.detail?.[index]?.[propname]
        );
    };

    return (
        <>
            <TableList
                maxHeight={550}
                tableHeader={[
                    { text: "NIK" },
                    { text: "Pekerja" },
                    { text: "Unit Organisasi" },
                    { text: "Jabatan" },
                    { text: "Tgl. Bergabung" },
                    { text: "Masa Kerja" },
                    {
                        props: { minWidth: "200px", fixed: true },
                        text: "Upah Pokok"
                    },
                    {
                        props: { minWidth: "200px", fixed: true },
                        text: "Tunjangan Tetap"
                    },
                    {
                        props: { minWidth: "200px", fixed: true },
                        text: "Gaji 13"
                    }
                ]}
                tableBody={[
                    {
                        field: "nik"
                    },
                    {
                        field: "pekerjaNama"
                    },
                    {
                        field: "unitOrganisasiNama"
                    },
                    {
                        field: "jabatanNama"
                    },
                    {
                        customField: (val) => val?.tglBergabung ? date.getSlashDMY(new Date(val?.tglBergabung)) : "-"
                    },
                    {
                        customField: (val) => val?.masaKerjaTampil ? number.getWithComa(val?.masaKerjaTampil, { minimumFractionDigits: 0 }) + " tahun" : "-"
                    },
                    {
                        props: { textRight: true },
                        customField: (val) => val?.upahPokok ? "Rp" + number.getWithComa(val?.upahPokok, { minimumFractionDigits: 2 }) : "Rp0"
                    },
                    {
                        props: { textRight: true },
                        customField: (val) => val?.tunjanganTetap ? "Rp" + number.getWithComa(val?.tunjanganTetap, { minimumFractionDigits: 2 }) : "Rp0"
                    },
                    {
                        props: { minWidth: "200px", fixed: true },
                        customField: (val, index) => (
                            <InputCurrency
                                className="text-end"
                                name="nominal"
                                disabled
                                value={"Rp" + number.getWithComa(val?.nominal, { minimumFractionDigits: 2 })}
                                onChange={(x) => {
                                    // setNewValues({ index: index, propname: "checked", val: true })
                                    setNewValues({ index: index, propname: "nominal", val: x })
                                }}
                                error={getError(index, "nominal")}
                                errorText={getErrorText(index, "nominal")}
                            />
                        )
                    }
                ]}
                data={values?.detail}
            />
        </>
    );
};

export const Content = ({ action, onSearchButtonClick, onResetClickButton }) => {
    const { values, setValues, errors, touched } = useFormikContext();
    const number = numberConvert();
    const date = dateConvert();

    if (action === "READ") {
        return (
            <>
                <InfoItemVertical label="NIK" text={values.nik} />
                <InfoItemVertical label="Pekerja" text={values.pekerjaNama} />
                <InfoItemVertical label="Unit Organisasi" text={values.unitOrganisasiNama} />
                <InfoItemVertical label="Jabatan" text={values.jabatanNama} />
                <InfoItemVertical label="Tgl. Bergabung" text={values?.tglBergabung ? date.getSlashDMY(new Date(values?.tglBergabung)) : "-"} />
                <InfoItemVertical
                    label="Upah Pokok"
                    text={values?.upahPokok ? "Rp" + number.getWithComa(values?.upahPokok, { minimumFractionDigits: 2 }) : "-"}
                />
                <InfoItemVertical
                    label="Tunjangan Tetap"
                    text={values?.tunjanganTetap ? "Rp" + number.getWithComa(values?.tunjanganTetap, { minimumFractionDigits: 2 }) : "-"}
                />
                <InfoItemVertical
                    label="Gaji 13"
                    text={values.nominal ? "Rp" + number.getWithComa(values?.nominal, { minimumFractionDigits: 2 }) : "-"}
                />
            </>
        );
    }

    if (action === "UPDATE") {
        return (
            <>
                <Input disabled label="NIK" value={values?.nik} />
                <Input disabled label="Pekerja" value={values?.pekerjaNama} />
                <Input disabled label="Unit Organisasi" value={values?.unitOrganisasiNama} />
                <Input disabled label="Jabatan" value={values?.jabatanNama ? values?.jabatanNama : "-"} />
                <Input disabled label="Tgl. Bergabung" value={date.getSlashDMY(new Date(values?.tglBergabung))} />
                <Input disabled label="Upah Pokok" value={values?.upahPokok ? "Rp" + number.getWithComa(values?.upahPokok, { minimumFractionDigits: 2 }) : "-"} />
                <Input disabled label="Tunjangan Tetap" value={values?.tunjanganTetap ? "Rp" + number.getWithComa(values?.tunjanganTetap, { minimumFractionDigits: 2 }) : "-"} />
                <InputCurrency
                    name="nominal"
                    label="Gaji 13"
                    value={values.nominal ? "Rp" + number.getWithComa(values?.nominal, { minimumFractionDigits: 2 }) : 0}
                    onChange={(val) =>
                        setValues({
                            ...values,
                            nominal: val
                        })
                    }
                    error={Boolean(errors.nominal && touched.nominal)}
                    errorText={Boolean(errors.nominal && touched.nominal) && errors.nominal}
                />
            </>
        );
    }

    return (
        <>
            <Row>
                <Col md={3} className="mb-4">
                    <Card>
                        <Card.Header>
                            <b>Setup Data</b>
                        </Card.Header>
                        <Card.Body>
                            <SectionDataSetup onSearchButtonClick={onSearchButtonClick} onResetClickButton={onResetClickButton} />
                        </Card.Body>
                    </Card>
                </Col>

                <Col md={9}>
                    <Card>
                        <Card.Header>
                            <b>Data Pekerja</b>
                        </Card.Header>
                        <Card.Body>
                            <TabelDataPekerja />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};
