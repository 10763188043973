import { SlipGajiList, SlipGajiDetail } from "pages/Payroll";

export const SlipGaji = {
  component: <SlipGajiList />,
  link: "slip-gaji",
  name: "Slip Gaji",
  access: "SLIP_GAJI",
  child: [
    {
      name: "Detail",
      link: "/detail/:id",
      component: <SlipGajiDetail />
    }
  ]
};
